import React, { useState, useEffect, useContext } from "react";

import { ChangeLayoutModalStyle } from "./ChangeLayoutModalStyle.styled";
import { EditInvoiceContext } from "../../../contexts/EditInvoiceContext";
import { UserContext } from "../../../contexts/UserContext";

import Modal from "../../Modal/Modal";
import Form from "../../Forms/Form";
import { AppMessage } from "../../MessageUtils";

import { entriesPageOrderName, topSectionPageOrderName } from "../invoiceUtils";

// rendered by ChangeLayoutModal
const ShowTableHeadingsText = ({
	obj,
	somePageOrder,
	setSomePageOrder,
	significantItemOfGroupIndex,
	pageOrderName,
	userObject,
	editedInv,
}) => {
	const [tableHeadingsStyle, setTableHeadingsStyle] = useState({
		display: "none",
	});

	const handleThChange = (th, thIndex, val) => {
		let changedPageOrder = [];
		somePageOrder.forEach((item, i) => {
			let newItem = { ...item };
			if (i === significantItemOfGroupIndex) {
				let newTableHeadings = [];
				item.tableHeadings.forEach((th) => newTableHeadings.push({ ...th }));
				newTableHeadings[thIndex] = {
					...newTableHeadings[thIndex],
					visible: val === "no" ? false : true,
				};

				newItem = { ...newItem, tableHeadings: newTableHeadings };
			}
			changedPageOrder = [...changedPageOrder, newItem];
		});
		setSomePageOrder(changedPageOrder);
	};

	const handleSectionTotalVisibilityChange = (group, type, value) => {
		let changedPageOrder = [];
		somePageOrder.forEach((item, i) => {
			let newItem = { ...item };
			if (item.group === group && item.type === type) {
				newItem = { ...newItem, visible: value === "yes" ? true : false };
			}
			changedPageOrder = [...changedPageOrder, newItem];
		});
		setSomePageOrder(changedPageOrder);
	};

	const handleChangeHiddenItems = (infoType, infoTypeIndex, val) => {
		let changedPageOrder = [];
		somePageOrder.forEach((item, i) => {
			let newItem = { ...item };
			// only changes hiddenItems array
			if (i === significantItemOfGroupIndex) {
				if (infoType === "contractorFullAddress") {
					if (val === "no") {
						newItem.val = {
							...newItem.val,
							[infoType]: ""
						}		
					} else {
						// set the contractorFullAddress to userObject private address only if user is owner
						if (userObject && userObject.id === editedInv.owner) {
							if (userObject.private && userObject.private.address && userObject.private.address.addressString) {
								newItem.val = {
									...newItem.val,
									[infoType]: userObject.private.address.addressString
								}
							}
						}
					}
				}

				let newHiddenItems = item.hiddenItems || [];
				if (val === "no") {
					// add to hidden items
					newHiddenItems = [...(item.hiddenItems || []), infoType];
				} else {
					// remove from hidden items
					newHiddenItems = newHiddenItems.filter((hi) => hi !== infoType);
				}
				newItem = { ...newItem, hiddenItems: newHiddenItems };
			}
			changedPageOrder = [...changedPageOrder, newItem];
		});
		setSomePageOrder(changedPageOrder);
	};

	const pageOrderItem = somePageOrder[significantItemOfGroupIndex];
	const pageOrderItemSectionTotal = somePageOrder.find(
		(item) => item.group === pageOrderItem.group && item.type === "sectionTotal"
	);

	if (pageOrderItem.type === "table" || pageOrderItem.type === "div-table") {
		if (pageOrderItem.tableHeadings.length > 1) {
			return (
				<div key={somePageOrder[significantItemOfGroupIndex].group}>
					<div
						className={"show-table-headings-text"}
						style={{ margin: "0 0 0 5px" }}
						onClick={(e) =>
							setTableHeadingsStyle((tableHeadingsStyle) => ({
								...tableHeadingsStyle,
								display:
									tableHeadingsStyle.display === "none" ? "block" : "none",
							}))
						}
					>
						{tableHeadingsStyle.display === "none"
							? " + Columns"
							: " - Columns"}
					</div>
					<div style={tableHeadingsStyle}>
						{somePageOrder[significantItemOfGroupIndex].tableHeadings.map(
							(th, i) => {
								const thDisabled =
									th.for === "item" || th.for === "cost" || th.for === "total"
										? obj.disabled
										: false;
								return (
									<div
										className="checkBoxes"
										key={th.for}
										style={{ margin: "0 0 0 10px" }}
									>
										<input
											id={`${th.for} ${somePageOrder[significantItemOfGroupIndex].group}`}
											onChange={(e) => handleThChange(th, i, e.target.value)}
											type="checkbox"
											value={th.visible === false ? "yes" : "no"}
											checked={th.visible === false ? false : true}
											disabled={thDisabled}
										/>
										<label
											htmlFor={`${th.for} ${somePageOrder[significantItemOfGroupIndex].group}`}
										>
											{th.val}
										</label>
									</div>
								);
							}
						)}
					</div>
					{pageOrderItemSectionTotal && (
						<div
							className="checkBoxes"
							key={
								pageOrderItemSectionTotal.group +
								"-" +
								pageOrderItemSectionTotal.type
							}
							style={{ margin: "0 0 0 10px" }}
						>
							<label
								htmlFor={
									pageOrderItemSectionTotal.group +
									"-" +
									pageOrderItemSectionTotal.type
								}
							>
								Show Total
							</label>{" "}
							<input
								id={
									pageOrderItemSectionTotal.group +
									"-" +
									pageOrderItemSectionTotal.type
								}
								onChange={(e) =>
									handleSectionTotalVisibilityChange(
										pageOrderItemSectionTotal.group,
										pageOrderItemSectionTotal.type,
										e.target.value
									)
								}
								type="checkbox"
								value={
									pageOrderItemSectionTotal.visible === false ? "yes" : "no"
								}
								checked={pageOrderItemSectionTotal.visible}
							/>
						</div>
					)}
				</div>
			);
		} else return null;
	} else if (pageOrderItem.for === "companyInfo") {
		let companyInfoNames = [];
		if (somePageOrder[significantItemOfGroupIndex].val.length) {
			companyInfoNames = [
				"companyName",
				"contractorFullAddress",
				"companyEmail",
				"phone",
				"gstNumber",
			];
		} else {
			companyInfoNames = Object.keys(
				somePageOrder[significantItemOfGroupIndex].val
			);
		}

		return (
			<div key={somePageOrder[significantItemOfGroupIndex].group}>
				<div
					className={"show-table-headings-text"}
					style={{ margin: "0 0 0 5px" }}
					onClick={(e) =>
						setTableHeadingsStyle((tableHeadingsStyle) => ({
							...tableHeadingsStyle,
							display: tableHeadingsStyle.display === "none" ? "block" : "none",
						}))
					}
				>
					{tableHeadingsStyle.display === "none" ? " + Options" : " - Options"}
				</div>
				<div style={tableHeadingsStyle}>
					{companyInfoNames.map((infoType, i) => {
						const hiddenItems =
							somePageOrder[significantItemOfGroupIndex].hiddenItems || [];
						const isInHiddenItemsList = hiddenItems.includes(infoType);
						let displayName = "";
						if (infoType === "companyName") {
							displayName = "Display Company Name";
						}
						if (infoType === "contractorFullAddress") {
							displayName = "Display Address";
						}
						if (infoType === "companyEmail") {
							displayName = "Display Email";
						}
						if (infoType === "phone") {
							displayName = "Display Phone";
						}
						if (infoType === "gstNumber") {
							displayName = "Display GST number";
						}

						return (
							<div
								className="checkBoxes"
								key={i}
								style={{ margin: "0 0 0 10px" }}
							>
								<input
									id={`${i} ${somePageOrder[significantItemOfGroupIndex].group}`}
									onChange={(e) =>
										handleChangeHiddenItems(infoType, i, e.target.value)
									}
									type="checkbox"
									value={isInHiddenItemsList ? "yes" : "no"}
									checked={isInHiddenItemsList ? false : true}
								/>
								<label
									htmlFor={`${i} ${somePageOrder[significantItemOfGroupIndex].group}`}
								>
									{displayName}
								</label>
							</div>
						);
					})}
				</div>
			</div>
		);
	} else return null;
};

// rendered by InvoiceControls
// errors and success text handled except in getInputs function
const ChangeLayoutModal = ({ setLayoutModalOpen }) => {
	const {
		editedInv,
		// setEditedInv,
		handleSetEditedInv,
		// handleSave
	} = useContext(EditInvoiceContext);
	const {userObject} = useContext(UserContext)
	
	const [topSectionPageOrderGroups, setTopSectionPageOrderGroups] = useState(
		[]
	);
	const [newTopSectionPageOrder, setNewTopSectionPageOrder] = useState([]);

	const [pageOrderGroups, setPageOrderGroups] = useState([]);
	const [newPageOrder, setNewPageOrder] = useState([]);
	// const [mustRefresh, setMustRefresh] = useState(false)

	// const topSectionChanges = JSON.stringify(newTopSectionPageOrder) !== JSON.stringify(editedInv.topSectionPageOrder)

	const getGroups = (sectionName) => {
		let groups = [];
		if (sectionName === topSectionPageOrderName) {
			editedInv[sectionName].forEach((item) => {
				if (item.group && !groups.some((obj) => obj.group === item.group)) {
					groups.push({
						group: item.group,
						label: item.label,
						visible: item ? item.visible : false,
					});
				}
			});
		} else {
			// pageOrder groups
			editedInv[sectionName].forEach((item) => {
				if (
					!groups.some((obj) => obj.group === item.group) &&
					item.group !== "totals"
				) {
					let disabled = false;

					if (
						item.group === "payments" &&
						editedInv.paymentEntries.find((row) => row.immutable)
					) {
						// note this does not disable the option to hide headings date and qty
						// only the payments section and under it the item, cost and total tableheadings cannot be removed
						disabled = true;
					}

					const sectionHeadingItemInGroupIndex = editedInv[
						sectionName
					].findIndex(
						(po) =>
							po.group === item.group && item.className === "section-heading"
					);
					const userLabelForGroup =
						sectionHeadingItemInGroupIndex === -1
							? ""
							: editedInv[sectionName][sectionHeadingItemInGroupIndex].val;
					groups.push({
						disabled,
						group: item.group,
						visible: item ? item.visible : false,
						val: userLabelForGroup,
					});
				}
			});
		}

		return groups;
	};

	useEffect(() => {
		// move this to invoice utils ?
		// research if map mutates original array, need to find a better way to
		// keep things immutable and organize this int a function that can be used throughout the app
		const getClone = (originalPageOrder) => {
			let newClonePageOrder = [];
			originalPageOrder.forEach((obj) => {
				newClonePageOrder.push({ ...obj });
			});
			return newClonePageOrder;
		};
		setNewPageOrder(getClone(editedInv[entriesPageOrderName]));
		setNewTopSectionPageOrder(getClone(editedInv[topSectionPageOrderName]));
		setPageOrderGroups(getGroups(entriesPageOrderName));
		setTopSectionPageOrderGroups(getGroups(topSectionPageOrderName));

		// return () => {
		// 	if (mustRefresh) {
		// 		// wait for changes to save to DB need to change this to async await
		// 		// see work around comment below
		// 		window.location.reload()
		// 	}
		// }

		// eslint-disable-next-line
	}, []);

	const onClickOutside = () => {
		setLayoutModalOpen(false);
	};

	const handleSaveChanges = (
		e,
		topSectionPageOrderGroups,
		newTopSectionPageOrder,
		pageOrderGroups,
		newPageOrder /*, closeModal*/
	) => {
		e.preventDefault();

		// setEditedInv(editedInv => ({...editedInv, [topSectionPageOrderName]: newTopSectionPageOrder, [entriesPageOrderName]: newPageOrder}))
		let summaryEntriesChanges = [...editedInv.summaryEntries];
		const materialHeading = newPageOrder.find(
			(item) => item.for === "materialHeading"
		);
		const laborHeading = newPageOrder.find(
			(item) => item.for === "laborHeading"
		);

		// if the table is not visible there should not be a summary entry for it
		if (materialHeading) {
			const materialInSummaryEntriesIndex = summaryEntriesChanges.findIndex(
				(ent) => ent.id === "materialTotals"
			);
			summaryEntriesChanges[materialInSummaryEntriesIndex] = {
				...summaryEntriesChanges[materialInSummaryEntriesIndex],
				visible: materialHeading ? materialHeading.visible : false,
			};
		}
		if (laborHeading) {
			const materialInSummaryEntriesIndex = summaryEntriesChanges.findIndex(
				(ent) => ent.id === "laborTotals"
			);
			summaryEntriesChanges[materialInSummaryEntriesIndex] = {
				...summaryEntriesChanges[materialInSummaryEntriesIndex],
				visible: laborHeading ? laborHeading.visible : false,
			};
		}

		// if (closeModal) {
		handleSetEditedInv({
			changes: {
				[topSectionPageOrderName]: newTopSectionPageOrder,
				[entriesPageOrderName]: newPageOrder,
				summaryEntries: summaryEntriesChanges,
			},
			caller: "ChangeLayoutModal - handleSaveChanges",
		});
		onClickOutside();
	};

	const getInputs = (
		groupArray,
		setGroupArray,
		somePageOrder,
		setSomePageOrder,
		sectionName
	) => {
		let changeLayoutInputs = [];
		groupArray.forEach((obj, i) => {
			let significantItemOfGroupIndex = -1;
			if (sectionName === entriesPageOrderName) {
				significantItemOfGroupIndex = somePageOrder.findIndex(
					(item) => item.group === obj.group && item.type === "table"
				);
			} else if (sectionName === topSectionPageOrderName) {
				significantItemOfGroupIndex = somePageOrder.findIndex(
					(item) => item.group === obj.group && item.label
				);
			}
			// match group name to page order
			let allItemIndexs = [];
			somePageOrder.forEach((pageOrderItem, j) => {
				if (pageOrderItem.group === obj.group) {
					allItemIndexs.push(j);
				}
			});

			// visibility change method
			const handleChange = (nameOrId, val) => {
				let newGroupArray = [...groupArray];
				// have to do visibility with groupArray because we dont want all items with the group name as inputs eg> page spacers
				// and significantItemOfGroupIndex might not be found
				// newGroupArray[i].visible = val === "yes" ? true : false
				newGroupArray[i].visible = val;
				setGroupArray(newGroupArray); // this is either setPageOrderGroups or setTopSection...

				let changedPageOrder = [...somePageOrder];
				// change the visibility of any item with the group name
				allItemIndexs.forEach((num) => {
					// changedPageOrder[num].visible = val === "yes" ? true : false
					changedPageOrder[num].visible = val;
				});
				setSomePageOrder(changedPageOrder);
			};

			if (significantItemOfGroupIndex !== -1) {
				let label;
				if (obj.label) {
					label = obj.label;
				} else if (obj.val) {
					label = obj.val;
				} else {
					label = obj.group;
				}
				if (
					somePageOrder[significantItemOfGroupIndex].type === "table" ||
					somePageOrder[significantItemOfGroupIndex].type === "div-table"
				) {
					if (
						somePageOrder[significantItemOfGroupIndex].tableHeadings.length < 2
					) {
						label =
							somePageOrder[significantItemOfGroupIndex].tableHeadings[0].val;
					}
				}
				let inputType = "checkbox";
				let name = null;
				// let value = obj.visible ? "no" : "yes"
				let value = (obj && obj.visible) ? false : true;
				let checked = (obj && obj.visible) ? true : false;
				// make all disabled checkboxes checked
				// let checked = obj.disabled ? true : obj.visible
				let disabled = obj.disabled;
				const needsMoreInfoIcon = () => {
					if (disabled) {
						return {
							moreInfoIcon: {
								before: " ",
								absolute: true,
								// className: isLessThan700px ? "text-width-mobile" : "text-width",
								text:
									obj.group === "payments"
										? "You can't remove the payments section once an In App payment has been made"
										: "Can't remove this section",
							},
						};
					} else return {};
				};

				changeLayoutInputs = [
					...changeLayoutInputs,
					{
						label,
						onChange: handleChange,
						properties: {
							type: inputType,
							id: `${obj.group}${obj.type}`,
							name,
							value,
							checked,
							disabled,
						},
						...needsMoreInfoIcon(),
						afterInput: [
							<ShowTableHeadingsText
								obj={obj}
								key={obj.group}
								somePageOrder={somePageOrder}
								setSomePageOrder={setSomePageOrder}
								significantItemOfGroupIndex={significantItemOfGroupIndex}
								pageOrderName={sectionName}
								userObject={userObject}
								editedInv={editedInv}
							/>,
						],
					},
				];
			} else {
				if (obj.group !== "invNumberPageSpacer") {
					// setErrorObj(errorObj => ({message: "Error: item not found in page order", type: "Page order Error"}))
					// causing infinite re render, cant catch errors here
				}
			}
		});
		return changeLayoutInputs;
	};
	return (
		<Modal custom={{ absolute: true }} onClickOutside={onClickOutside}>
			<ChangeLayoutModalStyle>
				<AppMessage />
				{(topSectionPageOrderGroups.length && pageOrderGroups.length) ? (
					<Form
						inputs={[
							{
								label: "Show Invoice Status",
								onChange: (name, val) => {									
									handleSetEditedInv({
										changes: {
											hideStatus: !val,
										},
										caller:
											"ChangeLayoutModal - handleChangeInvoiceStatus",
									})
								},
								properties: {
									type: "checkbox",
									id: "invoice-status",
									name: "hideStatus",
									value: editedInv.hideStatus ? true : false,
									checked: !(editedInv.hideStatus || false),
								},	
							},
							...getInputs(
								topSectionPageOrderGroups,
								setTopSectionPageOrderGroups,
								newTopSectionPageOrder,
								setNewTopSectionPageOrder,
								topSectionPageOrderName
							),
							...getInputs(
								pageOrderGroups,
								setPageOrderGroups,
								newPageOrder,
								setNewPageOrder,
								entriesPageOrderName
							),
							{
								label: "Total Cost",
								onChange: (name, val) => {
									handleSetEditedInv({
										changes: {
											[name]: val,
										},
										caller:
											"ChangeLayoutModal - handleChangeTotalCostVisibility",
									});
								},
								properties: {
									type: "checkbox",
									id: "showTotalCost",
									name: "showTotalCost",
									// if undefined default to true
									value: editedInv.showTotalCost === false ? true : false,
									checked: editedInv.showTotalCost === false ? false : true,
								},
							},
							{
								label: "Total Owing",
								onChange: (name, val) => {
									handleSetEditedInv({
										changes: {
											[name]: val,
										},
										caller:
											"ChangeLayoutModal - handleChangeTotalOwingVisibility",
									});
								},
								properties: {
									type: "checkbox",
									id: "showTotalOwing",
									name: "showTotalOwing",
									// if undefined default to true
									value: editedInv.showTotalOwing === false ? true : false,
									checked: editedInv.showTotalOwing === false ? false : true,
								},
							},
						]}
						heading={<div className="heading">Change Invoice Layout</div>}
						submitName="Ok"
						// onSubmit={e => handleSaveChanges(e, topSectionPageOrderGroups, newTopSectionPageOrder, pageOrderGroups, newPageOrder, !topSectionChanges)}
						onSubmit={(e) =>
							handleSaveChanges(
								e,
								topSectionPageOrderGroups,
								newTopSectionPageOrder,
								pageOrderGroups,
								newPageOrder
							)
						}
					></Form>
				) : ""}
			</ChangeLayoutModalStyle>
		</Modal>
	);
};

export default ChangeLayoutModal;
