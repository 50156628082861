import React  from 'react'
// import PropTypes from 'prop-types'
import { InvoicePageStyle } from '../InvoiceStyle.styled';

// rendered by renderingUtils.js
const InvoicePage = ({paperDetails, children, id}) => {

	// const padding = paddingArray.toString().replace(/,+/g, "vw ") + "vw"
	const invContentWidthPx = (paperDetails.screenPageWidthPx - (paperDetails.paddingLeftRight * 2)) /*/ paperDetails.printablePaperWidthToScreenWidthRatio*/

	const invContentHeightPx = (paperDetails.screenPageHeightPx - (paperDetails.paddingTopBottom * 2)) /*/ paperDetails.printablePaperWidthToScreenWidthRatio*/

	const resultPaddingTopBottom = 
		paperDetails.printAction ? paperDetails.paddingTopBottom * 0.5
		: paperDetails.paddingTopBottom

	const resultPaddingLeftRight = 
		paperDetails.printAction ? paperDetails.paddingLeftRight * 0.5
		: paperDetails.paddingLeftRight

	// console.log(paperDetails.screenPageWidthPx, paperDetails.screenPageHeightPx, paperDetails.screenPageHeightPx / paperDetails.screenPageWidthPx)
	// console.log(invContentHeightPx + (paperDetails.paddingTopBottom * 2), paperDetails.screenPageHeightPx)

	return (
		<InvoicePageStyle 
			margin={paperDetails.margin}
			paddingTopBottom={resultPaddingTopBottom / paperDetails.printablePaperWidthToScreenWidthRatio}
			paddingLeftRight={resultPaddingLeftRight / paperDetails.printablePaperWidthToScreenWidthRatio}
			screenPageWidthPx={paperDetails.screenPageWidthPx / paperDetails.printablePaperWidthToScreenWidthRatio}
			invContentWidthPx={invContentWidthPx / paperDetails.printablePaperWidthToScreenWidthRatio}
			invContentHeightPx={invContentHeightPx / paperDetails.printablePaperWidthToScreenWidthRatio}
			rowHeight={paperDetails.rowHeight}
			scaleRatio={paperDetails.printablePaperWidthToScreenWidthRatio}
			className="invoice-page"
			id={id}
		>
			{children}
		</InvoicePageStyle>
	)
}


export default InvoicePage