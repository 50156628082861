import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'

// import { useStripe } from '@stripe/react-stripe-js'

import { EditInvoiceContext } from '../contexts/EditInvoiceContext'
import { UserContext } from '../contexts/UserContext'
import { DialogContext } from "../contexts/DialogContext";


import Invoice from '../components/Invoice/Invoice'
import InvoiceSummary from '../components/Invoice/InvoiceSummary/InvoiceSummary'
import ChangeBillToModal from '../components/Invoice/ChangeBillToModal'
import Modal from '../components/Modal/Modal'
import AddSummaryEntryModal from '../components/Invoice/AddSummaryEntryModal/AddSummaryEntryModal'
import AddInvoicePaymentModal from '../components/Invoice/AddInvoicePaymentModal/AddInvoicePaymentModal'
import ViewPaymentModal from '../components/Invoice/ViewPaymentModal/ViewPaymentModal'
import ForwardBillModal from '../components/ForwardBill/ForwardBillModal';
import MoreInfoIcon from '../components/MoreInfoIcon'


// import Spinner from '../components/Spinner/Spinner'

import firebase from '../firebase/index'
// import { logInAnonymously } from '../firebase/auth'
import { getDefaultEntries, makeCopyOfInvoice } from '../components/Invoice/invoiceUtils'
import { handleAddFollower, handleUnfollow, checkFollowOrEditRequest } from '../utils/followUtils'
import { getNum, removeDuplicates, deepObjectCompareDiffers } from '../utils/appUtils'
import { getLocalISODate } from '../utils/dateUtils'


const SpecificInvoiceStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	.main-invoice-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;		
	}

	.copy-invoice {
		cursor: pointer;
		position: fixed;
		// top: 20%;
		right: 0;
		z-index: 1;
		margin-bottom: 50px;
		margin-right: 20px;
		box-shadow: 0px 4px 31px -14px;
		border-radius: 50%;
		> div {
	    text-align: center;
			font-size: var(--font-size-xxs);
	    height: 40px;
	    width: 40px;
			background-color: #27c727;
	    border-style: outset;
	    border-color: #27c727;
			color: var(--white-text);
			border-radius: 50%;
			width: 70px;
			height: 70px;
			display: flex;
	    flex-flow: column;
	    align-items: center;
	    justify-content: center;
			padding: 5px;
			svg {
				height: 20px;
				width: 20px;
			}
		}
	}

	.forward-bill {
		display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    > h4 {
    	margin-left: 10px;
    	margin-right: 5px;
    }
		> svg {
			margin-left: 10px;
			height: 15px;
			padding: 10px;
		}
		svg {
			vertical-align: middle;
		}
		> .clickable {
			display: inline-block;
			border-radius: 50%;
			padding: 5px;
	    box-shadow: 5px 6px 16px -5px;
	    height: 40px;
	    width: 40px;
			background-color: #27c727;
	    border-style: outset;
	    border-color: #27c727;
			color: var(--white-text);
			div {
				border-radius: 50%;
				padding: 0;
				height: 100%
			}	
			p {
				font-size: var(--font-size-xxs);
				padding: 0;
			}	\
		}
	}

`

const useQuery = () => new URLSearchParams(useLocation().search)


// rendered by: App.js
// Errors and successText handled
const SpecificInvoice = ({stripePublicKey}) => {
	let query = useQuery()
	const history = useHistory()
	const { invoiceId } = useParams(); // company param has no affect, it is for the visuals

	const { dialog } = useContext(DialogContext);
	const {
		inv,
		handleSetInv,
		editedInv, 
		handleSetEditedInv,
		setUserCanEdit,
		userIsOwner,
		setUserIsOwner,
		// userIsFollower,
		setUserIsFollower,
		userCanEdit,
		userCanView,
		setUserCanView,
		followersUserObjs,
		changeBillToModalOpen,
		setChangeBillToModalOpen,
		addSummaryEntryModal,
		setWorkerIsWorking,
		addPaymentModal, 
		setAddPaymentModal,
		verifiedPaymentModalData,
		// billSnapshotListeners,
		// setBillSnapshotListeners,
		lastSavedVersion,
		setErrorObj,
		handleSetSuccessText,
		invoiceTotalStr,
		invoiceTotals,
		handleSave,
	} = useContext(EditInvoiceContext)

	const {
		currentUser,
		userObject,
		setUserObject,
		updateUserData,
		usersInvoices,
		getUsersInvoices,
		usersProjects,
		getUsersProjects,
		isFetching,
		setIsFetching,
		handleSetMessage,
		loginModalOpen,
	} = useContext(UserContext)

	const [isNewDoc] = useState((window.history.state &&  window.history.state.state) ? window.history.state.state.isNewDoc : false)
	const [successText] = useState((window.history.state &&  window.history.state.state) ? window.history.state.state.successText : false)
	// const [followText, setFollowText] = useState("Follow")
	const [showInvoiceSummary, setShowInvoiceSummary] = useState(false)
	// const [acceptBillModal, setAcceptBillModal] = useState({open: false})
	// const [recentPaymentInfo, setRecentPaymentInfo] = useState({
	// 	paymentSuccess: query.get("paymentSuccess"),
	// 	session_id: query.get("session_id"),
	// 	paid: query.get('amount')
	// })
	// const [paymentSuccessListener, setPaymentSuccessListener] = useState(null)
	const [addedToRecentlyViewed, setAddedToRecentlyViewed] = useState(false)
  const [forwardBillModal, setForwardBillModal] = useState({open: false, invoice: {}})
	const [forwardedBill, setForwardedBill] = useState({})
	const [invoiceLoaded, setInvoiceLoaded] = useState(false)


	const getFollowState = (editedInv, userObject, foundFollowNotification, fetchingFollowNotification) => {
		let state = {
			followText: "Follow", 
			followButtonDisabled: true
		}

		if (!userObject.id || !invoiceLoaded) {
			return state
		}


		if (editedInv.followers && editedInv.followers.includes(userObject.id)) {
			state.followText = "Unfollow"
			state.followButtonDisabled = false
			return state
		}

		if (fetchingFollowNotification) {
			return state
		}

		if (editedInv.id === "notFound") {
			state.followText = "Follow"
			state.followButtonDisabled = true
			return state
		}

		if (editedInv.visibility === "private") {
			// if no notification found and not fetching, initiate fetching
			if (!foundFollowNotification && !fetchingFollowNotification) {
				setFetchingFollowNotification(true)
				firebase
					.firestore()
					.collection("notifications")
					.where("accessors", "array-contains", userObject.id)
					.where("forDocumentId", "==", invoiceId)
					.get()
					.then(snapshot => {
						if (snapshot.docs.length) {
							const doc = snapshot.docs[0]
							setFoundFollowNotification({...doc.data(), id: doc.id})
						} else {
							setFoundFollowNotification({empty: true})
						}

						setFetchingFollowNotification(false)
					}).catch(err => { //must catch and set follow notification to empty
						console.log("error getting follow notification")
						console.error(err)
						setFoundFollowNotification({empty: true})
						setFetchingFollowNotification(false)
					})
			}

			// if notification found, set request sent
			if (foundFollowNotification) {
				if (foundFollowNotification.empty) {
					state.followText = "Follow"
					state.followButtonDisabled = false
				} else { // has sent notification
					state.followText = "Request Sent"
					state.followButtonDisabled = true
				}
			}

		}

		if (invoiceLoaded && editedInv.visibility === "public") {
			state.followText = "Follow"
			state.followButtonDisabled = false
			// terminate fetching and set follow notification to prevent fetching
			if (!setFoundFollowNotification) {
				setFoundFollowNotification({empty: true})
			}
			if (fetchingFollowNotification) {
				setFetchingFollowNotification(false)
			}
		}

		return state

	}


	const [fetchingFollowNotification, setFetchingFollowNotification] = useState(false)
	const [foundFollowNotification, setFoundFollowNotification] = useState(false)
	const [followText, setFollowText] = useState("Follow")
	const [followButtonDisabled, setFollowButtonDisabled] = useState(null)

	// must use state here becuase of infinite rerenders
	useEffect(() => {
		const state = getFollowState(editedInv, userObject, foundFollowNotification, fetchingFollowNotification)
		if (followText !== state.followText) {
			setFollowText(state.followText)
		}

		if (followButtonDisabled !== state.followButtonDisabled) {
			setFollowButtonDisabled(state.followButtonDisabled)
			
		}
	  
	  // not including getFollowState
		// putting userObject in dep array causes unlimited rerender for some reason
		// eslint-disable-next-line
	}, [editedInv, foundFollowNotification, fetchingFollowNotification, followText, followButtonDisabled])
	// const followText = "Follow"
	// const followButtonDisabled = true

	const followButton = <button 
		key={followText}
		className="button-appearance small white" 
		disabled={followButtonDisabled}
		onClick={async () => {
			try {
				const isFollowing = followText === "Unfollow"
				if (isFollowing) {
					if (editedInv.followers && userObject.id) {
						const newFollowers = editedInv.followers.filter(f => f.id === userObject.id)
						handleSetEditedInv({mergeIntoHistory: true, changes: {followers: newFollowers}, caller: "SpecificInvoice - unfollow"})
					}
					return handleUnfollow({userId: currentUser.uid, collection: "invoices", userIsOwner, docId: editedInv.id, docEditors: editedInv.editors, dialog})
				}

				setFollowButtonDisabled(true)
				const followSuccess = await handleAddFollower({userId: currentUser ? currentUser.uid : "", username: userObject.username, collection: "invoices", parentResource: {...editedInv, id: invoiceId}})
				if (followSuccess) {
					handleSetSuccessText(followSuccess)
					if (followSuccess !== "Request sent") {
						handleSetEditedInv({mergeIntoHistory: true, changes: {followers: [...editedInv.followers, currentUser.uid]}, caller: "SpecificInvoice - handleFollow in followSuccess"})
					} else {
						setFoundFollowNotification({empty: false, followSuccess})
					}
				} else {
					setFollowButtonDisabled(false)
				}
			} catch(err) {
				setFollowButtonDisabled(false)
				setErrorObj(err)
			}
		}} >
		{followText}
	</button>


	const handleCopyInvoice = async (e) => {
		// await logInAnonymously()
		setIsFetching(true)
		const fd = await makeCopyOfInvoice({
			i: 0,
			type: "fullCopy",
			inv: {...editedInv, isSampleInvoice: false},
			whenLoading: () => null,
			userObject,
			includeEditors: false,
			includeFollowers: false,
			includeBillTo: false,
			includeForProject: false,
			setUserObject,
		}).catch(err => {
			throw err
		})
		const successText = `You are now viewing a copy of Invoice #${editedInv.invNumber} (${editedInv.invShortHand})`
		history.push(`/${fd.contractor.username}/${fd.id}`, {isNewDoc: true, successText});
		setIsFetching(false)
		window.location.reload()

	}

  const evaluatePendingOperations = (str) => {
    let pendingOpsArray = JSON.parse(str)
    if (pendingOpsArray.includes("setAcceptBillModalOpenTrue")) {
    	setAddPaymentModal({open: true})
    	pendingOpsArray.pop("setAcceptBillModalOpenTrue")
    	if (!pendingOpsArray.length) {
	    	localStorage.setItem('linvoPendingOps', '')
    	} else {
	    	localStorage.setItem('linvoPendingOps', JSON.stringify(pendingOpsArray))
    	}
    }
    // ....
    // if (str.includes("settingsModalOpen") ) {}
  }

	const getRecentUserData = (id) => {
		return firebase.firestore().collection("users").doc(id).get()
		.then(doc => ({...doc.data(), id: doc.id}))
		.catch(err => setErrorObj(err))
	}

	// const getBillReceiptTotals = (billId) => {
	// 	return firebase.firestore().collection("invoices").doc(billId).get().then(doc => {
	// 		return doc.data().invoiceTotals
	// 	})
	// }

	const handleSortEntries = useCallback((entries, newEditedInv, subCollectionName) => {
		// sort and or add default entries
		let newEntries = (entries && entries.length) ? [...entries] : getDefaultEntries(subCollectionName, newEditedInv.industry, newEditedInv.rate, invoiceId)

		// sort the entries by date
		newEntries = newEntries.sort((a, b) => {
			const d1 = new Date(a.values[0].val)
			const d2 = new Date(b.values[0].val)
			return d1 - d2
		})

		return newEntries
	}, [invoiceId])

	const getSubCollectionEntries = async ({subCollectionName, invoiceId, originalInv, editedInvoice}) => {
		try {
			let newInvoice = {...originalInv}
			let newEditedInv =  editedInvoice ? {...editedInvoice} : {...originalInv}

			const snapshot = await firebase.firestore().collection(`invoices/${invoiceId}/${subCollectionName}`).get()

			let entries = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))

			const handleEmptyValues = (entries) => {
				// if the value is no value this could be a forwarded invoice that has been updated recently 
				// since we cannot update certain ways in firestore we have the values under row.values. and under row.
				const newEntries = entries.map(ent => {
					let newEntry = {...ent}
					if (ent.values && ent.values.length) {
						const newValues = ent.values.map(obj => {
							// handle replacing entries that were default in a levied invoice
							if (((obj.val === undefined || obj.val === null) && obj.for) || (newEditedInv.pairedInvoices && newEditedInv.pairedInvoices.length)) {
								// obj.val should be null if a paired invoice had no labor entries then adds one
								// determine val from other places
								let foundVal = ent[obj.for]
								// handle subcontractor adding a new row in a forwarded bill... rate update
								if (subCollectionName === "laborEntries") {
									// always update the total
									// if (foundVal === undefined || foundVal === null || obj.for === "total") {
										if ((foundVal === undefined || foundVal === null) && obj.for === "cost") {
											foundVal = newEditedInv.rate
										}	
										if (obj.for === "total") {
											// find the resulting cost and qty then make the total
											const costTd = ent.values.find(td => td.for === "cost")
											const qtyTd = ent.values.find(td => td.for === "qty")

											// let qty = qtyTd.val || ent[qtyTd.for] || 0
											let qty = qtyTd.val
											if (qty === undefined || qty === null) {
												qty = ent.qty
											}
											if (qty === undefined || qty === null) {
												qty = 0
											}

											let rate = costTd.val
											if (rate === undefined || rate === null) {
												// bug with invoice levying was below
												rate = ent.cost
											}
											if (rate === undefined || rate === null) {
												rate = newEditedInv.rate || 0
											}											

											foundVal = getNum(parseFloat(rate) * parseFloat(qty), 2)
										}
									// }
								}
								return {
									...obj,
									val: foundVal
								}
							} else return obj
						})

						newValues.forEach(td => {
							newEntry[td.for] = td.val
						})

						return {
							...newEntry,
							values: newValues
						}
					} else return ent
				})

				return newEntries
			}

			// replace empty values and update totals
			entries = handleEmptyValues(entries)
			// setInv(...originalInv, [subCollectionName]: entries)
			if (subCollectionName === "paymentEntries") {
				// find verifiedPayments which returns full invoice objects
				const verifiedPaymentEntries = await firebase.firestore().collection(`invoices/${invoiceId}/payments`).get()
				if (verifiedPaymentEntries.docs.length) {
					// return the full new invoice objects

					const {newPageOrder, newPaymentEntries, newPayments} = handlePaymentEntries(entries, newEditedInv, verifiedPaymentEntries) 

					return {
						newInvoice: {
							...newInvoice,
							pageOrder: newPageOrder,
							paymentEntries: newPaymentEntries,
							payments: newPayments
						},
						newEditedInv: {
							...newEditedInv,
							pageOrder: newPageOrder,
							paymentEntries: newPaymentEntries,
							payments: newPayments
						}
					}
				}

				// else handle entries as normal
			}

			if (subCollectionName !== "receipts") {
				// sort entries by date and add default entries if no entries
				entries = handleSortEntries(entries, newEditedInv, subCollectionName)
			}

			newInvoice[subCollectionName] = entries
			newEditedInv[subCollectionName] = entries

			return {
				newInvoice,
				newEditedInv
			}

		} catch (err) {
			setIsFetching(false)
			throw err
		}
		// })
	}

	// returns new paymentEntries, new pageOrder and the found immutable payments 
	const handlePaymentEntries = useCallback((entries, newEditedInv, verifiedPaymentEntries) => {
		let newEntries = entries ? [...entries] : [...newEditedInv.paymentEntries]
		// let newInvoice
		// let newEditedInv
		let newPageOrder = []
		let newPayments = []

		try {
			// const verifiedPaymentEntries = await firebase.firestore().collection(`invoices/${invoiceId}/payments`).get()
			if (!verifiedPaymentEntries.docs.length) {
				console.log("error no verifiedPaymentEntries.docs.length", verifiedPaymentEntries)
				return {
					newPageOrder,
					newPaymentEntries: newEntries,
					newPayments
				}
			}

			const formatVerifiedPayments = (newEntries, newPayments) => {
				// map through verified payments and add or update them in entries 
				let resEntries = [...newEntries]
				newPayments.forEach(p => {
					// if (p.status !== "succeeded") {
					// 	console.log("todo: get the payment info from stripe from the id because status is not succeeded")
					// }
					let dateVal
					let itemVal
					let qtyVal
					let costVal
					let totalVal
					let itemValType

					if (p.type === "stripe") { // only stripe payments have chargeIds
						let suffix = ""
						let last4 = ""
						if (p.payment_method && p.payment_method.type) {
							if (p.payment_method.type === "card") {
								suffix = p.payment_method.card ? p.payment_method.card.brand : ""
								last4 = p.payment_method.card.last4
							}
							if (p.payment_method.type === "acss_debit") {
								suffix = p.payment_method.acss_debit ? "debit" : ""
								last4 = p.payment_method.acss_debit.last4
							}
						} else {
							suffix = p.card ? (p.card.brand || "") : ""
							last4 = p.card ? (p.card.last4 || "") : ""
						}

						if (suffix && suffix.replace(/\s/g, "").toLowerCase() === "mastercard") {
							suffix = "mc"
						}

						dateVal = getLocalISODate(false, parseFloat(p.created) * 1000)
						itemVal = `${last4 || "****"} ${suffix || "credit"}`
						qtyVal = 1
						costVal = parseFloat(p.amount) / 100
						totalVal = parseFloat(p.amountReceived) / 100

						if (p.refunded) {
							dateVal = getLocalISODate(false, p.refundedDate ? parseFloat(p.refundedDate) * 1000 : "")
							itemVal = `${suffix || "credit"} refunded`
							costVal = (parseFloat(p.amount) - parseFloat(p.amount_refunded)) / 100
							totalVal = (parseFloat(p.amount) - parseFloat(p.amount_refunded)) / 100
						}
					} else {
						// must be cash etransfer cheque or other?
						dateVal = p.receivedDate || getLocalISODate(false, parseFloat(p.created) * 1000)
						itemVal = p.paymentName
						qtyVal = 1
						costVal = parseFloat(p.amount) / 100
						totalVal = parseFloat(p.amountReceived) / 100
						itemValType = p.type
					}

					if (!p.type) {
						console.warn("no p.type! check stripe checkoutSessionCompletedWebhook type is hard coded to 'stripe'")
					}

					if (p.status !== "succeeded") {
						if (p.status === "failed") {
							itemVal = `${p.status.slice(0,7)}`
							// costVal = 0
							totalVal = 0
							qtyVal = 0
						} else if (p.status === "pending" || p.status === "processing") {
							itemVal = `${p.status.slice(0,7)} ...`
							qtyVal = 0
							totalVal = 0
						} else if (p.status === "needs_confirmation") {
							itemVal = p.paymentName
							qtyVal = 0
						} else {
							console.log("unknown status ", p.status, p)
							itemVal = `${p.status.slice(0,7)} ...`
						}
					}

					// apply discounts
					if (p.discount) {
						totalVal = totalVal + (parseFloat(p.discount) / 100)
					}

					let resValues = [
						{for: "date", val: dateVal},
						{for: "item", val: itemVal, ...itemValType && {type: itemValType}},
						{for: "qty", val: qtyVal},
						{for: "cost", val: getNum(costVal, 2)},
						{for: "total", val: getNum(totalVal, 2), noEdit: true}
					]

					let newEntry = {
						id: p.id,
						...p.receipt_url && {url: p.receipt_url},
						verifiedPayment: true,
						immutable: true,
						refunded: p.refunded || false,
						values: resValues,
						status: p.status
					}

					// handle if verified payments already in entries
					const existingEntryIndex = resEntries.findIndex(ent => ent.id === p.id) 
					if (existingEntryIndex !== -1) {
						// replace the entry with new p data
						resEntries[existingEntryIndex] = newEntry
					} else {
						resEntries.push(newEntry)
					}

				})

				return resEntries.filter(ent => ent.status !== "failed")
			}

			// handle pending payments			
			// const {paymentSuccess, paid, session_id} = recentPaymentInfo
			// if (session_id && paymentSuccess === "true") {
			// 	// add a pending payment to the invoice
			// 	let pendingEntry = {
			// 		id: session_id,
			// 		url: "",
			// 		verifiedPayment: true,
			// 		immutable: true,
			// 		values: [
			// 			{for: "date", val: getLocalISODate()},
			// 			{for: "item", val: "Getting receipt..."},
			// 			{for: "qty", val: 1},
			// 			{for: "cost", val: getNum(parseFloat(paid) / 100, 2)},
			// 			{for: "total", val: getNum(parseFloat(paid) / 100, 2)}
			// 		]
			// 	}
			// 	const existingEntryIndex = newEntries.findIndex(ent => ent.id === pendingEntry.id)
			// 	if (existingEntryIndex !== -1) {
			// 		// replace the entry
			// 		newEntries[existingEntryIndex] = pendingEntry
			// 	} else {
			// 		newEntries.push(pendingEntry)
			// 	}
			// }

			newPayments = verifiedPaymentEntries.docs.map(doc => ({...doc.data(), id: doc.id}))

			newEntries = formatVerifiedPayments(newEntries, newPayments)

			// change pageOrder to make and payment sections visible if not already
			newEditedInv.pageOrder.forEach(item => {
				if (item.group === "payments") {
					let newTableHeadings = []
					if (item.tableHeadings) {
						item.tableHeadings.forEach(th => {
							if (th.for === "item" || th.for === "cost" || th.for === "total") {
								newTableHeadings.push({...th, visible: true})
							} else {
								newTableHeadings.push(th)
							}
						})
						newPageOrder.push({...item, visible: true, tableHeadings: newTableHeadings})
					} else {
						newPageOrder.push({...item, visible: true})
					}
				} else {
					newPageOrder.push(item)
				}
			})

			newEntries = handleSortEntries(newEntries, newEditedInv, "paymentEntries")
			return {
				newPageOrder,
				newPaymentEntries: newEntries,
				newPayments
			}
		} catch (err) {
			setErrorObj(err)
		}

	}, [handleSortEntries, setErrorObj])


	// clear url state 
	useEffect(() => {
	  if (isNewDoc) {
	  	window.history.state.state = {}
	  }
	  if (successText) {
	  	handleSetSuccessText(successText)
	  	window.history.state.state = {}
	  }
	  // eslint-disable-next-line
	}, [])

	useEffect(() => {
		// update the contractors address if need be. This should only be updated if the contractorFullAddress property is not hidden
	  if (invoiceLoaded && userObject && editedInv && userObject.id === editedInv.owner && userObject.private && !editedInv.closed) {
	  	if (userObject.private.address && userObject.private.address.addressString) {
  			let newPageOrder = [...editedInv.topSectionPageOrder]
	  		let newCompanyInfoObj = newPageOrder.find(obj => obj.for === "companyInfo")
	  		if (!newCompanyInfoObj.hiddenItems || !newCompanyInfoObj.hiddenItems.includes("contractorFullAddress")) {
	  			if (newCompanyInfoObj.val.contractorFullAddress !== userObject.private.address.addressString) {
		  			newCompanyInfoObj.val = {
	  					...newCompanyInfoObj.val,
	  					contractorFullAddress: userObject.private.address.addressString,
	  				}
		  			handleSave({handleSetSuccessText: () => null, newEditedInv: {...editedInv, topSectionPageOrder: newPageOrder}})
						// handleSetEditedInv({mergeIntoHistory: false, changes: {topSectionPageOrder: newPageOrder}, caller: "SpecificInvoice - useEffect for updating address"})
	  			}
	  		}
	  		
	  	}
	  }
	}, [userObject, editedInv, handleSave, editedInv.owner, editedInv.pageOrder, invoiceLoaded])

	useEffect(() => {
		setIsFetching(true)
    const pendingOperations = localStorage.getItem("linvoPendingOps")

		if (pendingOperations) {
			evaluatePendingOperations(pendingOperations)
		}

		const getAndUpdateInvoiceUserData = async (newInvoice, newEditedInv) => {
			// find the indexes of all the properties in topSectionPageOrder that need up to date user information
			// change the topSectionPageOrder to the updated values if invoice is not closed
			// only update usernames and logoUrl if invoice closed 
			// update the actual billTo logoUrl and contractor sections of invoice

			// we dont need to update the topSectionPageOrder billTo index because it updates automatically ?
			let newTopSectionPageOrder = [...newInvoice.topSectionPageOrder]
			const indexOfLogo = newTopSectionPageOrder.findIndex(item => item.for === "logo")
			const indexOfCompanyInfo = newTopSectionPageOrder.findIndex(item => item.for === "companyInfo")
			// const indexOfBillTo = ??? 

			const {
				companyEmail, 
				companyName, 
				// address, 
				// addressString,
				phone, 
				id, 
				logoUrl, 
				username,
				firstname,
				lastname,
				gstNumber,
			} = await getRecentUserData(newEditedInv.contractor.id)
			let newBillToFullUserObj = newEditedInv.billTo.uid ? await getRecentUserData(newEditedInv.billTo.uid) : newEditedInv.billTo
			let newBillToData = {}
			let updatedInvoiceObjects = {}
			// if invoice is closed only update the contractors logoURL and all usernames
			if (newInvoice.closed) {
				// update topSectionPageOrder
				newTopSectionPageOrder[indexOfLogo] = {...newTopSectionPageOrder[indexOfLogo], url: logoUrl}

				if (newBillToFullUserObj && newBillToFullUserObj.username) {
					// update the username of the billTo
					newBillToData = {
						...newInvoice.billTo, 
						...newBillToFullUserObj.username && {username: newBillToFullUserObj.username},
						...newBillToFullUserObj.logoUrl && {logoUrl: newBillToFullUserObj.logoUrl},
					}
				} else {
					// dont update anything
					newBillToData = {...newInvoice.billTo}
				}

				updatedInvoiceObjects = {
					contractor: {...newInvoice.contractor, logoUrl, username},
					topSectionPageOrder: [...newTopSectionPageOrder],
					billTo: {...newInvoice.billTo, ...newBillToData}
				}

			} else { // update all the users information

				// find out what items to exclude
				let newCompanyInfoObj = {
					companyName: companyName || firstname + " " + lastname,
					// contractorFullAddress: addressString || "",
					companyEmail: companyEmail || "",
					phone: phone || "",
					gstNumber: gstNumber ? `GST #${gstNumber}` : "",
				}


				// newCompanyInfoVal = newCompanyInfoVal.filter(isTruthy => isTruthy)
				newTopSectionPageOrder[indexOfLogo] = {...newTopSectionPageOrder[indexOfLogo], url: logoUrl, val: companyName}

				if (!newBillToFullUserObj) {
					// remove the billto
					newBillToFullUserObj = {...newInvoice.billTo, username: "", uid: ""}
				}

				newTopSectionPageOrder[indexOfCompanyInfo] = {...newTopSectionPageOrder[indexOfCompanyInfo], val: {...newTopSectionPageOrder[indexOfCompanyInfo].val, ...newCompanyInfoObj}} 

				newBillToData = {
					...newBillToFullUserObj.username && {username: newBillToFullUserObj.username},
					...newBillToFullUserObj.logoUrl && {logoUrl: newBillToFullUserObj.logoUrl},
				}

				updatedInvoiceObjects = {
					contractor: {
						// dont spread the new contractor object in case a user is trying to overwrite 
						// their eg companyEmail to an enpty string
						// ...newInvoice.contractor || {},
						companyEmail: companyEmail || "",
						companyName: companyName || "", 
						// address: address || {}, 
						// addressString: addressString || "", 
						id, 
						logoUrl: logoUrl || "", 
						phone: phone || "", 
						username, 
						gstNumber: gstNumber || "",
					},
					topSectionPageOrder: [...newTopSectionPageOrder],
					billTo: {...newInvoice.billTo, ...newBillToData}
				}

			}

			// note on comparing objects with JSON.stringify need to have obj in same order as the db
			// if there is updated data...
			if (JSON.stringify({...newInvoice}) !== JSON.stringify({...newInvoice, ...updatedInvoiceObjects})) {
				// if user is owner or contractor (and can edit) update the invoice 
				if (currentUser && newEditedInv.editors.includes(currentUser.uid) && (currentUser.uid === newEditedInv.contractor.id || currentUser.uid === newEditedInv.owner)) {
					firebase.firestore().collection("invoices").doc(invoiceId).update({
						...updatedInvoiceObjects
					}).catch(err => setErrorObj({...err, message: err.message+" error in updating users data when retrieving invoice data" }))
				}

				return {
					inv: {
						// ...inv,
						...newInvoice,
						...updatedInvoiceObjects
					},
					editedInv: {
						// ...editedInv,
						...newEditedInv,
						...updatedInvoiceObjects
					}
				}
			// if all the data is current ...
			} else {
				// setInv(inv => ({...inv, ...newInvoice}))
				// setEditedInv(editedInv => ({...editedInv, ...newEditedInv}))
				return {
					inv: newInvoice,
					editedInv: newEditedInv
				}
			}

		}

		// let unsubscribeReceipts = () => null

		const summaryEntriesHaveFormulas = (summaryEntries) => {
			if (!summaryEntries.length) {
				return false
			}
			try {
				const materialRow = summaryEntries.find(ent => ent.id === "materialTotals")
				const materialItemTd = materialRow.values.find(td => td.for === "item")
				const laborRow = summaryEntries.find(ent => ent.id === "laborTotals")
				const laborItemTd = laborRow.values.find(td => td.for === "item")
				// itemTd.val should be something like "= editedInv.pageOrder.0.val"
				if (materialItemTd.val.includes("=") && laborItemTd.val.includes("=")) {
					return true
				} else return false
			} catch (err) {
				console.log("error in summaryEntriesHaveFormulas", err)
				return false
			}
		}

		const replaceValuesWithFormulas = (invoiceDoc) => {
			// changes pageOrder values to be the vals in the invoiceDoc
			// so when the invoice doc val changes they will automatically update
			// let newInvoiceDoc = {}
			let newTopSectionPageOrder = []
			const hasSummaryEntriesInPageOrder = invoiceDoc.pageOrder.findIndex(obj => obj.for === "summaryEntries")
			let addSummaryEntries = []

			let newSummaryEntries = []
			if (summaryEntriesHaveFormulas(invoiceDoc.summaryEntries)) {
				newSummaryEntries = invoiceDoc.summaryEntries
			} else { // failsaife in case unless there are no formulas. summaryEntries array should always have length... should only be for old docs
				invoiceDoc.summaryEntries.forEach(ent => {
					if (ent.id === "materialTotals") {
						let materialSummaryVisible = true
						const materialsTable = invoiceDoc.pageOrder.find(item => item.for === "materialEntries")
						if (materialsTable && materialsTable.visible) {
							materialSummaryVisible = true
						} else {
							materialSummaryVisible = false
						}

						newSummaryEntries.push({
							...ent,
							visible: materialSummaryVisible,
							values: ent.values.map(td => {
								if (td.for === "total") {
									return {
										...td,
										isFormula: true,
										val: "= invoiceTotals.materialEntries.total"
									}
								} else if (td.for === "item") {
									return {
										...td, 
										isFormula: true,
										val: "= editedInv.pageOrder.0.val"
									}
								} else {
									return td
								}
							})
						})
					} else if (ent.id === "laborTotals") {

						let laborSummaryVisible = true
						const laborTable = invoiceDoc.pageOrder.find(item => item.for === "laborEntries")
						if (laborTable && laborTable.visible) {
							laborSummaryVisible = true
						} else {
							laborSummaryVisible = false
						}

						newSummaryEntries.push({
							...ent,
							visible: laborSummaryVisible,
							values: ent.values.map(td => {
								if (td.for === "total") {
									return {
										...td,
										isFormula: true,
										val: "= invoiceTotals.laborEntries.total"
									}
								} else if (td.for === "item") {
									return {
										...td, 
										isFormula: true,
										val: "= editedInv.pageOrder.4.val"
									}
								} else {
									return td
								}
							})
						})

					} else {
						newSummaryEntries.push(ent)
					}
				})
			}

			// if summaryEntries not in pageOrder add it in
			if (hasSummaryEntriesInPageOrder < 0) {
				addSummaryEntries = [
					{
						className: "section-heading",
						for: "summaryHeading",
						group: "summary",
						// rows: 1,
						type: "div",
						val: "Summary",
						visible: true
					},
					{
						for: "summaryEntries",
						group: "summary",
						tableHeadings: [
							// {for: "date", val: "Date", visible: false},
							{for: "item", val: "Item", visible: true},
							{for: "qty", val: "Qty", visible: true},
							// {for: "cost", val: "% Applied to", visible: true},
							{for: "total", val: "Total", visible: true}
						],
						type: "table",
						val: "editedInv.summaryEntries",
						// val: newSummaryEntries || [],
						visible: true
					}
				]
			}

			invoiceDoc.topSectionPageOrder.forEach(item => {
				// cant do this until handle changes of bill to are updated to not update the pageOder
				// if (item.for === "billTo") {
					// change the vals to be formula ... "= editedInv.billTo.name ...etc"
				// }

				// not doing the below for date-invoice because when the value of the td input is changed we must change the formula too 
				// otherwise when user backspaces the  " " stayes because it is part of the formula
				// the code below works besides this quirk, uncomment it and the code in handleTdChange in invoiceUtils.js to make it work

				/*if (item.for === "date-invoice") {
					// item.val // the "table"
					const newItemVal = item.val.map(row => {
						if (row.id === "invNumber") {
							return {
								...row,
								values: row.values.map(td => {
									if (td.for === "invNumber") {
										return {
											...td,
											isFormula: true,
											val: "= " + JSON.stringify(['#', '=editedInv.invNumber', ' ', '=editedInv.invShortHand'])
										}
									} else return td
								})
							}
						} else return row //date-invoice only has one row so else shouldnt run
					})

					// add the item into new page order
					newTopSectionPageOrder.push({
						...item, 
						val: newItemVal // a table object
					})
				} else*/ if (item.for === "description") {
					const newItemVal = item.val.map(row => {
						if (row.id === "description") {
							return {
								...row,
								values: row.values.map(td => {
									if (td.for === "description") {
										return {
											...td,
											isFormula: true,
											val: "= editedInv.description"
										}
									} else return td // description only has one td so else shouldnt run
								})
							}
						} else return row //description only has one row so else shouldnt run
					})

					// add the item into new page order
					newTopSectionPageOrder.push({
						...item, 
						val: newItemVal // a table object
					})
				} else {
					// push the current item into new page order no editing necessisary 
					newTopSectionPageOrder.push(item)
				}
			})

			return {
				...invoiceDoc,
				topSectionPageOrder: newTopSectionPageOrder,
				summaryEntries: newSummaryEntries,
				pageOrder: [
					...invoiceDoc.pageOrder,
					...addSummaryEntries
				]
			}


			// let newPageOrder = []
			// don't do below because pageOrder arrangment can change then every time it changes we'd need to update the formula
			// this is done in some handle change function and only the render of a section total is changed the actual texVal in section total in db is never changed
			// invoiceDoc.pageOrder.map(item => {
				// if (item.type === "sectionTotal") {
				// 	const sectionHeadingNameIndex = invoiceDoc.pageOrder.findIndex(obj => obj.className === "section-heading" && obj.for === item.for)
				// 	newPageOrder.push({
				// 		...item,
				// 		textVal: `= invoiceDoc.pageOrder.${sectionHeadingNameIndex}.val`
				// 	})
				// } else newPageOrder.push(item)
			// })
		}

		const setData = async (invoiceDoc) => {
			// const nowMin = Math.round((new Date().getTime()) / 60000)
			try {
				let invoiceObjects = await getSubCollectionEntries({subCollectionName: "receipts", invoiceId, originalInv: invoiceDoc})
				let parentDocId = invoiceId
				// clone everything instead the
				// let isCopy = false
				// if (invoiceObjects.newEditedInv.fullCopyOf) {
					// isCopy = true
					// parentDocId = invoiceObjects.newEditedInv.fullCopyOf
				// 	// when deleting here it still gets added to db, need ot overwrite
				// 	// delete invoiceObjects.newEditedInv.fullCopyOf
				// 	// delete invoiceObjects.newInvoice.fullCopyOf
				// 	// overwrite fullCopyOf
				// 	invoiceObjects.newEditedInv.fullCopyOf = ""
				// 	invoiceObjects.newInvoice.fullCopyOf = ""
				// }

				invoiceObjects = await getSubCollectionEntries({subCollectionName: "laborEntries", invoiceId: parentDocId, originalInv: invoiceObjects.newInvoice, editedInvoice: invoiceObjects.newEditedInv})

				invoiceObjects = await getSubCollectionEntries({subCollectionName: "materialEntries", invoiceId: parentDocId, originalInv: invoiceObjects.newInvoice, editedInvoice: invoiceObjects.newEditedInv})


				// get verified payments handled within getSubCollectionEntries
				invoiceObjects = await getSubCollectionEntries({subCollectionName: "paymentEntries", invoiceId: parentDocId, originalInv: invoiceObjects.newInvoice, editedInvoice: invoiceObjects.newEditedInv})

				setIsFetching(false)

				const {newInvoice, newEditedInv} = invoiceObjects
				if (currentUser) {
					// check for followers, editors and owner
					let checkUserIsOwner = currentUser.uid === newEditedInv.owner || currentUser.uid === newEditedInv.contractor.id
					let checkUserCanEdit = newEditedInv.editors.includes(currentUser.uid) || newEditedInv.owner === currentUser.uid || newEditedInv.contractor.id === currentUser.uid
					let checkUserIsFollower = newEditedInv.followers.includes(currentUser.uid)

					setUserIsOwner(checkUserIsOwner)
					setUserCanEdit(checkUserCanEdit)
					setUserIsFollower(checkUserIsFollower)
					// if (currentUser.uid === newEditedInv.contractor.id || currentUser.uid === newEditedInv.creatorUid) {
					// 	setUserIsCreatorOrContractor(true)
					// } else {
					// 	setUserIsCreatorOrContractor(false)
					// }
				}


				// in getAndUpdateInvoiceUserData if the invoice is not closed, check for any updates in user objects
				return getAndUpdateInvoiceUserData(newInvoice, newEditedInv)

			} catch (err) {
				setIsFetching(false)
				throw err
			}

		}

		const handleInvoiceDoc = async (newData, newUserCanView) => {
								let mustUpdate = false

								if (!newData.version) {
									newData.version = 1
									mustUpdate = true
								}

								// tempory fix for rate updates not doing anything
								// Updating rate in details and settings can update okay but it doesnt trigger a re render of laborEntries so the update doesnt reflect in the UI
								// edge case with Invoice Levying since the levied invoice cant be updated on a nested level eg: laborEntry[0]....{name, "", val} by the sub contractor
								if (newData.rate !== editedInv.rate) {
									mustUpdate = true
								}

								// handle firestore enable persistence caching problem
								// if a user was able to view the doc then they log out or switch accounts, firestore still lets them view the doc
								if (newData.visibility === "private") {
									if (currentUser && currentUser.uid) {
										newUserCanView = (newData.owner === currentUser.uid) || (newData.billTo.uid === currentUser.uid) || (newData.editors.includes(currentUser.uid)) || (newData.followers.includes(currentUser.uid))
									}
								} else {
									newUserCanView = true
								}
								if (!newUserCanView) {
									handleSetEditedInv({mergeIntoHistory: true, changes: {visibility: "private"}, caller: "SpecificInvoice - unsubscribeMain !newUserCanView"})
								} else {
									// prevent the autosave from overwriting new edits
									// by only running handleSetEditedInv if this version doesnt match the last saved version
									// this should allow another editor to make a change and have that change worked in to the invoice even while the current user is editing 
									const newDataInvVersion = isNaN(parseInt(newData.version)) ? 1 : parseInt(newData.version)
									const lastSavedVersionAsNum = isNaN(parseInt(lastSavedVersion)) ? 0 : parseInt(lastSavedVersion)
									// have to use lastSaved version otherwise this useEffect has to depend on editedInv and that causes to many
									// onSnapshot calls... need to make the invoice from firestore a mutable ref?
									let newUpdateVersionIsGreaterThanLastSaved = newDataInvVersion > lastSavedVersionAsNum


									if (mustUpdate || !lastSavedVersion || /*lastSavedVersion === "original" ||*/ newUpdateVersionIsGreaterThanLastSaved) {
										// add in the default summary entries and replace values with formulas if they are not formulas already
										newData = replaceValuesWithFormulas({...newData, summaryEntries: (newData.summaryEntries && newData.summaryEntries.length) ? newData.summaryEntries : editedInv.summaryEntries})
										// checkFollowOrEditRequest({...newData, id: invoiceId}, currentUser ? currentUser.uid : "")
										const invoicesAfterSetData = await setData(newData)

							  		// only merge into history if user hasnt made any edits yet
							  		if (lastSavedVersion === "original") {
											handleSetEditedInv({mergeIntoHistory: true, changes: invoicesAfterSetData.editedInv, caller: "SpecificInvoice - unsubscribeMain doc.exists = true"})
											handleSetInv({changes: invoicesAfterSetData.inv, caller: "SpecificInvoice.js unsubscribeMain, doc.exists === true"})
							  		} else {
											handleSetEditedInv({mergeIntoHistory: false, changes: invoicesAfterSetData.editedInv, caller: "SpecificInvoice - unsubscribeMain doc.exists = true, mergeIntoHistory: false"})
											handleSetInv({changes: invoicesAfterSetData.inv, caller: "SpecificInvoice.js unsubscribeMain, doc.exists === true, mergeIntoHistory: false"})
							  		}
									}

									// handle if this invoice was recently levied or levier is updating settings or unpairing
									if (newData.leviedInvoice) {
										const leviedInvoiceOptionsChanged = deepObjectCompareDiffers({a: newData.leviedInvoiceOptions || {}, b: editedInv.leviedInvoiceOptions || {}})
										// if levied invoice we need to always keep the billTO up to date
										const newBillTo = newData.billTo
										if (!editedInv.leviedInvoice || leviedInvoiceOptionsChanged || editedInv.leviedInvoice !== newData.leviedInvoice) {
											// only update the leviedInvoiceOptions and leviedInvoice properties
											handleSetEditedInv({mergeIntoHistory: true, changes: { leviedInvoiceOptions: newData.leviedInvoiceOptions || {}, leviedInvoice: newData.leviedInvoice, billTo: newBillTo }, caller: "SpecificInvoice - unsubscribeMain leviedInvoice initiated update"})
											handleSetInv({changes: { leviedInvoiceOptions: newData.leviedInvoiceOptions || {}, leviedInvoice: newData.leviedInvoice, billTo: newBillTo }, caller: "SpecificInvoice - unsubscribeMain leviedInvoice initiated update"})								
										}

									} else {
										// must have been unpaired
										// if the invoice has been recently unpaired
										const newBillTo = newData.billTo

										if (editedInv.leviedInvoice) {
											// do update invoice
											handleSetEditedInv({mergeIntoHistory: true, changes: { leviedInvoiceOptions: newData.leviedInvoiceOptions || {}, leviedInvoice: newData.leviedInvoice || "", billTo: newBillTo }, caller: "SpecificInvoice - unsubscribeMain leviedInvoice initiated update because no editedInv.leviedInvoice"})
											handleSetInv({changes: { leviedInvoiceOptions: newData.leviedInvoiceOptions || {}, leviedInvoice: newData.leviedInvoice || "", billTo: newBillTo }, caller: "SpecificInvoice - unsubscribeMain leviedInvoice initiated update because no editedInv.leviedInvoice"})
										}
									}
								}
			return newUserCanView
		}
		// let unsubscribeMain = () => null
			// if (currentUser) {

		// beloq doenst improve load times that much like 0.3 seconds
		// find invoice in users invoices and add that instead of re-fetching
		// if (usersInvoices && usersInvoices.length) {
		// 	const foundInUsersInvoices = usersInvoices.find(inv => inv.id === invoiceId)
		// 	console.log("usersInvoices true", {foundInUsersInvoices})
		// 	if (foundInUsersInvoices) {
		// 		let newUserCanView = false
		// 		let newData = foundInUsersInvoices
		// 		handleInvoiceDoc(newData, newUserCanView).then(newUserCanView => {
		// 			setUserCanView(newUserCanView)
		// 			setInvoiceLoaded(true)
		// 					console.log("end: " + Date.now())

		// 			setIsFetching(false)
		// 		})
		// 	}
		// }

			let unsubscribeMain = firebase.firestore().collection("invoices").doc(invoiceId).onSnapshot(doc => {
				(
					async () => {
						try {
							let newUserCanView = false
							if (doc.exists) {
								let newData = {...doc.data(), id: doc.id}
								newUserCanView = await handleInvoiceDoc(newData, newUserCanView)
							} else {

								handleSetEditedInv({mergeIntoHistory: true, changes: {id: "notFound"}, caller: "SpecificInvoice - unsubscribeMain doc.exists = false"})
								handleSetInv({changes: {id: "notFound"}, caller: "SpecificInvoice.js unsubscribeMain doc.exists === false"})

								newUserCanView = true
							}

							setUserCanView(newUserCanView)
							setInvoiceLoaded(true)
							setIsFetching(false)

						} catch (err) {
							throw err
						}
					}
				)()
			}, (err) => {
				setInvoiceLoaded(true)
				setIsFetching(false)
				// whether doc exists or not this will return insufficient permissions
				if (err.message.includes("Missing or insufficient permissions")) {
					if (isNewDoc) {
						// the invoice has just been created so it doesn't exist yet
						setUserCanView(true)
					} else {
						// set doc doesnt exist until we find out if it exists or not
						checkFollowOrEditRequest({...editedInv, id: invoiceId}, currentUser ? currentUser.uid : "")

						// setUserCanView(true)
						// will not work on linvo-dev enviroment
						const docExists = firebase.functions().httpsCallable("docExists")
						docExists({collection: "invoices", docId: invoiceId}).then(({data}) => {
							if (data.exists) {
								handleSetEditedInv({mergeIntoHistory: true, changes: {id: invoiceId, visibility: "private"}, caller: "SpecificInvoice - unsubscribeMain catch error doc exists but private"})
								setUserCanView(false)
							} else {
								handleSetEditedInv({mergeIntoHistory: true, changes: {id: "notFound"}, caller: "SpecificInvoice - unsubscribeMain insufficient permissions"})
								handleSetInv({changes: {id: "notFound"}, caller: "SpecificInvoice.js unsubscribeMain doc.exists === false"})
							}
							setIsFetching(false)
						}).catch(err => {
							setIsFetching(false)
							setErrorObj(err)
						})
					}
				} else {
					setErrorObj(err)
				}
			})
			
		// }

		return () => {
			unsubscribeMain()
			// handleSetEditedInv({makeDefault: true, changes: {}, caller: "SpecificInvoice - unsubscribeMain on unmount"})			
			setIsFetching(false)
		}

	// eslint-disable-next-line
	}, [/*userObject,*/currentUser, invoiceId, lastSavedVersion])

		
	// for live entry
	useEffect(() => {
		if (editedInv.laborEntries && editedInv.laborEntries.length && editedInv.liveEntryId) {
			// const nowMin = Math.round(Date.now() / 60000)
			const nowMin = Date.now() / 60000 // dont round so that it will be greater right away

			const liveEntryRow = editedInv.laborEntries.find(row => row.id === editedInv.liveEntryId)
			if (liveEntryRow && editedInv.startedWorkTodayAt <= nowMin && (editedInv.endWorkTodayAt - (editedInv.todayBreakTimeMin ? parseFloat(editedInv.todayBreakTimeMin) : 0)) > nowMin) {
				setWorkerIsWorking(true)
			} else {
				setWorkerIsWorking(false)
			}

		}
	}, [editedInv.laborEntries, editedInv.liveEntryId, editedInv.startedWorkTodayAt, editedInv.endWorkTodayAt, editedInv.todayBreakTimeMin, setWorkerIsWorking,])


	// set success message after payment if urlParams say to
	useEffect(() => {
		const paymentSuccess = query.get("paymentSuccess")
		// const session_id = query.get("session_id")

		let timeout

		if (paymentSuccess && currentUser && !isFetching && editedInv.pageOrder) {
			// add the success timeout
			if (currentUser && !isFetching && editedInv.pageOrder) {
				if (timeout) {
					clearTimeout(timeout)
				}
				timeout = setTimeout(() => {
					handleSetMessage("")
				}, 7000)
				if (paymentSuccess === "true") {
					handleSetMessage(<div>Payment successful!</div>)
					// query.delete("paymentSuccess")
					// query.delete("session_id")
					// query.delete("paid")
				} else {
					handleSetMessage(<div style={{color: "red"}}>Payment failed</div>)
					// query.delete("paymentSuccess")
					history.replace(history.location.pathname)
				}

      	let newQuery = new URLSearchParams(query.toString())
    		newQuery.delete("paymentSuccess")

				if (newQuery) {
	        history.replace(`${history.location.pathname}?${newQuery.toString()}`);
				} else {
	        history.replace(`${history.location.pathname}`);
				}
			}
		}

	}, [query, history, currentUser, isFetching, editedInv.pageOrder, handleSetMessage])


	// check for url query prompt login
	useEffect(() => {
		// const login = query.get("promptLogin")
		if (query && query.toString()) {
			const pay = query.get("promptPayment")
			const paymentAmount = query.get("paymentAmount")
			const paymentMethod = query.get("paymentMethod")
			const checkoutAsGuest = query.get("checkoutAsGuest")

			// if login modal is open, then suer must log in first so we dont want to clear the url params
			if (pay && paymentAmount && !loginModalOpen) {
				setAddPaymentModal({
					open: true,
					defaultAdding: parseFloat(paymentAmount),
					paymentMethod,
					checkoutAsGuest: checkoutAsGuest === "true" ? true : false,
				});

      	let newQuery = new URLSearchParams(query.toString())
    		newQuery.delete("promptPayment")
    		newQuery.delete("paymentAmount")
    		newQuery.delete("paymentMethod")
    		newQuery.delete("checkoutAsGuest")

				if (newQuery) {
	        history.replace(`${history.location.pathname}?${newQuery.toString()}`);
				} else {
	        history.replace(`${history.location.pathname}`);
				}
			}
			
		}

	}, [query, loginModalOpen, setAddPaymentModal, history, currentUser]);


	useEffect(() => {
		if (userObject.id && !usersInvoices) {
			getUsersInvoices({userId: userObject.id, caller: "SpecificInvoice.js - useEffect",})
		}
		// eslint-disable-next-line
	}, [userObject.id, usersInvoices])

	useEffect(() => {
		if (userObject.id && !usersProjects) {
			getUsersProjects({userId: userObject.id, caller: "SpecificInvoice.js - useEffect",})
		}
		// eslint-disable-next-line
	}, [userObject.id, usersProjects])


	// set recently viewed 
	useEffect(() => {
		// must check the notFound property because once invoice is deleted this property is set right away
		// if invoice was just deleted we dont want to add it back to recentlyViewed
		if (/*userObject.id &&*/ editedInv.owner && editedInv.id !== "notFound" && !addedToRecentlyViewed) {
			const invoiceName = `#${editedInv.invNumber} ${editedInv.invShortHand}`
			let newUserObject = null
			const docType = (userObject.id && editedInv.billTo.uid === userObject.id) ? "bill" : "invoice"

			if ((!userObject.recentlyViewedDocs) || (!userObject.recentlyViewedDocs.length) || !(userObject.recentlyViewedDocs[0].id === invoiceId)) {
				newUserObject = {
					...userObject, 
					recentlyViewedDocs: removeDuplicates([
						{id: invoiceId, linkName: invoiceName, docType, relUrl: window.location.pathname || "/invoices/" + invoiceId}, 
						...userObject.recentlyViewedDocs || []
					]).slice(0, 10)
				}
				
				setUserObject(newUserObject)
				setAddedToRecentlyViewed(true)

				if (userObject.id) {
					updateUserData(newUserObject, true, "SpecificInvoice useEffect set recently viewed").catch(err => setErrorObj(err))
				}
			}
		}
	}, [
		addedToRecentlyViewed, 
		setAddedToRecentlyViewed, 
		userObject, 
		updateUserData, 
		setUserObject, 
		editedInv.invNumber,
		editedInv.invShortHand,
		editedInv.billTo.uid,
		editedInv.owner,
		invoiceId, 
		setErrorObj,
		editedInv.id
	])

	// pre load the levied invoice
	// useEffect(() => {
	//   if (editedInv.leviedInvoice && editedInv.billTo.uid === userObject.uid) {
	//   	(
	//   		async () => {
	//   			try {
	// 	  			const doc = await firebase.firestore().collection("invoices").doc(editedInv.leviedInvoice).get()
	// 	  			if (doc.exists) {
	// 		  			setForwardedBill({...doc.data(), id: doc.id})
	// 	  			}
	//   			} catch (err) {
	//   				if (err.message.includes("Missing or insufficient permissions")) {
	//   					return
	//   				} else {
	//   					setErrorObj(err)
	//   				}
	//   			}
	//   		}
	//   	)()
	//   }
	// }, [editedInv.leviedInvoice, editedInv.billTo.uid])

	let leviedInvInUsersInvoices = null
	if (usersInvoices && usersInvoices.length && editedInv.leviedInvoice) {

		leviedInvInUsersInvoices = usersInvoices.find(inv => inv.id === editedInv.leviedInvoice)
	} else {
		leviedInvInUsersInvoices = null
	}

	let canLevyInvoice = false


	if (userObject.id && (!editedInv.billTo.uid || (editedInv.billTo.uid === userObject.id && !editedInv.leviedInvoice))) {
		// if (userObject.hasEarlyAccess) {
			canLevyInvoice = true
		// }
	}

	// const aModalIsOpen = showInvoiceSummary || changeBillToModalOpen || addSummaryEntryModal.open || addPaymentModal.open || verifiedPaymentModalData.open || forwardBillModal.open

	// main conditionals
	if (editedInv.id === "notFound") {
		return (
			<SpecificInvoiceStyle>
				<div className="main-container" >
					<div>Invoice Not Found</div>
				</div>
			</SpecificInvoiceStyle>
		)
	} else if (userCanView === null) {

		return (
			<SpecificInvoiceStyle>
				<div className="main-container" >
					<div>...loading</div>
				</div>
			</SpecificInvoiceStyle>
		)
	} else if (userCanView === false) {
		return (
			<SpecificInvoiceStyle>
				<div className="main-container" >
					<div>Invoice is Private</div>
					{followButton}
				</div>
			</SpecificInvoiceStyle>
		)
	} else { // conditionals passed or loading
		return (
			<SpecificInvoiceStyle /*aModalIsOpen={aModalIsOpen}*/>
				{
					// <div className="invoice-controls">
					// 	<ul >
					// 		<li>Follow</li>
					// 		<li>Pay</li> 
					// 		<li className="main-action">Start work</li>
					// 		<li>Settings</li>
					// 		<li>Edit</li>
					// 	</ul>
					// </div>
				}
				{
					showInvoiceSummary &&
					<Modal 
						custom={{absolute: true}} 
						// containerHeading="Summary and Details"
						onClickOutside={() => setShowInvoiceSummary(false)}>						
						<InvoiceSummary setShowInvoiceSummary={setShowInvoiceSummary} />
					</Modal>
				}
				{
					(changeBillToModalOpen && currentUser) &&
					<ChangeBillToModal 
						userObject={userObject} 
						parentResourceContextItems={{
							inv,
							handleSetEditedInv, 
							followersUserObjs,
							// changeBillToModal,
							setChangeBillToModalOpen,
							setErrorObj,
							handleSetSuccessText
						}} 
						parentResource={editedInv}
						// setParentResource={setEditedInv}
						collectionName="invoices"
					/>
				}
				{
					(addSummaryEntryModal.open && currentUser) &&
					<AddSummaryEntryModal />
				}
				{
					(addPaymentModal.open) &&
					<AddInvoicePaymentModal stripePublicKey={stripePublicKey} />
				}
				{
					(verifiedPaymentModalData.open) && 
					<ViewPaymentModal />
				}
	      {
	      	forwardBillModal.open && (
		        <ForwardBillModal editedInv={editedInv} handleSetEditedInv={handleSetEditedInv} invoiceTotals={invoiceTotals} forwardedBill={forwardedBill} setForwardedBill={setForwardedBill} invoiceTotalStr={invoiceTotalStr} userObject={userObject} getNum={getNum} setForwardBillModal={setForwardBillModal} />
		      )
	      }
				<div className="main-invoice-container" >
					<div className="align-left noprint">
						{
						editedInv.isSampleInvoice ?
						<div className="copy-invoice">
							<div onClick={handleCopyInvoice}>
								<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							    <path d="M27 15 L27 30 2 30 2 5 17 5 M30 6 L26 2 9 19 7 25 13 23 Z M22 6 L26 10 Z M9 19 L13 23 Z" />
								</svg>
								<div>
									Copy and Edit this
								</div>
							</div>
						</div>
						: ""
						}
						{followButton}
						<div className="section-divider" />
						<button className="button-appearance small white" onClick={() => setShowInvoiceSummary(!showInvoiceSummary)} >
							{userCanEdit ? "Details & Settings" : "Invoice Details"}
						</button>
					</div>
					{
						// if user is billTo and there is no levied invoice
						canLevyInvoice &&
						<div className="noprint forward-bill poppins-light-chicago-16px" >
							<div className="clickable" onClick={() => {
									setForwardBillModal(forwardBillModal => ({
										...forwardBillModal, open: true, invoice: editedInv
									}))
								}}>
								<svg viewBox="-10 -20 400 400" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" fillRule="evenodd" >
						      <path d="M75 1  L 71 32 59 32  C 45 32 42 33 40 36  C 38 40 37 336 39 340  C 42 344 40 344 109 344  L 174 344 173 347  C 171 358 170 370 170 382  L 170 396 172 398  C 178 403 183 400 188 390  C 203 358 227 347 277 347  L 281 347 281 355  C 281 368 288 373 296 366  C 331 336 361 308 361 306  C 362 301 361 300 340 282  L 321 265 321 160  C 321 72 321 55 320 53  C 319 52 308 39 294 25  L 268 0 172 0 C 108 0 76 0 75 1 M256 38  L 257 60 259 62  L 262 64 283 64  L 305 64 305 157  L 305 250 301 247  C 293 239 288 238 284 242  C 282 244 282 244 281 255  C 281 268 282 267 273 267  C 246 267 216 277 201 291  L 196 295 142 295  L 88 295 88 155  L 88 16 172 16  L 256 16 256 38 M283 38  L 293 48 283 48  L 273 48 273 38  C 273 33 273 28 273 28  C 273 28 277 33 283 38 M71 177  C 71 315 71 308 75 310  C 76 311 95 311 131 311  L 185 311 183 315  C 182 318 180 322 179 324  L 178 327 116 327  L 55 327 55 188  L 55 48 63 48  L 71 48 71 177 M321 287  C 337 301 341 304 340 305  C 324 320 298 342 298 340  C 297 332 295 330 283 330  C 239 330 213 337 194 353  L 188 358 189 354  C 196 305 220 286 280 283  C 297 282 298 282 298 271  L 298 266 299 268  C 300 269 310 277 321 287" />
						      <text fontSize="200px" x="50%" y="55%" stroke="currentcolor" fill="currentcolor" strokeWidth="5px" textAnchor="middle">$</text>
						    </svg>
							</div>
		    			<h4 className="poppins-light-chicago-16px" onClick={() => {
								setForwardBillModal(forwardBillModal => ({
									...forwardBillModal, open: true, invoice: editedInv
								}))
							}}>Pair/Levy</h4>
		    			<MoreInfoIcon
								absolute={true}
								text="Automatically update items in another invoice when this one updates"
								custom={"bottom: -50%"}
							/>
						</div>
					}
					{
						(leviedInvInUsersInvoices /*&& editedInv.billTo.uid === userObject.id*/) &&
						<div className="noprint right">
							<a className="noLink poppins-light-chicago-16px" href={`
									/${leviedInvInUsersInvoices.contractor.companyName 
										|| leviedInvInUsersInvoices.contractor.username
									}
									/${leviedInvInUsersInvoices.id}`
							} target="blank">{"View levied invoice "}
							<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
						    <path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
							</svg>
							</a>
						</div>
					}
					{
						(editedInv.laborEntries.length && editedInv.materialEntries.length) ?
							<Invoice currentUser={currentUser} userObject={userObject} invoiceId={invoiceId} />
						: <div>... Loading</div>
					}
					<div className="section-divider noprint" />
					<div className="section-divider noprint" />
					<div className="section-divider noprint" />
				</div>
			</SpecificInvoiceStyle>
		)
	}
}

export default SpecificInvoice
