import React, { useState, useContext } from 'react'
import firebase from '../firebase/index'

import { UserContext } from './UserContext'


const NotificationsContext = React.createContext()

const NotificationsContextProvider = ({children}) => {
	const {
		userObject,
		offlineMode,
		handleSetSuccessText,
		setErrorObj,
		personalNotifications,
	} = useContext(UserContext)

  const [notificationSubscriptions, setNotificationSubscriptions] = useState([])

	const [messageNotifications, setMessageNotifications] = useState([])
	const [forUsersNotifications, setForUsersNotifications] = useState([])
	const [inAppNotifications, setInAppNotifications] = useState([])
	// const [projectNotifications, setProjectNotifications] = useState([])
	// const [invoiceNotifications, setInvoiceNotifications] = useState([])
	const [alreadyFetchedUsers, setAlreadyFetchedUsers] = useState([])


	const allNotifications = [
		...personalNotifications,
		// ...messageNotifications,
		...forUsersNotifications,
		// ...inAppNotifications,
		// ...projectNotifications,	
		// ...invoiceNotifications,
	].filter(n => n)


	let userNotifications = []

	// const readNotifications = allNotifications.filter(n => n.read)

	allNotifications.forEach(n => {
		const alreadyInUsersNotifications = userNotifications.find(obj => obj.id === n.id)

		if (!alreadyInUsersNotifications) {
			let modifiedNotification = {...n}

			// if (modifiedNotification.read && (!modifiedNotification.className || !modifiedNotification.className.includes("notification-read"))) {
				// modifiedNotification.className = (modifiedNotification.className || "") + " notification-read"
			// }

			userNotifications.push(modifiedNotification)
		}
	})

	const unreadNotifications = userNotifications.filter(n => !n.read)

	const privateNotificationsRef = userObject.id ? firebase.firestore().collection("users").doc(userObject.id).collection("notifications") : null


	const handleSetAlreadyFetchedUsers = ({newUsers, overWrite, caller}) => {
		if (overWrite) {
			return setAlreadyFetchedUsers(newUsers)
		} else {
			const oldUsers = alreadyFetchedUsers.filter(u => !newUsers.find(updatedUser => updatedUser.id === u.id))
			const combineUsers = [...oldUsers, ...newUsers]
			return setAlreadyFetchedUsers(combineUsers)
		}
	}


  const updateNotification = (newNotification, docRef) => {
  	// maybe move to an archived section instead
  	// let newState = currentState.map(item => {
  	// 	if (item.id === newNotification.id) {
  	// 		return {...item, ...changes}
  	// 	} else {
  	// 		return item
  	// 	}
  	// })
  	// setCurrentState(newState)

  	// if (newNotification.read) {
	  // 	if (readNotificationsRef.current && readNotificationsRef.current.length) {
	  // 		const foundInRead = readNotificationsRef.current.find(n => n.id === newNotification.id)
	  // 		if (!foundInRead) {
	  // 			readNotificationsRef.current = [...readNotificationsRef.current, newNotification] 
	  // 		}
	  // 	} else {
	  // 		readNotificationsRef.current = [newNotification]
	  // 	}
  		
  	// }


		return docRef.update({...newNotification}).catch(err => {
			console.log(JSON.stringify(err), {newNotification})
			err.message = "Action failed. Please contact support. Message: " + err.message || ""
			setErrorObj(err)
		})

  }

	const removeNotification = (obj, userId, docRef, keepInList) => {
		let ref = docRef
		if (!docRef) {
			ref = firebase.firestore().collection("notifications").doc(obj.id) 
		}

		return ref.delete().catch(err => {
			err.message = "Cannot delete notification. Please contact support. Message: " + err.message || ""
			setErrorObj(err)
			console.log(JSON.stringify(err))
		})
	} 

	return (
		<NotificationsContext.Provider value={{
			userObject,
			offlineMode,
			handleSetSuccessText,
			setErrorObj,
			userNotifications,
			notificationSubscriptions,
			setNotificationSubscriptions,
			removeNotification,
			updateNotification,
			privateNotificationsRef,
			messageNotifications,
			setMessageNotifications,
			// invoiceNotifications,
			// setInvoiceNotifications,
			// projectNotifications,
			// setProjectNotifications,
			inAppNotifications, 
			setInAppNotifications,
			unreadNotifications,
			forUsersNotifications, 
			setForUsersNotifications,
			alreadyFetchedUsers, 
			setAlreadyFetchedUsers,
			handleSetAlreadyFetchedUsers
		}}>
			{children}
		</NotificationsContext.Provider>
	)
}

export {NotificationsContextProvider, NotificationsContext}