import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { NotificationsContext } from "../contexts/NotificationsContext";
import { UserContext } from "../contexts/UserContext";
import { MessagingContext } from "../contexts/MessagingContext";
import { MediaQueryContext } from "../contexts/MediaQueryContext";


import { UserPhoto } from './Marketplace/marketplaceComponents';
import Spinner from "./Spinner/Spinner"
import SymbolFromId from "./svgComponents/SymbolFromId";


import Modal from "./Modal/Modal";

import { handleImageOnError, getNum } from "../utils/appUtils";
import { AppMessage } from "./MessageUtils";
import {
	handleAcceptFollower,
	handleAcceptEditor,
	getUserObjs,
	getFollowersUserDataWithType,
} from "../utils/followUtils";
import {
	msInADay,
	getTimeRemaining,
	getFriendlyDate,
} from "../utils/dateUtils";
import { handleTdChange, addEditorsToProject } from "./Invoice/invoiceUtils";
import MoreInfoIcon from "./MoreInfoIcon";


import firebase from "../firebase/index";


const NotificationsModalStyle = styled.div `
	.clear-error {
		${({ isLessThan700px }) => isLessThan700px ? `margin-right: 5px;` : '' }
	}

`

const NotificationStyle = styled.div `

	.notification-unread, .notification-read, .notification-deleted {
	  position: relative;
	  transition: opacity 0.2s linear, left 0.5s ease 0.3s, max-height 0.3s ease 0.2s, padding 0.3s ease 0.2s, margin 0.3s ease 0.2s;		
	}

	.notification-unread, .notification-read {
	  left: 0;
	  max-height: 400px;
	}

	.notification-unread {
		opacity: 1;
	}

	.notification-read {
		.notification-container > div > * {
		  opacity: 0.3;
		}
		.notification-container > div > .action-buttons-container {
			opacity: 1;
		}
	}


	.notification-deleted {
	  opacity: 0.3;
	  left: -101vw !important;
	  padding: 0 !important;
	  margin: 0 !important;
	  // max-width: 100vw !important;
	  max-height: 0px;
	}

	.doc-deleted {
		opacity: 0.3;
		transition: opacity 0.5s linear;	
		text-decoration: line-through;
	}

	> div {
	  padding: 10px;
	  opacity: 1;
	  background-color: var(--titan-white);
	  padding: 10px;
	  margin-bottom: 5px;
  }

  .note {
  	font-size: var(--font-size-xs);
  }
  .invoices svg {
	  box-shadow: 0px 5px 11px -3px  green;
	}

	.bill svg {
	  box-shadow: 0px 5px 11px -3px blue;
	}

	.projects svg {
	  box-shadow: 0px 5px 11px -3px brown;
	}
		.notification-container {
		  display: flex;
		  justify-content: flex-start;
		  column-gap: 10px;
		  text-align: left;
		  display: flex;
		  // justify-content: center;
		  .flex {
			  display: flex;
			  // justify-content: center;
			  row-gap: 10px;
			  flex-direction: column;
			}
		  .type-and-user-photo {
		  	position: relative;
		  	margin: 0 20px 5px 0; // make space for user photo offset
		  	height: 70px;
		  	width: 70px;
		  	padding-bottom: 20px;
		  	.user-photo-container {
		  		position: absolute;
		  		bottom: -5px;
		  		right: -20px;
	  		  border-radius: 50%;
				  background: transparent;
				  // margin-left: 2px;
				  box-shadow: 0px 0px 10px -2px;
			    height: 50px;
			  	width: 50px;
		  		> img {
		  		  border-radius: 50%;
		  		  height: 100%;
					  object-position: center;
					  aspect-ratio: 1 / 1;
					  object-fit: cover;
		  		}
		  	}
		  }
		  .type-and-user-photo-stacked {
		  	position: relative;
		  	margin: 0 20px 5px 0; // make space for user photo offset
		  	height: 70px;
		  	width: 70px;
		  	padding-bottom: 20px;
		  	.stacker {
		  		position: absolute;
		  		bottom: -5px;
		  		right: -20px;
			  	.user-photo-container {
			  		display: inline-block;
			  		width: 10px;
		  		  border-radius: 50%;
					  background: transparent;
					  // margin-left: 2px;
					  box-shadow: 0px 0px 10px -2px;
				    height: 50px;
				    width: 20px;
	  	  		> img {
	  	  		  border-radius: 50%;
	  	  		  height: 100%;
	  				  object-position: center;
	  				  aspect-ratio: 1 / 1;
	  				  object-fit: cover;
	  	  		}
			  	}
		  	}
		  	.user-photo-container:last-child {
		  		display: inline-block;
			  	width: 50px;
		  		overflow: visible;
		  	}
		}

		.notification-image {
		  height: 70px;
			width: 70px;
			.spinner {
				position: absolute;
				height: 80px;
				width: 80px;
				top: 0;
				left: 0;
			}
			> img {
			  height: 100%;
			  object-position: center;
			  aspect-ratio: 1 / 1;
			  object-fit: cover;
			}
			svg {
				height: 70px;
				width: 70px;
			}
			.material-icons {
				font-size: 70px;
				color: var(--light-gray)
			}
		}


	}
`

const NewMessageNotifications = ({
	usersThreads,
	onClickOutside,
	userObject,
	alreadyFetchedUsers,
	handleSetAlreadyFetchedUsers,
}) => {
	const [notificationContainerClass, setNotificationContainerClass] = useState("notification-unread")

	const newMessages = (usersThreads && userObject.id) ? usersThreads.filter(thread => {
		const userAsParticipant = thread.participants[userObject.id]
		if (userAsParticipant && userAsParticipant.lastMessageRead !== thread.newestMessage.id) {
			return true
		} else return false
	}) : []

	if (!newMessages.length) {
		return null
	}

	let newMessagesFrom = []

	const newMessagesFromText = newMessages.map((thread, i) => {
		// only include the first 3
		if (i < 3) {
			if (!thread.isGroup) {
				let messageFromUsername  
				for (let key in thread.participants) {
					const user = thread.participants[key]
					if (key !== userObject.id) {
						messageFromUsername = user.username
						newMessagesFrom.push({...user, id: key})
					}
				}
				return <div key={thread.id}><span className="bold" >@{messageFromUsername}</span></div>
			} else {
				return <span key={thread.id}><span className="bold" >@Group</span></span>
			}
		} else {
			if (i === 3) {
				return <span key={thread.id}> and {newMessages.length - 3} others</span>
			} else return null
		}
	})

	const handleNotificationClicked = () => {
		setNotificationContainerClass("notification-read")
		return onClickOutside()
	}

	// if problem with user photo just make user null in the user from the UserPhoto component
	let photosComponents = <div className="type-and-user-photo-stacked" >
		<div className="notification-image" >
			<div className="material-icons">{"\ue158"}</div>
		</div>
		<div className="stacker"> 
			{	
				newMessagesFrom.slice(0,3).map((user, i) => {
					return <UserPhoto key={i} alreadyFetchedUsers={alreadyFetchedUsers} handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers} userId={user.id} user={user} isFetchingUser={false} />
				})
			}
		</div>
	</div>


	return (
		<NotificationStyle>
			<div className={notificationContainerClass} >
				<Link className="notification-container no-link" to={"/messages"} onClick={handleNotificationClicked}>
					{photosComponents}
					<div className="flex" >
						<div className="notification-text">
							You have new messages from {newMessagesFromText}
						</div>
					</div>
				</Link>
			</div>
		</NotificationStyle>
	)

}

const JobPostNotification = ({
	data, 
	userObject, 
	setErrorObj, 
	handleReadNotification, 
	handleRemoveNotification,
	personalNotifications, 
	setPersonalNotifications,
	alreadyFetchedUsers,
	handleSetAlreadyFetchedUsers,
	onClickOutside
}) => {

	let postData = (data.additional && data.additional.post) ? data.additional.post : null;

	if (!postData) {
		console.error("Cant find postData in notification data... fetching the post... ", {data})
	}

	const [notificationUpdates, setNotificationUpdates] = useState({})
	const [newPostData, setNewPostData] = useState(null)
	// const [post, setPost] = useState(null)
	const [docExists, setDocExists] = useState(true)
	const [isFetchingDoc, setIsFetchingDoc] = useState(false)

	const post = {
		...postData, 
		...newPostData || {}
	}

	const notification = {
		...data,
		additional: {
			...data.additional,
			post
		},
		...notificationUpdates
	}

	if (post && post.viewedBy && post.viewedBy.includes(userObject.id)) {
		notification.read = true
	}


	const readJobPostNotification = (notification, user) => {
		const changes = {...notification, read: true}
		setNotificationUpdates(notificationUpdates => ({...notificationUpdates, read: true}))
		handleReadNotification(changes)
	}

	const handleIgnoreNewJobPost = (notification, user) => {
		const changes = {...notification, read: true, ignored: true}
		setNotificationUpdates(notificationUpdates => ({...notificationUpdates, read: true, ignored: true}))
		handleReadNotification(changes)
	}

	const handleDeleteJobPostNotification = (notification, user) => {
		// removeNotification reads it as well
		setNotificationUpdates(notificationUpdates => ({...notificationUpdates, read: true, deleted: true}))
		handleRemoveNotification(notification)
	}

	const fetchDoc = async (data) => {
		if (!data.forDocumentCollection || !data.forDocumentId) {
			console.error("No forDocumentCollection or no forDocumentId found")
			console.log("No forDocumentCollection or no forDocumentId found", {data})
			return
		}
		try {
			setIsFetchingDoc(true)
			const newDoc = await firebase.firestore().collection(data.forDocumentCollection).doc(data.forDocumentId).get().then(doc => doc.exists ? ({...doc.data(), id: doc.id}) : null)
			if (newDoc) {
				setNewPostData(newDoc)
				setDocExists(true)
			} else {
				setDocExists(false)
				handleDeleteJobPostNotification(data, userObject)

			}
			setIsFetchingDoc(false)

		} catch (err) {

			setDocExists(false)
			setIsFetchingDoc(false)
			if (err.code === "permission-denied") {
				handleDeleteJobPostNotification(data, userObject)
			} else {
				setErrorObj(err)
			}
		}
	}

	// get the updated post data
	useEffect(() => {
	  if (!newPostData && docExists && data) {
	  	fetchDoc(data)
	  }
	  // todo: try adding in docExists to dep arrahy...
	}, [data, newPostData])


  const cityShort = post.city.split(", ")[0].trim() || post.city || "your area";
  let notificationImage = <img alt="" src="/assets/fallback-image.jpg" onError={handleImageOnError} />

  if (post.photos.length) {
  	notificationImage = <img alt="" src={post.photos[0].url || "/assets/fallback-image.jpg"} onError={handleImageOnError} />
  }


	const timeSincePosted = getFriendlyDate(data.timestamp || post.timestamp, "noTime") + " at " + getFriendlyDate(data.timestamp || post.timestamp, "time")

	let budgetNumber
	if (post.budget) {
		budgetNumber = parseInt(post.budget)
	}

	if (post.budget > 1000) {
		budgetNumber = "999+"
	}

	if (budgetNumber) {
		budgetNumber = budgetNumber.toString()
	}


	let otherNotificationClassName = "notification-unread"
	if (notification.read) {
		otherNotificationClassName = "notification-read"
	}

	if (notification.deleted) {
		otherNotificationClassName = "notification-deleted"
	}

	return (
			<div className={`${notification.className || ""} ${otherNotificationClassName}`}>
				<div className={`notification-container ${docExists ? "" : "doc-deleted"}`}>
					<div className="type-and-user-photo" >
						<Link
							className="no-link"
							onClick={() => {
								readJobPostNotification(notification, userObject)
								onClickOutside()
							}}
							to={`/marketplace/jobs/${post.id}`}
						>
							<div className="notification-image">{notificationImage}</div>
						</Link>

						<Link
							to={(location) => ({
								...location,
								pathname: `/users/${post.username}`,
							})}
							className="no-link"
							onClick={onClickOutside}
						>
							<UserPhoto alreadyFetchedUsers={alreadyFetchedUsers} handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers} userId={post.userId} user={null} isFetchingUser={false} />
						</Link>
					</div>
					<div className="flex" >
						<div className="notification-text">
							<Link
								className="notification-container no-link bold"
								onClick={() => {
									readJobPostNotification(notification, userObject)
									onClickOutside()
								}}
								to={`/marketplace/jobs/${post.id}`}
							>
								{"New "}
									{post.industry} Job
								{` in ${cityShort}`} 
							</Link>
							<div className="tiny">"{post.title}"</div>
							{
								budgetNumber ? 
				  				<div className="post-time bold">
				  					<span className="material-icons" >{"\uf05b"}</span>
										<span className="meta-info">
											${budgetNumber} {post.budgetIsHourly ? "/h" : "total"}
										</span>
									</div>
								: ""
							}
						</div>
						<div className="action-buttons-container">
							{!notification.read && (
								<React.Fragment>
									{"  "}
									<button
										className="button-appearance tiny no-icon-or-underline"
										onClick={() =>
											handleIgnoreNewJobPost(notification, userObject)
										}
									>
										Ignore
									</button>
									{"  "}
								</React.Fragment>
							)}
							{
								!notification.deleted &&
								<button
									className="button-appearance tiny no-icon-or-underline"
									onClick={() => handleDeleteJobPostNotification(notification, userObject)}
								>
									Delete
								</button>
							}
						</div>
						<div className="post-time">{timeSincePosted}</div>
					</div>
				</div>
			</div>
	)
}

const DocumentNotification = ({
	notificationUpdates, 
	userObject, 
	setErrorObj, 
	offlineMode,
	handleRemoveNotification, 
	handleSetSuccessText,
	// invoiceNotifications,
	// setInvoiceNotifications,
	// projectNotifications,
	// setProjectNotifications,
	alreadyFetchedUsers,
	handleSetAlreadyFetchedUsers,
	onClickOutside
}) => {
	const [doc, setDoc] = useState(null)
	const [docExists, setDocExists] = useState(true)
	const [isFetchingDoc, setIsFetchingDoc] = useState(false)
	const [data, setData] = useState({...notificationUpdates})

	const onRemoveNotification = (obj) => {
		setIsFetchingDoc(false)
		// wait til the loading spinner is gone
		// setTimeout(() => {
			setData(data => ({...data, read: true, deleted: true}))
			handleRemoveNotification(obj)
		// }, 30)
	}

	const fetchDoc = async (data) => {
		if (!data.forDocumentCollection || !data.forDocumentId) {
			console.error("No forDocumentCollection or no forDocumentId found")
			console.log("No forDocumentCollection or no forDocumentId found", {data})
			return
		}
		try {
			setIsFetchingDoc(true)
			const newDoc = await firebase.firestore().collection(data.forDocumentCollection).doc(data.forDocumentId)
				.get()
				.then(doc => doc.exists ? ({...doc.data(), id: doc.id}) : null)
			if (newDoc) {
				if (!doc) {
					setDoc(newDoc)
				} else if (newDoc.version && doc.version && newDoc.version !== doc.version) {
					setDoc(newDoc)
				} // else dont update doc

				setDocExists(true)
			} else {
				setDocExists(false)
				onRemoveNotification(data)
			}
			setIsFetchingDoc(false)

		} catch (err) {
			setIsFetchingDoc(false)
			setDocExists(false)
			if (err.code === "permission-denied") {
				onRemoveNotification(data)
			} else {
				setErrorObj(err)				
			}
		}
	}


	useEffect(() => {
	  if (!doc && docExists && data) {
	  	fetchDoc(data)
	  }
	  // todo: try adding in rest of dep array do tests if too many fetchDocs...
	}, [data])

	const handleRemovePayment = async (obj) => {
		// approve the removeal of a payment entry that the contractor wants gone
		try {
			setIsFetchingDoc(true)
			const { paymentData } = obj.additional;
			const collection = obj.forDocumentCollection;
			const batch = firebase.firestore().batch();
			// delete the payment entry
			batch.delete(
				firebase
					.firestore()
					.collection(collection)
					.doc(obj.forDocumentId)
					.collection("payments")
					.doc(paymentData.id)
			);

			// delete notification and any duplicates
			// await firebase
			// 	.firestore()
			// 	.collection("notifications")
			// 	.where("accessors", "array-contains", userObject.id)
			// 	.where("additional.paymentData.id", "==", paymentData.id)
			// 	.get()
			// 	.then((snapshot) => {
			// 		// delete all notifications about that payment
			// 		return snapshot.docs.forEach((doc) => {
			// 			// dont remove the 'remove payment' notification here, remove it in handleRemoveNotification for the UI effects
			// 			if (doc.id !== obj.id) {
			// 				return batch.delete(doc.ref);
			// 			}
			// 		});
			// 	});

			await batch.commit();
			setIsFetchingDoc(false)
			onRemoveNotification(obj);
			console.log("todo: test deleting payment to see if this removes notification, test deleting payment then running this function to see error code")
			return handleSetSuccessText("Payment removed successfully!");
		} catch (err) {
				
			setIsFetchingDoc(false)
			console.log(JSON.stringify(err))
			if (err.code !== "storage/object-not-found") { // this is fine, dont show error
				setErrorObj(err);
			}
		}
	};

	const handleApprovePayment = async (obj) => {
		try {
			setIsFetchingDoc(true)
			const { additional } = obj;
			const docRef = firebase
				.firestore()
				.collection(obj.forDocumentCollection)
				.doc(obj.forDocumentId);

			let newPaymentData = {
				...additional.paymentData,
			};

			// delete the only properties that the payer could have changed since this notification to avoid insufficient permissions error
			delete newPaymentData.amount;
			// delete newPaymentData.amountApplied;
			// delete billTo information
			delete newPaymentData.billTo;

			newPaymentData.status = "succeeded";
			// set amount received to amount paid
			newPaymentData.amountReceived = additional.paymentData.amount;

			if (offlineMode) {
				docRef
					.collection("payments")
					.doc(newPaymentData.id)
					.set({
						...newPaymentData,
					}, { merge: true });
				if (obj.forDocumentCollection === "invoices") {
					updateInvoiceWithNewItems({
						doc,
						newInvoiceProperties: {
							...additional.billTo ? {billTo: additional.billTo} : {}
						},
						docRef,
					});
				}
			} else {
				await docRef
					.collection("payments")
					.doc(newPaymentData.id)
					.set({
						...newPaymentData,
					}, { merge: true });

				if (obj.forDocumentCollection === "invoices") {
					// if the notification is sent with billTO data use this as well as the payer uid and payer username
					await updateInvoiceWithNewItems({
						doc,
						newInvoiceProperties: {
							...additional.billTo ? {billTo: additional.billTo} : {}
						},
						docRef,
					});
				}
				setIsFetchingDoc(false)
				onRemoveNotification(obj);
				handleSetSuccessText("Success! Payment approved");
			}
		} catch (err) {
			setIsFetchingDoc(false)
			console.error(err)
			setErrorObj(err)
		}
	};

	const handleSetNewBillTo = async (obj, newBillTo) => {
		// cant just use handleSave here because handleSave is in editedInv context

		const docRef = firebase
			.firestore()
			.collection(obj.forDocumentCollection)
			.doc(obj.forDocumentId);
		// const newBillTo = obj.additional ? obj.additional.billTo : null
		if (!newBillTo || !newBillTo.uid) {
			setErrorObj({
				message:
					"Bill acceptor notification does not contain all necessary user data",
			});
			return;
		}

		setIsFetchingDoc(true)
		// get a string value for the address
		if (!newBillTo.userFullAddress) {
			let userFullAddress = "";
			if (newBillTo.address && typeof newBillTo.address === "object") {
				const address = newBillTo.address;
				let addressArray = [];

				address.line1 && addressArray.push(address.line1);
				address.line2 && addressArray.push(address.line2);
				address.city && addressArray.push(address.city);
				address.state && addressArray.push(address.state);
				address.postal_code && addressArray.push(address.postal_code);
				address.country && addressArray.push(address.country);
				userFullAddress = addressArray.join(", ");
			}

			newBillTo.userFullAddress = userFullAddress;
		}

		// const objAdditional = {
		// 	...obj.additional,
		// 	// cant just add the new accessors array in the notification obj.additional
		// 	// because the array could have updated sincce the notification was clicked
		// 	// ex: user accepts bill when jim is editor, jim is removed as editor, invoice owner clicks notification, notification payload has the old project accessors, jim is added back as an accessor
		// 	accessors: firebase.firestore.FieldValue.arrayUnion(obj.additional.billTo.uid),
		// 	followers: firebase.firestore.FieldValue.arrayUnion(obj.additional.billTo.uid)
		// }

		if (offlineMode) {
			// saveProject({newProjectData: {...projectData, billTo: obj.additional}})
			// })
			if (obj.forDocumentCollection === "invoices") {
				updateInvoiceWithNewItems({
					doc,
					newInvoiceProperties: { billTo: newBillTo },
					docRef,
				});
			}
			if (obj.forDocumentCollection === "projects") {
				docRef.update({
					...obj.additional,
					accessors: firebase.firestore.FieldValue.arrayUnion(
						obj.additional.billTo.uid
					),
					followers: firebase.firestore.FieldValue.arrayUnion(
						obj.additional.billTo.uid
					),
				});

				handleAcceptFollower(
					obj.forDocumentCollection,
					obj.forDocumentId,
					obj.sentBy
				);
			}
			// handleAcceptFollower(obj.forDocumentCollection, obj.forDocumentId, obj.sentBy)
			// !userIsFollower && handleAcceptFollower(i, obj.id, obj.sentByUsername, "projects", projectData, setProjectData, setErrorObj, handleSetSuccessText)
			setIsFetchingDoc(false)
			onRemoveNotification(obj);
		} else {
			try {
				// need to update topSectionPageOrder
				if (obj.forDocumentCollection === "invoices") {
					await updateInvoiceWithNewItems({
						doc,
						newInvoiceProperties: { billTo: newBillTo },
						docRef,
					});
				}

				if (obj.forDocumentCollection === "projects") {
					await docRef.update({
						...obj.additional,
						accessors: firebase.firestore.FieldValue.arrayUnion(
							obj.additional.billTo.uid
						),
						followers: firebase.firestore.FieldValue.arrayUnion(
							obj.additional.billTo.uid
						),
					});
					await handleAcceptFollower(
						obj.forDocumentCollection,
						obj.forDocumentId,
						obj.sentBy
					);
				}

				setIsFetchingDoc(false)
				onRemoveNotification(obj);

				handleSetSuccessText(
					`${obj.forDocumentCollection.slice(0, -1)} now billed to ${
						obj.sentByUsername
					}`
				);
			} catch (err) {
				setIsFetchingDoc(false)
				setErrorObj(err);
			}
		}

	};

	const handleAcceptFollowerOrEditor = async (obj) => {
		setIsFetchingDoc(true)
		if (offlineMode) {
			if (obj.type === "followRequest") {
				handleAcceptFollower(
					obj.forDocumentCollection,
					obj.forDocumentId,
					obj.sentBy
				);
			}
			if (obj.type === "editRequest") {
				handleAcceptEditor(
					obj.forDocumentCollection,
					obj.forDocumentId,
					obj.sentBy
				);
				handleAcceptFollower(
					obj.forDocumentCollection,
					obj.forDocumentId,
					obj.sentBy
				);
			}

			setIsFetchingDoc(false)
			onRemoveNotification(obj);

		} else {
			try {
				if (obj.type === "followRequest") {
					await handleAcceptFollower(
						obj.forDocumentCollection,
						obj.forDocumentId,
						obj.sentBy
					);
					handleSetSuccessText(
						`${obj.sentByUsername} is now following ${
							obj.docLink.name || obj.forDocumentCollection.slice(0, -1)
						}`
					);
				}
				if (obj.type === "editRequest") {
					await handleAcceptEditor(
						obj.forDocumentCollection,
						obj.forDocumentId,
						obj.sentBy
					);
					await handleAcceptFollower(
						obj.forDocumentCollection,
						obj.forDocumentId,
						obj.sentBy
					);
					handleSetSuccessText(
						`${obj.sentByUsername} is now an editor of ${
							obj.docLink.name || obj.forDocumentCollection.slice(0, -1)
						}`
					);
				}

				setIsFetchingDoc(false)
				onRemoveNotification(obj);
			} catch (err) {
				setIsFetchingDoc(false)
				setErrorObj(err);
			}
		}

		return;	
	};

	const updateInvoiceWithNewItems = async ({
		doc,
		newInvoiceProperties,
		docRef,
	}) => {
		try {
			const invoice = { ...doc, ...newInvoiceProperties };

			const val = `${invoice.billTo.firstname} ${invoice.billTo.lastname} ${
				invoice.billTo.userFullAddress
					? "\n" + invoice.billTo.userFullAddress
					: ""
			}`;
			// get new topSectionPageOrder values
			let newInvoice = handleTdChange(
				{ for: "billTo", val },
				invoice,
				"billTo",
				"billTo"
			);

			// just do handleSave instead ? cant do handleSace because it is in invoice context
			const updatedItemsObj = {
				billTo: newInvoice.billTo,
				topSectionPageOrder: newInvoice.topSectionPageOrder,
				// cant just add the new accessors array in the notification obj.additional
				// because the array could have updated sincce the notification was clicked
				// ex: user accepts bill when jim is editor, jim is removed as editor, invoice owner clicks notification, notification payload has the old project accessors, jim is added back as an accessor
				accessors: firebase.firestore.FieldValue.arrayUnion(
					newInvoice.billTo.uid
				),
				followers: firebase.firestore.FieldValue.arrayUnion(
					newInvoice.billTo.uid
				),
			};

			if (offlineMode) {
				docRef.update(updatedItemsObj);
				return;
				// notifications require usernames
			} else {
				await docRef.update(updatedItemsObj);

				// add editors to project
				if (doc.forProject) {
					// notifications require usernames
					let followersUserObjs = [];
					const resEditorData = await getUserObjs(newInvoice.editors);
					const resFollowerData = await getUserObjs(
						newInvoice.followers,
						resEditorData
					);
					// combine editors and followers in case editor is not follower
					const followersAndEditors = [...resFollowerData, ...resEditorData];

					// make follower data have type and only have username and id in the follower obj
					followersUserObjs = getFollowersUserDataWithType(
						followersAndEditors,
						resEditorData,
						newInvoice
					);

					await addEditorsToProject(
						userObject.id,
						newInvoice,
						followersUserObjs,
						setErrorObj,
						handleSetSuccessText
					);
				}
				return;
			}
		} catch (err) {
			setErrorObj(err);
		}
	};


	const paymentData = data.additional ? data.additional.paymentData || null : null;

	let resLinkName = data.docLink ? data.docLink.name : ""

	// find the doc in users invoices or projects 
	if (data.forDocumentCollection === "invoices") {
		resLinkName = resLinkName || (doc ? `Invoice #${doc.invNumber} ${doc.invShortHand}` : "This invoice")
	} else if (data.forDocumentCollection === "projects") {
		resLinkName = resLinkName || (doc ? `${doc.projectName} (${doc.shortHandId})` : "This project")
	} else {
		resLinkName = resLinkName || "This document"
	}

	// let documentPhoto = getDocumentPhoto(data, userObject, usersInvoices)

	let symbolTypeClassName = data.forDocumentCollection

	if (doc && doc.billTo && doc.billTo.uid === userObject.id) {
		symbolTypeClassName = "bill"
	}

	if (!paymentData && (data.type === "deletePayment" || data.type === "addPayment")) {
		console.error("cant find paymentData...", {data})
		let err = new Error("Someone is requesting to add or remove a payment from an invoice but we can't find any payment data in a payment notification. Please contact support if problem persists.")
		err.metadata = {
			...data
		}
		setErrorObj(err)
		return ""
	}

	let timeSincePosted = "" 

	if (data.date) {
		const timestamp = Math.round(new Date(data.date).valueOf() / 1000)
		timeSincePosted = getFriendlyDate(timestamp, "noTime") + " at " + getFriendlyDate(timestamp, "time")
	}


	let otherNotificationClassName = "notification-unread"
	if (data.read) {
		otherNotificationClassName = "notification-read"
	}

	if (data.deleted) {
		otherNotificationClassName = "notification-deleted"
	}

	return (
			<div className={`${data.className || ""} ${otherNotificationClassName}`}>
				<div className={`notification-container ${docExists ? "" : "doc-deleted"}`}>
					<div className="type-and-user-photo" >
						<div className={symbolTypeClassName + " notification-image"}>
							<Link 
								className="link-appearance symbol-container" 
								to={(location) => ({
									...location,
									pathname: data.docLink.pathname,
								})}
								onClick={onClickOutside}
							>
								{isFetchingDoc && (
									<div className="spinner">
										<Spinner position="relative" height="80px" width="80px" />
									</div>
								)}

								<SymbolFromId
									id={data.forDocumentId}
									svgProperties={{
										filter: "url(#shadow1)",
										strokeWidth: "2",
									}}
								/>
							</Link>
						</div>
						<Link
							to={(location) => ({
								...location,
								pathname: `/users/${data.sentByUsername}`,
							})}
							className="no-link"
						>
							<UserPhoto alreadyFetchedUsers={alreadyFetchedUsers} handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers} userId={data.sentBy} user={null} isFetchingUser={false} />
						</Link>
					</div>
					<div className="flex" >
						<div className="notification-text">
							<Link
								className="link-appearance no-link bold"
								to={(location) => ({
									...location,
									pathname: `/users/${data.sentByUsername}`,
								})}
								onClick={onClickOutside}
							>
								@{data.sentByUsername || `User ${data.sentBy}`}
							</Link>
								{
									data.type === "deletePayment" ?
									` wants to remove ${paymentData.refunded ? "the refunded" : "a" } payment of $${getNum(parseFloat(paymentData.amount) / 100, 2)} in `
									: ""
								}
								{
									data.type === "addPayment" ?
									` has indicated a ${paymentData.paymentName} payment of $${getNum(parseFloat(paymentData.amount) / 100, 2)} to `
									: ""
								}
								{
									data.type === "acceptBill" ?
										" wants to accept "
									: ""
								}
								{
									data.type === "followRequest" ? 
									" wants to follow "
									: ""
								}

								{
									data.type === "editRequest" ? 
									" should have edit capabilities in "
									: ""
								}

							<Link
								className="link-appearance"
								to={(location) => ({
									...location,
									pathname: data.docLink.pathname,
								})}
								onClick={onClickOutside}
							>
								{resLinkName}
							</Link>
							&nbsp;&nbsp;
							<span>
							{
								data.type === "addPayment" ?
								<MoreInfoIcon
									absolute={true}
									text="Click Approve once you have received this payment" 
								/>
								: ""
							}
							</span>
							{
								data.type === "acceptBill" ?
									" as their bill "
								: ""
							}

							{
								data.type === "editRequest" ? 
								" since they have an invoice or bill in this project."
								: ""
							}
						</div>
						<div className="action-buttons-container">
							{!data.read && (
								<React.Fragment>
									<button
										className="button-appearance tiny no-icon-or-underline"
										onClick={() => {
											if (data.type === "addPayment") {
												return handleApprovePayment(data)
											}

											if (data.type === "deletePayment") {
												return handleRemovePayment(data)
											}
											if (data.type === "acceptBill") {
												const newBillTo = data.additional
													? data.additional.billTo
													: null;
												return handleSetNewBillTo(data, newBillTo);	
											}
											if (data.type === "followRequest") {
												handleAcceptFollowerOrEditor(data)	
											}

											if (data.type === "editRequest") {
												handleAcceptFollowerOrEditor(data)	
											}

										}}
									>
										Approve
									</button>
									{"  "}
									<button
										className="button-appearance tiny no-icon-or-underline"
										onClick={() =>
											onRemoveNotification(data)
										}
									>
										Decline
									</button>
								</React.Fragment>
							)}
						</div>
					{
						timeSincePosted ?
						<div className="post-time" >{timeSincePosted}</div>
						: ""
					}
					</div>
				</div>
			</div>
	)
}

const NotificationsModal = ({ setNotificationsModalOpen, setLoginModalOpen }) => {
	const {
		userNotifications,
		removeNotification,
		updateNotification,
		privateNotificationsRef,
		// invoiceNotifications,
		// setInvoiceNotifications,
		// projectNotifications,
		// setProjectNotifications,
		alreadyFetchedUsers, 
		handleSetAlreadyFetchedUsers,
	} = useContext(NotificationsContext);

	const {
		currentUser,
		offlineMode,
		handleSetSuccessText,
		setErrorObj,
		userIsAnonymous,
		userObject,
		newUserData,
		personalNotifications,
		setPersonalNotifications,
	} = useContext(UserContext);
	const {
		usersThreads,
	} = useContext(MessagingContext);
	const {isLessThan700px} = useContext(MediaQueryContext);

	const [notificationsToDelete, setNotificationsToDelete] = useState([])
	const personalNotificationsTypes = ["jobPost"]

	// const [modifiedUserNotifications, setModifiedUserNotifications] = useState(
	// 	[]
	// );

	// userNotifications should just be whats in firestore

	// hold the removed notifications in state so that we can animate
	// const [removedNotifications, setRemovedNotifications] = useState([])
	const isMounted = useRef(null)
	useEffect(() => {
		isMounted.current = true
	  return () => {
	  	isMounted.current = false
	  }
	}, [])

	// run any delete operations on unMount
	useEffect(() => {
		return () => {
			if (isMounted.current === false) {
			  return Promise.all(
			  	notificationsToDelete.map(n => {
						let docRef
						if (personalNotificationsTypes.includes(n.type)) {
							docRef = privateNotificationsRef.doc(n.id)
						} else {
							docRef = firebase.firestore().collection("notifications").doc(n.id)
						}
				  	return removeNotification(n, userObject.id, docRef, false)
				  })
			  )
				
			}
		}
		// onlywant to  run once
		// eslint-disable-next-line
	}, [notificationsToDelete])

	const handleReadNotification = (obj) => {
		let docRef
		if (!personalNotificationsTypes.includes(obj.type)) {
			console.error("obj.type must be one from personalNotificationsTypes. Cant add a read:true property to shared notification")
			return
		}

		docRef = privateNotificationsRef.doc(obj.id)
		return updateNotification({...obj, read: true}, docRef)
	}

	const handleRemoveNotification = (obj) => {
		// let docRef
		// if (personalNotificationsTypes.includes(obj.type)) {
		// 	docRef = privateNotificationsRef.doc(obj.id)
		// } else {
		// 	docRef = firebase.firestore().collection("notifications").doc(obj.id)
		// }

		let newObj = {...obj}
		newObj = {...newObj, deleted: true}
		setNotificationsToDelete(notificationsToDelete => [...notificationsToDelete, newObj])
		return
	}

	const sortByTime = (list) => {
		// sorts by mostRecentEntryDate with newest date first
		let sortableItems = [];
		let nonSortableItems = [];

		list.forEach((item) => {
			if (item.timestamp) {
				sortableItems.push(item);
			} else if (item.date) {
				const timestamp = Math.round(new Date(item.date).valueOf() / 1000)
				sortableItems.push({...item, timestamp});
			} else {
				nonSortableItems.push(item);
			}
		});

		sortableItems = sortableItems.sort((a, b) => {
			const d1 = new Date(a.timestamp);
			const d2 = new Date(b.timestamp);
			// sort by most recent on top
			return d2 - d1;
		});

		return [...sortableItems, ...nonSortableItems];
	};

	const displayNotifications = sortByTime([
		...userNotifications,
	])


	let upgradeAnonymousAccountLink = "?action=promtpLogin&signup=true"

	const onClickOutside = () => {
		setNotificationsModalOpen(false)
	}

	if (userObject.id && newUserData.email) {
		// add in newUserData to the link in case the user has just edited their data
		let params =  {name: "", email: "", phone: "", username: ""}
		params.name = newUserData.name || (newUserData.firstname + " " + newUserData.lastname).trim()
		if (!newUserData.email.includes("sample")) {
			params.email = newUserData.email
		}
		if (newUserData.phone) {
			params.phone = newUserData.phone
		}
		if (newUserData.username && newUserData.username !== userObject.username) {
			params.username = newUserData.username
		}

		for (let key in params) {
			if (params[key]) {
				upgradeAnonymousAccountLink += `&${key}=${params[key]}`
			}
		}
	}


	if (!userObject.id) {
		return (
			<Modal
				custom={{ absolute: true }}
				onClickOutside={onClickOutside}
			>
				<div className="heading">Notifications</div>
			</Modal>
		);
	} else {

		const accountCreationMS = parseInt(currentUser.metadata.createdAt)
		const msSinceCreation = Date.now() - accountCreationMS 
		const dateAccountExpiresIfAnon = new Date(accountCreationMS + (30 * msInADay))
		const timeTilAccountDeletedIfAnon = getTimeRemaining(dateAccountExpiresIfAnon)
		let customStyle = ''

		if (isLessThan700px) {
			customStyle = `
				.container {
					padding-left: 0px;
					padding-right: 0px;
					width: 100%;
				}
			`
		}

		return (
			<NotificationsModalStyle isLessThan700px={isLessThan700px}>
				<Modal
					custom={{ 
						absolute: true, 
						style: customStyle
					}}
					onClickOutside={onClickOutside}
					key={displayNotifications}
				>
					<div className="heading">Notifications</div>
					<div style={{width: "100%", position: "relative"}}>
						<div style={{width: "100%", position: "absolute"}} >
							<AppMessage dependants={[isMounted.current]} />
						</div>
						<div className="section-divider" />
						<div className="section-divider" />
					</div>
					{
						// show if created more than 1 min ago
						(userIsAnonymous && msSinceCreation > 60000) ? 
						  // show a notification reminding user that their account will be deleted if anon
							<div>
					  		You are using a demo account! Any changes you make may not be saved and this account and all it's data will be <span className="error-text">deleted in {Math.floor(timeTilAccountDeletedIfAnon.days)} days!</span>
					  		<div className="small-line-break" />
					  		<Link to={upgradeAnonymousAccountLink} onClick={() => setLoginModalOpen(true)} className="link-appearance blue">Sign up</Link>
					  		{" to change this"}
							</div>
						: null
					}
					<NewMessageNotifications 
						usersThreads={usersThreads}
						onClickOutside={onClickOutside}
						userObject={userObject}
						alreadyFetchedUsers={alreadyFetchedUsers}
						handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers}
					/>

					{displayNotifications.map((obj, i) => {
						if (obj.type === "deletePayment" || obj.type === "addPayment" || obj.type === "acceptBill" || obj.type === "followRequest" || obj.type === "editRequest") {
							return (
								<NotificationStyle key={i} >
									<DocumentNotification 
										notificationUpdates={obj} 
										key={i}
										userObject={userObject} 
										setErrorObj={setErrorObj} 
										offlineMode={offlineMode}
										handleRemoveNotification={handleRemoveNotification} 
										handleSetSuccessText={handleSetSuccessText}
										// invoiceNotifications={invoiceNotifications}
										// setInvoiceNotifications={setInvoiceNotifications}
										// projectNotifications={projectNotifications}
										// setProjectNotifications={setProjectNotifications}
										alreadyFetchedUsers={alreadyFetchedUsers}
										handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers}
										onClickOutside={onClickOutside}
								/>
								</NotificationStyle>
							);
						} else if (obj.type === "jobPost") {
							return (
								<NotificationStyle isLessThan700px={isLessThan700px} key={i} >
									<JobPostNotification 
										data={obj} 
										key={i}
										userObject={userObject} 
										setErrorObj={setErrorObj} 
										handleReadNotification={handleReadNotification} 
										handleRemoveNotification={handleRemoveNotification}
										personalNotifications={personalNotifications} 
										setPersonalNotifications={setPersonalNotifications}
										alreadyFetchedUsers={alreadyFetchedUsers}
										handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers}
										onClickOutside={onClickOutside}
									/>
								</NotificationStyle>
							);
						} else {
							console.warn("obj.type " + obj.type + " not implemented")
							return null
						}
					})}
				</Modal>
			</NotificationsModalStyle>
		);
	}
};

export default NotificationsModal;
