import styled from 'styled-components';


export const StripeLineItems = styled.div `
  text-align: left;
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .inline-number-input {
    width: 10ch;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    font-size: 16px;
    padding: 0;
    height: 2ch;
    vertical-align: text-bottom;
    text-align: right;
  }

`

export const StripeFormStyles = styled.div `
  --theme-gray: #6772e5;
  
  /*
  ${({paymentMethod}) => paymentMethod === "credit" && `
    padding: 15px 5px 15px 5px;
    box-shadow: 0px 0px 11px 4px lightgrey;
    border-radius: 5px;
  `}
  */

  .result-message {
    line-height: 22px;
    font-size: 16px;
  }

  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }

  .hidden {
    display: none;
  }

  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }

  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }

  #payment-request-button {
    margin-bottom: 32px;
  }

  #powered-by-stripe {
    width: 35%;
    padding: 3px;
  }

  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: var(--theme-gray);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: var(--theme-gray);
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  .spinner.inverse {
    color: var(--theme-gray);

  }

  .spinner.inverse:before {
    background: #ffffff;
  }

  .spinner.inverse:after {
    background: #ffffff;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }

  * {
    box-sizing: border-box;
  }

  .DemoPickerWrapper {
    padding: 0 12px;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
  }

  .DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;

    -webkit-appearance: none;
  }

  .DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
  }

  label, .total-amount {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }
  .total-amount {
    font-weight: 700;
    font-family: "Source Code Pro",monospace;
    font-size: 18px;
    position: relative;
    .spinner {
      position: absolute;
      right: 50%;
      top: 0;
    }
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: var(--theme-gray);
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .StripeElement.PaymentRequestButton {
    height: 40px;
  }

`