// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import './polyfills'
import * as serviceWorker from './serviceWorker'

import App from './App'
import { UserContextProvider } from './contexts/UserContext'
import { MediaQueryContextProvider } from './contexts/MediaQueryContext'
import { AppMessageContextProvider } from './contexts/AppMessageContext'
import { NotificationsContextProvider } from './contexts/NotificationsContext'
import { DialogContextProvider } from './contexts/DialogContext'
import { MessagingContextProvider } from './contexts/MessagingContext'


import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePublicKey = "pk_live_51IaikqDllOrYuqccqsiGn4vkYsckLSGJMq5WsMMcEavFRDPgkBInGnnSTckszEwwXoJWD6paTcdHHkiqKYqJPhsG00rY5HpuDJ"
// const stripePublicKey = "pk_test_51IaikqDllOrYuqccYJpkX6oWBfGLKfdXBjTfAykwyQw2PXGT1XELgcIiCdUPbBpwwdVfmthD54Fp8Ddj3caOMS5q00ocq9sFLr"
let stripePromise = loadStripe(stripePublicKey)

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
	<MediaQueryContextProvider>
		<AppMessageContextProvider>
			<DialogContextProvider>
				<UserContextProvider>
					<NotificationsContextProvider>
						<MessagingContextProvider>
							<Elements stripe={stripePromise}>
									<Router>
										<App stripePublicKey={stripePublicKey} />
									</Router>
							</Elements>
						</MessagingContextProvider>
					</NotificationsContextProvider>
				</UserContextProvider>
			</DialogContextProvider>
		</AppMessageContextProvider>
	</MediaQueryContextProvider>
	)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }

