import React, { useState, useEffect } from "react";
import firebase from "../firebase/index";

const AppMessageContext = React.createContext();

const AppMessageContextProvider = ({ children }) => {
	const [successText, setSuccessText] = useState("");
	const [errorText, setErrorText] = useState("");
	const [errorObj, setErrorObj] = useState(null);
	const [textClassName, setTextClassName] = useState("hidden");
	const [messageClassName, setMessageClassName] = useState("hidden");
	const [offlineMode, setOfflineMode] = useState(true);
	const [message, setMessage] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const [doNotTerminateFetching, setDoNotTerminateFetching] = useState(false);


	const timeout = (resTime, callback) =>
		setTimeout(
			() => (callback ? callback() : setTextClassName("hidden")),
			resTime
		);

	const handleSetSuccessText = (newText, time) => {
		let resTime = time;
		if (!time) {
			resTime = 5000;
		}
		handleSetMessage("");
		setSuccessText("");
		setSuccessText(newText);
		setTextClassName("visible");
		timeout(resTime, () => setTextClassName("hidden"));
		timeout(resTime + 4000, () => setSuccessText(""));
	};

	const handleSetMessage = (component, metaData) => {
		setMessage({ component, code: metaData ? metaData.code : "" });
		if (messageClassName !== "visible") {
			setMessageClassName("visible");
		}
	};

	useEffect(() => {
		const connectedRef = firebase.database().ref(".info/connected");
		connectedRef.on("value", function (snap) {
			if (snap.val() === true) {
				setOfflineMode(false);
			} else {
				setOfflineMode(true);
			}
		});
	}, []);

	return (
		<AppMessageContext.Provider
			value={{
				successText,
				setSuccessText,
				handleSetSuccessText,
				textClassName,
				setTextClassName,
				timeout,
				errorText,
				setErrorText,
				errorObj,
				setErrorObj,
				offlineMode,
				setOfflineMode,
				messageClassName,
				setMessageClassName,
				message,
				setMessage,
				handleSetMessage,
				isFetching,
				setIsFetching,
				doNotTerminateFetching,
				setDoNotTerminateFetching,
			}}
		>
			{children}
		</AppMessageContext.Provider>
	);
};

export { AppMessageContextProvider, AppMessageContext };
