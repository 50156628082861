import styled from 'styled-components'

export const MenuStyle = styled.div `
	margin: 40px 20px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  // overflow-y: scroll;
	width: 100%;
	height: 100%;
  position: fixed;
  align-items: unset;
  margin: 0;
  z-index: 100;
  background-color: rgba(10, 10, 10, 0.65);
  a {
  	text-decoration: none;
  	color: inherit;
  }
  ul {
  	padding: 10px 0 0 0;
  	margin: 0;
  	display: flex;
  	justify-content: space-around;
  	flex-direction: column;
  	height: 100%;
  }
	li {
    list-style: none;
    padding: 10px;
    margin: 0px 4px 1px 4px;
    cursor: pointer;
    box-shadow: 0px 4px 11px -9px;
    background-color: white;
    :hover {
    	color: black;
    	background-color: var(--nav-item-hover);
    }
	}
	.close {
		top: 10px;
		left: 10px;
		position: absolute;
		transition: width .2s ease;
	}
	.hide-menu {
		width: 1%;
	}
	.container {
		transition: width .2s ease;
		margin: 0;
		top: 0;
		right: 0;
		width: 50%;
		max-width: 300px;
		height: 100%;
		// border-radius: 4px 0 0 4px;
		padding: 20px 1px 20px 1px;
		position: relative;
		color: var(--chicago);
		// background-color: white;
	  background-color: var(--off-white);
	}
	.network-status {
		padding: 10px 5px;
		text-align: right;
		top: 0;
		right: 0;
		position: absolute;
		font-size: 10px;
	}
`