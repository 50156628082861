import React, { useState, useContext, useEffect } from 'react'

import { UserContext } from '../../contexts/UserContext'
import { DialogContext } from "../../contexts/DialogContext";

import Form from '../Forms/Form'
import { AppMessage } from '../MessageUtils'

// import MoreInfoIcon from '../MoreInfoIcon'
import { getStars } from './UserRating'
import { getNum } from '../../utils/appUtils'
import firebase from '../../firebase/index'

const LeaveReviewForm = ({resultUserData, setReviewModalOpen, userRatings, setUserRatings}) => {
	const { dialog } = useContext(DialogContext);
	const {
		currentUser,
		userObject,
		setErrorObj,
		handleSetSuccessText,
		usersProjects,
		// setUsersProjects,
		getUsersProjects,
		usersInvoices,
		getUsersInvoices
		// setUsersInvoices
		// errorObj,
	} = useContext(UserContext)

	const [viewerIsProvider, setViewerIsProvider] = useState(false)
	const [viewerIsCustomer, setViewerIsCustomer] = useState(false)
	const defaultFormData = {
		overall: 5,
		// reviewer: "",
		text: "",
		title: "",
		// username: "",
		isAnonymous: false,
		unverifiesCustomerRating: false,
		// customer: {},
		// worker: {}
	}
	const [formData, setFormData] = useState(defaultFormData)
	const [isNewRating, setIsNewRating] = useState(true)

	const formDataSortStructure = ["quality", "punctuality", "price", "text"/*, "overall"*/]

	const updateOverallScore = (object, objectName) => {
		let newScore = 0
		let denominator = 0
		let addProperties = ["quality", "punctuality", "price"]
		for (let key in object) {
			const value = object[key]
			if (objectName === "worker" || objectName === "customer") {
				if (addProperties.includes(key)) {
					newScore += value || 0
					denominator += 1
				}
			}
			if (objectName === "formData") {
				if (key === "worker" || key === "customer") {
					newScore += value.overall || 0
					denominator += 1
				}
			}
		}

		newScore = getNum(newScore / denominator, 1)

		return newScore
	}

	const handleChange = (name, val) => {
		let propertiesArray = name.split("-")
		// cant do this in a for loop, have to do it in stages, 
		// if propertiesArray.length === 1 ...
		let newFormData = {...formData}

		if (propertiesArray.length === 1) {
			// setFormData(formData => ({...formData, [name]: val}))
			newFormData = {...newFormData, [name]: val}
		} else if (propertiesArray.length === 2) {
			let newProperty0 = {
				...formData[propertiesArray[0]], 
				[propertiesArray[1]]: val
			}

			// update the inner overall score
			if ((propertiesArray[0] === "worker" || propertiesArray[0] === "customer") && propertiesArray[1] !== "overall") {
				newProperty0 = {
					...newProperty0,
					overall: updateOverallScore(newProperty0, propertiesArray[0])
				}
			}
			// setFormData(formData => ({...formData, [propertiesArray[0]]: newProperty0}))
			newFormData = {...newFormData, [propertiesArray[0]]: newProperty0}
		} else if (propertiesArray.length === 3) {
			const newProperty1 = {...formData[propertiesArray[0]][propertiesArray[1]], [propertiesArray[2]]: val}
			const newProperty0 = {...formData[propertiesArray[0]], [propertiesArray[1]]: newProperty1}
			// setFormData(formData => ({...formData, [propertiesArray[0]]: newProperty0}))
			newFormData = {...newFormData, [propertiesArray[0]]: newProperty0}
		} else {
			console.log("error propertiesArray too long", propertiesArray)
			return
		}


		// update the main overall score
		if (propertiesArray[1] === "price" || propertiesArray[1] === "quality" || propertiesArray[1] === "punctuality" || propertiesArray[1] === "overall") {
			newFormData = {
				...newFormData,
				overall: updateOverallScore(newFormData, "formData")
			}
		}

		setFormData(newFormData)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const userId = currentUser.uid
		try {
			let newFormData = {...formData}
			if (newFormData.isAnonymous) {
				newFormData.username = ""
				newFormData.reviewer = ""
			} else {
				newFormData.username = userObject.username
				newFormData.reviewer = userObject.firstname || ""
			}

			const newUserReviewObject = {
				...newFormData,
				date: firebase.firestore.FieldValue.serverTimestamp(),
				seconds: firebase.firestore.Timestamp.now().seconds
			}

			await firebase.firestore().collection("users").doc(resultUserData.id).collection("ratings").doc(userId).set({
				...newUserReviewObject
			}, { merge: true }).catch(err => {
				throw err
			})

			setUserRatings(userRatings => ({
				...userRatings,
				[userId]: newUserReviewObject
			}))
			setReviewModalOpen(false)
			if (isNewRating) {
				handleSetSuccessText("Review submitted!")
			} else {
				handleSetSuccessText("Review updated!")
			}
		} catch (err) {
			setErrorObj(err)
		}
	}

	const checkViewerIsCustomerOrProvider = async (resultUserData) => {
		// viewer can only get projects/invoices in which viewer is listed as accessor
		// if viewer is billto of any projects/invoices
			// if doc is Project
				// if userShown is owner of this doc, viewer can leave a worker review (How was X as a worker)
			// if doc is Invoice (contains a contractor property)
				// if userShown is contractor of this doc, viewer can leave a worker review (How was X as a worker)
		// if userShown is billTo of any projects/invoices
			// if doc is Project
				// if viewer is owner of this doc, viewer can leave a customer review (how was X as a customer)
			// if doc is Invoice (contains a contractor property)
				// if viewer is contractor of this doc, viewer can leave a customer review (how was X as a customer)


		// when it comes to Projects, only the owner of the project can leave a customer review based on the project alone
		// if there are contractors attaching invoices to a project they will be listed as a contractor on a project 
		// but cant review the main Customer of the project (the project's BillTO)
		// however, if an invoice within the project is accepted by the project's billTo the invoice's contractor can leave a customer review based on that
		// when a customer accepts a project as their bill, any editors of that project can automatically add Project bill to as their new invoices billTO... as of 2021/01/16

		try {
			let newUsersProjects = []
			let newUsersInvoices = []

			if (usersProjects && usersProjects.length) {
				newUsersProjects = usersProjects
			} else {
				newUsersProjects = await getUsersProjects({userId: currentUser.uid, caller: "LeaveReviewForm.js: checkViewerIsCustomerOrProvider"})
				// setUsersProjects(newUsersProjects)
			}

			if (usersInvoices && usersInvoices.length) { 
				newUsersInvoices = usersInvoices
			} else {
				newUsersInvoices = await getUsersInvoices({userId: currentUser.uid, caller: "LeaveReviewForm: checkViewerIsCustomerOrProvider"})
				// setUsersInvoices(getUsersInvoices)
			}
			const allDocs = [...newUsersProjects, ...newUsersInvoices]
			let isCustomer = false // current user hires the user being viewed
			let isProvider = false // current user does work for the user being viewed
			for (let i=0; i<allDocs.length; i++) {
				const doc = allDocs[i]
				const viewerIsContractorOrProjectOwner = () => {
					if (doc.contractor) { // if doc is not a Project
						return doc.contractor.id === currentUser.uid
					} else {
						return doc.owner === currentUser.uid
					}
				}

				const userShownIsContractorOrProjectOwner = () => {
					if (doc.contractor) { // if doc is not a Project
						return doc.contractor.id === resultUserData.id
					} else {
						return doc.owner === resultUserData.id
					}
				}

				if (!isProvider && doc.billTo.uid === resultUserData.id && viewerIsContractorOrProjectOwner()) {
					setViewerIsProvider(true)
					isProvider = true
				}
				if (!isCustomer && doc.billTo.uid === currentUser.uid && userShownIsContractorOrProjectOwner()) {
					setViewerIsCustomer(true)
					isCustomer = true
				}
				if (isCustomer && isProvider) {
					// we have all the data we need, exit out of for loop
					break
					// return
				}
			}

		} catch (err) {
			setErrorObj(err)
		}
	}

	const handleDeleteReview = async (e) => {
		e.preventDefault()
		try {
			const userId = firebase.auth().currentUser.uid
			let confirmRemove = await dialog.confirm("Confirm delete review")
			if (confirmRemove) {
				await firebase.firestore().collection("users").doc(resultUserData.id).collection("ratings").doc(userId).delete()

				setUserRatings(userRatings => {
					const newRatings = {...userRatings}
					delete newRatings[userId]
					return newRatings
				})
				setReviewModalOpen(false)
				handleSetSuccessText("Review deleted successfully")
			} else return
		} catch (err) {
			setErrorObj(err)
		}

	}

	useEffect(() => {
		if (currentUser && resultUserData) {
			setFormData(formData => ({
				...formData,
				reviewer: userObject.firstname || "",
				username: userObject.username || ""
			}))
			// if (userObject && resultUserData && userObject.id !== resultUserData.id) {
				// the leave review button should not show up if user is self
				checkViewerIsCustomerOrProvider(resultUserData)
			// }

			// if the current user has already left a review
			if (userRatings && userRatings[currentUser.uid]) {
				// could use a sorting fn here before setting form data
				setIsNewRating(false)
				setFormData(formData => ({...formData, ...userRatings[currentUser.uid]}))
			}
		}
		// eslint-disable-next-line
	}, [currentUser, userObject, userRatings, resultUserData])

	useEffect(() => {	
		// customers can leave worker reviews
		if (viewerIsCustomer && !formData.worker) {
			// set defaults for a worker review
			setFormData(formData => ({
				...formData, 
				worker: {
					quality: 5,
					punctuality: 5,
					price: 5,
					text: "",
					overall: 5
				}
			}))
		}

		// workers can leave customer reviews
		if (viewerIsProvider && !formData.customer) {
			// set defaults for a customer review	
			setFormData(formData => ({
				...formData, 
				customer: {
					quality: 5,
					punctuality: 5,
					text: "",
					overall: 5
				}
			}))
		}
		// eslint-disable-next-line
	}, [viewerIsCustomer, viewerIsProvider])


	const getInputs = (formData) => {
		let inputs = [
			{
				label: "Review title", 
				onChange: handleChange,
				properties: {
					type: "text",
					id: "title",
					value: formData.title,
					// autoFocus: "autoFocus",
				},
				beforeInput: [
					<div key="overall-section" className="section-title" >
						Overall Rating
					</div>,
					<div key="overall-stars" className="overall-stars-container">
						{getStars(formData.overall, (i) => handleChange("overall", i + 1))}
						<span> {formData.overall}</span>
						<div className="small-line-break" />
					</div>
				]
			},
			{
				custom: true,
				label: "Comment", 
				properties: {
					type: "textarea",
					name: "text",
					value: formData.text,
					placeholder: "review message",
					// autoFocus: "autoFocus",
					onChange: handleChange,
					className: "textarea-label"
				}
			},
			{
				// visible: (!viewerIsProvider && !viewerIsCustomer),
				label: `${resultUserData.firstname || resultUserData.username} is my customer`,
				onChange: handleChange,
				properties: {
					type: "radio",
					name: "unverifiesCustomerRating",
					id: "isCustomerRating",
					value: true,
					checked: formData.unverifiesCustomerRating,
				}
			},
			{
				// visible: (!viewerIsProvider && !viewerIsCustomer),
				label: `${resultUserData.firstname || resultUserData.username} does work for me`,
				onChange: handleChange,
				properties: {
					type: "radio",
					name: "unverifiesCustomerRating",
					id: "isWorkerRating",
					value: false,
					checked: !formData.unverifiesCustomerRating,
				}
			}
		]

		// sometimes firebase changes the order of object properties 
		const sortSpecificInputs = (specificInputs) => {
			let sortedInputs = []
			// let sortStructure = ["quality", "punctuality", "price", "text", "overall"]
			formDataSortStructure.forEach(sortByName => {
				const inputInSpecificInputs = specificInputs.find(obj => {
					const reviewTypeKey = obj.forReviewTypeKey
					return reviewTypeKey === sortByName
				})
				if (inputInSpecificInputs) {
					sortedInputs.push(inputInSpecificInputs)
				}
			})

			// for (let i=0; i<specificInputs.length; i++) {
			// 	const input = specificInputs[i]

			// 	let spot = sortStructure.indexOf(input.name)
			// 	if (i === 0) {
			// 		sortedInputs.push()
			// 	}
			// }

			return sortedInputs
		}

		const getSpecificInputs = (reviewType) => {
			let ratingTypes = []
			if (reviewType === "customer") {
				ratingTypes = ["overall", "punctuality", "quality"]
			}
			if (reviewType === "worker") {
				ratingTypes = ["overall", "punctuality", "quality", "price"]
			}
			let specificInputs = []

			for (let key in formData[reviewType]) {
				// const value = formData[reviewType][key]
				let labelName = key[0].toLocaleUpperCase() + key.slice(1)
				if (key === "quality" && reviewType === "customer") {
					labelName = "Easy to work for"
				}
				if (key === "punctuality" && reviewType === "customer") {
					labelName = "Pays on time"
				}
				if (key === "text") {
					specificInputs.push({
						custom: true,
						label: "Comment",
						forReviewTypeKey: key,
						properties: {
							type: "textarea",
							name: `${reviewType}-${key}`,
							value: formData[reviewType][key],
							placeholder: `${reviewType} review message`,
							// autoFocus: "autoFocus",
							onChange: handleChange,
							className: "textarea-label"
						}
					})
				} else if (ratingTypes.includes(key)) {
					specificInputs.push({
						label: "", 
						visible: true,
						forReviewTypeKey: key,
						properties: {
							type: "hidden",
							id: `${reviewType}-${key}`
						},
						beforeInput: [
							<div key="stars">
								{getStars(formData[reviewType][key], (i) => handleChange(`${reviewType}-${key}`, i + 1))}
								&nbsp;<span>{labelName}</span>
							</div>
						]
					})
				}
			}

			return [
				{
					label: "", 
					visible: true,
					forReviewTypeKey: "title",
					properties: {
						type: "hidden",
						id: `${reviewType}-section-title`
					},
					beforeInput: [
						<div key={`${reviewType}-section`} className="section-title">
							<div className="section-divider" />
							How was {resultUserData.firstname || resultUserData.companyName || resultUserData.username} as a {reviewType}?
						</div>
					]
				},
				...sortSpecificInputs(specificInputs)
			]
		}

		if (formData.worker) {
			inputs.push(...getSpecificInputs("worker"))
		}

		if (formData.customer) {
			inputs.push(...getSpecificInputs("customer"))
		}

		inputs = [
			...inputs,
			{
				label: "Anonymous Review",
				onChange: handleChange,
				properties: {
					type: "checkbox",
					id: "isAnonymous",
					value: formData.isAnonymous ? false : true,
					checked: formData.isAnonymous ? true : false 
				},
				afterInput: [
					<div key="posting-as">
						<div className="small-line-break" />
						<div className="section-title" >Posting as</div>
						{
							formData.isAnonymous ? 
							<div>Anonymous</div>
							:
							<React.Fragment>
								<div>{userObject.firstname ? `Name: ${userObject.firstname}` : ""}</div>
								<div>User: @{userObject.username}</div>
							</React.Fragment>
						}
						{
							!isNewRating &&
							<React.Fragment>
								<div className="section-divider" />
								<div>
									<span onClick={handleDeleteReview} className="link-appearance" >Delete Review</span>
								</div>
							</React.Fragment>
						}
					</div>
				]
			}
			// {
			// 	label: "Name", 
			// 	onChange: handleChange,
			// 	visible: !formData.isAnonymous,
			// 	properties: {
			// 		type: "text",
			// 		id: "reviewer",
			// 		value: formData.reviewer,
			// 		required: true,
			// 	},
			// 	beforeInput: [
			// 		<div key="before-name-section" className="section-divider" />
			// 	]
			// },
			// {
			// 	label: "Username", 
			// 	onChange: handleChange,
			// 	visible: !formData.isAnonymous,
			// 	properties: {
			// 		type: "text",
			// 		id: "username",
			// 		value: formData.username,
			// 		// required: true,
			// 	},
			// 	afterInput: [
			// 		<div key="after-username" className="section-divider" />
			// 	]
			// }
		]
		return inputs
	}

	return (
		<Form 
			heading={
				<React.Fragment>
					<div className="heading align-center">{isNewRating ? "Leave a Review" : "Update Review"}</div>
					<AppMessage dependants={[formData]} scrollIntoView={true} />
				</React.Fragment>
			}
			onSubmit={handleSubmit}
			inputs={getInputs(formData)}
		>
			<div className="section-divider" />
			<div className="section-divider" />
			<div className="align-center submit-actions">
				<button className="button-appearance" >
					{isNewRating ? "Submit" : "Update"}
				</button>
				<button className="button-appearance gray" onClick={(e) => {
					e.preventDefault()
				 setReviewModalOpen(false)
				}} >
					Cancel
				</button>
			</div>
		</Form>
	)
}

export default LeaveReviewForm