import styled from 'styled-components'

export const DotActionStyle = styled.div `
  position: absolute;
  top: 0;
  right: 0;
	font-size: inherit;
  font-size: 14px;
	width: 100%;
  display: flex;
  justify-content: flex-end;

  details.main:hover {
  	opacity: 1;
  }

	details.main {
		opacity: 0.4;
		width: min-content;
    height: fit-content;
	  /*transition: width 0.1s linear;*/  
	  background-color: #b9b8b8f0;
	  position: relative;
    border-radius: 5px;
	  color: black;
	  outline: none;
	  padding: 2px 3px 0 3px;

  	.dots-icon {
/*		  opacity: 1;
		  display: initial;*/
		  width: 18px;
      padding: 0 2px 4px 2px;
      vertical-align: top;
  	}
/*  	#close-icon {
	    opacity: 0;
		  display: none;
		  width: 14px;
      padding: 3px;
  	}*/

	  > summary {
		  /*opacity: 0.8;*/
		  outline: none;
		  width: 100%;
		  text-align: right;
		  list-style: none !important;
		  height: 10px;
      /*line-height: 10px;*/
	  }
	  /*need to use -webkit-details-marker for IOS safari*/
		> summary::marker, >summary::-webkit-details-marker {
		  display: none;
		}

		.expand-option {
			div {
				font-size: 12px;
			}
			/*padding-left: 10px;*/
			summary {
				width: 100%;
				text-align: center;
			}
		}
		ul {
	    margin: 0;
	    padding: 0;
      visibility: hidden;
	    height: 0pc;
	    width: 0px;
		}

		li {
			cursor: pointer;
		  font-size: 14px;
			list-style: none;
			text-align: center;
	    /* box-shadow: 1px 6px 3px 1px #ada9a9; */
	    border-bottom: 1px solid gray;
	    width: 100%;
	    line-height: 22px;
	    details {
	    	/*details within the details list*/
		    text-align: center;
				width: 100%;
	    }
		}
	}

	details.main[open]:active, details.main[open]:hover, details.main[open]:focus, details.main[open]:focus-within {
  	opacity: 1;
    width: 100%;
	  height: 100%;
    padding: 2px 2px 10px 2px;
	  z-index: 1;

		ul {
      visibility: visible;
	    height: auto;
	    width: auto;
		}

/*  	#dots-icon {
		  opacity: 0;
		  display: none;
  	}
  	#close-icon {
	    opacity: 1;
		  display: initial;
  	}*/

		/* summary::after {
		  content: "\\2715";
		}*/


	}
`

// export const DotActionStyle = styled.div`
// 	  position: absolute;
// 	  top: -0.2ch;
// 	  right: -0.2ch;
// 		font-size: inherit;



// `