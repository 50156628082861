import firebase from "../firebase/index";
import { removeDuplicates } from "../utils/appUtils";

export const getDefaultNotificationSubscriptions = (
	userObject,
	usersProjects,
	usersInvoices
) => {
	const userId = userObject.id;

	if (!usersProjects) {
		usersProjects = [];
	}

	const getUserRoles = (doc, collection) => {
		let roles = [];
		if (doc.owner === userId) {
			roles.push("owner");
		}
		if (
			collection === "invoices" &&
			doc.contractor &&
			doc.contractor.id === userId
		) {
			roles.push("contractor");
		}
		if (doc.billTo && doc.billTo.uid === userId) {
			roles.push("billTo");
		}
		if (doc.editors && doc.editors.includes(userId)) {
			roles.push("editor");
		}
		if (doc.followers && doc.followers.includes(userId)) {
			roles.push("follower");
		}

		return roles;
	};

	const getDefaultDocSubscriptions = (doc, collection) => {
		if (
			doc.owner === userId ||
			(collection === "invoices" && doc.contractor.id === userId)
		) {
			return [
				"follow",
				"edit",
				"acceptBill",
				"addPayment",
				"payments",
				"docAdded",
			];
		} else if (doc.billTo.uid === userId) {
			return ["follow", "deleted", "workerIsWorking", "paymentDue", "docAdded"];
		} else if (doc.editors.includes(userId)) {
			return ["follow", "docAdded"];
		} else {
			return [];
		}
	};

	const invoicesDocList = ([...usersInvoices || []])
		.map((inv) => {
			// prevent usersInvoices that are noResults: true
			if (inv.id && inv.billTo && !inv.noResults) {
				return {
					id: inv.id,
					roles: getUserRoles(inv, "invoices"),
					subscribedTo: getDefaultDocSubscriptions(inv, "invoices"),
				};
			} else return undefined;
		})
		.filter((notUndefined) => notUndefined);

	const projectsDocList = usersProjects
		.map((proj) => {
			if (proj.id && !proj.noResults) {
				return {
					id: proj.id,
					roles: getUserRoles(proj, "projects"),
					subscribedTo: getDefaultDocSubscriptions(proj, "projects"),
				};
			} else return undefined;
		})
		.filter((notUndefined) => notUndefined);

	return [
		{
			docList: [
				{
					id: userId,
					subscribedTo: [
						"accountMeta",
						"newDoc",
						"newReview",
						"acomplishments",
					],
				},
			],
			type: "forUsers",
		},
		{
			docList: invoicesDocList || [],
			type: "invoices",
		},
		{
			docList: projectsDocList || [],
			type: "projects",
		},
	];
};

export const sendNotification = (notificationObj) => {
	if (!notificationObj) {
		return;
	}
	const newForUsers = notificationObj.forUsers
		? removeDuplicates(notificationObj.forUsers)
		: [];
	const notification = {
		additional: {},
		date: new Date().toISOString(),
		docLink: {
			name: "",
			pathname: "",
			externamLink: "",
		},
		forDocumentCollection: "",
		forDocumentId: "",
		linkTo: "",
		sentBy: "",
		sentByUsername: "",
		// sentFor: "",
		// sentForUsername: "",
		type: "",
		...notificationObj, 
		forUsers: newForUsers,
		accessors: removeDuplicates([
			...newForUsers,
			notificationObj.sentBy,
		]),
	};

	return firebase
		.firestore()
		.collection("notifications")
		.doc()
		.set(notification);
};

export const checkForAcceptBillNotification = ({ forDocumentId }) => {
	return firebase
		.firestore()
		.collection("notifications")
		.where("type", "==", "acceptBill")
		.where("forDocumentId", "==", forDocumentId)
		.get()
		.then((snapshot) => {
			if (snapshot.docs.length) {
				if (snapshot.docs.length > 1) {
					console.log("error more than one accept bill notification");
				}
				return { ...snapshot.docs[0].data(), id: snapshot.docs[0].id };
			}
		});
};

// returns first first notification that matched
export const checkForNotification = ({
	type,
	userKind,
	userId,
	forDocumentId,
}) => {
	// userKind example "sentBy"
	if (userId === "any") {
		// eslint-disable-next-line
		throw { message: "Cannot retrive notification" };
	}
	return firebase
		.firestore()
		.collection("notifications")
		.where(userKind, "==", userId)
		.where("forDocumentId", "==", forDocumentId)
		.where("type", "==", type)
		.get()
		.then((snapshot) => {
			if (snapshot.docs.length) {
				if (snapshot.docs.length > 1) {
					console.log("error more than one notification of type", type);
				}
				return { ...snapshot.docs[0].data(), id: snapshot.docs[0].id };
			}
		});
};
