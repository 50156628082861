import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { EditProjectContext } from '../../contexts/EditProjectContext'

import Modal from '../Modal/Modal'
import DocumentList from '../DocumentList/DocumentList'
import { LayoutIconColumnsSvg, LayoutIconRowSvg } from '..//svgComponents/generalIcons'

// import { handleRemoveInvoice } from '../Invoice/invoiceUtils'
import { getNum } from '../../utils/appUtils'


// rendered by Specific Project
// errors and success text handled
const IndustryModals = ({userId, setModalData, isFetchingDocs, setIsFetchingDocs, sections}) => {

	const {
		// projectData,
		invoiceData,
		setInvoiceData,
		followersUserObjs,
		// handleSetSuccessText,
		// setErrorObj,
		// userCanEdit
	} = useContext(EditProjectContext)

	const getIndustryTotals = (industry, userId) => {
		let totalCost = 0
		let totalIOwe = 0
		let totalOwedToMe = 0
		invoiceData.forEach(inv => {
			if (inv.invoiceTotals) {

				const {laborEntries, materialEntries, paymentEntries, customEntries} = inv.invoiceTotals
				const laborEntriesTotal = laborEntries ? getNum(laborEntries.total, 100) : 0
				const materialEntriesTotal = materialEntries ? getNum(materialEntries.total, 100) : 0
				const paymentEntriesTotal = paymentEntries ? getNum(paymentEntries.total, 100) : 0
				const customEntriesTotal = customEntries ? getNum(customEntries.total, 100) : 0

				const invoiceTotalCost = laborEntriesTotal + materialEntriesTotal + customEntriesTotal

				if (inv.industry === industry) {
					totalCost += invoiceTotalCost
					if (inv.contractor.id === userId) {
						totalOwedToMe += invoiceTotalCost - paymentEntriesTotal
					}
					if (inv.billTo.uid === userId) {
						totalIOwe += invoiceTotalCost - paymentEntriesTotal
					}
				}
			}
		})

		if (totalIOwe < 0) {
			totalOwedToMe += (totalIOwe * -1)
			totalIOwe = 0
		}
		if (totalOwedToMe < 0) {
			totalIOwe += (totalOwedToMe * -1)
			totalOwedToMe = 0
		}

		return {
			// totalCost: Math.round(totalCost * 100) / 100,
			// totalIOwe: Math.round(totalIOwe * 100) / 100, 
			// totalOwedToMe: Math.round(totalOwedToMe * 100) / 100
			totalCost: getNum(totalCost, 100),
			totalIOwe: getNum(totalIOwe, 100), 
			totalOwedToMe: getNum(totalOwedToMe, 100)
		}
	}

	useEffect(() => {
		if (!invoiceData || !sections) {
			setIsFetchingDocs(true)
		} else {
			// if (sections) {
				setIsFetchingDocs(false)
			// }
		}
	}, [invoiceData, sections, setIsFetchingDocs])

	return (
		<div>
			{
				sections && sections.map(sec => {
					const totalsObj = getIndustryTotals(sec.industry, userId)

					return (
						<Modal 
							key={sec.industry} 
							containerHeading={sec.industry ? sec.industry : "Other"}
						>
							{
								invoiceData.length ?
								<React.Fragment>
									<div>Total Cost: ${getNum(totalsObj.totalCost, 2)}
									</div>
									{
										userId &&
										<React.Fragment>
											<div>I Owe: ${getNum(totalsObj.totalIOwe, 2)}
											</div>
											<div>Owed To Me: ${getNum(totalsObj.totalOwedToMe, 2)}
											</div>
										</React.Fragment>
									}
									<div className="small-line-break" />
								</React.Fragment>
								: null
							}
							{
								sec.companyIds.map(id => {
									// find the contractor name by using the data from the first invoice matching the contractors ID
									const invoiceOfContractor = invoiceData.find(inv => inv.contractor.id === id)/*.contractor*/
									let contractorFollowerObj = followersUserObjs.find(user => user.id === id)
									if (!contractorFollowerObj && invoiceOfContractor) { // wont find invoiceOfContractor on invoice delete if last invoice from contractor
										contractorFollowerObj = invoiceOfContractor.contractor
									}
									// get all the companys invoices for this industry for this project
									const companysInvoices = invoiceData.filter(inv => (
										inv.contractor.id === id && inv.industry === sec.industry
									))

									let userCanAddDoc = false
									if (id === userId) {
										userCanAddDoc = true
									}

									return (
										<React.Fragment key={id}>
											<div style={{position: "relative"}}>
												<div className="small-line-break" />
												<div className="small-line-break" />
												<div className="small-line-break" />
												{
													contractorFollowerObj &&
													<div className="align-left">
														<Link className="medium link-appearance blue" to={`/users/${contractorFollowerObj.username}`}>{contractorFollowerObj.companyName || contractorFollowerObj.username}</Link>	
													</div>
												}
												{
													(userCanAddDoc || invoiceData.length) ?
													<React.Fragment>
														<input className="checkbox-item-layout" id={`${sec.industry}-${id}-item-layout`} type="checkbox" defaultChecked />
														<label className="item-layout" htmlFor={`${sec.industry}-${id}-item-layout`} >
															<LayoutIconColumnsSvg className="columns-layout" />
															<LayoutIconRowSvg className="horizontal-scroll-layout" />
														</label>
														<DocumentList 
															// isLoadingDocs={isLoadingDocs}
															userId={userId}
															collection={userCanAddDoc ? "projects-invoices" : "unspecified"} 
															docList={companysInvoices} 
															setDocList={setInvoiceData} 
															setModalData={() => {
																setModalData(modalData => ({
																	...modalData, 
																	open: true, 
																	type: "invoices", 
																	industry: sec.industry, 
																	contractorId: id
																}))
															}}
															isFetchingDocs={isFetchingDocs}
															setIsFetchingDocs={setIsFetchingDocs}
															withinProject={true}
															userCanAddDoc={userCanAddDoc}
														/>
													</React.Fragment>
													: "-- empty --"
												}
											</div>
										{
										/*{
										return (
											<div key={id} className="align-left">
													<Link className="medium link-appearance blue" to={`/users/${contractorFollowerObj.username}`}>{contractorFollowerObj.companyName || contractorFollowerObj.username}</Link>
												{
													companysInvoices.map((inv, i) => {
														let userCanEditInv = false
														if (currentUser && (inv.editors.includes(currentUser.uid) || (inv.owner === currentUser.uid))) {
															userCanEditInv = true
														}
														return (
															<div key={inv.id}>
																<Link 
																	className="link-appearance blue" 
																	to={`/${inv.contractor.username}/${inv.id}`}  
																>
																	{
																		`- ${(currentUser && inv.billTo.uid === currentUser.uid) ? "Bill" : "Invoice"} #${`${inv.invNumber} ${inv.invShortHand || ""}`}`
																	}
																</Link>
																{
																	userCanEditInv &&
																	<div className="delete-or-add" >
																		{
																			// i === 0 &&
																			// <span 
																			// 	onClick={() => setModalData(modalData => ({
																			// 		...modalData, 
																			// 		open: true, 
																			// 		type: docType, 
																			// 		industry: sec.industry, 
																			// 		contractorId: inv.contractor.id
																			// 	}))}
																			// 	// className="add-invoice"
																			// >
																			// 	<svg viewBox="0 0 32 32" width="12px" height="12px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
																			//     <path d="M16 2 L16 30 M2 16 L30 16" />
																			// 	</svg>
																			// </span>
																		}
																		<span 

																			onClick={() => handleRemoveInvoice(invoiceData, setErrorObj, () => null, dialog)} 
																			// className="delete-invoice"
																		>
																			<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
																		    <path d="M28 6 L6 6 8 30 24 29 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
																			</svg>
																		</span>
																	</div>
																}
															</div>
														)
													})
												}
												{
													userCanEdit &&
													<React.Fragment>
														<div className="small-line-break" />
														<button 
															className="button-appearance small" 
															onClick={() => setModalData(modalData => ({
																...modalData, 
																open: true, 
																type: docType, 
																industry: sec.industry, 
																contractorId: id
															}))}
															// className="add-invoice"
														>
															<svg viewBox="0 0 32 32" width="12px" height="12px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
														    <path d="M16 2 L16 30 M2 16 L30 16" />
															</svg> Add
														</button>
													</React.Fragment>
												}
												<div className="small-line-break" />
											</div>
										)*/
										}
										</React.Fragment>
									)

								})
							}
						</Modal>
					)
				})
			}
		</div>
	)
}

export default IndustryModals
