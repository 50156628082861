import styled from 'styled-components'

export const UserProfileStyle = styled.div `
	text-align: left;
	font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: var(--font-family-poppins);
	.stars {
		svg {
			width: 18px;
			height: 18px;
		}
	}
	.location {
		width: 100%;
		margin-right: 5px;
		color: var(--chicago);
		.location-name {
			text-align: left;
			display: flex;
		  align-items: center;
		}
		.location-icon {
			// margin-top: 2px;
		  color: var(--light-gray);
		}
	}
	.grid-container {
		display: grid;
		grid-template-rows: auto;
	  // grid-template-columns: auto 1fr auto auto auto;
	  grid-template-columns: auto auto 1fr auto auto;
	  grid-gap: 5px;
	  max-width: 700px;
	  margin-left: auto;
	  margin-right: auto;
    width: 100%;
	  .add-post {
	  	text-align: right;
	  }
		.about-container {
			border: 1px solid var(--chicago);
		}
		.actions {
			grid-row: 2;
			grid-column: 1 / -1;
			padding: 10px 0 0 0;
	    border-top: 1px solid var(--chicago);
	    width: 100%;
			.actions-icon-container {
				display: flex;
		    justify-content: flex-end;
	      align-items: flex-start;
		    .button-appearance.tiny {
		    	padding-bottom: 5px;
		    	.material-icons {
		    		font-size: 14px;
		    	}
			  }
				div, a {
			    display: flex;
			    text-align: right;
			    text-decoration: none;
					svg {
						padding-right: 7px;
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.inner-img-container {
			position: relative;
			display: inline-block;
			grid-column: 1 / 3;
	    grid-row: 3 / 7;
	    width: min-content;
			img {
				width: 140px;
			}
		}
		.stars + #text {
			vertical-align: middle;
		}
		.overall-stars-container {
			span {
				font-size: 18px;
		    vertical-align: middle;
			}
			.stars {
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
		.star-rating {
			grid-column: 3 / -1;
	    grid-row: 4;
	    display: flex;
	    width: 100%;
	    .text {
	    	margin: 0 0 0 2ch;
	    }
		}
		.company-name {
			font-size: 25px;
			grid-column: 3 / -1;
	    grid-row: 3;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    width: 100%;
      :hover {
    		// overflow: visible;
    		overflow-wrap: break-word;
      }
		}
		progress {
			grid-column: 1 / 3;
			grid-row: 2;
			width: 100%;
		}
		.auto-set-card-info {
			grid-column: 1 / -1;
		}
		.user-info {
			vertical-align: top;
			display: inline-block;
			font-size: 14px;
			font-family: inherit;
			line-height: inherit;
			width: 100%;
			grid-column: 3 / -1;
	    grid-row: 5 / 7;
	    white-space: break-spaces;
      overflow: hidden;
			p {
				padding: 0;
				margin: 0;
		    text-overflow: ellipsis;
		    overflow: hidden;
		    width: 100%;
	      :hover {
      		// overflow: visible;
      		overflow-wrap: break-word;
	      }
			}
			textarea {
				width: 100%;
				font-family: inherit;
				font-size: 14px;
				line-height: inherit;
				background-color: transparent;
				padding: 0;
			}
		}
		.profile-section-container {
			grid-row: 9;
			grid-column: 1 / -1;
		}
		.profile-navigation {
			grid-row: 7;
			grid-column: 1 / -1;
	    border-top: 1px solid var(--chicago);
		}
		.edit-logo-actions {
			color: black;
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			opacity: 0.8;
	    background-color: white;
      box-shadow: 0 0 13px -6px;
			svg {
				padding: 5px;
		    :hover {
			    background-color: var(--nav-item-hover);
		    }
			}
		}

	}




	.hidden-input {
		opacity: 0;
		height: 1px;
		grid-column: none;
		grid-row: none;
		position: absolute;
		bottom: 0;
		left: 0;
	  z-index: -1;
	}
	.name-input {
		width:70%;
	}
	.rate-input {
		width: 30%;
	}

`
