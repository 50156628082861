import React from 'react'
import { useMediaQuery } from 'react-responsive'

const MediaQueryContext = React.createContext()

const MediaQueryContextProvider = ({children}) => {
	// const [isLessThan700px, setIsLessThan700px] = useState(true)

	const isLessThan700px = useMediaQuery({ maxWidth: 700 })
	const isLessThan415px = useMediaQuery({ maxWidth: 415 })

	// useEffect(() => {
	// 	setIsLessThan700px()
	// }, [])

	return (
		<MediaQueryContext.Provider value={{
			isLessThan700px,
			isLessThan415px
		}}>
			{children}
		</MediaQueryContext.Provider>
	)
}

export {MediaQueryContextProvider, MediaQueryContext}