import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";

import { UserContext } from "../contexts/UserContext";
import { EditProjectContext } from "../contexts/EditProjectContext";
import { DialogContext } from "../contexts/DialogContext";


import IndustryModals from "../components/projectComponents/IndustryModals";
import Modal from "../components/Modal/Modal";
import NewInvoiceForm from "../components/CreateItem/NewInvoiceForm";
import AddPrivateProjectDocs from "../components/projectComponents/AddPrivateProjectDocs";
import ProjectSummary from "../components/projectComponents/ProjectSummary";
// import AcceptBillModal from '../components/Invoice/AcceptBillModal'
import ChangeBillToModal from "../components/Invoice/ChangeBillToModal";
import DocumentList from "../components/DocumentList/DocumentList";
import Spinner from "../components/Spinner/Spinner";

import {
	getFollowersUserDataWithType,
	getUserObjs,
	handleAddFollower,
	handleUnfollow,
	// checkFollowOrEditRequest,
} from "../utils/followUtils";

import firebase from "../firebase/index";
// import { checkForAcceptBillNotification } from '../utils/notificationUtils'
import { removeDuplicates } from "../utils/appUtils";

const ProjectStyle = styled.div`
	margin: 0 10px;
	.page-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
	}
`;

// rendered by: App.js
// errors and successText handled
const SpecificProject = () => {
	const { projectId } = useParams();
	const history = useHistory();

	// const userId = firebase.auth().currentUser
	// 	? firebase.auth().currentUser.uid
	// 	: null;
	const isMountedRef = useRef(null);

	const { dialog } = useContext(DialogContext);
	const {
		projectData,
		setProjectData,
		invoiceData,
		setInvoiceData,
		userIsOwner,
		setUserIsOwner,
		// userIsBillTo,
		setUserIsBillTo,
		userCanEdit,
		setUserCanEdit,
		// userIsFollower,
		setUserIsFollower,
		followersUserObjs,
		setFollowersUserObjs,
		changeBillToModalOpen,
		setChangeBillToModalOpen,
		saveProject,
	} = useContext(EditProjectContext);
	const {
		foundAuthState,
		userObject,
		setUserObject,
		updateUserData,
		usersProjects,
		setUsersProjects,
		// isFetching,
		setIsFetching,
		errorObj,
		setErrorObj,
		handleSetSuccessText,
	} = useContext(UserContext);

	const userId = userObject.id

	const [userCanView, setUserCanView] = useState(null);
	const [isNewDoc] = useState(
		window.history.state && window.history.state.state
			? window.history.state.state.isNewDoc
			: false
	);
	const [addDocsModalOpen, setAddDocsModalOpen] = useState(false);
	// const [followText, setFollowText] = useState("Follow");
	// const [setFollowButtonDisabled, setSetFollowButtonDisabled] = useState(true)
	// const [acceptBillButtonText, setAcceptBillButtonText] = useState("")
	// const [acceptBillModal, setAcceptBillModal] = useState({open: false})
	const [showProjectSunmary, setShowProjectSummary] = useState(false);
	const [billToUserObj, setBillToUserObj] = useState({
		firstname: "",
		lastname: "",
		address: {
			city: "",
			country: "",
			line1: "",
			line2: "",
			postal_code: "",
			state: "",
		},
		uid: "",
		companyName: "",
	});

	const [modalData, setModalData] = useState({
		open: false,
		type: "",
		industry: "",
		contractorId: "",
	});

	const [addedToRecentlyViewed, setAddedToRecentlyViewed] = useState(false);
	const [newCurrentProject, setNewCurrentProject] = useState([]); // must be an array due to selections and set selections in DocumentList
	const [isFetchingDocs, setIsFetchingDocs] = useState(false);
	const [sections, setSections] = useState(null);
	const [didUpdateInvoiceData, setDidUpdateInvoiceData] = useState(false);

	const sortByRecentEntryDate = (invoices) => {
		let sortedInvoices = [...invoices].sort((a, b) => {
			const d1 = new Date(a.mostRecentEntryDate);
			const d2 = new Date(b.mostRecentEntryDate);
			return d2 - d1;
		});

		return sortedInvoices;
	};

	const sortByIndustry = (invoiceData) => {
		// return an array of industries and get all contractors by industry
		// if the industries match get the id of the contractor

		// industries only lits company ids, it does not care about how many invoices that comapny may have
		let industries = [];

		for (let i = 0; i < invoiceData.length; i++) {
			const alreadyListedIndustry = industries.find(
				(ind) => ind.industry === invoiceData[i].industry
			);

			if (alreadyListedIndustry) {
				const alreadyListedCompany = alreadyListedIndustry.companyIds.find(
					(id) => id === invoiceData[i].contractor.id
				);
				if (!alreadyListedCompany) {
					alreadyListedIndustry.companyIds.push(invoiceData[i].contractor.id);
				}
			} else {
				industries.push({
					industry: invoiceData[i].industry,
					companyIds: [invoiceData[i].contractor.id],
				});
			}
		}

		// sort industries in alphabetical order
		industries = industries.sort((a, b) => {
			// if industry === "" move to lowest position eg: "other"
			const aT = a.industry ? a.industry.toUpperCase() : "x";
			const bT = b.industry ? b.industry.toUpperCase() : "x";
			return aT < bT ? -1 : aT > bT ? 1 : 0;
		});

		return industries;
	};

	const handleUnfollowProject = async () => {
		try {
			let confirmUnfollow = true;
			const userIsBillTo = projectData.billTo.uid === userId;
			if (userIsBillTo) {
				confirmUnfollow = false;
				dialog.alert("You cannot unfollow a project which is billed to you")
			}

			if (projectData.visibility === "private") {
				confirmUnfollow = confirmUnfollow
					? await dialog.confirm(
							"Confirm unfollow this project? You will need the project link and the project creators approval to start following this project again"
					  )
					: false;
			}

			if (confirmUnfollow) {
				// handleUnfollow = async (userId, collection, userIsOwner, docId, docEditors)
				await handleUnfollow({
					userId,
					collection: "projects",
					userIsOwner,
					docId: projectData.id,
					docEditors: projectData.editors,
					dialog,
				});
				setProjectData((projectData) => ({
					...projectData,
					accessors: projectData.followers.filter((id) => id !== userId),
					followers: projectData.followers.filter((id) => id !== userId),
					editors: projectData.editors.filter((id) => id !== userId),
				}));

				handleSetSuccessText("Unfollowed Project " + projectData.projectName);
			}
		} catch (err) {
			setErrorObj(err);
		}
	};

	// ran if user is not billTo amd currentUser is true
	// const getAcceptBillButtonText = async (projectData, userId) => {
	// 	try {
	// 		if (!projectData.billTo.uid) {

	// 			if (projectData.billTo.uid === userId) {
	// 				return "Pay/Handle Bill"
	// 			}

	// 			// see if someone has already tried to accept the bill but awaiting the owners acceptance of the notification
	// 			const notificationObj = await checkForAcceptBillNotification({forDocumentId: projectData.id})
	// 			if (notificationObj) { // if someone has requested to accept the bill
	// 				if (notificationObj.sentBy === userId) {
	// 					// render of AcceptBillModal relies on text being "Handle Bill" to have type property in the modal be of handleBill
	// 					return "Handle Bill"
	// 				} else return ""
	// 			} else {
	// 				return "Accept Bill"
	// 			}
	// 		} else return ""
	// 	} catch (err) {
	// 		throw err
	// 	}
	// }

	// get any invoices where visibility is private
	const getPrivateInvoiceData = async (projectId, userId, publicDocIds) => {
		try {
			let results = [];
			let alreadyGottenDocIds = [...publicDocIds];

			// get docs where user is follower
			const followersSnapshot = await firebase
				.firestore()
				.collection("invoices")
				.where("followers", "array-contains", userId)
				.where("visibility", "==", "private")
				.where("forProject", "==", projectId)
				.get();

			if (followersSnapshot.docs.length) {
				// check each invoice to see if it has already been added then add it if not
				followersSnapshot.docs.forEach((doc) => {
					if (!alreadyGottenDocIds.includes(doc.id)) {
						alreadyGottenDocIds.push(doc.id);

						results.push({ ...doc.data(), id: doc.id });
					}
				});
			}
			// get docs where user is accessor
			const accessorsSnapshot = await firebase
				.firestore()
				.collection("invoices")
				.where("accessors", "array-contains", userId)
				.where("visibility", "==", "private")
				.where("forProject", "==", projectId)
				.get();

			if (accessorsSnapshot.docs.length) {
				// check each invoice to see if it has already been added then add it if not
				accessorsSnapshot.docs.forEach((doc) => {
					if (!alreadyGottenDocIds.includes(doc.id)) {
						alreadyGottenDocIds.push(doc.id);

						results.push({ ...doc.data(), id: doc.id });
					}
				});
			}

			return results;
		} catch (err) {
			throw err;
		}
	};

	// get all public invoices where forProject is projectId
	const getPublicInvoiceData = async (projectId) => {
		try {
			let results = [];
			const snapshot = await firebase
				.firestore()
				.collection("invoices")
				.where("visibility", "==", "public")
				.where("forProject", "==", projectId)
				.get();

			if (snapshot.docs.length) {
				results = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			}

			return results;
		} catch (err) {
			throw err;
		}
	};

	const updateInvData = (projectId, resContractorUserData, resInvoiceData) => {
		let newInvData = [];
		resInvoiceData.forEach((inv) => {
			const indexOfContractor = resContractorUserData.findIndex(
				(user) => user.id === inv.contractor.id
			);
			newInvData.push({
				...inv,
				contractor: { ...resContractorUserData[indexOfContractor] },
			});
		});
		return newInvData;
	};

	const getBillToUser = (id) => {
		if (id) {
			return firebase
				.firestore()
				.collection("users")
				.doc(id)
				.get()
				.then((doc) => {
					if (doc.exists && doc.data().username) {
						return {
							uid: id,
							username: doc.data().username,
							firstname: doc.data().firstname || "",
							lastname: doc.data().lastname || "",
							address: doc.data().address || "",
							companyName: doc.data().companyName || "",
						};
					} else {
						return null
					}
				});
		} else return null;
	};

	// only renendered if no current project
	const projectList = (
		<div className="align-left">
			<div className="section-divider" />
			<Modal>
				<h2 className="heading">Select a current project</h2>
				{usersProjects && usersProjects.length ? (
					<React.Fragment>
						<DocumentList
							selections={newCurrentProject}
							setSelections={setNewCurrentProject}
							maxSelections={1}
							collection="projects"
							isFetchingDocs={isFetchingDocs}
							setIsFetchingDocs={setIsFetchingDocs}
							docList={usersProjects}
							setDocList={setUsersProjects}
							userId={userId}
						/>
						<div className="section-divider" />
						<div className="align-center">
							<button
								className="button-appearance align-center"
								onClick={() => {
									if (newCurrentProject[0]) {
										updateUserData({ currentProject: newCurrentProject[0].id }, false, "Specific Project projectList");
									}
								}}
							>
								Ok
							</button>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div>No projects found</div>
						<div className="small-line-break" />
						<Link to="/dashboard?action=addDocument&open=true&type=projects">
							Create a project in Dashboard
						</Link>
					</React.Fragment>
				)}
			</Modal>
			<div className="small-line-break" />
		</div>
	);

	// clear url state
	useEffect(() => {
		if (isNewDoc) {
			window.history.state.state = {};
		}
		// eslint-disable-next-line
	}, []);

	// track whether the component is mounted to prevent memory leaks
	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		return () => {
			setProjectData(null);
			setInvoiceData(null);
			setSections(null);
		};
		// eslint-disable-next-line
	}, [projectId]);

	useEffect(() => {
		if (errorObj) {
			setIsFetching(false);
		}
	}, [errorObj, setIsFetching]);

	// get and set the project from DB
	useEffect(() => {
		if (projectId) {
			if (projectId !== "current-project") {
				// check if project in users projects first
				const projectInUsersProjects =
					usersProjects && usersProjects.find((proj) => proj.id === projectId);
				if (projectInUsersProjects) {
					setProjectData(projectInUsersProjects);
					setUserCanView(true);
				}
				// subscribe to updates
				firebase
					.firestore()
					.collection("projects")
					.doc(projectId)
					.get()
					.then(
						(doc) => {
							if (isMountedRef.current) {
								if (doc.exists) {
									// need to check if user is allowed to view even if we passed the insufficient permissions because user could have just logged out so doc is in memory
									if (doc.data()) {
										if (doc.data().visibility === "private") {
											if (
												userId &&
												doc.data().owner &&
												doc.data().billTo &&
												doc.data().editors &&
												doc.data().followers
											) {
												setUserCanView(
													doc.data().owner === userId ||
														doc.data().billTo.uid === userId ||
														doc.data().editors.includes(userId) ||
														doc.data().followers.includes(userId)
												);
											}
										} else {
											setUserCanView(true);
										}
										// set the project data
										const resProjectData = { ...doc.data(), id: doc.id };
										setProjectData(resProjectData);
									}
								} else {
									// if no project with that ID
									setProjectData((projectData) => {
										return projectData
											? { ...projectData, id: "notFound" }
											: { id: "notFound" };
									});
								}
							}
						}).catch(err => {
							setIsFetching(false);
							// whether doc exists or not this will return insufficient permissions
							if (err.message.includes("Missing or insufficient permissions")) {
								if (isNewDoc) {
									// the project has just been created so it doesn't exist yet
									setUserCanView(true);
									return;
								} else {
									// set doc doesnt exist until we find out if it exists or not
									// handleSetEditedInv({mergeIntoHistory: true, changes: {id: "notFound"}, caller: "SpecificInvoice - unsubscribeMain insufficient permissions"})
									// handleSetInv({changes: {id: "notFound"}, caller: "SpecificInvoice.js unsubscribeMain doc.exists === false"})
									setUserCanView(true);
									setProjectData({ id: "notFound" });
									
									// will not work on linvo-dev enviroment]
									const docExists = firebase
										.functions()
										.httpsCallable("docExists");
									docExists({ collection: "projects", docId: projectId })
										.then(({ data }) => {
											if (data.exists) {
												// handleSetEditedInv({mergeIntoHistory: true, changes: {id: invoiceId, visibility: "private"}, caller: "SpecificInvoice - unsubscribeMain catch error doc exists but private"})
												setProjectData({ visibility: "private" });
												setUserCanView(false);
											}
										})
										.catch((err) => {
											setErrorObj(err);
										});
								}
							} else {
								console.log("err in main get project")
								console.error(err)
								console.log(err)
								setErrorObj(err);
							}
						});
			}
		}
		// not including isNewDoc in dep array since it is only set once  based on url state ... use useMemo instead?
		// eslint-disable-next-line
	}, [projectId, setErrorObj, setProjectData, usersProjects, userId]);

	// handle projects public and private invoice data
	useEffect(() => {
		if (projectId && foundAuthState) {
			// foundAuthState is set in userContext and  is set when auth state changes.
			// as long as irebase.auth().currentUser is gotten before the foundAuthState is set
			// this allows the api call to happen only once
			if (projectId !== "current-project") {
				(async () => {
					try {
						const publicInvoices = await getPublicInvoiceData(projectId);
						let privateInvoices = [];

						if (userId) {
							const publicDocIds = publicInvoices.map((doc) => doc.id);
							privateInvoices = await getPrivateInvoiceData(
								projectId,
								userId,
								publicDocIds
							);
						}

						if (isMountedRef.current) {
							setInvoiceData((invoiceData) => {
								let newInvoiceData = [...privateInvoices, ...publicInvoices];

								// dont add invoice data if there is already invoie data
								// because if a user switches from one project to say current project
								// that invoice data is carried over

								// if there is already invoice data , filter out any tnvoices in the newly gotten
								// data if their ID's are found in the already gotten invoice data
								// if (invoiceData) {
								// 	const invoiceDataDocIds = invoiceData.map(inv => inv.id)
								// 	newInvoiceData.filter(doc => !invoiceDataDocIds.includes(doc.id))
								// 	newInvoiceData.push(...invoiceData)
								// }

								// sort the invoices by mostRecentEntryDate
								newInvoiceData = sortByRecentEntryDate(newInvoiceData);
								return newInvoiceData;
							});
						}
					} catch (err) {
						setErrorObj(err);
					}
				})();
			}
		}
		// eslint-disable-next-line
	}, [projectId, foundAuthState, userId, setErrorObj]);

	// set user roles
	useEffect(() => {
		if (
			userId &&
			projectData &&
			userCanView &&
			projectData.id !== "notFound" &&
			projectId !== "current-project"
		) {
			let checkUserIsOwner = false;
			let checkUserIsBillTo = false;
			let checkUserIsEditor = false;
			let checkUserIsFollower = false;
			if (projectData.owner === userId) {
				checkUserIsOwner = true;
				checkUserIsEditor = true;
			}

			if (projectData.billTo && projectData.billTo.uid === userId) {
				checkUserIsBillTo = true;
			}

			if (projectData.editors && projectData.editors.includes(userId)) {
				checkUserIsEditor = true;
			}

			if (projectData.followers && projectData.followers.includes(userId)) {
				checkUserIsFollower = true;
			}
			setUserIsOwner(checkUserIsOwner);
			setUserIsBillTo(checkUserIsBillTo);
			setUserCanEdit(checkUserIsEditor);
			setUserIsFollower(checkUserIsFollower);
		}
	}, [
		userId,
		projectData,
		projectId,
		setUserCanEdit,
		setUserIsBillTo,
		setUserIsFollower,
		setUserIsOwner,
		userCanView,
	]);


	const getFollowState = (projectData, userObject, foundFollowNotification, fetchingFollowNotification) => {
		let state = {
			followText: "Follow", 
			followButtonDisabled: true
		}

		if (!projectData || !userObject.id) {
			return state
		}

		if (projectData.followers && projectData.followers.includes(userObject.id)) {
			state.followText = "Unfollow"
			state.followButtonDisabled = false
			return state
		}

		if (fetchingFollowNotification) {
			return state
		}

		if (projectData.id === "notFound") {
			state.followText = "Follow"
			state.followButtonDisabled = true
		}

		if (projectData.visibility === "private") {
			// if no notification found and not fetching, initiate fetching
			if (!foundFollowNotification && !fetchingFollowNotification) {
				setFetchingFollowNotification(true)
				firebase
					.firestore()
					.collection("notifications")
					.where("accessors", "array-contains", userObject.id)
					.where("forDocumentId", "==", projectId)
					.get()
					.then(snapshot => {
						if (snapshot.docs.length) {
							const doc = snapshot.docs[0]
							setFoundFollowNotification({...doc.data(), id: doc.id})
						} else {
							setFoundFollowNotification({empty: true})
						}

						setFetchingFollowNotification(false)
					}).catch(err => { //must catch and set follow notification to empty
						console.log("error getting follow notification")
						console.error(err)
						setFoundFollowNotification({empty: true})
						setFetchingFollowNotification(false)
					})
			}

			// if notification found, set request sent
			if (foundFollowNotification) {
				if (foundFollowNotification.empty) {
					state.followText = "Follow"
					state.followButtonDisabled = false
				} else { // has sent notification
					state.followText = "Request Sent"
					state.followButtonDisabled = true
				}
			}

		}

		if (projectData.visibility === "public") {
			state.followText = "Follow"
			state.followButtonDisabled = false
			// terminate fetching and set follow notification to prevent fetching
			if (!setFoundFollowNotification) {
				setFoundFollowNotification({empty: true})
			}
			if (fetchingFollowNotification) {
				setFetchingFollowNotification(false)
			}
		}

		return state

	}

	const [fetchingFollowNotification, setFetchingFollowNotification] = useState(false)
	const [foundFollowNotification, setFoundFollowNotification] = useState(false)

	// let {followText, followButtonDisabled} = getFollowState(projectData, userObject, foundFollowNotification, fetchingFollowNotification)

	const [followText, setFollowText] = useState("Follow")
	const [followButtonDisabled, setFollowButtonDisabled] = useState(null)

	// must use state here becuase of infinite rerenders
	useEffect(() => {

		const state = getFollowState(projectData, userObject, foundFollowNotification, fetchingFollowNotification)
		if (followText !== state.followText) {
			setFollowText(state.followText)
		}

		if (followButtonDisabled !== state.followButtonDisabled) {
			setFollowButtonDisabled(state.followButtonDisabled)
			
		}	  
		// not putting in getFollowState
		// putting userObject in dep array causes unlimited rerender for some reason
		// eslint-disable-next-line
	}, [projectData, foundFollowNotification, fetchingFollowNotification, followText, followButtonDisabled])

	// set categorys (sections) for each type of invoice based on its industry
	useEffect(() => {
		if (invoiceData) {
			if (invoiceData.length) {
				setSections(sortByIndustry(invoiceData));
			} else {
				setSections([
					{
						companyIds: [userId || "Sample Contractor"],
						industry: "Project Invoices",
					},
				]);
			}
		}
	}, [invoiceData, userId]);

	//  update invoice data with any new updates
	useEffect(() => {
		if (
			invoiceData &&
			projectData &&
			projectData.billTo &&
			!didUpdateInvoiceData
		) {
			(async () => {
				try {
					setDidUpdateInvoiceData(true);
					let allContractors = [];

					invoiceData.forEach((inv) => {
						if (!allContractors.includes(inv.contractor.id)) {
							allContractors.push(inv.contractor.id);
						}
					});

					const resContractorUserData = await getUserObjs(
						allContractors,
						[],
						setErrorObj
					);

					if (isMountedRef.current) {
						setInvoiceData(
							updateInvData(projectId, resContractorUserData, invoiceData)
						);
					}

					const resEditorUserData = await getUserObjs(
						projectData.editors,
						resContractorUserData,
						setErrorObj
					);
					// editors must be followers... updated from not needing to be follower
					const resFollowerData = await getUserObjs(
						projectData.followers,
						[...resContractorUserData, ...resEditorUserData],
						setErrorObj
					);
					if (isMountedRef.current) {
						setFollowersUserObjs(
							getFollowersUserDataWithType(
								resFollowerData,
								resEditorUserData,
								projectData
							)
						);
					}
					const resBillToUserObj = await getBillToUser(projectData.billTo.uid);
					if (isMountedRef.current && resBillToUserObj) {
						setBillToUserObj(resBillToUserObj);
					}
				} catch (err) {
					setErrorObj(err);
				}
			})();
		}
	}, [
		invoiceData,
		setInvoiceData,
		didUpdateInvoiceData,
		projectData,
		// getBillToUser,
		projectId,
		setFollowersUserObjs,
		setErrorObj,
	]);

	// set recently viewed
	useEffect(() => {
		if (
			/*userObject.id &&*/ projectId !== "current-project" &&
			projectData &&
			projectData.owner &&
			!addedToRecentlyViewed
		) {
			const linkName =
				projectData.projectName || "Project " + projectData.shortHandId;
			let newUserObject = null;
			const docType = "project";

			// if already updated recentlyViewedDocs
			if (
				!userObject.recentlyViewedDocs ||
				!userObject.recentlyViewedDocs.length ||
				!(userObject.recentlyViewedDocs[0].id === projectId)
			) {
				newUserObject = {
					...userObject,
					recentlyViewedDocs: removeDuplicates([
						{
							id: projectId,
							linkName,
							docType,
							relUrl: window.location.pathname || "/projects/" + projectId,
						},
						...(userObject.recentlyViewedDocs || []),
					]).slice(0, 10),
				};
				setUserObject(newUserObject);
				setAddedToRecentlyViewed(true);

				if (userObject.id) {
					updateUserData(newUserObject, true, "SpecificProject useEffect recentlyViewedDocs").catch((err) => setErrorObj(err));
				}
			}
		}
	}, [
		addedToRecentlyViewed,
		setAddedToRecentlyViewed,
		userObject,
		updateUserData,
		setUserObject,
		projectData,
		projectId,
		setErrorObj,
	]);

	// handle newly set current project from projectList
	useEffect(() => {
		if (projectId === "current-project" && userObject.currentProject) {
			history.replace(`/projects/${userObject.currentProject}`);
		}
	}, [userObject.currentProject, projectId, history]);

	if (projectId === "current-project" && foundAuthState) {
		return (
			<ProjectStyle>
				<div className="page-container">{projectList}</div>
			</ProjectStyle>
		);
	}

	// projectData invoiceData and sections are null to start but as soon as projectData is retrieved
	// invoiceData is set to an empty array which triggers sections to be set to an empty array
	// main conditionals

	if (projectData) {
		if (projectData.id === "notFound") {
			return (
				<ProjectStyle>
					<div className="page-container">
						<div className="align-left">
							{followText === "Follow" ? (
								<button
									className="button-appearance small"
									disabled={followButtonDisabled}
									onClick={async () => {
										try {
											if (!userId) {
												setErrorObj({message: "log in to follow"})
											}
											// set follow request notification right away
											// followButtonDisabled = true
											setFollowButtonDisabled(true)
											const followSuccess = await handleAddFollower({
												userId,
												username: userObject.username,
												collection: "projects",
												parentResource: { ...projectData, id: projectId },
											});
											if (followSuccess) {
												if (followSuccess === "Request sent") {
													setFoundFollowNotification({empty: false, followSuccess})

													// handleSetSuccessText(
													// 	"You will be added as a follower upon owners approval"
													// );
												} else {
													handleSetSuccessText(followSuccess);
													setProjectData((projectData) => ({
														...projectData,
														followers: [...projectData.followers, userId],
													}));
												}
											} else {
												setFollowButtonDisabled(false)
											}
										} catch (err) {
											setFollowButtonDisabled(false)
											setErrorObj(err);
										}
									}}
								>
									{followText}
								</button>
							) : (
								<button
									className="button-appearance small"
									onClick={handleUnfollowProject}
									disabled={followButtonDisabled}
								>
									{followText}
								</button>
							)}
						</div>
						<div className="section-divider" />
						<div>Project Not Found</div>
					</div>
				</ProjectStyle>
			);
		}

		return (
			<ProjectStyle>
				<div className="page-container">
					<div className="align-left">
						{followText === "Follow" ? (
							<button
								className="button-appearance small"
								disabled={followButtonDisabled}
								onClick={async () => {
									try {
										if (!userId) {
											setErrorObj({message: "log in to follow"})
										}
										// set follow request notification right away
										setFollowButtonDisabled(true)
										const followSuccess = await handleAddFollower({
											userId,
											username: userObject.username,
											collection: "projects",
											parentResource: { ...projectData, id: projectId },
										});
										if (followSuccess) {
											if (followSuccess === "Request sent") {
												setFoundFollowNotification({empty: false, followSuccess})

												// handleSetSuccessText(
												// 	"You will be added as a follower upon owners approval"
												// );
											} else {
												handleSetSuccessText(followSuccess);
												setProjectData((projectData) => ({
													...projectData,
													followers: [...projectData.followers, userId],
												}));
											}
										}
									} catch (err) {
										setFollowButtonDisabled(false)
										setErrorObj(err);
									}
								}}
							>
								{followText}
							</button>
						) : (
							<button
								className="button-appearance small"
								onClick={handleUnfollowProject}
								disabled={followButtonDisabled}
							>
								{followText}
							</button>
						)}
					</div>

					<div className="section-divider" />

					{userCanView ? (
						<div>
							{addDocsModalOpen && (
								<Modal
									custom={{ absolute: true }}
									onClickOutside={() => setAddDocsModalOpen(false)}
								>
									<AddPrivateProjectDocs
										projectId={projectId}
										setAddDocsModalOpen={setAddDocsModalOpen}
									/>
								</Modal>
							)}
							{modalData.open && (
								<Modal
									custom={{ absolute: true }}
									onClickOutside={() =>
										setModalData((modalData) => ({ ...modalData, open: false }))
									}
								>
									{modalData.type === "invoices" && (
										<NewInvoiceForm
											billToUserObj={billToUserObj}
											projectData={projectData}
											modalData={modalData}
										/>
									)}
								</Modal>
							)}
							{changeBillToModalOpen && (
								<ChangeBillToModal
									userObject={userObject}
									parentResourceContextItems={{
										handleSave: saveProject,
										followersUserObjs,
										setChangeBillToModalOpen,
										setErrorObj,
										handleSetSuccessText,
									}}
									parentResource={projectData}
									setParentResource={setProjectData}
									collectionName="projects"
								/>
							)}
							{showProjectSunmary && (
								<Modal
									custom={{ absolute: true }}
									onClickOutside={() => setShowProjectSummary(false)}
								>
									<ProjectSummary
										setShowProjectSummary={setShowProjectSummary}
									/>
								</Modal>
							)}

							{
								// COMPONENTS ...
							}

							<div className="large">
								{projectData.projectName || ""}&nbsp;
								<span className="small">
									{projectData.shortHandId
										? `(${projectData.shortHandId})`
										: ""}
								</span>
							</div>

							<div className="small-line-break" />

							{userCanEdit && (
								<button
									className="button-appearance small"
									onClick={(e) =>
										setModalData((modalData) => ({
											...modalData,
											contractorId: userId,
											industry: userObject.industries.length
												? userObject.industries[0].name
												: "",
											type: "invoices",
											open: true,
										}))
									}
								>
									+ Create Invoice
								</button>
							)}

							<div className="section-divider" />
							<button
								className="button-appearance small"
								onClick={() => setShowProjectSummary(!showProjectSunmary)}
							>
								Project Details
							</button>

							{invoiceData && sections ? (
								<React.Fragment>
									<div className="section-divider" />
									<IndustryModals
										userId={userId}
										setModalData={setModalData}
										isFetchingDocs={isFetchingDocs}
										setIsFetchingDocs={setIsFetchingDocs}
										sections={sections}
										userCanEdit={userCanEdit}
									/>
								</React.Fragment>
							) : (
								<Spinner position="relative" />
							)}
							<div>
								{
									// (acceptBillButtonText && sections) &&
									// <div className="button-appearance" onClick={e => {
									// 	setAcceptBillModal({
									// 		open: true,
									// 		type: userIsBillTo ? "payOrHandleBill" : acceptBillButtonText === "Handle Bill" ? "handleBill" : ""
									// 	})
									// }} >
									// 	<div>{acceptBillButtonText}</div>
									// </div>
								}
							</div>
						</div>
					) : (
						<div>Project is Private</div>
					)}
				</div>
			</ProjectStyle>
		);
	} else return null;
};

export default SpecificProject;
