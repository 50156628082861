import React, { useContext } from 'react'

import { EditInvoiceContext } from '../../contexts/EditInvoiceContext'

import { TableSidebarStyle } from '../Invoice/InvoiceStyle.styled'
import { MediaQueryContext } from '../../contexts/MediaQueryContext'

// rendered by Table.js
// errors and successText handled
const TableSidebar = ({paperDetails, body, tableName, activeRow}) => {
	const {
		addRow, 
		deleteRow,
		setAddSummaryEntryModal,
		handleSetSuccessText,
		handleSetEditedInv,
		editedInv
	} = useContext(EditInvoiceContext)
	const {
		isLessThan700px
	} = useContext(MediaQueryContext)

	const handleAddRow = ({id, tableName, showWindowConfirm}) => {
		// check if tableName is summaryEntries
		if (tableName === "summaryEntries") {
			// add a new entry into summary entries with noEdit false
			setAddSummaryEntryModal({open: true, data: {id, tableName, showWindowConfirm}})
			// return addRow({id, tableName, showWindowConfirm}) 
		} else {
			return addRow({id, tableName, showWindowConfirm}) 
		}
	}

	const handleDeleteRow = ({id, tableName, showWindowConfirm}) => {

		// check if tableName is summaryEntries
		if (tableName === "summaryEntries") {
			// summaryEntries do not have defaultEntries so dont allow deleting a final row
			if (editedInv.summaryEntries) {
				const visibleSummaryEntries = editedInv.summaryEntries.filter(ent => ent.visible)
				if (visibleSummaryEntries.length === 1) {
					return handleSetSuccessText("Remove this row by removing the table in Invoice Layout", 6000)
				}
			}

			if (id === "materialTotals" || id === "laborTotals") {
				let newSummaryEntries = []
				// make new summary entries and just change the .visible property
				editedInv.summaryEntries.forEach(ent => {
					if (ent.id === id) {
						newSummaryEntries.push({
							...ent,
							visible: false
						})
					} else newSummaryEntries.push(ent)
				})
				// handleSetSuccessText("Remove this row by removing the table in Invoice Layout", 6000)

				// setChangeLayoutModalOpen(true)
				return handleSetEditedInv({changes: {summaryEntries: newSummaryEntries}, caller: "TableSidebar - handleDeleteRow"})

			} else {
				// must be tax or custom item... allow delete and edit
				return deleteRow({id, tableName, showWindowConfirm}) 
			}

		} else {
			return deleteRow({id, tableName, showWindowConfirm}) 

		}
	}

	// const filteredBody = body.filter(row => row.visible !== false)
	// console.log(body, filteredBody)

	return (
		<TableSidebarStyle
			className="noprint"
			columns={1}
			minWidth={"0"} 
			scaleRatio={paperDetails.printablePaperWidthToScreenWidthRatio}
			rowHeight={paperDetails.rowHeight}
			isLessThan700px={isLessThan700px}
		>
			<thead>
				<tr><th></th></tr>
			</thead>
			<tbody>
				{
					body.map((row, i) => {

						let canAddBelow = true
						let canDeleteRow = true
						if (row.immutable) {
							canDeleteRow = false
						}
						// disallow adding a row between Total Material and Total Labor
						// if (tableName === "summaryEntries" && row.id === "materialTotals") {
						// 	if (body[i+1] && body[i+1].visible !== false) {
						// 		canAddBelow = false
						// 	}
						// }

						if (row.visible !== false) {
							return (
								<tr key={`edit ${row.id}`} >
									<td>
										<div className="td-container">
											{
												// 	<div>stop</div>
												(activeRow.i === i && activeRow.tableName === tableName) &&
												<React.Fragment> 
													{
														canAddBelow &&
														<svg onClick={e => handleAddRow({id: row.id, tableName, showWindowConfirm: false})} viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
													    <path d="M16 2 L16 30 M2 16 L30 16" />
														</svg>
													}
													{
														canDeleteRow &&
														<svg onClick={e => handleDeleteRow({id: row.id, tableName, showWindowConfirm: true})} viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
													    <path d="M28 6 L6 6 8 30 24 29 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
														</svg>
													}
												</React.Fragment>
											}
											</div>
									</td>
								</tr>
							)


						} else return null
					})
				}
			</tbody>
		</TableSidebarStyle>
	)
}

export default TableSidebar
