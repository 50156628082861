import styled from "styled-components";

export const FooterStyle = styled.div `
	margin-top: 200px;
  transition: opacity 5s cubic-bezier(1, 0.02, 1, 1);

.footer-statement {
  background-color: #131339;
	padding: 60px 0;
	p {
		color: var(--white-text);
	  font-family: var(--font-family-poppins);
	  font-size: ${({isLessThan700px}) => isLessThan700px ? "14px" : "var(--font-size-m)" };
	  font-weight: 600;
	  text-align: center;	
	  max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px 0 40px;
	}
}
.legal-links {
	margin-top: 20px;
	text-align: center;
	div {
		padding: 0;
		margin: 0;
	}
}
.copyright-statement {
	font-size: var(--font-size-xs);
	padding: 0;
	margin: 15px 0 15px 10px;
	text-align: left;
	color: var(--chicago);
	p {
		padding: 0;
		margin: 0;
	}
}
.logo {
	margin: 0 5px;	
}
.footer-navigation {
  align-items: center;
  background-color: #fbfcff;
  display: flex;
  justify-content: space-between;
  margin: 20px 10px 0 10px;
  padding-bottom: 5px;
  box-shadow: 0px 4px 15px -10px;
  .social-links {
		display: flex;
		margin: 0 5px;
		padding: 0;
  	li {
			list-style: none;
  		padding: 5px;
  	}
  }
  .site-links {
  	// min-width: 25%;
  	${({isLessThan700px}) => isLessThan700px ? "display: none" : "display: flex"};
  	justify-content: space-between;
		margin: 0 5px;
  	padding: 0;
  	list-style: none;
  	li {
		  text-align: center;
		  a {
		  	padding: 5px;
		  	text-decoration: none;
		  }
  	}
  }
}

.cookies-notice {
	position: fixed;
	bottom: 0;
	left: 0;
  font-size: 12px;
  width: 100%;
  z-index: 2;
	> div {
		padding: 10px 20px 20px 20px;
		background-color: var(--white-text);
		width: 90%;
		opacity: 0.9;
		position: relative;
		margin: auto;
		border-radius: 5px;
    box-shadow: 0px 4px 18px -6px;
    p {
	    padding-top: 10px;
    }
		.close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 10px;
		}
	}
}




`