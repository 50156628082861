import React, { useState } from "react";
import Modal from '../components/Modal/Modal';


const DialogContext = React.createContext();
	

const DialogContextProvider = ({ children }) => {
	const defaultDialogState = {open: false, type: "", message: "", input: ""}
	const [dialogState, setDialogState] = useState({...defaultDialogState});

	const dialog = {
		// result: null,
		close: () => {
			if (dialogState.reject) {
				dialogState.reject()
				setDialogState(defaultDialogState)
			} else {
				setDialogState(defaultDialogState)
			}
		},
		confirm: (message) => {
			const promise = new Promise((resolve, reject) => {
				setDialogState({
					open: true, 
					type: "confirm", 
					message, 
					proceed: resolve,
					cancel: reject,
				})
			})

			return promise.then(
				() => {
					// resolve
			  	setDialogState({
			  		open: false,
			  		type: "",
			  		message: "",
			  	})
			  	return true
				},
				() => {
					// reject
			  	setDialogState({
			  		open: false,
			  		type: "",
			  		message: "",
			  	})
			  	return false
				}
			)
		},
		alert: (message) => {
			const promise = new Promise((resolve, reject) => {
				setDialogState({
					open: true, 
					type: "alert", 
					message, 
					proceed: resolve,
					cancel: reject,
				})
			})

			return promise.then(
				() => {
					// resolve
			  	setDialogState({
			  		open: false,
			  		type: "",
			  		message: "",
			  	})
			  	return true
				},
				() => {
					// reject
			  	setDialogState({
			  		open: false,
			  		type: "",
			  		message: "",
			  	})
			  	return false
				}
			)
		},
		prompt: (message, prefill, inputProperties) => {
			const promise = new Promise((resolve, reject) => {
				setDialogState({
					open: true, 
					type: "prompt", 
					message, 
					proceed: resolve,
					cancel: reject,
					input: prefill || "",
					inputProperties
				})
			})

			return promise.then(
				(input) => {
					// resolve
			  	setDialogState({
			  		open: false,
			  		type: "",
			  		message: "",
			  	})
			  	return input
				},
				(input) => {
					// reject
			  	setDialogState({
			  		open: false,
			  		type: "",
			  		message: "",
			  	})
					// input should always be null
			  	return null
				}
			)
		}

	}

	return (
		<DialogContext.Provider
			value={{
				dialog,
			}}
		>
			{
				dialogState.open &&
				<Modal
					custom={{ absolute: true, style: "z-index: 105;"}}
					onClickOutside={() => null}
					onClickClose={() => {
						dialogState.cancel(null)
					}}
				>
					<h4>
						{dialogState.message}
					</h4>
					{
						dialogState.type === "prompt" &&
						<div>
							<input id="prompt-input" type="text" value={dialogState.input} onChange={(e) => {
								setDialogState(dialogState => ({...dialogState, input: e.target.value}))
							}} {...dialogState.inputProperties || {}} />
							<div className="section-divider" />
						</div>
					}
					<button className="button-appearance" onClick={() => {
						setDialogState(dialogState => ({...dialogState, result: true}))
						dialogState.proceed && dialogState.proceed(dialogState.input)
					}} >
						OK
					</button>
					{
						dialogState.type !== "alert" &&
						<React.Fragment>
							{" "}
							<button className="button-appearance gray" onClick={() => {
								setDialogState(dialogState => ({...dialogState, result: false}))
								dialogState.cancel && dialogState.cancel(null)
							}} >
								Cancel
							</button>
						</React.Fragment>
					}
	      </Modal>
			}
			{children}
		</DialogContext.Provider>
	);
};

export { DialogContextProvider, DialogContext };
