import React, { useContext } from 'react'
import firebase from '../../firebase/index'

import { UserContext } from '../../contexts/UserContext'


// rendered by: UserProfile.js
// errors and successText handled
const ProfileControls = ({logoFile, setLogoFile, fileInputRef, handleRemoveLogo, isEditing, setIsEditing, handleUploadLogo, setUploadProgress}) => {
	const {
		userObject,
		// setUserObject,
		newUserData,
		setNewUserData,
		updateUserData,
		handleSetSuccessText,
		setErrorObj,
		offlineMode
	} = useContext(UserContext)

	const handleSave = async ({isEditing, setIsEditing, newUserData, setNewUserData, logoFile, setLogoFile, setUploadProgress}) => {
		try {
			let updatedUserData = {...newUserData}
			if (offlineMode) {
				setIsEditing(false)
			}
			// let uploadTask
			// let setUploadProgress
			if (logoFile && logoFile.id.includes("newFile")) {
				const uploadTask = await handleUploadLogo()
				// uploadTask = task[0]
				// setUploadProgress = task[1]
			  const url = await uploadTask.ref.getDownloadURL()

				// remove old photo from storage
				if (newUserData.logoPath) {
					await firebase.storage().ref(newUserData.logoPath).delete()
				}

				updatedUserData.logoUrl = url
				updatedUserData.logoPath = `${newUserData.id}/public/${logoFile.uploadedDate}`

			}

			setNewUserData(newUserData => ({...newUserData, ...updatedUserData}))
			await updateUserData(updatedUserData)
		  // setUserObject(userObject => ({...userObject, ...updatedUserData}))
		  if (logoFile && logoFile.id.includes("newFile")) {
		  	// reset logoFile
		  	setLogoFile({id: ""})
				fileInputRef.current.value = ""
				setUploadProgress(uploadProgress => ({...uploadProgress, percent: 100, opacity: 0}))
		  }

			handleSetSuccessText("All changes saved", 5000)

			setIsEditing(false)
		} catch (err) {
			setErrorObj(err)
		}
	}

	return (
		<React.Fragment>
			{
				isEditing ? 
				<React.Fragment>
					<div className="button-appearance tiny" onClick={() => handleSave({isEditing, setIsEditing, newUserData, setNewUserData, logoFile, setLogoFile, setUploadProgress})} >
					<span className="material-icons" >{"\ue876"}</span>&nbsp;Save
					</div>&nbsp;&nbsp;
					<div className="button-appearance gray tiny" onClick={e => {
						handleRemoveLogo(true) // dont need to await  because were only removing it if its new and not uploaded yet
						setNewUserData(newUserData => ({...newUserData, logoPath: userObject.logoPath || "", logoUrl: userObject.logoUrl || "", cardUserInfo: userObject.cardUserInfo}))
						setIsEditing(false)
					}} >Cancel
					</div>
				</React.Fragment>
				: <div className="button-appearance tiny" onClick={e => setIsEditing(true)} >
					<span className="material-icons">{"\ue3c9"}</span>&nbsp;Edit
				</div>
			}
		</React.Fragment>
	)
}

export default ProfileControls