import React from 'react'

import { Link } from 'react-router-dom'

import SymbolFromId from '../svgComponents/SymbolFromId'
import { setImageSrcToDefault, findExistingOrNewMessageThread } from './utils'
import { getStars } from '../UserProfile/UserRating'

import firebase from "../../firebase/index";

import Spinner from '../Spinner/Spinner'


// rendered by SearchResults.js and Marketplace
const SearchResult = ({ isAdmin, doc, userObject, searchResultsArray, searchData, usersThreads, changeLocation }) => {
  	// const docType = doc.filterName
  	const docType = doc.docType
  	let componentImage
  	let docBody
  	let userInfoComponent
  	let userStarsComponent
  	let userLocationComponent
  	let actionButtons
  	let belowBody
  	let topInfoComponent

  	const docName = (docType && docType.slice) ? docType.slice(0, docType.lastIndexOf("s")) : ""


  	// projects and invoices
  	if (docType !== "users" && docType !== "sponsored") {
  		let docLink
  		let docLinkName

  		// projects
  		if (docType === "projects") {
    		docLink = `/projects/${doc.id}`
    		docLinkName = doc.projectName
    		if (doc.billTo.username) {
    			let imageIsLoading = false
					let resImageSrc = doc.billTo.logoUrl

    			if (!doc.billTo.logoUrl) {
      			// imageIsLoading = true
        		resImageSrc = setImageSrcToDefault(doc.id, "billTo.logoUrl", imageIsLoading, searchResultsArray)
					}

      		userInfoComponent = 
					<Link className="user-icon-container" to={`/users/${doc.billTo.username}`} >
						<img 
							onError={(e) => {
								if (e.target.src) {
									// prevent infinite loop of error on fallback images
									if (e.target.src.includes("blank-profile-picture") || e.target.src.includes("fallback-image")) {
										e.target.src = ""
									} else {
		            		e.target.src = setImageSrcToDefault(doc.id, "logoUrl", imageIsLoading, searchResultsArray)
									}
								}
            	}}
          		className="search-result-img" 
							src={resImageSrc || "/assets/blank-profile-picture.png"}
							alt="logo"
						/>
						{
							imageIsLoading && <Spinner position="absolute" />
						}
						<p>@{doc.billTo.username}</p>
					</Link>
    		}

				docBody = [
					{type: "title", val: `${(doc.billTo.firstname || doc.billTo.lastname || doc.billTo.username) ? "For: " + doc.billTo.firstname || doc.billTo.lastname || doc.billTo.username : ""}`},
					{type: "lineBreak"},
					{type: "text", val: `Quick ID: ${doc.shortHandId}`}
				]
	  		componentImage = 
	  		<div className={docName} >
	    		<div className="symbol-container" >
	      		<Link className="link-appearance" to={docLink} >
							<SymbolFromId id={doc.id} />
							<p>{docLinkName || doc.id}</p>
						</Link>
					</div>
				</div>
			} else if (docType === "posts") {
				docLinkName = doc.caption
				docLink =`/users/${doc.username}/gallery`

				userInfoComponent = 
					<Link className="user-icon-container" to={`/users/${doc.username}`} >
						<img 
							className="search-result-img" 
							src={doc.logoUrl || "/assets/blank-profile-picture.png"} 
							alt="logo"
						/> 
						<p>@{doc.username}</p>
					</Link>
				docBody = [
					{type: "title", val: docLinkName},
					{type: "lineBreak"}
				]
				docBody = [
					...docBody,
					{
						type: "component",
						val: 
						<div className="hashtags" key={"hashtags " + doc.id}>
							{
								doc.tags.map((tag, i) => {
									return (
										<Link key={i} to={`/search?text=${encodeURIComponent(tag.replace("#", ""))}&category=contractors&posts=true`} >
											{tag}
										</Link>
									) 
								})
							}
						</div>
					}
				]
				componentImage = 
				<div className="symbol-container" >
	      		<Link className="link-appearance align-left" to={docLink} >
						<img src={doc.photos[0].url || "/assets/fallback-image.jpg"} alt=""/>
					</Link>
				</div>
			// invoices / bills
  		} else {
				docLink = `/${doc.contractor.username || "invoice"}/${doc.id}`
				docLinkName = `#${doc.invNumber} ${doc.invShortHand}`
				let imageIsLoading = false
				let resImageSrc = doc.contractor.logoUrl

				if (!doc.contractor.logoUrl) {
					resImageSrc = setImageSrcToDefault(doc.id, "contractor.logoUrl", imageIsLoading, searchResultsArray)
				}

				userInfoComponent = 
				<Link className="user-icon-container" to={`/users/${doc.contractor.username}`} >
					<img 
						onError={(e) => {
							if (e.target.src) {
								// prevent infinite loop of error on fallback images
								if (e.target.src.includes("blank-profile-picture") || e.target.src.includes("fallback-image")) {
									e.target.src = ""
								} else {
		          		e.target.src = setImageSrcToDefault(doc.id, "logoUrl", imageIsLoading, searchResultsArray)
								}
							}
          	}}
        		className="search-result-img" 
						src={resImageSrc || "/assets/blank-profile-picture.png"} 
						alt="logo"
					/> 
					{
						imageIsLoading && <Spinner position="absolute" />
					}
					<p>@{doc.contractor.username}</p>
				</Link>

				docBody = [
					{type: "title", val: `By: ${doc.contractor.companyName || doc.contractor.username}`},
					{type: "lineBreak"},
					{type: "text", val: doc.description.trim() || "Industry: " + doc.industry}
				]

				if (!doc.searchable) {
					if (doc.visibility === "public") {
						topInfoComponent = <div className="top-info"><strong>Visibility</strong> <em>Only to those with the shareable link</em></div>
					} else {
						topInfoComponent = <div className="top-info"><strong>Visibility</strong> <em>Private</em></div>
					}
				} else {
					if (doc.visibility === "public") {
						topInfoComponent = <div className="top-info"><strong>Visibility</strong> <em>Public</em></div>
					} else {
						topInfoComponent = <div className="top-info"><strong>Visibility</strong> <em>Private</em></div>
					}
				}

	  		componentImage = 
	  		<div className={docName} >
	    		<div className="symbol-container" >
	      		<Link className="link-appearance" to={docLink} >
							<SymbolFromId id={doc.id} />
							<p>{docLinkName || doc.id}</p>
						</Link>
					</div>
				</div>
  		}

		// users
  	} else {
  		const searchingContractors = searchData.mainSearchCategory === "contractors"
  		const searchingCustomers = searchData.mainSearchCategory === "users"

			let imageIsLoading = false
			let resImageSrc = doc.logoUrl

  		const existingOrNewMessageThread = findExistingOrNewMessageThread(userObject, usersThreads, doc)

  		const sponsor = async (user) => {
  			const sponsorInfo = {
					sponsored: true,
					expired: false,
					sponsorText: "Discounted rates until march 25!", // not used
  				userId: user.id, // must be userId
  				logoUrl: doc.logoUrl, // can be any image
					industries: user.industries, // same as user industries, they mcst change this in their profile
					cardUserInfo: user.cardUserInfo, // editable same as profile
					companyName: user.companyName, // editable ?
					username: user.username, // must be username
					contractorRating: user.contractorRating, // non editable
					customerRating: user.customerRating, // not applicable
					fullCityString: user.fullCityString, // editable
					adminLevel2: user.adminLevel2, // editable
					// additional
					actionButtonName: "Chat",
  			}

				await firebase.firestore().collection("sponsored").doc().set(sponsorInfo)

				// todo: write cloud function for this that will update the users userObject with the private ads collection
				window.alert(`${user.companyName} is now sponsored. Update the users ads collection`)
  		}

  		if (searchingContractors) {
  			const userIndustries = doc.industries
  			if (userIndustries && userIndustries.length) {
		  		let industryInUserIndustries = userIndustries.find(industry => industry.name.toLowerCase() === searchData.text.toLowerCase())

		  		if (industryInUserIndustries) {
			  		belowBody = <span className="contractor-rate">{industryInUserIndustries.rate ? `$${industryInUserIndustries.rate} /h` : "$ Rates vary"}</span>	
		  		} else {
		  			let highestRate = 0 
		  			let lowestRate = 0 

		  			userIndustries.forEach(ind => {
		  				const rate = parseFloat(ind.rate)
		  				if (!isNaN(rate) && rate > highestRate) {
		  					highestRate = rate
		  				}
		  				if (!isNaN(rate) && rate < highestRate) {
		  					lowestRate = rate
		  				}
		  			})
		  			if (highestRate && lowestRate && highestRate !== lowestRate) {
				  		belowBody = <span className="contractor-rate">{`$${lowestRate} to $${highestRate} /h`}</span>	
		  			} else if (highestRate) {
				  		belowBody = <span className="contractor-rate">{`Up to $${highestRate} /h`}</span>	
		  			}
		  		}
  			}
  		}

  		let cardUserInfoList = doc.cardUserInfo.val.filter ? doc.cardUserInfo.val.filter(truthy => truthy) : []

			docBody = [
				{type: "title", val: `${doc.companyName || doc.firstname + " " + doc.lastname}`},
				{type: "lineBreak"},
				{type: "list", listItems: (cardUserInfoList && cardUserInfoList.slice) ? cardUserInfoList.slice(0, 3) : []},
				// {type: "link", linkTo: `/users/${doc.username}`, val: `@${doc.username}`},
				// {type: "lineBreak"},
				// ...doc.industries.length ? doc.industries.slice(0,5).map(ind => ({type: "link", val: "#" + ind.name, linkTo: `/search?text=${ind.name}&category=contractors`}))
				// : []
			]

			if (!doc.logoUrl) {
  			// imageIsLoading = true
    		resImageSrc = setImageSrcToDefault(doc.id, "logoUrl", imageIsLoading, searchResultsArray)
			}

			// add in sponsored tag
			if (doc.docType === "sponsored") {
				topInfoComponent = <div className="top-info"><em>Sponsored</em></div>
			}

			// sponsored must still have image
  		componentImage = 
  		<Link to={`/users/${doc.username}`} >
    		<img
					onError={(e) => {
						if (e.target.src) {
							// prevent infinite loop of error on fallback images
							if (e.target.src.includes("blank-profile-picture") || e.target.src.includes("fallback-image")) {
								e.target.src = ""
							} else {
		        		e.target.src = setImageSrcToDefault(doc.id, "logoUrl", imageIsLoading, searchResultsArray)
							}
						}
        	}}
      		className="search-result-img" 
      		src={resImageSrc || "/assets/blank-profile-picture.png"} 
      		alt="logo"
    		/> 
    		{
					imageIsLoading && <Spinner position="absolute" />
				}
  		</Link>

  		let actionButtonName = "Chat"

  		if (doc.docType === "sponsored" && doc.actionButtonName) {
  			actionButtonName = doc.actionButtonName
  		}

  		actionButtons = <div className="action-buttons">
				{
					(doc.id !== userObject.id) &&
					<React.Fragment>
						<Link to={`/messages${existingOrNewMessageThread ? `?threadId=${existingOrNewMessageThread.id}&from=${userObject.id}&to=${doc.id}&toUsername=${doc.username}` : ""}`} 
							className="button-appearance tiny">
							<svg
								viewBox="0 0 32 32"
								fill="none"
								stroke="currentcolor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="3"
							>
								<path d="M2 4 L30 4 30 22 16 22 8 29 8 22 2 22 Z" />
							</svg>
							{actionButtonName}
						</Link>
						{
							isAdmin ? 
							<React.Fragment>
								<div className="small-line-break" />
								<button className="button-appearance tiny no-icon-or-underline"  onClick={() => sponsor(doc)}>
									Sponsor
								</button>
							</React.Fragment>

							: ""
						}
					</React.Fragment>
				}
  		</div>

  		// even sponsored must have stars
  		if (searchingContractors || searchingCustomers) {
	  		const ratings = searchingContractors ? doc.contractorRating : doc.customerRating
	  		const overallStars = ratings ? ratings.stars : 0

  			let ratingText = ""
  			let ratingClassName = ""

  			if (ratings.allRatings === 0) {
  				ratingText = "No ratings yet"
	  			ratingClassName = "no-rating"
  			}

  			if (ratings.allRatings > 3) {
  				if (overallStars > 4) {
		  			ratingText = "Highly rated"
		  			ratingClassName = "high-rating"	
  				}
  			}

  			if (ratings.allRatings > 10) {
  				if (overallStars > 4) {
						ratingText = "Expert rating"
		  			ratingClassName = "expert-rating"
  				} else if (overallStars > 3.5) {
		  			ratingText = "Highly rated"
		  			ratingClassName = "high-rating"
  				}
  			}

  			if (!ratingText) {
  				ratingText = `${ratings.allRatings} ${ratings.allRatings > 1 ? "reviews" : "review"}` 
  			}

	  		userStarsComponent = <div className={"user-stars " + ratingClassName} >
	  			{getStars(overallStars)}
	  			{
	  				ratingText ? <span className="rating-text">
	  					{ratingText}
	  				</span>
	  				: null	
					}
	  		</div>

	  		if (doc.fullCityString) {
	  			userLocationComponent = <div className="user-location location">
	  			<span 
		  			className="location-name" 							
		  			onClick={() => {
							changeLocation(doc.fullCityString)
						}}
					>
						<span className="pac-icon pac-icon-marker"></span>
		  			{doc.fullCityString}
	  			</span>
	  			</div>
	  		}

  		}

  	}

    return (
      <div className={`search-result ${topInfoComponent ? "add-top-margin" : ""} ${doc.visibleInSearchResults === false ? "swipe-hide" : "swipe-show"}`} key={doc.id} >
      	{
					topInfoComponent
      	}
      	<div className="left-column-container" >
	        {
	        	componentImage ? componentImage : ""
	        }
        	{actionButtons}
      	</div>
        {
        	docBody &&
          <div className="search-result-body" >
            {
            	typeof(docBody) === "string" ?
            	docBody
            	: docBody.map((obj, j) => {
            		if (obj.type === "title") {
            			return <div key={j} className="card-title">{obj.val}</div>
            		}
            		if (obj.type === "text") {
            			return obj.val + " "
            		} else if (obj.type === "link") {
            			return (
            				<React.Fragment key={j}>
              				<Link to={obj.linkTo} >{obj.val}</Link>&nbsp; 
              			</React.Fragment>
          				)
            		} else if (obj.type === "lineBreak") {
            			return <div key={j} />
            		} else if (obj.type === "list") {
            			return <ul key={j}>
            				{
            					obj.listItems.map((item, k) => {
            						return <li key={k}>{item}</li>
            					})
            				}
            			</ul>
            		} else if (obj.type === "component") {
            			return obj.val
            		} else {
            			return null
            		}
            	})
            }
		        {
		        	belowBody ?
		        	<React.Fragment>
		            <div className="small-line-break" />
			        	{belowBody}
		        	</React.Fragment>
		        	: null
		        }
		      	{userLocationComponent}
          </div>
        }
        {userStarsComponent}
      	{userInfoComponent}
      </div>
    )
	}


export default SearchResult;
