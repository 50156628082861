import styled from "styled-components";

export const FormStyle = styled.div`
	form {
		font-size: var(--font-size-m);
	}
	textarea {
		width: 100%;
		font-family: inherit;
	}
	input[type="checkbox" i] {
		margin-left: 0;
	}
	*:disabled {
		cursor: not-allowed;
	}
	*:disabled ~ label {
		cursor: not-allowed;
	}
	*:disabled + label {
		cursor: not-allowed;
	}
	*:required + label::after, *:required + span label::after {
		content: " *";
		color: red;
	}
	.submit-actions {
		> * {
			margin: 10px;
		}
	}
	.heading {
	  font-size: var(--font-size-lll);
	  color: black;
		margin: 0 0 15px 0;
		font-weight: 600;
	}
	.inputs {
		margin: 20px 0;
		display: flex;
		flex-direction: column;
		text-align: left;
	}
	.section-title {
		font-size: var(--font-size-l);
		font-weight: 600;
	  color: black;
	  margin-top: 20px;
	}
	.textarea-label label {
		font-size: 11px;
	}
	.small-note {
		font-size: 11px;
	}
	.inline-input {
		display: inline-block;
		padding: 0 5px 0 0;
	}
	.section-input {
		// background-color: #d3d3d359;
    background: repeating-linear-gradient(269deg, #8080804a, #80808012, #8080804a 100%);
		input {
			background-color: transparent;
		}
	}
	.section-input.top {
		// box-shadow: 0px 4px 18px -6px;

		// border-top: 1px solid;
		border-radius: 10px 10px 0 0;
		// border-left: 1px solid;
		// border-right: 1px solid;
		padding: 7px 7px 0 7px;
		margin-top: 10px;
	}
	.section-input.middle {
		// box-shadow: 0px 4px 18px -6px;

		// border-left: 1px solid;
		// border-right: 1px solid;
		padding: 0 7px;
	}
	.section-input.bottom {
    box-shadow: -1px 9px 10px -6px;
    border-radius: 0 0 10px 11px;
    padding: 0 7px 7px 7px;
    margin-bottom: 20px;
	}
}
	.hidden-input {
		margin: 0;
		padding: 0;
		input,
		label {
			display: none;
		}
	}
	input.inline-number-input {
		width: 10ch;
		border: none;
		border-bottom: 1px solid #9e9e9e;
		border-radius: 0;
		padding: 0;
		height: 2ch;
		vertical-align: text-bottom;
	}
	.normal-input-style {
		.input-container {
			display: inline-flex;
			flex-direction: row-reverse;
			width: 100%;
			justify-content: flex-end;
			margin: 10px 0 0 0;
			label,
			input:focus + label {
				margin: 0 10px 0 0;
				font-size: var(--font-size-m);
				line-height: 18px;
				padding: 5px 0 1px 0;
			}
			input,
			input:focus {
				outline: none;
				text-align: left;
				box-shadow: none;
				box-sizing: content-box;
				font-size: var(--font-size-m);
				/*height: 16px;*/
				padding: 5px 0;
			}
		}
	}
`;

export const InputDiv = styled.div`
	margin: 5px 0;
	display: flex;

	label {
		line-height: 18px;
		padding: 5px 0 1px 0;
		text-align: left;
	}
	/*need width 95% for safari ios*/

	${({ inputTypeTextFamily, hasVal }) =>
		inputTypeTextFamily &&
		`
		margin: 0;
	  .input-container {
			display: inline-flex;
		  flex-direction: column-reverse;
	    width: 95%;
	    position: relative;
	  }
		svg {
			display: unset;
	    padding: 7px 0 7px 7px;
	    opacity: ${hasVal ? "1" : "0.3"};
	    margin: auto 0 0 0;
		}
	  label {
	  	margin: ${hasVal ? "0" : "24px"} 0 0 0;
	  	${
				hasVal
					? `
	  		font-size: 11px;
	  		`
					: `
	  		font-size: var(--font-size-m);
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    overflow: hidden;
  		`
			};
		}
	  }
		input {
			border: none;
			border-bottom: 1px solid #9e9e9e;
			border-radius: 0;
			font-size: var(--font-size-m);
			outline: none;
			text-align: left;
			box-shadow: none;
			box-sizing: content-box;
			height: ${hasVal ? "16px" : "2px"};
			padding: ${hasVal ? "5px 0" : "0"};
		}
		input:focus + label {
			font-size: 11px;
	    margin: 0;
		}
		input:focus {
			height: 16px;
	    padding: 5px 0;
		}
		input[type="date"] {
			height: unset;
	    padding: unset;	 
		}
		input[type="date"]:focus {
			height: unset;
	    padding: unset;	    
		}
		input[type="date"] + label {
			font-size: 11px;
	    margin: 0;
		}
		
	`}
`;
