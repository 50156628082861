import React from 'react'
import styled from "styled-components";

const ContactStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
	}
`;

const Contact = () => {
	return (
		<ContactStyle>
			<div className="main-container">
				<div className="large">
					Contact us
				</div>
				<p>
					For non urgent inquiries please email us at: <a className="link-appearance" href="mailto:contact@linvo.ca">contact@linvo.ca</a>
				</p>
				<p>
					Or send us a text or call: <a className="link-appearance" href="tel:+16049015925">604-901-5925</a>
				</p>
			</div>
		</ContactStyle>
	)
}

export default Contact
