import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
// import 'firebase/messaging'
import 'firebase/compat/functions'


const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: "linvo.ca",
    databaseURL: "https://live-invoicing.firebaseio.com",
    projectId: "live-invoicing",
    storageBucket: "live-invoicing.appspot.com",
    messagingSenderId: "1010553419339",
    appId: "1:1010553419339:web:ec1cbc3070ffedb38dbfce",
    measurementId: "G-JQJMCSFFWM"
  }

firebase.initializeApp(firebaseConfig)
// firebase.analytics()
firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    firebase.analytics();
  }
})



// firebase.firestore().enablePersistence({synchronizeTabs:true})
//   .catch(function(err) {
//     console.log(err)
//     const user = firebase.auth().currentUser;

//     const handleSubmitError = async (errorObj, errorText) => {
//       const docName = errorText ? errorText.slice(0, 25) : "no-error-text"
//       return firebase.firestore().collection("errorLogs").doc(docName).set({
//         errorText,
//         code: errorObj.code || "",
//         // errorObj,
//         foundIn: "index.js",
//         type: errorObj.type || "",
//         message: errorObj.message || "no message",
//         lastInstance: firebase.firestore.FieldValue.serverTimestamp(),
//         occurance: firebase.firestore.FieldValue.increment(1),
//         href: encodeURIComponent(window.location.href),
//         ...(user && user.uid) ? {affectedUsers: firebase.firestore.FieldValue.arrayUnion(user.uid)} : {}

//       }, { merge: true })
//       .catch(err => {
//         console.log("Can't submit error", err)
//       })
//     }

//     if (
//       window.location.href.includes("192.168") ||
//       window.location.href.includes("localhost")
//     ) {
//       console.log("not sending error because server is localhost, json:", JSON.stringify(err))

//     } else {
//       handleSubmitError(err, err.message)
//     }
//   })

export default firebase