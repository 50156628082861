import React, { useState, useContext, useEffect, useRef } from "react";

import { UserContext } from "../../contexts/UserContext";
import { DialogContext } from "../../contexts/DialogContext";

import Form from "../Forms/Form";
import { AppMessage } from "../MessageUtils";

// import MoreInfoIcon from '../MoreInfoIcon'
import { formulateSearchKeywords } from "../../utils/appUtils";
import firebase from "../../firebase/index";

const EditPostForm = ({
	setUserHasPosts,
	posts,
	setPosts,
	postData,
	setEditPostModalOpen,
}) => {
	const { dialog } = useContext(DialogContext);
	const {
		currentUser,
		userObject,
		setErrorObj,
		// handleSetSuccessText,
		offlineMode,
	} = useContext(UserContext);
	const [formData, setFormData] = useState({
		id: "",
		caption: "",
		searchKeywords: "",
		visibility: "public", // customers, public
		photos: [],
		tags: [],
		uid: currentUser.id
	});
	const [storageItemsToRemove, setStorageItemsToRemove] = useState([]);
	// const [uploadProgress, setUploadProgress] = useState(0)
	const [uploadProgress, setUploadProgress] = useState({
		percent: 0,
		opacity: 0,
	});

	const ref = useRef();

	let isNewPost = postData && postData.id ? false : true;
	const fileInputRef = useRef(null);
	let firebaseUserPostsDBRef;
	if (formData.id) {
		firebaseUserPostsDBRef = firebase
			.firestore()
			.collection("users")
			.doc(currentUser.uid)
			.collection("posts")
			.doc(formData.id);
	} else {
		firebaseUserPostsDBRef = firebase
			.firestore()
			.collection("users")
			.doc(currentUser.uid)
			.collection("posts")
			.doc();
	}

	const handleChange = (name, val) => {
		setFormData((formData) => ({ ...formData, [name]: val, uid: currentUser.uid}));
	};

	const handleChangeTags = (name, value) => {
		let newVal = value;
		const cursorPosition = ref.current.selectionStart;

		if (newVal.endsWith("#")) {
			if (newVal.charAt(newVal.length - 2) !== " ") {
				newVal = newVal.slice(0, -1) + " #";
			}
		}

		let newArr = newVal.split(" ")
		if (newVal !== "") {
			newArr = newArr.map((tag, i) => {
				if (tag !== "" && newVal !== "# ") {
					return "#" + tag.replace(/#/g, "");
				} else return tag;
				
			});
		}
		if (newArr.length > 1 && newArr[0] === "#" && newArr[1] !== "") {
			newArr.shift();
		}
		
		setFormData((formData) => ({ ...formData, [name]: newArr, uid: currentUser.uid }));
		
		if(newVal[ref.current.selectionStart-1] === ' '|| ref.current.selectionStart === 1 || newVal[ref.current.selectionStart] === '#' ){
			
			setTimeout(() => {	
				ref.current.setSelectionRange(cursorPosition+1, cursorPosition+1)
			}, 0)
		
		}
	};

	const handleDeletePost = async (e) => {
		e.preventDefault();
		try {
			let confirmRemove = await dialog.confirm("Confirm delete post");
			if (confirmRemove) {
				await removeStorageItems(formData.photos);
				await firebase
					.firestore()
					.collection("users")
					.doc(currentUser.uid)
					.collection("posts")
					.doc(formData.id)
					.delete();
				// update the UI
				const newPosts = posts.filter((post) => post.id !== formData.id);
				setPosts(newPosts);
				if (!newPosts.length) {
					setUserHasPosts(false);
				}
				setEditPostModalOpen(false);
			} else return;
		} catch (err) {
			setErrorObj(err);
		}
	};

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			if (!formData.photos.length) {
				setErrorObj({ message: "Please choose a photo", noReport: true });
				return;
			}
			if (offlineMode) {
				console.log(
					"haven't handled offline mode for a post edit or new post yet"
				);
				setErrorObj({
					message: "Can't upload new posts while offline",
					noReport: true,
				});
			}
			// remove any items that need to be removed from storage
			await removeStorageItems(storageItemsToRemove);
			// if there are new photos
			let newPhotosArray = [];

			// upload any files to storage and get the url
			// if (formData.photos.find(photo => photo.isNewPhoto)) {
			// run through all photos and get the new downloadURL
			const photosToUpload = formData.photos.filter(
				(photo) => photo.isNewPhoto
			);
			const uploadTaskPromises = await fileUploader(
				photosToUpload,
				setUploadProgress
			);
			// add the url in to the firestore post
			for (var i = 0; i < formData.photos.length; i++) {
				const file = formData.photos[i];
				let newFile = { ...file };
				delete newFile.isNewPhoto;
				delete newFile.src;
				delete newFile.file;
				const fileDoneUploading = uploadTaskPromises.find(
					(snapshot) => snapshot.metadata.name === file.id
				);
				if (fileDoneUploading) {
					const url = await fileDoneUploading.ref.getDownloadURL();
					newPhotosArray.push({
						...newFile,
						url,
						updated: fileDoneUploading.metadata.updated,
						storagePath: fileDoneUploading.metadata.fullPath,
					});
				} else if (!file.isNewPhoto) {
					newPhotosArray.push(newFile);
				}
			}
			// }

			let newPostData = {
				...formData,
				photos: newPhotosArray,
				// searchKeywords: formulateSearchKeywords(formData.tags),
				searchKeywords: formulateSearchKeywords({
					doc: formData,
					type: "userPost",
					userObject,
				}),
			};

			if (isNewPost) {
				newPostData.date = firebase.firestore.FieldValue.serverTimestamp();
				newPostData.seconds = firebase.firestore.Timestamp.now().seconds;
			}
			// add the updates to the DB of the new post and its files
			await firebase
				.firestore()
				.collection("users")
				.doc(currentUser.uid)
				.collection("posts")
				.doc(formData.id)
				.set(
					{
						...newPostData,
					},
					{ merge: true }
				);
			// update the UI
			if (isNewPost) {
				setUserHasPosts(true);
				setPosts((posts) => [newPostData, ...posts]);
			} else {
				const newPosts = posts.map((post) => {
					if (post.id === formData.id) {
						return {
							...post,
							...newPostData,
						};
					} else {
						return post;
					}
				});

				setPosts(newPosts);
			}

			setEditPostModalOpen(false);
		} catch (err) {
			setErrorObj(err);
		}
	};

	const fileSelectedHandler = (e) => {
		if (e.target.files[0]) {
			const fileList = e.target.files;
			let i;
			// const newPostId = formData.id
			// const newAllFiles = []
			for (i = 0; i < fileList.length; i++) {
				let file = fileList[i];
				let fileReader = new FileReader();

				fileReader.onload = (e) => {
					let newFile = {
						src: fileReader.result,
						url: "",
						id: Date.now().toString(),
						name: file.name,
						fileType: file.type,
						width: "",
						height: "",
						isNewPhoto: true,
					};
					const image = new Image();
					image.src = fileReader.result;
					image.onload = () => {
						newFile = {
							...newFile,
							file,
							width: image.width,
							height: image.height,
						};

						setFormData((formData) => ({
							...formData,
							photos: [...formData.photos, newFile],
						}));
					};
					// newAllFiles.push(newFile)
				};

				fileReader.readAsDataURL(file);
			}
		}
	};

	const fileRemovedHandler = (id) => {
		// if file exists in db window confirm delete
		const fileObj = formData.photos.find((obj) => obj.id === id);

		const newPhotos = formData.photos.filter((file) => file.id !== id);
		setFormData((formData) => ({ ...formData, photos: newPhotos }));
		// fileInput.current.value = ""

		if (!fileObj.isNewPhoto) {
			setStorageItemsToRemove((storageItemsToRemove) => [
				...storageItemsToRemove,
				fileObj,
			]);
		}
	};

	const removeStorageItems = async (items) => {
		let deleteTasks = [];
		for (let i = 0; i < items.length; i++) {
			// remove from firebase if it exists
			const item = items[i];
			try {
				let storageRef = firebase
					.storage()
					.ref(`${currentUser.uid}/posts/${item.id}`);
				// firebaseUserPostsDBRef.update({
				// 	photos: firebase.firestore.FieldValue.arrayRemove
				// })
				// .catch(err => setErrorObj(err))

				// update storage
				deleteTasks.push(storageRef.delete());
			} catch (err) {
				if (err.code !== "storage/object-not-found") {
					throw err;
				}
			}
		}
		return Promise.all(deleteTasks);
	};

	const fileUploader = (files, setUploadProgress) => {
		// dont use long cache control on posts
		// const metadata = {
		//   cacheControl: 'public, max-age=2592000, stale-while-revalidate=86400'
		// }
		let uploadTaskPromises = [];
		if (files.length) {
			for (let i = 0; i < files.length; i++) {
				const doc = files[i];
				const storageRef = firebase.storage().ref(`${currentUser.uid}/posts`);
				let uploadTask = storageRef.child(doc.id).put(doc.file);
				uploadTask.on(
					"state_changed", // on('evt_listener', progress(), error(), complete()
					(snapshot) => {
						// progress function
						let progress = Math.round(
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100
						);
						setUploadProgress((uploadProgress) => ({
							...uploadProgress,
							percent: progress,
						}));
					},
					(err) => {
						// error function
						setUploadProgress((uploadProgress) => ({
							...uploadProgress,
							percent: 0,
							opacity: 0,
						}));
						throw err;
					},
					() => {}
				);
				uploadTaskPromises.push(uploadTask);
			}
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				percent: 0,
				opacity: 0,
			}));

			return Promise.all(uploadTaskPromises);
		} else {
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				percent: 100,
			}));
			return [];
		}
	};

	useEffect(() => {
		// const user = firebase.auth().currentUser
		if (isNewPost) {
			const newPostId = firebaseUserPostsDBRef.id;
			setFormData((formData) => ({ ...formData, id: newPostId }));
		} else {
			setFormData((formData) => ({
				...formData,
				...postData,
			}));
		}
		// eslint-disable-next-line
	}, [isNewPost]);

	const inputs = [
		{
			label: "Photo caption",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "caption",
				value: formData.caption,
			},
			afterInput: [
				<div key="caption-line-break" className="small-line-break" />,
				<div key="photos">
					<progress
						style={{ opacity: uploadProgress.opacity }}
						value={uploadProgress.percent}
						max="100"
					/>
					{formData.photos.length
						? formData.photos.map((file) => {
								return (
									<div key={file.id} className="thumbnail-container">
										<div className="thumbnail-heading">
											<div />
											<div className="file-remove">
												<svg
													onClick={() => fileRemovedHandler(file.id)}
													viewBox="0 0 32 32"
													fill="none"
													stroke="currentcolor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
												>
													<path d="M28 6 L6 6 8 30 24 29 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
												</svg>
											</div>
										</div>
										<img
											className="file"
											src={file.url || file.src}
											alt={file.caption}
										/>
										<div className="small-line-break" />
									</div>
								);
						  })
						: null}
					<div>
						<div className="section-divider" />
						{formData.photos.length < 1 && (
							// change when ready to handle multiple photos per post
							<button
								className="button-appearance"
								onClick={(e) => {
									e.preventDefault();
									fileInputRef.current.click();
								}}
							>
								+ Add
							</button>
						)}
					</div>
					<div className="section-divider" />
				</div>,
			],
		},
		// {
		// 	custom: true,
		// 	label: "Visibility",
		// 	properties: {
		// 		type: "dropdown",
		// 		name: "visibility",
		// 		options: [
		// 			{name: "Public", id: "public"},
		// 			{name: "My customers only", id: "customers"}
		// 		],
		// 		optionVal: "id",
		// 		value: formData.visibility,
		// 		onChange: handleChange
		// 	},
		// 	afterInput: [
		// 		<div key="photos">
		// 			<progress style={{opacity: uploadProgress.opacity}} value={uploadProgress.percent} max="100" />
		// 			{
		// 				formData.photos.length ?
		// 				formData.photos.map(file => {
		// 					return (
		// 						<div key={file.id} className="thumbnail-container">
		// 							<div className="thumbnail-heading">
		// 								<div />
		// 								<div className="file-remove">
		// 									<svg onClick={() => fileRemovedHandler(file.id)} viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		// 								    <path d="M28 6 L6 6 8 30 24 29 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
		// 									</svg>
		// 								</div>
		// 							</div>
		// 							<img className="file" src={file.url || file.src} alt={file.caption} />
		// 							<div className="small-line-break" />
		// 						</div>
		// 					)
		// 				}) : null
		// 			}
		// 			<div>
		// 			<div className="section-divider" />
		// 			{
		// 				formData.photos.length < 1 &&
		// 				// change when ready to handle multiple photos per post
		// 				<button className="button-appearance" onClick={(e) => {
		// 					e.preventDefault()
		// 					fileInputRef.current.click()
		// 				}}>+ Add</button>
		// 			}
		// 			</div>
		// 			<div className="section-divider" />
		// 		</div>
		// 	]
		// },
		{
			label: "Tags",
			onChange: handleChangeTags,

			// change the handleChange to formulate words into tags and into an array
			properties: {
				type: "text",
				id: "tags",
				value: formData.tags.join(" "),
				ref: ref
			},
			afterInput: [
				<div key="delete-post">
					<div className="section-divider" />
					{!isNewPost && (
						<span className="link-appearance" onClick={handleDeletePost}>
							Delete Post
						</span>
					)}
				</div>,
			],
		},
	];

	return (
		<Form
			heading={
				<React.Fragment>
					<div className="heading">
						{isNewPost ? "Add a Photo" : "Edit Post"}
					</div>
					<AppMessage dependants={[formData]} scrollIntoView={true} />
					<input
						id="file-input"
						type="file"
						accept="image/*"
						required
						ref={fileInputRef}
						onChange={fileSelectedHandler}
						style={{ display: "none" }}
					/>
				</React.Fragment>
			}
			inputs={inputs}
		>
			<div className="section-divider" />
			<div className="section-divider" />
			<div className="align-center submit-actions">
				<button className="button-appearance" onClick={handleSubmit} >
					{isNewPost ? "Post" : "Update"}
				</button>
				<button className="button-appearance gray" onClick={(e) => {
					e.preventDefault()
	 				setEditPostModalOpen(false)
				}} >
					Cancel
				</button>
			</div>
		</Form>
	);
};

export default EditPostForm;
