import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";
import { DialogContext } from "../../contexts/DialogContext";


import { MenuStyle } from "./MenuStyle.styled";

import { logUserOut } from "../../firebase/auth";
// import {
// 	providerIsFacebookOrGoogle,
// } from '../../utils/appUtils';

// rendered by App.js
const Menu = ({ setMenuOpen }) => {
	const {
		currentUser,
		setSettingsModalOpen,
		setLoginModalOpen,
		offlineMode,
		setPaymentSettingsModalOpen,
		userObject,
	} = useContext(UserContext);
	const { dialog } = useContext(DialogContext);

	const [containerClass, setContainerClass] = useState("hide-menu");

	const checkClickisOutside = (e) => {
		const classArray = [...e.target.classList];
		if (classArray.includes("menu-background")) {
			return true;
		} else return false;
	};

	const handleClickOutside = () => {
		setMenuOpen(false);
	};

	useEffect(() => {
		setContainerClass("container");
	}, []);

	return (
		<MenuStyle
			onClick={(e) => checkClickisOutside(e) && handleClickOutside()}
			className="menu-background"
		>
			<div className={containerClass}>
				<div className="network-status">
					<svg
						id="network-status-indicator"
						viewBox="0 0 32 32"
						width="12px"
						height="12px"
						fill="none"
						stroke="var(--brand-green)"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
						<circle
							cx="16"
							cy="16"
							r="14"
							fill={offlineMode ? "white" : "var(--brand-green)"}
						/>
					</svg>
					&nbsp;
					{offlineMode ? "Network: Offline mode" : "Network: Connected"}
				</div>
				<svg
					className="close"
					onClick={handleClickOutside}
					viewBox="0 0 32 32"
					width="16px"
					height="16px"
					fill="none"
					stroke="currentcolor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
				>
					<path d="M2 30 L30 2 M30 30 L2 2" />
				</svg>
				<ul>
					<div>
						{(userObject && userObject.id) ? (
							<React.Fragment>
								{
									userObject.isAnonymous ? 
									<li onClick={async (e) => {
										await dialog.alert(
											<p>
												You can't log out of a demo account.
												<br/>
												<br/>
									  		Sign up or Delete your account in <span onClick={() => {
  												setMenuOpen(false);
													setSettingsModalOpen(true);
													dialog.close()
													return false
									  		}} className="link-appearance blue">Settings</span>
											</p>
										);
									}}>Logout</li>
									:
									<li onClick={() => logUserOut()}>Logout</li>
								}
							</React.Fragment>
						) : (
							<li
								onClick={() => {
									setMenuOpen(false);
									setLoginModalOpen(true);
								}}
							>
								Login
							</li>
						)}
					</div>
					<div>
						{currentUser && (
							<Link to="/messages" onClick={() => setMenuOpen(false)}>
								<li>Messages</li>
							</Link>
						)}
						{currentUser && (
							<li
								onClick={() => {
									setMenuOpen(false);
									setSettingsModalOpen(true);
								}}
							>
								Settings
							</li>
						)}
						{(currentUser 
							// && (currentUser.emailVerified || providerIsFacebookOrGoogle(currentUser)) 
							&& userObject 
							// && userObject.hasEarlyAccess
							)
							&& (
							<li
								onClick={() => {
									setMenuOpen(false);
									setPaymentSettingsModalOpen(true);
								}}
							>
								Payments
							</li>
						)}
					</div>
					<div>
						<Link to="/pricing" onClick={() => setMenuOpen(false)}>
							<li>Pricing</li>
						</Link>
						<Link to="/about" onClick={() => setMenuOpen(false)}>
							<li>About</li>
						</Link>
						<Link to="/contact" onClick={() => setMenuOpen(false)}>
							<li>Contact</li>
						</Link>
					</div>
					<div>
						<Link to="/help" onClick={() => setMenuOpen(false)}>
							<li>Help</li>
						</Link>
					</div>
				</ul>
			</div>
		</MenuStyle>
	);
};

export default Menu;
