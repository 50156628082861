import styled from 'styled-components'

/*move whitespace pre wrap to modal style sheet*/
export const ReceiptViewModalStyle = styled.div `
  text-align: left;
  white-space: pre-wrap;
  .edit-button {
    ${({isLessThan700px}) => isLessThan700px ? `
      padding: 5px;
      width: 100%;
      border-radius: 4px;
    ` : `
      position: absolute;
      top: 10px;
      right: 10px;
    `
  }
  }
  .heading {
    font-size: 2em;
    margin: 0 0 10px 0;
    text-align: center;
  }
  .thumbnail-container {
    padding: 4px;
    border: 1px solid gray;
  }
  .editable-title-container {
    display: flex;
    justify-content: space-between;
  }
  .editable-title {
    font-size 20px;
    padding: 0 3px 3px 3px;
    font-family: inherit;
  }
  .thumbnail-heading {    
    display: flex;
    justify-content: space-between;
  }
  .receipt-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: inherit;
    width: 100%;
  }
  .inline-input {
    display: inline-block;
    padding: 0 3px 3px 3px;
    font-size: 16px;
    font-family: inherit; 
    width: 40%;
  }
  .file-remove {
    text-align: right;
    svg {
      width: 18px;
      height: 18px;
      padding: 0 1px 0 3px;
    }
  }
  #revert-changes {
    position: absolute;
    top: 10px;
  }
  a {
    font-size: 14px;
  }
  .file {
    margin: 4px 0 0 0;
    width: 100%;
  }
  object.file, iframe.file {
    height: -webkit-fill-available;
    overflow: scroll;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: -webkit-box;
  }

  .embed-type-html, .embed-type-pdf {
    height: ${({isLessThan700px}) => isLessThan700px ? "80vw" : "80vh"}; 
    overflow: "hidden";
    object, iframe {
      -webkit-box-pack: center;
      -webkit-box-align: center;
      display: -webkit-box;
      ${({isLessThan700px}) => isLessThan700px ? `
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        width: 250%;
        height: 250%;
      ` : `
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        height: 165%;
        width: 165%;
      `
      }
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  .cant-display-file {
    margin: 5px;
    svg {
      margin: 10px 0 0 0;
      width: 18px;
      height: 18px;
      padding: 5px;
      background-color: red;
      border-radius: 50%;
    }
  }
`;