import { formulateSearchKeywords, getSearchRelevanceScore } from '../../utils/appUtils'
import firebase from '../../firebase/index'
import { industries } from '../../utils/appUtils'

export 	const changeFilterVisibility = (formData, mainSearchCategory) => {
	const currentUser = firebase.auth().currentUser
	let newFormData = {
		...formData,
		searchFilters: { ...formData.searchFilters },
	};

	// make all filters not visible
	for (let key in newFormData.searchFilters) {
		let obj = newFormData.searchFilters[key];
		obj.visible = false;
	}
	// add in visibility
	if (mainSearchCategory === "contractors") {
		newFormData.searchFilters.generalContractors.visible = true;
		newFormData.searchFilters.subContractors.visible = true;
		newFormData.searchFilters.posts.visible = true;
	} else if (mainSearchCategory === "users") {
		if (currentUser && currentUser.uid) {
			newFormData.searchFilters.myCustomers.visible = true;
		}
	} else if (mainSearchCategory === "documents") {
		newFormData.searchFilters.invoices.visible = true;
		newFormData.searchFilters.projects.visible = true;
		newFormData.searchFilters.bills.visible = true;
		newFormData.searchFilters.posts.visible = true;
	}

	return newFormData;
};

const getPostsResults = async (currentUser, formData, retrievedUsers) => {
	try {
		const searchText = formData.text.trim()
		let formattedLocation =  "" 

		let searchArray = formulateSearchKeywords({
			doc: {searchText, searchTextArray: searchText.toString().split(" ")}, 
			type: "search"
		})

		// make location separated by "-"
		if (formData.location) {
			// remove all ", " or extra spaces or commas 
			formattedLocation = formData.location.toLowerCase().replace(/,+\s+/g, "-")

			// replace all non A-Z  except"-"
			formattedLocation = formattedLocation.replace(/[^a-zA-Z-]/g, "")
			// firebase array-contains-any can have max 10 items in array
			searchArray = searchArray.slice(0, 9)
			// push formatted location to the most important part of the searchArray
			searchArray = [
				formattedLocation,
				...searchArray
			]
		} else {
			searchArray = searchArray.slice(0, 10)
		}

		if (!searchArray.length) {
			console.log("Error: Search array has no length")

			return []
		}

		const snapshot = await firebase.firestore().collectionGroup('posts')
			.where("visibility", "==", "public")
			.where("searchKeywords", "array-contains-any", searchArray)
			.get()

		const docs = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
		let res = []

		for (let i=0; i<docs.length; i++) {
			const post = docs[i]

			const searchName = post.caption || post.searchKeywords[0] || post.id
			let resUsername = post.username
			let resLogoUrl = post.logoUrl

			let postByRetrievedUser = post.uid ? retrievedUsers.find(user => user.id === post.uid) : null

			if (postByRetrievedUser) {
				resUsername = postByRetrievedUser.username
				resLogoUrl = postByRetrievedUser.logoUrl
			} else {
				if (!resUsername || !resLogoUrl) {
					// get the user
					postByRetrievedUser = await firebase.firestore().collection("users").doc(post.uid).get()
					.then(doc => {
						if (doc.exists) {
							return {...doc.data(), id: doc.id}
						} else return null
					}).catch(err => {
						resUsername = "Linvo User"
					})

					if (postByRetrievedUser) {
						resUsername = postByRetrievedUser.username
						resLogoUrl = postByRetrievedUser.logoUrl
					}
				}
			}

			res.push({
				...post,
				username: resUsername,
				logoUrl: resLogoUrl,
				docType: "posts",
				filterNames: ["posts"],
				visibleInSearchResults: true,
				relevanceScore: getSearchRelevanceScore(searchText, post.searchKeywords, searchName)	
			})

		}

		return res

	} catch (err) {
		// dont return error, posts are not super important
		console.error(err)
		return []
	}

}

const handleGetDocs = async (existingDocs, firebaseQuery, searchText, type) => {
	let alreadyRetrievedDocIds = []
	let publicDocs = []
	if (existingDocs && existingDocs.length) {
		// add in users invoices
		alreadyRetrievedDocIds = existingDocs.map(doc => doc.id)
	}

	if (firebaseQuery) {
		publicDocs = await firebaseQuery.get().then(snapshot => {
			let docs = []
			snapshot.docs.forEach(doc => {
				const docData = {...doc.data(), id: doc.id}
				if (!alreadyRetrievedDocIds.includes(doc.id)) {
					docs.push(docData)
				}
			})
			return docs
		})

		const resDocs = [...existingDocs || [], ...publicDocs].map(invOrProj => {
			let searchName = ""
			if (type === "invoices" || type === "bills") {
				searchName = invOrProj.invShortHand
				if (invOrProj.description) {
					searchName += " " + invOrProj.description.slice(0,40)
				}
				// searchName = invOrProj.invShortHand + " " + invOrProj.description ? invOrProj.description.slice(0,40) : ""
			}

			if (type === "projects") {	
				searchName = invOrProj.shortHandId
				if (invOrProj.projectName) {
					searchName += " " + invOrProj.projectName.slice(0,40)
				}
				// searchName = invOrProj.shortHandId + " " + invOrProj.projectName ? invOrProj.projectName.slice(0,40) : ""
			}

			return {
				...invOrProj,
				docType: type,
				filterNames: [type],
				visibleInSearchResults: true,
				description: invOrProj.description ? invOrProj.description : "",
				shortHandId: invOrProj.shortHandId ? invOrProj.shortHandId : "",
				relevanceScore: getSearchRelevanceScore(searchText, invOrProj.searchKeywords, searchName)
			}
		})

		// filter out irrelevant docs
		return resDocs.filter(doc => doc.relevanceScore > 0)
		
	} else return []

}

export const getSearchResults = async (currentUser, formData, usersInvoices, usersProjects) => {
	if (!formData.text) {
		return []
	}
	const searchText = formData.text.trim()
	if (!searchText) {
		return []
	}
	// firebase array-contains-any can have max 10 items in array
	let firebaseQuery = firebase.firestore()
	let formattedLocation =  "" 

	let searchArray = formulateSearchKeywords({
		doc: {searchText, searchTextArray: searchText.toString().split(" ")}, 
		type: "search"
	})

	let shouldAddLocationToSearchArray = true

	if (formData.mainSearchCategory === "users") {
		if (searchText !== "all") {
			shouldAddLocationToSearchArray = false
		}
	}

	// make location separated by "-"
	if (formData.location && shouldAddLocationToSearchArray) {
		// remove all ", " or extra spaces or commas 
		formattedLocation = formData.location.toLowerCase().replace(/,+\s+/g, "-")

		// replace all non A-Z  except"-"
		formattedLocation = formattedLocation.replace(/[^a-zA-Z-]/g, "")
		// firebase array-contains-any can have max 10 items in array
		searchArray = searchArray.slice(0, 9)
		// push formatted location to the most important part of the searchArray
		searchArray = [
			formattedLocation,
			...searchArray
		]
	} else {
		searchArray = searchArray.slice(0, 10)
	}
	// if searching for contractors

	if (formData.mainSearchCategory === "contractors") {
		firebaseQuery = firebaseQuery.collection("users")
		.where("searchable", "==", true)

		// replace all non A-Z  except "-"
		let formattedSearchText = searchText.toLowerCase().replace(/[^a-zA-Z-]/g, "")
		// if the user is searching for an industry in the list, use the actual list name
		industries.forEach(obj => {
			const title = obj.title.toLowerCase().replace(/[^a-zA-Z-]/g, "")
			const name = obj.name.toLowerCase().replace(/[^a-zA-Z-]/g, "")
			if (formattedSearchText === name || formattedSearchText === title) {
				// make the text the name not the title
				formattedSearchText = name
				// properIndustryName = name
			}
		})

		let newSearchText = ""

		if (formattedLocation) {
			newSearchText = formattedSearchText + "-" + formattedLocation
		} else {
			newSearchText = formattedSearchText
		}

		const generalContractorsFilter = formData.searchFilters.generalContractors
		const subContractorsFilter = formData.searchFilters.subContractors
		const searchingSubcontractors = subContractorsFilter.visible && subContractorsFilter.checked
		const searchingGeneralContractors = generalContractorsFilter.visible && generalContractorsFilter.checked

		// if both general contractors and sub contractors being searched dont add any roles query
		if (!(searchingSubcontractors && searchingSubcontractors)) {
			if (searchingGeneralContractors) {
				// searchArray.push("generalContractors")
				firebaseQuery = firebaseQuery.where("roles.generalContractor", "==", true)
			} else if (searchingSubcontractors) {
				firebaseQuery = firebaseQuery.where("roles.subContractor", "==", true)
			}
		}

		// if theres a valid location we can search through contractor keywords otherwise we have to search through basic user searchKeywords
		if (formattedLocation) {
			firebaseQuery = firebaseQuery.where("contractorKeywords", "array-contains", newSearchText)
		} else {
			firebaseQuery = firebaseQuery.where("searchKeywords", "array-contains-any", searchArray)
		}

		//  if no location specified newSearchText just doesn't include location
		firebaseQuery = firebaseQuery
		// .where("contractorKeywords", "array-contains", newSearchText)
		.orderBy("contractorRating.stars", "desc")
		// .startAfter(lastDoc)
		// .limit(20)
		return firebaseQuery.get().then(snapshot => {
			const usersArray = snapshot.docs.map(doc => {
				const user = {...doc.data(), id: doc.id}
				const isGeneralContractor = user.roles.generalContractor === true
				const isSubContractor = user.roles.subContractor === true

				const searchName = user.username
				return  {
					...user,
					docType: "users",
					filterNames: [...isGeneralContractor ? ["generalContractors"] : [], ...isSubContractor ? ["subContractors"] : []],
					// filterName: "users",
					visibleInSearchResults: true,
					// username: user.username + " " + "relevance: " + getSearchRelevanceScore(searchText, user.searchKeywords, searchName) + " " + "rating: " + user.contractorRating.stars,
					relevanceScore: getSearchRelevanceScore(searchText, user.searchKeywords, searchName)
				}
			})

			return usersArray.filter(truthy => truthy)
		})

	} else if (formData.mainSearchCategory === "users") {
		firebaseQuery = firebaseQuery.collection("users")

		let customerIdList = []
		// make the customer ID list
		if (usersInvoices && usersInvoices.length) {
			usersInvoices.forEach(inv => {
				if (inv.billTo && currentUser && inv.billTo.uid && inv.billTo.uid !== currentUser.uid) {
					if (!customerIdList.includes(inv.billTo.uid)) {
						customerIdList.push(inv.billTo.uid)
					}
				}
			})
		}

		if (formData.searchFilters.myCustomers.visible && formData.searchFilters.myCustomers.checked) {
			// search only for users customers
			// get all users invoices

			const customerRefs = customerIdList.map(id => {
				return firebaseQuery.doc(id)
			})

			let users = await firebase.firestore().runTransaction(t => {
				return Promise.all(customerRefs.map(ref => {
					return t.get(ref).then(doc => {
						if (doc.exists) {
							const userData = {...doc.data(), id: doc.id}
							const searchName = userData.username
							// filter out any users that dont have the same location
							if (formData.location && userData.fullCityString !== formData.location) {
								return null
							} else {
								return {
									...userData, 
									id: doc.id,
									docType: "users",
									filterNames: ["myCustomers", "users"],
									visibleInSearchResults: true,
									relevanceScore: getSearchRelevanceScore(searchText, userData.searchKeywords, searchName)
								}
							}
						}
					})
				}))
			})

			// only return 10 users
			if (searchText === "all") {
				return users.filter(truthy => truthy)
			} else {
				return users.filter(truthy => truthy).filter(user => user.relevanceScore > 0)
			}

		} else {
			firebaseQuery = firebaseQuery
			.where("searchable", "==", true)
			// if searching for location, only search for users that have listed the same location as their city 
			if (formData.location) {
				firebaseQuery = firebaseQuery
				.where("fullCityString", "==", formData.location)
				// if searching for all, just search for all in the location
				if (searchText !== "all") {
					firebaseQuery = firebaseQuery
					// any user can be a customer so just search all
					.where("searchKeywords", "array-contains-any", searchArray)
				}
			} else {
				if (searchText !== "all") {
					firebaseQuery = firebaseQuery
					.where("searchKeywords", "array-contains-any", searchArray)
				} // else get all users ... paginate or remove this in the future when it is too big of a query
			}

			firebaseQuery = firebaseQuery
			// rank by customer rating
			.orderBy("customerRating.stars", "desc")

			const users = await firebaseQuery.get().then(snapshot => {
				return snapshot.docs.map(doc => {
				const user = {...doc.data(), id: doc.id}
					const isUsersCustomer = customerIdList.includes(user.id)
					const searchName = user.username

					return  {
						...user,
						docType: "users",
						filterNames: isUsersCustomer ? ["myCustomers", "users"] : ["users"],
						visibleInSearchResults: true,
						relevanceScore: getSearchRelevanceScore(searchText, user.searchKeywords, searchName)
					}
				})
			})

			return users
		}
	} else if (formData.mainSearchCategory === "documents") {
		const invoicesFilter = formData.searchFilters.invoices
		const projectsFilter = formData.searchFilters.projects
		const billsFilter = formData.searchFilters.bills

		let firebaseInvQuery = null
		let firebaseProjQuery = null
		let bills = null

		if (projectsFilter.visible && projectsFilter.checked) {
			firebaseProjQuery = firebaseQuery
				.collection("projects")
				.where("visibility", "==", "public")
				.where("searchable", "==", true)
				.where("searchKeywords", "array-contains-any", searchArray)
		}

		if (billsFilter.visible && billsFilter.checked) {
			const usersBills = (usersInvoices && usersInvoices.length) ? usersInvoices.filter(inv => inv.billTo.uid === currentUser.uid) : []
			bills = await handleGetDocs(usersBills, null, searchText, "bills")
		}

		if (invoicesFilter.visible && invoicesFilter.checked) {
			firebaseInvQuery = firebaseQuery
				.collection("invoices")				
				.where("visibility", "==", "public")
				.where("searchable", "==", true)
				.where("searchKeywords", "array-contains-any", searchArray)
		}

		const invoices = await handleGetDocs(usersInvoices || [], firebaseInvQuery, searchText, "invoices")
		const projects = await handleGetDocs(usersProjects || [], firebaseProjQuery, searchText, "projects")

		return [...projects || [], ...bills || [], ...invoices || []]

	} else {
		console.warn("no matched mainSearchCategory: ", formData.mainSearchCategory)
		return []
	}


}

const addRatingToRelevance = (users, type) => {
	let sortedArray = []
	users.forEach(user => {
		if (user[type]) {
			let additionToRelevance = 0
			const verifiedRatings = user[type].verifiedRatings
			const allRatings = user[type].allRatings
			const unverifiedRatings = user[type].allRatings - verifiedRatings
			const stars = user[type].stars
			let ratio = 2
			if (verifiedRatings > 0) {
				ratio += Math.floor(unverifiedRatings / verifiedRatings)
			}
			if (ratio < 2) {
				ratio = 2
			}

			additionToRelevance += allRatings
			additionToRelevance += (stars * 15)
			additionToRelevance += verifiedRatings * 1.5
			additionToRelevance += Math.floor(unverifiedRatings / ratio)

			if (allRatings < 10) {
				additionToRelevance += 20
			}
			sortedArray.push({
				...user,
				relevanceScore: user.relevanceScore + additionToRelevance
			})
		}
	})

	return sortedArray
}

export const handleSearch = async ({formData, currentUser, setIsSearching, isFetching, canSearchAgain, setCanSearchAgain, setSearchResultsArray, setErrorObj, usersInvoices, usersProjects, overrideCanSearch}) => {
	if (!canSearchAgain && !overrideCanSearch) {
		// make it seem like a search is happening
		if (!isFetching) {
			setIsSearching(true)
		}
		const randomDelay = 100 + Math.round(500 * Math.random())
		setTimeout(() => {
			setIsSearching(false)
		}, randomDelay)
		return
	}

	setCanSearchAgain(false)
	if (!formData.text) {
		return false
	}

	try {
		if (!isFetching) {
			setIsSearching(true)
		}
		const res = await getSearchResults(currentUser, formData, usersInvoices || [], usersProjects)
		// sort by rating
		let sortedArray = [...res]

		if (formData.mainSearchCategory === "contractors") {
			// filter out anyone without the hasEarlyAccess or directlyListed property
			// sortedArray = sortedArray.filter(user => (user.hasEarlyAccess || user.directlyListed))
			sortedArray = addRatingToRelevance(sortedArray, "contractorRating")
		}

		if (formData.mainSearchCategory === "users" && !formData.searchFilters.myCustomers.checked) {
			sortedArray = addRatingToRelevance(sortedArray, "customerRating")
		}		
		// add in posts
		if (formData.searchFilters.posts.visible && formData.searchFilters.posts.checked) {
			const retrievedUsers = res.filter(doc => doc.docType === "users")
			const postsRes = await getPostsResults(currentUser, formData, retrievedUsers)
			sortedArray.push(...postsRes)
		}


		sortedArray = sortedArray.sort((a, b) => b.relevanceScore - a.relevanceScore)
		setSearchResultsArray(sortedArray)
		setIsSearching(false)
	} catch (err) {
		setIsSearching(false)
		if (err.message.includes("Missing or insufficient permissions")) {
			return
		} else {
			setErrorObj(err)
		}
	}
}

export const setImageSrcToDefault = (id, type, imageIsLoading, searchResultsArray) => {
	const findDocWithContractorLogoUrl = (idOfLogoOwner, currentLogoUrl) => {
		// find an invoice or project doc that could have a logoUrl attached to a contractor
		const found = searchResultsArray.find(doc => {
			if (doc.contractor && doc.contractor.logoUrl && doc.contractor.id === idOfLogoOwner) {
				if (doc.contractor.logoUrl !== currentLogoUrl) {
					return true
				}
			}
			// else if (doc.billTo ...) /// for projects
			// ...
			return false
		})

		return found ? found.contractor.logoUrl : null
	}

	const findUserDocWithLogoUrl = (idOfLogoOwner) => {
		// should only ever be one user with this id in search results
		const found = searchResultsArray.find(doc => doc.id === idOfLogoOwner) 
		return found ? found.logoUrl : null
	}

	const docInSearchResultsArray = searchResultsArray.find(obj => obj.id === id)
	const  fallbackLogo = "/assets/blank-profile-picture.png"

	if (type === "logoUrl") {
		// if this obj is a user profile check if any docs in results could have the users image
		let foundLogo = findDocWithContractorLogoUrl(docInSearchResultsArray.id, docInSearchResultsArray.logoUrl)
		// if a logo is found and it is not equal to this obj current logoUrl
		if (!foundLogo || foundLogo === docInSearchResultsArray.logoUrl) {
			foundLogo = fallbackLogo
		}

		return foundLogo
	} else if (type === "contractor.logoUrl") {
		// if this obj is a user profile check if any docs in results could have the users image
		// check for logo in any docs that are a user object first
		let foundLogo = findUserDocWithLogoUrl(docInSearchResultsArray.contractor.id) || findDocWithContractorLogoUrl(docInSearchResultsArray.contractor.id, docInSearchResultsArray.contractor.logoUrl)

		if (!foundLogo || foundLogo === docInSearchResultsArray.logoUrl) {
			foundLogo = fallbackLogo
		}

		// return {...obj, contractor: {...obj.contractor, logoUrl: foundLogo}}
		return foundLogo
	} else if (type === "billTo.logoUrl") {
		let foundLogo = findUserDocWithLogoUrl(docInSearchResultsArray.billTo.uid) || findDocWithContractorLogoUrl(docInSearchResultsArray.billTo.uid, docInSearchResultsArray.billTo.logoUrl)

		if (!foundLogo || foundLogo === docInSearchResultsArray.logoUrl) {
			foundLogo = fallbackLogo
		}

		return foundLogo
	} else {
		return "/assets/fallback-image.jpg"
	}
}

export const findExistingOrNewMessageThread = (userObject, usersThreads, viewedUserData) => {
	if (userObject && userObject.id && usersThreads) {
		// find a message thread between users that is not a group message
		let foundExisting = false
		if (usersThreads && usersThreads.length) {
			foundExisting = usersThreads.find(thread => thread.isGroup === false && thread.accessors.includes(viewedUserData.id))
		}

		if (foundExisting) {
			// setexistingOrNewMessageThread(foundExisting)
			return foundExisting
		} else {
			// make a new thread
			const newThread = {
				id: firebase.firestore().collection("messages").doc().id,
				isGroup: false,
				accessors: [viewedUserData.id, userObject.id],
				// messages is in a subcollection in DB
				newestMessage: {
					text: "",
					by: userObject.id
				},
				participants: {
					[viewedUserData.id]: {
						logoUrl: viewedUserData.logoUrl,
						username: viewedUserData.username,
					},
					[userObject.id]: {
						logoUrl: userObject.logoUrl,
						username: userObject.username,
					}
				}
			}
			// handleSetUserMessages({newMessages: [newThread], overwrite: false})
			// setexistingOrNewMessageThread(newThread)
			return newThread
		}

  }
}