import React, { useContext, useState, useRef } from 'react'
import imageCompression from 'browser-image-compression';

import Form from "../Forms/Form"


import { AppMessage } from "../MessageUtils";
import MoreInfoIcon from "../MoreInfoIcon";
import Autocomplete from "react-google-autocomplete";
// import AutoCompletePlace from "../GoogleLocation/AutoCompletePlace"
import { InputDiv } from '../Forms/Form.styled'


import { getRandomCoordinatesNearby, getAddressDetails } from './utils'
import { formulateSearchKeywords, industries, capitalizeFirstLetter } from "../../utils/appUtils";
import { UserContext } from "../../contexts/UserContext";
import Spinner from "../Spinner/Spinner"

import { getLocalISODate } from "../../utils/dateUtils"
import firebase from "../../firebase/index";

const MakePost = ({ 
	handleUpdatePost,
	handleDeletePost,
	userObject, 
	mainFormData, 
	handleIndustryDatalist, 
	searchingJobs, 
	searchingContractors,
	onClickOutside, 
	postType, 
	postData,
	handleSetJobs,
	filters,
}) => {
	const autoCompleteCityRef = useRef(null)
	const autoCompleteAddressRef = useRef(null)

	const {
		isFetching,
		setIsFetching,
		setErrorObj,
		offlineMode,
		handleSetSuccessText,
		isAdmin,
	} = useContext(UserContext);


	// default expires in a month
	const oneMonthInMS = 60000 * 60 * 24 * 30
	const defaultExpires = Math.round((Date.now() + oneMonthInMS) / 1000)

	const isEditingPost = postData && postData.id ? true : false


	let defaultData = {
		title: "",
		body: "",
		searchKeywords: "",
		visibility: "public", // not configurable here. this is for completely private
		photos: [],
		userId: userObject.id,
		accessors: [userObject.id],
		type: postType,
		city: mainFormData.city, // required if job or company
		adminLevel2: mainFormData.adminLevel2,
		generalLocation: mainFormData.cityCenter ? getRandomCoordinatesNearby(mainFormData.cityCenter) : "",
		viewedBy: [],
		interestedUsers: [],
		likedBy: [],
		inviteOnly: false,
		timestamp: 0,
		expires: getLocalISODate(false, defaultExpires * 1000 ),
		expiresTimestamp: defaultExpires,
		username: userObject.username, 
		// firstname
		// companyName
		publiclySearchable: true,
		private: {
			address: {
				fullAddress: ""
			},
			budget: "",
		}, // subcollection
		budget: "",
		budgetPrivate: true,
		budgetIsHourly: false,
		industry: "",
		version: 1,
		deleteOnExpiry: false,
	}

	if (isEditingPost) {
		defaultData = {
			...defaultData,
			...postData,
			version: parseInt(postData.version) + 1
		}

		// add in the private data if isEditing
		if (postData.budgetPrivate && postData.private && postData.private.budget) {
			defaultData.budget = postData.private.budget.budget
			defaultData.budgetIsHourly = postData.private.budget.budgetIsHourly
		}
	}


	const [formData, setFormData] = useState(defaultData)

	// const [postVisibility, setPostVisibility] = useState("public")

	// const postVisibilityOptions = [
	// 	{name: "Public", value: "public"},
	// 	{name: "Invite only", value: "inviteOnly"},
	// ]
	// somthing
	const [uploadProgress, setUploadProgress] = useState({
		percent: 0,
		opacity: 0,
	});

	const [storageItemsToRemove, setStorageItemsToRemove] = useState([]);

	// const [industriesFilter, setIndustriesFilter] = useState(
	// 	industries.slice(0, 6)
	// );

	const [hasEditedTitle, setHasEditedTitle] = useState(isEditingPost ? true : false)
	const [hasEditedBody, setHasEditedBody] = useState(isEditingPost ? true : false)

	// const inviteLink = `${window.location.origin}/marketplace/jobs/${marketPostDocRef.id}`

	const fileInputRef = useRef(null);

	const findAdminLevel2FromPlace = (place) => {

		// loop through all results until we can find a type of administrative_area_level_2
		let foundAdministrativeLevel2 = null
			let inAddressComponents = place?.address_components?.find(obj => {
				return obj?.types?.includes("administrative_area_level_2")
			})
			if (inAddressComponents) {
				foundAdministrativeLevel2 = inAddressComponents.long_name // has long_name and short_name
			}

		return foundAdministrativeLevel2
	}

	const fileSelectedHandler = async (e) => {
		if (e.target.files[0]) {
			const fileList = e.target.files;
			let i;
			for (i = 0; i < fileList.length; i++) {
				let file = fileList[i];
				let fileReader = new FileReader();

				const options = {
				  maxSizeMB: 1,
				  maxWidthOrHeight: 1920,
				  useWebWorker: false,
				}
				try {
				  const compressedFile = await imageCompression(file, options);

					fileReader.onload = (e) => {
						let newFile = {
							src: fileReader.result,
							url: "",
							id: Date.now().toString(),
							name: compressedFile.name,
							fileType: compressedFile.type,
							width: "",
							height: "",
							tag: "",
							isNewPhoto: true,
						};
						const image = new Image();
						image.src = fileReader.result;
						image.onload = () => {
							newFile = {
								...newFile,
								file: compressedFile,
								width: image.width,
								height: image.height,
							};

							setFormData((formData) => ({
								...formData,
								photos: [...formData.photos, newFile],
							}));
						};
						// newAllFiles.push(newFile)
					};

					fileReader.readAsDataURL(compressedFile);

				} catch (error) {
				  console.log(error);
				}

			}
		}
	};


	const fileRemovedHandler = (id) => {
		// if file exists in db window confirm delete
		const fileObj = formData.photos.find((obj) => obj.id === id);

		const newPhotos = formData.photos.filter((file) => file.id !== id);
		setFormData((formData) => ({ ...formData, photos: newPhotos }));
		// fileInput.current.value = ""

		if (fileObj && !fileObj.isNewPhoto) {
			setStorageItemsToRemove((storageItemsToRemove) => [
				...storageItemsToRemove,
				fileObj,
			]);
		}
	};

	const removeStorageItems = async (items) => {
		let deleteTasks = [];
		for (let i = 0; i < items.length; i++) {
			// remove from firebase if it exists
			const item = items[i];
			try {
				let storageRef = firebase
					.storage()
					.ref(`${userObject.id}/marketPosts/${item.id}`);
				// firebaseUserPostsDBRef.update({
				// 	photos: firebase.firestore.FieldValue.arrayRemove
				// })
				// .catch(err => setErrorObj(err))

				// update storage
				deleteTasks.push(storageRef.delete());
			} catch (err) {
				if (err.code !== "storage/object-not-found") {
					console.log("item not found in storage:", item)
					// throw err;
				}
			}
		}
		return Promise.all(deleteTasks);
	};

	const fileUploader = (files, setUploadProgress) => {
		// dont use long cache control on posts
		// const metadata = {
		//   cacheControl: 'public, max-age=2592000, stale-while-revalidate=86400'
		// }
		let uploadTaskPromises = [];
		if (files.length) {
			setUploadProgress(uploadProgress => ({
				...uploadProgress,
				opacity: 1,
			}))
			for (let i = 0; i < files.length; i++) {
				const doc = files[i];
				const storageRef = firebase.storage().ref(`${userObject.id}/marketPosts`);
				let uploadTask = storageRef.child(doc.id).put(doc.file);
				uploadTask.on(
					"state_changed", // on('evt_listener', progress(), error(), complete()
					(snapshot) => {
						// progress function
						let progress = Math.round(
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100
						);
						setUploadProgress((uploadProgress) => ({
							...uploadProgress,
							percent: isNaN(progress) ? 0 : progress,
							opacity: 1,
						}));
					},
					(err) => {
						// error function
						setUploadProgress((uploadProgress) => ({
							...uploadProgress,
							percent: 0,
							opacity: 0,
						}));
						throw err;
					},
				);
				uploadTaskPromises.push(uploadTask);
			}
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				percent: 0,
				opacity: 0,
			}));

			return Promise.all(uploadTaskPromises);
		} else {
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				percent: 100,
				opacity: 0,
			}));
			return [];
		}
	};

	// should this be in a useMemo?d
	const handleChange = (name, val, e) => {
		let newVal = val
		let newFormData = {...formData}
		if (name === "expires") {
			const d = new Date(new Date(val).valueOf() + new Date().getTimezoneOffset() * 60000)
			newFormData.expiresTimestamp = Math.round(d.valueOf() / 1000)
		}

		// let newFormData = {...formData}

		if (name === "title") {
			setHasEditedTitle(true)
		}

		if (name === "body") {
			setHasEditedBody(true)
		}

		setFormData({
			...newFormData,
			[name]: newVal
		})
	}


	// need to pass setFormData, otherwise it is outdated
	const handleChangeGeneralLocation = (name, val, place, setFormData) => {
		if (!place) {
			// return handleChange(name, val)
			return setFormData(formData => ({
					...formData,
					private: {
						...formData.private,
						address: {
							lat: null,
							lng: null,
							fullAddress: val
						}
					}
			}))
		}

		// console.log({place, adminLevel2})
		// const place_id = place.place_id
		// const fullAddress = place.formatted_address
		const latlng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
		const randomCoords = getRandomCoordinatesNearby(latlng)

		const { fullRegion, state, country } = getAddressDetails(place)
		let adminLevel2
		if (state && country) {
			const level2Name = findAdminLevel2FromPlace(place) 
			if (level2Name) {
				adminLevel2 = level2Name + ", " + state + ", " + country
			} else { // defualt to fullRegion
				adminLevel2 = fullRegion
			}
		}

		setFormData(formData => ({
				...formData,
				private: {
					...formData.private,
					address: {
						lat: latlng.lat,
						lng: latlng.lng,
						fullAddress: val,
					}
				},
				generalLocation: randomCoords,
				// update  or overwrite city
				city: fullRegion,
				adminLevel2

			}))
	}

	// need to pass setFormData, otherwise it is outdated
	const handleChangeCity = ({val, place, setFormData}) => {
		// if (autoCompleteCityRef.current) {
		// 	autoCompleteCityRef.current.value = newVal	
		// }

		if (!place) {
			return setFormData(formData => ({...formData, city: val}))
		}

		// const place_id = place.place_id
		// const city = place.formatted_address

		// if the user has selected an actual job site address, dont overwrite the random coordinates
		let randomCoords
		if (!formData.generalLocation) {
			const latlng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
			randomCoords = getRandomCoordinatesNearby(latlng)
		}

		const { fullRegion } = getAddressDetails(place)

		// setCityHasBeenEdited(true)
		setFormData(formData => ({
			...formData,
			// ...formData.private.address.fullAddress 
			...randomCoords ? {generalLocation: randomCoords} : {},
			city: fullRegion,
			// place_id
		}))
	}


	const handleSubmit = async (e) => {
		e && e.preventDefault()
		window.scrollTo(0,0)
		setIsFetching(true)
		let privateData = {...formData.private}

		let postData = {...formData}

		privateData.budget = {
			budget: formData.budget,
			budgetIsHourly: formData.budgetIsHourly
		}
		if (postData.budgetPrivate) {
			postData.budget = ""
			// postData.budgetIsHourly = false
		}

		// if (postVisibility === "public") {
		// 	postData.publiclySearchable = true
		// }

		// if (postVisibility === "inviteOnly") {
		// 	postData.publiclySearchable = false
		// 	postData.inviteOnly = true
		// 	// postData.visibility would make it totally private, those with link would not be able to view
		// }

		delete postData.private

		try {
			if (offlineMode) {
				setErrorObj({
					message: "Can't upload new posts while offline",
					noReport: true,
				});
			}

			let newPhotosArray = [];
			
			// upload or update photos handleUpdatePost does not update photos
			if (postData.photos.length && !offlineMode) {
				// remove any items that need to be removed from storage
				await removeStorageItems(storageItemsToRemove);
				// if there are new photos

				// upload any files to storage and get the url
				// if (postData.photos.find(photo => photo.isNewPhoto)) {
				// run through all photos and get the new downloadURL
				const photosToUpload = postData.photos.filter(
					(photo) => photo.isNewPhoto
				);

				const uploadTaskPromises = await fileUploader(
					photosToUpload,
					setUploadProgress
				);
				// add the url in to the firestore post
				for (let i = 0; i < postData.photos.length; i++) {
					const file = postData.photos[i];
					let newFile = { ...file };
					delete newFile.isNewPhoto;
					delete newFile.src;
					delete newFile.file;
					const fileDoneUploading = uploadTaskPromises.find(
						(snapshot) => snapshot.metadata.name === file.id
					);
					if (fileDoneUploading) {
						const url = await fileDoneUploading.ref.getDownloadURL();
						newPhotosArray.push({
							...newFile,
							url,
							updated: fileDoneUploading.metadata.updated,
							storagePath: fileDoneUploading.metadata.fullPath,
						});
					} else if (!file.isNewPhoto) {
						newPhotosArray.push(newFile);
					}
				}
				
			}

			// add in photos and search keywords
			postData = {
				...postData,
				photos: newPhotosArray,
				// searchKeywords: formulateSearchKeywords(formData.tags),
				timestamp: firebase.firestore.Timestamp.now().seconds,
				searchKeywords: formulateSearchKeywords({
					doc: postData,
					type: "marketPost",
					userObject,
				}),
			};

			await handleUpdatePost({newPostData: {...postData, private: {...privateData}}, userObject, isUsersFirstInteraction: !isEditingPost, handleSetJobs, filters})

			// scroll to top 
			onClickOutside()
			window.scrollTo(0,0)

			if (!isEditingPost) {
				handleSetSuccessText(`Post made successfully!`)
			} else {
				handleSetSuccessText(`All changes saved`)
			}

			setIsFetching(false)

		} catch (err) {
			setErrorObj(err)
			setIsFetching(false)
		}

	}

	let industriesFilter = handleIndustryDatalist(formData.industry, industries, searchingJobs, searchingContractors);

  if (formData.industry) {
  	const ind = industries.find(ind => ind.name === formData.industry.toLowerCase())
  	if (ind) {
  		let newFd = {
  			...formData
  		}

  		if (!hasEditedTitle) {
				newFd.title = `${capitalizeFirstLetter(ind.title)} needed!`
  		}

  		if (!hasEditedBody) {
				newFd.body = `Looking for a ${capitalizeFirstLetter(ind.title)}${formData.city ? " in the " + formData.city.split(",")[0] + " area" : "" }`
  		}

  		if (formData.title !== newFd.title || formData.body !== newFd.body) {
	  		setFormData(formData => ({
	  			...formData,
	  			...newFd
	  		}))
  		}
  	}
  }


	let inputs = [
		{
			beforeInput: [
				<div key="for-industry" className="section-title" >
					What
				</div>
			],
			label: "Industry",
			onChange: (name, val) => handleChange(name, val),
			// containerClass: "section-input top",
			properties: {
				type: "text",
				id: "industry",
				name: "industry",
				value: formData.industry,
				autoComplete: "off",
				required: true,
			},
			afterInput: [
				<div key="industry" 
					style={{
						position: "relative"
					}}
				>
					{
						industriesFilter.length ?
						<div className="pac-container pac-logo" 
							style={{
						    width: "100%",
						    top: "0",
						    display: formData.industry ? "block" : "none",
							}}
						>
							{
								industriesFilter.map((obj) => {
									return (
										<div
											key={obj.name}
											className="pac-item"
											onClick={() =>{
												handleChange("industry", capitalizeFirstLetter(obj.name))
											}}
										>
											<span className="material-icons pac-icon pac-icon-marker industries" >{obj.icon}</span>
											<span className="pac-item-query">
												{capitalizeFirstLetter(obj.name)}
											</span>
											<span>{capitalizeFirstLetter(obj.title)}</span>
										</div>
									)
								})
							}
						</div>
					: null
					}
					<div className="section-divider" />
				</div>
			]
		},
		{
				label: "",
				properties: {
					type: "hidden",
				},
				beforeInput: [
					<div key="near" className="section-title">
						Where
					</div>
				],
				afterInput: [
					<React.Fragment key="city">
						<div className="small-line-break" />
						<InputDiv inputTypeTextFamily={true} hasVal={true}>
								<div className="input-container" >
								<Autocomplete
								  apiKey={process.env.GOOGLE_PLACES_API_KEY}
								  onPlaceSelected={(place, target) => {
								  	handleChangeGeneralLocation("generalLocation", target.value, place, setFormData)
								  }}
								  onChange={(e) => handleChangeGeneralLocation(e.target.name, e.target.value, null, setFormData)}
									type="search"
									name="generalLocation"
									id="generalLocation"
									ref={autoCompleteAddressRef}
									placeholder="Start typing address"
									value={formData.private.address.fullAddress}
									options={{
										componentRestrictions: { country: ["ca"] },
										fields: ["geometry.location", "address_components"/*, "place_id"*/],
										types: ["address"],
									}}
								/>
								<span>
									<label htmlFor="generalLocation" >Address </label>
									 <MoreInfoIcon 
										customIcon=<span className="info-tag">Private </span>
										absolute={true}
										text="Job address is private. Users will be shown a random general location within 1 km of the site"
									/>
								</span>
							</div>
						</InputDiv>
				</React.Fragment>
			],
		},
		{
				label: "",
				properties: {
					type: "hidden",
				},
				afterInput: [
					<React.Fragment key="city">
						<div className="small-line-break" />
						<InputDiv inputTypeTextFamily={true} hasVal={true}>
							<div className="input-container" >
							<Autocomplete
							  apiKey={process.env.GOOGLE_PLACES_API_KEY}
							  onPlaceSelected={(place, target) => handleChangeCity({ val: target.value, place, setFormData })}
							  onChange={(e) => handleChangeCity({ val: e.target.value, place: null, setFormData })}
								type="search"
								name="city"
								id="city"
								ref={autoCompleteCityRef}
								placeholder="City/Region"
								value={formData.city}
								options={{
									componentRestrictions: { country: ["ca"] },
									fields: ["geometry.location", "address_components"/*, "place_id"*/],
									types: ["(cities)"],
								}}
							/>
							<span>
								<label htmlFor="city" >Main </label>
							  <MoreInfoIcon 
									customIcon=<span className="info-tag">Public </span>
									absolute={true}
									text="THe map will show your job if the city name matches this name"
								/>
							</span>
						</div>
					</InputDiv>
					<div className="section-divider" />
				</React.Fragment>
			],
		},
		{
			beforeInput: [
				<React.Fragment key="before-post-title">
					<div className="section-divider" />
					<div className="section-title">
						Details
					</div>
				</React.Fragment>
			],
			label: "Post title",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "title",
				value: formData.title,
				required: true,
			},
		},
		{
			custom: true,
			label: "Post body", 
			properties: {
				type: "textarea",
				name: "body",
				value: formData.body,
				placeholder: "",
				// autoFocus: "autoFocus",
				onChange: handleChange,
				// className: "textarea-label"
			}
		},
		{
			label: "Job Offer Expires",
			onChange: handleChange,
			properties: {
				type: "date",
				id: "expires",
				value: formData.expires,
				min: getLocalISODate()
			},
		},
		{
			label: "Auto delete this post when it expires",
			onChange: handleChange,
			beforeInput: [
				<div key="before-delete-post-expiry" className="small-line-break" />
			],
			properties: {
				type: "checkbox",
				id: "deleteOnExpiry",
				value: !formData.deleteOnExpiry,
				checked: formData.deleteOnExpiry,
			}
		},
		// not yet implemented should have 3 options, private, not searchable or public
		// {
		// 	custom: true,
		// 	label: "Visibility",
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "dropdown",
		// 		name: "visibility",
		// 		id: "visibility",
		// 		value: postVisibility,
		// 		options: postVisibilityOptions,
		// 		optionVal: "value",
		// 		onChange: (name, val) => setPostVisibility(val),
		// 	},
		// 	beforeInput: [
		// 		<div key="visibility-LB" className="small-line-break" />
		// 	],
		// 	insideInputDiv: [
		// 		<React.Fragment key="visibilityInfo">
		// 			&nbsp;
		// 			<MoreInfoIcon
		// 				absolute={true}
		// 				text={
		// 					postVisibility === "public"
		// 						? "Anyone can see this"
		// 						: "Only those with the invite link can see this"
		// 				}
		// 			/>
		// 		</React.Fragment>,
		// 	],
		// },

		// {
		// 	label: "",
		// 	visible: postVisibility === "inviteOnly",
		// 	properties: {
		// 		type: "hidden",
		// 	},
		// 	afterInput: [
		// 		<div key="copy-link">
		// 			<div className="small-line-break" />
		// 			<strong>Invite Link: </strong>

		// 				<input id="invite-link" readOnly={true} defaultValue={inviteLink} />
		// 			{" "}
		// 			<span
		// 				className="copy-link"
		// 				onClick={() => {
		// 					const linkInput = document.getElementById("invite-link")
		// 					linkInput.select();
		// 					document.execCommand("copy");
		// 					handleSetSuccessText("Link copied");
		// 				}}
		// 			>
		// 				<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		// 			    <path d="M12 2 L12 6 20 6 20 2 12 2 Z M11 4 L6 4 6 30 26 30 26 4 21 4" />
		// 				</svg>
		// 				<span>Copy</span>
		// 			</span>
		// 		</div>
		// 	],
		// },
		// {
		// 	label: "Visibility",
		// 	properties: {
		// 		type: "dropdown",
		// 		name: "visibility",
		// 		options: [{ name: "private" }, { name: "public" }],
		// 		optionVal: "name",
		// 		value: formData.visibility,
		// 		onChange: handleChange,
		// 	},
		// },
		{
			label: "Hourly",
			onChange: handleChange,
			beforeInput: [
				<div key="budget-type" >
					<div className="section-title" >
						Budget <MoreInfoIcon
							absolute={true}
							text="You can always update this later"
					/>
					</div>
				</div>
			],
			properties: {
				type: "radio",
				id: "hourlyBudgetTrue",
				name: "budgetIsHourly",
				value: true,
				checked: formData.budgetIsHourly,
			},
		},
		{
			label: "Total",
			onChange: handleChange,
			properties: {
				type: "radio",
				id: "hourlyBudgetFalse",
				name: "budgetIsHourly",
				value: false,
				checked: !formData.budgetIsHourly,
			},
		},
		{
			label: formData.budgetIsHourly ? "Hourly Budget" : "Total Budget",
			onChange: handleChange,
			properties: {
				type: "number",
				id: "budget",
				value: formData.budget,
			},
		},
		{
			label: <span>Keep budget private <MoreInfoIcon
				absolute={true}
				text={
					formData.budgetPrivate
						? "Only you can see the budget"
						: "Users will be able to see the budget"
				}
			/> </span>,
			beforeInput: [
				<div key="before-private-budget" className="small-line-break" />
			],
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "budgetPrivate",
				value: !formData.budgetPrivate,
				checked: formData.budgetPrivate,
			}
		},
		
		// not ready for this yet, need to find a way to choose people to invite
		// {
		// 	beforeInput: [
		// 		<div key="beforeVisibilityInput" className="small-line-break" />			
		// 	],
		// 	custom: true,
		// 	label: "Visibility",
		// 	properties: {
		// 		type: "dropdown",
		// 		name: "visibility",
		// 		options: [{ name: "private" }, { name: "public" }],
		// 		optionVal: "name",
		// 		value: formData.visibility,
		// 		onChange: handleChange,
		// 	},
		// 	insideInputDiv: [
		// 		<React.Fragment key="visibilityInfo">
		// 			&nbsp;
		// 			<MoreInfoIcon
		// 				absolute={true}
		// 				text={
		// 					formData.visibility === "private"
		// 						? "Anyone can see this"
		// 						: "Only those you invite can see this"
		// 				}
		// 			/>
		// 		</React.Fragment>,
		// 	],
		// },
		// {
		// 	label: "Invite",
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "title",
		// 		value: invitees,
		// 	},
		// },
		{
			label: "",
			properties: {
				type: "hidden",
			},
			afterInput: [
				<div key="title-section-divider" className="section-divider" />,
				<div key="photos">
					{formData.photos.length
						? formData.photos.map((file,i) => {
								return (
									<div key={file.id + i} className="thumbnail-container">
										<div className="thumbnail-heading">
											<div />
											<div className="file-remove">
												<svg
													onClick={() => fileRemovedHandler(file.id)}
													viewBox="0 0 32 32"
													fill="none"
													stroke="currentcolor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
												>
													<path d="M28 6 L6 6 8 30 24 29 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
												</svg>
											</div>
										</div>
										<img
											className="file"
											src={file.url || file.src}
											alt=""
											style={{
												objectFit: "cover", 
												objectPosition: file.objectPosition || "center",
										    aspectRatio: "1 / 1",
											}}
										/>
										<div className="small-line-break" />
										<input type="text" placeholder="Work type tag" max="20" value={file.tag} onChange={(e) => {
											let newPhotos = formData.photos.map(obj => {
												if (obj.id === file.id) {
													return {
														...obj,
														tag: e.target.value
													}
												} else return obj
											})

											setFormData({...formData, photos: newPhotos})
										}} />
									</div>
								);
						  })
						: null}
					<div>
						<div className="section-divider" />
							<button
								className="button-appearance"
								onClick={(e) => {
									e.preventDefault();
									fileInputRef.current.click();
								}}
							>
								+ Add Photos
							</button>
					</div>
					<div className="section-divider" />
				</div>,
			],
		}

	];

	
	if (isAdmin) {
		inputs.push({
			label: "Publicly searchable",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "publiclySearchable",
				value: !formData.publiclySearchable,
				checked: formData.publiclySearchable,
			}
		})
	}

  return (

		<Form
			heading={
				<React.Fragment>
					<div className="heading">
					{
						isEditingPost ? 
						"Edit Post"
						:
						"New Post"
					}
					</div>
					<AppMessage dependants={[formData]} scrollIntoView={true} />
					<input
						id="file-input"
						type="file"
						accept="image/*"
						multiple
						ref={fileInputRef}
						onChange={fileSelectedHandler}
						style={{ display: "none" }}
					/>
					<progress
						style={{ opacity: uploadProgress.opacity }}
						value={uploadProgress.percent}
						max="100"
					/>
				</React.Fragment>
			}
			inputs={inputs}
			onSubmit={handleSubmit}
		>
			{
				isFetching && <Spinner position="fixed" />
			}

			<div className="section-divider" />
			<div className="section-divider" />
			<div className="align-center submit-actions">
				<button className="button-appearance" >
					{
						isEditingPost ?
						"Update"
						:"Post"
					}
				</button>
				<button className="button-appearance gray" onClick={(e) => {
					e.preventDefault()
	 				onClickOutside()
	 				window.scrollTo(0,0)
				}} >
					Cancel
				</button>
				<div className="small-line-break" />
					{
						isEditingPost ? 
						<div className="link-appearance" ><span onClick={(e) => {
							e.preventDefault()
							return handleDeletePost({postData, userObject})}
						}>Delect Post</span></div>
						: ""
					}
			</div>
		</Form>
  )
}

export default MakePost