import React from 'react'

import { SymbolFromIdStyle } from './SymbolFromId.styled'

const SymbolFromId = ({id, svgProperties, maxCoordinate, className}) => {
  const getPathFromCoordinateArray = (coordinateArray, pathSeq) => {
    let resDPath = ""
    const lastIndex = coordinateArray.length - 1

    let pathSeqIterator = 0
    for (let i=0; i<coordinateArray.length; i+=2) {
      let letter = pathSeq[pathSeqIterator]
      let coordString

      if (i + 4 >= lastIndex && letter === "C") {
        // cant add any letters that require 6 coords (C)
        letter = "L"
      }

      if (i + 2 >= lastIndex && letter === "Q") {
        // cant add any letters that require 4 coords (Q)
        letter = "L"
      }

      if (letter === "M") {
        // start the moveTo
        const arrSlice = coordinateArray.slice(i, i+2)
        if (arrSlice.length < 2) {
          // if we've reached the last coordinate, make the y coord the last y coord
          coordString = coordinateArray[i] + " " + coordinateArray[i-1]
        } else {
          coordString = coordinateArray.slice(i, i+2).join(" ")
        }

        resDPath += "M" + coordString 
      }
      if (letter === "L") {
        const arrSlice = coordinateArray.slice(i, i+2)
        if (arrSlice.length < 2) {
          // if we've reached the last coordinate, make the y coord the last y coord
          coordString = coordinateArray[i] + " " + coordinateArray[i-1]
        } else {
          coordString = coordinateArray.slice(i, i+2).join(" ")
        }

        resDPath += " L" + coordString 
      }
      if (letter === "Q") {
        // start the moveTo
        coordString = coordinateArray.slice(i, i+4).join(" ")
        i+=2
        resDPath += " Q" + coordString
      }
      if (letter === "C") {
        coordString = coordinateArray.slice(i, i+6).join(" ")
        i+=4
        resDPath += " C" + coordString
      }

      if (pathSeqIterator === pathSeq.length - 1) {
        // re loop through the path seq
        pathSeqIterator = 1
      } else {
        pathSeqIterator += 1
      }

    }

    return resDPath.trim()
  }


  const getCompressedIdCharCodeArray = (charCodeArray, numberOfAxisValues) => {
    // compresses charCodeArray with string bias of least significant at end
    // eg charCodeArray = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k"o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
    // [...o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "yz"]
    // [...o", "p", "q", "r", "s", "t", "u", "v", "wx", "yz"]
    // [...o", "p", "q", "r", "s", "t", "uv", "wx", "yz"]
    // [...o", "p", "q", "r", "s", "tuv", "wxyz"]
    // [...o", "p", "q", "rs", "tuv", "wxyz"]
    // [...o", "p", "qrs", "tuvwxyz"]
    // ...

    const compressionRate = charCodeArray.length - numberOfAxisValues
    const combineNumbers = (a, b) => {
      // get the average of the 2 numbers
      return (parseFloat(a) + parseFloat(b)) / 2
    }

    if (compressionRate === 0) {
      return charCodeArray
    }

    if (compressionRate < 0) {

      let i=0
      for (let j=0; j<(compressionRate * -1); j++) {
        // mmake the rest of the values simply repeat
        charCodeArray.push(charCodeArray[i])
        i++
      }
      return charCodeArray
    } else {
      // compress the codes
      let compressedCharCodeArray = [...charCodeArray]
      
      let restrictGrowth = true
      let reLoops = 0
      const maxReLoops = Math.round(compressionRate / (numberOfAxisValues * 2))
      for (let i=charCodeArray.length -1; i>-1; i--) {
        // change charCodeArray to ['a','b','c'...] and change combineNumbers return val to a + b
        // to see what compression looks like


        if (compressedCharCodeArray.length === numberOfAxisValues) {
          return compressedCharCodeArray
        }

        // const subtractor = Math.round(1 + (charCodeArray.length / compressedCharCodeArray.length))
        // const compArrayLength = compressedCharCodeArray.length

        const lastIndexComp = compressedCharCodeArray.length - 2

        let a = compressedCharCodeArray[lastIndexComp - 2]
        let b = compressedCharCodeArray[lastIndexComp - 1]

        compressedCharCodeArray.splice(lastIndexComp - 2, 2, combineNumbers(a, b))

        if (compressedCharCodeArray.length === numberOfAxisValues) {
          return compressedCharCodeArray
        }

        if (restrictGrowth) {
          // restrict array growth
          // replace current array[i] val with combine ab
          // replace the last 2 numbers in the array with one combine number
          // c is last restrictGrowth iteration combination of a and b
          // d is last non restrictGrowth iteration combination of a and b
      
          const newLastIndex = compressedCharCodeArray.length - 1
          let c = compressedCharCodeArray[newLastIndex -1]
          let d = compressedCharCodeArray[newLastIndex]

          compressedCharCodeArray.splice(newLastIndex - 1, 2, combineNumbers(c, d))

          i--
          restrictGrowth = false
        } else {
          // allow array to grow by one
          // replace 2 and 3 to last numbers in the array with one
          restrictGrowth = true
        }

        if (i <= 3 && reLoops <= maxReLoops) {
          i = charCodeArray.length -1
          restrictGrowth = true
          reLoops += 1
        }

      }


    }
  }


  const makeAdditiveCoordinates = (coordinateArray, maxCoord) => {
    let newArray = []
    for (let i=0; i<coordinateArray.length; i+=2) {
      const codeA = coordinateArray[i - 2] 
      const codeB = coordinateArray[i - 1]
      const codeC = coordinateArray[i]
      const codeD = coordinateArray[i + 1]

      let nextX
      let nextY

      if (i === 0) {
        newArray = [codeC, codeD]
        // i+=2
      } else {
        
        nextY = codeA + codeC

        nextX = codeB + codeD

        if (nextX > maxCoord) {
          // max minus amount which excedes maxCoord
          nextX = maxCoord - (nextX - maxCoord)
        }
        if (nextY > maxCoord) {
          // max minus amount which excedes maxCoord
          nextY = maxCoord - (nextY - maxCoord)
        }
      
        newArray = [...newArray, nextX || "", nextY || ""]

      }


    }

    return newArray.filter(a => typeof(a) === "number")
  }

  const makeSymbolWithInput = ({drawType, compressString}) => {
    // minCoord = minCoord || 0
    // const compressString = false
    let startX
    let startY
    const maxCoord = maxCoordinate || 31
    const pathSeq = ["M", "Q", "Q", "Q", "Q"] 
    let numberOfCoordinates = 0
    pathSeq.forEach(p => {
      if (p === "M" || p ==="L") {
        numberOfCoordinates += 1
      }
      if (p === "Q") {
        numberOfCoordinates += 2
      } 
      if (p === "C") {
        numberOfCoordinates += 3
      }
    })

    let numberOfAxisValues = numberOfCoordinates * 2

    if (typeof(startX)=== "number") {
      // we need one less Y coord
      numberOfAxisValues -= 1
    }

    if (typeof(startY)=== "number") {
      // we need one less Y coord
      numberOfAxisValues -= 1
    }

    if (id) {
      let idString = id
      let coordinateArray = []

      // get char codes for the id
      let charCodeArray = idString.split("").map(c => c.charCodeAt(0))

      if (compressString) {
        // combine character codes near the end of the string into an average value
        charCodeArray = getCompressedIdCharCodeArray(charCodeArray, numberOfAxisValues)
      }

      const largestValueCharCode = Math.max(...charCodeArray)
      const smallestValueCharCode = Math.min(...charCodeArray)

      // shortening factor abandon.. looks awful
      // let shorteningFactor = 1
      charCodeArray.forEach((code, i) => {
        // get percent of maxCoord, charcodes are sliced to only their first 2 digits
        // let slicedCode = code.toString().slice(0,2) + "." + code.toString().slice(2)
        let newCode = code - smallestValueCharCode
        const ratio = parseInt(newCode) / (largestValueCharCode - smallestValueCharCode)

        // const newCoord = (maxCoord * ratio) / shorteningFactor
        const newCoord = maxCoord * ratio

        coordinateArray.push(Math.round(newCoord))
      })

      if (typeof(startX)=== "number") {
        coordinateArray.splice(0, 0, parseInt(startX))
      }
      if (typeof(startY)=== "number") {
        coordinateArray.splice(1, 0, parseInt(startY))
      }

      if (drawType === "additive") { // additive
        coordinateArray = makeAdditiveCoordinates(coordinateArray, maxCoord)
      }

      let resDPath = getPathFromCoordinateArray(coordinateArray, pathSeq)

      pathSeq.value = pathSeq.join(" ")
      let viewBox = `-7.5 -5 ${parseInt(maxCoord) + 15} ${parseInt(maxCoord) + 5}`

      return {
        properties: {
          viewBox,
          ...svgProperties
        },
        path: {
          properties: {
            d: resDPath
          }
        }
      }

    } else {
      return (
        <img src="/assets.fallback-image.jpg" alt="no id found"/>
      )
    }

  }

  const resProperties = makeSymbolWithInput({drawType: "", compressString: false}) // non additive

  return (
    <SymbolFromIdStyle className={className}>
      <svg strokeLinecap="round" fill="currentcolor" stroke="currentcolor" strokeLinejoin="round" {...resProperties.properties} strokeWidth="1">
        <path {...resProperties.path.properties} />
      </svg>
    </SymbolFromIdStyle>
  )

}


export default SymbolFromId