import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { NotificationsContext } from "../../contexts/NotificationsContext";
import { UserContext } from "../../contexts/UserContext";
import { MessagingContext } from "../../contexts/MessagingContext";

import { HeadingStyle } from "./HeadingStyle.styled";
// import LoginOrSignup from '../Login/LoginOrSignup'
import firebase from "../../firebase/index";
// import { notificationStatus } from "../../firebase/messaging";
// import { getDefaultNotificationSubscriptions } from "../../utils/notificationUtils";
import Navbar from '../Navbar/Navbar';

// rendered by App.js
const Heading = ({
	children,
	setLoginModalOpen,
	setMenuOpen,
	setNotificationsModalOpen,
	isLessThan700px,
}) => {
	const {
		notificationSubscriptions,
		setNotificationSubscriptions,
		privateNotificationsRef,
		setMessageNotifications,
		setInAppNotifications,
		forUsersNotifications, 
		setForUsersNotifications,
		unreadNotifications,
		inAppNotifications,
		messageNotifications
	} = useContext(NotificationsContext);
	const {
		currentUser,
		userObject,
		setErrorObj,
		offlineMode,
		userIsAnonymous,
		personalNotifications,
		setPersonalNotifications,
	} = useContext(UserContext);

	const {
		usersThreads,
	} = useContext(MessagingContext);

	let notificationIconProps = {
		text: "",
		bubbleRadius: "", // 9
		bubbleOpacity: 0,
	}

	const handleImageOnError = (e) => {
		if (e.target.src && !e.target.src.includes("blank-profile-picture")) {
			e.target.src = "/assets/blank-profile-picture.png"
		} else {
			e.target.src = ""
			e.target.alt = "Photo"
		}
	}
	
	// const handleSetNotification = async (snapshot, type, currentNotifications, setCurrentNotifications) => {
	// 	if (!snapshot.empty) {

	// 		let newCurrentNotifications = []

	// 		for (let i=0; i<snapshot.docs.length; i++ ) {
	// 			let docData = snapshot.docs[i].data();

	// 			// prevent un-resolved notification isses with user having a doc they were previously
	// 			// subscribed to still in their notifications subscriptions
	// 			// if there are any ID's in forUsers, and currentUser is not included dont add the notification
	// 			let notificationSentForUser = docData.forUsers && docData.forUsers.includes(currentUser.uid)

	// 			if (!docData.forUsers || !docData.forUsers.length) { // if no forUsers doc should be for user because they must be owner
	// 				notificationSentForUser = true
	// 			}

	// 			// even if the sender is the current user, if the notification was sent for user show it... need to fix this in the add payment send notification should not send notification if adding payment to self
	// 			if (currentUser.uid === docData.sentBy) {
	// 				notificationSentForUser = false
	// 			}

	// 			if (type === "personal" || notificationSentForUser) {
	// 				// add docLinkName
	// 				if ((type === "invoices" || type === "projects") && !docData.docLink.name) {
	// 					let docLinkName = "";
	// 					const docRef = firebase
	// 						.firestore()
	// 						.collection(docData.forDocumentCollection)
	// 						.doc(docData.forDocumentId);
	// 					let doc = await docRef
	// 						.get()
	// 						.then((doc) => ({ ...doc.data(), id: doc.id }))
	// 						.catch((err) => {
	// 							setErrorObj(err);
	// 						});
	// 					if (docData.forDocumentCollection === "invoices") {
	// 						docLinkName = `invoice #${doc.invNumber} ${doc.invShortHand}`;
	// 					} else if (docData.forDocumentCollection === "projects") {
	// 						docLinkName = `${doc.projectName} ${doc.shortHandId}`;
	// 					}

	// 				}

	// 				newCurrentNotifications.push({ ...docData, id: snapshot.docs[i].id });
	// 			}

	// 		}

	// 		// filter out duplicates
	// 		// dont remove duplicates because there could be same notifications with different id
	// 		// need to prevent a duplicate notification from being added in the first place
	// 		// newNotifications = removeDuplicates(newNotifications)
	// 		// only add new notifications
	// 		const oldNotifications = currentNotifications.filter(
	// 			(n) => !newCurrentNotifications.find((obj) => obj.id === n.id)
	// 		);

	// 		setCurrentNotifications([
	// 			...newCurrentNotifications,
	// 			...oldNotifications,
	// 		]);
	// 	} else {
	// 		// setCurrentNotifications([])
	// 	}
	// };

	const handleSetNotification = (snapshot, type, currentNotifications, setCurrentNotifications, userObject) => {
		let newCurrentNotifications = []
		if (!snapshot.empty) {
			newCurrentNotifications = snapshot.docs.map(doc => {
				const docData = {...doc.data(), id: doc.id}

				if (docData.type === "personal") {
					return docData
				}
				// prevent un-resolved notification isses with user having a doc they were previously
				// subscribed to still in their notifications subscriptions
				// if there are any ID's in forUsers, and currentUser is not included dont add the notification
				let notificationSentForUser = docData.forUsers && docData.forUsers.includes(userObject.id)

				if (!docData.forUsers || !docData.forUsers.length) { // if no forUsers doc should be for user because they must be owner
					notificationSentForUser = true
				}

				// even if the sender is the current user, if the notification was sent for user show it... need to fix this in the add payment send notification should not send notification if adding payment to self
				if (userObject.id === docData.sentBy) {
					notificationSentForUser = false
				}

				if (notificationSentForUser) {
					return docData
				} else return null

			}).filter(n => n)

			// filter out duplicates
			// dont remove duplicates because there could be same notifications with different id
			// need to prevent a duplicate notification from being added in the first place
			// newNotifications = removeDuplicates(newNotifications)
			// only add new notifications
			// const oldNotifications = currentNotifications.filter(
			// 	(n) => !newCurrentNotifications.find((obj) => obj.id === n.id)
			// );

			// setCurrentNotifications([
			// 	...newCurrentNotifications,
			// 	...oldNotifications,
			// ]);
		}


		setCurrentNotifications(newCurrentNotifications)

	};


	// useEffect(() => {
	// 	let timer = null

	// 	if (currentUser && userIsAnonymous) {
	// 		const accountCreationMS = parseInt(currentUser.metadata.createdAt)
	// 		const msSinceCreation = Date.now() - accountCreationMS 
	// 		const timeRemaining = 60000 - msSinceCreation
	// 		if (msSinceCreation < 60000) {
	// 			timer = setTimeout(() => {
	// 				setWarnUser(true)
	// 			}, timeRemaining)
	// 		}
	// 	}

	// 	return () => {
	// 		clearTimeout(timer)
	// 	}
	// }, [currentUser, userIsAnonymous])

	// get and set message notifications
	useEffect(() => {
	  if (userObject.id) {
	  	if (usersThreads && usersThreads.length) {
		  	let unreadMessages = []
		  	usersThreads.forEach(thread => {
		  		if (thread.newestMessage) {
		  			const userInThread = thread.participants[userObject.id]
		  			if (userInThread && userInThread.lastMessageRead !== thread.newestMessage.id) {
		  				unreadMessages.push(thread)
		  			}
		  		}
		  	})

		  	setMessageNotifications(unreadMessages)
	  	}
	  }
	  // dont want to include setMessageNotifications prevent infinite loop
		// eslint-disable-next-line
	}, [usersThreads, userObject.id])

	// set account is demo account reminder
	useEffect(() => {

		if (currentUser && currentUser.metadata && currentUser.metadata.createdAt) {
			if (userIsAnonymous) {
				const accountCreationMS = parseInt(currentUser.metadata.createdAt)
				const msSinceCreation = Date.now() - accountCreationMS 

				if (msSinceCreation > 60000) {
					// only the .length property is used so we dont need the qhole notification 
					setInAppNotifications([{type: "inAppComponent"}])
				}
				
			} else {
				// else remove the notification
				if (inAppNotifications && inAppNotifications.length) {
					setInAppNotifications(inAppNotifications => inAppNotifications.filter(n => n.type !== "inAppComponent"))
				}
			}
		}
	  // dont want to include setInAppNotifications prevent infinite loop
		// eslint-disable-next-line
	}, [userIsAnonymous, currentUser])

	const notificationRef = firebase.firestore().collection("notifications");

	useEffect(() => {
		// notificationStatus()
		// firebase.messaging().onMessage((payload) => console.log('Message received. ', payload))

		// if (userObject && userObject.uid) {
		if (userObject.id) {
			(async () => {
				try {
					let newNotificationSubscriptions = [];
					// let newUserNotificationSubscriptions = [];
					// if (!userObject.notificationSubscriptions) {
					// cant use a notification subscriptions property on
					// a user object unless every time a user is revoked access or chooses not to
					// follow / edit anymore the doc is removed
					// get default notification subs and project and invoice data if not found yet
					// let resUsersProjects = usersProjects;
					// let resUsersInvoices = usersInvoices;

					// if (!resUsersProjects) {
						// resUsersProjects = await getUsersProjects({userId: userObject.id, caller: "Dashboard: useEffect"});
					// 	// setUsersProjects(resUsersProjects);
					// }
					// if (!resUsersInvoices) {
						// resUsersInvoices = await getUsersInvoices({userId: userObject.id, caller: "Heading.js: useEffect"});
					// 	setUsersInvoices(resUsersInvoices);
					// }

					// newUserNotificationSubscriptions =
					// 	getDefaultNotificationSubscriptions(
					// 		userObject,
					// 		resUsersProjects,
					// 		resUsersInvoices
					// 	);

					// } else {
					// newUserNotificationSubscriptions = [...userObject.notificationSubscriptions]
					// }

					// update 2023-09-24: do not use forDucument since this causes overlapping documents
					// there is a cloud function that gets the appropriate user ids when the notification is sent and 
					// puts them into the forUsers collection

					// newUserNotificationSubscriptions.forEach((obj) => {
					// 	// obj structure:
					// 	// {
					// 	// 	docList: [
					// 	// 		{
					// 	// 			id: doc.id,
					// 	// 			roles: ex: ["editor", "contractor", ...],
					// 	// 			subscribedTo: ex: ["follow", "acceptBill", "deleted", "workerIsWorking", "paymentDue", "docAdded"]
					// 	// 		},
					// 	// 		...
					// 	// 	],
					// 	// 	type: ex: "invoices",
					// 	// }
					// 	const docIds = obj.docList.map((item) => item.id);

					// 	const numberOfSections = Math.ceil(docIds.length / 10);
					// 	let docIdSections = [];
					// 	let sectionSliceStart = 0;
					// 	for (let i = 0; i < numberOfSections; i++) {
					// 		docIdSections.push(
					// 			docIds.slice(sectionSliceStart, sectionSliceStart + 10)
					// 		);
					// 		sectionSliceStart += 10;
					// 	}

					// 	docIdSections.forEach((arrayOfIds) => {
					// 		if (obj.type === "forUsers") {
					// 			newNotificationSubscriptions.push({
					// 				id: obj.type,
					// 				docs: arrayOfIds,
					// 				listener: notificationRef
					// 					.where("forUsers", "array-contains-any", arrayOfIds)
					// 					.limit(10)
					// 					.onSnapshot((snapshot) => {
					// 						console.log("snapshot forUsers...", (forUsersNotifications))
					// 						handleSetNotification(snapshot, obj.type, forUsersNotifications, setForUsersNotifications, userObject);
					// 					}),
					// 			});
					// 		}

					// 		// update 2023-09-24: do not use forDucument since this causes overlapping documents
					// 		// there is a cloud function that gets the appropriate user ids when the notification is sent and 
					// 		// puts them into the forUsers collection

					// 		// Edit requests are the only type where a document can be listened to instead of a notification where userId is in notification forUsers 
					// 		// if (obj.type === "projects" || obj.type === "invoices") {
					// 		// 	let projectsOrInvoicesState = invoiceNotifications
					// 		// 	let setProjectsOrInvoicesState = setInvoiceNotifications
					// 		// 	if (obj.type === "projects") {
					// 		// 		projectsOrInvoicesState = projectNotifications
					// 		// 		setProjectsOrInvoicesState = setProjectNotifications
					// 		// 	} 

					// 		// 	console.log("is proj or invoice", {obj})

					// 		// 	newNotificationSubscriptions.push({
					// 		// 		id: obj.type,
					// 		// 		docs: arrayOfIds,
					// 		// 		// problem: everyone needs to be able to have access to notifications
					// 		// 		// maybe change to each document holding notifications as well as general notifications
					// 		// 		listener: notificationRef
					// 		// 			// .where("type", "==", "editRequest")
					// 		// 			.where("forDocumentId", "in", arrayOfIds)
					// 		// 			.limit(10)
					// 		// 			.onSnapshot((snapshot) => {
					// 		// 				// tbe filter based on each specific docList rols and subscribedTo
					// 		// 				// filter out notifications that should only be sent to one user
					// 		// 				// .where("type", "!=", "acceptBill")
					// 		// 				// .where("type", "!=", "addPayment")
					// 		// 				// .where("type", "!=", "deletePayment")
					// 		// 				// ^^ need to do this in the handleSetNotification fn and pass the obj.docList to this fn then find what the user is subscribed to in each of the do list items
					// 		// 				handleSetNotification(snapshot, obj.type, projectsOrInvoicesState, setProjectsOrInvoicesState, userObject);
					// 		// 			}),
					// 		// 	});
					// 		// }

					// 	});
					// });

					newNotificationSubscriptions.push({
						id: "forUsers",
						docs: [userObject.id],
						listener: notificationRef
							// .where("forUsers", "array-contains-any", [userObject.id])
							.where("forUsers", "array-contains", userObject.id)
							// .limit(10)
							.onSnapshot((snapshot) => {
								handleSetNotification(snapshot, "forUsers", forUsersNotifications, setForUsersNotifications, userObject);
							}),
					});
					// add in private notifications
					newNotificationSubscriptions.push({
						id: "personal",
						docs: [],
						listener: privateNotificationsRef.onSnapshot((snapshot) => {
							handleSetNotification(snapshot, "personal", personalNotifications, setPersonalNotifications, userObject)
						})
					})

					setNotificationSubscriptions(newNotificationSubscriptions);
					// updateUserData({notificationSubscriptions: newUserNotificationSubscriptions})
				} catch (err) {
					// throw err
					setErrorObj(err);
				}
			})();
		}

		return () => {
			if (notificationSubscriptions.length) {
				notificationSubscriptions.forEach((sub) => {
					sub.listener();
				});
			}
		};
		// eslint-disable-next-line
	}, [userObject.id]);

	const unreadNotificationsMessagesAndInAppNotifications = [
		...unreadNotifications,
		...messageNotifications,
		...inAppNotifications,
	]

	if (unreadNotificationsMessagesAndInAppNotifications.length) {
		notificationIconProps = {
			text: unreadNotificationsMessagesAndInAppNotifications.length > 9 ? "9+" : unreadNotificationsMessagesAndInAppNotifications.length,
			bubbleRadius: 10,
			bubbleOpacity: 1,
		}
	} else {
		notificationIconProps = {
			text: "",
			bubbleRadius: "",
			bubbleOpacity: 0,
		}
	}
	
	return (
		<HeadingStyle className="noprint" isLessThan700px={isLessThan700px}>
			<div className="heading-container noprint">
				<div className="bar-container" >
					<div className="logo-container" >
						<Link
							to="/"
							// onClick={() => window.location.assign(window.location.origin)}
						>
	            <img className="logo" alt="" src="/assets/linvo@2x.svg" />
						</Link>
						{
							//userObject && userObject.hasEarlyAccess ? 
							//<div className="early-access">Early Access</div>
							//: ""
						}
						{
							userIsAnonymous ? 
							<div className="early-access">Demo account</div>
							: ""
						}
					</div>
					{
						!isLessThan700px &&
			      <Navbar
			        links={[
			          { name: "Dashboard", to: "/dashboard" },
			          {
			            name: "Profile",
			            to:
			              userObject && userObject.id
			                ? `/users/${userObject.username || "undefined"}`
			                : "/users/current-user",
			          },
			          {
			            name: "Marketplace",
			            to: "/marketplace",
			          },
			        ]}
			        className="sub-nav"
			      />
					}
					<ul className="heading-icons">
						<li>
							<Link to="/search" className="no-link icon">
								<svg
									id="svg-search"
									viewBox="0 0 32 32"
									fill="none"
									stroke="currentcolor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
									<circle cx="14" cy="14" r="11" />
									<path d="M23 23 L28 28" />
								</svg>
							</Link>
						</li>
						<li className="icon" onClick={() => setNotificationsModalOpen((notificationsModalOpen) => !notificationsModalOpen)}>
							<svg
								id="svg-bell"
								viewBox="1 1 30 30"
								fill="none"
								stroke="currentcolor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							>
								<path d="M8 17 C8 12 9 6 16 6 23 6 24 12 24 17 24 22 27 25 27 25 L5 25 C5 25 8 22 8 17 Z M20 25 C20 25 20 29 16 29 12 29 12 25 12 25 M16 3 L16 6" />
								<circle
									cx="21"
									cy="11"
									r={notificationIconProps.bubbleRadius || "0"}
									fill="red"
									fillOpacity={notificationIconProps.bubbleOpacity}
									strokeWidth="0"
								/>
								<text
									fill="white"
									x="21"
									y="15"
									fontFamily="monospace"
									fontSize="0.8em"
									stroke="currentcolor"
									strokeWidth="1"
									textAnchor="middle"
								>
									{notificationIconProps.text}
								</text>
							</svg>
						</li>
						<li className="icon" id="hamburger-menu" onClick={() => setMenuOpen(true)}>
							{
								userObject.id ? (
									<React.Fragment>
										<img
											className="user-photo"
											alt=""
											src={
												(userObject.logoUrl /*&& !offlineMode*/) ? userObject.logoUrl : "/assets/blank-profile-picture.png"
											}
											width="30"
											height="30"
											onError={handleImageOnError}
										/>
										<svg
											id="online-status"
											viewBox="0 0 32 32"
											fill={offlineMode ? "var(--brand-red)" : "var(--brand-green)"}
											stroke={offlineMode ? "var(--brand-red)" : "var(--brand-green)"}
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1"
										>
										<circle 
											cx="16"
											cy="9"
											r="8"
											// fill={offlineMode === true ? "red" : "green"}
											fill={offlineMode ? "var(--brand-red)" : "var(--brand-green)"}
											stroke={offlineMode ? "var(--brand-red)" : "var(--brand-green)"}
											strokeWidth="1"
										/>
										</svg>
									</React.Fragment>
								) : (
									<svg
										id="svg-menu"
										viewBox="0 0 32 32"
										fill="none"
										stroke="currentcolor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									>
										<path d="M4 8 L28 8 M4 16 L28 16 M4 24 L28 24" />
									</svg>
								)
							}
						</li>

					</ul>
				</div>
				{children}
			</div>
			{/*<div className="section-divider" />*/}
			{
				isLessThan700px &&
				<Navbar
	        links={[
	          { name: "Dashboard", to: "/dashboard" },
	          {
	            name: "Profile",
	            to:
	              userObject && userObject.id
	                ? `/users/${userObject.username}`
	                : "/users/current-user",
	          },
	          {
	            name: "Marketplace",
	            to: "/marketplace",
	          },
	        ]}
	        className="sub-nav"
	      />
			}
		</HeadingStyle>
	);
};

export default Heading;
