import React, { useContext } from 'react'
import Table from '../../Table/Table'
import { Link } from 'react-router-dom'

// import { UserContext } from '../../../contexts/UserContext'
import { EditInvoiceContext } from '../../../contexts/EditInvoiceContext'

// rendered by renderingUtils
// errors and success text not necessary 
// everything about this invoice rendering thing is a mess, re do it
const InvoiceTopSection = React.forwardRef(({paperDetails, setLayoutModalOpen}, ref) => {
// useEffect ... send data back up to parent on the height of this top section
	const {editedInv, handleSetEditedInv, isEditing} = useContext(EditInvoiceContext)
	const entriesPageOrderName = "topSectionPageOrder"
	const invoiceHeadingSection = editedInv[entriesPageOrderName].filter(item => item.section === "invoice-heading")
	const topSectionTables = editedInv[entriesPageOrderName].filter(item => item.section === "topSectionTables")
	const getSortedTableBody = (item) => {
		let sortedTableBody = []
		item.val.forEach(row => {
			let newRow = {...row, values: []}
			item.tableHeadings.forEach(th => {
				const newTd = row.values.find(td => td.for.toLowerCase() === th.for.toLowerCase())
				if (th && th.visible !== false) {
					newRow.values.push(newTd)
				}
			})
			sortedTableBody.push(newRow)
		})
		return sortedTableBody
	}

	const handleChange = (e, itemFor) => {
		const val = e.target.value
		let newTopSectionPageOrder = []
		editedInv.topSectionPageOrder.forEach(item => {
			let newItem = {...item}
			if (item.for === itemFor) {
				newItem = {...newItem, val}
			}
			newTopSectionPageOrder.push({...newItem})
		})
		// setEditedInv(editedInv => ({...editedInv, topSectionPageOrder: newTopSectionPageOrder}))
		handleSetEditedInv({changes: {topSectionPageOrder: newTopSectionPageOrder}, caller: "InvoiceTopSection - handleChange"})
	}

	// useEffect(() => {
	// }, [editedInv.topSectionPageOrder])

	const spitOutComponent = (section, i, paperDetails) => {
		let item = section[i]
		if (item.for === "billTo" && item.val && item.val[0] && item.val[0].values && typeof(item.val[0].values) !== "string") {
			let newVal = ""
			if (editedInv.billTo) {
				newVal += (editedInv.billTo.name || "").trim() || (`${editedInv.billTo.firstname} ${editedInv.billTo.lastname || ""}`).trim()
				// only add in address if there is a userFullAddress
				let hasAddress = false
				for (let key in editedInv.billTo.address) {
					if (editedInv.billTo.address[key]) {
						hasAddress = true
					}
				}
				if (editedInv.billTo.userFullAddress && hasAddress && editedInv.billTo.showAddress !== false) {
					if (newVal) {
						newVal += ("\n")	
					}
					newVal += editedInv.billTo.userFullAddress
				}
			}

			item = {
				...item,
				val: [
					{
						...item.val[0], 
						values: [
							{...item.val[0].values[0], val: newVal}
						]
					}
				]
			}
		}

		if (!item || !item.visible) {
				return null
		}
		if (item.type === "img") {
			// return image
			return (
				<div key={item.for} id={item.for} className={item.className}>
					{
						item.url ? 
						<Link to={`/users/${editedInv.contractor.username}`}><img src={item.url} alt="logo" /></Link>
						: ""
					}
					{
						isEditing &&
						<Link className="link-appearance blue small noprint" to={`/users/${editedInv.contractor.username}`}>
							{
								item.url ?  
									<svg id="edit-logo" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
								    <path d="M30 7 L25 2 5 22 3 29 10 27 Z M21 6 L26 11 Z M5 22 L10 27 Z" />
									</svg>
								: "Add Logo"
							}
						</Link>
					}
				</div>
			)
		} // logo
		if (item.type === "div-divs") {
			let components = []
			if (item.val.length) {
				// item val is array
				item.val.forEach((divVal, j) => {
					const component = <div key={j}>{divVal}</div>
					components.push(component)
				})
			} else {
				// item val is object
				for (let key in item.val) {
					if (item.val[key]) {
						if (!item.hiddenItems || !item.hiddenItems.includes(key)) {
							const component = <div key={key}>{item.val[key]}</div>
							components.push(component)
						}
					}
				}
			}
			return (
				<div key={item.for} id={item.for} className={item.className}>
					{
						components
					}
					{
						isEditing &&
						<div id="edit-company-info" className="noprint">
							<svg onClick={() => setLayoutModalOpen(true)} viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
						    <path d="M30 7 L25 2 5 22 3 29 10 27 Z M21 6 L26 11 Z M5 22 L10 27 Z" />
							</svg>
						</div>
					}
				</div>
			)
		}
		if (item.type === "div-table") {
			return (
				<div key={item.for} id={item.for} className={item.className} >
					<Table 
						headings={item.tableHeadings}
						body={getSortedTableBody(item)}
						paperDetails={paperDetails}
						margin="0"
						tableName={item.for}
						// tableName="InvoiceTopSection"
						className={item.className}
						entriesPageOrderName={entriesPageOrderName}
						// minWidth={item.minWidth}
						// maxWidth={item.maxWidth}
					/>
				</div>
			)
		}
		if (item.type === "div") { // spacers
			// remove the page spacer after invoice heading section if both date-invoice table and company info are not visible
			if (item.for === "title") {
				return (
					<div key={item.for} id={item.for} className={item.className}>
					{
						isEditing ?
						<input type="text" value={item.val} onChange={e => handleChange(e, item.for)}/>
						: item.val
					}
					</div>
				) 

			} else {

					return (
						<div key={item.for} id={item.for} className={item.className}>
						{item.val}
						</div>
					)
			}
		}
		if (item.type === "table") { // billTo and description tables
			return (
				<Table 
					key={item.for}
					headings={item.tableHeadings}
					body={getSortedTableBody(item)}
					paperDetails={paperDetails} 
					margin="0" 
					tableName={item.for}
					className={item.className}
					minWidth={item.minWidth} // problem ?
					maxWidth={item.maxWidth} // problem ?
					entriesPageOrderName={entriesPageOrderName}
				/>
			)
		}
	}

	const renderedItems = (section) => {
		let items = []

		for (let i=0; i < section.length; i++) {
				items = [
					...items,
					spitOutComponent(section, i, paperDetails)
				]
			// }
		}
		return items
	}

	return (
		<div id="invoice-top-section" ref={ref}>
			<div id="invoice-heading" className="invoice-heading">
				{renderedItems(invoiceHeadingSection)}
				{
					// renderedItems(invoiceHeadingSection).length &&
					// <div className="page-spacer" />
				}
			</div>
			{renderedItems(topSectionTables)}

		</div>
	)
})


export default InvoiceTopSection
