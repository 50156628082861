import React, { useState } from 'react'

import Spinner from "../Spinner/Spinner";
import firebase from "../../firebase/index";
import { handleLogoImageOnError } from '../../utils/appUtils';

export const UserPhoto = ({
	alreadyFetchedUsers,
	// setAlreadyFetchedUsers,
	handleSetAlreadyFetchedUsers,
	userId, 
	user, 
	isFetchingUser 
}) => {
	const [triedToFindUser, setTriedToFindUser] = useState(false)
	const [isLocallyFetchingUser, setIsisLocallyFetchingUser] = useState(false)
	const [resUser, setResUser] = useState(user || {})

	const handleFetchUser = async (userId, alreadyFetchedUsers, handleSetAlreadyFetchedUsers) => {
		if (!userId) {
			// setPhoto({src: "/assets/fallback-image.png"})
			return {}
		}
		const userInAlreadyFetchedUsers = alreadyFetchedUsers.find(u => u.id === userId)

		let user
		if (userInAlreadyFetchedUsers) {
			user = userInAlreadyFetchedUsers
		} else {
			setIsisLocallyFetchingUser(true)
			user = await firebase.firestore().collection("users").doc(userId).get().then(doc => {
				if (!doc.exists) {
					return {}
				}
				const user = {
					...doc.data(),
					id: doc.id
				}
				return user
			}).catch(err => {
				console.log(err)
				return {}
			})
		}


		setTriedToFindUser(true)
		setIsisLocallyFetchingUser(false)
		
		if (user.id) {
			setResUser(user)
			if (!userInAlreadyFetchedUsers) {
				// setAlreadyFetchedUsers(alreadyFetchedUsers => [...alreadyFetchedUsers, user])
				handleSetAlreadyFetchedUsers({newUsers: [user], caller: "UserPhoto - user.id true"})
			}
		}
	}


	// console.log({resUser, isFetchingUser, isLocallyFetchingUser, userId, resUser, triedToFindUser})
	if (!isFetchingUser && !isLocallyFetchingUser && userId && !resUser.id && !triedToFindUser) {
		handleFetchUser(userId, alreadyFetchedUsers, handleSetAlreadyFetchedUsers)
	}


  return (
  	<div className="user-photo-container">
	  	{
	  		(isFetchingUser || isLocallyFetchingUser) ? 
		  		<div className="spinner-container" >
						<Spinner position="absolute" height="40px" width="40px" />
					</div>
					:
	  		<img 
		  		key={userId} 
		  		src={resUser.logoUrl || "/assets/blank-profile-picture.png"} 
		  		alt="" 
					onError={handleLogoImageOnError}
	  		/>
	  	}
  	</div>
  )

}
