import styled from 'styled-components'

/*move whitespace pre wrap to modal style sheet*/
export const ViewPaymentModalStyle = styled.div `
  p {
    margin: 7px 0;
  }
  p > em {
    color: gray;
    padding: 0 10px;
    box-shadow: 0px 3px 10px -5px;
  }
  .heading {
    text-align: center;
    font-size: 2em;
    margin: 0 0 10px 0;
  }
  .thumbnail-container {
    padding: 4px;
    border: 1px solid gray;
  }
  .thumbnail-heading {    
    display: flex;
    justify-content: space-between;
  }
  .inline-input {
    display: inline-block;
    padding: 0 3px 3px 3px;
    font-size: 16px;
    font-family: inherit; 
    width: 40%;
  }
  .file-remove {
    text-align: right;
    svg {
      width: 18px;
      height: 18px;
      padding: 0 1px 0 3px;
    }
  }
  #revert-changes {
    position: absolute;
    top: 10px;
  }
  a {
    font-size: 14px;
  }
  .file {
    margin: 4px 0 0 0;
    width: 100%;
  }
`;