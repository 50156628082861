import styled from 'styled-components'

export const ModalStyle = styled.div`
	margin: 40px 0;
  display: flex;
  flex-direction: column;
  ${({custom}) => custom && custom.absolute && `flex-direction: unset;` }
  align-items: center;    
  justify-content: center;
  color: #001114;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  color: var(--chicago);
  progress {
  	display: block;
  }
	.align-left {
    text-align: left;
	}
	.create-new {
		position: absolute;
		top: 0;
		left: 0;
		margin: 10px;
	}
	${({custom, willDoubleClick}) => custom && custom.absolute && `
	  top: 0;
	  left: 0;
	  bottom: 0;
		width: 100%;
	  position: absolute;
	  height: fit-content;
	  align-items: unset;
	  margin: 0;
	  z-index: 100;
	  padding: 40px 0;
		.modal-background {
			position: fixed;
			top: 0;
			bottom: 0;
			width: 100%;
		  background-color: ${willDoubleClick ? "rgba(10, 10, 10, 0.5)" : "rgba(10, 10, 10, 0.65)"};
		}
	  `
	}


	${({custom, willDoubleClick}) => custom && custom.style}

	details > summary {
    width: fit-content;
		cursor: pointer;
	}

	tooltip {
		background-color: transparent;
		color: transparent;
		padding: 0;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 80%;
		height: 80%;
		border-radius: 10px;
		:hover {
			background-color: tan;
			color: black;
			padding: 10px;
			position: absolute;
			z-index: 100;
			right: 100%;
			bottom: 100%;
			height: auto;
			width: 200px;
		}
	}
	.delete, .edit-action, .item-layout {
		top: 10px;
		right: 10px;
		position: absolute;
	}
	.item-layout {
		color: var(--chicago);
	}
	input.checkbox-item-layout {
		opacity: 0;
		width: 0;
		height: 0;
	}
	input.checkbox-item-layout {
		+ label {
			.horizontal-scroll-layout {
				display: none;
			}
			.columns-layout {
				display: inline-block;
			}
		}
		.wrapper {
			.nav-arrows {
				display: initial;
			}
		}
		.wrapper > ul {
/*			display: block;
			width: unset;*/
			/*> li {
		    display: inline-block;
			}*/
		}

	}

	input.checkbox-item-layout:checked {
		+ label {
			.columns-layout {
				display: none;
			}
			.horizontal-scroll-layout {
				display: inline-block;
			}
		}
		
		+ label + * {
			.nav-arrows {
				display: none;
			}
			> .wrapper {
				> ul {
					display: block;
					width: unset;
					text-align: center;
					padding: 0;
					> li {
				    display: inline-block;
				    .symbol-container {
		    	    max-width: min-content;
				    }
					}
				}
			}
		}
	}

	.inputs {
		.project {
			svg {
			  box-shadow: 0px 5px 11px -3px  brown;
			}
		}
		.invoice {
			svg {
			  box-shadow: 0px 5px 11px -3px  green;
			}
		}


		.symbol-container {
	    position: relative;
			text-align: center;
			display: inline-block;
		  svg {
		    width: 100px;
		    height: 100px;
		    path {
			    transition: all 0.8s linear;
		    }
			}
			p {
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    width: 100%;
		    margin: 0 2px;
			}
			.spinner {
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    height: 100%;
			}
			.spinner ~ * {
			  opacity: 0.2;
			}
		}
	}

	.section-title {
		font-size: 18px;
		font-weight: 600;
	  color: black;
	}
	.delete-invoice {
		right: 10px;
		position: absolute;
	}
	.delete-or-add {
		right: 32px;
		position: absolute;
		display: inline-block;
		span {
			margin: 0 5px;
		}
	}
	.close {
		top: 0;
		left: 0;
		padding: 10px 15px 15px 10px;
		position: absolute;
		z-index: 1000;
	}
	.container {
		position: relative;
		border-radius: 10px;
		width: 75%;
		max-width: 700px;
		height: min-content;
		background-color: white;
		padding: 35px 42px;
		font-size: 1em;
		box-shadow: 0px 4px 18px -6px;
		opacity: ${({willDoubleClick}) => willDoubleClick ? "0.95" : "1"};
	}
	.heading-l {
	  font-size: var(--font-size-ll);
	  color: black;
		margin: 0 0 15px 0;
		font-weight: 600;
	}
	.heading {
	  font-size: var(--font-size-lll);
	  color: black;
		margin: 0 0 15px 0;
		font-weight: 600;
	}
	.radio-button-nav {
		width: 100%;
		display: flex;
		justify-content: space-around;
		margin: 20px 0 10px 0;
		label {
			width: 35%;
			border: none;
		}
		input[type="radio"] {
	    position: absolute;
	    height: 0;
	    width: 0;
	    opacity: 0;
		}
		input:checked + label {
			border-bottom: 2px solid var(--chicago);
		}
	}
	${({custom}) => custom && custom.style}

`
