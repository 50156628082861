import React, { useContext } from "react";

import { EditInvoiceContext } from "../../contexts/EditInvoiceContext";

import Table from "../Table/Table";
import InvoicePage from "./InvoicePage/InvoicePage";
import InvoiceTopSection from "./InvoicePage/InvoiceTopSection";

import { parsePageOrderReferences, entriesPageOrderName } from "./invoiceUtils";
import { getNum } from "../../utils/appUtils";

// rendered by RenderPages
// errors and success text handled
const CreateNewPage = ({
	editedInv,
	handleSetEditedInv,
	isEditing,
	paperDetails,
	pageOrder,
	allRows,
	entryIndex,
	p,
	setErrorObj,
}) => {
	const handleSectionHeadingChange = (val, /*newEditedInv,*/ itemFor) => {
		// const pageOrderIndex = newEditedInv[entriesPageOrderName].findIndex(item => item.for === itemFor)
		const pageOrderIndex = editedInv[entriesPageOrderName].findIndex(
			(item) => item.for === itemFor
		);

		// create a whole new pageOrder object to keep state immutable
		let newPageOrder = [];
		// newEditedInv[entriesPageOrderName].forEach(item => newPageOrder = [...newPageOrder, {...item}])
		editedInv[entriesPageOrderName].forEach(
			(item) => (newPageOrder = [...newPageOrder, { ...item }])
		);
		newPageOrder[pageOrderIndex].val = val;
		// setEditedInv(editedInv => ({...newEditedInv, pageOrder: newPageOrder}))
		handleSetEditedInv({
			changes: { pageOrder: newPageOrder },
			caller: "renderingUtils - CreateNewPage - handleSectionHeadingChange",
		});
	};

	let pageOrderThisPage = [];
	let i = 0;
	let rowsAdded = 0;
	const entriesPermittedThisPage =
		p === 0
			? paperDetails.entriesPermittedFirstPage
			: paperDetails.entriesPermittedOnOnePage;
	// entriesPermittedFirstPage and entriesPermittedOnOnePage = null until useLayoutEffect is done
	// so first let rowsNeeded =
	let rowsNeeded = allRows.length - entryIndex;
	if (entryIndex + entriesPermittedThisPage < allRows.length) {
		rowsNeeded = entriesPermittedThisPage;
	}

	if (p === 0 && entryIndex !== 0) {
		setErrorObj({
			message:
				"entry index is not 0 on first page: renderingUtils > CreateNewPage",
		});
	}

	for (; i < rowsNeeded; ) {
		const index = entryIndex + i;

		let rowsAddedInLoop = 1; // prevent infinate loop
		if (
			allRows[index].type === "div" ||
			allRows[index].type === "sectionTotal"
		) {
			pageOrderThisPage.push(allRows[index]);
			rowsAdded += 1;
		} else if (allRows[index].type === "table") {
			// find the row in the pageOrder array and return as much of the array as you can
			const pageOrderIndex = pageOrder.findIndex((obj) => {
				return obj.for === allRows[index].for;
			});
			// let tableEntrySliceStart = 0
			// if (p !== 0) {
			const tableEntrySliceStart = pageOrder[pageOrderIndex].val.findIndex(
				(row) => row.id === allRows[index].id
			);
			// }
			let sliceEnd =
				tableEntrySliceStart + entriesPermittedThisPage - rowsAdded;
			// if (p === 0) {
			// 	tableEntrySliceStart = 0
			// sliceEnd = paperDetails.entriesPermittedFirstPage - rowsAdded
			// }

			let newValTableEntries = pageOrder[pageOrderIndex];

			newValTableEntries = newValTableEntries.val.slice(
				tableEntrySliceStart,
				sliceEnd
			);
			let tableEntries = {
				...pageOrder[pageOrderIndex],
				val: newValTableEntries,
			};
			if (tableEntries.val.length) {
				pageOrderThisPage.push(tableEntries);
			} else {
				setErrorObj({
					message:
						"no length on tableEntries.val in renderingUtils, CreateNewPage",
				});
			}
			rowsAddedInLoop = tableEntries.val.length ? tableEntries.val.length : 1;
			rowsAdded = tableEntries.val.length
				? rowsAdded + tableEntries.val.length
				: rowsAdded + 1;
		} else if (allRows[index].type === "th") {
			rowsAdded += 1;
		} else {
			rowsAdded += 1;
		}

		i += rowsAddedInLoop;
		if (i > rowsNeeded) {
			setErrorObj({
				message: "i > rowsNeeded in renderingUtils, CreateNewPage",
			});
			console.log("error: i > rowsNeeded");
		}
	}
	return pageOrderThisPage.map((item, k) => {
		if (item.type === "div") {
			if (item.className === "page-spacer" && k === 0) {
				return null;
			}
			if (isEditing && item.className === "section-heading") {
				return (
					<div className={item.className} key={`${item.for} ${item.type}`}>
						<input
							value={item.val}
							type="text"
							onChange={(e) => {
								handleSectionHeadingChange(
									e.target.value,
									/*editedInv,*/ item.for
								);
								// item.change(e.target.value, editedInv)
							}}
						/>
					</div>
				);
			} else
				return (
					<div className={item.className} key={item.for}>
						{item.val}
					</div>
				);
		} else if (item.type === "table") {
			// sort td's into the correct columns
			let sortedTableBody = [];
			item.val.forEach((row) => {
				let newRow = { ...row, values: [] };
				item.tableHeadings.forEach((th) => {
					const newTd = row.values.find(
						(td) => td.for.toLowerCase() === th.for.toLowerCase()
					);
					if (th && th.visible !== false) {
						newRow.values.push(newTd);
					}
				});
				sortedTableBody.push(newRow);
			});
			return (
				<Table
					key={`${item.for} ${item.type}`}
					paperDetails={paperDetails}
					headings={item.tableHeadings}
					body={sortedTableBody}
					tableName={item.for}
					className={item.for}
					minWidth="100%"
					entriesPageOrderName={entriesPageOrderName}
				/>
			);
		} else if (item.type === "sectionTotal") {
			return (
				<div
					className={`section-total ${item.className}`}
					key={`${item.for} ${item.type} ${item.textVal}`}
				>
					<div />
					<div>{item.textVal ? item.textVal : item.val}</div>
					<span>{getNum(item.val, 2)}</span>
				</div>
			);
		} else if (item.type === "th") {
			return null;
		} else {
			return null;
		}
	});
};

// rendered by invoice.js
// errors handled success text not necessary
export const RenderPages = ({
	editedInv,
	handleSetEditedInv,
	isEditing,
	liveEntryTotal,
	paperDetails,
	invoiceTopSectionRef,
	setLayoutModalOpen,
}) => {
	const {
		invoiceTotals,
		// totalsTableHeadings,
		// summaryEntries,
		invoiceTotalStr,
		// invoiceOwed,
		invoiceOwedStr,
		setErrorObj,
	} = useContext(EditInvoiceContext);

	if (
		!paperDetails.entriesPermittedFirstPage ||
		!paperDetails.entriesPermittedOnOnePage
	) {
		// return top section so that its height can be calculated for the useLayout effect in invoice.js
		return (
			<InvoicePage
				paperDetails={paperDetails}
				key={"first-page"}
				id={"first-page"}
			>
				<InvoiceTopSection
					editedInv={editedInv}
					ref={invoiceTopSectionRef}
					paperDetails={paperDetails}
				/>
			</InvoicePage>
		);
	} else {
		let allRows = [];
		let newPageOrder = [...editedInv[entriesPageOrderName]]; // keeps state immutable but nested pageOrder items could be a problem, as of now this doesnt mutate state because map returns a new array ?

		// check if the invoice is pre 2021-03-11 and if so, add the summary entries into pageOrder
		// let indexOfSummaryEntries = editedInv[entriesPageOrderName].find()
		// let addSummaryEntries = []
		// const hasSummaryEntriesInPageOrder = editedInv[entriesPageOrderName].findIndex(obj => obj.for === "summaryEntries")
		// filter out the old style total section heading

		newPageOrder = newPageOrder.filter((obj) => obj.for !== "totals");
		// this is now handled in specificInvoice
		// if (hasSummaryEntriesInPageOrder < 0) {
		// 	addSummaryEntries = [
		// 		{
		// 			className: "section-heading",
		// 			for: "summaryHeading",
		// 			group: "summary",
		// 			// rows: 1,
		// 			type: "div",
		// 			val: "Summary",
		// 			visible: true
		// 		},
		// 		{
		// 			for: "summaryEntries",
		// 			group: "summary",
		// 			tableHeadings: [
		// 				// {for: "date", val: "Date", visible: false},
		// 				{for: "item", val: "Item", visible: true},
		// 				{for: "qty", val: "Qty", visible: true},
		// 				// {for: "cost", val: "% Applied to", visible: true},
		// 				{for: "total", val: "Total", visible: true}
		// 			],
		// 			type: "table",
		// 			// val: "editedInv.summaryEntries", // need to parse this because it is not parsed in parsePageOrderReferences
		// 			val: editedInv.summaryEntries || [],
		// 			visible: true
		// 		}
		// 	]
		// }
		// add the totals into pageOrder and parse the page order references
		newPageOrder = [
			...parsePageOrderReferences(
				editedInv,
				newPageOrder,
				invoiceTotals,
				entriesPageOrderName
			),
			// {val: summaryEntries, type: "table", for: "summaryEntries", rows: summaryEntries.length + 1, tableHeadings: totalsTableHeadings},
			// ...addSummaryEntries,
			{
				val: invoiceTotalStr,
				visible: !(editedInv.showTotalCost === false),
				type: "sectionTotal",
				for: "summaryEntries",
				textVal: "Total Cost",
				rows: 1,
			},
			{
				val: invoiceOwedStr,
				visible: !(editedInv.showTotalOwing === false),
				type: "sectionTotal",
				for: "summaryEntries",
				textVal: "Total Owing",
				rows: 1,
			},
		];

		newPageOrder = newPageOrder.filter((item) => item && item.visible);

		newPageOrder.forEach((row) => {
			if (row.type === "div" || row.type === "sectionTotal") {
				return allRows.push(row);
			} else if (row.type === "table") {
				const rowValWithFor = row.val.map((entry) => {
					return { ...entry, for: row.for, type: row.type };
				});
				return (allRows = [
					...allRows,
					{ val: "", type: "th", for: `${row.for} th` },
					...rowValWithFor,
				]);
			} else {
				setErrorObj({
					message:
						"could not find pageOrder item.type in renderingUtils, RenderPages, type: " +
						row.type,
				});
			}
		});

		// need to subtract if the tbale splits on to the next page because a new th is added which means another row
		if (
			allRows.length > paperDetails.entriesPermittedFirstPage &&
			allRows[paperDetails.entriesPermittedFirstPage].type === "table" &&
			allRows[paperDetails.entriesPermittedFirstPage].for ===
				allRows[paperDetails.entriesPermittedFirstPage - 1].for
		) {
			// console.log("new row because of table", allRows)
			allRows.splice(paperDetails.entriesPermittedFirstPage, 0, {
				val: "",
				type: "th",
				for: `${allRows[paperDetails.entriesPermittedFirstPage].for} th`,
			});
		}

		const pages = Math.ceil(
			(allRows.length - paperDetails.entriesPermittedFirstPage) /
				paperDetails.entriesPermittedOnOnePage
		);

		let pagesNeededArray = [0];

		for (let i = 0; i < pages; i++) {
			pagesNeededArray.push(i + 1);
		}

		return (
			<div>
				{pagesNeededArray.map((page, p) => {
					if (p === 0) {
						return (
							<InvoicePage
								paperDetails={paperDetails}
								key={"first-page"}
								id={"first-page"}
							>
								<InvoiceTopSection
									ref={invoiceTopSectionRef}
									paperDetails={paperDetails}
									setLayoutModalOpen={setLayoutModalOpen}
								/>
								<CreateNewPage
									editedInv={editedInv}
									handleSetEditedInv={handleSetEditedInv}
									isEditing={isEditing}
									paperDetails={paperDetails}
									pageOrder={newPageOrder}
									entryIndex={0}
									allRows={allRows}
									p={p}
									setErrorObj={setErrorObj}
									// setUploadFileModalOpen={setUploadFileModalOpen}
								/>
							</InvoicePage>
						);
					} else {
						let entryIndex =
							paperDetails.entriesPermittedFirstPage +
							(p - 1) * paperDetails.entriesPermittedOnOnePage;
						return (
							<InvoicePage
								paperDetails={paperDetails}
								key={`page ${p}`}
								id={`page ${p}`}
							>
								<CreateNewPage
									editedInv={editedInv}
									handleSetEditedInv={handleSetEditedInv}
									isEditing={isEditing}
									paperDetails={paperDetails}
									pageOrder={newPageOrder}
									entryIndex={entryIndex}
									allRows={allRows}
									p={p}
									setErrorObj={setErrorObj}
									// setUploadFileModalOpen={setUploadFileModalOpen}
								/>
							</InvoicePage>
						);
					}
				})}
			</div>
		);
	}
};
