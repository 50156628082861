import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
// import { ResizableBox } from 'react-resizable'

import { UserContext } from "../../contexts/UserContext";
import { DialogContext } from "../../contexts/DialogContext";
import { MediaQueryContext } from "../../contexts/MediaQueryContext";
import { MessagingContext } from "../../contexts/MessagingContext";

import { UserProfileStyle } from "./UserProfileStyle.styled";
import ProfileControls from "./ProfileControls";
import UserRating from "./UserRating";
import { ControlsNavStyle } from "../Navbar/ControlsNavStyle.styled";
import ProfilePosts from "./ProfilePosts/ProfilePosts";
import ProfileAbout from "./ProfileAbout/ProfileAbout";
import ProfileReviews from "./ProfileReviews";

import Modal from "../Modal/Modal";
import LeaveReviewForm from "./LeaveReviewForm";
import EditPostForm from "./EditPostForm";

import { removeDuplicates, handleLogoImageOnError } from "../../utils/appUtils";
import firebase from "../../firebase/index";

// rendered by App.js replaces Profile.js
// errors and successText handled
const UserProfile = ({ setLoginModalOpen }) => {
	const { userUsername, section } = useParams();
	const history = useHistory();

	const { dialog } = useContext(DialogContext);
	const { isLessThan700px } = useContext(MediaQueryContext);
	const {
		currentUser,
		userObject,
		updateUserData,
		newUserData,
		setNewUserData,
		offlineMode,
		setErrorObj,
		defaultUserData,
	} = useContext(UserContext);
	const {
		usersThreads,
		setCurrentThreadData,
	} = useContext(MessagingContext);

	const [isEditing, setIsEditing] = useState(false);
	const [logoFile, setLogoFile] = useState({ id: "" });
	const [uploadProgress, setUploadProgress] = useState({
		percent: 0,
		opacity: 0,
	});
	const [userNotFound, setUserNotFound] = useState(false);
	const [reviewModalOpen, setReviewModalOpen] = useState(false);
	const [editPostModalOpen, setEditPostModalOpen] = useState(false);
	const [userHasPosts, setUserHasPosts] = useState("");
	const [postData, setPostData] = useState({});
	const [posts, setPosts] = useState([]);
	const [userRatings, setUserRatings] = useState({});

	const [currentUsersProfile, setCurrentUsersProfile] = useState(false);
	const [viewedUserData, setviewedUserData] = useState({
		...defaultUserData,
	});

	const [existingOrNewMessageThread, setexistingOrNewMessageThread] = useState(null)

	let resultUserData;

	const handleCardUserInfoChange = (name, val) => {
		const newVal = val.split("\n");
		setNewUserData((newUserData) => ({
			...newUserData,
			[name]: { rows: newVal.length, val: newVal },
		}));
	};

	const getDefaultCardUserInfo = (userDoc) => {
		let rowsNeeded = 0;
		let itemVals = [];
		userDoc.industries.forEach((ind, i) => {
			rowsNeeded += 1;
			if (i < 8) {
				itemVals.push("- " + ind.name);
			}
		});
		if (rowsNeeded < 8) {
			rowsNeeded += 1;
			itemVals.push("@" + userDoc.username);
		}
		if (userDoc.companyEmail && rowsNeeded < 8) {
			rowsNeeded += 1;
			itemVals.push(userDoc.companyEmail);
		}
		if (userDoc.phone && rowsNeeded < 8) {
			rowsNeeded += 1;
			itemVals.push(userDoc.phone);
		}

		return {
			rows: rowsNeeded,
			val: itemVals,
		};
	};

	const getPosts = async (lastVisibleDoc, setLastVisibleDoc, setEndOfPosts) => {
		const limit = 10;
		try {
			let resPosts = [];
			let snapshot;

			if (!lastVisibleDoc) {
				// if posts have not been gotten before
				snapshot = await firebase
					.firestore()
					.collection("users")
					.doc(resultUserData.id)
					.collection("posts")
					.orderBy("date", "desc")
					.limit(limit)
					.get();
			} else {
				snapshot = await firebase
					.firestore()
					.collection("users")
					.doc(resultUserData.id)
					.collection("posts")
					.orderBy("date", "desc")
					.startAfter(lastVisibleDoc)
					.limit(limit)
					.get();
			}

			if (snapshot.docs.length) {
				setUserHasPosts(true);
				setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
				resPosts = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

				// if all the data retried is less than the limit we're at the end of posts
				if (resPosts.length < limit) {
					// cant run if user has no posts
					setEndOfPosts(true);
				}

				if (!lastVisibleDoc) {
					setPosts(removeDuplicates(resPosts));
				} else {
					setPosts((posts) => removeDuplicates([...posts, ...resPosts]));
				}
			} else {
				setUserHasPosts(false);
			}
		} catch (err) {
			setErrorObj(err);
		}
	};

	const fileSelectedHandler = (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0];
			let fileReader = new FileReader();
			fileReader.onload = () => {
				const image = new Image();
				image.src = fileReader.result;
				image.onload = () => {
					const newFile = {
						src: image.src,
						width: image.width,
						height: image.height,
						url: "",
						file: file,
						name: file.name.slice(0, file.name.lastIndexOf(".")),
						uploadedDate: new Date().toJSON(),
						id: "newFile logo",
					};
					setLogoFile({ ...newFile });
				};
			};
			fileReader.readAsDataURL(file);
		}
	};

	const handleRemoveLogo = async (onlyRemoveIfNew) => {
		// if file exists in db window confirm delete
		if (!logoFile.id.includes("newFile") && !userObject.logoUrl) {
			console.log("no logoFile.id and no logoUrl");
			return null;
		}
		let confirmRemove;
		if (logoFile.id.includes("newFile")) {
			confirmRemove = true;
		} else {
			if (onlyRemoveIfNew) {
				return;
			} else {
				confirmRemove = await dialog.confirm("Confirm remove this file?");
			}
		}

		if (confirmRemove) {
			setLogoFile({ id: "" });
			fileInputRef.current.value = "";
		}

		// remove from firebase db if ecists
		if (confirmRemove && !logoFile.id.includes("newFile")) {
			// if user is offline, do this operation regardless if one of them fail
			if (offlineMode) {
				// rm photo from db
				if (newUserData.logoPath) {
					firebase
						.storage()
						.ref(newUserData.logoPath)
						.delete()
						.catch((err) => setErrorObj(err));
				}

				updateUserData({
					logoUrl: "",
					logoPath: "",
				});

				firebase
					.auth()
					.currentUser.updateProfile({
						photoUrl: "",
					})
					.catch((err) => setErrorObj(err));
			} else {
				(async () => {
					try {
						const user = firebase.auth().currentUser;

						// remove file from storage
						if (newUserData.logoPath) {
							await firebase.storage().ref(newUserData.logoPath).delete();
						}

						await updateUserData({
							logoUrl: "",
							logoPath: "",
						});

						// remove photoURL from firebase user object
						if (user && user.photoURL) {
							await firebase.auth().currentUser.updateProfile({
								photoURL: "",
							});
						}
					} catch (err) {
						setErrorObj(err);
					}
				})();
			}
		}
	};

	const handleUploadLogo = async (modifiedUserData) => {
		if (!logoFile.id) {
			console.log("no files", logoFile, newUserData.logoUrl);
			return;
		}

		try {
			if (offlineMode) {
				// eslint-disable-next-line
				throw {
					message:
						"Unable to upload files in offline mode. Check your internet connection",
					noReport: true,
				};
			}

			let uploadTask;

			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				display: "block",
				opacity: 1,
			}));

			const metadata = {
				cacheControl: "public, max-age=2592000, stale-while-revalidate=86400",
			};

			uploadTask = firebase
				.storage()
				.ref()
				.child(`${newUserData.id}/public/${logoFile.uploadedDate}`)
				.put(logoFile.file, metadata);
			// console.log(usersFilesRef.child("public").child(logoFile.uploadedDate).getDownloadURL())
			uploadTask.on(
				"state_changed", // on('evt_listener', progress(), error(), complete()
				(snapshot) => {
					// progress function
					let progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					setUploadProgress((uploadProgress) => ({
						...uploadProgress,
						percent: progress,
					}));
				},
				(err) => {
					// error function
					setUploadProgress((uploadProgress) => ({
						...uploadProgress,
						percent: 0,
						opacity: 0,
					}));
					throw err;
				} // complete function
			);

			return uploadTask;
		} catch (err) {
			throw err;
		}
	};

	const getRatings = async (userId) => {
		const userRatingsSnapshot = await firebase
			.firestore()
			.collection("users")
			.doc(userId)
			.collection("ratings")
			.get();
		let ratings = {};
		userRatingsSnapshot.forEach((doc) => (ratings[doc.id] = doc.data()));
		return ratings;
	};

	// prompt login if path === undefined
	useEffect(() => {
		if (userUsername === "current-user") {
			setLoginModalOpen(true);
			history.replace("/users/undefined/signInWithPopup");
		}

		if (userUsername === "undefined" && userObject.username) {
			setLoginModalOpen(false);
			history.replace(`${userObject.username}`);
		}
	}, [userObject.username, userUsername, setLoginModalOpen, history]);

	useEffect(() => {
		if (
			userUsername &&
			userUsername !== "current-user" &&
			userUsername !== "undefined"
		) {
			(async () => {
				try {
					const snapshot = await firebase
						.firestore()
						.collection("users")
						.where("username", "==", userUsername)
						.get()
						.then((snapshot) => snapshot);
					if (!snapshot.docs.length) {
						setUserNotFound(true);
						// setNewUserData({...defaultUserData})
					} else {
						setUserNotFound(false);
						if (snapshot.docs.length > 1) {
							// eslint-disable-next-line
							throw {
								message: "more than one user with username " + userUsername,
							};
						} else {
							let userDoc = {...snapshot.docs[0].data(), id: snapshot.docs[0].id}; /*.map(doc => doc.data())*/
							const ratings = await getRatings(userDoc.id);

							if (currentUser && userDoc.id === currentUser.uid) {
								setCurrentUsersProfile(true);

								// set default cardUserInfo if no cardUserInfo
								setNewUserData((newUserData) => ({
									...newUserData,
									...userDoc,
									cardUserInfo: userDoc.cardUserInfo
										? userDoc.cardUserInfo
										: getDefaultCardUserInfo(userDoc),
								}));
							} else {
								setCurrentUsersProfile(false);
								setviewedUserData((viewedUserData) => ({
									...viewedUserData,
									...userDoc,
									industryTitle: userDoc.industries.length
										? userDoc.industries[0].name
										: "",
									id: snapshot.docs[0].id,
									companyName: userDoc.companyName
										? userDoc.companyName
										: `${userDoc.firstname} ${userDoc.lastname}`,
									companyEmail: userDoc.companyEmail
										? userDoc.companyEmail
										: userDoc.email,
									logoUrl: userDoc.logoUrl
										? userDoc.logoUrl
										: "/assets/blank-profile-picture.png",
									cardUserInfo: userDoc.cardUserInfo
										? userDoc.cardUserInfo
										: getDefaultCardUserInfo(userDoc),
								}));
							}

							setUserRatings(ratings);
						}
					}

					// }
				} catch (err) {
					setErrorObj(err);
				}
			})();
		}

		// prevent navigations from a users profile to "My Profile" keeping the same newUserData
		// eslint-disable-next-line
	}, [/*userObject,*/ /*logoFile,*/ userUsername, currentUser]);

	useEffect(() => {
		if (textareaRef && textareaRef.current) {
			textareaRef.current.focus();
		}
	}, [isEditing /*, userUsername*/]);

	useEffect(() => {
		// reset data, needed when navigating from a users profile to My Profile
		return () => {
			setIsEditing(false);
			setUserHasPosts("");
			setUserRatings({});
			setPosts([]);
			setLogoFile({ id: "" });
			setReviewModalOpen(false);
			setEditPostModalOpen(false);
		};
	}, [userUsername]);

	// get ready for user to click send message...
	// check if users have an existing message thread, if they do set this thread else create new
	useEffect(() => {
		// if can message and usersThreads have been retrieved (are not null)
	  if (!existingOrNewMessageThread && userObject && userObject.id && viewedUserData.id && usersThreads) {
			// find a message thread between users that is not a group message
			let foundExisting = false
			if (usersThreads && usersThreads.length) {
				foundExisting = usersThreads.find(thread => thread.isGroup === false && thread.accessors.includes(viewedUserData.id))
			}

			if (foundExisting) {
				setexistingOrNewMessageThread(foundExisting)
			} else {
				// make a new thread
				const newThread = {
					id: firebase.firestore().collection("messages").doc().id,
					isGroup: false,
					accessors: [viewedUserData.id, userObject.id],
					// messages is in a subcollection in DB
					newestMessage: {
  					text: "",
  					by: userObject.id
  				},
					participants: {
						[viewedUserData.id]: {
							logoUrl: viewedUserData.logoUrl,
							username: viewedUserData.username,
						},
						[userObject.id]: {
							logoUrl: userObject.logoUrl,
							username: userObject.username,
						}
					}
				}
				// handleSetUserMessages({newMessages: [newThread], overwrite: false})
				setexistingOrNewMessageThread(newThread)
			}

	  }
	}, [existingOrNewMessageThread, usersThreads, viewedUserData, userObject])


	const fileInputRef = useRef(null);
	const logoRef = useRef(null);
	const textareaRef = useRef(null);

	resultUserData = currentUsersProfile ? newUserData : viewedUserData;

	if (userNotFound) {
		return (
			<div className="grid-container" >
				<div>User not found</div>
			</div>
		)
	} else {
		return (
			<UserProfileStyle id="UserProfileStyle">

				{reviewModalOpen && (
					<Modal
						custom={{ absolute: true }}
						onClickOutside={() => setReviewModalOpen(false)}
					>
						<LeaveReviewForm
							resultUserData={resultUserData}
							setReviewModalOpen={setReviewModalOpen}
							userRatings={userRatings}
							setUserRatings={setUserRatings}
						/>
					</Modal>
				)}
				{editPostModalOpen && (
					<Modal
						custom={{ absolute: true }}
						onClickOutside={() => setEditPostModalOpen(false)}
					>
						<EditPostForm
							setUserHasPosts={setUserHasPosts}
							posts={posts}
							setPosts={setPosts}
							postData={postData}
							setEditPostModalOpen={setEditPostModalOpen}
						/>
					</Modal>
				)}
				<input
					ref={fileInputRef}
					onChange={(e) =>
						currentUsersProfile ? fileSelectedHandler(e) : null
					}
					className="hidden-input"
					type="file"
					accept="image/*"
				/>

				<div className="grid-container" >
					<div className="actions">
						<div className="actions-icon-container">		
							{
								resultUserData.fullCityString ?
								<div className="location">
									<span className="location-name" >
										<span className="material-icons location-icon" >{"\ue0c8"}</span>
										{resultUserData.fullCityString}
									</span>
								</div>
								: ""
							}
						{currentUsersProfile ? (
							<React.Fragment>
									<ProfileControls
										logoFile={logoFile}
										setLogoFile={setLogoFile}
										fileInputRef={fileInputRef}
										handleRemoveLogo={handleRemoveLogo}
										isEditing={isEditing}
										setIsEditing={setIsEditing}
										handleUploadLogo={handleUploadLogo}
										setUploadProgress={setUploadProgress}
									/>
							</React.Fragment>
						) : currentUser ? (
							<React.Fragment>
								{
									(resultUserData.id && resultUserData.id !== currentUser.uid) &&
									<Link onClick={(e) => {
										if (existingOrNewMessageThread) {
									  		setCurrentThreadData(newThreadData => {
									  			return {
									  				...existingOrNewMessageThread,
									  				newestMessage: {
									  					text: "",
									  					by: userObject.id
									  				},
														participants: {
															...existingOrNewMessageThread.participants,
															[userObject.id]: {
																logoUrl: userObject.logoUrl,
																username: userObject.username,
															}
														}
									  			}
									  		})
										} else {
											e.preventDefault()
										}
									}} to={`/messages${existingOrNewMessageThread ? `?threadId=${existingOrNewMessageThread.id}&from=${userObject.id}&to=${resultUserData.id}&toUsername=${resultUserData.username}` : ""}`} className="button-appearance">
										<svg
											viewBox="0 0 32 32"
											fill="none"
											stroke="currentcolor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
										>
											<path d="M2 4 L30 4 30 22 16 22 8 29 8 22 2 22 Z" />
										</svg>
										Message
									</Link>
								}
							</React.Fragment>
						) : 
							<React.Fragment>							
								{
									<Link to={`/messages`} className="button-appearance">
										<svg
											viewBox="0 0 32 32"
											fill="none"
											stroke="currentcolor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
										>
											<path d="M2 4 L30 4 30 22 16 22 8 29 8 22 2 22 Z" />
										</svg>
										Message
									</Link>
								}
							</React.Fragment>
						}
						</div>
					</div>
					<React.Fragment>
						<div className="star-rating">
							<UserRating userRatings={userRatings} />
						</div>
						<div className="company-name">{resultUserData.companyName}</div>
					</React.Fragment>
					<progress
						style={{ opacity: uploadProgress.opacity }}
						value={uploadProgress.percent}
						max="100"
					/>
					<React.Fragment>
						<div className="inner-img-container">
							{isEditing && (
								<div className="edit-logo-actions">
									<svg
										className="edit-logo-icon"
										onClick={(e) => fileInputRef.current.click()}
										viewBox="0 0 32 32"
										width="16px"
										height="16px"
										fill="none"
										stroke="currentcolor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									>
										<path d="M30 7 L25 2 5 22 3 29 10 27 Z M21 6 L26 11 Z M5 22 L10 27 Z" />
									</svg>
									{userObject.logoUrl && (
										<svg
											onClick={() => handleRemoveLogo()}
											viewBox="0 0 32 32"
											width="16px"
											height="16px"
											fill="none"
											stroke="currentcolor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
										>
											<path d="M28 6 L6 6 8 30 24 30 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
										</svg>
									)}
								</div>
							)}
							<img
								ref={logoRef}
								src={
									(logoFile.src ? logoFile.src : resultUserData.logoUrl) ||
									"/assets/blank-profile-picture.png"
								}
								alt="logo"
								onError={handleLogoImageOnError}
							/>
						</div>
						<div className="user-info">
							{isEditing ? (
								<React.Fragment>
									<textarea
										ref={textareaRef}
										id="cardUserInfo"
										rows={parseInt(resultUserData.cardUserInfo.val.length)}
										value={resultUserData.cardUserInfo.val.join("\n")}
										onChange={(e) =>
											handleCardUserInfoChange("cardUserInfo", e.target.value)
										}
									/>
									<div className="auto-set-card-info">
										<span
											onClick={(e) =>
												setNewUserData((newUserData) => ({
													...newUserData,
													cardUserInfo: getDefaultCardUserInfo(newUserData),
												}))
											}
											className="link-appearance"
										>
											auto set
										</span>
									</div>
								</React.Fragment>
							) : (
								resultUserData.cardUserInfo.val.map((t, i) => (
									<p key={i}>{t}</p>
								))
							)}
						</div>
					</React.Fragment>
					<div className="profile-navigation">
						<ControlsNavStyle isLessThan700px={isLessThan700px}>
							<div className="nav-container">
								<Link
									className="no-link"
									to={{
										pathname: `/users/${userUsername}/gallery`,
									}}
								>
									<div
										className={`nav-item ${
											section === "gallery" || !section ? "active" : ""
										}`}
									>
										Gallery
									</div>
								</Link>
								<Link
									className="no-link"
									to={{
										pathname: `/users/${userUsername}/about`,
									}}
								>
									<div
										className={`nav-item ${section === "about" ? "active" : ""}`}
									>
										About
									</div>
								</Link>
								<Link
									className="no-link"
									to={{
										pathname: `/users/${userUsername}/reviews`,
									}}
								>
									<div
										className={`nav-item ${
											section === "reviews" ? "active" : ""
										}`}
									>
										Reviews
									</div>
								</Link>
							</div>
						</ControlsNavStyle>
					</div>
					<div className="profile-section-container">
						{userUsername === resultUserData.username && (
							<React.Fragment>
								<div className="section-divider" />
								<div className="section-divider" />
								{(section === "gallery" || !section) && (
									<ProfilePosts
										userHasPosts={userHasPosts}
										setPosts={setPosts}
										posts={posts}
										getPosts={getPosts}
										setPostData={setPostData}
										resultUserData={resultUserData}
										currentUsersProfile={currentUsersProfile}
										setEditPostModalOpen={setEditPostModalOpen}
									/>
								)}
								{section === "about" && (
									<ProfileAbout
										resultUserData={resultUserData}
										currentUsersProfile={currentUsersProfile}
									/>
								)}
								{section === "reviews" && (
									<ProfileReviews
										resultUserData={resultUserData}
										userRatings={userRatings}
										setUserRatings={setUserRatings}
										reviewModalOpen={reviewModalOpen}
										setReviewModalOpen={setReviewModalOpen}
									/>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</UserProfileStyle>
		);
	}
};

export default UserProfile;
