import styled from 'styled-components'

export const ProfileAboutStyle = styled.div `
	.icon-container {
		position: absolute;
		top: -25px;
		right: 0;
		display: flex;
		justify-content: flex-end;
		div {
			padding: 5px;
			margin-left: 10px;
		}
		svg {
			height: 16px;
			width: 16px;
		}
	}
	.main-text-wrapper {
    white-space: break-spaces;
		position: relative;
		.main-text {
			padding: 0;
			margin: 0;
			width: 100%;
	    font: inherit;
	    line-height: inherit;
	    background-color: transparent;
		}
		textarea {
			width: 100%;
			font: inherit;
			line-height: inherit;
			background-color: transparent;
			padding: 0;
		}
	}

`