import React from 'react'

export const AddDocumentSvg = ({className}) => {
	return (
		<svg 
			xmlns="http://www.w3.org/2000/svg"
			style={{
				color: "gray",
			  // filter: "url(#shadow1)"
		    // borderRadius: '10px',
				// boxShadow: '0 0 17px 1px currentcolor'
		  }} 
		  // filter="url(#shadow1)"
	    viewBox="0 0 32 32" 
	    className={className || ""} 
	    stroke="gray"
	    strokeWidth="2.5" 
	    fill="none" 
	    // strokeLinecap="round" 
	    strokeLinejoin="round" >
			<path filter="url(#shadow1)" d="M6 12 L26 12 M16 2 L16 22" />
			<text x="16" y="30" strokeWidth="1" textAnchor="middle" fontSize="0.55em" >ADD</text>
		</svg>
	)
}

export const AddCircleButton = (properties) => {
	return (
		<svg 
			xmlns="http://www.w3.org/2000/svg"
		  // filter="url(#shadow1)"
	    viewBox="0 0 32 32" 
	    // className={className || ""} 
	    stroke="currentcolor"
	    strokeWidth="3" 
	    fill="none" 
	    // strokeLinecap="round" 
	    strokeLinejoin="round" 
	    strokeLinecap="round"
	    {...properties}
			style={{
				color: "white",
				padding: "4px",
			  boxShadow: "2px 3px 9px -3px var(--chicago)",
				borderRadius: "50%",
				margin: "2px",
				border: "outset var(--chicago)",
				borderWidth: "thin",
		    verticalAlign: "middle",
		    backgroundColor: "var(--brand)",
		    width: properties.width || "14px",
		    height: properties.height || "14px",
		    ...properties.style ? properties.style : {}
		  }} 
    >
			<path filter="url(#shadow1)" d="M16 2 L16 30 M2 16 L30 16" />
		</svg>
	)
}

export const LayoutIconColumnsSvg = ({className}) => {
	return (
		<svg 
			xmlns="http://www.w3.org/2000/svg"
			className={className || ""}
			style={{
				width: "1.5em",
		  }} 
	    viewBox="0 0 32 32" 
	    stroke="currentcolor"
	    strokeWidth="2" 
	    fill="none" 
	    strokeLinecap="round" 
	    strokeLinejoin="round" >
	    <rect stroke="none" fill="currentcolor" x="0" y="0" width="32" height="32" />
	    <path stroke="white" strokeWidth="4" d="M0 16 L32 16 M16 0 L16 32" />
		</svg>
	)
}


export const LayoutIconRowSvg = ({className}) => {
	return (
		<svg 
			className={className || ""}
			style={{
				width: "2.5em"
		  }} 
	    viewBox="0 0 42 19" 
	    stroke="currentcolor"
	    strokeWidth="2" 
	    fill="none" 
	    strokeLinecap="round" 
	    strokeLinejoin="round" >

	    <rect stroke="none" fill="currentcolor" x="0" y="0" width="42" height="14"/>
			<path stroke="white" strokeWidth="2" d="M15 0 L15 24 M31 0 L31 24" />
			<path strokeWidth="2" d="M10 17 L20 17" />
		</svg>
	)
}

export const SearchIcon = (props) => {
	props = props || {}
	return (
		<svg 
			viewBox="0 0 32 32" 
			fill="none" 
			stroke="currentcolor" 
			strokeLinecap="round" 
			strokeLinejoin="round" 
			strokeWidth="2"
			{...props}
		>
	    <circle cx="14" cy="14" r="11" />
	    <path d="M23 23 L28 28"  />
		</svg>
	)
}