import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";

import { UserContext } from "../../contexts/UserContext";
import { DialogContext } from "../../contexts/DialogContext";


import Modal from "../Modal/Modal";
import Form from "../Forms/Form";
import { InputDiv } from '../Forms/Form.styled'
import { AddCircleButton } from '../svgComponents/generalIcons'
import MoreInfoIcon from '../MoreInfoIcon'
// import AutoCompletePlace from '../GoogleLocation/AutoCompletePlace'

import Spinner from "../Spinner/Spinner";

import { AppMessage } from "../MessageUtils";

import firebase from "../../firebase/index";
import {
	handleDeleteAccount,
	signInWithGoogle,
	signInWithFacebook,
} from "../../firebase/auth";
import { 
	formulateSearchKeywords,
	industries, 
	capitalizeFirstLetter,
	// formatLocationString,
	getContractorKeywords,
	getNum,
} from "../../utils/appUtils";
import {
	msInADay,
	getTimeRemaining,
} from "../../utils/dateUtils";

import { findAdminLevel2, getRandomCoordinatesNearby, getAddressDetails } from '../Marketplace/utils'


const UserSettingsModalStyle = styled.div`
	.early-access-only {
		color: gray;
		::after {
			content: "🔒 Early access only";
			font-size: var(--font-size-xs);
			box-shadow: 0 0 12px -4px;
			padding: 0 5px;
		}
	}
	// .demo-account {
	// 	border: 1px solid red;
	// 	padding: 10px;
	// 	background-image: linear-gradient;
	// }
	input {
		font-size: inherit;
	}
	.ind-name {
		width: 110px;
	}
	.ind-rate {
		width: 50px;
	}
	.spacer-for-label {
		margin-top: 5px;
		height: 15px;
	}
	#label-removeAutoCompleteUFA::after {
		content: "Full address";
		font-size: 12px;
		margin: 10px 0 10px 0;
	}
	.absolute-info-icon {
		position: absolute;
		top: 0;
	}
	.specialties-wrapper {
		.specialty {
			padding: 1px 7px;
			font-size: var(--font-size-s);
			color: #ada9a9;
			border-radius: 10px;
			margin: 3px 10px 3px 0;
	    box-shadow: 0px 1px 5px 0px var(--chicago);
      display: inline-block;
			svg {
		    padding: 5px 2px 5px 7px;
		    vertical-align: middle;
		    width: 12px;
		    height: 12px;
			}
		}
		input[value], input[value] + span {
			opacity: 1;
		}
		input[value=""], input[value=""] + span {
			opacity: 0;
		}
		input:focus, input:focus + span {
			opacity: 1;
		}
		.add-item-container {
			display: flex;
			span {
		    margin-top: auto;
		    margin-bottom: auto;
			}
		}
		input {
			width: 100%;
			opacity: 0;
			font-size: var(--font-size-m);
			border-radius: 10px;
			padding: 0 5px;
			margin: 5px 0;
		}
		input + span {
			opacity: 0;
			padding: 2px 5px;
		}
	}
	.remove-industry {
		position: relative;
		div {
			position: absolute;
			top: 10px;
			right: -30px;
			svg {
				width: 20px;
			}
		}
	}
`;


// rendered by App.js
// errors and successText handled
const UserSettingsModal = () => {
	const history = useHistory();
	const { dialog } = useContext(DialogContext);
	const {
		currentUser,
		userObject,
		newUserData,
		setNewUserData,
		setSettingsModalOpen,
		setLoginModalOpen,
		setErrorObj,
		handleSetSuccessText,
		handleSetMessage,
		offlineMode,
		updateUserData,
		canAcceptStripePayments,
		defaultUserData,
		isFetching,
		setIsFetching,
		setChangePasswordModalData,
		userIsAnonymous,
	} = useContext(UserContext);

	const autoCompleteAddressRef = useRef(null)
	const autoCompleteCityRef = useRef(null)

	const [addNewLocation, setAddNewLocation] = useState("")

	const [industriesFilters, setIndustriesFilters] = useState([])

	const usernameTaken = firebase.functions().httpsCallable("usernameTaken");


	const isSubcontractor = newUserData.roles && newUserData.roles.subContractor
	const isGeneralContractor = newUserData.roles && newUserData.roles.generalContractor

	const handleChange = (name, val) => {
		let newNewUserData = {...newUserData}

		if (name.includes(".")) {			
			const parentProperty = name.split(".")[0]
			const nestedProperty = name.split(".")[1]

			newNewUserData[parentProperty] = {
				...newNewUserData[parentProperty],
				[nestedProperty]: val
			}

		} else {
			newNewUserData[name] = val
		}

		if (name === "isCustomerOnly") {
			newNewUserData.roles = {
				generalContractor: false,
				subContractor: false,
			}
		}

		if (name === "roles.generalContractor" || name === "roles.subContractor") {
			if (val === true) {
				newNewUserData.isCustomerOnly = false
			}
		}

		setNewUserData(newNewUserData)

	};

	const checkUsernameAvailable = async (username, userUid) => {
		const usernameIsTaken = await usernameTaken({username}).then(({data}) => {
			if (data.errored || !data) {
				// if there was an error, make the users email as their username
				if (data.errorMessage) {
					setErrorObj({message: data.errorMessage})
				}
				return true
			} else if (data.takenBySelf) {
				return false
			} else {
				return data.taken
			}
		}).catch(err => {
			console.error(err)
			return true
		})

		return !usernameIsTaken
	};

	const handleIndustryEdit = (name, value, i) => {
		const newIndustries = newUserData.industries.map((ind, j) => {
			if (j === i) {
				return {
					...ind,
					[name]: value
				}
			} else return ind
		})
		setNewUserData((newUserData) => ({
			...newUserData,
			industries: newIndustries,
		}));
	};

	const handleSave = async ({
		e,
		userObject,
		newUserData,
	}) => {
		e.preventDefault();

		try {
			// handle invalid or not allowed usernames
			setIsFetching(true)
			let trimedUsername = newUserData.username.trim();

			if (
				trimedUsername === "current-user" ||
				trimedUsername === "null" ||
				trimedUsername === "undefined" ||
				trimedUsername.includes("@")
			) {
				const err = new Error();

				if (trimedUsername.includes("@")) {
					err.message += " (username can't include '@')";
				} else {
					err.message = "username not allowed";
				}
				err.noReport = true;
				throw err;
			}

			if (trimedUsername.includes(" ")) {
				const err = new Error();

				err.message = "Username cannot contain spaces";
				err.noReport = true;
				throw err;
			}

			// if (newUserData.username !== userData.username) {
			if (trimedUsername !== userObject.username) {
				const usernameAvailable = await checkUsernameAvailable(
					trimedUsername,
					currentUser.uid
				);
				if (!usernameAvailable) {
					const err = new Error();

					err.message = "Username is not available";
					err.noReport = true;
					throw err;
				}

				if (history.location.pathname.includes("/users")) {
					history.push(`/users/${trimedUsername}`);
				}
			}

			if (newUserData.email !== currentUser.email) {
				if (currentUser.email !== userObject.email) {
					console.log(
						"Problem with old email not being equal to current user email"
					);
				}
				let promptPassword = true;
				let cred;
				if (
					currentUser.providerData.find((obj) => obj.providerId === "password")
				) {
					promptPassword = await dialog.prompt("Changing your account email address requires re-authentication, please enter your password", "", {type: "password"});
					cred = firebase.auth.EmailAuthProvider.credential(
						currentUser.email,
						promptPassword
					);
					// re authenticate
					await currentUser.reauthenticateWithCredential(cred);
				} else {
					try {
						if (!userIsAnonymous) {
							await currentUser.updateEmail(newUserData.email);
						}
					} catch (err) {
						if (err.code === "auth/requires-recent-login") {
							return dialog.confirm("Changing your email requires re-authentication, click OK to continue").then(continueToReAuth => {
								if (continueToReAuth) {
									localStorage.setItem("newEmailAddress", newUserData.email);
									if (
										currentUser.providerData.find(
											(obj) => obj.providerId === "google.com"
										)
									) {
										return signInWithGoogle("changeEmailAddress");
									} else if (
										currentUser.providerData.find(
											(obj) => obj.providerId === "facebook.com"
										)
									) {
										return signInWithFacebook("changeEmailAddress");
									} else {
										err.message =
											"No such login provider, please contact support";
										err.code =
											"Provider-not-found delete-account AND auth/requires-recent-login";
									}
								}
							})
						} else {
							setErrorObj(err);
						}
					}
				}
				if (!promptPassword) {
					return false;
				}

				if (!userIsAnonymous) {
					// update the email used to sign in
					await currentUser.updateEmail(newUserData.email);
					// send email verification
					await currentUser.sendEmailVerification();
				}

			}

			let resOtherLocationsServed = []
			if (newUserData.otherLocationsServed && newUserData.otherLocationsServed.length) {
				resOtherLocationsServed = newUserData.otherLocationsServed
			} else {
				// in case the user forgets to click "add"
				if (addNewLocation) {
					resOtherLocationsServed = [addNewLocation]
				}
			}

			// remove white space from industries
			const cleanedIndustries = newUserData.industries.map(ind => {
				if (ind.name) {
					return {
						...ind,
						name: ind.name.trim(),
						rate: ind.rate ? parseFloat(getNum(ind.rate)) : ind.rate,
					}
				} else {
					return {
						...ind, 
						rate: ind.rate ? parseFloat(getNum(ind.rate)) : ind.rate, 
					}
				}
			})

			// update search keywords and other meta
			let updatedUserData = {
				...newUserData,
				industries: cleanedIndustries,
				username: trimedUsername,
				otherLocationsServed: resOtherLocationsServed,
			};

			// add in search keywords
			updatedUserData = {
				...updatedUserData,
				id: firebase.auth().currentUser.uid,
				searchKeywords: formulateSearchKeywords({
					doc: {
						...userObject,
						...newUserData,
						id: firebase.auth().currentUser.uid,
						contractorKeywords: getContractorKeywords(updatedUserData, isSubcontractor, isGeneralContractor),
						username: trimedUsername,
					},
					type: "user",
				}),
				contractorKeywords: getContractorKeywords(updatedUserData, isSubcontractor, isGeneralContractor)
			}

			await updateUserData(updatedUserData, false, "UserSettingsModal - handleSave");
			// on username change update stripe website url..
			// update stripe upon phone, email, name, company name and username
			const usernameHasChanged = trimedUsername !== userObject.username;
			const emailHasChanged = newUserData.email !== userObject.email;
			const companyEmailHasChanged =
				newUserData.companyEmail !== userObject.companyEmail;
			const phoneHasChanged = newUserData.phone !== userObject.phone;
			const companyNameHasChanged =
				newUserData.companyName !== userObject.companyName;

			// update the username in each of the users posts since it is used in the search results and is part of the link to the users profile
			if (usernameHasChanged) {
				const batch = firebase.firestore().batch()
				const allUserPosts = await firebase.firestore().collection("users").doc(currentUser.uid).collection("posts").get().then(snapshot => {
					return snapshot.docs
				})

				allUserPosts.forEach(post => {
					batch.update(post.ref, {username: trimedUsername})
				})

				// update marketPosts as well?
				await batch.commit()
			}

			setIsFetching(false)
			setSettingsModalOpen(false);
			if (
				canAcceptStripePayments &&
				(usernameHasChanged ||
					emailHasChanged ||
					companyEmailHasChanged ||
					phoneHasChanged ||
					companyNameHasChanged)
			) {
				handleSetMessage(
					<div className="align-center">
						<div>All changes saved!</div>
						<div className="small-line-break" />
						Note that if you want this new information to update in your stripe
						account, stripe requires you to do it yourself through your{" "}
						<a
							className="link-appearance white"
							target="_blank"
							rel="noopener noreferrer"
							href="https://dashboard.stripe.com"
						>
							Stripe.com Dashboard
						</a>
					</div>
				);
			} else {
				handleSetSuccessText("All changes saved!");
			}
		} catch (err) {
			setIsFetching(false)
			setErrorObj(err);
		}
	};

	const handleCancelChnages = (e) => {
		e && e.preventDefault();
		setNewUserData((newUserData) => ({
			...defaultUserData,
			...userObject,
		}));
		setSettingsModalOpen(false);
	};

	const handleClickOutside = () => {
		handleCancelChnages();
	};

	const handleRemoveIndustry = (i) => {
		let newIndustries = []
		// if ((userObject.hasEarlyAccess || userObject.directlyListed)) {
		if (userObject.id && newUserData.industries) {
			newUserData.industries.forEach((ind, j) => {
				if (j !== i) {
					newIndustries.push(ind)
				}
			})

			setNewUserData((newUserData) => ({
				...newUserData,
				industries: newIndustries,
			}));
		}
	};

	// 
	const getRelevantIndustries = (userInput, industries) => {
		let newIndustriesFilter = []
		const cleanUserInput = userInput ? userInput.toLowerCase() : ""
		if (cleanUserInput) {
			const reversedIndustries = industries.reverse()
			reversedIndustries.forEach(obj => {
				const title = obj.title.toLowerCase()
				const name = obj.name.toLowerCase()
				// direct hits
				if (name.startsWith(cleanUserInput) || title.startsWith(cleanUserInput)) {
					newIndustriesFilter = [
						obj,
						...newIndustriesFilter
					]

				// indirect hits
				} else if (name.includes(cleanUserInput) || title.includes(cleanUserInput)) {
					newIndustriesFilter = [
						...newIndustriesFilter,
						obj
					]
				}
			})
		} else return []

		if (newIndustriesFilter[0] && newIndustriesFilter[0].name === cleanUserInput) {
			return []
		} else {
			return newIndustriesFilter.slice(0,6)
		}
	}

	// add or remove a location served
	const addOrRemoveLocation = (value, action, e) => {
		e && e.preventDefault()
		const cleanedValue = value.trim()
		let newLocationsServed = []

		// if the location is already listed return
		if (action === "add") {
			if (newUserData.otherLocationsServed.includes(cleanedValue)) {
				return
			} else {
				newLocationsServed = [
					...newUserData.otherLocationsServed,
					cleanedValue
				]
			}
		} else {
			newLocationsServed = newUserData.otherLocationsServed.filter(l => l !== cleanedValue)
		}

		setNewUserData(newUserData => ({
			...newUserData,
			otherLocationsServed: newLocationsServed
		}))
	}


	// need to pass setNewUserData, otherwise it is outdated
	const handleChangeGeneralLocation = ({name, val, place, setNewUserData, autoCompleteAddressRef}) => {
		if (!place || !val) {
			console.log({autoCompleteAddressRef})
			let newNewUserData = {
				...newUserData,
				generalLocation: {lat: null, lng: null},
				private: {
					...newUserData.private,
					address: {
						...newUserData.private.address,
						lat: null,
						lng: null,
						addressString: val,
					}
				}
			}
			// return handleChange(name, val)
			return setNewUserData(newNewUserData)
		}

		// const place_id = place.place_id
		// const fullAddress = place.formatted_address
		const latlng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
		let radius = 1000 
		if (newUserData.private.address.locationPrivacy) {
			radius = parseFloat(newUserData.private.address.locationPrivacy) * 1000
		}

		const randomCoords = getRandomCoordinatesNearby({...latlng, radius})

		const { fullRegion, state, country } = getAddressDetails(place)
		let adminLevel2

		if (state && country) {
			const level2Name = findAdminLevel2([{...place}]) 
			if (level2Name) {
				adminLevel2 = level2Name + ", " + state + ", " + country
			} else { // defualt to fullRegion
				adminLevel2 = fullRegion
			}
		}

		setNewUserData(newUserData => ({
				...newUserData,
				private: {
					...newUserData.private,
					address: {
						...newUserData.private.address,
						lat: latlng.lat,
						lng: latlng.lng,
						addressString: val,
					}
				},
				generalLocation: randomCoords,
				fullCityString: fullRegion,
				adminLevel2,
			}))
	}


	const handleChangeCity = ({val, place, setNewUserData}) => {
		if (!place) {
			return setNewUserData(newUserData => ({...newUserData, fullCityString: val}))
		}

		let centerOfMainCityServed = {lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng()}
		// round
		if (centerOfMainCityServed.lat && centerOfMainCityServed.lng) {
			centerOfMainCityServed = {
				lat: Math.round(centerOfMainCityServed.lat * 100000) / 100000,
				lng: Math.round(centerOfMainCityServed.lng* 100000) / 100000,
			}
			
		}
		// if the user has selected an actual address, dont overwrite the random coordinates
		let randomCoords
		if (!newUserData.generalLocation || !newUserData.generalLocation.lat || !newUserData.generalLocation.lng) {
			const latlng = {lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng()}
			let radius = 1000 
			if (newUserData.private.address.locationPrivacy) {
				radius = parseFloat(newUserData.private.address.locationPrivacy) * 1000
			}

			randomCoords = getRandomCoordinatesNearby({...latlng, radius})

		}

		const { fullRegion, state, country } = getAddressDetails(place)
		let adminLevel2

		if (state && country) {
			const level2Name = findAdminLevel2([{...place}]) 
			if (level2Name) {
				adminLevel2 = level2Name + ", " + state + ", " + country
			} else { // defualt to fullRegion
				adminLevel2 = fullRegion
			}
		}

		setNewUserData(newUserData => ({
			...newUserData,
			...randomCoords ? {generalLocation: randomCoords} : {},
			fullCityString: fullRegion,
			adminLevel2,
			centerOfMainCityServed,
		}))
	}

	const handleLocationPrivacyChange = (value) => {
		let newNewUserData = {...newUserData}
		if (newUserData.private.address.lat && newUserData.private.address.lng) {
			const radius = parseFloat(value) * 1000
			newNewUserData.generalLocation = getRandomCoordinatesNearby({lat: newUserData.private.address.lat, lng: newUserData.private.address.lng, radius})
		}
		newNewUserData.private.address = {
			...newUserData.private.address,
			locationPrivacy: parseFloat(value)
		}

		setNewUserData(newNewUserData)
	}

	let inputs = [
		{
			label: "First Name",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "firstname",
				value: newUserData.firstname,
				required: true,
			},
			beforeInput: [
				<React.Fragment key="My Page">
					{
						userIsAnonymous ? null :
						<React.Fragment>
							<div className="section-title">My Page</div>
							<a
								style={{ lineBreak: "anywhere" }}
								href={`${window.location.origin}/users/${newUserData.username}`}
							>{`${window.location.origin}/users/${newUserData.username}`}</a>
							<div className="section-divider" />
							<div className="section-title">My Info</div>
						</React.Fragment>
					}
				</React.Fragment>,
			],
		},
		{
			label: "Last Name",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "lastname",
				value: newUserData.lastname,
			},
		},
		{
			label: "Account Email",
			onChange: handleChange,
			properties: {
				type: "email",
				id: "email",
				value: newUserData.email,
				required: true,
			},
		},
		{
			label: "Phone",
			onChange: handleChange,
			properties: {
				type: "tel",
				id: "phone",
				value: newUserData.phone,
			},
		},
		{
			label: "Username",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "username",
				value: newUserData.username,
				required: true,
			},
			afterInput: [
				<React.Fragment key="changePassword">
					{firebase.auth().currentUser.providerData.find((obj) => obj.providerId === "password") && (
						<React.Fragment>
							<div className="small-line-break" />
							<div
								className="link-appearance small blue"
								onClick={() => {
									const email = firebase.auth().currentUser.email

									firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
										if (methods[0] === 'emailLink' && methods.length === 1) {
											setChangePasswordModalData(changePasswordModalData => ({
												...changePasswordModalData,
												open: true,
												creatingNew: true,
												email: email,
											}))
							  		} else {
											setChangePasswordModalData(changePasswordModalData => ({
												...changePasswordModalData,
												open: true,
												creatingNew: false,
												email: email,
											}))

							  		}
									})

									// if (isChangingPassword) {
									// 	setNewPasswordText({
									// 		oldPassword: "",
									// 		password: "",
									// 		confirmPassword: "",
									// 	});
									// }
									// setIsChangingPassword(!isChangingPassword);
								}}
							>Change password
								{
									// isChangingPassword
									// ? "Cancel change password"
									// : "Change password"
								}
							</div>
							{/*<div className="small">
								<div className="small-line-break" />
								<span>Forgot Password?</span>&nbsp;
								<span
									className="link-appearance blue"
									onClick={handleSendPasswordReset}
								>
									Send password reset link
								</span>
							</div>*/}
						</React.Fragment>
					)}
				</React.Fragment>,
			],
		},
		{
			label: "Users can search for me",
			onChange: handleChange,
			beforeInput: [
				<React.Fragment key="privacy">
					{
						// <SectionTitle key="privacy" text="Privacy" />
					}
					<div className="section-divider" />
					<div className="section-title">Privacy</div>
				</React.Fragment>,
			],
			properties: {
				type: "checkbox",
				id: "searchable",
				value: newUserData.searchable ? false : true,
				checked: newUserData.searchable ? true : false,
			},
			afterInput: [
				<React.Fragment key="afterPrivacy">
					<div>
						<span
							className="link-appearance blue"
							onClick={(e) => {
								handleDeleteAccount(e, offlineMode, false, false, dialog)
								.catch((err) => setErrorObj(err));
							}}
						>
							Delete Account
						</span>
					</div>
				</React.Fragment>,
			],
		},
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "Preferences",
				onChange: () => null,
			},
			beforeInput: [
				<div key="Preferences">
					<div className="section-divider" />
					<div className="section-title">Preferences</div>
					<div>
						<Link onClick={() => handleClickOutside()} to="/unsubscribe">Email preferences</Link>
					</div>
				</div>
			]
		},
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "account-type",
				onChange: () => null,
			},
			beforeInput: [
				<div key="account-type">
					<div className="section-divider" />
					<div className="section-title">
						Account Type <span className="info-tag">{(isGeneralContractor || isSubcontractor) ? "Contractor" : "Customer"}</span>
					</div>
				</div>
			]
		},
		{
			visible: true,
			label: "Customer Only (I do not offer any services)",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "isCustomerOnly",
				value: !newUserData.isCustomerOnly,
				checked: newUserData.isCustomerOnly,
			},
		},
		{
			visible: true,
			label: "General Contractor",			
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "roles.generalContractor",
				value: !isGeneralContractor,
				checked: isGeneralContractor,
				// disabled: newUserData.isCustomerOnly,
			},
			beforeInput: [
				<div key="generalContractor-lb" className="small-line-break" />
			],
			moreInfoIcon: {
				before: " ",
				absolute: true,
				text: "Your company hires sub-contractors"
			}
		},
		{
			visible: true,
			label: "Sub-contractor",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "roles.subContractor",
				value: !isSubcontractor,
				checked: isSubcontractor,
			},
		},
		{
			visible: !newUserData.isCustomerOnly,
			properties: {
				type: "hidden",
				id: "company-info",
				onChange: () => null,
			},
			beforeInput: [
				<div key="company-info">
					<div className="section-divider" />
					<div className="section-title">
						Company Info 
					</div>
				</div>
			]
		},
		{
			visible: !newUserData.isCustomerOnly,
			label: "Company Name",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "companyName",
				value: newUserData.companyName || "",
			},
		},
		{
			visible: !newUserData.isCustomerOnly,
			label: "Company Email (public)",
			onChange: handleChange,
			properties: {
				type: "email",
				id: "companyEmail",
				value: newUserData.companyEmail,
			},
		},
		{
			visible: isSubcontractor || isGeneralContractor,
			label: "GST #",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "gstNumber",
				value: newUserData.gstNumber,
			},
		},
		{
			label: "",
			properties: {
				type: "hidden",
				id: "removeAutoCompleteUFAHidden-input"
			},
			afterInput: [
				<div key="private-address">
					<div className="small-line-break" />
					<div >
						<span id="label-removeAutoCompleteUFA" /*className="small-line-break spacer-for-label"*/ />
						{
							parseFloat(newUserData.private.address.locationPrivacy) > 0 ?
							<span>
								<MoreInfoIcon 
									customIcon=<span className="info-tag green white">Private </span>
									absolute={true}
									text={`Address is private. On a map, users will only be shown a random general location within ${newUserData.private.address.locationPrivacy} km of this address`}
								/>
							</span>
							: 
							<span>
								<MoreInfoIcon 
									customIcon=<span className="info-tag">Public </span>
									absolute={true}
									text={`This address can be shown on a map as your business location`}
								/>
							</span>
						}
					</div>
					{/*put a blank input to satisfy autofill off for chrome*/}
					<input type="address" autoComplete="address" style={{opacity: "0", height: "0", position: "absolute"}} />

					<div >
						<InputDiv key={newUserData} inputTypeTextFamily={true} hasVal={true}>
								<div className="input-container" >
								<Autocomplete
									key={newUserData}
								  apiKey={process.env.GOOGLE_PLACES_API_KEY}
								  onPlaceSelected={(place, target) => handleChangeGeneralLocation({name: "removeAutoCompleteUFAHidden", val: target.value, place, setNewUserData})}
								  onChange={(e) => handleChangeGeneralLocation({name: "removeAutoCompleteUFAHidden", val: e.target.value, place: null, setNewUserData, autoCompleteAddressRef})}
									type="search"
									name="removeAutoCompleteUFAHidden"
									id="removeAutoCompleteUFAHidden"
									ref={autoCompleteAddressRef}
									placeholder="Start typing"
									value={newUserData.private.address.addressString}
									options={{
										componentRestrictions: { country: ["ca"] },
										fields: ["geometry.location", "address_components"],
										types: ["address"],
									}}
								/>
							</div>
						</InputDiv>
					</div>
					<div className="section-divider" />
				</div>
			],
		},
		{
			label: "",
			onChange: (name, val) => handleLocationPrivacyChange(val),
			beforeInput: [
				<div key="privacy-level-label">{"Privacy level "}
					<MoreInfoIcon 
						customIcon=<span className={`info-tag ${parseFloat(newUserData.private.address.locationPrivacy) > 0.1 ? "green white" : ""}`} >{newUserData.private.address.locationPrivacy > 0 ? newUserData.private.address.locationPrivacy + " km range" : "none"}</span>
						absolute={true}
						text={`Random location coordinates within this range will be shown as your address`}
					/>
				</div>
			],
			properties: {
				type: "range",
				id: "private.address.locationPrivacy",
				name: "private.address.locationPrivacy",
				value: newUserData.private.address.locationPrivacy,
				min: 0,
				max: 2,
				step: 0.05,
			}
		},
		
		{
			visible: isSubcontractor || isGeneralContractor,
			properties: {
				type: "hidden",
				id: "locations-address-title",
				onChange: () => null,
			},
			beforeInput: [
				<div key="locations-address-title" className="section-title">
					<div className="section-divider" />
					Locations Served
				</div>
			],
		},

		{
				visible: isSubcontractor || isGeneralContractor,
				label: "",
				properties: {
					type: "hidden",
					required: true,
				},
				afterInput: [
					<React.Fragment key="city">
						<InputDiv inputTypeTextFamily={true} hasVal={true}>
							<div className="input-container" >
								<Autocomplete
								  apiKey={process.env.GOOGLE_PLACES_API_KEY}
								  onPlaceSelected={(place, target) => handleChangeCity({ val: target.value, place, setNewUserData })}
								  onChange={(e) => handleChangeCity({ val: e.target.value, place: null, setNewUserData })}
									type="search"
									id="removeAutoCompleteFCS"
									ref={autoCompleteCityRef}
									placeholder="Start typing your city"
									value={newUserData.fullCityString}
									required={true}
									options={{
										componentRestrictions: { country: ["ca"] },
										fields: ["geometry.location", "address_components"/*, "place_id"*/],
										types: ["(cities)"],
									}}
								/>
							<span>
								<label htmlFor="removeAutoCompleteFCS" >{"Main:"}
								</label>
								<MoreInfoIcon svgClassname="absolute-info-icon" absolute={true} text="The main city you work in" />
							</span>
						</div>
					</InputDiv>
					<div className="small-line-break" />
					<div className="specialties-wrapper">
						<div className="small-line-break" />
						<div>
						{
							newUserData.otherLocationsServed.map((place, i) => {
								return (
									<span key={place} className="specialty">{place}
										<svg
											onClick={() => {
												addOrRemoveLocation(place, "remove")
											}}
											viewBox="0 0 32 32" 
											fill="none" stroke="currentcolor" strokeLinecap="round" 
											strokeLinejoin="round" strokeWidth="3"
										>
									    <path d="M2 30 L30 2 M30 30 L2 2" />
								    </svg>
									</span>
								)
							})
						}
						</div>
						<span>Add new Location </span>

						<label htmlFor={`removeAutoCompleteNewLoc`}>
							<AddCircleButton 
								height="10px" width="10px"
							/>
						</label>
						<div className="add-item-container">
								<Autocomplete
								  apiKey={process.env.GOOGLE_PLACES_API_KEY}
								  // must have a key here otherwise will overwrite otherLocationsServed
								  key={newUserData.otherLocationsServed}
								  onPlaceSelected={(place, target) => {
										const { fullRegion } = getAddressDetails(place)

								  	addOrRemoveLocation(fullRegion, "add")
										setAddNewLocation("")
								  }}
								  onChange={(e) => {
								  	setAddNewLocation(e.target.value)
								  }}
									type="search"
									name= "removeAutoCompleteNewLoc"
									id= "removeAutoCompleteNewLoc"
									value={addNewLocation}
									placeholder= "Start typing"
									// onKeyPress= {(e) => {
									// 	if (e.key === "Enter") {
									// 		addOrRemoveLocation(addNewLocation, "add", e, newUserData ,setNewUserData)
									// 		setAddNewLocation("")
									// 	}
									// }}
									options={{
										componentRestrictions: { country: ["ca"] },
										fields: [/*"geometry.location", */"address_components"],
										types: ["(cities)"],
									}}
								/>
								<span 
									className="link-appearance tiny"
									onClick={(e) => {
										if (addNewLocation) {
											addOrRemoveLocation(addNewLocation, "add")
											setAddNewLocation("")
										}
									}}
								>Add</span>
						</div>
					</div>
					<div className="small-line-break" />
				</React.Fragment>
			],
		},
		{
			visible: isSubcontractor || isGeneralContractor,
			properties: {
				type: "hidden",
				id: "Services",
				onChange: () => null,
			},
			beforeInput: [
				<div key="My Services">
					<div className="section-divider" />
					<div className="section-title">
						{"My Services "}
						<AddCircleButton 
							onClick={(e) => {
								e.preventDefault();
								if (userObject.id) {
									setNewUserData((newUserData) => ({
										...newUserData,
										industries: [
											{ name: "", rate: "" },
											...newUserData.industries,
										],
									}));
								}
							}}
						/><span className="tiny lightgray"> Add</span>
					</div>
					{!newUserData.industries.length && (
						<React.Fragment>
							<div className="small-line-break" />
						</React.Fragment>
					)}
				</div>
			]
		},
	];

	// useEffect(() => {
	//   if (userIsAnonymous) {
	//   	handleSetMessage(
	//   		<div>
	// 	  		<div>
	// 		  		Note: you are using a demo account! Any changes you make may not be saved and this account and all it's data will be deleted in 30 days
	// 	  		</div>
	// 	  		<button onClick={() => setLoginModalOpen(true)} className="button-appearance tiny blue">Log in/Sign up</button>
	// 	  		{" to change this"}
	//   		</div>
 //  		)
	//   }
	// }, [])

	useEffect(() => {
	  if (newUserData.industries && newUserData.industries.length) {
	  	let newIndustriesFilters = []
	  	newUserData.industries.forEach(ind => {
	  		if (ind.name) {
		  		const relevantIndustries = getRelevantIndustries(ind.name, industries)
		  		newIndustriesFilters.push(relevantIndustries)
	  		}
	  	})

	  	setIndustriesFilters(newIndustriesFilters)
	  } else {
	  	setNewUserData((newUserData) => ({
				...newUserData,
				industries: [
					{ name: "", rate: "" },
				]
			}))

	  	setIndustriesFilters([])
	  }
	  // disable because would cause infinite loop but should change to only set new user data if it has changed
	  // eslint-disable-next-line
	}, [newUserData.industries])

	// handle industries inputs
	if (isSubcontractor || isGeneralContractor) {
		for (let i = 0; i < newUserData.industries.length; i++) {
			const ind = newUserData.industries[i];
			const handleAddSpecialty = (e) => {
				e && e.preventDefault()
				let newSpecialties = [] 
				let newSpecialty = ""
				const previousElementSibling = e.target.previousElementSibling

				if (previousElementSibling) {
					newSpecialty = previousElementSibling.value ? previousElementSibling.value.trim() : ""
				}
				if (!newSpecialty || (ind.specialties && ind.specialties.includes(newSpecialty))) {
					return
				} else {
					if (ind.specialties && ind.specialties.length) {
						newSpecialties = [
							...ind.specialties,
						]
					}

					newSpecialties.push(newSpecialty)

					handleIndustryEdit("specialties", newSpecialties, i)
					previousElementSibling.value = ""
				}
			}

			let newInputs = [
				{
					beforeInput: [
						<div key={`remove-industry-${i}`} className="remove-industry">
						{
							<div onClick={() => handleRemoveIndustry(i)}>
								<svg
									viewBox="0 0 32 32"
									fill="none"
									stroke="currentcolor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
									<path d="M28 6 L6 6 8 30 24 30 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
								</svg>
							</div>
						}
						</div>,
					],
					label: "Industry",
					onChange: (name, val) => handleIndustryEdit("name", val, i),
					containerClass: "section-input top",
					properties: {
						type: "text",
						id: `${i}-name`,
						value: ind.name,
						autoComplete: "off",
						required: ind.rate || (ind.specialties && ind.specialties.length),
					},
					afterInput: [
						<div key={i} 
							style={{
								position: "relative"
							}}
						>
							<div className="pac-container pac-logo" 
								style={{
							    display: (industriesFilters[i] && industriesFilters[i].length) ? "block" : "none",
							    width: "100%",
							    top: "0",
								}}
							>
								{
									(industriesFilters[i] && industriesFilters[i]) ?  industriesFilters[i].map((obj) => {
										return (
											<div key={obj.name} className="pac-item" onClick={() => handleIndustryEdit("name", capitalizeFirstLetter(obj.name), i)} >
												<span className="material-icons pac-icon pac-icon-marker industries" >{obj.icon}</span>
												<span className="pac-item-query" >{capitalizeFirstLetter(obj.name)}</span>
												<span>{capitalizeFirstLetter(obj.title)}</span>
											</div>
										)
									})
									: ""
								}
							</div>
						</div>
					]
				},
				{
					label: "Rate",
					onChange: (name, val) => handleIndustryEdit("rate", val, i),
					containerClass: "section-input middle",
					properties: {
						type: "number",
						id: `${i}-rate`,
						value: ind.rate,
					},
					afterInput: [
						<div key="specialties" className="section-input bottom specialties-wrapper">
							<div className="small-line-break" />
							<div>Specialties</div>
							{
								ind.specialties ? ind.specialties.map(sp => {
									return (
										<span key={sp} className="specialty">{sp}
											<svg
												onClick={() => {
													handleIndustryEdit("specialties", ind.specialties.filter(s => s !== sp), i)
												}}
												viewBox="0 0 32 32" 
												fill="none" stroke="currentcolor" strokeLinecap="round" 
												strokeLinejoin="round" strokeWidth="3"
											>
										    <path d="M2 30 L30 2 M30 30 L2 2" />
									    </svg>
										</span>
									)
								})
								: ""
							}
							{
								(ind.specialties && ind.specialties.length) ?
								<div className="small-line-break" />
								: ""
							}
							<label htmlFor={`${i}-specialties`}>
								<AddCircleButton 
									height="10px" width="10px"
								/>
								{" "}
							</label>
							<div className="add-item-container">
								<input 
									type="text" 
									id={`${i}-specialties`}
									placeholder="eg: hardwood flooring"
									autoComplete="off"
									onChange={e => e.target.setAttribute("value", e.target.value)}
									// cant use onKeypress because it submits the form and preventDefault looses e.target
									onKeyPress={(e) => {
											if (e.key === "Enter") {
												handleAddSpecialty(e)
											}
										}
									}
								/>
								<span 
									className="link-appearance tiny"
									onClick={handleAddSpecialty} 
								>Add</span>
							</div>
							<div className="small-line-break" />
						</div>
					]
				},
			];

			inputs.push(...newInputs);
		}
	}

	// make the sign up link for an anonymous user
	let upgradeAnonymousAccountLink = "?action=promtpLogin&signup=true"

	if (userObject.id && newUserData.email) {
		// add in newUserData to the link in case the user has just edited their data
		let params =  {name: "", email: "", phone: "", username: ""}
		params.name = newUserData.name || (newUserData.firstname + " " + newUserData.lastname).trim()
		if (!newUserData.email.includes("sample")) {
			params.email = newUserData.email
		}
		if (newUserData.phone) {
			params.phone = newUserData.phone
		}
		if (newUserData.username && newUserData.username !== userObject.username) {
			params.username = newUserData.username
		}

		for (let key in params) {
			if (params[key]) {
				upgradeAnonymousAccountLink += `&${key}=${params[key]}`
			}
		}
	}

	const accountCreationMS = parseInt((currentUser && currentUser.metadata) ? currentUser.metadata.createdAt : Date.now())
	const dateAccountExpiresIfAnon = new Date(accountCreationMS + (30 * msInADay))
	const timeTilAccountDeletedIfAnon = getTimeRemaining(dateAccountExpiresIfAnon)

	return (
		<Modal
			custom={{ absolute: true, style: `z-index: 101;` }}
			onClickOutside={handleClickOutside}
		>
			{isFetching && <Spinner position="fixed" />}
			<UserSettingsModalStyle>
				{currentUser ? (
					<div>
						<Form
							inputs={inputs}
							heading={
								<React.Fragment>
									<div className="heading">Settings</div>
									<AppMessage dependants={[newUserData]} />
									{
										userIsAnonymous ? 
										<div>
								  		Note: you are using a demo account! Any changes you make may not be saved and this account and all it's data will be <span className="error-text">deleted in {Math.floor(timeTilAccountDeletedIfAnon.days)} days!</span>
								  		<div className="small-line-break" />
								  		<Link 
									  		to={upgradeAnonymousAccountLink} 
									  		onClick={() => {
									  			setLoginModalOpen(true)
									  			handleClickOutside()
									  		}} 
								  		className="button-appearance tiny blue no-icon-or-underline">
									  		Sign up
								  		</Link>
								  		{" to change this"}
							  		</div>
							  		: null
									}
									{
										// userIsAnonymous ?
										// <div className="demo-account">
										// 	<h4 className="section-title">You are using a demo account</h4>
										// 	<button className="button-appearance blue no-icon-or-underline" >Sign up/Log In</button>
										// </div>
										// : null
									}
								</React.Fragment>
							}
							// submitName="Save"
							onSubmit={(e) => {
								e.preventDefault()
								handleSave({
									e,
									userObject,
									newUserData,
								})
							}}
						>
							<div className="section-divider" />
							<div className="align-center submit-actions">
								<button className="button-appearance" >
									Save
								</button>
								<button className="button-appearance gray" onClick={handleCancelChnages} >
									Cancel
								</button>
							</div>
						</Form>
					</div>
				) : (
					<React.Fragment>
						<div className="heading">Settings</div>
						<div
							className="link-appearance blue"
							onClick={() => {
								setSettingsModalOpen(false);
								setLoginModalOpen(true);
							}}
						>
							Log in or Sign up
						</div>
					</React.Fragment>
				)}
			</UserSettingsModalStyle>
		</Modal>
	);
};

export default UserSettingsModal;
