import React from 'react'
import styled, { keyframes } from 'styled-components'

//for spinner
const rotate360 = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }

`

export const SpinnerStyle = styled.div`
  position: ${({position}) => position ? position : "fixed"};
  top: 10%;
  right: 0;
  left: 0;
  width: ${({position}) => position ? "100%" : "inherit"};
  display: flex;
  justify-content: center;
  z-index: 5;

  div {
    animation: ${rotate360} 1.5s linear infinite;
    transform: translateZ(0);
    
    /*border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px transparent;*/
    box-shadow: 4px 4px 3px 0px grey;
    background: transparent;
    width: ${({width}) => width ? width : "10vw"};
    height: ${({height}) => height ? height : "10vw"};
    border-radius: 50%;
  }

`

const Spinner = ({position, width, height}) => {
  // let resDependants = []
  // if (dependants) {
  //   resDependants = dependants
  // }
  // useEffect(() => {
    // eslint-disable-next-line
  // }, [])

  return (
    <SpinnerStyle className="spinner" position={position} width={width} height={height} >
      <div />
    </SpinnerStyle>
  )
}


export default Spinner