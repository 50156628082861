import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { EditInvoiceContext } from "../../../contexts/EditInvoiceContext";
import { UserContext } from "../../../contexts/UserContext";

import Modal from "../../Modal/Modal";
import { AppMessage } from "../../MessageUtils";
import { sendAddPaymentNotification } from "../invoiceUtils";
import { getNum, capitalizeFirstLetter } from "../../../utils/appUtils";
import { getLocalISODate } from "../../../utils/dateUtils";
import { StripeLineItems } from "../../stripeComponents/StripeFormStyles.styled";
import { loadStripe } from "@stripe/stripe-js";
import MoreInfoIcon from "../../MoreInfoIcon";

import SmallSpinner from "../../stripeComponents/SmallSpinner";

import firebase from "../../../firebase/index";

// rendered by specificInvoice.js
const AddInvoicePaymentModal = ({ stripePublicKey }) => {
	const { editedInv, addPaymentModal, setAddPaymentModal, invoiceOwed } =
		useContext(EditInvoiceContext);

	const {
		currentUser,
		userObject,
		setErrorObj,
		handleSetSuccessText,
		setIsFetching,
	} = useContext(UserContext);

	let contractorPaymentMethods = editedInv.paymentMethods;

	if (!contractorPaymentMethods) {
		contractorPaymentMethods = {
			cash: {
				accepted: true,
				discount: "",
			},
			etransfer: {
				accepted: false,
				discount: "",
				email: "",
			},
			cheque: {
				accepted: false,
				discount: "",
				payableTo: "",
			},
			stripe: {
				accepted: false,
			},
		};
	}

	// default adding can be passed in through url query params in SpecificInvoice.js
	let defaultAdding = addPaymentModal.defaultAdding;

	if (!defaultAdding) {
		defaultAdding = parseFloat(invoiceOwed) < 0 ? 0 : invoiceOwed;
	} else {
		defaultAdding = parseFloat(defaultAdding);
	}

	const processingFeePercent = 0
	let defaultFee = defaultAdding * processingFeePercent
	let defaultDiscount = 0;
	const defaultTotal = defaultAdding;

	let defaultPaymentMethod = "";
	// if contractor selects "charge processing fee to customer for non cash payments"
	// if (editedInv.processingFee) {
	// 	defaultFee =
	// 		defaultAdding * (parseFloat(editedInv.processingFee || 0) / 100);
	// }

	if (contractorPaymentMethods) {
		if (contractorPaymentMethods.stripe.accepted) {
			defaultPaymentMethod = "stripe";
		} else if (contractorPaymentMethods.cash.accepted) {
			defaultPaymentMethod = "cash";
		}
	}

	defaultDiscount =
		(parseFloat(contractorPaymentMethods[defaultPaymentMethod].discount || 0) /
			100) *
		defaultTotal;

	// const [canUsePaymentRequestButtonElement, setCanUsePaymentRequestButtonElement] = useState(false);
	const [formData, setFormData] = useState({
		country: "CA",
		currency: "cad",
		total: getNum(defaultTotal, 2),
		fee: getNum(defaultFee, 2),
		discount: getNum(defaultDiscount, 2),
		adding: getNum(defaultAdding, 2),
		paymentMethod: addPaymentModal.paymentMethod || defaultPaymentMethod,
		transactionType: "personal",
		// customerPaysFees: false,
	});
	const [processing, setProcessing] = useState("");

	const onClickOutside = () => {
		setAddPaymentModal((addPaymentModal) => ({
			...addPaymentModal,
			open: false,
		}));
	};

	const getNewTotals = (newFormData) => {
		let adding = parseFloat(newFormData.adding);
		let newTotal;
		// let newFee = parseFloat(editedInv.processingFee || 0) * adding;
		let newFee = processingFeePercent * adding;
		const discountPercent =
			parseFloat(
				contractorPaymentMethods[newFormData.paymentMethod].discount || 0
			) / 100;

		newTotal = adding + newFee;
		const newDiscount = discountPercent * newTotal;
		newTotal = newTotal - newDiscount;

			// let invoiceAmountAdded = newTotal
   //    let checkoutTotal = parseFloat(newTotal)
   //    let contractorGets = parseFloat(newTotal)
   //    let applicationFee = Math.round( // in cents
   //      0.01 * parseFloat(newTotal)
   //    ); /** parseFloat(qty)*/
   //    let resultFee = applicationFee
   //    let initialStripeFees = (checkoutTotal * 0.029) + 0.30
   //    let newStripeFees = 0

      // if (newFormData.customerPaysFees) {

      // 	// make the application fee the difference of the total payment minus amount adding to invoice  minus stripe fees
      //   checkoutTotal = checkoutTotal + initialStripeFees
      //   // add in application fee
      //   applicationFee = checkoutTotal * 0.01
      //   checkoutTotal = checkoutTotal + applicationFee

      //   // add in stripe fees
      //   newStripeFees = (checkoutTotal * 0.029) + 0.30
      //   // checkoutTotal = checkoutTotal
      //   resultFee = (checkoutTotal - newStripeFees) - invoiceAmountAdded

      //   contractorGets = invoiceAmountAdded
      //   checkoutTotal = Math.round(checkoutTotal)
      //   console.log("result fee if giving contractor full: ", resultFee)
      // } else {
      // 	// checkoutTotal = checkoutTotal
	     //  contractorGets = (checkoutTotal - initialStripeFees) - applicationFee
      // }

      // console.log({invoiceAmountAdded, checkoutTotal, contractorGets, applicationFee})

		return {
			...newFormData,
			fee: newFee,
			discount: newDiscount,
			total: newTotal,
		};
	};

	// const pretendCalculate = () => {
	// 	setNumberCalculating(true)
	// 	if (calculatingTimeout) {
	// 		clearTimeout(calculatingTimeout)
	// 	}

	// 	setCalculatingTimeout(setTimeout(() => {
	// 		setNumberCalculating(false)
	// 	}, 1000))
	// }

	const handleChangePayment = (name, value) => {
		setFormData((formData) => ({
			...formData,
			...getNewTotals({ ...formData, [name]: value }),
		}));
	};

	const createCheckoutSession = async (excludeCustomerId) => {
		// Call your backend to create the Checkout session.
		try {
			setProcessing(true);
			const fetchCheckoutSession = firebase
				.functions()
				.httpsCallable("fetchCheckoutSession");
			const amountInCents = parseInt(parseFloat(formData.total) * 100);

			let userCustomerId = "";
			if (
				!excludeCustomerId && // if were including the customer id
				userObject.private &&
				userObject.private.stripe &&
				userObject.private.stripe.customer
			) {
				// if the private stripe data customer field is only customer: "customerId"
				// instead of the full customer object
				if (typeof userObject.private.stripe.customer === "string") {
					userCustomerId = userObject.private.stripe.customer;
				} else {
					userCustomerId = userObject.private.stripe.customer.id || "";
				}
			}

			let inputData = {
				// payment intent object
				invoiceId: editedInv.id,
				amountInCents,
				country: formData.country,
				currency: formData.currency,
				// currency: "eur",
				success_url:
					window.location.href + "?paymentSuccess=true&paid=" + amountInCents,
				cancel_url: window.location.href + "?paymentSuccess=false",
				...(userCustomerId && { customer: userCustomerId }),
				// only uses customer email if !customer
				useCustomerEmail: true,
				useCustomerField: true,
				customerNeedsUpdating: false,
				receiverUid: editedInv.contractor.id,
				payerFirstname: userObject.firstname,
				payerLastname: userObject.lastname,
				payerUsername: userObject.username,
				transactionType: formData.transactionType,
				// not: cant use objects in metadata .... ?
				// customer,
				// ... see docs https://stripe.com/docs/api/checkout/sessions/create
			};

			const { data } = await fetchCheckoutSession({ ...inputData });
			sessionStorage.setItem("lastCheckoutSessionId", data.id);
			// When the customer clicks on the button, redirect them to Checkout.
			const stripe = await loadStripe(stripePublicKey, {
				stripeAccount: data.stripeAccount,
			});
			const result = await stripe.redirectToCheckout({
				sessionId: data.id,
			});
			// redirect ... no code works beyond this point ... unless error?

			if (result.error) {
				setProcessing(false);
				if (result.message) {
					setErrorObj({ ...result });
				} else {
					setErrorObj({
						message: "an unknown error with stripe checkout has occurred",
						...result,
					});
				}
				// If `redirectToCheckout` fails due to a browser or network
				// error, display the localized error message to your customer
				// using `result.error.message`.
			}
		} catch (err) {
			if (err.message.includes("No such customer")) {
				handleSubmit(null, true);
			} else {
				setProcessing(false);
				err.message = err.message || "Payment failed"
				setErrorObj(err);
			}
		}
	};

	const handleAddNonCreditPayment = async () => {
		try {
			setProcessing(true);
			const amountInCents = parseInt(parseFloat(formData.total) * 100);

			// const timestamp = Math.round(Date.now() / 1000);
			const timestamp = firebase.firestore.Timestamp.now().seconds;
			let paymentName = "Cash";
			if (formData.paymentMethod === "etransfer") {
				paymentName = "E-transfer";
			}
			if (formData.paymentMethod === "cheque") {
				paymentName = "Cheque";
			}

			const firebaseRef = firebase
				.firestore()
				.collection("invoices")
				.doc(editedInv.id)
				.collection("payments")
				.doc();

			const newPaymentData = {
				amount: amountInCents, // the owner cannot change this field
				amountApplied: amountInCents,
				amountReceived: 0,
				created: timestamp, // the owner cannot change this field
				receivedDate: getLocalISODate(false),
				paymentName,
				status: "needs_confirmation",
				type: formData.paymentMethod,
				payer: userObject.id, // the owner cannot change this field
				payerUsername: userObject.username,
				id: firebaseRef.id,
				receiver: editedInv.contractor.id,
			};

			await firebaseRef.set(newPaymentData);

			let newBillTo;

			if (editedInv.billTo && editedInv.billTo.uid) {
				// dont overwrite the billTo if the contractor is adding their own payment
				if (userObject && newPaymentData.payer !== newPaymentData.receiver) {
					newBillTo = {
						...editedInv.billTo,
						...(userObject.firstname && { firstname: userObject.firstname }),
						...(userObject.lastname && { lastname: userObject.lastname }),
						// ...(userObject.private.address && { address: userObject.private.address }),
						// ...(userObject.private.address.addressString && {
						// 	userFullAddress: userObject.private.address.addressString,
						// }),
						username: userObject.username,
						uid: userObject.id,
					};
				} else {
					newBillTo = null;
				}
			} else {
				newBillTo = {
					firstname: userObject.firstname || "",
					lastname: userObject.lastname || "",
					// address: userObject.private.address || "",
					// userFullAddress: userObject.private.address.addressString || "",
					username: userObject.username || "",
					uid: userObject.id || "",
					showAddress: editedInv.billTo ? (editedInv.billTo.showAddress || false) : false,
				};
			}

			const notificationAdditional = {
				paymentData: newPaymentData,
				...(newBillTo && { billTo: newBillTo }),
			};

			await sendAddPaymentNotification(
				"invoices",
				notificationAdditional,
				editedInv
			);

			handleSetSuccessText(
				"Payment added and is awaiting the contractors approval",
				5000
			);
			setTimeout(() => {
				setIsFetching(true);
				setProcessing(false);
				onClickOutside();
				handleSetSuccessText("Reloading Invoice...");
				document.getElementById("top-of-page").scrollIntoView(false);
			}, 3000);
			setTimeout(() => {
				window.location.reload();
			}, 5000);
		} catch (err) {
			setProcessing(false);
			setIsFetching(false);
			setErrorObj(err);
		}
	};

	const handleSubmit = (e, excludeCustomerId) => {
		e && e.preventDefault();

		if (parseInt(formData.adding) === 0) {
			setErrorObj({
				message: "Please enter a value greater than zero",
				noReport: true,
			});
			return false;
		}
		if (formData.paymentMethod === "stripe") {
			return createCheckoutSession(excludeCustomerId);
		} else {
			return handleAddNonCreditPayment();
		}
	};

	const noPaymentTypesAccepted =
		!contractorPaymentMethods.cash.accepted &&
		!contractorPaymentMethods.etransfer.accepted &&
		!contractorPaymentMethods.cheque.accepted &&
		!contractorPaymentMethods.stripe.accepted;


	useEffect(() => {
	  if (addPaymentModal.checkoutAsGuest) {
	  	handleSubmit(null, true)
	  }
	  // handlesubmit changes on every render
	  // eslint-disable-next-line
	}, [addPaymentModal])

	return (
		<Modal custom={{ absolute: true }} onClickOutside={onClickOutside}>
			<div className="heading">Add Payment</div>
			<AppMessage />
			<form id="payment-form" onSubmit={(e) => handleSubmit(e)}>
				<StripeLineItems>
					{Object.keys(contractorPaymentMethods).map((key) => {
						const paymentMethod = contractorPaymentMethods[key];
						return (
							<div key={key}>
								<input
									type="radio"
									name="paymentMethod"
									id={key + " payment"}
									value={key}
									checked={formData.paymentMethod === key}
									onChange={(e) =>
										handleChangePayment(e.target.name, e.target.value)
									}
									disabled={!paymentMethod.accepted}
									required={true}
								/>
								<label htmlFor={key + " payment"}>
									{key === "stripe"
										? "Credit Card/Debit"
										: capitalizeFirstLetter(key)}
								</label>
								{
									(key !== "stripe" && !currentUser) ?
									<React.Fragment>
										<MoreInfoIcon absolute={true} customIcon={
											<span className="info-tag">🔒 log in</span> 
										} text="You will need to log in or sign up to be able verify this type of payment" />
										&nbsp;
									</React.Fragment>
									: ""
								}
								{/*<div className="tiny">
									{
										// if stripe selected and the contractor accepts debit and card payments
										formData.paymentMethod === "stripe" &&
											key === "stripe" &&
											editedInv.paymentMethods.stripe.acss_debit_payments &&
											editedInv.paymentMethods.stripe.card_payments &&
											`* note: Pre Authorized Debit refers to a ONE TIME PAYMENT and saves ${
												editedInv.contractor.companyName ||
												editedInv.contractor.username ||
												"the contractor"
											} from 2.9% of this payment going to credit card fees`
									}
								</div>*/}
							</div>
						);
					})}
					<div className="section-divider" />
					<div className="wrapper">
						<label htmlFor="adding">Amount Adding</label>
						<div>
							$
							<input
								className="inline-number-input"
								type="number"
								value={formData.adding}
								id="adding"
								name="adding"
								autoFocus="autoFocus"
								onChange={(e) => {
									e.preventDefault();
									handleChangePayment(e.target.name, e.target.value);
								}}
								step="0.01"
								required
							/>
						</div>
					</div>
					<div>
						<span
							className={`link-appearance tiny ${
								getNum(formData.adding, 2) === getNum(defaultAdding, 2) &&
								"hide"
							}`}
							onClick={(e) => {
								e.preventDefault();
								handleChangePayment("adding", getNum(defaultAdding, 2));
							}}
						>
							Reset to Total Owed
						</span>
					</div>
					{formData.fee > 0 && (
						<React.Fragment>
							<div className="small-line-break" />
							<div className="wrapper">
								{/*<div>Processing {getNum(editedInv.processingFee, 2)}% </div>*/}
								<div>Processing {getNum((processingFeePercent * 100), 2)}% </div>
								<div>${getNum(formData.fee, 2)}</div>
							</div>
						</React.Fragment>
					)}
					{formData.discount > 0 && (
						<React.Fragment>
							<div className="small-line-break" />
							<div className="wrapper">
								<div>
									{formData.paymentMethod} discount{" "}
									{getNum(
										contractorPaymentMethods[formData.paymentMethod]
											.discount,
										2
									)}
									%{" "}
								</div>
								<div>- ${getNum(formData.discount, 2)}</div>
							</div>
						</React.Fragment>
					)}
					<div className="section-divider" />
					<div className="wrapper">
						<strong>Total</strong>
						<strong>${getNum(formData.total, 2)}</strong>
					</div>
					<div className="section-divider" />
					{contractorPaymentMethods[formData.paymentMethod].payableTo && (
						<label>
							<input type="checkbox" required /> Please make payment out to:{" "}
							<em>
								{contractorPaymentMethods[formData.paymentMethod].payableTo}
							</em>
						</label>
					)}
					{contractorPaymentMethods[formData.paymentMethod].email && (
						<label>
							<input type="checkbox" required /> Please send payment to:{" "}
							<em>
								{contractorPaymentMethods[formData.paymentMethod].email}
							</em>
						</label>
					)}
					{formData.paymentMethod === "stripe" ? 
						<div>
							<label htmlFor="transactionType">
								{"Pay as: "}
							</label>
							<select
								name="transactionType"
								id="transactionType"
								value={formData.transactionType}
								onChange={(e) => {
									setFormData((formData) => ({
										...formData,
										transactionType: e.target.value,
									}));
								}}
							>
								<option name="personal" value="personal">
									Personal
								</option>
								<option name="business" value="business">
									Business
								</option>
								}
							</select>{" "}
							<MoreInfoIcon
								absolute={true}
								text={
									"Select 'Business' if you will be paying with a business credit card or bank account"
								}
							/>
							<div className="section-divider" />
							{/*<div>Who's paying the Processing Fee?</div>
							<div className="small-line-break" />
							<input onChange={() => setFormData(formData => ({...formData, customerPaysFees: true}))} id="customerPaysFees" name="customerPaysFees" type="radio" checked={formData.customerPaysFees} value={true} />
							<label htmlFor="customerPaysFees">Payer 
							</label>
							<input onChange={() => setFormData(formData => ({...formData, customerPaysFees: false}))} id="contractorPaysFees" name="customerPaysFees" type="radio" checked={!formData.customerPaysFees} value={false} />
							<label htmlFor="contractorPaysFees">Recipient ({editedInv.contractor.companyName || editedInv.contractor.companyName || ""})
							</label>

							<div>Fee: approx. 4%</div>*/}

						</div>
						: ""
					}
					<div className="section-divider" />
				</StripeLineItems>
				<div className="section-divider" />
				{
					formData.paymentMethod === "stripe" ? 
					<React.Fragment>
						{
							(currentUser || addPaymentModal.checkoutAsGuest) ? 
							<button
								className="button-appearance"
								disabled={processing || noPaymentTypesAccepted}
								// id="submit"
								// onClick={e => handleSubmit(e, clientSecret)}
							>
								<span>Check out</span>
							</button>
							:
							<Link className="button-appearance white" to={`?fromPayment=true&action=promptLogin&promptPayment=true&paymentAmount=${formData.adding}&paymentMethod=${formData.paymentMethod}`}>
								Check out
							</Link>
						}
					</React.Fragment>
					: <div>
						{
							currentUser ?
							<button className="button-appearance">Add to invoice</button>
							:
							<Link className="button-appearance white" to={`?fromPayment=true&action=promptLogin&promptPayment=true&paymentAmount=${formData.adding}&paymentMethod=${formData.paymentMethod}`}>Continue</Link>
						}
					</div>
				}
				<div className="small-line-break" />
				{processing && <SmallSpinner classNames="inverse" />}
				{formData.paymentMethod === "stripe" && (
					<React.Fragment>
						<div className="small-line-break" />
						<a href="https://stripe.com">
							<img
								height="22px"
								id="powered-by-stripe"
								alt="powered by stripe"
								src="/assets/powered-by-stripe.svg"
							/>
						</a>
					</React.Fragment>
				)}
			</form>
		</Modal>
	);
};

export default AddInvoicePaymentModal;
