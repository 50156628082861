import React, { useContext, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { MediaQueryContext } from '../contexts/MediaQueryContext'
import { logInAnonymously } from '../firebase/auth'
// import  CountDownTimer from '../components/CountDownTimer'

// import BlankPhone from '../components/svgComponents/BlankPhone'
// import Spinner from '../components/Spinner/Spinner'

// import firebase from '../firebase/index'

const HomeStyle = styled.div`
	.big-quote {
    position: relative;
    height: 15px;
    width: 25px;
    display: inline-block;
    margin-left: -4px;
		span {
	    font-size: var(--font-size-xxl);
	    position: absolute;
	    top: -12px;
	    left: 0;
		}
	}
	.marketplace-quote {
		color: var(--chicago);
		font-style: italic;

		// .big-quote {
	  //   position: relative;
	  //   height: 15px;
	  //   width: 25px;
	  //   display: inline-block;
	  //   margin-left: -4px;
		// 	span {
		//     font-size: var(--font-size-xxl);
		//     position: absolute;
		//     top: -12px;
		//     left: 0;
		// 	}
		// }
		strong {
			color: inherit;
		}
	}
	.marketplace-features-list {
		text-align: left;
		display: inline-block;
		margin: 20px 0 50px 0;
		padding: 0;
		li {
			list-style: none;
			margin-bottom: 15px;
			.material-icons {
			  vertical-align: text-bottom;
			  color: var(--light-gray);
			}
		}
	}
	.marketplace-new-feature {
		position: relative;
	  align-items: center;
	  margin-bottom: 40px;

	  h3 {
  	  color: rgb(0, 17, 20);
		  font-family: var(--font-family-poppins);
		  font-size: var(--font-size-xl);
		  font-weight: 600;
		  display: inline;
		}
		.new-tag {
		  position: relative;
			> span {
			    color: var(--brand-red);
			    font-size: var(--font-size-l);
			    transform: rotate(20deg);
			    padding: 0px 7px;
			    box-shadow: 12px 6px 27px -11px;
			    display: inline-block;
				  position: absolute;
			    top: -35px;
			    left: -10px;
			  }
			}

		}
	}

	.feature-heading {
		display: flex;
    justify-content: space-between;
    background-color: #131339;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    text-align: center;
		.screen-container {
      display: inline-flex;
	    flex-direction: column-reverse;
	    min-width: 47%;
	    align-items: end;
      margin-left: auto;
      margin-right: auto;
	    ${({isLessThan700px}) => isLessThan700px ?
		    `
			    min-height: 230px;
		    `:`
			    min-height: 280px;
		    `
		  } 
	    max-width: 470px;
			img {
				width: 100%;
			}
		}
		.feature-heading-container {
	    display: inline-flex;
	    padding-top: 40px;
	    ${({isLessThan700px}) => isLessThan700px ? 
		    `
		    	padding-right: 20px;
		    	padding-left: 20px;
			    text-align: center;
	        align-items: center;
			    padding-bottom: 30px;
		    ` : `
			    padding-right: 40px;
			    padding-bottom: 80px;
			    padding-left: 80px;
			    text-align: left;
		    `
		  };
	    max-width: 410px;
	    flex-direction: column;
	    /*min-width: 425px;*/
			h1 {
		    color: var(--white-text);
		    font-family: var(--font-family-gilroy-semibold);
		    font-size: 42px;
		    font-weight: 400;
		    -webkit-letter-spacing: 0;
		    -moz-letter-spacing: 0;
		    -ms-letter-spacing: 0;
		    letter-spacing: 0;
		    min-height: 118px;
			}
			p {
			  color: var(--white-text);
			  font-family: var(--font-family-poppins);
			  font-size: var(--font-size-m);
			  font-weight: 300;
			}
			// .countdown-timer {
			// 	> div:first-child {
			// 		padding-left: 0;
			// 	}
			// 	> div {
			// 		text-align: center;
			// 		font-size: var(--font-size-m);
			// 		box-shadow: none;
			// 		padding: 0px 7px;
			//     width: 40px;
			// 		> p {
			// 			font-size: var(--font-size-xxs);
			// 			color: var(--chicago);
			// 		}
			// 	}
			// }
			#no-catch {
				font-size: var(--font-size-s);
			}
		}
	}
	.linvoice-text {
		.underline-section {
			display: inline-flex;
			flex-direction: column;
			svg {
				// width: 100%;
				display: inline-block;
				// height: 10px;
				color: var(--brand);
				// transform: rorate(-5deg);
				margin-top: -15px;
			}
		}
	}
	.sample-invoice {
    border: 5px solid var(--brand-green);
    box-shadow: 1px 1px 18px -3px;
    border-radius: 5px;
    /* transform: rotate(-20deg); */
    display: inline-block;
    padding: 0 10px;
    svg {
    	width: 1.3ch;
    	height: 1.3ch;
    }
	}
	.testimonials {
		text-align: left;
		// background-color: var(--off-white);
		// border-radius: 20px;
		color: var(--chicago);
		// padding: 25px 40px;
		font-size: var(--font-size-s);
		font-style: italic;
		// box-shadow: 0 0 25px -10px;
		.testimonial {
			box-shadow: 0 0 25px -10px;
			padding: 30px 20px;
	    font-size: var(--font-size-m);
			.testimonial-heading {
				display: flex;
				margin: 20px 0;
				img {
					height: 80px;
					border-radius: 50%;
					margin-right: 10px;
				}
				div {
					display: flex;
					flex-direction: column;
			    margin-top: auto;
			    font-size: var(--font-size-s);
				}
			}
			p {
				margin: 0;
			}
		}
	}
	.try-for-free {
	  margin-top: 20px;
	}
	.feature-container {
	  /*margin-top: 120px;*/
	  position: relative;
	  margin-top: 90px;
    max-width: 700px;
    ${({isLessThan700px}) => isLessThan700px ? 
	    `
	    	margin-right: 20px;
	    	margin-left: 20px;
	    ` : `
		    margin-left: auto;
		    margin-right: auto;
	    `
	  };
	  .feature-statement {
	  	margin-bottom: 40px;
	  }
	  h3 {
		  color: #001114;
		  font-family: var(--font-family-poppins);
		  font-size: var(--font-size-xl);
		  font-weight: 600;
	  }
	}
	.decorative-divider {
		display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
		/*position: absolute;
  	bottom: 0;
		right: 0;*/
	}
	.decorative-divider-left {
		display: flex;
    justify-content: left;
    ${({isLessThan700px}) => isLessThan700px ? 
	    `
	    	margin-top: 65px;
	    ` : ``
	  };
    img {
	    transform: rotate(180deg);
    }
	}
	.argument-container {
		display: flex;
		justify-content: space-between;
		text-align: left;
	  align-items: center;
	  display: flex;
	  flex-wrap: wrap;
	  p {
		  max-width: 375px;
		  margin-bottom: 40px;
	  }
	  h4 {
	  	margin: 0;
	  }
	}
	.iphone {
		max-width: 300px;
		img {
			width: 100%;
		}
    ${({isLessThan700px}) => isLessThan700px ? 
	    `
		    margin-left: auto;
		    margin-right: auto;
	    ` : ``
	  };
	}
	.customer {
		.argument {
	    margin-left: auto;
	    text-align: right;
		}
    flex-direction: row-reverse;
	}
	.arrow-1 {
	  margin-bottom: 40px;
		${({isLessThan700px}) => isLessThan700px ? 
	    `
			  margin-top: 40px;
		    width: 80%;
		    text-align: end;
	    ` : `
		    transform: scaleX(-1);
	    `
	  };
	}
	.arrow-2 {
	  margin-bottom: 40px;
    ${({isLessThan700px}) => isLessThan700px ? 
	    `
			  margin-top: 40px;
		    width: 80%;
		    transform: scaleX(-1);
	    ` : `
	    `
	  };
	}
	
`

const CarouselStyle = styled.div`
  max-width: 700px;
  overflow: auto;
  position: relative;
  .can-swipe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	font-size: 100px;
  	opacity: 0.2;
  	transition: opacitty 2s linear;
  	.material-icons {
  		font-size: 30px;
  		color: var(--chicago);
  	}
  }
  .icon-show {
  	opacity: 1;
  }
  .icon-hide {
  	opacity: 0.2;
  }
  > div {
    min-width: max-content;
    column-gap: 20px;
    display: flex;
    // overflow-x: scroll;
    scroll-behavior: smooth;
    // scroll-snap-type: x mandatory;
    .img-container {
      max-width: 300px;
      img {
        width: 100%;
        // scroll-snap-align: center;
      }
    }
  }
`;

const Carousel = ({ images, isLessThan700px }) => {
  const [hasIndicatedScrollable, setHasIndicatedScrollable] = useState(false)
  const [scrollY, setScrollY] = useState(0)

  const [canSwipeClass, setCanSwipeClass] = useState('')

  const carouselRef = useRef(null);
  // const timeoutRef = useRef(null);
  const animationRef = useRef(null);

  const runAnimation = (scrollStep) => {
  	// const scrollStep = 10; // Adjust the scroll step as desired
  	if (animationRef.current) {
	  	cancelAnimationFrame(animationRef.current)
  	}

  	const animate = () => {
  	  carouselRef.current.parentElement.scrollLeft += scrollStep;
  	  animationRef.current = requestAnimationFrame(animate);
  	};

  	animationRef.current = requestAnimationFrame(animate);
  }

  if (carouselRef.current && carouselRef.current.parentElement) {
  	const clientRect = carouselRef.current.getBoundingClientRect()
  	const stickyHeadderHeight = isLessThan700px ? 73 : 91
  	const caroselHight = clientRect.height
  	// const caroselElementStart = carouselRef.current.offsetTop
  	const caroselElementStart = clientRect.top


  	// const triggerAtFrom = caroselElementStart + caroselHight + (isLessThan700px ? stickyHeadderHeight : (stickyHeadderHeight * -1) / 2)
  	const triggerAtFrom = caroselElementStart + caroselHight + stickyHeadderHeight

  	const triggerAtTo = triggerAtFrom + 200
  	const caroselElementEnd = caroselElementStart + (caroselHight * 2)

	  if (hasIndicatedScrollable) {
			if (scrollY > caroselElementEnd || scrollY < caroselElementStart) {
				// console.log("setting hasIndicatedScrollable false...", {caroselElementEnd, caroselElementStart, scrollY})
		  	// cancelAnimationFrame(animationRef.current)
				setHasIndicatedScrollable(false)
			}
	  	
	  } else {
		  	// console.log({scrollY, triggerAtFrom, triggerAtTo})

		  if (scrollY > triggerAtFrom && scrollY < triggerAtTo) {
		  	// console.log("should trigger")
		  	// cancelAnimationFrame(animationRef.current)
		  	runAnimation(2)
		  	setCanSwipeClass("icon-show")
		  	setTimeout(() => {
			  	// cancelAnimationFrame(animationRef.current)
		  		runAnimation(-2)
			  	setCanSwipeClass("icon-hide")
		  	}, 300)

		  	setTimeout(() => {
			  	cancelAnimationFrame(animationRef.current)
		  		// runAnimation(-2)
		  	}, 600)
		  	setHasIndicatedScrollable(true)
		  }
	  }
  	
  }


	useEffect(() => {
		const handleScroll = () => {
			const newScrollY = window.scrollY
			setScrollY(newScrollY)
		}

	  document.addEventListener("scroll", handleScroll)

	  return () => {
	  	document.removeEventListener("scroll", handleScroll)
	  }
	}, [])





  return (
    <CarouselStyle>
	    <div className={`can-swipe ${canSwipeClass}`}>
		    <span className="material-icons">{"\ueb59"}</span>
	    </div>
      <div id={"caroselRef"} ref={carouselRef} /*onScroll={handleContainerScroll}*/>
        {images.map((image, index) => (
          <div className="img-container" key={index}>
            <img src={image} alt="" />
          </div>
        ))}
      </div>
    </CarouselStyle>
  );
};


	  // background-image: url(../assets/2-screens-background.png);

// rendered by App.js
const Home = ({setErrorObj, userObject, offlineMode, setLoginModalOpen, handleSetSuccessText}) => {
	const {
		isLessThan700px
	} = useContext(MediaQueryContext)

	return (
		<HomeStyle isLessThan700px={isLessThan700px}>
			<div className="feature-heading" >
				<div className="feature-heading-container">
					<h1>
						{"Skilled Workers. Amazing Customers."}
					</h1>
					<p>
						{/*Linvo provides advanced billing tools and 
						Invoicing, project management and networking tools that help give your business an incredible advantage in the construction market
						Less paperwork, happier customers, more profit*/}
						{/*Helping Homeowners and business managers connect with those hard to find, highly efficient small contractors and helping these contractors grow into modern industry leaders.*/}
						Unique invoicing, automation and networking tools that will help give your construction business an incredible advantage. 
						{/*Less paperwork, happier customers, more profit*/}
		      </p>
		      <div className="try-for-free">
		      	{
		      		userObject.id ?
		      		<Link className="button-appearance no-link" to="/dashboard" >Go to Dashboard</Link>
		      		: 
		      		<React.Fragment>
			      		<div className="button-appearance" onClick={() => {
				        	setLoginModalOpen(true)}
				        }>Sign up</div>
				        <div className="section-divider" />
				        <p>
				        	{"Not ready to sign up? "}
	                <Link to="/dashboard" className="link-appearance blue" onClick={() => {
				          	if (!userObject.id) {
		                	logInAnonymously()
				          	}
	                }}>
					          {"Just try it out now"}
				          </Link>
				          {" with a sample account"}
				        </p>
		      		</React.Fragment>
		      	}
		      </div>
	      </div>
	      <div className="screen-container">
					<img alt="" src="/assets/two-phones.png" />
				</div>
			</div>

      <div className="feature-container">
        <div className="feature-statement">

					<p className="marketplace-quote">
						<span className="big-quote">
							<span>"</span>
						</span>
						Helping Homeowners and Building Managers connect with those <strong>hard to find, highly efficient small contractors</strong> and helping these contractors grow into modern industry leaders.
						<span className="big-quote">
							<span>"</span>
						</span>
					</p>
					<div className="section-divider" />
					<div className="section-divider" />
					<div className="section-divider" />

		      <div className="marketplace-new-feature">
	          <h3>Marketplace</h3>
	          <span className="new-tag"><span>NEW!</span></span>
	          <div>By Linvo</div>
					</div>
{/*	      	<div >
	      		<span className="new-feature">
			      	<span>New feature!</span> <span className="info-icon">June 2023</span>
		      	</span>
	      	</div>*/}
{/*          <p className="poppins-light-chicago-16px">
          	A Marketplace of Contractors and Customers
	          Find contractors, post jobs and build your reputation on Marketplace
          </p>*/}
					<ul className="marketplace-features-list poppins-light-chicago-16px">
						<li><span className="material-icons">{"\uea1d"}</span> Customer Job Postings</li>
						<li><span className="material-icons">{"\uea23"}</span> Highly skilled independent contractors</li>
						<li><span className="material-icons">{"\ue01d"}</span> Top rated General Contractors</li>
						<li>. . . In <strong>Your Area</strong></li>
						<li><br/></li>
						<li className="poppins-bold-mariner-24px align-center">
							Find it all on <Link to="/marketplace" className="link-appearance blue">Linvo Marketplace!</Link>
						</li>

					</ul>
					<Carousel isLessThan700px={isLessThan700px} images={[
						"/assets/iso-plumbers.png",
						"/assets/see-recommended.png",
						"/assets/see-post.png",
						"/assets/view-your-post.png"
					]} />
        </div>
      <div className="section-divider" />
				<p>Find <Link to="/marketplace" className="link-appearance blue">Contractors in your area</Link> now!
				</p>
      </div>

      <div className="section-divider" />
      <div className="section-divider" />

      <div className="feature-container">
        <div className="feature-statement">
          <h3>{"Introducing the "}
	          <span className="linvoice-text">
		          <span className="underline-section">Linvoi
								<svg viewBox="0 0 32 4" fill="url('#underlineGrad')" stroke="url('#underlineGrad')" strokeLinecap="round" strokeWidth="0.5">								
							    <path d="M1 3 L34 2 L0 2 L1 3" />
								</svg>
		          </span>
	          </span>ce
          </h3>
          <p className="poppins-light-chicago-16px">
						Linvoice: A live, web-hosted, automatable invoice 
						{/*"The perfect invoice solution for renovations, ongoing jobs or contractors who want to find skilled workers to join their team."*/}
          </p>
	        <div className="section-divider"/>
	        <div className="section-divider"/>

					<p className="marketplace-quote">
						<span className="big-quote">
							<span>"</span>
						</span>
						The perfect invoice solution for renovations, ongoing jobs and billing subcontractor invoices through to the customer.

						<span className="big-quote">
							<span>"</span>
						</span>
					</p>
	        <div className="section-divider"/>
	        <div className="section-divider"/>

          <p>
	        	{"Sick of reading? "}
	          <Link to="/dashboard" className="link-appearance blue" onClick={() => {
	          	if (!userObject.id) {
		          	logInAnonymously()
	          	}
	          }}>
		          {"Just try it now"}
	          </Link>
	          <span className="tiny">{userObject.id ? "" :  " (No sign up needed)"}</span>
	        </p>
        <div className="section-divider"/>
        <div className="section-divider"/>
        <div className="section-divider"/>
        <div className="section-divider"/>

	      <div className="testimonials">

	      	<div className="testimonial">
						<p><span className="big-quote"><span>"</span></span>
			      	Linvoices have been great for my renovation projects. My workers can upload all their receipts right into the invoice for me to see and I can add payments at any time right into the invoice.					
						<span className="big-quote"><span>"</span></span></p>
						<Link to="/users/Hostahomes" className="no-link">
							<div className="testimonial-heading">
					      <img alt="" src="/assets/hostahomespicture.jpeg" />
								<div>
									<p>Dave</p>
									<p>Hostahomes</p>
									<p>General Contractor</p>
								</div>
							</div>
						</Link>
					</div>

					<div className="section-divider" />
					<div className="section-divider" />
					<div className="section-divider" />

					<div className="testimonial">
						<p><span className="big-quote"><span>"</span></span>
							The 'Invoice Levying' feature of Linvoices allows us to easily keep track of what we owe to subcontractors and have their totals automatically updated into our invoice as the job progresses.
						<span className="big-quote"><span>"</span></span></p>
						<Link to="/users/Wesran" className="no-link">
							<div className="testimonial-heading">
					      <img alt="" src="/assets/wesran.jpg" />
								<div>
									<p>Nathan & Mellissa</p>
									<p>Wesran Construction</p>
									<p>Renovations & Interior Design</p>
								</div>
							</div>
						</Link>
					</div>
					<div className="section-divider" />
					<div className="section-divider" />

	      </div>

      	{
      		userObject.id ? "" :
		      <React.Fragment>
		        <div className="section-divider"/>
		        <div className="section-divider"/>
		        <p className="sample-invoice">{"Check out a "}
		          <Link to="/Linvo/39kAjH7B8Uv8I9saFIVu" onClick={() => {
		          	if (!userObject.id) {
			          	logInAnonymously()
		          	}
		          }}>
			          {"Sample Linvoice "}
								<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							    <path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
								</svg>
		          </Link>
		          {" With a demo account"}
		        </p>
		      </React.Fragment>
      	}

        <div className="section-divider"/>

        </div>
	      <div className="decorative-divider">
	      	<img alt="" src="/assets/decorative-divider.png" />
	      </div>
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />

	      <div className="argument-container contractor">
		      <div className="argument">
            <h4 className="poppins-bold-mariner-24px">Get paid sooner</h4>
            <p className="poppins-light-chicago-16px">
            	Linvoices allow customers can add <strong>frequent, verified payments</strong> directly in the Linvoice so the job is <strong>more affordable</strong> for you and the customer
            </p>
          </div>
					<div className="iphone" >
						<img alt="" src="/assets/add-payment.png" />
					</div>
	      </div>

	      <div className="arrow-1">
					<img alt="" src="/assets/curved-arrow-down.png" />
				</div>

				<div className="argument-container customer">
					<div className="argument">
            <h4 className="poppins-bold-mariner-24px">Never too busy</h4>
            <p className="poppins-light-chicago-16px">
            	Swipe through <strong>Customer Created Job Posts in your area</strong> and take on the job yourself or hire subcontractors on Linvo Marketplace to do the work - <strong>while you focus on growth</strong>
            </p>
          </div>
					<div className="iphone" >
						<img alt="" src="/assets/jobs-and-contractors.png" />
					</div>
	      </div>

	      <div className="arrow-2">
					<img alt="" src="/assets/curved-arrow-down.png" />
				</div>

	      <div className="argument-container contractor">
					<div className="argument">
            <h4 className="poppins-bold-mariner-24px">Build Reputation</h4>
            <p className="poppins-light-chicago-16px">
            	Verified reviews on <strong>contractors AND customers</strong> so you know who your working with and so you can become <strong>THE BEST CONTRACTOR IN TOWN</strong>
            	{/*Find reliable subcontractors that help you build a reputation as <strong>THE BEST CONTRACTOR IN TOWN</strong>*/}
            </p>
          </div>
					<div className="iphone" >
						<img alt="" src="/assets/search-results.png" />
					</div>
	      </div>
	      <div className="decorative-divider-left">
	      	<img alt="" src="/assets/decorative-divider.png" />
	      </div>
      </div>
		</HomeStyle>
	)
}

export default Home