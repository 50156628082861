import React, { useContext, useState, useEffect } from 'react'

import { UserContext } from "../contexts/UserContext";

import Modal from "./Modal/Modal";
import Form from "./Forms/Form"
import { AppMessage } from './MessageUtils'
// import { getNum } from "../utils/appUtils";
// import {
// 	handleAcceptFollower,
// 	handleAcceptEditor,
// 	getUserObjs,
// 	getFollowersUserDataWithType,
// } from "../utils/followUtils";

import {
	logInWithEmailLink,
} from "../firebase/auth";

import firebase from "../firebase/index";

const ChangePasswordModal = () => {
	const {
		userObject,
		changePasswordModalData, 
		setChangePasswordModalData,
		setErrorObj,
		handleSetMessage,
		handleSetSuccessText,
	} = useContext(UserContext)

	const [formData, setFormData] = useState({
		email: "",
		oldPassword: "",
		newPassword: "",
		newPasswordAgain: ""
	})

	const handleSendPasswordReset = async (e) => {
		e && e.preventDefault();
		let email = formData.email
		try {
			await firebase.auth().sendPasswordResetEmail(email);
			handleSetSuccessText(
				`Success, password reset link sent to ${email}`,
				6000
			);
		} catch (err) {
			setErrorObj(err);
		}
	};

	const handleChange = (name, val) => {
		setFormData(formData => ({
			...formData,
			[name]: val,
		}))
	}

	const handleClickOutside = () => {
		setChangePasswordModalData(changePasswordModalData => ({
			...changePasswordModalData,
			open: false,
			oldPassword: "",
			newPassword: "",
			newPasswordAgain: "",
			callback: null,
			creatingNew: false,
		}))
		handleSetMessage("")
	}


	const hdandleSubmit = async (e, formData, setFormData) => {
		e && e.preventDefault();

		if (formData.newPassword.length < 6) {
			setErrorObj({message: "Password must be more than 6 characters", noReport: true})
			return
		}

		if (formData.newPassword !== formData.newPasswordAgain) {
			setErrorObj({message: "Passwords do not match", noReport: true})
			return
		}

		// let credential = firebase.auth.EmailAuthProvider.credential(formData.email, formData.newPassword);

		if (formData.creatingNew) {
			try {
				await firebase.auth().currentUser.updatePassword(formData.newPassword)

			} catch (err) {
				if (err.code === "auth/provider-already-linked") {
					err.noReport = true
					setFormData(formData => ({
						...formData,
						creatingNew: false
					}))
					handleSetMessage("You already have a password, enter your old password to update it")
					// setErrorObj({message: "You already have a password, enter your old password to update it"})
					return
				} else if (err.code === "auth/requires-recent-login") {
						// send emailLoginLink then reAuth
					const linkSent = await logInWithEmailLink(formData.email);
					if (linkSent) {
						handleSetSuccessText(
							`Login link sent to ${formData.email} please click the link to make a password`,
							6000
						);
					}
					return 

				} else {
					setErrorObj(err)
					return
				}
			}

		} else {
			try {
				const cred = firebase.auth.EmailAuthProvider.credential(
					formData.email,
					formData.oldPassword
				);
				// re authenticate
				await firebase.auth().currentUser.reauthenticateWithCredential(cred);
				// change password
				await firebase.auth().currentUser.updatePassword(formData.newPassword);
			} catch (err) {
				if (err.code === "auth/wrong-password") {
					err.noReport = true
					err.message = "Wrong password"
					setErrorObj(err);
					// setErrorObj({ ...err, noReport: true });
				} else if (err.code === "auth/too-many-requests") {
					err.noReport = true
					err.metadata = {email: formData.email}
					setErrorObj(err);
				} else if (err.code === "auth/user-not-found") {
					err.message = "No account found for that email"
					err.noReport = true
					setErrorObj(err);
				} else {
					setErrorObj(err);
				}
				return
			}
		}

		formData.callback && typeof(formData.callback) === "function" && formData.callback()
		handleSetSuccessText("Password updated successfully")
		return handleClickOutside()
	}

	useEffect(() => {
	  if (userObject.id) {

	  	setFormData(formData => ({
	  		...formData,
				...changePasswordModalData,
	  	}))
	  }
	}, [userObject.id, changePasswordModalData])

	const inputs = [
		{
			label: "Old Password", 
			onChange: handleChange,
			visible: !formData.creatingNew,
			properties: {
				type: "password", 
				id: "oldPassword",
				value: formData.oldPassword,
				required: true			
			},
		},
		{
			label: "Password", 
			onChange: handleChange,
			properties: {
				type: "password", 
				id: "newPassword",
				value: formData.newPassword,
				required: true			
			},
		},
		{
			label: "Re-type password", 
			onChange: handleChange,
			properties: {
				type: "password", 
				id: "newPasswordAgain",
				value: formData.newPasswordAgain,
				required: true			
			},
		},
	]

  return (
		<Modal
			custom={{ absolute: true, style: {zIndex:101} }}
			onClickOutside={handleClickOutside}
		>
		<div className="heading">{formData.creatingNew ? "Please create a password" : "Update Password"}</div>
			<AppMessage dependants={[formData]} />
			<Form
				inputs={inputs}
				heading=""
				submitName=""
				onSubmit={(e) => hdandleSubmit(e, formData, setFormData)}
			>
				<div className="section-divider" />
				<div className="small">				
					<span>Forgot Password?</span>&nbsp;
					<span
						className="link-appearance blue"
						onClick={handleSendPasswordReset}
					>
						Send password reset link
					</span>
				</div>
				<div className="section-divider" />
				<div className="section-divider" />

				<div className="align-center submit-actions">
					<button disabled={userObject.id ? false : true} className="button-appearance">
						{formData.creatingNew ? "Create" : "Update"}
					</button>
					<button className="button-appearance gray" onClick={(e) => {
						e.preventDefault()
					 	handleClickOutside()
					}} >
						Cancel
					</button>
				</div>
			</Form>
    </Modal>
  )
}

export default ChangePasswordModal