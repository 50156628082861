import styled from 'styled-components'

export const SymbolFromIdStyle = styled.div `
	/*padding: 3px;*/

  svg {
    width: 80px;
    height: 80px;
    fill: white;
    stroke-width: 3px;
    stroke-linejoin: round;
    color: white;
    padding: 5px;
    border-radius: 50%;
    background-color: var(--brand);
    filter: url(#shadow1);
    overflow: visible;
	}
`