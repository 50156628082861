import React, { useState, useRef, useEffect, useContext } from 'react'
import { ProfileAboutStyle } from './ProfileAboutStyle.styled'

import { UserContext } from '../../../contexts/UserContext'

// import firebase from '../../../firebase/index'

// rendered by UserProfile.js
const ProfileAbout = ({resultUserData, currentUsersProfile}) => {
	const {
		// currentUser,
		// newUserData,
		// setNewUserData,
		updateUserData,
		setErrObj,
		handleSetSuccessText
	} = useContext(UserContext)

	const [isEditing, setIsEditing] = useState(false)
	const [profileAboutData, setProfileAboutData] = useState({
		mainText: [],
		mainTextRows: 2
	})
	const textareaRef = useRef(null)

	const handleChange = (name, val) => {
		let newVal = val
		// let newMainTextRows = profileAboutData.mainTextRows
		if (name === "mainText") {
			newVal = val.split("\n")
			// newMainTextRows = newVal.length
		}

		setProfileAboutData(profileAboutData => ({...profileAboutData,/* mainTextRows: newMainTextRows,*/ [name]: newVal}))
	}

	const handleSave = async () => {
		try {
			// const user = firebase.auth().currentUser

			await updateUserData({profileAbout: profileAboutData})
			// await firebase.firestore().collection("users").doc(currentUser.uid).update({
			// 	profileAbout: profileAboutData
			// })
			// update the ui ( newUserData )
			// setNewUserData(newUserData => ({
			// 	...newUserData,
			// 	profileAbout: profileAboutData
			// }))
			handleSetSuccessText("All changes saved")
			setIsEditing(false)
		} catch (err) {
			setErrObj(err)
		}
	}

	const getDefaultProfileAbout = () => {
		if (resultUserData.profileAbout) {
			return {...resultUserData.profileAbout}
		} else if (resultUserData.cardUserInfo) {
			return {
				mainText: resultUserData.cardUserInfo.val,
				mainTextRows: resultUserData.cardUserInfo.rows
			}
		} else {
			return {
				mainText: "",
				mainTextRows: ""
			}
		}
	}

	useEffect(() => {
		setProfileAboutData(profileAboutData => ({...profileAboutData, ...getDefaultProfileAbout()}))
		// eslint-disable-next-line
	}, [resultUserData.id])


	return (
		<ProfileAboutStyle isEditing={isEditing}>
			{
				currentUsersProfile ? 
					<div className="main-text-wrapper">
					{
						isEditing ? 
							<React.Fragment>
								<textarea ref={textareaRef} rows={parseInt(profileAboutData.mainText.length)} value={profileAboutData.mainText.join("\n")} onChange={e => handleChange("mainText", e.target.value)} />
								<div className="icon-container">
									<div onClick={handleSave}>
										<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
									    <path d="M2 20 L12 28 30 4" />
										</svg>
									</div>
									<div onClick={() => {
										setIsEditing(false)
										setProfileAboutData(profileAboutData => ({...profileAboutData, ...getDefaultProfileAbout()}))
									}}>Cancel</div>
								</div>
							</React.Fragment>
						: 
						<React.Fragment>
							<div className="main-text">
								{
									profileAboutData.mainText.map((t, i) => <div key={i}>{"" + t + ""}</div>)
								}
							</div>
							<div className="icon-container">
								<div onClick={() => setIsEditing(true)}>
									<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
								    <path d="M30 7 L25 2 5 22 3 29 10 27 Z M21 6 L26 11 Z M5 22 L10 27 Z" />
									</svg>
								</div>
							</div>
						</React.Fragment>
					}
				</div>
				: <div className="main-text-wrapper">
					<div className="main-text">
						{
							profileAboutData.mainText.map((t, i) => <div key={i}>{"" + t + ""}</div>)
						}
					</div>
				</div>
			}
		</ProfileAboutStyle>
	)
}

export default ProfileAbout