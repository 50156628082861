import styled from "styled-components";

export const ForwardBillModalStyle = styled.div `
	.invoice-info {
		text-align: left;
		p {
			margin: 7px 0;
		}
		em {
			color: gray;
			padding: 0 10px;
			box-shadow: 0px 3px 10px -5px;
		}
		em.green {
			box-shadow: 0px 3px 10px -5px green;
		}
		em.red {
			box-shadow: 0px 3px 10px -5px red;
		}
		img {
			height: 40px;
			width: 40px;
			border-radius: 50%;
		}

	}

`