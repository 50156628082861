import React, { useContext, useEffect, useState, useRef } from 'react'

import { EditInvoiceContext } from '../../contexts/EditInvoiceContext'
import MoreInfoIcon from '../MoreInfoIcon'

// import { MediaQueryContext } from '../../contexts/MediaQueryContext'

// import Modal from '../Modal/Modal'
// import ChangeLayoutModal from './ChangeLayoutModal/ChangeLayoutModal'
// import { AppMessage } from '../MessageUtils'
// import DotActions from '../DotActions/DotActions'

// import { ControlsNavStyle } from '../Navbar/ControlsNavStyle.styled'

// rendered by: Invoice.js
// errors and success text handled
const InvoiceControls = ({invoiceId, handlePrint, paperDetails, setLayoutModalOpen}) => {
	const {
		isEditing,
		setIsEditing, 
		editedInv,
		userCanEdit,
		handleSave,
		setErrorObj,
		undoChange,
		redoChange,
		setLastSavedVersion,
		editedInvVersions,
		handleSetSuccessText,
	} = useContext(EditInvoiceContext)

	const [toolbarCOllapsed, setToolbarCollapsed] = useState(false)
	// remove the option to remove totals... ? 
	const [invoiceToolbarClass, setInvoiceToolbarClass] = useState("noprint invoice-toolbar")
	const controlsRef = useRef(null)
	const toolbarRef = useRef(null)
	const showToolbar = userCanEdit

	const resetPage = () => {
		window.location.reload()
	}

	useEffect(() => {
		const printDoc = async () => {
			try {
			  document.execCommand('print', false, null)
			}
			catch(e) {
			  window.print()
			}
		}
		if (paperDetails.printAction) {
			// IOS has no support for onafterprint so it has to be this way for now :(
			// window.print()
			// setPaperDetails(prevPaperDetails => ({
			// 	...prevPaperDetails,
			// 	margin: invoicePageMargin,
			// 	printAction: false
			// }))
			printDoc()
			window.addEventListener("scroll", resetPage)
			window.addEventListener("mousemove", resetPage)
		} 

		return () => {
			window.removeEventListener("scroll", resetPage)
			window.removeEventListener("mousemove", resetPage)
		}
	}, [paperDetails.printAction])

	useEffect(() =>{
		const checkControlsPosition = () => {
			if (controlsRef.current) {			
				const bottomOfPagesY = controlsRef.current.getBoundingClientRect().height - toolbarRef.current.getBoundingClientRect().height
				if (window.scrollY > bottomOfPagesY) {
					// controlsRef.position = "abosolute"
					// controlsRef.top = "363px"
					// console.log(window.scrollY, invoiceToolbarClass, invoiceToolbarClass.includes(" pin-at-bottom"))
					if (!invoiceToolbarClass.includes(" pin-at-bottom")) {
						setInvoiceToolbarClass(invoiceToolbarClass => {
							return invoiceToolbarClass + " pin-at-bottom"
						})
					}
				} else {
					if (invoiceToolbarClass.includes(" pin-at-bottom")) {
						setInvoiceToolbarClass(invoiceToolbarClass => {
							return invoiceToolbarClass.replace(" pin-at-bottom", "")
						})
					}
				}
			}
		}
		if (showToolbar) {
			window.addEventListener("scroll", checkControlsPosition)
		}
		return () => {
			window.removeEventListener("scroll", checkControlsPosition)
		}
	}, [/*controlsRef,*/ invoiceToolbarClass, setInvoiceToolbarClass, showToolbar])


	return (
		<React.Fragment>



			<div className="print-share-container noprint">
				<div>
					<div onClick={handlePrint}>
						<span>
		  				<span className="material-icons">{"\ue8ad"}</span>
	  				</span>Print
					</div>
				</div>
				<div>
					<div onClick={(e) => {
  					const input = document.getElementById("invoice-" + editedInv.id + "-input")
  					const textEl = input?.parentElement?.previousElementSibling?.firstChild?.firstChild
  					if (textEl) { 
  						textEl.textContent = "link copied"
  					}
  					input.select()
						document.execCommand("copy");
						handleSetSuccessText("Link copied");
  				}}>
						<MoreInfoIcon 
	  					absolute={true} 
	  					custom={`top: -32px; right: 0; width: max-content;`}
	  					customIcon={
			  				<span className="material-icons">{"\ue80d"}</span>
							} 
							text="copy link" 
						/>Share
						<div>
	  					<input id={"invoice-" + editedInv.id + "-input"} defaultValue={window.location.href} readOnly style={{position: "fixed", bottom: "-100px", opacity: 0}} />
						</div>
					</div>
				</div>
			</div>
			<div className="controls-container noprint" ref={controlsRef}>
				{
					showToolbar &&
					<div className={invoiceToolbarClass} ref={toolbarRef}>
						<div className={toolbarCOllapsed ? "toolbar-hide" : ""}>
							{
								!editedInv.autoSaveEnabled &&
								<div onClick={e => handleSave()}>
									<svg viewBox="0 0 32 32" /*width="16px" height="16px"*/ fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
								    <path d="M2 20 L12 28 30 4" />
									</svg> Save
								</div>
							}
							<div onClick={e => {
								if (editedInv.closed) {
									setErrorObj({message: "Invoice is closed. Re-open Invoice to enable editing", noReport: true})			
								} else {
									// every time edit status changes run autosave
									const currentInvVersion = editedInvVersions.find(v => v.current)
									if (isEditing && editedInv.autoSaveEnabled && currentInvVersion && currentInvVersion.id !== "original") {
							  		handleSave()
							  		setLastSavedVersion(currentInvVersion.id)
									}
									setIsEditing(!isEditing)
								}
							}} >
								<svg viewBox="0 0 32 32" /*width="16px" height="16px"*/ fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
							    <path d="M30 7 L25 2 5 22 3 29 10 27 Z M21 6 L26 11 Z M5 22 L10 27 Z" />
								</svg>{isEditing ? " Cancel" : " Edit"}
							</div>
							<div onClick={e => {
								if (editedInv.closed) {
									setErrorObj({message: "Invoice is closed. Re-open Invoice to enable editing", noReport: true})			
								} else {
									setLayoutModalOpen(true)
								}
							}}>
								<svg id="layout-edit" viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
							    <path d="M27 15 L27 30 2 30 2 5 17 5 M30 6 L26 2 9 19 7 25 13 23 Z M22 6 L26 10 Z M9 19 L13 23 Z" />
								</svg> Layout
							</div>
							<div onClick={undoChange} >
								<svg id="undo-icon" viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
									<path d="M12 22 L2 12 L12 3 M2 12 L16 12 C24 12 29 18 29 28" />
								</svg>Undo
							</div>
							<div onClick={redoChange}>
								<svg id="redo-icon" viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
									<path d="M20 22 L30 12 L20 3M30 12 L16 12 C8 12 3 18 3 28"  />
							  </svg>Redo
							</div>
						</div>
						<div onClick={() => setToolbarCollapsed(!toolbarCOllapsed)}>
							{
								toolbarCOllapsed ?
								<div>
									<svg id="down-arrow" viewBox="0 0 32 32" /*width="16px" height="16px"*/ fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
									    <path d="M30 12 L16 24 2 12" />
									</svg>
								</div>
								:
								<div>
									<svg id="up-arrow" viewBox="0 0 32 32" /*width="16px" height="16px"*/ fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
								    <path d="M30 20 L16 8 2 20" />
									</svg>
								</div>
							}
						</div>
					</div>

				}
			</div>
		</React.Fragment>
	)
}

export default InvoiceControls
