import React, { useState, useEffect, useRef, useContext } from 'react'
import firebase from '../../firebase/index'

import { UserContext } from '../../contexts/UserContext'
import { AppMessageContext } from '../../contexts/AppMessageContext'

import { AppMessage } from '../MessageUtils'
import Form from '../Forms/Form' 

// rendered by specificProject
const AddPrivateProjectDocs = ({projectId, setAddDocsModalOpen}) => {
	// const [errorText, setErrorText] = useState("")
	const {
		currentUser,
		userObject,
		// setUserObject,
		usersProjects,
		updateUserData
	} = useContext(UserContext)
	const {setErrorObj} = useContext(AppMessageContext)

	const [quickAddItem, setQuickAddItem] = useState({})
	const [formData, setFormData] = useState({
		invoices: [],
		projects: [],
		cotracts: [],
		bills: [],
		receipts: [],
		photos: [],
		notes: [],
		searchText: "",
		searchFilters: [],
		// searchProjects: "no",
		// searchInvoices: "no",
		// searchContracts: "no",
		// searchBills: "no",
		// searchReceipts: "no",
	})
	const [searchResults, setSearchResults] = useState([])
	const [itemsToAdd, setItemsToAdd] = useState([])
	const [quickAddOptions, setQuickAddOptions] = useState([
		{name: "Select one", id: ""}
	])

	const quickAddDropdownRef = useRef(null)
	const filterOptions = ["projects", "invoices", "contracts", "bills", "receipts"]

	// const thisProject = userObject
	const thisProject = usersProjects ? usersProjects.find(proj => proj.id === projectId) : null

	const isItemAlreadyListed = (id) => {
		if (!id) {
			console.log("no id")
			return false
		}
		if (itemsToAdd.find(doc => doc.id === id)) {
			return true
		} else return false
	}

	const isInPrivateDocsAlready = (id) => {
		if (!id) {
			console.log("no id")
			return false
		}
		if (userObject.privateDocs.find(doc => doc.id === id)) {
			return true
		} else return false
	}

	const getQuickAddOptions = (userObject) => {
		const quickAddList = firebase.firestore().collection("invoices").where("followers", "array-contains", currentUser.uid).get().then(snapshot => {
			if (snapshot.docs.length) {
				return snapshot.docs.map(doc => {
					const displayName = `${doc.data().billTo.uid === currentUser.uid ? "Bill" : "Invoice"} #${doc.data().invNumber}`
						return {
							...doc.data(), id: doc.id, displayName, path: doc.ref.path
						}
					})
				} else return ([])
			})
		return quickAddList
	}

	const getSearchResults = async (text, fd) => {
		let resultsList = []

		for (let i=0; i<formData.searchFilters.length; i++) {
			let newListItems = []

			const collName = formData.searchFilters[i]
			let firebaseQuery = firebase.firestore().collection(collName)/*.where("followers", "array-contains", userObject.uid)*/.where("searchKeywords", "array-contains", text).get()

			if (collName === "bills") {
				firebaseQuery = firebase.firestore().collection("invoices").where("billTo.uid", "==", currentUser.uid).where("searchKeywords", "array-contains", text).get()
			}

			await firebaseQuery.then(snapshot => {
				const docs = snapshot.docs
				if (docs.length) {
					snapshot.docs.forEach(doc => {
						console.log(doc.ref.path)
						const docData = doc.data()
						let displayName = doc.id

						if (collName === "invoices") {
							displayName = `Invoice #${docData.invNumber}`
						}
						if (collName === "bills") {
							displayName = `Bill #${docData.invNumber}`
						}
						if (collName === "contracts") {
							displayName = `Contract #${docData.invNumber}`
						}
						if (collName === "projects") {
							displayName = `Project: '${docData.projectName}'`
						}
						newListItems.push({
							...docData,
							id: doc.id,
							displayName,
							path: doc.ref.path
						})
					})
				} else {
					return ([])
				}
			}).catch(err => setErrorObj(err))
				resultsList = [...resultsList, ...newListItems]
		}

		// console.log(resultsList)
		// if (!resultsList.length) {
		// 	resultsList = [{id: "", displayName: "no results found"}]
		// }

		const res = Promise.all(resultsList)
		// console.log(resultsList)
		return await res
	}

	const handleSearchResults = (nameOrId, value, newFormData) => {
		setFormData(formData => ({...formData, [nameOrId]: value}))

			const searchResultsRes = async () => {
				const res = await getSearchResults(value, newFormData)
				const removeDuplicatesRes = []

				res.forEach(item => {
					const duplicate = removeDuplicatesRes.find(doc => doc.id === item.id)
					if (duplicate) {
						if (item.displayName.includes("Bill")) {
							removeDuplicatesRes.pop(duplicate)
							removeDuplicatesRes.push(item)
						}
					} else {
						removeDuplicatesRes.push(item)
					}
				})

				setSearchResults(removeDuplicatesRes)
			}
			searchResultsRes()
	}

	const handleChange = (nameOrId, value) => {
		if (nameOrId === "searchText") {
			handleSearchResults(nameOrId, value, formData)
		}

		else if (nameOrId.includes("searchFilters")) {
			let collectionName = nameOrId.slice(nameOrId.lastIndexOf("-") +1)
			let newSearchFilters = []
			if (!value) {
				// remove name from searchFilters
				newSearchFilters = formData.searchFilters.filter(collName => collName !== collectionName)
			} else {
				// add name to searchFilters
				newSearchFilters = [...formData.searchFilters, collectionName]
			}
			console.log(newSearchFilters)
			setFormData(formData => ({...formData, searchFilters: newSearchFilters}))
			// console.log("formData", formData)
			// handleSearchResults("searchText", formData.searchText, {...formData, searchFilters: newSearchFilters})

		} else { 
			if (nameOrId === "quickAddItem") {
				const quickAddObj = quickAddOptions.find(opt => opt.id === value)
				// const searchResObj = searchResults.find(doc => doc.id === value)
				// if (quickAddObj) {
				// 	setItemsToAdd(itemsToAdd => [...itemsToAdd, quickAddObj])
				// } else if (searchResObj) {
				// 	setItemsToAdd(itemsToAdd => [...itemsToAdd, searchResObj])
				// }
				setQuickAddItem(quickAddObj)
			}
			// setFormData(formData => ({...formData, [nameOrId]: value}))
		}
	}

	const handleAddToList = (e, value) => {
		e.preventDefault()
		// get the full object from the id (value) and add it to itemsToAdd
		const quickAddObj = quickAddOptions.find(opt => opt.id === value)
		const searchResObj = searchResults.find(doc => doc.id === value)
		// if (!displayName) {
		// 	console.log("no displayName", displayName)
		// }
		// const itemAlreadyListed = itemsToAdd.find(doc => doc.id === value)
		if (isItemAlreadyListed(value)) {
			console.log("itemAlreadyListed")
			return false
		}
		if (quickAddObj) {
			setItemsToAdd(itemsToAdd => [...itemsToAdd, quickAddObj])
		} else if (searchResObj) {
			setItemsToAdd(itemsToAdd => [...itemsToAdd, searchResObj])
		} else {
			console.log("value not found in quickAddOptions or in searchResults")
		}
	}

	const handleRemoveFromList = (e, value) => {
		e.preventDefault()
		// const itemAlreadyListed = itemsToAdd.find(doc => doc.id === value)
		if (!isItemAlreadyListed(value)) {
			console.log("cant remove an item not listed", value, itemsToAdd)
			return false
		}
		const newItemsToAdd = itemsToAdd.filter(item => item.id !== value)
		setItemsToAdd(newItemsToAdd)
	}

	const handleSubmit = (e, fd, itemsToAdd) => {
		e.preventDefault()
		// add the items
		// const thisProject = userObject.projects.find(proj => proj.id === projectId)
		if (!thisProject) {
			console.log("error this project is not listed on userObject", userObject, projectId)
		}

		let addedPrivateDocs = []
		itemsToAdd.forEach(({id, path}) => {
			// const isInPrivateDocsAlready = thisProject.privateDocs.find(doc => doc.id === id)
			if (isInPrivateDocsAlready(id)) {
				// could do an override window confirm here in case user wants a duplicate or the off chance of 2 docs with the same id
				console.log("ID in privateDocs already", userObject.privateDocs, itemsToAdd)
			} else {
				addedPrivateDocs.push({id, path})
			}
		})

		const newProjects = []
		if (usersProjects && usersProjects.length) {
			usersProjects.forEach(proj => {
				if (proj.id === projectId) {
					newProjects.push({...proj, privateDocs: [...proj.privateDocs, ...addedPrivateDocs]})
				} else {
					newProjects.push(proj)
				}
			})
			
		}
		// setUserObject(userObject => ({...userObject, projects: newProjects}))
		updateUserData({projects: newProjects}).then(() => {
			// setUserObject(userObject => ({...userObject, projects: newProjects}))
			setAddDocsModalOpen(false)
		})
		// find each item to add and add it to this users userObject in a place where 
		// it cam be retrreived easily when looking at project private docs
		
		// need to add a private items property to this project in userObject

	}

	useEffect(() => {
		if (currentUser) {
			const reqQuickAddOptions = async () => {
				const resQuickAddOptions = await getQuickAddOptions(userObject)
				if (resQuickAddOptions.length) {
					setQuickAddOptions([{name: "Select one", id: ""}, ...resQuickAddOptions])
				}
			} 
			reqQuickAddOptions()
		}
		if (formData.searchText) {
			handleSearchResults("searchText", formData.searchText, formData)
		}
		// eslint-disable-next-line
	}, [formData.searchFilters])


	const inputs = [
		{
			custom: true,
			label:"Quick Add",
			properties: {
				type: "dropdown",
				name: "quickAddItem",
				options: quickAddOptions,
				optionVal: "id",
				// value: formData.addItem,
				value: quickAddItem.id ? quickAddItem.id : "",
				onChange: handleChange,
				ref: quickAddDropdownRef
			}, 
			insideInputDiv:
				<React.Fragment>
				{
					quickAddDropdownRef.current 
					&& quickAddDropdownRef.current.value 
					&& !isItemAlreadyListed(quickAddDropdownRef.current.value) 
					&& !isInPrivateDocsAlready(quickAddDropdownRef.current.value) ?
					<button onClick={e => handleAddToList(e, quickAddDropdownRef.current.value)} className="button-appearance small-button">+ Add</button>
					: <span>&nbsp;{
						quickAddDropdownRef.current && quickAddDropdownRef.current.value ?
						<svg id="checkmark" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
					    <path d="M2 20 L12 28 30 4" />
						</svg>
						: null
					}
					</span>
				}
				</React.Fragment>,
			beforeInput: [
				<div key="select-items-title" className="section-title">Select Items</div>,
				<div key="select-items-line-break" className="small-line-break" />
			]
		},
		{
			label:"Search",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "searchText",
				value: formData.searchText,
				ref: useRef(null)
			}
		},
		{
			custom: true,
			customElements: 
			<div key="search-filters">
				{
					filterOptions.map(collName => {
						return (
							<label key={collName} className="inline-input">
								<input
									onChange={(e) => handleChange(`searchFilters-${collName}`, e.target.value)}
									type="checkbox"
									id={`searchFilters-${collName}`} // handleChange relies on "-"
									value={formData.searchFilters.includes(collName) ? "" : collName}
									checked={formData.searchFilters.includes(collName) ? true : false}
								/>
								{collName}
							</label>
						)
					})
				}
				<div key="searchResults">
					<div className="small-line-break" />
					Results: 
					{
						searchResults.length ? searchResults.map(doc => {
							// const itemToAddRef = itemsToAdd.find(item => item.id === doc.id)
							return (
								<div key={doc.id} className="flex-list">
									<div>- {doc.displayName}</div>
									{
										!isItemAlreadyListed(doc.id) && !isInPrivateDocsAlready(doc.id) ?
										<button onClick={e => handleAddToList(e, doc.id)} className="delete-or-add button-appearance small-button">+ Add</button>
										: <div className="delete-or-add" >
												<svg id="checkmark" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
											    <path d="M2 20 L12 28 30 4" />
												</svg>
										</div>
									}
								</div>
							)
						})
						: formData.searchText ? <div>No results found</div> : null
					}
				</div>
				<div className="section-divider" />
				<div className="section-title">Items To Add</div>
				<div className="small-line-break" />
				{
					itemsToAdd.length ? itemsToAdd.map(item => {
						return (
							<div key={item.id} className="flex-list">
								<div>- {item.displayName}</div>
								<button onClick={e => handleRemoveFromList(e, item.id)} className="delete-or-add button-appearance small-button">X</button>
							</div>
						)
					}) : <div>-- empty --</div>
				}
			</div>
		},
	]

	return (
		<Form
			inputs={inputs} 
			heading={
				<React.Fragment>
					<div className="heading">Add Private Docs</div>
					<AppMessage />
				</React.Fragment>
			} 
			submitName="Save"
			onSubmit={e => handleSubmit(e, formData, itemsToAdd)} 
		>
		</Form>
	)
}

export default AddPrivateProjectDocs






					// Results: 
					// {
					// 	searchResults.length ? searchResults.map(doc => {
					// 		const itemToAddRef = itemsToAdd.find(item => item.id === doc.id)
					// 		return (
					// 			<div key={doc.id}>
					// 				<label>
					// 					<input 
					// 						onChange={e => {
					// 							if (itemToAddRef) {
					// 								let newItemsToAdd = [...itemsToAdd]
					// 								newItemsToAdd.pop(itemToAddRef)
					// 								setItemsToAdd(newItemsToAdd)
					// 							} else {
					// 								setItemsToAdd(itemsToAdd => [...itemsToAdd, doc])
					// 							}
					// 						}}
					// 						type="checkbox" 
					// 						value={itemToAddRef ? "" : doc.id} 
					// 						checked={itemToAddRef ? true : false}
					// 					/>
					// 					- {doc.displayName}
					// 				</label>
					// 			</div>
					// 		)
					// 	})
					// 	: formData.searchText ? <div>No results found</div> : null
					// }