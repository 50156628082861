import { formulateSearchKeywords, industries } from "../../utils/appUtils";
import firebase from "../../firebase/index";


export 	const findAdminLevel2 = (results) => {
	// loop through all results until we can find a type of administrative_area_level_2
	let foundAdministrativeLevel2 = null
	for (let i=0; i<results.length; i++) {
		const res = results[i]
		let isTypeAdminLevel2 = res?.types?.includes("administrative_area_level_2")
		// console.log({AC: res.address_components})
		let inAddressComponents = res?.address_components?.find(obj => {
			return obj?.types?.includes("administrative_area_level_2")
		})
		if (inAddressComponents) {
			foundAdministrativeLevel2 = inAddressComponents.long_name // has long_name and short_name
			break;
		}
		if (isTypeAdminLevel2) {
			foundAdministrativeLevel2 = res.formatted_address
			break;
		}

	}

	return foundAdministrativeLevel2
}

export const getContractorIndustries = (industries, filters) => { 
	let contractorIndustries = []
	industries.forEach(ind => {
		// return industry, rate and specialties
		const specialties = ind.specialties ? ind.specialties.map(sp => ({name: sp, rate: ind.rate})) : []

		contractorIndustries.push(
			{
				name: ind.name,
				rate: ind.rate,
			},
			...specialties
		)
	})

	let matchedIndustry

	// find the first contractor industry that matches an industry filtered for
	// if no filters, take first industry
	if (!filters.length) {
		matchedIndustry = contractorIndustries[0]
	} else {
		for (let filter of filters) {
			const industryInContractorIndustries = contractorIndustries.find(ind => ind.name.toLowerCase() === filter.name.toLowerCase())
			if (industryInContractorIndustries) {
				matchedIndustry = industryInContractorIndustries
				break;
			}
		}
	}

	// if (!matchedIndustry) {
	// 	matchedIndustry =  {
	// 		name: "contracting",
	// 		rate: ""
	// 	}
	// }


	return {
		contractorIndustries,
		matchedIndustry
	}
}

// same as in the daily pubsub cloud function
export const getTopPickRating = (userObject, job) => {
	const addBudgetScore = (matchedIndustry, job) => {
		const userRate = parseFloat(matchedIndustry.rate)
		const jobBudgetRate = parseFloat(job.budget)

		if (isNaN(userRate) || isNaN(jobBudgetRate)) {
			return 0
		}

		let budgetScore = 0

		if (userRate < jobBudgetRate) {
			const difference = jobBudgetRate - userRate
			if (difference < 3) { // pretty close
				budgetScore += 25
			} else if (difference < 10) { // perfect range
				budgetScore += 30
			} else if (difference < 25) { // good range
				budgetScore += 15
			} else if (difference > 25) { // too low
				if (difference < 50) {
					budgetScore += 10
				} // else under budget
			}
		} else {
			const difference = userRate - jobBudgetRate

			if (difference < 5) {
				budgetScore += 20
			} else if (difference < 20) {
				budgetScore += 10
			} // else over budget
		}
		return budgetScore
	}

	let score = 0

	let {contractorIndustries, matchedIndustry} = getContractorIndustries(userObject.industries, [{name: job.industry}])

	if (matchedIndustry) {
		score += 25
		const isSpecialtyIndustry = !industries.find(ind => ind.name.toLowerCase() === matchedIndustry.name.toLowerCase())

		// specialty matches are worth more
		if (isSpecialtyIndustry) {
			score += 10
		}
	} else {
		// add score based on budget range
		// get average contractor rate and see how close it is 

		if (contractorIndustries.length) {
			let ratesSum = 0
			contractorIndustries.forEach(ind => {
				const rate = parseFloat(ind.rate)

				if (!isNaN(rate)) {
					ratesSum += parseFloat(ind.rate)
				}

			})

			const averageRate = Math.round(ratesSum / contractorIndustries.length)

			matchedIndustry = {
				name: "contracting",
				rate: averageRate,
			}

			// score += addBudgetScore(industryAverage, job)
			
		}

		// toDo
		// else could get an average of all of users invoices rate and industry types

	}

	if (!job.budgetPrivate) {
		if (matchedIndustry) {
			if (matchedIndustry.rate && job.budget && job.budgetIsHourly) {
				// score += 30 - (2 * jobBudgetRate - userRate)
				score += addBudgetScore(matchedIndustry, job)
			}

		}
		
	}

	// add in workType tags in photos

	// add in locations
	if (userObject.fullCityString && userObject.fullCityString === job.city) {
		score += 30
	} else if (userObject.otherLocationsServed) {

		if (userObject.otherLocationsServed.includes(job.city)) {
			score += 20
		}
	}

	// add in the job posters ratings
	return score
}

export const filterByIndustry = (filters, list) => {
	const newFilteredList = list.filter(j => {
		if (filters.length) {
			const filterNames = filters.map(f => f.name.toLowerCase())

			if (filterNames.includes(j.industry.toLowerCase())) {
				return true
			} else return false
		} else {
			return true
		}
	})

	return newFilteredList
}


export const filterContractorsByIndustry = (filters, list) => {
	const newFilteredList = list.filter(contractor => {
		if (filters.length) {

			const {matchedIndustry} = getContractorIndustries(contractor.industries, filters)

			const filterNames = filters.map(f => f.name.toLowerCase())

			if (matchedIndustry && filterNames.includes(matchedIndustry.name.toLowerCase())) {
				return true
			} else return false
		} else {
			return true
		}
	})

	return newFilteredList
}

export const getAddressDetails = (place) => {
	let city
	let state
	let country

	if (place.address_components) {
		place.address_components.forEach(component => {
			const type = component.types[0]
			const shortName = component.short_name
			if (type === "locality") {
				city = shortName
			}
			
			if (type === "administrative_area_level_1") {
				state = shortName
			}
			if (type === "country") {
				country = component.long_name
			}
		})
		// if the selected place is not within a city find nearest city

		if (!city) {
			const administrative_area_level_2 =  place.address_components.find(com => com.types.includes("administrative_area_level_2"))
			if (administrative_area_level_2) {
				city = administrative_area_level_2.short_name
			} else {
				const administrative_area_level_3 =  place.address_components.find(com => com.types.includes("administrative_area_level_3"))
				if (administrative_area_level_3) {
					city = administrative_area_level_3.short_name
				} else {
					city = ""
				}

			}

		}
	}

	const fullRegion = `${city}, ${state}, ${country}`

	if (!city) {
		// return blanks
		return {
			city: "",
			state: "",
			country: "",
			fullRegion: ""
		}
	} else {
		return {city, state, country, fullRegion}
	}

}

export const fetchPrivate = async ({userObject, postData}) => {
	if (!userObject.id || userObject.id !== postData.userId) {
		return []
	}

	return firebase.firestore().collection("marketPosts").doc(postData.id).collection("private")
		.get()
		.then(snap => {
			let newPrivateData = {}

			if (snap.docs.length) {
				snap.docs.forEach(doc => {
					newPrivateData[doc.id] = {...doc.data(), id: doc.id}
				})
			}

			return newPrivateData
		}).catch(err => {
			console.log("err in fetchPrivate...", JSON.stringify(err))
			throw err
		})


}

export const fetchAllInteractions = async ({userObject, postData}) => {
		let newInteractionsObj = {}

	// we dont need this for now
		// get the interactions subcollection in 2 queries one is public info and one is private
		// const publicInteractions = await firebase.firestore().collection("marketPosts").doc(postData.id).collection("interactions")
		// 	.where("visibility", "==", "public")
		// 	.get()
		// 	.then(snap => {
		// 		snap.docs.forEach(doc => {
		// 			newInteractionsObj[doc.id] = {...doc.data(), id: doc.id}
		// 		})
		// 	})
		if (userObject.id) {
			await firebase.firestore().collection("marketPosts").doc(postData.id).collection("interactions")
				.where("accessors", "array-contains", userObject.id)
				.get()
				.then(snap => {
					if (snap.docs.length) {

						snap.docs.forEach(doc => {
							if (!newInteractionsObj[doc.id]) {
								newInteractionsObj[doc.id] = {...doc.data(), id: doc.id}
							}
						})
					}

				}).catch(err => {
					console.log("err at fetchAllInteractions...", JSON.stringify(err))
					throw err
				})

		}

	return newInteractionsObj
}

const handleReadNotificationIfExists = (userObject, newPostData, personalNotifications) => {
	if (personalNotifications && personalNotifications.length) {
		let promises = []

		// only update if user has not read
		let relevantUnreadNotifications = personalNotifications.filter(n => {
			if (n.additional.post.id === newPostData.id) {
				return !n.read
			} else return false
		})

		// update the notification with the new post data as well
		for (let i=0; i<relevantUnreadNotifications.length; i++) {
			const notification = relevantUnreadNotifications[i]
			promises.push(
				firebase.firestore().collection("users").doc(userObject.id).collection("notifications").doc(notification.id).update({
					additional: {post: newPostData},
					read: true
				})
			)
		}

		return Promise.all(promises).catch(err => {
			console.error(err)
			console.log(JSON.stringify(err))
		})
	} else return
}

// note: will not update photos!
export const updatePost = async ({ newPostData, userObject, isUsersFirstInteraction, personalNotifications }) => {
	let cleanedPostData = {...newPostData}
	let allowedKeys = []
	const publicInteractionKeys = ["likedBy", "interestedUsers", "viewedBy"]

	const postRef = firebase.firestore().collection("marketPosts").doc(newPostData.id)
	// filter out any non permisible fields

	const userIsPoster = userObject.id === newPostData.userId
	if (!userObject.id) {
		let err = new Error(`Please Log in to interact with this post`)
		err.noReport = true
		throw err
	}

	if (!userIsPoster) {
		handleReadNotificationIfExists(userObject, newPostData, personalNotifications)
	}

	const batch = firebase.firestore().batch();

	// in firestore rules, users are only allowed to add or remove their own ID from the arrays
	// likedBy, interestedUsers or viewedBy
	// but in case there are concurent updates we need to only use arrayUnion
	publicInteractionKeys.forEach(item => {
		// add or remove user id from the arrays
		if (cleanedPostData[item]) {
			if (!cleanedPostData[item].includes) {
				console.error("cleanedPostData[item] is not an array")
			}
		}

		if (cleanedPostData[item] && cleanedPostData[item].includes(userObject.id)) {
			if (cleanedPostData[item].length > 1) {
				cleanedPostData[item] = firebase.firestore.FieldValue.arrayUnion(userObject.id)
			} // otherwise we must create the array. arrayUnion only adds if field exists
			// can just leave alone because cleanedPostData[item] should be an array
		} else {
			if (cleanedPostData[item]) {
				cleanedPostData[item] = firebase.firestore.FieldValue.arrayRemove(userObject.id)
				// if there is no cleanedPostData[item] in DB this should not error
			} // otherwise do nothing, this userId is not in the array
		}
	})

	if (userIsPoster) {
		// if user is poster all keys are allowed except timestamp
		// also remove keys that are subcollections: private and interaction data
		if (isUsersFirstInteraction) {
			allowedKeys = Object.keys(cleanedPostData).filter(k => (k !== "private" && k !== "interactions"))
		} else {
			allowedKeys = Object.keys(cleanedPostData).filter(k => (k !== "private" && k !== "timestamp" && k !== "interactions"))
		}

		cleanedPostData.searchKeywords = formulateSearchKeywords({
			doc: cleanedPostData,
			type: "marketPost",
			userObject,
		})
	} else {
		allowedKeys = [...publicInteractionKeys]
	}


	// update main post data
	Object.keys(cleanedPostData).forEach(k => {
		if (!allowedKeys.includes(k)) {
			delete cleanedPostData[k]
		}
	})

	batch.set(postRef, cleanedPostData, {merge: true})
	// await postRef.set(postDataToSend, {merge: true}).catch(err => {
	// 	console.log("err")
	// 	console.log(err)
	// 	throw err
	// })

	/// update private data
	if (userIsPoster) {
		// use newPostData since we removed the private data from cleanedPostData
		for (let key in newPostData.private) {
			const data = newPostData.private[key]
			batch.set(postRef.collection("private").doc(key), data, {merge: true});
			// await postRef.collection("private").doc(key).set(data, {merge: true}).catch(err => {
			// 	console.log("err")
			// 	throw err
			// })
		}

	}

	// update interactions
	if (newPostData.interactions) {
		// use newPostData since we removed the interactions data from cleanedPostData
		for (let userId in newPostData.interactions) {
			let cleanedInteractionsData = newPostData.interactions[userId] || {} // eg post[postId].interactions[userId]
			let includeOnlyInteractionKeys = [] 

			if (userObject.id === userId) { // current user is updating their own interaction
				// here if userIsPoster, user is interacting with their own post 

				// can update almost all keys... cant invite self or change timestamp (handled later on when making actual update)
				// timestamp is added back in later on if this is users first interation
				includeOnlyInteractionKeys = Object.keys(cleanedInteractionsData).filter(k => (k !== "invited"))

				// user can uninvite self or if user is poster they can uninvite another user
				if (cleanedInteractionsData.invited === false || userIsPoster) {
					includeOnlyInteractionKeys.push("invited")
				} 

			} else {
				// allow the poster to change invited status
				if (userIsPoster) {
					includeOnlyInteractionKeys = ["invited"]
					console.log("todo: only include the interaction objects that are being updated by the poster otherwise we update all the interactions invited key every time")
				}
			}
			



			if (Object.keys(cleanedInteractionsData).length) {

				// delete all keys not in includeOnlyInteractionKeys array
				for (let key in cleanedInteractionsData) { // delete all except allowed
					if (!includeOnlyInteractionKeys.includes(key)) {
						delete cleanedInteractionsData[key]
					}
				}
				// update timestamp or lastEdited
				// if this is the users first interaction with the post, allow timestamp update
				if (isUsersFirstInteraction) {
					cleanedInteractionsData.timestamp = firebase.firestore.Timestamp.now().seconds
					cleanedInteractionsData.lastEdited = firebase.firestore.Timestamp.now().seconds
				} else {
					cleanedInteractionsData.lastEdited = firebase.firestore.Timestamp.now().seconds
					delete cleanedInteractionsData.timestamp 
				}

				batch.set(postRef.collection("interactions").doc(userId), cleanedInteractionsData, {merge: true});
				// await postRef.collection("interactions").doc(userId).set(cleanedInteractionsData, {merge: true})
				// .catch(err => {
				// 	console.log("err")
				// 	console.log(err)
				// 	throw err
				// })
			}
		}
	}

	// return
	return batch.commit().catch(err => {
		console.log("err in updatePost...", JSON.stringify(err))
		throw err
	})
}

export const removeStorageItems = async (items, userObject) => {
	if (!items.length) {
		return true
	}
	if (!userObject.id) {
		throw new Error("Missing user paramater")
	}
	let deleteTasks = [];
	for (let i = 0; i < items.length; i++) {
		// remove from firebase if it exists
		const item = items[i];
		try {
			let storageRef = firebase
				.storage()
				.ref(`${userObject.id}/marketPosts/${item.id}`);
			// firebaseUserPostsDBRef.update({
			// 	photos: firebase.firestore.FieldValue.arrayRemove
			// })
			// .catch(err => setErrorObj(err))

			// update storage
			deleteTasks.push(storageRef.delete().catch(err => {
				console.log("err in removeStorageItems delete task...", JSON.stringify(err))
				throw err
			}));
		} catch (err) {
			if (err.code !== "storage/object-not-found") {
				console.log("item not found in storage:", item)
				// throw err;
			}
		}
	}
	return Promise.all(deleteTasks);
};


export const deletePost = async ({userObject, postData}) => {
	if (!userObject.id) {
		throw new Error("Please Log in to delete this post")
	}

	if (!postData.id) {
		throw new Error("No post ID found")	
	}

	const userIsPoster = postData.userId === userObject.id

	if (!userIsPoster) {
		throw new Error("Only the user that made this post can delete it")	
	}

	const postRef = firebase.firestore().collection("marketPosts").doc(postData.id)

	const batch = firebase.firestore().batch();

	// delete private data
	if (postData.private) {
		for (let key in postData.private) {
			batch.delete(postRef.collection("private").doc(key))
		}
	}

	// delete interactions data
	if (postData.interactions) {
		for (let key in postData.interactions) {
			batch.delete(postRef.collection("interactions").doc(key))
		}
	}

	// delete p[ost]
	batch.delete(postRef)

	// delete photos
	if (postData.photos.length) {
		await removeStorageItems(postData.photos, userObject).catch(err => {
			if (err.code !== "storage/object-not-found") {
				console.log("item not found in storage:", JSON.stringify(err))
			} else {
				throw err
			}
		})
	}

	return batch.commit().catch(err => {
		console.log("err in deletePost...", JSON.stringify(err))
		throw err
	})

}

export const fetchContractors = async ({ userObject, lastDoc, amount, mapCenter, extendedSearch }) => {
	// query based first on the adminLevel2 eg. Metro Vancouver or Capital Regional District or Strathcona
	// then query based on actual city if few results 
	let query = firebase.firestore().collection("users")
		.where("searchable", "==", true)
		.where("isCustomerOnly", "==", false)

	if (mapCenter.adminLevel2) {
		query = query.where("adminLevel2", "==", mapCenter.adminLevel2)
	} else {
		query = query.where("fullCityString", "==", mapCenter.fullRegion)
	}

	const snapshot = await query.get().catch(err => {
		console.log("err in fetchContractors fullCityString...", JSON.stringify(err))
		throw err
	})

	let docs = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))

	if (docs.length < 10) {
		// todo: search for fullCityString here ?  could be that this was just not picked up correctly in adminLevel2
		const otherLocationsServedSnap = await firebase.firestore().collection("users")
			.where("searchable", "==", true)
			.where("isCustomerOnly", "==", false)
			.where("otherLocationsServed", "array-contains", mapCenter.fullRegion)
			.get()
			.catch(err => {
				console.log("err in fetchContractors otherLocationsServedSnap...", JSON.stringify(err))
				throw err
			})

		let otherDocs = otherLocationsServedSnap.docs.map(doc => ({...doc.data(), id: doc.id}))
		otherDocs = otherDocs.filter(other => !docs.find(user => user.id === other.id))
		docs = [...docs, ...otherDocs]
	}

	// temp solution to users not having a generalLocation property
	docs = docs.map(d => {
		if (!d.generalLocation) {
			return {
				...d,
				generalLocation: getRandomCoordinatesNearby({...mapCenter.latlng, radius: 2000})
			}
		} else return d
	})

	return docs
}

const cleanJob = (doc) => {
	let job = {...doc}
	if (job.sections) {
		console.warn("Warning... job has sections!", {job})
		delete job.sections
	}
	if (job.recommendedScore) {
		delete job.recommendedScore
	}

	return job
}

export const fetchJobs = async ({ userObject, lastDoc, amount, mapCenter, extendedSearch, isAdmin }) => {
	// if (!mapCenter.place_id) {
	// 	console.error("no place_id", mapCenter)
	// 	return []
	// }
	let newLastDoc
	let docs = []
	let queryFinished = false

	if (extendedSearch) {
		if (!mapCenter.adminLevel2) {
			console.error("No mapCenter.adminLevel2 in fetchJobs")
			return {docs: [], lastDoc}
		}
		let query = firebase.firestore().collection("marketPosts")
			.where("visibility", "==", "public")
			.where("publiclySearchable", "==", true)
			// .where("place_id", "==", mapCenter.place_id)
			.where("adminLevel2", "==", mapCenter.adminLevel2)
			// .orderBy("date", "desc")

		if (isAdmin) {
			query = firebase.firestore().collection("marketPosts")
			.where("visibility", "==", "public")
			.where("adminLevel2", "==", mapCenter.adminLevel2)
		}

		if (lastDoc) {
			query = query.startAfter(lastDoc)
		}
		const snapshot = await query
			.limit(amount || 20)
			.get()
			.catch(err => {
				console.log("err in fetchJobs extended search...", JSON.stringify(err))
				throw err
			})

		docs = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, version: doc.data().version || 1}))
		newLastDoc = snapshot.docs[snapshot.docs.length - 1]

		docs = docs.map(doc => cleanJob(doc))

	} else {
		let snapshot
		if (isAdmin) {
			snapshot = await firebase.firestore().collection("marketPosts")
			.where("visibility", "==", "public")
			.where("city", "==", mapCenter.fullRegion)
			.get()
			.catch(err => {
				console.log("err in fetchJobs else, isAdmin...", JSON.stringify(err))
				throw err
			})
		} else {
			snapshot = await firebase.firestore().collection("marketPosts")
				.where("visibility", "==", "public")
				.where("publiclySearchable", "==", true)
				// .where("place_id", "==", mapCenter.place_id)
				.where("city", "==", mapCenter.fullRegion)
				.get()
				.catch(err => {
					console.log("err in fetchJobs search...", JSON.stringify(err))
					throw err
				})
		}
		
		docs = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, version: doc.data().version || 1}))
		newLastDoc = snapshot.docs[snapshot.docs.length - 1]

		docs = docs.map(doc => cleanJob(doc))

	}

	queryFinished = docs.length < amount

	return {
		docs,
		lastDoc: newLastDoc,
		queryFinished
	}
}

export const fetchUsersJobs = async ({userObject}) => {
	if (!userObject || !userObject.id) {
		return []
	}

	let docs = []
	const snapshot = await firebase.firestore().collection("marketPosts").where("accessors", "array-contains", userObject.id).get().catch(err => {
		console.log("err in fetchUsersJobs...", JSON.stringify(err))
		throw err
	})
	if (snapshot.docs.length) {
		docs = snapshot.docs.map(doc => ({...doc.data(), id: doc.id, version: doc.data().version || 1}))
		docs = docs.map(doc => cleanJob(doc))
	}

	return docs
}

export const fetchPost = ({postId}) => {
	return firebase.firestore().collection("marketPosts").doc(postId).get().then(doc => {
		if (doc && doc.exists) {
			return cleanJob({...doc.data(), id: doc.id, version: doc.data().version || 1})
		} else return null
	}).catch(err => {
		console.log("err in fetchPost...", JSON.stringify(err))
		if (err.message.includes("Missing or insufficient permissions")) {
			return null
		} else {
			throw err
		}
	})
}


export const getRandomCoordinatesNearby = ({lat, lng, radius = 1000}) => {
		if (radius === 0) {
			// round coords
			return {lat: Math.round(lat * 100000) / 100000, lng: Math.round(lng * 100000) / 100000}
		}

		function generateRandomPoint(center, radius) {
		  var x0 = center.lng;
		  var y0 = center.lat;
		  // Convert Radius from meters to degrees.
		  var rd = radius/111300;

		  var u = Math.random();
		  var v = Math.random();

		  var w = rd * Math.sqrt(u);
		  var t = 2 * Math.PI * v;
		  var x = w * Math.cos(t);
		  var y = w * Math.sin(t);

		  var xp = x/Math.cos(y0);

		  // Resulting point.
		  const lat = Math.round((y+y0) * 100000) / 100000
		  const lng = Math.round((xp+x0) * 100000) / 100000
		  return {lat, lng};
		}

		const randomCoords = generateRandomPoint({lat, lng}, radius)
		return randomCoords
	}
