import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import Form from "./Forms/Form";
import Modal from "./Modal/Modal";

import firebase from "../firebase/index";




// http://localhost:3000/unsubscribe?user=Sj7zD4934qinF2XE28SN&type=newFeatures&key=KhYUiuBI1KtV0i47tM54


const EmailPreferences = ({ query, history }) => {
	const {
		userObject,
		setErrorObj,
		isFetching,
		updateUserData,
		setIsFetching,
		handleSetSuccessText,
		setSettingsModalOpen,
	} = useContext(UserContext);

	const subscriptionTypes = [
		{ name: "New message reminders", type: "newMessageReminders" },
		{
			name: "Unread notification reminders",
			type: "unreadNotificationReminders",
		},
		{ name: "New features", type: "newFeatures" },
		{ name: "Local Construction news/updates", type: "constructionNews" },
		{ name: "New marketplace job posts", type: "jobPosts" },
		// { name: "Early access requests", type: "earlyAccessRequests" },
	];

	const [subscribeStatus, setSubscribeStatus] = useState("Unsubscribe");
	const [emailPreferences, setEmailPreferences] = useState({
		newMessageReminders: true,
		unreadNotificationReminders: true,
		newFeatures: true,
		constructionNews: true,
		noEmails: false,
		jobPosts: true,
	});

	const key = query.get("key");
	const user = query.get("user");
	const type = query.get("type");
	const resubscribe = query.get("resubscribe");

	let subscriptionName = "email service";
	let typeFound = subscriptionTypes.find((t) => t.type === type);
	if (typeFound) {
		subscriptionName = typeFound.name;
	} else if (type === "unsubscribeAll") {
		subscriptionName = "All";
	}

	useEffect(() => {
		if (userObject.id && userObject.emailPreferences && !user) {
			setEmailPreferences((emailPreferences) => ({
				...emailPreferences,
				...userObject.emailPreferences,
			}));
		}
	}, [userObject.id, userObject.emailPreferences, user]);

	useEffect(() => {
		if (user) {
			const handleUnsubscribe = firebase
				.functions()
				.httpsCallable("handleUnsubscribe");

			if (resubscribe === "true") {
				setSubscribeStatus("Resubscribing to " + subscriptionName + "...");
				if (!isFetching) {
					setIsFetching(true);
				}

				handleUnsubscribe({ user, key, type, resubscribe: true })
					.then((res) => {
						history.replace(history.location.pathname);
						setIsFetching(false);
						setSubscribeStatus(
							"Successfully resubscribed to: " + subscriptionName
						);
						handleSetSuccessText("Successfully resubscribed, thank you!");
					})
					.catch((err) => {
						setIsFetching(false);
						setErrorObj(err)
						history.replace(history.location.pathname);
						if (!firebase.auth().currentUser) {
							setErrorObj(err);
							setSubscribeStatus(
								"Oops there was an error! We are very sorry, please contact us if this problem persists!"
							);
						}
					});

			} else {
				setSubscribeStatus("Unsubscribing from " + subscriptionName + "...");
				if (!isFetching) {
					setIsFetching(true);
				}

				handleUnsubscribe({ user, key, type, resubscribe: false })
					.then((res) => {
						history.replace(history.location.pathname);
						setIsFetching(false);
						setSubscribeStatus(
							"Successfully unsubscribed from: " + subscriptionName
						);
						handleSetSuccessText("Successfully unsubscribed");
					})
					.catch((err) => {
						setIsFetching(false);
						console.error(err);
						history.replace(history.location.pathname);
						if (!firebase.auth().currentUser) {
							setErrorObj(err);
							setSubscribeStatus(
								"Oops there was an error! We are very sorry, please contact us if this problem persists!"
							);
						}
					});

			}
		}
		// eslint-disable-next-line
	}, []);

	const handleChange = (nameOrId, value) => {
		// if (nameOrId === "noEmails") {
		// 	setEmailPreferences(emailPreferences => {
		// 		let newPreferences = {}
		// 		for (let key in emailPreferences) {
		// 			const val = emailPreferences[key]
		// 			if (key === "noEmails") {
		// 				newPreferences.key = true
		// 			} else {
		// 				newPreferences.key = false
		// 			}
		// 		}
		// 		return newPreferences
		// 	})
		// }
		setEmailPreferences((emailPreferences) => ({
			...emailPreferences,
			[nameOrId]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (isFetching) {
			return;
		} else {
			setIsFetching(true);
		}

		updateUserData({
			emailPreferences,
		}, false, "EmailPreferences - handleSubmit")
			.then((res) => {
				setIsFetching(false);
				handleSetSuccessText("Email preferences updated successfully!");
				setSettingsModalOpen({open: true});
			})
			.catch((err) => {
				setIsFetching(false);
				setErrorObj(err);
			});
	};

	let inputs = [];

	if (userObject.id && !user) {
		subscriptionTypes.forEach(({ name, type }) => {
			inputs.push({
				label: name,
				onChange: handleChange,
				properties: {
					type: "checkbox",
					id: type,
					value: !emailPreferences[type],
					checked: emailPreferences[type],
					disabled: emailPreferences.noEmails,
				},
			});
		});

		// add in unsubscribe all input
		inputs = [
			...inputs,
			{
				label: "Unsubscribe all",
				onChange: handleChange,
				beforeInput: [
					<div key="before-unsubscribe-all" className="section-divider" />,
				],
				properties: {
					type: "checkbox",
					id: "noEmails",
					value: !emailPreferences.noEmails,
					checked: emailPreferences.noEmails,
				},
			},
		];
	}

	// if user (the first query param) present then it is a direct ubnsubscribe action
	if (userObject.id && !user) {
		return (
			// return the form to updat email preferences
			<Modal containerHeading="Update Email Preferences">
				<Form
					heading=""
					submitName="Update"
					onSubmit={handleSubmit}
					inputs={inputs}
				></Form>
			</Modal>
		);
	} else {
		return (
			<Modal>
				<h2>{subscribeStatus}</h2>
				<p>
					<Link to={"?action=promptLogin"}>Log in</Link> to update{" "}
					{user ? "more " : ""}email preferences
				</p>
			</Modal>
		);
	}
};

export default EmailPreferences;
