import React, {
	useState,
	useEffect,
	useContext,
	useRef,
} from "react";
import styled from 'styled-components';
import { useLocation } from "react-router-dom";

import { AppMessageContext } from "../contexts/AppMessageContext";
import { UserContext } from "../contexts/UserContext";
// import {
// 	providerIsFacebookOrGoogle,
// } from '../utils/appUtils';

import firebase from "../firebase/index";

const AppMessageStyle = styled.div `
	text-align: center;
	> div {
	  font-size: var(--font-size-s);
		> div {
			> div {
				width: 95%; 
				> div {
					padding-left: 5%;
				}
			}
			position: relative; 
			.error-text {
			  color: red;
			  text-overflow: ellipsis;
			  overflow: hidden;
			  white-space: pre;
			}
			.error-text:hover {
			  white-space: normal;
			  overflow: visible;
			}
		  .clear-error {
		  	position: absolute;
				padding: 4px 7px 7px 7px;
				right: 0;
				top: 0;
		  }
		  .visible {
			  opacity: 0.8;
			  z-index: unset;
			}
			.hidden {
			  opacity: 0;
			  z-index: -1;
			  transition: opacity 3s linear;
			}

		}
	}

`

export const AppMessage = ({ className, dependants, scrollIntoView }) => {
	const { pathname } = useLocation();
	const windowLocationHref = window.location.href;

	const appMessageRef = useRef(null);
	const isMountedRef = useRef(null);
	let dependantsArray = dependants || [];
	const {
		successText,
		// setSuccessText,
		textClassName,
		setTextClassName,
		messageClassName,
		setMessageClassName,
		// timeout,
		errorText,
		setErrorText,
		errorObj,
		setErrorObj,
		handleSetSuccessText,
		message,
		handleSetMessage,
		isFetching,
	} = useContext(AppMessageContext);

	const { userObject } = useContext(UserContext);

	const [errorSubmitted, setErrorSubmitted] = useState(false);
	const [erroredOnPage, setErroredOnPage] = useState({
		pathname: "",
		href: "",
	});
	const [alreadySetMessages, setAlreadySetMessages] = useState([]);

	const user = firebase.auth().currentUser;

	const handleSubmitError = async (copyOfErrorObj, errorTextArg) => {
		// only send error if setErroredOnPage has finished setting
		let errorText = errorTextArg;
		if (!errorText) {
			errorText = copyOfErrorObj.message || "no message";
		}
		errorText = errorText.toLocaleLowerCase();
		if (errorText.slice(0, 10).includes("error")) {
			errorText = errorText.replace("error", "");
			if (errorText.slice(0, 10).includes(":")) {
				errorText = errorText.replace(":", "");
			}
		}

		errorText = errorText.trim();

		setErroredOnPage({ pathname: "", href: "" });
		setErrorText("");

		const docName = `${erroredOnPage.pathname}=${errorText.slice(0, 20)}`;

		if (
			windowLocationHref.includes("192.168") ||
			windowLocationHref.includes("localhost")
		) {
			console.log(
				"Not sending this error to db because href includes localhost || 192.168: ",
				{
					errorText,
					type: copyOfErrorObj.type || "",
					code: copyOfErrorObj.code || "",
					name: copyOfErrorObj.name || "",
					stack: copyOfErrorObj.stack || "",
					json: copyOfErrorObj.json || "",
					message: copyOfErrorObj.message || errorText || "no message",
					pathname: erroredOnPage.pathname,
					href: erroredOnPage.href,
					metadata: copyOfErrorObj.metadata || {},
					...(user && user.uid) ? {affectedUsers: firebase.firestore.FieldValue.arrayUnion(user.uid)} : {}
				}
			);

			setErrorObj(null);
		} else
			return firebase
				.firestore()
				.collection("errorLogs")
				.doc(docName)
				.set(
					{
						errorText,
						type: copyOfErrorObj.type || "",
						code: copyOfErrorObj.code || "",
						name: copyOfErrorObj.name || "",
						stack: copyOfErrorObj.stack || "",
						json: copyOfErrorObj.json || "",
						message: copyOfErrorObj.message || "no message",
						lastInstance: firebase.firestore.FieldValue.serverTimestamp(),
						occurance: firebase.firestore.FieldValue.increment(1),
						pathname: erroredOnPage.pathname,
						href: erroredOnPage.href,
						metadata: copyOfErrorObj.metadata || {},
						...(user && user.uid) ? {affectedUsers: firebase.firestore.FieldValue.arrayUnion(user.uid)} : {}
					},
					{ merge: true }
				)
				.then(() => {
					setErrorObj(null);
				});

	};

	const ignoreThisError = (errorObjAsObject) => {
		const exemptions = [
			{
				// error happens when user offline and tries to delete from storage and doesnt connect for a while
				message:
					"Firebase Storage: Max retry time for operation exceeded, please try again.",
				noReport: true,
			},
		];

		for (let i = 0; 1 < exemptions.length; i++) {
			const exempt = exemptions[i];

			// check for exemptions and upload them to db if !noReport
			if (errorObjAsObject.message === exempt.message) {
				return true;
			}
		}
	};

	const findErrorText = (errorObjAsObject) => {
		if (errorObjAsObject.message && !ignoreThisError(errorObjAsObject)) {
			let newErrorText = errorObjAsObject.message;
			// if (errorObjAsObject.code && errorObjAsObject.code === "invalid-argument" && errorObjAsObject.details !== "invalid_request_error") { // invalid_request_error is thrown from a stripe related cloud function
			// 	newErrorText =
			// 		"Error: A problem occurred while retrieving/adding information in Database";
			// }
			// add the word "Error" before the message if it doesnt already exist
			if (errorObjAsObject.code === "invalid-argument") {
				newErrorText = "Unknown database error"
			}
			if (errorObjAsObject.code === "permission-denied") {
				// newErrorText = ""
				return ""
			}
			const errorMessageIncludesString = newErrorText
				.toLocaleLowerCase()
				.slice(0, 10)
				.includes("error");
			// let newErrorText = errorMessageIncludesString ? errorObjAsObject.message : `Error: ${errorObj.message}`
			if (!errorMessageIncludesString) {
				newErrorText = `Error: ${newErrorText}`;
			}
			newErrorText = newErrorText.replace(/firebase/g, "database")
			newErrorText = newErrorText.replace(/Firebase/g, "Database")

			return newErrorText;
		} else {
			// dont show error text
			return "";
		}
	};

	// track whether the component is mounted to prevent memory leaks
	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		let errorObjAsObject = {}
		// if (
		// 	windowLocationHref.includes("192.168") ||
		// 	windowLocationHref.includes("localhost")
		// ) {
			// if (errorObj ) {
			// 	console.log("throwing error... DEV only")
			// 	throw errorObj
			// }			
		// }
		// to throw the error in dev
		// if (errorObj ) {
		// 	console.log("throwing error... DEV only")
		// 	throw errorObj
		// }
		// if there is an errorObject and it is not empty
		if (errorObj && (errorObj.message || errorObj.code || errorObj.name || errorObj.type)) {
			errorObjAsObject.json = JSON.stringify(errorObj)
			errorObjAsObject.stack = errorObj.captureStackTrace ? errorObj.captureStackTrace() : null
			errorObjAsObject.message = errorObj.message || "Unknown error"
			errorObjAsObject.code = errorObj.code || ""
			errorObjAsObject.type = errorObj.type || ""
			errorObjAsObject.name = errorObj.name || ""
			errorObjAsObject.noReport = errorObj.noReport || false
			// as soon as errorObj grab a copy
			handleSetMessage("");
			const newErrorText = findErrorText(errorObjAsObject) 
			if (newErrorText) {
				setErrorText(newErrorText); // findErrorText could return ""
			}
			// errored on...
			setErroredOnPage((erroredOnPage) => ({
				pathname: pathname.replace(/\//g, "-"),
				href: encodeURIComponent(windowLocationHref),
			}));
			if (scrollIntoView !== false) {
				appMessageRef.current.scrollIntoView(false);
			}
		} else {
			setErroredOnPage({ pathname: "", href: "" });
			try {
				let json = JSON.stringify(errorObj)
				errorObjAsObject.json = json

			} catch (err) {
				console.log("Cannot stringify error: ", errorObj)
			}
		}

		return () => {
			const copyOfErrorObj = { ...errorObjAsObject };
			if (copyOfErrorObj && Object.keys(copyOfErrorObj).length > 1) { // should always be an errorObj.json
				// wait until copyOfWeeoeObj is found
				if (!errorSubmitted && !copyOfErrorObj.noReport) {
					handleSubmitError(
						copyOfErrorObj,
						errorText || findErrorText(copyOfErrorObj)
					);
					setErrorSubmitted(true);
				} else {
					setErrorText("");
					setErrorObj(null);
				}
			} else {
				setErrorText("");
				setErrorSubmitted(false);
			}
			// setTextClassName("visible");
			// clearTimeout(timeout);
			// setSuccessText("");
		};
		// eslint-disable-next-line
	}, [errorObj, isFetching, pathname, windowLocationHref, ...dependantsArray]);



	// toDo: find a way to make the below work, when users sign up, success text is shown then immediatly hidden because of some state change
	// just remove the clearing of successText for now

	// // reset successText on pathname change or error or in dependants
	// useEffect(() => {
	//   return () => {
	//   	if (isMountedRef.current) {
	// 			setTextClassName("visible");
	// 			clearTimeout(timeout);
	// 			setSuccessText("");
	//   	}
	//   }
	//   // esLint-disable-next-line
	// }, [pathname, errorObj, ...dependantsArray])

	useEffect(() => {
		if (user && userObject.id) {
			const creationTime = new Date(user.metadata.creationTime).getTime();
			// if more than 5 secconds has gone by the user is not a "new user" for the purpose of setting the welcome success text
			// should change this to an account created success page in future
			const isNewUser = new Date().getTime() - creationTime < 20000;
			if (!isFetching && isNewUser && !alreadySetMessages.includes("welcome")) {
				if (user.isAnonymous) {
					handleSetSuccessText(
					"You are now using a sample/demo account",
					8000
				);
				} else {
					handleSetSuccessText(
						"Account created successfully! Welcome " +
							(userObject.name || userObject.firstnane || user.email),
						8000
					);
				}
				setAlreadySetMessages((alreadySetMessages) => [
					...alreadySetMessages,
					"welcome",
				]);
			}

			// if (!user.emailVerified && !providerIsFacebookOrGoogle(user) && !user.isAnonymous) {
			// 	// dont be annoying with verify email reminders
			// 	// only set message if not a brand new user
			// 	if (!alreadySetMessages.includes("verifyEmail") && !isNewUser) {
			// 		handleSetMessage(
			// 			<div>
			// 				Please verify email
			// 				<div
			// 					onClick={() => {
			// 						user
			// 							.sendEmailVerification()
			// 							.then(() => {
			// 								handleSetSuccessText("Email verification link sent");
			// 							})
			// 							.catch((err) => setErrorObj(err));
			// 					}}
			// 					className="link-appearance"
			// 				>
			// 					Re-send verification link to {user.email}
			// 				</div>
			// 			</div>
			// 		);
			// 		setAlreadySetMessages((alreadySetMessages) => [
			// 			...alreadySetMessages,
			// 			"verifyEmail",
			// 		]);
			// 	}
			// }
		}
	}, [
		user,
		userObject,
		alreadySetMessages,
		setAlreadySetMessages,
		handleSetSuccessText,
		setErrorObj,
		handleSetMessage,
		isFetching,
	]);

	return (
		<AppMessageStyle>
			<div className={className || ""} ref={appMessageRef}>
				<div className={messageClassName}>
					<div>
						{
							successText && 
							<div className={textClassName}>{successText}</div>
						}
						{
							errorText &&
							<div className={`error-text ${errorText ? "visible" : "hidden"}`}>
								{errorText}
							</div>
						}
						{message.component || ""}
					</div>
					{(successText || errorText || message.component) && (
						<svg
							className="clear-error"
							onClick={() => {
								if (successText) {
									handleSetSuccessText("");
								}
								if (errorText) {
									setErrorObj({});
								}
								if (message.component) {
									handleSetMessage("");
								}
								setMessageClassName("hidden")
								setTextClassName("hidden")
							}}
							viewBox="0 0 32 32"
							width="10px"
							height="10px"
							fill="none"
							stroke="currentcolor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="3"
						>
							<path d="M2 30 L30 2 M30 30 L2 2" />
						</svg>
					)}
				</div>
			</div>
		</AppMessageStyle>	
	);
};
