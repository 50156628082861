import React from 'react'

import { DotActionStyle } from './DotActionsStyle.styled'

const DotActions = ({children, options}) => {
	return (
		<DotActionStyle>
			<details className="main">
				<summary>
						<svg className="dots-icon" viewBox="0 0 32 16" fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
					    <circle cx="7" cy="8" r="2" fill="black" />
					    <circle cx="16" cy="8" r="2" fill="black" />
					    <circle cx="25" cy="8" r="2" fill="black" />
						</svg>
				</summary>
				{
					options.ul ? 
					<ul>
						{children}
					</ul>
					: children
				}
			</details>
		</DotActionStyle>
	)
}

export default DotActions

						// <svg id="close-icon" viewBox="0 0 32 32" fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
					 //    <path d="M2 30 L30 2 M30 30 L2 2" />
						// </svg>

					// children.map((c, i) => {
					// 	let properties = c.properties
					// 	if (!properties) {
					// 		properties = {}
					// 	}

					// 	return (
					// 		<li key={i} {...properties}>
					// 			{c.children}
					// 		</li>
					// 	)
					// })