import React from 'react'
import styled from "styled-components";
import MoreInfoIcon from '../components/MoreInfoIcon'


// import  CountDownTimer from '../components/CountDownTimer'
// import Countdown from 'react-countdown';

const PricingStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
	}

	table {
		// display: flex;
		text-align: center;
		width: 100%;
		margin-top: 50px;
		margin-bottom: 100px;
		border: none;
    border-collapse: collapse;
		tbody {
		}
		tr {
			text-align: center;
			// display: flex;
			// justify-content: space-between;
		}
		tr:nth-child(odd) {
		background-color: var(--titan-white);
		}
		td {
	    padding: 0 10px;
	    border-style : hidden!important;
		}
		td:last-child {
			text-align: right;
		}
		td:first-child {	
			text-align: left;
		}
	}
	h2 {
		font-size: var(--font-size-xl);
		margin-bottom: 100px;
	}
	h3 {
		text-align: left;
		font-size: var(--font-size-ll);
		// margin: 0;
	}
	h4 {
		font-size: var(--font-size-l);
		margin: 0;
	}
	// .promotion {
	// 	background-color: red;
 //    font-style: italic;
 //    color: var(--white-text);
 //    box-shadow: 0 0 30px -12px;
 //    border-radius: 10px;
	// }

	.info {
		font-style: italic;
	}
	// .countdown-timer {
	// 	margin-top: 20px;
	// }
`;


const Pricing = ({userObject, setLoginModalOpen}) => {
	// const july1InMs = 1656658800000
	// const promotionActive = Date.now() < july1InMs

	return (
		<PricingStyle>
			<div className="main-container">
				<h2>
					Pricing
				</h2>
				<h3>
					{"Verified Invoice Payments "}
    			<MoreInfoIcon
						absolute={true}
						text="When your customers add a payment to your invoice"
						// custom={"bottom: -50%"}
					/>
				</h3>
				<table>
					<tbody>
						<tr>
							<td>Cash</td>
							<td></td>
							<td>Free</td>
						</tr>
						<tr>
							<td>E-transfer</td>
							<td></td>
							<td>Free</td>
						</tr>						
						<tr>
							<td>Cheque</td>
							<td></td>
							<td>Free</td>
						</tr>						
						<tr>
							<td>Credit, Debit</td>
							<td></td>
							<td><a href="https://stripe.com/en-ca/pricing" target="_blank" rel="noopener noreferrer">Stripe Fees (approx: 1% - 2.9%) </a> + 1%</td>
						</tr>
					</tbody>
				</table>

				<h3>
					Storage
				</h3>
				<table>
					<tbody>
						<tr>
							<td>Invoices</td>
							<td>Unlimited</td>
							<td>Free</td>
						</tr>
						<tr>
							<td>Projects</td>
							<td>Unlimited</td>
							<td>Free</td>
						</tr>
						<tr>
							<td>Photos &<br />Receipts</td>
							<td>First 2 GB <MoreInfoIcon
									absolute={true}
									text="Don't worry that's like 2000 pictures!"
								/>
							</td>
							<td>Free</td>
						</tr>
						<tr>
							<td>Photos &<br />Receipts</td>
							<td>
								per GB over 2 GB
							</td>
							<td>
								$2/month 
							</td>
						</tr>
					</tbody>
				</table>
{/*				<p className="info">When your customers add a payment to your invoice</p>
				<p>Cash, E-transfer, Cheque: FREE!</p>
				<p>Credit, Debit: <a href="https://stripe.com/en-ca/pricing">Stripe Fees (approx: 1% - 2.9%) </a> + 1%</p>

				{/*<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />

				<h3>
					Storage
				</h3>
				<p className="info">Photos, files and other storage</p>
				<p>First 200MB (about 200 photos): FREE!</p>
				<p>Over 200MB: $1.99/month for every additional 200MB</p>*/}
			</div>
		</PricingStyle>
	)
}

export default Pricing
