export const msInAMinute = 1000 * 60;
export const msInAnHour = msInAMinute * 60
export const msInADay = msInAnHour * 24

const pad = (num) => {
	const normalized = Math.floor(Math.abs(num));
	return (normalized < 10 ? "0" : "") + normalized;
};

export const getLocalISODate = (fullISO, dateString) => {
	// returns a local date in format yyy-mm-ddThh:mm:ss:ssss[TIMEZONEOFFSET]
	// the fullISO of this date string is the correct date and can be stored and re used in a new Date(fullISO) to get the real date and time
	let d;
	if (dateString) {
		// dont do below because it it adding then subtracting timezone offset...?
		// const tzo = new Date().getTimezoneOffset()  * 60000
		// d = new Date(new Date(dateString).valueOf() + tzo)
		d = new Date(dateString);
	} else {
		d = new Date();
	}

	const timezoneOffset = -d.getTimezoneOffset();
	const timezoneOffsetMS = timezoneOffset * 60000;
	const plusMinus = timezoneOffset >= "0" ? "+" : "-";
	const tzoPaddedHours = pad(timezoneOffset / 60);
	const tzoPaddedRemainderHours = pad(timezoneOffset % 60);

	const paddedTimezoneOffsetHours = `${plusMinus}${tzoPaddedHours}${tzoPaddedRemainderHours}`;
	const localFullISODate = new Date(d.valueOf() + timezoneOffsetMS)
		.toISOString()
		.replace("Z", paddedTimezoneOffsetHours);

	if (fullISO) {
		return localFullISODate;
	} else {
		const indexOfT = localFullISODate.search("T");
		return localFullISODate.slice(0, indexOfT);
	}
};

// return 24hr time
export const extractTimeFromDate = (date) => {
	let d;
	if (date) {
		d = new Date(date);
	} else {
		d = new Date();
	}

	const localFullISODate = getLocalISODate(true, d.valueOf());

	const indexOfT = localFullISODate.search("T");
	const resultHours = localFullISODate.slice(indexOfT + 1, indexOfT + 3);
	const resultMinutes = localFullISODate.slice(indexOfT + 4, indexOfT + 6);

	return `${resultHours}:${resultMinutes}`;
};

export const convertToAMPM = (time) => {
	const timeAsArray = time.split(":");
	const hour = timeAsArray[0];
	const minute = timeAsArray[1];
	if (parseFloat(hour) === 0) {
		return `12:${minute} AM`;
	} else if (parseFloat(hour) === 12) {
		return `12: ${minute} PM`;
	} else if (parseFloat(hour) > 12) {
		return `${parseFloat(hour) - 12}: ${minute} PM`;
	} else {
		return `${time} AM`;
	}
};

const daysOfWeek = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

export const getFriendlyDate = (timestamp, type) => {
	let resultFriendlyName = "";
	const nowSeconds = Date.now() / 1000;
	const secondsInAWeek = 6 * 24 * 60 * 60; // not including last week today
	const secondsInADay = 23 * 60 * 60; // not including the current hour
	const wasThisWeek = nowSeconds - timestamp < secondsInAWeek;
	const wasToday = nowSeconds - timestamp < secondsInADay;
	const timestampInMs = timestamp * 1000;
	if (wasToday || type === "time") {
		if (type === "noTime") {
			resultFriendlyName = "Today"
		} else {
			resultFriendlyName = convertToAMPM(extractTimeFromDate(timestampInMs));
		}
	} else if (wasThisWeek) {
		resultFriendlyName = daysOfWeek[new Date(timestampInMs).getDay()];
	} else {
		resultFriendlyName = getLocalISODate(false, timestampInMs);
	}

	return resultFriendlyName;
};

export 	const getTimeRemaining = (endTime) => {
	const nowTime = Date.now()
	let days = 0
	let hours = 0
	let minutes = 0
	let seconds = 0


	const initMsRemaining = endTime - nowTime
	let msLeft = initMsRemaining

	if (endTime > nowTime) {
		days = Math.floor(msLeft / msInADay) 
		msLeft = (msLeft % msInADay)

		hours = Math.floor(msLeft / msInAnHour)
		msLeft = (msLeft % msInAnHour)

		minutes = Math.floor(msLeft / msInAMinute)
		msLeft = (msLeft % msInAMinute)

		seconds = Math.floor(msLeft / 1000)
		msLeft = (msLeft % 1000) 
	}

	return {
		days: pad(days),
		hours: pad(hours),
		minutes: pad(minutes),
		seconds: pad(seconds)
	}
}

