import React, { useState, useEffect, useRef } from 'react'
import styled from "styled-components";
import he from 'he';

import { MarkerClusterer } from "@googlemaps/markerclusterer";
// import { Wrapper } from "@googlemaps/react-wrapper";

import { getRandomCoordinatesNearby, getContractorIndustries } from './utils'
import { getFriendlyDate } from '../../utils/dateUtils'
import { industries } from "../../utils/appUtils";
import { getStarsAsHtmlString, getUserRating } from '../UserProfile/UserRating'

import MapControls from './MapControls';


const MarketplaceMapStyle = styled.div `
  overflow: hidden;
  height: 520px;
  position: relative;
  color: var(--chicago);

	${({ custom }) => custom && custom};

  .info-window {
  	h2 {
  		margin: 0;
  	}
  	.stars-and-card-info-container {
  		width: 100%;
  		overflow: hidden;
  	}
  	.user-stars {
			background-color: white;
			position: static;
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		  text-wrap: nowrap;
		  // display: block;
  		.stars {
  			svg {
  				height: 18px;
  				width: 18px;
  			}
  		}
  		.rating-text {
  			font-size: var(--font-size-xs);
  			margin: 0;
  			padding: 0 1px;
			  align-self: center;
  		}
  	}
  	.user-stars.no-rating {
  		.stars { 
  			svg {
  				fill: var(--nav-item-hover);
  				color: var(--nav-item-hover);
  			}
  		}
  	}
  	.user-stars.high-rating {
  		.rating-text {
  			background-color: var(--brand-green);			
  	    border-radius: 10px;
  	    padding: 0 7px;
  		}
  	}
  	.user-stars.expert-rating {
  		.rating-text {
  			background-color: var(--brand);			
  	    color: white;
  	    border-radius: 10px;
  	    padding: 0 7px;
  		}
  	}
	  .industry-tag {
	  	.material-icons {
	  		// color: var(--light-gray);
	  		font-size: var(--font-size-s);
	  		margin: 0 2px 0 0;
  		  vertical-align: bottom;
	  	}
	  	// margin: 0;
  	  background-color: var(--nav-item-hover);
		  font-size: 12px;
		  // padding: 0 5px 1px 5px;
		  padding: 2px 5px 1px 5px;
		  // margin: 2px 5px;
		  margin: 2px;

		  display: inline-block;
	  }
	  .cardUserInfo-container {
	  	display: flex;
  	  column-gap: 10px;
  	  width: 100%;
  	  .cardInfo-text {
  	    // text-overflow: ellipsis;
			  // white-space: nowrap;
			  // display: block;
			  p {
				  margin: 0;
    	    text-overflow: ellipsis;
				  overflow: hidden;	
			  }
  	  }
	  	.user-photo {
	  		width: 60px;;
	  		height: 60px;
	  		border-radius: 50%;
  		  background: transparent;
  		  margin-left: 2px; //to allow box-shadow
			  box-shadow: 0px 0px 10px -2px;
	  		img {
		  		border-radius: 50%;
		  		width: 60px;
				  height: 60px;
	  		}
	  	}
	  }
  }
  #map {
	  position: relative;
	  overflow: hidden;
	  height: 100%;
  }

  
  .job-marker:after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #FFFFFF;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 95%;
    transform: translate(-50%, 0);
    transition: all 0.3s ease-out;
    width: 0;
    z-index: 1;
  }
  .job-marker {
  	align-items: center;
  	background-color: #FFFFFF;
  	border-radius: 50%;
  	color: #263238;
  	display: flex;
  	font-size: 14px;
  	gap: 15px;
  	justify-content: center;
  	padding: 4px;
  	position: relative;
  	position: relative;
  	transition: all 0.3s ease-out;

  	.icon {
		  align-items: center;
		  display: flex;
		  justify-content: center;
	    color: var(--chicago);
		  fill: red;
		  svg {
			  height: 20px;
			  width: auto;
			}
		}

		.card {
			display: none;
			flex-direction: column;
			flex: 1;
		}

	  pointer-events: all;
  	height: 31px;
  	width: 31px;
  }
  .expand:after {
  	border-top: 9px solid #FFFFFF;
  }
  .expand {

  	background-color: #FFFFFF;
  	border-radius: 8px;
  	box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  	height: 80px;
  	padding: 8px 15px;
  	width: auto;
  	.card {
  		display: flex;
  	}

  }
`

const makeMarkers = ({ setSelectedMarker, allFiltered, isLoadingMarkers, setIsLoadingMarkers, map, setErrorObj, userObject, mapCenter, filters, recentlyViewedJobs, recentlyViewedContractors}) => {
	let newMarkers = []
	allFiltered.forEach((obj, i) => {
		const priceTagPath = "M2 4 L30 4 30 23 20 23 16 29 12 23 2 23 Z"
		// if the user doesnt have a general location, return a random one
		// this should never be the case as long as we are handling the userSettings set location okay
		let position = obj?.generalLocation 
		if (!position || !position.lat || !position.lng) {
			position = getRandomCoordinatesNearby({...obj.centerOfMainCityServed, radius: 3000})
		}
		// dont do this as it causes pins to be generated based on mapcenter
		// if (!obj.generalLocation?.lat || !!obj.generalLocation?.lng) {				
		// 	position = getRandomCoordinatesNearby({...mapCenter.latlng, radius: 2000})
		// }

		if (!position || !position.lat || !position.lng) { // dont add the marker
			// worse case fallback... should never happen because of update to user settings modal that adds a city coordinates
			if (mapCenter.fullRegion && obj.fullCityString) {
				// show the user if the map's city is the same as any of the cities served in the user profile
				// let allLocationsServed = [obj.fullCityString, ...obj.otherLocationsServed]
				// show a pin if the map city name is the same as the main city of the user
				const usersCityCleaned = obj.fullCityString.toLowerCase()
				const mapCenterCleaned = mapCenter.fullRegion.toLowerCase()
				if (usersCityCleaned === mapCenterCleaned) {
					position = getRandomCoordinatesNearby({...mapCenter.latlng, radius: 2000})
				}
			}
		}

		// if still no position this is an error, set the users location to earth center
		if (!position || !position.lat || !position.lng) {
			console.warn("Cant find contractor position, setting to 0, 0")
			// console.log({obj})
			position = {
				lat: 0,
				lng: 0
			}
		}

		let industry
		let infoWindowTitle
		let markerColor

		if (obj.markerType === "job") {
			markerColor = "#20e037" 
			if (!obj.publiclySearchable) {
				markerColor = "#7920e0"
			}
			const jobInRecentlyViewed = recentlyViewedJobs.find(jId => jId === obj.id)
			if (jobInRecentlyViewed) {
				// markerColor = "#20E0E9" // this color is --brand-green-visited
				markerColor = "#6be97a" // this color is --brand-green-visited
			}

			if (userObject.id === obj.userId) {
				markerColor = "#a9a9a9"
			}

			industry = industries.find(ind => ind.name.toLowerCase() === obj.industry.toLowerCase())
			if (!industry) {
				industry = {
					name: obj.industry,
					formattedName: obj.industry
				}
			}
      infoWindowTitle = industry.formattedName


			let budgetNumber = ""
			if (obj.budget) {
				budgetNumber = parseInt(obj.budget)
			}

			if (obj.budget > 10000) {
				budgetNumber = "9999+"
			}
			if (budgetNumber) {
	      infoWindowTitle += "-" + budgetNumber.toString()
			}
			
		}

		if (obj.markerType === "contractor") {
			markerColor = "#2069e0"
			// changing marker color with below not working well because would have to re render markers which closes infoWindow
			// const contractorInRecentlyViewed = recentlyViewedContractors.find(id => id === obj.id)
			// if (contractorInRecentlyViewed) {
			// 	// markerColor = "#3979e1" // this color is --brand-blue-visited
			// 	markerColor = "#84b3ff" // this color is --brand-blue-visited
			// }

			const {matchedIndustry} = getContractorIndustries(obj.industries, filters)
			industry = matchedIndustry ? industries.find(ind => ind.name.toLowerCase() === matchedIndustry.name.toLowerCase()) : null

			if (!industry) {
				industry = {
					name: "Contracting",
					rate: "",
					formattedName: "Contracting"
				}
			}

			let rate = (matchedIndustry && matchedIndustry.rate) ? parseInt(matchedIndustry.rate) : ""

			infoWindowTitle = obj.companyName || obj.username

			if (rate > 10000) {
				rate = "9999+"
			}

			if (rate) {
				infoWindowTitle += "-" + rate.toString()
			}

		}

		const industryIcon = industry ? (industry.icon || "\uf10b") : "\uf10b"

		const labelOrigin = new window.google.maps.Point(16, 17)

		const svgMarker = {
	    path: priceTagPath,
	    fillColor: markerColor,
	    fillOpacity: 1,
	    strokeWeight: 7,
	    strokeColor: markerColor,
	    strokeOpacity: 1,
	    rotation: 0,
	    scale: 1.5,
	    anchor: new window.google.maps.Point(16, 32),
	    labelOrigin
	  };

    const marker = new window.google.maps.Marker({

      position,
      // map,
      label: {
        text: industryIcon,
        fontFamily: "Material Icons",
        color: "#e9edff",
        fontSize: "18px",
      },
      icon: svgMarker,
      title: infoWindowTitle, //do not css HE encode because this is just used for css and in tooltip encoding not necessary 
      id: obj.id,
      version: obj.version || 1,
      // zIndex: Number(google.maps.Marker.MAX_ZINDEX) + i,
      // animation: google.maps.Animation.iB
    // }
    })

    marker.addListener("click", () => {
      setSelectedMarker(marker)
    });

	  newMarkers.push(marker)

	})

	return newMarkers

}

const MarketplaceMap = ({ 
	isLessThan700px,
	postId,
	allFiltered,
	contractors,
	filteredContractors,
	searchingContractors,
	searchingJobs,
	filters,
	filteredJobs,
	changePostId,
	children, 
	viewingPost, 
	setViewingPost, 
	isLoadingMarkers, 
	setIsLoadingMarkers, 
	map, 
	jobs, 
	mapCenter, 
	setErrorObj, 
	userObject,

	showFilters, 
	setShowFilters,
	handleChange,
	formData,
	industriesFilter,
	setFilters,
	handleChangeCity, 
	setMapCenter,
}) => {

	const infoWindow = useRef(null)
	const seeMoreRef = useRef(null)
	const allMarkerCluster = useRef(null)
	const [selectedMarker, setSelectedMarker] = useState(null)
	const [mapClickListener, setMapClickListener] = useState(null)
	// const [allMarkerCluster, setAllMarkerCluster] = useState(null)
	const [makrerTitle, setMarkerTitle] = useState("title")
	const [shouldUpdateMarkers, setShouldUpdateMarkers] = useState(false)
	const [hasViewedPost, setHasViewedPost] = useState(false)
	
	const [recentlyViewedJobs, setRecentlyViewedJobs] = useState([])	
	const [recentlyViewedContractors, setRecentlyViewedContractors] = useState([])
	const [budgetStyles, setBudgetStyles] = useState('')

	useEffect(() => {
		if (!hasViewedPost && map && postId) { 
			// const inJobs = jobs.find(j => j.id === postId)
			const inContractors = contractors.find(c => c.id === postId)
			// only set this marker to the current marker for contractors since we open up a
			// modal for viewing posts if postId 
				if (inContractors && allMarkerCluster.current && allMarkerCluster.current.markers.length) {
				const foundMarker = allMarkerCluster.current.markers.find(m => m.id === postId)
				// const foundContractor = contractors.find(c => c.id === postId)

				if (foundMarker) {
					setSelectedMarker(foundMarker)
					setHasViewedPost(true)
					changePostId(null)		
				}
			}
		}

	}, [hasViewedPost, map, postId, contractors, changePostId])



	// need to update markers every time lookingFor changes
	useEffect(() => {
		if (map && !shouldUpdateMarkers) {
		  setShouldUpdateMarkers(true)
		}
		// dont include map or setShouldUpdateMarkers because that will cause unwanted setShouldUpdateMarkers
		// eslint-disable-next-line
	}, [searchingJobs, searchingContractors])

  useEffect(() => {
    if (map) {
    	setMapClickListener(map.addListener("mousedown", () => {
    		if (infoWindow.current) {
	    		infoWindow.current.close()
    		}

    		setSelectedMarker(null)
    	}))
    }
    return () => {
    	if (mapClickListener) {
		  	window.google.maps.event.removeListener(mapClickListener)    	
    	}
    }
    // dont add setMapClickListener because this useEffect runs setMapClickListener
    // eslint-disable-next-line
  }, [map])


  if (map) {
  	if (allFiltered.length) {
	  	if (!allMarkerCluster.current || allFiltered.length !== allMarkerCluster.current.markers.length) {
	  		setShouldUpdateMarkers(true)
	  	} else {  		
	  		// check the versions to see if the markers should be changed

	  		for (let key in allMarkerCluster.current.markers) {
	  			const m = allMarkerCluster.current.markers[key]
	  			const inAllFiltered = allFiltered.find(obj => obj.id === m.id)
	  			if (!inAllFiltered) {
	  				setShouldUpdateMarkers(true)
	  				break
	  				// below causes infinite loop and is not necessary as far as i can see we dont need to update if version changes
	  			// } else {
	  			// 	if (inAllFiltered.markerType === "job" && parseInt(inAllFiltered.version) !== parseInt(m.version)) {
	  			// 		console.log("sertting because not in allFiltered job")
	  			// 		setShouldUpdateMarkers(true)
	  			// 		break
	  			// 	}
	  			}

	  		}

	  	}
  		
  	} else { // no allFiltered length
  		if (filters.length && allMarkerCluster.current.markers.length) {
  			// reset the markers
				setShouldUpdateMarkers(true)
  		}
  	}
  	
  }


	// handle init infoWindows and add markers if new jobs
  if (shouldUpdateMarkers) {
  	setShouldUpdateMarkers(false)
  	// setIsLoadingMarkers(true)
  	const allMarkers = makeMarkers({setSelectedMarker, allFiltered, isLoadingMarkers, setIsLoadingMarkers, map, setErrorObj, userObject, mapCenter, filters, recentlyViewedJobs, recentlyViewedContractors })

    // if (allMarkerCluster) {
    // 	// jobsMarkerCluster.clearMarkers()
    // 	allMarkerCluster.reset()
    // 	allMarkerCluster.map = null
    // }


    if (!allMarkerCluster.current) {
	    const newAllMarkerCluster = new MarkerClusterer({map, markers: allMarkers})
	    allMarkerCluster.current = newAllMarkerCluster
    } else {

    	let markersToAdd = []
    	let markersToRemove = []

    	allMarkers.forEach(m => {
    		const inCurrentMarkers = allMarkerCluster.current.markers.find(marker => marker.id === m.id)
    		if (!inCurrentMarkers) {
    			markersToAdd.push(m)
    		}
    	})

    	allMarkers.forEach(m => {
    		const inCurrentMarkers = allMarkerCluster.current.markers.find(marker => marker.id === m.id)
    		if (!inCurrentMarkers) {
    			markersToAdd.push(m)
    		}
    	})

    	allMarkerCluster.current.markers.forEach(m => {
    		const markerInNewMarkers = allMarkers.find(marker => marker.id === m.id)
    		if (!markerInNewMarkers) {
    			markersToRemove.push(m)
    		}
    	})

    	allMarkerCluster.current.addMarkers(markersToAdd)
    	allMarkerCluster.current.removeMarkers(markersToRemove)


    	// allMarkerCluster.current.markers = allMarkers
    	// must use setMap here to trigger the MarkerCluster render() function
    	// allMarkerCluster.current.setMap(map)
    	// allMarkerCluster.current.map = map


    	// if (infoWindow.current && infoWindow.current.anchor && infoWindow.current.anchor.id) {
    	// 	const markerInNewMarkers = allMarkerCluster.current.markers.find(m => m.id === infoWindow.current.anchor.id)
    	// 	// infoWindow.current.setMap(map)

    	// 	if (markerInNewMarkers) {
	    // 		infoWindow.current.anchor = markerInNewMarkers
    	// 	}

	    // 	console.log({infoWindow, markerInNewMarkers})

    	// }
    }
    // setAllMarkerCluster(newAllMarkerCluster)

		// use default algorithm and renderer
  	// setJobMarkers(jobMarkers)
  	// need to open and close the window so that we can attach a click 
  	// event listener to the info window later on
    if (!infoWindow.current) { // set default data
				const defaultData = {
					budget: "",
					title: "Job",
					body: "Job post body",
					industry: "Contracting",
					timestamp: Date.now() / 1000,
					id: "default"
				}

			let timeSincePosted = getFriendlyDate(defaultData.timestamp, "noTime") + " at " + getFriendlyDate(defaultData.timestamp, "time")
			// <span class="material-icons" >\uf05b</span><strong>Budget</strong> $${budgetNumber} ${job.budgetIsHourly ? "/h" : "total"}						
			const contentString = `
				<div class="info-window">
					<h2>${defaultData.title}</h2>
					<div><span class="info-tag" >${defaultData.industry}</span></div>
					<p>${defaultData.body}</p>
					<div>
						<span id="see-more-${defaultData.id}" name="postId=${defaultData.id}" class="link-appearance tiny" >See More</span>
					</div>
					<br />
					<div class="post-time">${timeSincePosted}</div>
					
				</div>
			`
	  	// need to open and close the window so that we can attach a click 
	  	// event listener to the info window later on
			infoWindow.current = new window.google.maps.InfoWindow({
		    content: contentString,
		    ariaLabel: defaultData.title,
		    maxWidth: 300,
		    minWidth: 285,
		  });
	    infoWindow.current.id = defaultData.id

		  // infoWindow.current.open({
	    //   anchor: marker,
	    //   map,
	    // });

		  // infoWindow.current.close()
    }
  }

  // if ((selectedMarker && !infoWindowObj) || (selectedMarker && selectedMarker.job.id !== infoWindowObj.id)) {
  if (selectedMarker && map) {
  	// const {marker} = selectedMarker
		const id = selectedMarker.id
		const jobInJobs = jobs.find(j => j.id === id)
		const foundContractor = contractors.find(c => c.id === id)	

		// handle contractors
		if (foundContractor) {
			setRecentlyViewedContractors([
				id,
				...recentlyViewedContractors.filter(uid => uid !== id)
			])
			let {contractorIndustries, matchedIndustry} = getContractorIndustries(foundContractor.industries, filters)
			contractorIndustries = contractorIndustries.map(ind => {
				const indInIndustries = industries.find(industry => industry.name?.toLowerCase() === ind.name?.toLowerCase())
				if (indInIndustries) {
					return {
						...ind,
						...indInIndustries
					}
				} else {
					return {
						...ind,
						...ind.formattedName ? {} : {formattedName: ind.name}
					}
				}
			})

			let accountCreatedSeconds = Math.round(Date.now() / 1000) // default

			const d = new Date(foundContractor.accountCreated)
			if (!isNaN(d.valueOf())) {
				accountCreatedSeconds = Math.round(d.valueOf() / 1000)
			}
			// const accountCreatedSeconds = new Date(foundContractor.accountCreated).valueOf() / 1000

			let timeSincePosted = "With Linvo since: " + getFriendlyDate(accountCreatedSeconds, "noTime")

			let rateText = matchedIndustry && matchedIndustry.rate ? `$${matchedIndustry.rate}/h +` : ""
			// if there are no filters getContractorIndustries returns the first industry
			if (matchedIndustry && !filters.length) {
				let rates = contractorIndustries.map(obj => obj.rate ? parseFloat(obj.rate) : "")
				rates = rates.filter(r => r)
				rates = rates.sort((a,b) => a-b)
				// if there is more than one rate and they vary
				if (rates.length > 1 && rates[0] !== rates[rates.length - 1]) {
					const lowRate = rates[0]
					const highRate = rates[rates.length - 1]
					rateText = `$${lowRate} - $${highRate} /h`
				}
			}

			if (!matchedIndustry) {
				matchedIndustry = {
					name: "Industry unspecified",
					formattedName: "Industry unspecified",
					rate: ""
				}
			}


			let bodyComponents = ''
			let cardUserInfo = foundContractor.cardUserInfo
			if (!cardUserInfo) {
				cardUserInfo = {
					rows: contractorIndustries.length,
					val: "- " + contractorIndustries.map(ind => he.encode(ind.formattedName))
				}
			}
			if (cardUserInfo && cardUserInfo.val && cardUserInfo.val.length) {
				cardUserInfo.val.slice(0,5).forEach(item => {
					bodyComponents += `<p>${he.encode(item)}</p>`
				})
			}

			const title = he.encode(foundContractor.companyName || foundContractor.username)

			const {
				overallStars,
				ratingText,
				ratingClassName,
				// ratings,
			} = getUserRating(foundContractor, "contractorRating")
			let starsComponents = getStarsAsHtmlString(overallStars)

			const contentString = `
				<div class="info-window">
					<h2>${title}</h2>
					<br />
					<div class="cardUserInfo-container">
						<div class="user-photo">
							<img src="${he.encode(foundContractor.logoUrl) || '/assets/blank-profile-picture.png'}">
						</div>

						<div class="stars-and-card-info-container">
							<div class="${`user-stars ${he.encode(ratingClassName)}`}" >
								${starsComponents}
								${
									ratingText ? `<span class="rating-text">
										${he.encode(ratingText)}
									</span>`
									: ``
								}
							</div>
							<div class="cardInfo-text">
								${bodyComponents}
							</div>
						</div>
					</div>
					<p>
						${contractorIndustries && contractorIndustries.length ?
							contractorIndustries.map(ind => `<span class="industry-tag" >${ind.icon ? `<span class="material-icons">${he.encode(ind.icon)}</span>` : ""}${he.encode(ind.formattedName)}</span>`
							).join(" ")
							: ""
						}
					</p>
					${
						rateText ? `
						<p>
							<strong>Rate</strong> ${he.encode(rateText)}
						</p>
						`
						: ""
					}
					<p>
						<a href="${window.location.origin}/users/${foundContractor.username}" class="link-appearance tiny" target="_blank" rel="noopener noreferrer" >View Profile</a>
					</p>
					<br />
					<div class="post-time">${he.encode(timeSincePosted)}</div>
					<div class="post-time"><span class="material-icons">\ue0c8</span>Approximate ~ up to 2km</div>
				</div>
			`
	    if (!infoWindow.current) {
				infoWindow.current = new window.google.maps.InfoWindow({
			    content: contentString,
			    ariaLabel: title,
			    maxWidth: 300,
			  });
		    infoWindow.current.id = id

	    } else {
	  		infoWindow.current.setContent(contentString)
	  		infoWindow.current.setPosition(selectedMarker.position)
	  		infoWindow.current.setMap(map)

		    infoWindow.current.ariaLabel = title
		    infoWindow.current.id = id
		    // seeMoreRef.current = null
	    }

		  infoWindow.current.open({
	      anchor: selectedMarker,
	      map,
	    });

			setSelectedMarker(null)

		}


		// handle Jobs
		if (jobInJobs) {
			let budgetNumber = ""

			if (jobInJobs.budget) {
				budgetNumber = parseInt(jobInJobs.budget)
			}

			if (jobInJobs.budget >= 1000) {
				budgetNumber = "999+"
			}

			if (budgetNumber) {
				budgetNumber = budgetNumber.toString()
			} else {
				budgetNumber = ""
			}

			let timeSincePosted = getFriendlyDate(jobInJobs.timestamp, "noTime") + " at " + getFriendlyDate(jobInJobs.timestamp, "time")
			const userIsPoster = userObject.id && userObject.id === jobInJobs.userId
			let foundIndustry = industries.find(ind => ind.name.toLowerCase() === jobInJobs.industry.toLowerCase())
			if (!foundIndustry) {
				foundIndustry = {
					name: jobInJobs.industry,
					formattedName: jobInJobs.industry,
					icon: "",
				}
			}
			const contentString = `
				<div class="info-window">
					<h2>${he.encode(jobInJobs.title)}</h2>
					<div class="post-time"><span class="material-icons">\ue0c8</span>Approximate ~ up to 2km</div>
					<br />
					<div>
						<span class="industry-tag" >${foundIndustry.icon ? `<span class="material-icons">${he.encode(foundIndustry.icon)}</span>` : ""}${he.encode(foundIndustry.formattedName)}</span>
					</div>
					${
						budgetNumber ? `
						<p>
							<strong>Budget</strong> $${he.encode(budgetNumber)} ${jobInJobs.budgetIsHourly ? "/h" : "total"}
						</p>
						`
						: ""
					}
					<p>${he.encode(jobInJobs.body)}</p>
					<div>
						<span id="see-more-${id}" name="postId=${id}" class="link-appearance tiny" >${userIsPoster ? "View My Job" : "See More"}</span>
					</div>
					<br />
					<div class="post-time">Posted ${he.encode(timeSincePosted)}</div>
					<div class="post-time">by ${userIsPoster ? "me" : "@" + he.encode(jobInJobs.username)}</div>
					
				</div>
			`

	    if (!infoWindow.current) {
				infoWindow.current = new window.google.maps.InfoWindow({
			    content: contentString,
			    ariaLabel: jobInJobs.title,
			    maxWidth: 300,
			  });
		    infoWindow.current.id = jobInJobs.id

	    } else {
	  		infoWindow.current.setContent(contentString)
	  		infoWindow.current.setPosition(selectedMarker.position)
		    infoWindow.current.ariaLabel = jobInJobs.title
		    infoWindow.current.id = jobInJobs.id
		    // seeMoreRef.current = null
	    }

		  infoWindow.current.open({
	      anchor: selectedMarker,
	      map,
	    });

			setSelectedMarker(null)
		}

  }

	const handleSeeMoreClicked = (e) => {
		const el = e.target
		const elId = el.id.replace("see-more-", "")

		const post = jobs.find(p => p.id === elId)
		const toggleFSButton = document.querySelector(".gm-fullscreen-control")

		// exit full screen mode if it is in full screen
		let isFullScreen = false
    if (document.fullscreenElement ) {
	    isFullScreen = document.fullscreenElement
    } else if (document.mozFullScreenElement ) {
	    isFullScreen = document.mozFullScreenElement
    } else if (document.webkitFullscreenElement ) {
	    isFullScreen = document.webkitFullscreenElement
    } else if (document.msFullscreenElement  ) {
	    isFullScreen = document.msFullscreenElement
    }

		if (toggleFSButton && isFullScreen) {
			toggleFSButton.click()	
		}

		if (post) {
  		setViewingPost(post)
  		// setPostModalOpen(true)
  		changePostId(post.id, "jobs")
			setRecentlyViewedJobs([
				post.id,
				...recentlyViewedJobs.filter(id => id !== post.id)
			])
		}
	}

  if (infoWindow.current) {
  	// remove the listeners
  	if (seeMoreRef.current) {
  		const elId = seeMoreRef.current.id.replace("see-more-", "")

  		if (elId !== infoWindow.current.id || selectedMarker) {
  			seeMoreRef.current.removeEventListener("click", handleSeeMoreClicked)
  			seeMoreRef.current = null
  		}
  	}
  	// add listener
  	if (!seeMoreRef.current) {
	    const seeMore = document.getElementById(`see-more-${infoWindow.current.id}`)
	    if (seeMore) {
	    	seeMoreRef.current = seeMore
	    	seeMore.addEventListener("click", handleSeeMoreClicked)
	    }
  	}
  }

  // let titleTimer
  // useEffect(() => {
  //   if (makrerTitle === "title") {
  //   	titleTimer = setTimeout(() => {
  //   		setMarkerTitle("budget")
  //   	}, 3000)
  //   }

  //   return () => {
  //   	if (titleTimer) {
  //   		clearTimeout(titleTimer)
  //   	}
  //   }
  // }, [makrerTitle])

  // let budgetTimer
  // useEffect(() => {
  //   if (makrerTitle === "budget") {
  //   	budgetTimer = setTimeout(() => {
  //   		setMarkerTitle("title")
  //   	}, 3000)
  //   }

  //   return () => {
  //   	if (titleTimer) {
  //   		clearTimeout(budgetTimer)
  //   	}
  //   }
  // }, [makrerTitle])

  const newBudgetStyles = allFiltered.map(obj => {
  	let industry = ""
		let budgetNumber = ""
		let cssTitle = ""
		let content = ""

  	if (obj.markerType === "job") {
			industry = industries.find(ind => ind.name.toLowerCase() === obj.industry.toLowerCase())

			if (!industry) {
				industry = {
					name: obj.industry,
					formattedName: obj.industry
				}
			}

			if (obj.budget) {
				budgetNumber = parseInt(obj.budget || 0)
			}

			if (budgetNumber > 10000) {
				budgetNumber = "9999+"
			}


  		cssTitle = `${industry.formattedName}`
  		if (budgetNumber) {
				budgetNumber = budgetNumber.toString()
  			cssTitle = `${industry.formattedName}-${budgetNumber}`
				content = obj.budgetIsHourly ? "$" + budgetNumber + "/h" : "$" + budgetNumber
  		}

			if (makrerTitle === "title") {
				content = obj.industry
			}
			if (!budgetNumber) {
				content = obj.industry
			}
  		
  	} else if (obj.markerType === "contractor") {
			const {matchedIndustry} = getContractorIndustries(obj.industries, filters)

			industry = matchedIndustry ? industries.find(ind => ind.name.toLowerCase() === matchedIndustry.name.toLowerCase()) : null

			const contractorName = obj.companyName || obj.username

			if (!industry) {
				industry = {
					name: "Contracting",
					rate: "",
				}
			}

			let rate = (matchedIndustry && matchedIndustry.rate) ? parseInt(matchedIndustry.rate) : ""

			if (rate > 10000) {
				rate = "9999+"
			}

  		cssTitle = contractorName
  		if (rate) {
				rate = rate.toString()
  			cssTitle = `${contractorName}-${rate}`
				content = "$" + rate + "/h"
  		}

  		// markerTitle is to alternate between title and rate
			if (makrerTitle === "title") {
				content = contractorName
			}
			if (!rate) {
				content = contractorName
			}


  	} else {
  		console.log("could not find property markerType", obj)
  	}

		// content = content.toLowerCase() === "hvac" ? "HVAC" : content 
  	// cssTitle = he.encode(cssTitle || "")
  	cssTitle = cssTitle || "" // do not css HE encode because this is just used for css and in tooltip encoding not necessary 
  	content = he.encode(content || "")

		return `
  		div[title="${cssTitle}"]:before {
  			content: "${content}";
				font-size: 8px;
				color: var(--titan-white);
				text-align: center;
				opacity: 1;
			  text-overflow: ellipsis;
			  white-space: nowrap;
			  display: block;
			  ${isLessThan700px ? 'margin: 0 auto 0 auto' : 'margin: 0 auto 2px auto'};
			  overflow: hidden;
			  position: absolute;
			  width: 8ch;
			  display: inline-block;

			  bottom: 66%;
			  left: 0;
			  right: 0;
				line-height: 8px;
				border: none;
				padding: 0;
  		}

		`

  }).join(" ").trim()

  if (newBudgetStyles && newBudgetStyles !== budgetStyles) {
  	setBudgetStyles(newBudgetStyles)
  }

  return (
    <MarketplaceMapStyle custom={budgetStyles} >
    	<MapControls
    		allFiltered={allFiltered}
	    	contractors={contractors}
				filteredContractors={filteredContractors}
				searchingContractors={searchingContractors}
				searchingJobs={searchingJobs}
	    	showFilters={showFilters} 
	    	setShowFilters={setShowFilters}
	    	isLoadingMarkers={isLoadingMarkers}
	    	handleChange={handleChange}
	    	map={map}
	    	jobs={jobs}
	    	formData={formData}
	    	industriesFilter={industriesFilter}
	    	filters={filters} 
	    	setFilters={setFilters}
	    	filteredJobs={filteredJobs} 
	    	handleChangeCity={handleChangeCity} 
				setMapCenter={setMapCenter}

				infoWindow={infoWindow}
				setSelectedMarker={setSelectedMarker}
				selectedMarker={selectedMarker}
    	/>
    	{children}
    </MarketplaceMapStyle>
  )
}

export default MarketplaceMap
