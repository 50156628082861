import React, { useState, useContext, useEffect, useRef } from "react";

import { AppMessageContext } from "./AppMessageContext";
import { DialogContext } from "./DialogContext";

import firebase from "../firebase/index";
import { logUserOut, addNewUserToDB, addPermissions, getUserContactsGoogle } from "../firebase/auth";
import { getNewProfileFilePath } from '../utils/appUtils'


const UserContext = React.createContext();

// errors and successText handled
const UserContextProvider = ({ children }) => {
	const { dialog } = useContext(DialogContext);
	const {
		handleSetErrorObj,
		setErrorObj,
		handleSetSuccessText,
		offlineMode,
		setOfflineMode,
		isFetching,
		setIsFetching,
		handleSetMessage,
		doNotTerminateFetching,
		setDoNotTerminateFetching,
	} = useContext(AppMessageContext);

	const defaultUserData = {
		cardUserInfo: {
			rows: 0,
			val: [],
		},
		companyName: "",
		username: "",
		companyEmail: "",
		gstNumber: "",
		currentProject: null,
		email: "",
		id: "",
		industryTitle: "",
		industries: [],
		lastInvNumber: "",
		firstname: "",
		lastname: "",
		phone: "",
		logoPath: "",
		logoUrl: "",
		searchable: "",
		recentlyViewedDocs: null,
		// address: {
		// 	city: "",
		// 	country: "",
		// 	line1: "",
		// 	line2: "",
		// 	postal_code: "",
		// 	state: ""
		// },
		emailPreferences: {
			constructionNews: true,
			newFeatures: true,
			newMessageReminders: true,
			noEmails: false,
			unreadNotificationReminders: true,
			jobPosts: true,
		},
    isCustomerOnly: true,
		roles: {
			generalContractor: false,
			subContractor: false,
		},
		contractorRating: {
			allRatings: 0,
			stars: 0,
			verifiedRatings: 0,
		},
		contractorKeywords: [],
		customerRating: {
			allRatings: 0,
			stars: 0,
			verifiedRatings: 0,
		},
		fullCityString: "",
		adminLevel2: "",
		otherLocationsServed: [], // should be a list of city strings
		// addressString: "",
		hasEarlyAccess: null, // will change to boolean once user retrieved
		// need the private property because userSettingsModal uses it as an input value prop
		private: {
			address: {
				// city: "",
				// country: "",
				// line1: "",
				// line2: "",
				// postal_code: "",
				// state: ""
				addressString: "",
				locationPrivacy: 1,
			}
		},
	};
	
	const [userObject, setUserObject] = useState({ ...defaultUserData });
	const [isAdmin, setIsAdmin] = useState(false);

	const [usersProjects, setUsersProjects] = useState(null);
	const [usersInvoices, setUsersInvoices] = useState(null);
	const [loginModalOpen, setLoginModalOpen] = useState(false);
	const [allUserBills, setAllUserBills] = useState([]);
	const [settingsModalOpen, setSettingsModalOpen] = useState(false);
	const [foundAuthState, setFoundAuthState] = useState(false);
	const [paymentSettingsModalOpen, setPaymentSettingsModalOpen] = useState(false)
	const [canAcceptStripePayments, setCanAcceptStripePayments] = useState(false)
	const [stripeAccountNeedsUpdating, setStripeAccountNeedsUpdating] = useState(false)
	const [hasStripeBusinessAccount, setHasStripeBusinessAccount] = useState(false)
	const [changePasswordModalData, setChangePasswordModalData] = useState(false)

	const [personalNotifications, setPersonalNotifications] = useState([])


	const [currentProjectData, setCurrentProjectData] = useState({
		projectName: "",
		id: "",
	});

	const [newUserData, setNewUserData] = useState({
		...defaultUserData,
	});



	// prevent currentUser from being true before users data is retrieved
	let currentUser;
	const userIsAnonymous = (firebase.auth().currentUser || false) && firebase.auth().currentUser.isAnonymous

	if (userObject.id) {
		currentUser = firebase.auth().currentUser;

		const user = firebase.auth().currentUser

		if (!isAdmin) {
			user.getIdTokenResult()
			  .then((idTokenResult) => {
			     // Confirm the user is an Admin.
			     if (idTokenResult.claims.admin) {
			     	console.log("setting admin")
			       // Show admin UI.
			       setIsAdmin(true)
			     }
			  })
			  .catch((error) => {
			    console.log(error);
			  });
		}
	}

	const isMountedRef = useRef(null)

	useEffect(() => {
	  isMountedRef.current = true

	  return () => {
	  	isMountedRef.current = false
	  }
	}, [])

	// check if user needs to update their information in order to accept payments
	useEffect(() => {
		if (userObject.private) {
			const stripeData = userObject.private.stripe
			if (stripeData && stripeData.business && !stripeData.business.stripe_deauthorized) {
				// check if business account object has 
				setHasStripeBusinessAccount(true)
				// note with the ...busines.details_submitted 
				// check if details have been submitted and whether to initiate another onboarding flow if they are not 
				// stripe docs say: "You can check the state of the details_submitted parameter on their account to see if they’ve completed the onboarding process."
				if (stripeData.business.charges_enabled) {
					setCanAcceptStripePayments(true)
					setStripeAccountNeedsUpdating(false)
				} else {
					setStripeAccountNeedsUpdating(true)
				}
			}
		}
		// eslint-disable-next-line
	}, [userObject.private])

	// every time userObject changes, update the new user data object
	useEffect(() => {
		// caution when changing newUserData by doing newUserData[property].value = "something"
		// because this will mutate userObject
		let resNewUserData = {
			...defaultUserData,
			...userObject,
		}
		if (userObject.roles) {
			if (userObject.roles.generalContractor || userObject.roles.subContractor) {
				resNewUserData.isCustomerOnly = false
			} // else leave this up to the saved userObject to overwrite default or not
		}

		// // remove the default email so they must change it
		// if (userIsAnonymous && newUserData.email.includes("@linvo.ca")) {
		// 	resNewUserData = {
		// 		...newUserData,
		// 		email: "",
		// 		companyEmail: ""
		// 	}
		// }

		setNewUserData(resNewUserData);
		// eslint-disable-next-line
	}, [userObject/*, userIsAnonymous*/]);

	// every time industries changed, update the industry name
	useEffect(() => {
		if (userObject.industries.length) {
			if (userObject.industryTitle !== userObject.industries[0].name) {
				setUserObject((userObject) => ({
					...userObject,
					industryTitle: userObject.industries[0].name,
				}));
			}
		}
		// eslint-disable-next-line
	}, [userObject.industries]);

	useEffect(() => {
		// any time userObject changes check to see if there is userObject.logoPath but no userObject.logoUrl
		// or a userObject.logoUrl but no path
		if (userObject.logoPath && !userObject.logoUrl) {
			// add the logoUrl
			firebase.storage().ref(userObject.logoPath).getDownloadURL().then(url => {
				return updateUserData({
					logoUrl: url
				}, false, "UserContext - useEffect logoPath but no logoUrl")
			}).catch(err => {
				if (err.code === "storage/object-not-found") {
					console.log("storage obj not found")
					return 
				}
				if (err.message.toLowerCase().includes("not found")) {
					return 
				} else {
					console.log(JSON.stringify(err))
					console.error(err)
				}
			})
		}

		if (userObject.logoUrl && !userObject.logoPath) {
			// upload the file and get the file path
			if (!userObject.logoUrl.includes("firebasestorage.googleapis.com")) {
				getNewProfileFilePath(userObject.logoUrl).then(path => {
					return updateUserData({
						logoPath: path,
						logoUrl: "", // must remove this to ensure the previous block runs
					},false, "UserContext - useEffect logoUrl but no logoPath")
				}).catch(err => {
					console.error(err)
				})
			}
		}
		// dont include updateUserData unless making it a useCallback
	  // eslint-disable-next-line
	}, [userObject])

	// every time userObject.currentProject changes, set new current project data
	useEffect(() => {
		getAndSetCurrentProjectData(userObject);
		// eslint-disable-next-line
	}, [userObject.currentProject]);

	const initiatedFetchContacts = useRef(null)

	// passivly get users contacts once
	useEffect(() => {
		// let isMounted = true
		if (userObject.id && !userObject.contacts && (!initiatedFetchContacts || !initiatedFetchContacts.current)) {
			initiatedFetchContacts.current = true
			firebase.firestore().collection("users").doc(userObject.id).collection("contacts").get().then(snapshot => {
				if (snapshot.docs && snapshot.docs.length) {
					return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
				} else {
					return []
				}
			}).then(contacts => {
				if (isMountedRef.current) {
					setUserObject(userObject => ({
						...userObject,
						contacts
					}))
				}
			})
		}

		// if we unmount on userObject change this gets data twice 
		// return () => {
		// 	isMounted = false
		// }
	}, [userObject])


	// const userAnalytics = async (user) => {
	// 	try {
	// 		if (window.location.origin.includes("localhost")) {
	// 			return;
	// 		}

	// 		const date = sessionStorage.getItem("sessionDate");
	// 		const todaySessionDate = new Date().toDateString();
	// 		let todayAnalytics = {
	// 			startUrl: encodeURIComponent(window.location.href),
	// 			sessionDate: new Date().toDateString(),
	// 			path: firebase.firestore.FieldValue.arrayUnion(
	// 				window.location.pathname
	// 			),
	// 		};

	// 		sessionStorage.setItem("sessionDate", todaySessionDate);
	// 		const ipDetailsString = sessionStorage.getItem("ipDetails");
	// 		const ipDetails = JSON.parse(ipDetailsString);
	// 		if (ipDetails && ipDetails.ip) {
	// 			delete ipDetails.success;
	// 			todayAnalytics = {
	// 				...todayAnalytics,
	// 				ipDetails: firebase.firestore.FieldValue.arrayUnion(ipDetails),
	// 			};
	// 		} else {
	// 			todayAnalytics = {
	// 				...todayAnalytics,
	// 				ipDetails: firebase.firestore.FieldValue.arrayUnion({
	// 					status: "failed",
	// 					date: new Date().toLocaleString(),
	// 				}),
	// 			};
	// 		}
	// 		let docName;
	// 		if (user) {
	// 			docName = user.uid;
	// 		} else if (ipDetails) {
	// 			docName = ipDetails.ip;
	// 		} else {
	// 			docName = firebase.firestore().collection("addresses").doc();
	// 		}

	// 		sessionStorage.setItem("userAnalyticsDocId", docName);

	// 		if (!date || todaySessionDate !== date) {
	// 			return firebase
	// 				.firestore()
	// 				.collection("addresses")
	// 				.doc(docName)
	// 				.set(
	// 					{
	// 						user: user ? user.uid : null,
	// 						[todaySessionDate]: todayAnalytics,
	// 					},
	// 					{ merge: true }
	// 				);
	// 		} else return;
	// 	} catch (err) {
	// 		firebase
	// 			.firestore()
	// 			.collection("errorLogs")
	// 			.doc(err.message || "no message")
	// 			.set(
	// 				{
	// 					message: err.message,
	// 					lastInstance: firebase.firestore.FieldValue.serverTimestamp(),
	// 					occurance: firebase.firestore.FieldValue.increment(1),
	// 					fromAnylitics: true,
	// 				},
	// 				{ merge: true }
	// 			);
	// 	}
	// };

	// gets users google contacts and updated the contacts collection with the most up to date 
	// fetch response from google contacts
	// note: will not remove a contact in contacts collection that is no longer in the google contacts response
	const handleAddContacts = async () => {
		try {
			setIsFetching(true)
			setDoNotTerminateFetching(true)
			const result = await addPermissions(["https://www.googleapis.com/auth/contacts.readonly"], "google.com")
			const token = result.credential.accessToken;
			// The signed-in user info.
			const user = result.user;
			const contacts = await getUserContactsGoogle(token, user)
			if (contacts.length) {
				let cleanedContacts = contacts.map(c => {
					// const nameArray = c.names[0].displayName.split(/\s+/)
					let firstname = c.names[0].givenName
					if (!firstname) {
						if (!c.names[0].familyName) {
							firstname = c.names[0].displayName
						}
					}

					const lastname = c.names[0].familyName || ""

					return {
						...c,
						name: `${firstname.trim()} ${lastname.trim()}`.trim() || c.id,
						firstname: firstname.trim(),
						lastname: lastname.trim(),
						title: "From google contacts",
						...(c.emailAddresses && c.emailAddresses.length) ? {email: c.emailAddresses[0].value || ""} : {}, 
						...c.id ? {contactId: c.id} : {}, // in case there is an id property
						id: firebase.firestore().collection("users").doc(userObject.id).collection("contacts").doc().id,
						fromGoogle: true,
					}
				})
				// try to merge contacts with existing
				// contact is treated as an update if
				// - ID matches
				// - resourceName matches
				// - both name and email matches
				let mergedContacts = []
				if (userObject.contacts && userObject.contacts.length) {
					cleanedContacts.forEach(c => {
						let contactInUsersContacts = userObject.contacts.find(con => con.id === c.id)
						if (!contactInUsersContacts) {
							// find contact by resource name
							contactInUsersContacts = userObject.contacts.find(con => con.resourceName === c.resourceName)
						}
						if (!contactInUsersContacts) {
							// find a contact with matching names and email
							contactInUsersContacts = userObject.contacts.find(existingC => existingC.name === c.name && existingC.email === c.email)
						}

						if (contactInUsersContacts) {
							// update the contact
							mergedContacts.push({
								...contactInUsersContacts,
								...c,
								// overwrite the newly created  id because we are updating here
								id: contactInUsersContacts.id
							})
						} else { // couldn't find a probable existing contact, this is a new contact
							mergedContacts.push(c)
						}
					})
				} else { // user has no contacts yet
					mergedContacts = [...cleanedContacts]
				}
				// add contacts to userContacts
				if (cleanedContacts.length) {
					updateUserData({contacts: mergedContacts}, false, "NewInvoiceForm - handleAddContacts")
				}

				handleSetSuccessText("Contacts updated!")
			} else {
				handleSetSuccessText("No contacts found")
			}

			setIsFetching(false)
			setDoNotTerminateFetching(false)

		} catch (err) {
			setIsFetching(false)
			setDoNotTerminateFetching(false)
			if (err.code === "auth/popup-closed-by-user" || err.code === "auth/cancelled-popup-request") {
				return 
			} else {
				console.error(err)
				setErrorObj(err)
			}
		}

	}

	const getAndSetCurrentProjectData = async (userObject) => {
		if (userObject.currentProject) {
			try {
				const doc = await firebase
					.firestore()
					.collection("projects")
					.doc(userObject.currentProject)
					.get();
				setCurrentProjectData({ ...doc.data(), id: userObject.currentProject });
				// setIsFetching(false)
				return;
			} catch (err) {
				setIsFetching(false);
				if (err.message.includes("Missing or insufficient permissions")) {
					// setProjectData(projectData => ({...projectData, visibility: "private"}))
					setIsFetching(false);
					return;
				} else {
					throw err;
					// setErrorObj(err)
				}
			}
		} else {
			setCurrentProjectData({ projectName: "", id: "" });
			setIsFetching(false);
			return;
		}
	};

	// temp solution until we can make a handleSetUserObject function
	// this is a problem because if a user is updating in settings then their user doc updates before they
	// save the changes, things will be replaced.. should use an original user data state then compare changes
	useEffect(() => {
		let listener = () => null
		let isMounted = true
	  if (userObject.id) {
	  	listener = firebase
				.firestore()
				.collection("users")
				.doc(userObject.id)
				.onSnapshot(doc => {

					if (doc.exists && isMounted) {
						const userDataFromDatabase = {...doc.data(), id: doc.id}
						setUserObject((userObject) => ({
							...userObject,
							// ...user,
							...userDataFromDatabase,
							industryTitle: userDataFromDatabase.userDataFromDatabase || userDataFromDatabase.industries.length
								? userDataFromDatabase.industries[0].name
								: "",
							companyName: userDataFromDatabase.companyName
								? userDataFromDatabase.companyName
								: `${userDataFromDatabase.username.replace("@")}`,
							companyEmail: userDataFromDatabase.companyEmail
								? userDataFromDatabase.companyEmail
								: userDataFromDatabase.email,
						}));
					}
				})
	  }

	  return () => {
	  	listener()
	  	isMounted = false
	  }
	}, [userObject.id])

	// update any new bill tos as contacts
	// not in use until we can add functionality for recognising a contact update, deleting/ updating contacts etc
	// useEffect(() => {
	// 	if (usersInvoices && usersInvoices.length && userObject.contacts) {
	// 		let newContacts = []
	// 		usersInvoices.forEach(inv => {
	// 			if (inv.billTo.uid) {
	// 				// see if the user is already in users contacts
	// 				const userInContacts = userObject.contacts.find(person => person.uid === inv.billTo.uid)
	// 				if (!userInContacts) {
	// 					const contactObj = {
	// 						...inv.billTo,
	// 						id: firebase.firestore().collection("users").doc(userObject.id).collection("contacts").doc().id,
	// 					}

	// 					delete contactObj.showAddress

	// 					newContacts.push(contactObj)
	// 				}
	// 			}
	// 		})

	// 		// if theres an error here it is caught in the updateUserData fn
	// 		await updateUserData({ 
	// 			contacts: [...userObject.contacts || [], ...newContacts]
	// 		}, false, "UserContext - useEffect update contacts from invoice billTos");
	// 	}

	// }, [usersInvoices, userObject])

	const promptPasswordIfOnlyEmailLink = (email) => {
		firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
  		if (methods[0] === 'emailLink' && methods.length === 1) {
				setChangePasswordModalData(changePasswordModalData => ({
					...changePasswordModalData,
					open: true,
					creatingNew: true,
					email: email,
				}))
  		}
  	})
	}

	const handleAuthStateChanged = async (user) => {
		// find or add user data if the user has just signed up
		try {
			setFoundAuthState(true);
			if (user && user.uid) {
				setIsFetching(true);

				const doc = await firebase
					.firestore()
					.collection("users")
					.doc(user.uid)
					.get();
				let userDataFromDatabase = doc.data ? {...doc.data(), id: doc.id} : {};

				// always keep the email and uid up to date
				let newUserObjectItems = {
					uid: user.uid,
					// for anon users
					email: user.email || "sample" + user.uid.slice(0, 5) + "@linvo.ca",
				};

				// if the user is anon make a unique username not just the one generated by addNewUserToDB
				if (user.isAnonymous && !userDataFromDatabase.username) {
					newUserObjectItems.username = "sample" + user.uid.slice(0, 5)
				}
				if (!doc.exists) {
					userDataFromDatabase = await addNewUserToDB(user, {
						...userObject,
						...newUserObjectItems,
					}, dialog);

				} else {
					// TODO: THE LOGIIC IN THE IF STATEMENT BELOW MIGHT NOT BE NEEDED	
					// SINCE WE ALREADY ADD THIS INFO IN THE SESSIONsTORAGE IN  LOGINoRsIGNuP.JS
					// check if this was an anon user upgrading
					// this will only fire onAuthStateChanged which doesnt happen when linking email password method 
					if (userDataFromDatabase.isAnonymous && !user.isAnonymous) {
						// if user is upgrading and the anon user hasnt changed defaults, let the addNewUserToDB fn generate some data
						if (userDataFromDatabase.username && userDataFromDatabase.username.includes("sample")) {
							newUserObjectItems.username = ""
						}

						if (userDataFromDatabase.companyName && userDataFromDatabase.companyName.includes("sample")) {
							newUserObjectItems.companyName = "" 
						}

						if (userDataFromDatabase.companyEmail && userDataFromDatabase.companyEmail.includes("linvo.ca")) {
							newUserObjectItems.companyEmail = "" 
						}

						// let addNewUserToDB re make cardUserInfo
						newUserObjectItems.cardUserInfo = {}

						// add the new info to Db
						// update the user fully as if they didnt have an anon account but add in current data as defaultData
						userDataFromDatabase = await addNewUserToDB(user, {...userDataFromDatabase, ...newUserObjectItems}, dialog)
						newUserObjectItems = {...userDataFromDatabase}
					}
				}

				// get users private subcollection
				const privateInfoSnapshot = await firebase
					.firestore()
					.collection("users")
					.doc(user.uid)
					.collection("private")
					.get()
					.catch(err => {
						// user might not be in DB yet, dont show the error
						console.error(err)
						return null
					})
					
				if (privateInfoSnapshot && privateInfoSnapshot.docs && privateInfoSnapshot.docs.length) {
					newUserObjectItems.private = {
						...defaultUserData.private
					};
					// get all private fields
					for (let i = 0; i < privateInfoSnapshot.docs.length; i++) {
						const doc = privateInfoSnapshot.docs[i];
						const objectName = doc.id;
						newUserObjectItems.private[objectName] = { 
							// add in default data
							...newUserObjectItems.private[objectName] || {},
							...doc.data() 
						};
					}
				}

				// get rid of undefined or empty values but not false or null
				let cleanedNewUserObjectItems = {}

				for (let key in newUserObjectItems) {
					const val = newUserObjectItems[key]
					if (val !== undefined && val !== "") {
						cleanedNewUserObjectItems[key] = val
					}
				}

				setUserObject((userObject) => ({
					...userObject,
					// ...user,
					...userDataFromDatabase,
					industryTitle: userDataFromDatabase.userDataFromDatabase || userDataFromDatabase.industries.length
						? userDataFromDatabase.industries[0].name
						: "",
					companyName: userDataFromDatabase.companyName
						? userDataFromDatabase.companyName
						: `${userDataFromDatabase.username.replace("@")}`,
					companyEmail: userDataFromDatabase.companyEmail
						? userDataFromDatabase.companyEmail
						: userDataFromDatabase.email,
					logoUrl: userDataFromDatabase.logoUrl || "",
					...cleanedNewUserObjectItems, // contains 'private' subcollection
				}));

				setIsFetching(false);
			} else {
				setIsFetching(false);
			}

			// await userAnalytics(user);
		} catch (err) {
			setErrorObj(err);
			setIsFetching(false);
		}
	}

	const handleLogout = () => {
		logUserOut().catch((err) => setErrorObj(err));
		setUserObject(null);
		// setUserData(defaultUserData)
		setCurrentProjectData({
			projectName: "",
			id: "",
		});
	};

	const getUsersProjects = ({userId, caller, doNotSetProjects}) => {
		return firebase
			.firestore()
			.collection("projects")
			.where("accessors", "array-contains", userId)
			.get()
			.then((snapshot) => {
				if (snapshot.docs.length) {

					const newProjects = snapshot.docs.map((doc) => {
						const docData = doc.data();
						// remove bad properties
						if (docData.isLoading) {
							delete docData.isLoading;
						}

						return { ...docData, id: doc.id };
					});


					if (!doNotSetProjects) {
						setUsersProjects(newProjects)
					}
					return newProjects
				} else {
					if (!doNotSetProjects) {
						setUsersProjects([])
					}
					return [];
				}
			}).catch(err => {
				err.caller = caller
				console.log("error in getUsersProjects...", {err, caller})
				throw err
			})
	};

	const getUsersInvoices = ({userId, caller, doNotSetInvoices}) => {
		return firebase
			.firestore()
			.collection("invoices")
			.where("accessors", "array-contains", userId)
			.orderBy("mostRecentEntryDate", "desc")
			.get()
			.then((snapshot) => {
				if (snapshot.docs.length) {
					const newInvoices = snapshot.docs.map((doc) => {
						const docData = doc.data();
						// remove bad properties
						if (docData.isLoading) {
							delete docData.isLoading;
						}
						return { ...docData, id: doc.id };
					});

					if (!doNotSetInvoices) {
						setUsersInvoices(newInvoices)
					}

					return newInvoices
				} else {
					if (!doNotSetInvoices) {
						setUsersInvoices([])
					}
					return [];
				}
			}).catch(err => {
				err.caller = caller
				console.log("error in getUsersInvoices...", {err, caller})
				throw err
			})
	};

	const updateUserData = (newUserData, dontUpdateUserObject, caller) => {
		const userId = firebase.auth().currentUser.uid;
		// currentProject state relies on updating userObject here
		let privateData = newUserData.private

		let userDataWithoutPrivateOrContacts = { ...newUserData };
		delete userDataWithoutPrivateOrContacts.private;
		delete userDataWithoutPrivateOrContacts.contacts

		// below was only because it was hard to have input.value of deeply nested property in userSettingsModal... shouldnt need this anymore
		// // handle address data
		// if (newUserData.address && Object.keys(newUserData.address).length) {
		// 	privateData.address = newUserData.address
		// 	delete userDataWithoutPrivateOrContacts.address
		// }
		// if (newUserData.addressString) {
		// 	privateData.address.addressString = newUserData.addressString
		// 	delete userDataWithoutPrivateOrContacts.addressString
		// }


		if (!dontUpdateUserObject) {
			setUserObject((userObject) => ({ ...userObject, ...newUserData }));
		}

		const handlePrivateDataUpdate = () => {
			// update private data if the data to update includes private
			if (privateData && Object.keys(privateData).length) {
				let privateDataUpdates = [];

				for (let key in privateData) {
					const data = privateData[key];
					privateDataUpdates.push(
						firebase
							.firestore()
							.collection("users")
							.doc(userId)
							.collection("private")
							.doc(key)
							.set(
								{
									...data,
								},
								{ merge: true }
							)
					);
				}

				return Promise.all(privateDataUpdates);
			} else return true;
		}

		const handleContactsUpdate = () => {
			// update contact data: add new if new, update old if same id, 
			// note will not delete a contact if contact no longer in google contacts
			if (newUserData.contacts && Object.keys(newUserData.contacts).length) {
				let batch = firebase.firestore().batch()

				newUserData.contacts.forEach(cont => {
					let entryRef
					if (cont.id) {
						entryRef = firebase.firestore().collection("users").doc(userId).collection("contacts").doc(cont.id)
						// might be an update to existing contact or could be a new doc
						batch.set(entryRef, cont, { merge: true })
					} else {
						// in case there is no id on contact ... shouldn't happen
						entryRef = firebase.firestore().collection("users").doc(userId).collection("contacts").doc()
						batch.set(entryRef, cont)
					}
				})

				return batch.commit()
			} else return true;
		}

		return firebase
			.firestore()
			.collection("users")
			.doc(userId)
			.update({...userDataWithoutPrivateOrContacts})
			.then(() => {
				return handlePrivateDataUpdate()
			}).then(() => {
				return handleContactsUpdate()
			})
	};

	const removeDocFromRecentlyViewed = async (doc) => {
		const docInRecentlyViewed = userObject.recentlyViewedDocs.find(
			(obj) => obj.id === doc.id
		);
		const newRecentlyViewedDocs = userObject.recentlyViewedDocs.filter(
			(obj) => obj.id !== doc.id
		);
		if (docInRecentlyViewed) {
			// updates both user object and in DB
			return updateUserData({
				recentlyViewedDocs: newRecentlyViewedDocs,
			}, false, "UserContext - removeDocFromRecentlyViewed").catch((err) => setErrorObj(err));
		} else {
			return newRecentlyViewedDocs;
		}
	};

	return (
		<UserContext.Provider
			value={{
				currentUser,
				userObject,
				setUserObject,
				newUserData,
				setNewUserData,
				handleLogout,
				currentProjectData,
				setCurrentProjectData,
				usersProjects,
				setUsersProjects,
				getUsersProjects,
				usersInvoices,
				setUsersInvoices,
				getUsersInvoices,
				handleAuthStateChanged,
				setErrorObj,
				handleSetErrorObj,
				handleSetSuccessText,
				offlineMode,
				setOfflineMode,
				isFetching,
				setIsFetching,
				handleSetMessage,
				loginModalOpen,
				setLoginModalOpen,
				allUserBills,
				setAllUserBills,
				updateUserData,
				settingsModalOpen,
				setSettingsModalOpen,
				removeDocFromRecentlyViewed,
				defaultUserData,
				foundAuthState,
				setFoundAuthState,
				paymentSettingsModalOpen,
				setPaymentSettingsModalOpen,
				canAcceptStripePayments,
				setCanAcceptStripePayments,
				stripeAccountNeedsUpdating,
				setStripeAccountNeedsUpdating,
				hasStripeBusinessAccount,
				setHasStripeBusinessAccount,
				changePasswordModalData,
				setChangePasswordModalData,
				promptPasswordIfOnlyEmailLink,
				handleAddContacts,
				doNotTerminateFetching,
				setDoNotTerminateFetching,
				userIsAnonymous,
				isAdmin,
				personalNotifications, 
				setPersonalNotifications,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export { UserContextProvider, UserContext };
