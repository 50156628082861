import firebase from "../../firebase/index";
import { getLocalISODate } from "../../utils/dateUtils";
import { sendNotification } from "../../utils/notificationUtils";
import {
	getNum,
	makeRandomString,
	removeDuplicates,
	formulateSearchKeywords,
	deepObjectCompareDiffers,
} from "../../utils/appUtils";

// errors and success text handled except for handleRemoveInvoice and functions called by handleSave in EditedInvContext,
// handleSave needs to be changed to a batch write and handleRemoveInvoice needs to be changed to a cloud function

// cant use currentUser globally because the function is exported with the current currentUser value which is null upon this file loading
// const currentUser = firebase.auth().currentUser

export const getBillToDataFromContact = (billToObj) => {
	let newBillToObj = {...billToObj}

	if (billToObj.fromGoogle) {
		let foundAddress = (billToObj.addresses && billToObj.addresses[0]) ? billToObj.addresses[0] : false 
		if (foundAddress) {
			newBillToObj.userFullAddress = foundAddress.formattedValue ? foundAddress.formattedValue.replace(/\n/g, " ").replace(/\s+/g, " ") : ""
			newBillToObj.address = {
				...newBillToObj.address,
				...foundAddress.city && {city: foundAddress.city},
				...foundAddress.country && {country: foundAddress.country},
				...foundAddress.postalCode && {postal_code: foundAddress.postalCode},
				...foundAddress.region && {state: foundAddress.region},
				...foundAddress.state && {state: foundAddress.state},
				...foundAddress.streetAddress && {line1: foundAddress.streetAddress},
				...foundAddress.extendedAddress && {line2: foundAddress.extendedAddress},
			}
			newBillToObj.showAddress = true
		}

		newBillToObj.googleContactData = {
			...(newBillToObj.names && newBillToObj.names[0]) && {nameData: newBillToObj.names[0]},
			...foundAddress && {addressData: foundAddress},
			...(newBillToObj.photos && newBillToObj.photos[0]) && {photoData: newBillToObj.photos[0]},
		}

		if (newBillToObj.googleContactData.photoData.url) {
			newBillToObj.logoUrl = newBillToObj.googleContactData.photoData.url || ""
		} else {
			// get rid of logoUrl
			newBillToObj.logoUrl = ""
		}
	} // else the contact data should have the same properties as the billTo data eg: userFullAddress, address, uid, id ...

	delete newBillToObj.names
	delete newBillToObj.addresses
	delete newBillToObj.phoneNumbers
	delete newBillToObj.photos
	delete newBillToObj.resourceName
	delete newBillToObj.etag
	delete newBillToObj.emailAddresses

	return newBillToObj
}


// --- for custom entries and formulas ---- 
export const cutTableName = (propertyName) => {
	return propertyName.replace("Entries", "")
}

export const handleUpdateReceiptMetadata = async ({newEditors, receipts}) => {
	// changes receipt storage. If previous file metadata has editors that are not listed in newEditors this removes them 
	let promises = []
	for (let i=0; i<receipts.length; i++) {
		const rec = receipts[i]
		const storageRef = rec.storagePath;
		if (storageRef && rec.fileType !== "bill" && rec.fileType !== "url") {
			let customMetadata = {}
			const prevCustomMetadata = await firebase.storage().ref(storageRef).getMetadata().then(m => {
				if (m && m.customMetadata) {
					return m.customMetadata
				} else {
					return null
				}
			}).catch(err => {
				console.error(err)
			})

			newEditors.forEach(editor => {
				customMetadata[editor] = "editor"
			})

			// setting the userID in custom metadata to null if they arent in the new editors list
			if (prevCustomMetadata) {
				for (let key in prevCustomMetadata) {
					if (!newEditors.includes(key)) {
						customMetadata[key] = null
					}
				}
			}

			const metadata = {
				// cacheControl: "public, max-age=2592000, stale-while-revalidate=86400",
				customMetadata
			};

			promises.push(firebase.storage().ref(storageRef).updateMetadata(metadata).then(r => {
			}).catch(err => {
				console.error(err)
			}))
		}
	}

	return Promise.all(promises)
}

export const findTableTitle = (invoiceTotalsKey, invoice) => {
	let name = cutTableName(invoiceTotalsKey)
	if (invoice.pageOrder && invoice.pageOrder.length) {

		let sectionHeadingItem = invoice.pageOrder.find(item => item.className === "section-heading" && item.for === name + "Heading")
		if (sectionHeadingItem && sectionHeadingItem.val) {
			name = sectionHeadingItem.val
		}
	}

	return name
}

export const getFormulaVariableOptions = (invoice, invoiceTotals) => {
	let formulaVariableOptions = [
		{
			name: "invoice subtotal", 

			// name: "invoice.invoiceTotals.materialEntries.total + invoice.invoiceTotals.laborEntries.total",
			value: getSum([invoiceTotals.materialEntries.total, invoiceTotals.laborEntries.total]),
			stringFormula: "invoiceTotals.materialEntries.total + invoiceTotals.laborEntries.total"
		}
	] 
		// add formula variable options
	for (let key in invoiceTotals) {
		// do not include custom entries because this form is for custom  ( summary ) entries
		if (key !== "customEntries") {
			formulaVariableOptions.push({
				// displayName: cutTableName(key),
				// name: "invoiceTotals." +  key + ".total",
				name: cutTableName(key) + " total",
				value: invoiceTotals[key].total,
				stringFormula: `invoiceTotals.${key}.total`,
				displayName: findTableTitle(key, invoice) + " total"
			})
			formulaVariableOptions.push({
				// displayName: cutTableName(key) + " qty",
				// name: "invoiceTotals." +  key + ".qty",
				name: cutTableName(key) + " qty",
				value: invoiceTotals[key].qty,
				stringFormula: `invoiceTotals.${key}.qty`,
				displayName: findTableTitle(key, invoice) + " qty"
			})
		}
	}

		// add custom option
	formulaVariableOptions.push({
		// displayName: "Custom value",
		name: "custom number",
		value: 0.05
		// stringFormula: 0.05
	})

	return formulaVariableOptions
}

export const getTdTotalVal = (fd) => {
	// this can spit out a formula or a number value
	// formulas are parsed in renderingUtils
	// in this case the cost * qty automatic formula shoulf not happen in the table 

	// merge all of the stringFormulas
	let totalFormulaString = "="
	for (let key in fd.formulaStructure) {
		const selectionName = fd.formulaStructure[key].name

		// could change all operators to be "(" +  operator + ")"
		// for easier parsing or negative numbers...
		if (selectionName === "custom number") {
			totalFormulaString += " " + fd.formulaStructure[key].value || 0
		} else if (fd.formulaStructure[key].value.toString().length === 1 && fd.formulaStructure[key].value.toString().match(/[/\-*+]/)) {
			totalFormulaString += " " + (fd.formulaStructure[key].value).toString() 
		} else {
			totalFormulaString += " " + (fd.formulaStructure[key].stringFormula || fd.formulaStructure[key].value || 0).toString() // eg: invoiceTotals.materialEntries.total ...
		}
	}

	return totalFormulaString
}

export const updateTotals = (fd) => {
	let newTotal = 0
	// find a new formula
	let newFormula = ""
	for (let key in fd.formulaStructure) {
		// use the value in formulaVariableOptions because it gets its value directly from invoiceTOtals 
		// let selectedOption = formulaVariableOptions.find(opt => opt.name === fd.formulaStructure[key].name)
		// if (!selectedOption) {
		// 	// selectedOption must be in operatord
		// 	selectedOption = formulaOperatorOptions.find(opt => opt.value === fd.formulaStructure[key].value)
		// }
		// const updatedVal = selectedOption.value
		// newFormula += `${updatedVal || "0"} `

		// value is updated in find formulaStructure()
		newFormula += `${fd.formulaStructure[key].value || "0"} `
	}
		// newFormula = `${fd.var0 || 0} ${fd.var1} ${fd.var2 || 0}`
		newFormula = newFormula.trim()
		const formulaAsArray = newFormula.split(" ")
		newTotal = getNum(evaluateFormulaArray(formulaAsArray), 2)
		// newTotal = getNum(eval(newFormula.toString()), 2)

	// add all the totals 
	return {...fd, total: newTotal, formulaDisplayString: newFormula}
}

export const formulaOperatorOptions = [
	{
		name: "+",
		value: "+"
	},
	{
		name: "-",
		value: "-"
	},
	{
		displayName: "x",
		name: "*",
		value: "*"
	},
	{
		name: "/",
		value: "/"
	}
]

// general
export const subCollectionsList = [
	"laborEntries",
	"materialEntries",
	"paymentEntries",
	"receipts",
];

export const entriesPageOrderName = "pageOrder";

export const topSectionPageOrderName = "topSectionPageOrder";

export const disabledBillToFields = [
	"contractor",
	"editors",
	"endWorkTodayAt",
	"followers",
	"id",
	"industry",
	"invNumber",
	"invShortHand",
	"invoiceTotals",
	"liveEntryId",
	"owner",
	"pageOrder",
	"rate",
	"startedWorkTodayAt",
	"todayBreakTimeMin",
	"totalOwed",
	"totalPaid",
	"paymentMethods",
];

export const defaultTableHeadings = [
	{ for: "date", val: "Date", visible: true },
	{ for: "item", val: "Item", visible: true },
	{ for: "qty", val: "Qty", visible: true },
	{ for: "cost", val: "Cost", visible: true },
	{ for: "total", val: "Total", visible: true },
];

export const cloneInvoiceSubCollections = async ({
	invoice, // may not include full invoice when invoked by handleUpdateLeviedInvoice
	newFirebaseDoc,
	type,
	doNotUpdateCollections,
	newLaborEntries
}) => {
	try {
		let promises = [];
		let subcollectionsToUpdate = subCollectionsList
		if (doNotUpdateCollections && doNotUpdateCollections.length) {
			subcollectionsToUpdate = subcollectionsToUpdate.filter(col => !doNotUpdateCollections.includes(col))
		}
		// only update if invoice[col] is truthy
		// get the invoices subCollection docs and copy them intp the new invoice
		for (let i = 0; i < subcollectionsToUpdate.length; i++) {
			const batch = firebase.firestore().batch();

			const collection = subcollectionsToUpdate[i];
			// dont add any receipt entries to a copied doc
			/// need to check if type is forward bill then copy invoice subcollections from the invoice parameter instead of 
			// getting the actual collection
			let collectionSnapshotDocs

			if (type === "forwardBill") {
				let newEntries = null
				// in a blind levied invoice update any tcollections that are empty are removed 
				if (invoice[collection]) {
					// add the invoices subcollections 	
					newEntries = [...invoice[collection]]
				}

				if (newEntries && newEntries.length) {
					if (collection === "receipts") {
						collectionSnapshotDocs = newEntries.map(rec => {
							return {
								...rec,
								fromPairedInv: invoice.id,
							}
						})
					} else {
						// check if invoice[col] is not simply undefined since there are no entries
						newEntries = newEntries.map(entry => {
							let newEntry = {...entry}
							let shouldUpdate = true
							if (entry.isDefaultEntry) {
								// cant use new Labor entries here because they will never match default entry since the cost and total is deleted from them
								shouldUpdate = !(matchesDefaultEntry({entry, collection, invoice}))
								delete newEntry.isDefaultEntry
							}
							return shouldUpdate ? newEntry : false
						})

						if (collection === "laborEntries" && newLaborEntries && newLaborEntries.length) {
							// use the new labor entries
							// only use the new labor entries that werent filtered out
							// loop through the entries that differ and find that entry in the newly updated labor entry
							newEntries = newEntries.map(ent => {
								if (ent) {
									let newEntry = false
									const entryInNewLaborEntries = newLaborEntries.find(newLaborEnt => newLaborEnt.id === ent.id)
									if (entryInNewLaborEntries) {
										newEntry = {...entryInNewLaborEntries}
										delete newEntry.isDefaultEntry
									}

									return newEntry
								} else return false
							
							})
						}

						newEntries = newEntries.filter(isTruthy => isTruthy)
						collectionSnapshotDocs = newEntries
					}
				} else {
					collectionSnapshotDocs = []
				}
			} else {
				// get the subcollections from firestore and add them into the copied invoice
				// never add receipts in an invoice copy
				if (collection !== "receipts") {
					collectionSnapshotDocs = await firebase
						.firestore()
						.collection("invoices")
						.doc(invoice.id)
						.collection(collection)
						.get()
						.then(snapshot => {
							if (snapshot.docs.length) {
								let docs = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
								docs = docs.map(row => {
									// value entries on levied invoices can be null so check each time
									let newRowValues = []
									row.values.forEach(td => {
										if (!td.val && td.for && row[td.for]) {
											newRowValues.push({
												...td,
												val: row[td.for]
											})
										} else {
											newRowValues.push(td)
										}
									})

									return {...row, values: newRowValues}
								})

								return docs
							} else return []
						})
						.catch((err) =>
							console.log(
								"err in getting collection at cloneInvoiceSubCollections",
								err
							)
						);
					// make sure the collection ecists, if not do nothing
				}


			}

			if (collectionSnapshotDocs && collectionSnapshotDocs.length) {
				// add a new doc in subcollection folder of new firebase doc to batch update
				collectionSnapshotDocs.forEach((doc) => {
					// dont copy a verified payment
					if (!(collection === "paymentEntries" && doc.verifiedPayment)) {
						let copiedEntry
						if (type === "forwardBill") {
							// keep the ID of the entry the same for the purpose of forwarding receipts
							copiedEntry = newFirebaseDoc.collection(collection).doc(doc.id); 
						} else {
							copiedEntry = newFirebaseDoc.collection(collection).doc();
						}
						batch.set(copiedEntry, doc, {merge: true});
					}
				});

				// add the batch update into promises array
				promises.push(batch.commit());
			}

		}

		return Promise.all(promises);
	} catch (err) {
		throw err;
	}
};

export const makeCopyOfInvoice = async ({
	i,
	type,
	inv,
	pairedInvFd, 
	invShortHand,
	invNumber,
	invoiceId,
	whenLoading,
	userObject,
	newLaborEntries,
	includeEditors,
	includeFollowers,
	includeBillTo,
	includeForProject,
	setUserObject,
}) => {
	try {
		pairedInvFd = pairedInvFd || {}
		const invNumber = pairedInvFd.invNumber || parseInt(userObject.lastInvNumber || 100) + 1 || 100;
		const invShortHand = pairedInvFd.invShortHand || makeRandomString("", 3);
		const invoiceId = pairedInvFd.id;
		// must add and include editors if forwarding a bill
		// must add the firstname and lastname of billTO but not uid and username when forwarding bill 
		let newInvoiceDoc
		if (invoiceId) {
			newInvoiceDoc = firebase.firestore().collection("invoices").doc(invoiceId);
		} else {
			newInvoiceDoc = firebase.firestore().collection("invoices").doc();
		}
		const currentUser = firebase.auth().currentUser;

		let defaultFollowers = includeFollowers ? inv.followers : [currentUser.uid];
		let defaultEditors = includeEditors ? inv.editors : [currentUser.uid];
		defaultFollowers = Array.from(new Set(defaultFollowers));
		defaultEditors = Array.from(new Set(defaultEditors));
		let invoiceBillTo = inv.billTo
		if (type === "forwardBill") {
			invoiceBillTo = pairedInvFd.billTo
		}
		let billTo = { ...invoiceBillTo, username: "", uid: "" }; // adding a users uid means they accepted a bill
		if (includeBillTo && invoiceBillTo.uid && invoiceBillTo.username) {
			billTo.uid = invoiceBillTo.uid;
			billTo.username = invoiceBillTo.username;
		}

		let defaultContractorPaymentMethods = null
		// if the copier is the contractor, copy the payment methods
		// else copy the payment methods in userObject or set to null which will show default (cash only) upon invoice render
		if (userObject.paymentMethods && Object.keys(userObject.paymentMethods).length) {
			defaultContractorPaymentMethods = userObject.paymentMethods
		}
		if (inv.contractor.id === currentUser.uid && inv.paymentMethods /*&& userObject.paymentMethods && Object.keys(userObject.paymentMethods).length*/) {
			defaultContractorPaymentMethods = inv.paymentMethods
		} 


		const defaultFormDataContractorObj = {
			companyEmail: userObject.companyEmail, // dont use email as it shouldnt be public
			// address: userObject.address ,
			fullRate: userObject.industries.length
				? userObject.industries[0].rate
				: "",
			id: userObject.uid,
			// name: userObject.companyName ? userObject.companyName : userObject.lastname, // unless user adds company info this will be their name
			companyName: userObject.companyName || userObject.firstname + " " + userObject.lastname || userObject.username, // unless user adds company info this will be their name

			// make sure to put there name as default companyName when user signs up
			username: userObject.username,
			// companyUrl: userObject.companyUrl ? userObject.companyUrl : `${userObject.lastname}-invoices`,
			phone: userObject.phone || "",
			logoUrl: userObject.logoUrl || "",
			lastInvNumber: invNumber,
			addressString: userObject.private.address ? (userObject.private.address.addressString || "") : "",
			...userObject.private.address ? {address: userObject.private.address} : {},
		};

		let copyableInvoiceProperties = {
			accessors: inv.accessors,
			// billTo: inv.billTo,
			contractor: inv.contractor,
			closed: inv.closed, 
			closedDate: inv.closedDate,
			dateOfLastPayment: inv.dateOfLastPayment,
			dateOfNextPayment: inv.dateOfNextPayment,
			description: inv.description, 
			editors: inv.editors,
			endWorkTodayAt: inv.endWorkTodayAt,
			followers: inv.followers || [],
			forProject: inv.forProject,
			industry: inv.industry,
			invoiceTotals: inv.invoiceTotals,
			liveEntryId: inv.liveEntryId,
			mostRecentEntryDate: inv.mostRecentEntryDate || getLocalISODate(
				false,
				new Date().setHours(24, 0, 0, 0)
			),
			pageOrder: inv.pageOrder,
			rate: inv.rate, // overwritten if isForwardBill
			searchKeywords: inv.searchKeywords,
			searchable: inv.searchable,
			showDiscount: inv.showDiscount,
			showTotalCost: inv.showTotalCost,
			showTotalOwing: inv.showTotalOwing,
			startedWorkTodayAt: inv.startedWorkTodayAt || "",
			summaryEntries: inv.summaryEntries,
			todayBreakTimeMin: inv.todayBreakTimeMin,
			topSectionPageOrder: inv.topSectionPageOrder,
			totalOwed: inv.totalOwed,
			totalPaid: inv.totalPaid,
			visibility: inv.visibility,
			version: 1,
		}

		// get rid of any undefined properties
		for (let key in copyableInvoiceProperties) {
			const item = copyableInvoiceProperties[key]
			if (item === undefined) {
				delete copyableInvoiceProperties[key]
			}
		}

		// overwrite properties
		let fd = {
			invNumber,
			invShortHand,
			accessors: removeDuplicates([
				...defaultEditors,
				defaultFormDataContractorObj.id,
				billTo.uid || "",
			]),
			followers: defaultFollowers,
			editors: defaultEditors,
			id: newInvoiceDoc.id,
			owner: currentUser.uid,
			creatorUid: currentUser.uid,
			billTo,
			contractor: defaultFormDataContractorObj,
			forProject: includeForProject ? inv.forProject : "",
			paymentMethods: defaultContractorPaymentMethods,
			// temporary creation time for the whenLoading function so the new invoice can have a class of recent copy
			creationTime: Date.now() / 1000,
			topSectionPageOrder: getDefaultTopSectionPageOrder({
				contractor: defaultFormDataContractorObj,
				invNumber,
				industry: copyableInvoiceProperties.industry || "",
				billTo,
				description: copyableInvoiceProperties.description || "",
				invShortHand,
				fromTemplate: copyableInvoiceProperties.topSectionPageOrder || [],
			}),
			showDiscount: copyableInvoiceProperties.showDiscount || "",
			showTotalCost: copyableInvoiceProperties.showTotalCost || true,
			showTotalOwing: copyableInvoiceProperties.showTotalOwing || true,
		};

		if (type === "forwardBill") {
			fd = {
				...copyableInvoiceProperties, // doesnt invlude subcollections
				...fd,
				rate: pairedInvFd.rate,
				pairedInvoices: firebase.firestore.FieldValue.arrayUnion(inv.id),
				// isForwardedBill: true,
				pairedInvoiceOptions: { // subcontractor shouldnt be able to edit this
					[inv.id]: {
						condenseEntriesIn: pairedInvFd.condenseEntriesIn,
						contractor: inv.contractor || {},
						deleted: false,
						doNotUpdate: pairedInvFd.doNotUpdate || [],
						// restricted editors... not editors ?inv.restrictedEditors is created in forwardBillModal handleSave
						editors: inv.restrictedEditors || [],
						// editors: inv.editors || [],
						invNumber: inv.invNumber || "",
						invShortHand: inv.invShortHand || "",
						lastUpdated: firebase.firestore.Timestamp.now().seconds,
						unpaired: false,
					}
				},
				// inv.restrictedEditors is created in forwardBillModal handleSave
				restrictedEditors: inv.restrictedEditors || []
			}
		} else if (type === "fullCopy") {
			fd = {
				...copyableInvoiceProperties, // doesnt invlude subcollections
				...fd,
				fullCopyOf: inv.id,
			};
		} else {
			// template copy
			fd = {
				...fd,
				pageOrder: inv.pageOrder || [],
				industry: inv.industry || "",
				searchable: inv.searchable || true,
				rate: inv.rate || 0,
				summaryEntries: inv.summaryEntries || [],
				visibility: inv.visibility || "public",
				dateOfLastPayment: "",
				dateOfNextPayment: "",
				endWorkTodayAt: "",
				liveEntryId: "",
				startedWorkTodayAt: "",
				todayBreakTimeMin: 0,
				invoiceTotals: {},
				totalOwed: 0,
				totalPaid: 0,
				templateCopyOf: inv.id,
				mostRecentEntryDate: inv.mostRecentEntryDate ? getLocalISODate(
					false,
					new Date(inv.mostRecentEntryDate).setHours(24, 0, 0, 0)
				) : 
				getLocalISODate(
					false,
					new Date().setHours(24, 0, 0, 0)
				),
			};
		}

		fd = {
			...fd,
			searchKeywords: formulateSearchKeywords({
				doc: fd,
				type: "invoice",
				userObject,
			}),
		};
		// setList in dashboard to show an outline doc that is loading
		// not using whenLoading here because we want fd to be put into the list so we can have a symbolID
		// setList && setList(list => [...list.slice(0, i+1), {...fd, isLoading: true}, ...list.slice(i+1) ])
		whenLoading && whenLoading(fd);

		// update invoices in user data
		// const dontUpdateUserObject = true
		// an exception for always using updateUserData until we can pass that function in to here
		// await updateUserData({lastInvNumber: fd.invNumber}, dontUpdateUserObject)
		await firebase
			.firestore()
			.collection("users")
			.doc(currentUser.uid)
			.update({
				lastInvNumber: fd.invNumber,
			})
			.catch((err) => {
				throw err;
			});

		// update userObject.lastInvNumber
		setUserObject(userObject => {
			return {
				...userObject,
				lastInvNumber: fd.invNumber
			}
		})
		// add actual invoice
		await newInvoiceDoc.set({
			...fd,
			// creationTime: firebase.firestore.FieldValue.serverTimestamp(),
			creationTime: firebase.firestore.Timestamp.now().seconds
		});
		if (type === "fullCopy" || type === "forwardBill") {
			// clone the subcollection entries into new invoice
			await cloneInvoiceSubCollections({
				invoice: inv,
				newFirebaseDoc: newInvoiceDoc,
				type,
				doNotUpdateCollections: [],
				newLaborEntries
			})
		}
		return fd;
	} catch (err) {
		throw err;
	}
};

export const getSum = (items) => {
	let sum = 0;
	items.forEach((item) => (sum += getNum(item, 100)));
	return sum;
};

// invoked by table ?
const getArrayOfTdVals = (
	allTableEntries,
	tdProperty,
	tdPropertyEqualTo,
	editedInv,
	invoiceTotals,
	setErrorObj
) => {
	// when there are no entries you cant get tdVals
	// this is the case for custom summary entries
	if (!allTableEntries[0] || !allTableEntries[0].values.length) {
		return [];
	}
	const tdVals = allTableEntries.map((row) => {
		// look at row values array and pick out the table cell object (td) by getting the column with a property (eg: for of val) === tdPropertyEqualTo
		// usually tdProperty should be "for" and tdPropertyEqualTo should be the column
		let tdObj = row.values.find((td) => td[tdProperty] === tdPropertyEqualTo);
		// check for formula
		if (
			tdObj.val &&
			(tdObj.for === "cost" || tdObj.for === "qty" || tdObj.for === "total") &&
			tdObj.val.toString().startsWith("=")
		) {
			return parseEntriesRef(editedInv, invoiceTotals, tdObj.val);
		} else {
			return tdObj.val;
		}
	});

	return tdVals;
};

// usdd in editInvoiceContext and Invoice.js
export const getInvoiceTotals = ({
	editedInv,
	workerIsWorking,
	liveEntryTotal,
	invoiceTotals,
}) => {
	let invoiceTotalNames = Object.keys(invoiceTotals);

	if (!invoiceTotalNames || invoiceTotalNames.length === 0) {
		invoiceTotalNames = [
			"materialEntries",
			"laborEntries",
			"paymentEntries",
			"customEntries",
		];
	}

	let liveEntryExpectedQty = 0;
	let liveEntryExpectedTotal = 0;
	const liveEntry = editedInv.laborEntries.find(
		(row) => row.id === editedInv.liveEntryId
	);
	if (liveEntry && workerIsWorking) {
		liveEntryExpectedQty = liveEntry.values.find((td) => td.for === "qty").val;
		liveEntryExpectedTotal = liveEntry.values.find(
			(td) => td.for === "total"
		).val;
	}

	// if no workerIsWorking param or not liveEntryTotal param just return the expected value
	// of the amount of hours the worker was planning to work so if handleSave fired it doesnt
	// update with the wrong total of that moment
	const getLiveTotal = workerIsWorking && liveEntryTotal;

	let newInvoiceTotals = {};
	// get clone of invoiceTotals
	for (let key in invoiceTotals) {
		newInvoiceTotals = {
			...newInvoiceTotals,
			[key]: {
				...invoiceTotals[key],
			},
		};
	}

	// set to 0 anything that is not visible
	const materialEntriesHeading = editedInv.pageOrder.find(
		(item) => item.for === "materialEntries"
	);
	const laborEntriesHeading = editedInv.pageOrder.find(
		(item) => item.for === "laborEntries"
	);
	const paymentEntriesHeading = editedInv.pageOrder.find(
		(item) => item.for === "paymentEntries"
	);
	// const laborEntriesHeading = editedInv.pageOrder.find(item => item.for === "laborEntries")

	// getArrayOfTdVals is still used in getting custom entries so we need to use an updated
	// invoiceTotals  (newInvoiceTotals) in the getArrayOfTdVals function before we update the
	// custom entries in invoiceTotals
	editedInv.pageOrder.forEach((item) => {
		if (item && !item.visible && invoiceTotalNames.includes(item.for)) {
			newInvoiceTotals[item.for].qty = 0;
			newInvoiceTotals[item.for].total = 0;
		}
		// // handle customEntries which can only live inside summaryEntries table
		// if (item.for === "summaryEntries" && !item.visible) {
		// 	newInvoiceTotals.customEntries.total = 0
		// 	newInvoiceTotals.customEntries.qty = 0
		// }
	});

	const newLaborEntryTotal = () => {
		if (getLiveTotal) {
			return {
				total:
					laborEntriesHeading && laborEntriesHeading.visible
						? getSum(
								getArrayOfTdVals(
									editedInv.laborEntries,
									"for",
									"total",
									editedInv,
									newInvoiceTotals
								)
						  ) +
						  (getNum(liveEntryTotal.total, 100) -
								getNum(liveEntryExpectedTotal, 100))
						: 0,
				qty:
					laborEntriesHeading && laborEntriesHeading.visible
						? getSum(
								getArrayOfTdVals(
									editedInv.laborEntries,
									"for",
									"qty",
									editedInv,
									newInvoiceTotals
								)
						  ) +
						  (getNum(liveEntryTotal.qty, 100) -
								getNum(liveEntryExpectedQty, 100))
						: 0,
			};
		} else {
			return {
				total:
					laborEntriesHeading && laborEntriesHeading.visible
						? getSum(
								getArrayOfTdVals(
									editedInv.laborEntries,
									"for",
									"total",
									editedInv,
									newInvoiceTotals
								)
						  )
						: 0,
				qty:
					laborEntriesHeading && laborEntriesHeading.visible
						? getSum(
								getArrayOfTdVals(
									editedInv.laborEntries,
									"for",
									"qty",
									editedInv,
									newInvoiceTotals
								)
						  )
						: 0,
			};
		}
	};

	const customEntries = editedInv.summaryEntries.filter(
		(entry) => entry.id !== "laborTotals" && entry.id !== "materialTotals"
	);

	// change invoice totals using the new invoice totals because some totals were changed to 0 if not visible
	newInvoiceTotals = {
		materialEntries: {
			total:
				materialEntriesHeading && materialEntriesHeading.visible
					? getSum(
							getArrayOfTdVals(
								editedInv.materialEntries,
								"for",
								"total",
								editedInv,
								newInvoiceTotals
							)
					  )
					: 0,
			qty:
				materialEntriesHeading && materialEntriesHeading.visible
					? getSum(
							getArrayOfTdVals(
								editedInv.materialEntries,
								"for",
								"qty",
								editedInv,
								newInvoiceTotals
							)
					  )
					: 0,
		},
		laborEntries: {
			...newLaborEntryTotal(),
		},
		paymentEntries: {
			total:
				paymentEntriesHeading && paymentEntriesHeading.visible
					? getSum(
							getArrayOfTdVals(
								editedInv.paymentEntries,
								"for",
								"total",
								editedInv,
								newInvoiceTotals
							)
					  )
					: 0,
			qty:
				paymentEntriesHeading && paymentEntriesHeading.visible
					? getSum(
							getArrayOfTdVals(
								editedInv.paymentEntries,
								"for",
								"qty",
								editedInv,
								newInvoiceTotals
							)
					  )
					: 0,
		},
		// getArrayOfTdVals must have the updated versions of laborEntries etc because they may have just been updated due to visibility change
		customEntries: {
			// exclude the summary entries which are totals of the materials or labor
			total: getSum(
				getArrayOfTdVals(
					customEntries,
					"for",
					"total",
					editedInv,
					newInvoiceTotals
				)
			),
			qty: getSum(
				getArrayOfTdVals(
					customEntries,
					"for",
					"qty",
					editedInv,
					newInvoiceTotals
				)
			),
		},
	};

	// handle customEntries which can only live inside summaryEntries table
	const summaryEntriesInPageOrder = editedInv.pageOrder.find(item => item.for === "summaryEntries")

	if (!summaryEntriesInPageOrder || !summaryEntriesInPageOrder.visible) {
		newInvoiceTotals.customEntries.total = 0
		newInvoiceTotals.customEntries.qty = 0
	}

	return newInvoiceTotals;
};

export const getTableNameOfThisEntry = (rowId, editedInv) => {
	for (let i = 0; i < subCollectionsList.length; i++) {
		const name = subCollectionsList[i];
		if (name !== "receipts") {
			if (editedInv[name] && editedInv[name].find((ent) => ent.id === rowId)) {
				return name;
			}
		}
	}
	return;
};

export const getBillCosts = (file, bill) => {
	let newCostVal;
	let newPaidVal;

	if (file.fileType === "bill") {
		if (!bill || !bill.invoiceTotals) {
			return {};
		}
		const { laborEntries, materialEntries, paymentEntries, customEntries } =
			bill.invoiceTotals;
		const laborTotal = getNum(laborEntries.total, 100);
		const materialTotal = getNum(materialEntries.total, 100);
		const customEntryTotal = customEntries
			? getNum(customEntries.total || 0, 100)
			: 0;
		const paymentTotal = getNum(paymentEntries.total, 100);

		if (!file.costValueIs) {
			newCostVal = laborTotal + materialTotal + customEntryTotal;
		}
		if (!file.paidValueIs) {
			newPaidVal = 0;
		}

		if (file.costValueIs === "totalCost") {
			newCostVal = laborTotal + materialTotal + customEntryTotal;
		} else if (file.costValueIs === "totalPaid") {
			newCostVal = paymentTotal;
		} else if (file.costValueIs === "totalOwed") {
			newCostVal = laborTotal + materialTotal + customEntryTotal - paymentTotal;
		} else if (file.costValueIs === "totalLabor") {
			newCostVal = laborTotal;
		} else if (file.costValueIs === "totalMaterials") {
			newCostVal = materialTotal;
		}

		if (file.paidValueIs === "totalCost") {
			newPaidVal = laborTotal + materialTotal + customEntryTotal;
		} else if (file.paidValueIs === "totalPaid") {
			newPaidVal = paymentTotal;
		} else if (file.paidValueIs === "totalOwed") {
			newPaidVal = laborTotal + materialTotal + customEntryTotal - paymentTotal;
		} else if (file.paidValueIs === "totalLabor") {
			newPaidVal = laborTotal;
		} else if (file.paidValueIs === "totalMaterials") {
			newPaidVal = materialTotal;
		}
	}

	return {
		cost: getNum(newCostVal, 100),
		paid: getNum(newPaidVal, 100),
	};
};

export const getDefaultTopSectionPageOrder = ({
	contractor,
	invNumber,
	industry,
	billTo,
	description,
	invShortHand,
	fromTemplate,
}) => {


	let newTitle = fromTemplate ? fromTemplate.find(item => item.for === "title") : ""
	newTitle = (newTitle && newTitle.val) ? newTitle.val : "Invoice"

	let defaultPageOrder = [
		{
			className: "title",
			for: "title",
			group: "title",
			label: "Title",
			section: "invoice-heading",
			type: "div",
			val: newTitle,
			visible: true,
		},
		{
			className: "logo",
			for: "logo",
			group: "logo",
			label: "Logo",
			section: "invoice-heading",
			type: "img",
			url: contractor.logoUrl,
			val: contractor.companyName,
			visible: true,
		},
		{
			className: "company-info",
			for: "companyInfo",
			group: "companyInfo",
			label: "Company Info",
			section: "invoice-heading",
			type: "div-divs",
			val: {
				companyName: contractor.companyName, // is either company name or firstname + lastname
				contractorFullAddress: contractor.addressString || "",
				companyEmail: contractor.companyEmail || "",
				phone: contractor.phone || "",
				gstNumber: contractor.gstNumber ? `GST #${contractor.gstNumber}` : "",
			},
			hiddenItems: [],
			visible: true,
		},
		{
			className: "page-spacer",
			// for: "invNumberPageSpacer",
			for: "invoice-heading",
			// group: "title logo company info",
			section: "invoice-heading",
			type: "div",
			val: "",
			visible: true,
		},
		{
			className: "bill-to",
			for: "billTo",
			group: "billTo",
			label: "Bill To",
			maxWidth: "max-content",
			minWidth: "100%",
			// section: "topSectionTables",
			section: "invoice-heading",
			tableHeadings: [
				{
					for: "billTo",
					val: "Bill To",
					visible: true,
				},
			],
			type: "table",
			val: [
				{
					id: "billTo",
					values: [
						{
							for: "billTo",
							val: `${billTo.firstname} ${billTo.lastname}`,
						},
					],
				},
			],
			visible: true,
		},
		{
			className: "date-invoice",
			for: "date-invoice",
			group: "dateInvoice",
			label: "Date / Invoice Number",
			// section: "topSectionTables",
			section: "invoice-heading",
			tableHeadings: [
				{ for: "today", val: "Date", visible: true },
				{ for: "invNumber", val: "Invoice", visible: true },
			],
			type: "div-table",
			val: [
				{
					id: "invNumber",
					values: [
						{
							for: "today",
							noEdit: true,
							val: getLocalISODate(),
							visible: true,
						},
						{
							for: "invNumber",
							val: invNumber + " " + invShortHand || "",
							visible: true,
						},
					],
				},
			],
			visible: true,
		},
		{
			className: "description",
			for: "description",
			group: "description",
			label: "Description",
			minWidth: "100%",
			section: "topSectionTables",
			tableHeadings: [
				{
					for: "description",
					val: "Description",
					visible: true,
				},
			],
			type: "table",
			val: [
				{
					id: "description",
					values: [
						{
							for: "description",
							val: description
								? description
								: `${industry} Invoice for ${billTo.firstname} ${billTo.lastname}`,
						},
					],
				},
			],
			visible: true,
		},
		{
			className: "page-spacer",
			for: "descriptionPageSpacer",
			group: "description",
			section: "topSectionTables",
			type: "div",
			val: "",
			visible: true,
		},
	];

	if (fromTemplate) {
		defaultPageOrder.forEach((item, i) => {
			const itemInTemplate = fromTemplate.find(
				(templateItem) =>
					templateItem.for === item.for && templateItem.type === item.type
			);
			if (itemInTemplate) {
				// overWrite parts of item
				let templateItems = {};
				// filter the template to only push allowable items
				for (let key in itemInTemplate) {
					if (key !== "val") {
						templateItems = { ...templateItems, [key]: itemInTemplate[key] };
					}
				}
				defaultPageOrder[i] = {
					...item,
					...templateItems,
				};
			}
		});
	}

	return defaultPageOrder;
};

// export const updatePageOrderItems = ({items, pageOrder}) => {
// 	let newPageOrder = []
// 	pageOrder.forEach(item => {
// 		let newItem = {...item}
// 		for (let key in items) {
// 			if (items[key].for === item.for && items[key].type === item.type) {
// 				newItem = {...newItem, items[key]}
// 			}
// 		}
// 		newPageOrder.push(newItem)
// 	})
// 	return newPageOrder
// }

export const defaultPageOrder = [
	{
		className: "section-heading",
		for: "materialHeading",
		group: "materials",
		// rows: 1,
		type: "div",
		val: "Material",
		visible: true,
	},
	{
		for: "materialEntries",
		group: "materials",
		// rows: "editedInv.materialEntries.length",
		// rows: 1,
		tableHeadings: defaultTableHeadings,
		type: "table",
		val: "editedInv.materialEntries",
		visible: true,
	},
	{
		for: "materialHeading",
		group: "materials",
		// rows: 1,
		textVal: "Material Total",
		type: "sectionTotal",
		val: "invoiceTotals.materialEntries.total",
		visible: true,
	},
	{
		className: "page-spacer",
		for: "MaterialPageSpacer",
		group: "materials",
		// rows: 1,
		type: "div",
		val: "",
		visible: true,
	},
	{
		className: "section-heading",
		for: "laborHeading",
		group: "labor",
		// rows: 1,
		type: "div",
		val: "Labor",
		visible: true,
	},
	{
		for: "laborEntries",
		group: "labor",
		// rows: "editedInv.laborEntries.length",
		// rows: 1,
		tableHeadings: defaultTableHeadings,
		type: "table",
		val: "editedInv.laborEntries",
		visible: true,
	},
	{
		for: "laborHeading",
		group: "labor",
		// rows: 1,
		textVal: "Labor Total",
		type: "sectionTotal",
		val: "invoiceTotals.laborEntries.total",
		visible: true,
	},
	{
		className: "page-spacer",
		for: "laborPageSpacer",
		group: "labor",
		// rows: 1,
		type: "div",
		val: "",
		visible: true,
	},
	{
		className: "section-heading",
		for: "paymentHeading",
		group: "payments",
		// rows: 1,
		type: "div",
		val: "Payments",
		visible: true,
	},
	{
		for: "paymentEntries",
		group: "payments",
		// rows: "editedInv.paymentEntries.length",
		// rows: 1,
		tableHeadings: [
			{ for: "date", val: "Date", visible: true },
			{ for: "item", val: "Item", visible: true },
			{ for: "qty", val: "Qty", visible: true },
			{ for: "cost", val: "Paid", visible: true },
			{ for: "total", val: "Total", visible: true },
		],
		type: "table",
		val: "editedInv.paymentEntries",
		visible: true,
	},
	{
		for: "paymentHeading",
		group: "payments",
		// rows: 1,
		textVal: "Payment Total",
		type: "sectionTotal",
		val: "invoiceTotals.paymentEntries.total",
		visible: true,
	},
	{
		className: "page-spacer",
		for: "paymentPageSpacer",
		group: "payments",
		// rows: 1,
		type: "div",
		val: "",
		visible: true,
	},
	{
		className: "section-heading",
		for: "summaryHeading",
		group: "summary",
		// rows: 1,
		type: "div",
		val: "Summary",
		visible: true,
	},
	{
		for: "summaryEntries",
		group: "summary",
		tableHeadings: [
			// {for: "date", val: "Date", visible: false},
			{ for: "item", val: "Item", visible: true },
			{ for: "qty", val: "Qty", visible: true },
			// {for: "cost", val: "% Applied to", visible: true},
			{ for: "total", val: "Total", visible: true },
		],
		type: "table",
		val: "editedInv.summaryEntries",
		visible: true,
	},
	// {
	// 	for: "summaryHeading",
	// 	group: "summary",
	// 	// rows: 1,
	// 	textVal: "Total Cost",
	// 	type: "sectionTotal",
	// 	val: "invoiceTotals.....,
	// 	visible: true
	// },

	// {
	// 	className: "page-spacer",
	// 	for: "summaryPageSpacer",
	// 	group: "summary",
	// 	// rows: 1,
	// 	type: "div",
	// 	val: "",
	// 	visible: false
	// },
];

export const getUsername = (id, followersUserObjs, setErrorObj) => {
	const userInFollowersObjs = followersUserObjs
		? followersUserObjs.find((userId) => userId === id)
		: null;
	if (userInFollowersObjs) {
		return userInFollowersObjs.username;
	} else {
		return firebase
			.firestore()
			.collection("users")
			.doc(id)
			.get()
			.then((doc) => doc.data().username)
			.catch((err) => setErrorObj(err));
	}
};

export const sendAddPaymentNotification = (
	collectionName,
	notificationAdditional,
	parentResource
) => {
	const collectionWithNoS = collectionName.slice(0, -1);
	const { paymentData } = notificationAdditional;
	const newNotificationObj = {
		additional: notificationAdditional,
		// date: new Date().toISOString(),
		docLink: {
			// if the docs visibility is private the follow requestor wont be able to know the names or short hand IDs of the docs so add nothing and we add this later in rendering the notification
			name:
				collectionName === "projects"
					? parentResource.projectName
						? `${parentResource.projectName} ${
								parentResource.shortHandId || ""
						  }`
						: ""
					: parentResource.invNumber
					? `Invoice #${parentResource.invNumber} ${
							parentResource.invShortHand || ""
					  }`
					: "",
			pathname:
				collectionName === "projects"
					? `/${collectionName}/${parentResource.id}`
					: `/${parentResource.contractor.companyName || collectionWithNoS}/${
							parentResource.id
					  }`,
			externamLink: "",
		},
		forDocumentCollection: collectionName,
		forDocumentId: parentResource.id,
		forUsers: [parentResource.contractor.id],
		// linkTo: collectionName === "projects" ? `/${collectionName}/${parentResource.id}` : `/${parentResource.contractor.companyName || collectionWithNoS}/${parentResource.id}`,
		// message: "",
		sentBy: paymentData.payer,
		sentByUsername: paymentData.payerUsername,
		type: "addPayment",
	};

	return sendNotification(newNotificationObj);
};

// billTo functions
// run if user is owner or user is editor or user is following and inv is private
// change this to just return the  new invoice
// this is only for changing the billTo of invoices
export const handleChangeBillTo = (
	e,
	editedInv,
	handleSetEditedInv,
	followersUserObjs,
	userObjectUid,
	setErrorObj,
	handleSetSuccessText,
	oldBillTo
) => {
	e && e.preventDefault();
	// when accepting an inv the editedInv param has updated billTo info with uid
	const newBillTo = editedInv.billTo;
	if (!oldBillTo) {
		oldBillTo = { ...newBillTo };
	}
	// get a string value for the address
	let userFullAddress = "";
	if (newBillTo.address && typeof(newBillTo.address === "object") && Object.keys(newBillTo.address).length) {
		const address = newBillTo.address;
		let addressArray = [];

		address.line1 && addressArray.push(address.line1);
		address.line2 && addressArray.push(address.line2);
		address.city && addressArray.push(address.city);
		address.state && addressArray.push(address.state);
		address.postal_code && addressArray.push(address.postal_code);
		address.country && addressArray.push(address.country);
		userFullAddress = addressArray.join(", ");
	}

	// if user is invoice owner or current bill to or an editor
	if (
		editedInv.owner === userObjectUid ||
		oldBillTo.uid === userObjectUid ||
		editedInv.editors.includes(userObjectUid)
	) {
		const val = `${newBillTo.firstname} ${newBillTo.lastname} ${
			userFullAddress ? "\n" + userFullAddress : ""
		}`;
		let newEditedInv = handleTdChange(
			{ for: "billTo", val },
			{
				...editedInv,
				billTo: {
					...editedInv.billTo,
					...newBillTo,
					userFullAddress,
				},
			},
			"billTo",
			"billTo"
		);
		let newFollowers = [...newEditedInv.followers];

		// add billTo as a follower
		if (newBillTo.uid && !editedInv.followers.includes(newBillTo.uid)) {
			newFollowers.push(newBillTo.uid);
		}

		// if offline mode promise doesnt resolve so separate returns
		if (editedInv.forProject) {
			// handleSave({
			// 	newEditedInv: {
			// 		...newEditedInv,
			// 		followers: [...newFollowers] /*, editors: newEditors*/,
			// 	},
			// });
			handleSetEditedInv({changes: {...newEditedInv, followers: [...newFollowers]}, caller: "invoiceUtils - handleChangeBillTo - forProject"})

			return addEditorsToProject(
				userObjectUid,
				editedInv,
				followersUserObjs,
				setErrorObj,
				handleSetSuccessText
			);
		} else {
			// return handleSave({
			// 	newEditedInv: {
			// 		...newEditedInv,
			// 		followers: [...newFollowers] /*, editors: newEditors*/,
			// 	},
			// });
			return handleSetEditedInv({changes: {...newEditedInv, followers: [...newFollowers]}, caller: "invoiceUtils - handleChangeBillTo - no forProject"})
		}
	} else {
		// only update the billTo
		// if offline mode promise doesnt resolve so seperate returns
		if (editedInv.forProject) {
			firebase
				.firestore()
				.collection("invoices")
				.doc(editedInv.id)
				.update({
					billTo: {
						...newBillTo,
						userFullAddress,
					},
				})
				.catch((err) => {
					setErrorObj(err);
				});
			return addEditorsToProject(
				userObjectUid,
				editedInv,
				followersUserObjs,
				setErrorObj,
				handleSetSuccessText
			);
		} else {
			return firebase
				.firestore()
				.collection("invoices")
				.doc(editedInv.id)
				.update({
					billTo: {
						...newBillTo,
						userFullAddress,
					},
				});
			// dont need catch because promise returned
		}
	}
};

// adds invoice owner (and ?) or billTo to as project editor
export const addEditorsToProject = async (
	userObjectUid,
	editedInv,
	followersUserObjs,
	setErrorObj,
	handleSetSuccessText
) => {
	const projectRef = firebase
		.firestore()
		.collection("projects")
		.doc(editedInv.forProject);
	const getNotificationObj = async (sentBy) => {
		try {
			if (!sentBy) {
				return;
			}

			const userInFollowersObjs = followersUserObjs.find(
				(follower) => follower.id === sentBy
			);
			let sentByUsername = userInFollowersObjs
				? userInFollowersObjs.username
				: "";
			if (!sentByUsername) {
				sentByUsername = await firebase
					.firestore()
					.collection("users")
					.doc(sentBy)
					.get()
					.then((doc) => doc.data().username);
			}

			return {
				docLink: {
					name: "", // this is found in notificationsModal
					pathname: `/projects/${editedInv.forProject}`,
					externamLink: "",
				},
				forDocumentCollection: "projects",
				forDocumentId: editedInv.forProject,
				// forUsers: forUsersArray,
				sentBy,
				// sentByUsername: userInFollowersObjs ? userInFollowersObjs.username : await,
				type: "editRequest",
				sentByUsername,
			};
		} catch (err) {
			setErrorObj(err);
		}
	};

	try {
		// need to handle if offline
		const projectDoc = await projectRef
			.get()
			.then((doc) => ({ ...doc.data(), id: doc.id }));

		const userIsProjectEditorOrOwner =
			projectDoc.editors.includes(userObjectUid) ||
			projectDoc.owner === userObjectUid;
		const userIsInvoiceOwner = editedInv.owner === userObjectUid;
		const userIsNewBillTo = editedInv.billTo.uid === userObjectUid;
		// assume invoice owner and new billTo need to be added
		// let addProjectEditors = [editedInv.owner]
		// if (editedInv.billTo.uid) {
		// 	addProjectEditors.push(editedInv.billTo.uid)
		// }

		// let newProjectEditor
		// who is being added as project edotpr ?
		// this fumnction can be invoked from notifications modal or accept bill
		// or change project

		if (userIsProjectEditorOrOwner) {
			// add both editor and owner even if theyre already listed
			// const arrayToUnion = [editedInv.owner, editedInv.billTo.uid].filter(truthy => truthy)
			// await projectRef.update({
			// 	// arrayUnion adds to array and doesnt add if item already exists
			// 	editors: firebase.firestore.FieldValue.arrayUnion(...arrayToUnion),
			// 	accessors: firebase.firestore.FieldValue.arrayUnion(...arrayToUnion),
			// 	followers: firebase.firestore.FieldValue.arrayUnion(...arrayToUnion)
			// })
			if (!projectDoc.editors.includes(editedInv.owner)) {
				await projectRef.update({
					// arrayUnion adds to array and doesnt add if item already exists
					editors: firebase.firestore.FieldValue.arrayUnion(editedInv.owner),
					accessors: firebase.firestore.FieldValue.arrayUnion(editedInv.owner),
					followers: firebase.firestore.FieldValue.arrayUnion(editedInv.owner),
				});
				if (editedInv.owner === editedInv.contractor.id) {
					handleSetSuccessText(
						`${
							editedInv.contractor.username || "User" + editedInv.contractor
						} added as editor for '${projectDoc.projectName}'`,
						6000
					);
				} else {
					// dont add contractor if they arent owner ?
					handleSetSuccessText(
						`Invoice owner added as editor for '${projectDoc.projectName}'`,
						6000
					);
				}
			}
			if (
				editedInv.billTo.uid &&
				!projectDoc.editors.includes(editedInv.billTo.uid) &&
				editedInv.owner !== editedInv.billTo.uid
			) {
				await projectRef.update({
					// arrayUnion adds to array and doesnt add if item already exists
					editors: firebase.firestore.FieldValue.arrayUnion(
						editedInv.billTo.uid
					),
					accessors: firebase.firestore.FieldValue.arrayUnion(
						editedInv.billTo.uid
					),
					followers: firebase.firestore.FieldValue.arrayUnion(
						editedInv.billTo.uid
					),
				});
				handleSetSuccessText(
					`${
						editedInv.billTo.username || "User" + editedInv.billTo.uid
					} added as editor for '${projectDoc.projectName}'`,
					6000
				);
			}
		} else {
			// sendNotification ...
			let newEditorsArray = [];
			if (userIsInvoiceOwner) {
				// if the owner is doesnt have project edit capabilities
				newEditorsArray.push(editedInv.owner);
			}
			if (userIsNewBillTo) {
				// if the billTo doesnt have project edit capabilities
				newEditorsArray.push(editedInv.billTo.uid);
			}

			for (let i = 0; i < newEditorsArray.length; i++) {
				const editor = newEditorsArray[i];
				const newNotificationObj = await getNotificationObj(editor);
				sendNotification(newNotificationObj);
			}
		}
	} catch (err) {
		if (err.message.includes("Missing or insufficient permissions")) {
			// sendNotification ...
			if (
				editedInv.owner === userObjectUid ||
				editedInv.billTo.uid === userObjectUid
			) {
				// if the current user cant access the project then add the current user to the project
				const newNotificationObj = await getNotificationObj(userObjectUid);
				sendNotification(newNotificationObj);
			}
		} else {
			setErrorObj(err);
		}
	}
};

// adds invoice editors, billTo and owner as project followers
export const addFollowersToProject = async (
	userObjectUid,
	editedInv,
	followersUserObjs,
	setErrorObj,
	handleSetSuccessText
) => {
	const projectRef = firebase
		.firestore()
		.collection("projects")
		.doc(editedInv.forProject);

	try {
		const projectDoc = await projectRef
			.get()
			.then((doc) => ({ ...doc.data(), id: doc.id }));

		let allInvEditorsAndBillTo = [
			...editedInv.editors,
			editedInv.owner,
			editedInv.billTo.uid,
		].filter((isTruthy) => isTruthy);
		allInvEditorsAndBillTo = Array.from(new Set(allInvEditorsAndBillTo));

		// let allProjectAccessors = projectDoc.accessors

		// // list any invoice editors, inv billTo or owner if they dont have access to project already
		// const allNewProjectFollowers = allInvEditorsAndBillTo.filter(userId => !allProjectAccessors.includes(userId))

		// // auto add all invoice followers to project if user is owner, editor or billTo of project
		const userCanEditProject =
			projectDoc.editors.includes(userObjectUid) ||
			projectDoc.owner === userObjectUid ||
			projectDoc.billTo.uid === userObjectUid;

		// add project editors, owner or billTo to the project followers array
		if (userCanEditProject || projectDoc.visibility !== "private") {
			// should always be true because project shouldnt be listed if user is not part of it

			// if (allNewProjectFollowers.length) {
			await projectRef.update({
				// input the whole allInvEditorsAndBillTo array here because arrayUnion only adds if not already present
				followers: firebase.firestore.FieldValue.arrayUnion(
					...allInvEditorsAndBillTo
				),
				accessors: firebase.firestore.FieldValue.arrayUnion(
					...allInvEditorsAndBillTo
				),
				// editors: firebase.firestore.FieldValue.arrayUnion(...allNewProjectEditors)
			});

			handleSetSuccessText(
				"Invoice editors have been added as followers of this project",
				5000
			);
			// }
			// else send follow requests for all the editors of this inv to the project
		}

		// dont use else because sending follow requests for other prople is weird

		// else {
	} catch (err) {
		if (!err.message.includes("Missing or insufficient permissions")) {
			setErrorObj(err);
		}
	}
};

// returns new edited Invoice when a table cell changed
// can only handle first layer Object nesting without mutating
export const handleTdChange = (newTd, inv, tableName, rowId, setActiveRow) => {
	// let newVal = newTd.val.trim()
	let newEditedInv = { ...inv }; // only copies first layer nesting
	// newEditedInv.something = something is okay
	// newEditedInv.something.child = something is NOT OKAY

	let newVal = newTd.val;
	let tdFor = newTd.for;
	if (tdFor === "billTo" || tdFor === "description" || tdFor === "invNumber" || tdFor === "today") {
		// for billTo table this doesnt change the actual billTo obj in the inv, only the topSectionPageOrder unless newEditedInv has the new billTo Info in it

		if (tdFor === "description") {
			// do not update the actual page order value it is a formula
			// description value in page order is a formula that updates as soon as editedInc.description updates
			return { ...newEditedInv, description: newVal };
		} else {
			let newTopSectionPageOrder = [];

			if (tdFor === "invNumber") {
				// the invoice number ends upon first space " " or first letter
				// the invoice quickId is the next 3 letters excluding any spaces
				newVal = newVal.replace(/#+/g, "");
				// replace double spaces
				newVal = newVal.replace(/\s+/g, " ");
				const newValMatchNumbers = newVal.match(/\d+/);
				let number = newValMatchNumbers ? newValMatchNumbers[0] : "";
				// let letterId = newVal.slice(newVal.search(" ")+1).slice(0,3)

				const indexOfSpace = newVal.search(" ");
				let letterId = "";
				if (indexOfSpace > -1) {
					letterId = newVal
						.slice(indexOfSpace + 1)
						.trim()
						.replace(" ", "")
						.slice(0, 3);
				} else {
					letterId = newVal
						.replace(number, "")
						.trim()
						.replace(" ", "")
						.slice(0, 3);
				}
				newEditedInv[tdFor] = number;
				newEditedInv.invShortHand = letterId;
			}

			newTd = { ...newTd, val: newVal };

			newEditedInv.topSectionPageOrder.forEach((item) => {
				if (item.for === tableName) {
					let newTableRows = [];
					item.val.forEach((row) => {
						if (row.id === rowId) {
							let newValues = [];
							row.values.forEach((td) => {
								if (td.for === tdFor) {
									// newValues.push({...td, val: newVal})
									newValues.push({ ...td, ...newTd });
								} else {
									newValues.push(td);
								}
							});
							newTableRows.push({ ...row, values: newValues });
						} else {
							newTableRows.push(row);
						}
					});
					newTopSectionPageOrder.push({ ...item, val: newTableRows });
				} else {
					newTopSectionPageOrder.push(item);
				}
			});

			return { ...newEditedInv, topSectionPageOrder: newTopSectionPageOrder };
		}
	} else {
		const newEntries = getNewEntriesOnTdChange(
			newTd,
			newEditedInv,
			tableName,
			rowId,
			setActiveRow
		);
		// setNewEditedInv(newEditedInv => ({...newEditedInv, [tableName]: newEntries}))
		return { ...newEditedInv, [tableName]: newEntries };
	}
};

export const getNewEntriesOnTdChange = (
	newTd,
	newEditedInv,
	tableName,
	rowId,
	setActiveRow
) => {
	// only changes the newTd and totals
	const tdFor = newTd.for;
	let newEntries = [];
	const rowIndex = newEditedInv[tableName].findIndex((row) => row.id === rowId);
	const dateColumn = newEditedInv[tableName][rowIndex].values.findIndex(
		(td) => td.for === "date"
	);

	newEditedInv[tableName].forEach((ent) => {
		const qtyColumn = ent.values.findIndex((td) => td.for === "qty");
		const costColumn = ent.values.findIndex((td) => td.for === "cost");
		const totalColumn = ent.values.findIndex((td) => td.for === "total");

		// MUST CREATE NEW OBJ OTHERWISE THINGS GET MUTATED
		const newEnt = JSON.parse(JSON.stringify(ent))

		if (newEnt.id === rowId) {
			// change the values
			let newValues = [];
			newEnt.values.forEach((td) => {
				let fullNewTd = td;

				// update the new td
				if (td.for === tdFor) {
					let newTdVal = newTd.val
					if (td.for === "cost" || td.for === "qty" || td.for === "total") {
						newTdVal = parseFloat(newTd.val)
						// might be able to use below in case someone uses a .00  in the value
						// newTdVal = newTdVal.toString().length === newTd.val.toString().length ? newTdVal : newTd.val
					}

					fullNewTd = { ...td, ...newTd, val: newTdVal};
					// update the main property in ent.value
					if (newEnt[td.for]) {
						newEnt[td.for] = fullNewTd.val
					}
				}


				// get rid of all td.disabled properties
				// this should be checked in Table and only exist on front end
				if (td.disabled) {
					fullNewTd = { ...fullNewTd };
					delete fullNewTd.disabled;
				}

				newValues.push(fullNewTd);
			});

			// update the total
			// if the total column noEdit iss true dont change the total column, this must be changed directly by using td.for === "total"
			if (
				totalColumn > -1 &&
				costColumn > -1 &&
				qtyColumn > -1 &&
				!newValues[totalColumn].noEdit
			) {
				const newTotal = getNum(newValues[costColumn].val, 100) * getNum(newValues[qtyColumn].val, 100);

				newValues[totalColumn].val = newTotal
				if (newEnt.total) {
					newEnt.total = newTotal
				}
			}

			newEntries.push({ ...newEnt, values: newValues });
		} else {
			newEntries.push(newEnt);
		}
	});

	if (dateColumn > -1) {
		newEntries = newEntries.sort((a, b) => {
			const d1 = new Date(a.values[dateColumn].val);
			const d2 = new Date(b.values[dateColumn].val);
			return d1 - d2;
		});
		// keep the active row even though the row index changes
		const rowIndexOfNewEntry = newEntries.findIndex((row) => row.id === rowId);
		if (rowIndexOfNewEntry !== -1 && setActiveRow) {
			setActiveRow((activeRow) => ({ ...activeRow, i: rowIndexOfNewEntry }));
		}
	}

	// do not setEditedInv here because receipts can contain Blobs and src
	return newEntries;
};

// invoked by handleSave, update the main body of the invoice in DB
export const updateInvoice = (
	invoiceId,
	newInvWithoutEntries,
	setErrorObj,
	handleSetSuccessText
) => {
	return firebase
		.firestore()
		.collection("invoices")
		.doc(invoiceId)
		.set(
			{
				...newInvWithoutEntries,
			},
			{ merge: true }
		)
		.then(() => {
			handleSetSuccessText("All changes saved");
			return true;
		})
		.catch((err) => {
			setErrorObj(err);
		});
};

export const handleRemoveReceipts = (
	editedInv,
	rowId,
	setErrorObj,
	offlineMode
) => {
	// get all receipts that are for this row in the table
	return firebase
		.firestore()
		.collection("invoices")
		.doc(editedInv.id)
		.collection("receipts")
		.where("rowId", "==", rowId)
		.get()
		.then((snapshot) => {
			// chack if subcollection exists
			if (snapshot.docs.length) {
				snapshot.docs.forEach((doc) => {
					if (offlineMode) {
						if (
							doc.data().fileType !== "bill" &&
							doc.data().fileType !== "url" &&
							!doc.data().fromForwardedBill
						) {
							firebase
								.storage()
								.ref(
									doc.data().storagePath ||
										`${editedInv.owner}/receipts/${doc.id}`
								)
								.delete()
								.catch((err) => {
									if (err.code === "storage/object-not-found") {
										console.log("Receipt not found")
										console.error(err)
									} else if (err.code === "storage/unauthorized") {
										console.error(err)
									} else {
										throw err;
									}
								});
						}
						doc.ref.delete();
					} else {
						if (
							doc.data().fileType !== "bill" &&
							doc.data().fileType !== "url" &&
							!doc.fromForwardedBill
						) {
							return firebase
								.storage()
								.ref(
									doc.data().storagePath ||
										`${editedInv.owner}/receipts/${doc.id}`
								)
								.delete()
								.then(() => {
									return doc.ref.delete();
								})
								.catch((err) => {
									if (err.code === "storage/object-not-found") {
										console.log("Receipt not found")
										console.error(err)
									} else if (err.code === "storage/unauthorized") {
										console.error(err)
									} else {
										throw err;
									}
								});
						} else return doc.ref.delete();
					}
				});
			} else return;
		})
		.catch((err) => setErrorObj(err));
};

// remove main body of inv in DB and all subcollections, for the receopts subcollection, get thr storage ref and delete file from storage
export const handleRemoveInvoice = async (
	invToBeDeleted,
	setErrorObj,
	whenLoading,
	dialog
) => {
	try {
		let windowResult;
		// const invToBeDeleted = usersInvoices.find(inv => inv.id === id)
		if (invToBeDeleted && invToBeDeleted.billTo.uid) {
			// check if billTo has forwarded this bill and if so just remove current user as owner
			windowResult = await dialog.confirm(
				`Warning: ${
					"@" + invToBeDeleted.billTo.username ||
					invToBeDeleted.billTo.firstname ||
					"User: " + invToBeDeleted.billTo.uid
				} has accepted this invoice as a bill! Once deleted They will no longer be able to view it. \n\nConfirm delete invoice? This will delete any receipt files contained within the invoice as well`
			);
		} else {
			windowResult = await dialog.confirm(
				"Are you sure you want to delete this invoice? Doing so will delete any receipt files contained within the invoice as well"
			);
		}

		if (windowResult) {
			// dont set user invoices here ? check what calls this fn...
			whenLoading && whenLoading();
			// setUsersInvoices(usersInvoices => [...usersInvoices.filter(inv => inv.id !== id)])
			// delete actual invoice
			await firebase
				.firestore()
				.collection("invoices")
				.doc(invToBeDeleted.id)
				.delete();

			// delete all subCollections... can you add the function above to a batch ?
			subCollectionsList.forEach((col) => {
				const batch = firebase.firestore().batch();
				const entries = firebase
					.firestore()
					.collection("invoices")
					.doc(invToBeDeleted.id)
					.collection(col);
				entries
					.get()
					.then((snapshot) => {
						// if snapshot.exists...
						if (!snapshot.empty) {
							return snapshot.docs.forEach((ent) => {
								// if col === "receipts" go to storage and delete the documents
								if (col === "receipts") {
									// delete the file from storage
									const doc = ent.data();
									if (doc.fileType !== "bill" && doc.fileType !== "url" && !doc.fromForwardedBill) {
										firebase
											.storage()
											.ref(
												doc.storagePath ||
													`${invToBeDeleted.owner}/receipts/${ent.id}`
											)
											.delete()
											.catch((err) => {
												if (err.code === "storage/object-not-found") {
													console.log("Receipt not found")
													console.error(err)
												} else if (err.code === "storage/unauthorized") {
													console.error(err)
												} else {
													setErrorObj(err)
												}
											});
									}
									// delete instead with cloud function ? see post https://medium.com/google-developer-experts/automatically-delete-your-firebase-storage-files-from-firestore-with-cloud-functions-for-firebase-36542c39ba0d
								}
								batch.delete(ent.ref); // batch.delete().catch is not a function
							});
						}
					})
					.then(() => batch.commit())
					// .then(() => handleSetSuccessText("Invoice removed"))
					.catch((err) => setErrorObj(err));
			});

			// handle if this invoice has a levied invoice
			if (invToBeDeleted.leviedInvoice) {
				// unpair the levied invoice
				// dont await just catch error and set err obj
				firebase.firestore().collection("invoices").doc(invToBeDeleted.leviedInvoice).set({
					// do not use pairedInvoices since we cant easily know if the updater is owner of the pairedInvoice item
					// pairedInvoices: firebase.firestore.FieldValue.arrayRemove(invToBeDeleted.id),
					pairedInvoiceOptions: { // subcontractor shouldnt be able to edit all parts of this
						[invToBeDeleted.id]: {
							unpaired: true,
							deleted: true,
							lastUpdated: firebase.firestore.Timestamp.now().seconds,
						}
					},
					pairedInvoices: firebase.firestore.FieldValue.arrayRemove(invToBeDeleted.id),
					// must include  a lastUpdatedByDoc so that we can check in firestore rules if the user can update this doc
					lastUpdatedByDoc: invToBeDeleted.id,
						// cant remove restricted editors because this is a restricted editor updating the forwarded bill and security rules only allow if in restricted editor array
				}, { merge: true }).catch(err => {
					err.metadata = {
						message: "caught in handleRemoveInvoice invToBeDeleted.leviedInvoice"
					}
					setErrorObj(err)
				})
			}

			// handle if this invoice is a levied invoice
			// delete all pairs
			if (invToBeDeleted.pairedInvoices && invToBeDeleted.pairedInvoices.length) {
				for (let i=0; i<invToBeDeleted.pairedInvoices.length; i++) {
					const pairId = invToBeDeleted.pairedInvoices[i]

					// check first if not already deleted ? update should only update if doc not deleted 
					firebase.firestore().collection("invoices").doc(pairId).update({
						leviedInvoice: "",
						leviedInvoiceOptions: null
					}).catch(err => {
						err.metadata = {
							message: "caught in handleRemoveInvoice invToBeDeleted.leviedInvoice",
							guess: "the doc trying to update might have been deleted: doc: " + pairId
						}
						setErrorObj(err)
					})
				}
			}

			// handleSetSuccessText("Invoice removed")
			// dont await subcollection removal
			return true;
		} else return false;
	} catch (err) {
		// setErrorObj(err)
		throw err;
		// return false
	}
};

export const evaluateFormulaArray = (formulaArray) => {
	// could just eval() formula if it was a string but eval is "evil"
	let total = 0;
	for (let i = 0; i < formulaArray.length; i++) {
		// for BEDMAS notes:
		// let string = formulaArray.toString().replace(/\,/g, " ")
		// let operatorsArray = [...string.matchAll(/[\/\-\*\+]/g)]
		const currentProp = formulaArray[i];
		const prevProp = formulaArray[i - 1];
		const nextProp = formulaArray[i + 1];
		const currentPropIsOperator = currentProp
			? currentProp.toString().length === 1 &&
			  currentProp.toString().match(/[/\-*+]/)
			: false;
		if (currentPropIsOperator) {
			if (currentProp === "+") {
				total = parseFloat(prevProp) + parseFloat(nextProp);
			}
			if (currentProp === "-") {
				total = parseFloat(prevProp) - parseFloat(nextProp);
			}
			if (currentProp === "*") {
				total = parseFloat(prevProp) * parseFloat(nextProp);
			}
			if (currentProp === "/") {
				if (parseFloat(nextProp) === 0) {
					total = "!DIV0";
				} else {
					total = parseFloat(prevProp) / parseFloat(nextProp);
				}
			}

			i += 1;
			if (i < formulaArray.length) {
				formulaArray[i] = total;
			}
		}
	}

	return total;
};

// get the actual values to a string reference
export const parseEntriesRef = (editedInv, invoiceTotals, itemName) => {
	// when rendering a table, parse the DB value into an actual value for display
	const getParsedVal = (itemName) => {
		let parsedVal = "";
		if (typeof itemName !== "string") {
			return itemName;
		}

		if (!itemName.startsWith("=")) {
			return itemName;
		}

		const isNumber = (str) => {
			const s = str.replace(/\s/g, "");
			if (!isNaN(parseFloat(s)) && typeof parseFloat(s) === "number") {
				return true;
			} else return false;
		};

		const getOperator = (str) => {
			// if (str.match(/[\/\-\*\+]/)) {
			// handle negative numbers

			if (str.length === 1) {
				return str.match(/[/\-*+]/);
			} else return null;
			// } else return false
		};

		const getParsed = (str) => {
			let newVal;
			if (str.startsWith("editedInv")) {
				if (!editedInv || !editedInv.id) {
					console.log("ERROR: no editedInv property");
				}
				newVal = editedInv;
			}
			if (str.startsWith("invoiceTotals")) {
				if (!invoiceTotals) {
					console.log("ERROR: no invoiceTotals property");
				}
				newVal = invoiceTotals;
			}
			if (str.includes(".") && newVal) {
				const childArray = str.split(".");
				childArray.shift();
				childArray.forEach((child) => {
					newVal = newVal[child];
				});
			}

			return newVal;
		};

		const getNewItemName = (name) => {
			// remove all spaces in operators then add back in to handle array split based on spaces
			itemName = name.replace(/\s/g, "");
			itemName = itemName.replace("=", "= ");
			itemName = itemName.replace(/\+/g, " + ");
			// itemName = itemName.replace(/-/g, " - ")
			itemName = itemName.replace(/\*/g, " * ");
			itemName = itemName.replace(/\//g, " / ");

			return itemName;
		};

		const getValSplit = (name) => {
			// handle decimal numbers
			// find all decimal numbers
			let customDecimalNumbers = name.match(/\d+\.+\d+/g); // eg: [0.05, 10.234]
			// replace all decimal numbers with placeholder
			let itemName = name.replace(/\d+\.+\d+/g, "customDecimalNumber");
			// split the itemName into an array
			// split based on spaces or "."
			// let valSplit = itemName.split(/[\s.]/)
			// split based on white space
			let valSplit = itemName.split(/\s/);
			// replace all customDecimalNumber with the actual number
			valSplit = valSplit.map((item) => {
				if (item === "customDecimalNumber") {
					let newItem = customDecimalNumbers[0];
					customDecimalNumbers.shift();
					return newItem;
				} else if (item === "-customDecimalNumber") {
					let newItem = customDecimalNumbers[0];
					customDecimalNumbers.shift();
					return "-" + newItem;
				} else return item;
			});
			return valSplit;
		};

		itemName = getNewItemName(itemName);

		const valSplit = getValSplit(itemName);

		// check for formulas
		if (valSplit[0] === "=") {
			// parse formula
			let formulaArray = [];
			valSplit.forEach((item, i) => {
				if (i > 0) {
					if (getOperator(item) || isNumber(item)) {
						formulaArray.push(item);
					} else {
						formulaArray.push(getParsed(item));
					}
				}
			});

			if (formulaArray.length > 1) {
				parsedVal = evaluateFormulaArray(formulaArray);
			} else {
				parsedVal = formulaArray[0];
			}
		} else {
			// should never happen because itemName.startsWith at top automatically returns itemName
			console.log(
				"valSplit does not begin with '=' and !partOfArray",
				valSplit,
				itemName
			);
		}
		return parsedVal;
	};

	let parsedVal = "";

	if (
		itemName.startsWith("=") &&
		itemName.includes("[") &&
		itemName.includes("]")
	) {
		// ignores anything not in the brakets
		const indexOfOpenBraket = itemName.indexOf("[");
		const indexOfCloseBraket = itemName.indexOf("]");

		const jsonArray = itemName.slice(indexOfOpenBraket, indexOfCloseBraket + 1);
		const parseItemsArray = JSON.parse(jsonArray);
		parseItemsArray.forEach((formula) => {
			parsedVal += getParsedVal(formula, true);
		});
		return parsedVal;
	} else {
		return getParsedVal(itemName);
	}
};

// get the actual values within the pageOrder object which just contains a string reference eg: "editedInv.materialEntries"
export const parsePageOrderReferences = (
	editedInv,
	pageOrder,
	invoiceTotals,
	pageOrderName
) => {
	// handle pageOrder's that dont need parsing
	if (pageOrderName !== entriesPageOrderName) {
		return pageOrder;
	}

	let newPageOrder = [];
	// pageOrder.forEach(item => newPageOrder = [...newPageOrder, {...item}]) // sufficient to keep state immutable ?

	// newPageOrder = pageOrder.forEach((item, i) => {
	pageOrder.forEach((item, i) => {
		// work around for when layout for columns on table is changed, when this is changed it updates
		// pageOrder in editedInv
		if (typeof item.val !== "string") {
			// return item
			newPageOrder.push(item);
		}

		// get the actual values from the db reference
		const valSplit = item.val.split(".");
		// const rowsSplit = isNaN(parseFloat(item.rows)) ? item.rows.split(".") : []
		let newItem = { ...item };
		if (newItem.type === "sectionTotal" && newItem.textVal) {
			newItem = {
				...newItem,
				textVal: `${
					editedInv[entriesPageOrderName].find((obj) => obj.for === item.for)
						.val
				} Total`,
			}; // val on section headings must always be a string
		}

		if (valSplit[0] === "editedInv") {
			let newVal = editedInv;
			valSplit.forEach((property, i) => {
				if (i !== 0) {
					newVal = newVal[property];
				}
			});
			newItem = { ...newItem, val: newVal };
		}

		if (valSplit[0] === "invoiceTotals") {
			let newVal = invoiceTotals;
			valSplit.forEach((property, i) => {
				if (i !== 0) {
					newVal = newVal[property];
				}
			});
			newItem = { ...newItem, val: newVal };
		}

		newPageOrder.push(newItem);
	});
	return newPageOrder;
};

export const matchesDefaultEntry = ({entry, collection, invoice}) => {
	let entryWithoutIsDefault = {...entry}
	delete entryWithoutIsDefault.isDefaultEntry

	let defaultEntries = getDefaultEntries(collection, invoice.industry, invoice.rate, invoice.id)
	let defaultEntry
	if (defaultEntries && defaultEntries.length) {
		defaultEntry = {...defaultEntries[0]}
		// set the id's to be the same for purpose of deepObjectCompareDiffers
		defaultEntry.id = entry.id
		delete defaultEntry.isDefaultEntry
	}

	if (deepObjectCompareDiffers({a: entryWithoutIsDefault, b: defaultEntry})) {
		return false

	} else {
		return true
	}

}

export const getDefaultEntries = (
	subCollectionName,
	industry,
	rate,
	invoiceId
) => {
	const getValues = ({date, item, qty, cost, total, type}) => {
		return {
			date, item, qty, cost, total, // add in as direct property of entry so that paired invoices can do blind  updates
			values: [
				// td's
				{ for: "date", val: date},
				{ for: "item", val: item, ...type ? {type} : {} },
				{ for: "qty", val: qty },
				{ for: "cost", val: cost },
				{ for: "total", val: total },
			]
		}
	}

	let newDoc;
	if (invoiceId && subCollectionName) {
		newDoc = firebase
			.firestore()
			.collection("invoices")
			.doc(invoiceId)
			.collection(subCollectionName)
			.doc();
	}
	let firstEntryId = newDoc ? newDoc.id : null;
	// default values
	const date = getLocalISODate()
	let item = ""
	let qty = 0
	let cost = 0
	const total = 0

	if (subCollectionName === "materialEntries") {
		qty = 1
		item = "View Receipt"
		return [
			// row
			{
				id: firstEntryId || "firstMaterialEntry",
				isDefaultEntry: true,
				...getValues({date, item, qty, cost, total, type: "link"}),
			},
		];
	} else if (subCollectionName === "laborEntries") {
		item = industry || "Time"
		cost = parseFloat(getNum(rate, 2)) // get num can return a str if second arg is 2
		return [
			{
				id: firstEntryId || "firstLaborEntry",
				isDefaultEntry: true,
				...getValues({date, item, qty, cost, total}),
			},
		];
	} else if (subCollectionName === "paymentEntries") {
		qty = 1
		return [
			{
				id: firstEntryId || "firstPaymentEntry",
				isDefaultEntry: true,
				...getValues({date, item, qty, cost, total}),
			},
		];
	} else return [];
};
