import React, { useEffect, useRef } from 'react'


// BILLING NOTE
// " The session begins when the user starts typing a query, and concludes 
// when they select a place. Each session can have multiple queries, followed 
// by one place selection. Once a session has concluded, the token is no longer 
// valid. Your app must generate a fresh token for each session. ""
// https://developers.google.com/maps/documentation/javascript/places-autocomplete#:~:text=.%20The%20session%20begins%20when%20the%20user%20starts%20typing%20a%20query%2C%20and%20concludes%20when%20they%20select%20a%20place.%20Each%20session%20can%20have%20multiple%20queries%2C%20followed%20by%20one%20place%20selection.%20Once%20a%20session%20has%20concluded%2C%20the%20token%20is%20no%20longer%20valid.%20Your%20app%20must%20generate%20a%20fresh%20token%20for%20each%20session.


const AutoCompletePlace = ({inputProperties, options, setPlaceDetails, handleChange}) => {

	const locationInputRef = useRef(null)
	const autoCompleteRef = useRef(null)

	useEffect(() => {

	  if (locationInputRef.current && window.google && window.google.maps && window.google.maps.places) {

			autoCompleteRef.current = new window.google.maps.places.Autocomplete(locationInputRef.current, {
		    componentRestrictions: { country: ["ca"] },
		    fields: ["geometry"],
		    // types: ["(cities)"],
		    ...options || {}
		  })
	  }
	  // do not include options because it re creates he dropdown element
	  // eslint-disable-next-line
	}, [locationInputRef])


	useEffect(() => {
		let addedListener = false
		const handlePlaceChanged = () => {
      const place = autoCompleteRef.current.getPlace()
			console.log({place, autoCompleteRef})
      // Do something with the resolved place here (ie store in redux state)

      if (setPlaceDetails) {
		  	setPlaceDetails(placeDetails => {
		  		return [
		  			...placeDetails,
			  		{...place, name: locationInputRef.current.value}
		  		]
		  	})
      }
	  	handleChange(locationInputRef.current.name, locationInputRef.current.value, place)
	  	// add places changed listener
		}

	  if (autoCompleteRef.current) {
	  	addedListener = true
      autoCompleteRef.current.addListener('place_changed', handlePlaceChanged)
	  }

	  return () => {
	  	if (addedListener) {
		  	window.google.maps.event.clearInstanceListeners(autoCompleteRef.current);
	  	}
	  }
	}, [autoCompleteRef, handleChange, setPlaceDetails])

  return (
		<input 
			ref={locationInputRef}
			{...inputProperties}
			// autoComplete={(Math.random() + 1).toString(36).substring(6)}
			// onSelect={(e) => {
			// 	e.target.setAttribute("autoComplete", (Math.random() + 1).toString(36).substring(6))
			// }}
			onChange = {(e) => {
				handleChange(e.target.name, e.target.value)
			}}
		/>
  )
}

export default AutoCompletePlace