import React, { useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";
import { DialogContext } from "../../contexts/DialogContext";

import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import Modal from "../Modal/Modal";
import FirebaseLoginWithProvider from "../FirebaseLoginWithProvider/FirebaseLoginWithProvider";
import Spinner from "../Spinner/Spinner";


import {
	logUserIn,
	signUpWithEmail,
	logInWithEmailLink,
	signInWithGoogle,
	signInWithFacebook,
} from "../../firebase/auth";
import firebase from "../../firebase/index";

const useQuery = () => new URLSearchParams(useLocation().search);


/// rendered by: Heading.js > App.js
// errors handled success text not necessary
const LoginOrSignup = ({
	loginHeading,
	signupHeading,
	children,
	submitName,
	submitCallback,
	prefillData,
	exitModalCallback,
	noModal,
	defaultSignupHeadingText,
	defaultLoginHeadingText,
	defaultLoginOrSignup,
}) => {
	const history = useHistory();
  let query = useQuery();

  const [locallyFetching, setLocallyFetching] = useState(false)
	const { dialog } = useContext(DialogContext);
	const {
		setErrorObj,
		handleSetSuccessText,
		setLoginModalOpen,
		newUserData,
		userIsAnonymous,
		// updateUserData,
	} = useContext(UserContext);
	const [signup, setSignup] = useState(
		defaultLoginOrSignup === "signup" ? true : false
	);
	const [showEmailLoginItems, setShowEmailLoginItems] = useState(false);
	// const [isEarlyAccessSignup, setIsEarlyAccessSignup] = useState(false)
	const [signupHeadingText, setSignupHeadingText] = useState(defaultSignupHeadingText || "")
	const [loginHeadingText, setLoginHeadingText] = useState(defaultLoginHeadingText || "")
	// const [showSignupLink, setShowSignupLink] = useState(false)
	const [canCheckoutAsGuest, setCanCheckoutAsGuest] = useState(false)

	const [formData, setFormData] = useState({
		username: "",
		email: "",
		phone: "",
		// country: "",
		name: prefillData
			? prefillData.name || prefillData.firstname + " " + prefillData.lastname
			: "",
		password: "",
		passwordAgain: "",
		address: "",
		acceptTerms: false,
		signedUpFrom: history.location.pathname,
		cardUserInfo: {
			rows: 2,
			val: [],
		},
		companyName: "",
		companyEmail: "",
		contractorKeywords: [],
		fullCityString: "",
		adminLevel2: "",
		industries: [],
		industryTitle: "",
		otherLocationsServed: [],
    isCustomerOnly: true,
		roles: {
			generalContractor: false,
			subContractor: false
		},
		searchKeywords: [],
		addressString: "",
		...prefillData,
	});

	  // handle all urlParams to update signup data
  useEffect(() => {
    // let params = query.get("action")
    // if there is a query ... query.keys() returns an iterator
    if (query.toString()) {
      let keysToDelete = []
      let newFd = {
      	...formData
      }

      query.forEach((val, key) => {
      	let newVal = val
      	if (val === "true") {
      		newVal = true
      	}
      	if (val ==="false") {
      		newVal = false
      	}

        if (key === "signup") {
        	setSignup(newVal)
        	keysToDelete.push({key, val})
        }

        if (key === "withEmail") {
        	setShowEmailLoginItems(newVal)
        	keysToDelete.push({key, val})
        }

        // if (key === "earlyAccessId" || key === "isEarlyAccess") {
        // 	setIsEarlyAccessSignup(true)
        // 	keysToDelete.push({key, val})
        // }
        // set up new heading if this is login for payment
        if (key === "fromPayment" && val === "true") {
        	setLoginHeadingText("Continue to payment")
        	setSignupHeadingText("Continue to payment")
        	// setShowSignupLink(true)
        	keysToDelete.push({key, val})
        }
        // check if payment method is stripe if it is users can checkout as guest
        if (key === "paymentMethod" && val === "stripe") {
        	setCanCheckoutAsGuest(true)
        	keysToDelete.push({key, val})
        }

        for (let fdKey in formData) {
        	if (fdKey !== "password" && key !== "passwordAgain" && key !== "acceptTerms") {
        		if (key === fdKey) {

	        		// check if values are array or objects
	        		if (newVal.includes("{") || newVal.includes("[")) {
	        			try {
	        				let parsed = JSON.parse(newVal)

	        				if (parsed && typeof(parsed) === "object" && typeof(newFd[fdKey]) === "object") { // arrays are type object

			        			if (parsed && parsed.length && newFd[fdKey].length !== undefined) {
					        		newFd[fdKey] = [
					        			...parsed
					        		]
				        		} else { // must be object
				        			if (Object.keys(parsed).length) {
				        				newFd[fdKey] = {
				        					...newFd[fdKey],
				        					...parsed
				        				}
				        			}
				        		}
	        				}
	        			} catch (err) {
	        				// do nothing
	        				console.log(err)
	        			}
	        		} else {
		        		if (newVal) {
			        		newFd[fdKey] = newVal
		        		}

	        		}

		        	keysToDelete.push({key, val})

        		}
        	}
        }

        if (Object.keys(newFd).length) {
        	setFormData(formData => ({
        		...formData,
        		...newFd
        	}))
        }

      });

      // remove all keys
      if (keysToDelete.length) {
      	let newQuery = new URLSearchParams(query.toString())
      	keysToDelete.forEach(({key, val}) => {
      		newQuery.delete(key)
      	})

				if (newQuery) {
	        history.replace(`${history.location.pathname}?${newQuery.toString()}`);
				} else {
	        history.replace(`${history.location.pathname}`);
				}
      }
    }
    // eslint-disable-next-line
  }, [history.location]);

  const closeLoginModal = () => {
  	if (exitModalCallback) {
  		exitModalCallback()
  	}
  	setLoginModalOpen(false)
  }

	const handleChange = (name, value) => {
		setFormData((formData) => ({ ...formData, [name]: value }));
	};

	const handleSendPasswordReset = async (e, email) => {
		e.preventDefault();
		if (!email) {
			email = await dialog.prompt("Enter your email address");
		}
		if (email) {
			try {
				await firebase.auth().sendPasswordResetEmail(email);
				handleSetSuccessText(
					`Success, password reset link sent to ${email}`,
					6000
				);
			} catch (err) {
				setErrorObj(err);
			}
		}
	};

	const handleLogin = async (formData, sendEmailLoginLink) => {
		try {
			if (sendEmailLoginLink) {
				let email = formData.email;
				if (!email) {
					email = await dialog.prompt("Please provide your email for confirmation");
				}
				// let linkSent = false
				const linkSent = await logInWithEmailLink(email);
				if (linkSent) {
					handleSetSuccessText(
						`Success! Email link sent to ${email} click the link to log in`,
						6000
					);
				}
			} else {
				const resultAndUid = await logUserIn(formData);
				if (resultAndUid && resultAndUid.uid) {
					// instead of submitCallback, return response
					// return logUserIn(formData)
					if (!submitCallback) {
						submitCallback = closeLoginModal
					}
					// submitCallback(resultAndUid.uid)
					if (typeof submitCallback === "function") {
						submitCallback();
					}
				}

				handleSetSuccessText("You are now logged in", 4000)
			}
		} catch (err) {
			if (err.code === "auth/wrong-password") {
				err.noReport = true
				err.message = "Incorrect username or password"
				setErrorObj(err);
				// setErrorObj({ ...err, noReport: true });
			} else if (err.code === "auth/too-many-requests") {
				err.noReport = true
				err.metadata = {email: formData.email}
				// setErrorObj({ ...err, metadata: { email: formData.email}});
				setErrorObj(err);
			} else if (err.code === "auth/user-not-found") {
				err.message = "No account found for that email"
				err.noReport = true
				setErrorObj(err);
			} else {
				setErrorObj(err);
			}
		}
	};


	const cleanFormData = (formData) => {
		const handleAnonUpgradingUserData = (userData) => {
			// when a user upgrades from an anon account we need to remove default data
			let newUserObjectItems = {}
			// if the anon user hasnt changed defaults, let the addNewUserToDB fn generate some data
			if (userData.username && userData.username.includes("sample")) {
				newUserObjectItems.username = ""
			}

			if (userData.companyName && userData.companyName.includes("sample")) {
				newUserObjectItems.companyName = "" 
			}

			if (userData.companyEmail && userData.companyEmail.includes("linvo.ca")) {
				newUserObjectItems.companyEmail = "" 
			}
			// let addNewUserToDB re make cardUserInfo
			newUserObjectItems.cardUserInfo = {}

			return {...userData, ...newUserObjectItems}
		}
		// dont mutate formData
		let newUserFormData = {};

		// get rid of white space
		for (let key in formData) {
			// make sure to delete password first but double check
			// and dont touch email ...
			let val = formData[key]
			if (
				val &&
				key !== "password" &&
				key !== "email" && // get rid of email in case is anon user and signing in with other than email/password
				key !== "passwordAgain" &&
				key !== "acceptTerms" &&
				// key !== "private" && // include this because of promotions pre fill address data
				key !== "contacts" &&
				key !== "posts" &&
				key !== "ratings"
			) {
				if (typeof(val) === "string") {
					newUserFormData[key] = val.trim();
				} else {
					newUserFormData[key] = val;
				}
			}
		}


		newUserFormData.address = newUserFormData.address || {}
		// change to only using one name in DB
		const firstname = newUserFormData.name ? newUserFormData.name.split(" ")[0] : "";
		const lastname = newUserFormData.name ? newUserFormData.name.replace(firstname, "").trim() : "";

		if (userIsAnonymous) {
			newUserFormData = handleAnonUpgradingUserData({...newUserFormData, firstname, lastname})
		}

		return newUserFormData;
	};


	const handleEmailSignup = async (formData) => {
		try {
			// add in empty strings etc on original userData object and remove password and other unnecissary fields
			const addedData = cleanFormData({
				...newUserData, 
				...formData,
				industries: formData.industries.length ? formData.industries : (newUserData.industries || []),
				otherLocationsServed: formData.otherLocationsServed.length ? formData.otherLocationsServed : (newUserData.otherLocationsServed || []),
				// paymentMethods: formData.paymentMethods.length ? formData.paymentMethods : newUserData.paymentMethods || []
				// profileAbout
			}); 
			// addNewUserToDb runs as soon as authStateChanged. Cant find a way to pass the formdata to addNewUserToDB without infinite loops (if authStateChanged has newUserData as a dependent it wont stop rendering)
			// add the formdata email in... we need it for password provider
			addedData.email = formData.email
			sessionStorage.setItem("signupFormData", JSON.stringify(addedData));

			// must create user first so we have user uid
			const newUserCred = await signUpWithEmail(addedData, formData.password, dialog); // only need email and password in signUpWithEmail
			if (submitCallback && newUserCred && newUserCred.user && typeof(submitCallback) === "function") {
				// submitCallback could be fn when accepting bill
				closeLoginModal()
				return submitCallback(newUserCred.user.uid);
			} else {
				closeLoginModal()
				return newUserCred
			}

		} catch (err) {
			throw err
		}
	};

	const onClickOutside = () => {
		setFormData({
			username: "",
			email: "",
			id: "",
			name: "",
			password: "",
			passwordAgain: "",
			// address data is saved in sessionStorage then added to firestore under private collection in addNewUserToDB
			address: {
				city: "",
				country: "",
				line1: "",
				line2: "",
				postal_code: "",
				state: "",
			},
			acceptTerms: false,
		});
		closeLoginModal()
	};

	const signupItems = <React.Fragment>
		{locallyFetching && <Spinner position="fixed" />}
		{signup ? (
			<React.Fragment>			
				{signupHeading && typeof (signupHeading === "function") ? ( // note to self: ...start using typrscript
					<React.Fragment>
						{signupHeading()}
						<div className="section-divider" />
					</React.Fragment>
				) : (
					<React.Fragment>
						{
							//isEarlyAccessSignup ? 
							//<div>
							//	<span className="early-access">Early Access</span>
							//</div>
							//: ""
						}
						<div className="heading">{signupHeadingText ||  "Sign Up"}
						</div>
						{
							!showEmailLoginItems &&
							<React.Fragment>
								<div>
									or <span className="link-appearance blue" onClick={() => setSignup(false)}>Log in</span>
								</div>
								<div>&nbsp;</div>
							</React.Fragment>
						}
					</React.Fragment>
				)}
				{showEmailLoginItems && (
					<div
						onClick={() => setShowEmailLoginItems(false)}
						style={{
							position: "absolute",
							top: "34px",
							left: "0",
							padding: "10px",
						}}
					>
						<svg
							id="prev-icon"
							viewBox="0 0 32 32"
							width="18px"
							height="18px"
							fill="none"
							stroke="currentcolor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						>
							<path d="M10 6 L2 16 10 26 M2 16 L30 16" />
						</svg>
					</div>
				)}
				{showEmailLoginItems ? (
					<React.Fragment>
						<div className="section-divider" />
						{
							// showSignupLink ? 
							<React.Fragment>
								<div>Already have an account?&nbsp;</div>
								<div className="link-appearance blue">
									<span onClick={() => setSignup(false)}>Log In</span>
								</div>
							</React.Fragment>
							// : ""
						}
						<SignupForm
							formData={formData}
							setFormData={setFormData}
							handleChange={handleChange}
							setErrorObj={setErrorObj}
							setSignup={setSignup}
							handleEmailSignup={handleEmailSignup}
							submitName={submitName}
							prefillData={prefillData}
							setLocallyFetching={setLocallyFetching}
							locallyFetching={locallyFetching}
						>
							{children}
						</SignupForm>
					</React.Fragment>
				) : (
					<FirebaseLoginWithProvider
						setErrorObj={setErrorObj}
						submitCallback={submitCallback}
						signInWithGoogle={signInWithGoogle}
						signInWithFacebook={signInWithFacebook}
						setShowEmailLoginItems={setShowEmailLoginItems}
						formData={formData}
						cleanFormData={cleanFormData}
						newUserData={newUserData}
						signup={signup}
					/>
				)}
			</React.Fragment>
		) : (
			<React.Fragment>
				{loginHeading && typeof (loginHeading === "function") ? (
					<React.Fragment>
						{loginHeading()}
						<div className="section-divider" />
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className="heading">{loginHeadingText ||  "Log in"}</div>
						<React.Fragment>
							<div>Don't have an account yet?&nbsp;</div>
							<div className="link-appearance blue">
								<span onClick={() => setSignup(true)}>Sign Up</span>
								{/*<Link to="/request-early-access" onClick={() => onClickOutside()}>Sign up</Link>*/}
							</div>
						</React.Fragment>
						{
							// (!showEmailLoginItems && showSignupLink) && 
							// <React.Fragment>
							// 	<div>
							// 		or <span className="link-appearance blue" onClick={() => setSignup(true)}>Sign up</span>
							// 		{/*or <Link to="/request-early-access" onClick={() => onClickOutside()}>Sign up</Link>*/}
							// 	</div>
							// 	<div className="section-divider" />
							// </React.Fragment>
						}
					</React.Fragment>
				)}
				{showEmailLoginItems && (
					<div
						onClick={() => setShowEmailLoginItems(false)}
						style={{
							position: "absolute",
							top: "34px",
							left: "0",
							padding: "10px",
						}}
					>
						<svg
							id="prev-icon"
							viewBox="0 0 32 32"
							width="18px"
							height="18px"
							fill="none"
							stroke="currentcolor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						>
							<path d="M10 6 L2 16 10 26 M2 16 L30 16" />
						</svg>
					</div>
				)}
				{showEmailLoginItems ? (
					<React.Fragment>
						<div className="section-divider" />
						{
							// showSignupLink ? 
							//<React.Fragment>
							//	<div>Don't have an account yet?&nbsp;</div>
							//	<div className="link-appearance blue">
							//		<span onClick={() => setSignup(true)}>Sign Up</span>
							//		{/*<Link to="/request-early-access" onClick={() => onClickOutside()}>Sign up</Link>*/}
							//	</div>
							//</React.Fragment>
							//: <React.Fragment>
							//	<div>Don't have an account yet?&nbsp;</div>
							//	<div>
							//		<Link to="/request-early-access" onClick={() => onClickOutside()}>Request early access</Link>
							//	</div>
							//</React.Fragment>
						}
						<LoginForm
							formData={formData}
							handleChange={handleChange}
							setSignup={setSignup}
							handleLogin={handleLogin}
							submitName={submitName}
							handleSendPasswordReset={handleSendPasswordReset}
						>
							{children}
						</LoginForm>
					</React.Fragment>
				) : (
					<FirebaseLoginWithProvider
						setErrorObj={setErrorObj}
						submitCallback={submitCallback}
						signInWithGoogle={signInWithGoogle}
						signInWithFacebook={signInWithFacebook}
						setShowEmailLoginItems={setShowEmailLoginItems}
						formData={formData}
						cleanFormData={cleanFormData}
						newUserData={newUserData}
						signup={signup}
					/>
				)}
			</React.Fragment>
		)}
		{
			canCheckoutAsGuest ? 
			<div>
				<div className="section-divider" />
				<div className="section-title" >Or</div> 
				<div className="section-divider" />
				<div>
					<button className="button-appearance" onClick={(e) => {
						let newQuery = history.location.search ? history.location.search + "&checkoutAsGuest=true" : "?checkoutAsGuest=true"

						history.push(`${history.location.pathname}${newQuery}`)
						closeLoginModal()
					}}>Check out as guest</button>
				</div>
			</div>
			: ""
		}
	</React.Fragment>

	return (
		<React.Fragment>
			{
				noModal ? signupItems :
				<Modal custom={{ absolute: true, style: "z-index: 102"}} onClickOutside={onClickOutside}>
					{signupItems}
				</Modal>
			}
		</React.Fragment>
	);
};

export default LoginOrSignup;
