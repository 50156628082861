import React, { useEffect, useContext, useRef } from 'react'

import { UserContext } from '../../contexts/UserContext'
import { DocumentListStyle } from './DocumentListStyle.styled'

import Spinner from '../Spinner/Spinner'
import { InvoiceLiComponent, ProjectLiComponent } from './liComponentTypes'

// rendered by Dashboard.js
const DocumentList = ({withinProject, collection, isFetchingDocs, setIsFetchingDocs, docList, setDocList, userId, selections, setSelections, maxSelections}) => {
	// docList and setDocList for invoices or bills should always be usersInvoices
	// docList and setDocList for projects should always be usersProjects
	const {
		usersProjects,
		usersInvoices,
	} = useContext(UserContext)

	const scrollWrapper = useRef(null)

	const documentsSelectable = maxSelections && maxSelections > 0

	const handleWrapperScroll = (e) => {
		const wrapper = e.target
		let liWidth

		if (wrapper) {
			const ul = wrapper.firstElementChild
			const lastListItem = [...ul.children].reverse()[0]
			const liMargin = 20
			liWidth = lastListItem ? lastListItem.clientWidth + liMargin : 0//should be 125 px
		}
		if (liWidth > 0) {
			if (wrapper.scrollLeft >= liWidth) {
				if (wrapper.previousElementSibling.classList.contains("hide")) {
					wrapper.previousElementSibling.classList.remove("hide")
				}
			} else {
				if (!wrapper.previousElementSibling.classList.contains("hide")) {
					wrapper.previousElementSibling.classList.add("hide")
				}
			}

		}
	}

	const getFilteredDocList = (docList, collection, usersProjects, userId) => {
			if (userId && usersProjects) {
				if (collection === "invoices") {
					// doesnt handle onlyFollowing invoices...
					let invoiceList
					const projectIds = usersProjects.length ? usersProjects.map(proj => proj.id) : []
					invoiceList = docList.filter(inv => !projectIds.includes(inv.forProject))
					// seperate invoices from bills

					invoiceList = invoiceList.filter(inv => {
						// return if invoice is not a bill
						return !(inv.billTo && inv.billTo.uid && inv.billTo.uid === userId)
					})

					return invoiceList
				}

				if (collection === "bills") {
					let billsList
					const projectIds = usersProjects.length ? usersProjects.map(proj => proj.id) : []
					billsList = docList.filter(inv => !projectIds.includes(inv.forProject))
					// seperate invoices from bills
					billsList = billsList.filter(inv => {
						return (inv.billTo && inv.billTo.uid && inv.billTo.uid === userId)
					})

					return billsList
				}
			}

			// if still nothing has been returned yet (recents)
			return docList
	}

	useEffect(() => {
		// timeout for loading docs
		let timeout
		if (isFetchingDocs) {
			timeout = setTimeout(() => {
				setIsFetchingDocs(false)
			}, 7000)

		}

		return () => {
			if (timeout) {
				clearTimeout(timeout)
			}
		}
	}, [isFetchingDocs, setIsFetchingDocs])


	const filteredDocList = docList ? getFilteredDocList(docList, collection, usersProjects, userId) : null

	if (isFetchingDocs || !filteredDocList || !filteredDocList.length) {
		return (
			<DocumentListStyle>
					<div className={`wrapper ${!filteredDocList || !filteredDocList.length ? "overflow-clip": ""}`}>
						<ul>
							{
								(!filteredDocList || !filteredDocList.length) &&
								<ul className="empty-list">
									<li className="loading-within">
									{
										isFetchingDocs &&
										<div className="spinner-container"> 
											<Spinner position="relative" height="80px" width="80px" /> 
										</div>
									}
									</li>
									<li>
									</li>
									<li>
									</li>
									<li>
									</li>
									<li>
									</li>
									<li>
									</li>
								</ul>
							}
						</ul>
					</div>
			</DocumentListStyle>
		)
	} else {
		return (
			<DocumentListStyle>
				{
					<div onClick={() => {
						if (scrollWrapper.current) {
							let resScroll
							const scrollx = scrollWrapper.current.scrollLeft
							const ul = scrollWrapper.current.firstElementChild
							const lastListItem = [...ul.children].reverse()[0]
							const liMargin = 20
							const liWidth = lastListItem ? lastListItem.clientWidth + liMargin : 0//should be 125 px
							const itemsScrolled = Math.round(scrollx / liWidth)
							resScroll = (itemsScrolled * liWidth) - liWidth
							scrollWrapper.current.scrollTo(resScroll, 0)

						}
		
					}} className="nav-arrows prev hide" >
						&#10094;
					</div>
				}
				<div className="wrapper" ref={scrollWrapper} onScroll={handleWrapperScroll}>
					<ul>
						{
							filteredDocList.map((doc, i) => {
								let liClassNames = ""
								let creationTimeSeconds = doc.creationTime || 0
								let hasPairedInvoice = false
								let userHasLeviedThisInvoice = false
								let leviedInvoiceInUsersInvoices = null
								
								if (doc.creationTime && doc.creationTime.seconds) {
									creationTimeSeconds = doc.creationTime.seconds
								}
								creationTimeSeconds = parseInt(creationTimeSeconds)
								const nowSeconds  = Date.now() / 1000
								if (nowSeconds < creationTimeSeconds + 30) {
									// if doc created less than 10 seconds ago it is new
									liClassNames += "recent-copy "
								}

								if (doc.isLoading) {
									liClassNames += "loading-within "
								}

								// check the doc types
								// docType is a property in the lightweight recent docs only 

								if (Object.keys(doc).includes("invNumber") || (doc.docType === "invoice" || doc.docType === "bill")) {
									// return an invoice object or bill object
									if ((userId && doc.billTo && doc.billTo.uid === userId) || doc.docType === "bill") {
										liClassNames += "bill "
									} else {
										liClassNames += "invoice "
									}

									if (doc.pairedInvoices && doc.pairedInvoices.length) {
										// this is a levied invoice
										hasPairedInvoice = true
									}

									// if user is bill to and the doc is a levied invoice
									if (doc.leviedInvoice && usersInvoices && usersInvoices.length) {
										leviedInvoiceInUsersInvoices = usersInvoices.find(inv => inv.id === doc.leviedInvoice)
										if (leviedInvoiceInUsersInvoices) {
											userHasLeviedThisInvoice = true
										}
									}

									return (			
										<InvoiceLiComponent 
											documentsSelectable={documentsSelectable}
											selections={selections}
											setSelections={setSelections}
											maxSelections={maxSelections}
											key={i} 
											doc={doc} 
											setDocList={setDocList} 
											liClassNames={liClassNames} 
											i={i} 
											collection={collection}
											withinProject={withinProject} 
											hasPairedInvoice={hasPairedInvoice}
											userHasLeviedThisInvoice={userHasLeviedThisInvoice}
											leviedInvoiceInUsersInvoices={leviedInvoiceInUsersInvoices}
										/>
									)
								} else if (Object.keys(doc).includes("projectName") || doc.docType === "project") {
									liClassNames += "project "
									return (
										<ProjectLiComponent 
											documentsSelectable={documentsSelectable}
											selections={selections}
											setSelections={setSelections}
											maxSelections={maxSelections}
											key={i} 
											doc={doc} 
											setDocList={setDocList} 
											liClassNames={liClassNames} 
											i={i} 
											collection={collection} 
										/>
									)
								} else return null
							})
						}
					</ul>
				</div>
				{
					filteredDocList.length > 1 &&
					<div onClick={() => {
						if (scrollWrapper.current) {
							let resScroll
							const scrollx = scrollWrapper.current.scrollLeft

							const ul = scrollWrapper.current.firstElementChild
							const lastListItem = [...ul.children].reverse()[0]
							const liMargin = 20
							const liWidth = lastListItem ? lastListItem.clientWidth + liMargin : 0//should be 125 px

							if (scrollx < liWidth) {
								resScroll = liWidth
							} else {
								const itemsScrolled = Math.round(scrollx / liWidth)
								resScroll = (itemsScrolled * liWidth) + liWidth
							}
							scrollWrapper.current.scrollTo(resScroll, 0)
						}
					}} className="nav-arrows next" >
						&#10095;
					</div>

				}

			</DocumentListStyle>
		)

	}

}

export default DocumentList
