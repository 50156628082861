import styled from 'styled-components'


export const ProfilePostsStyle = styled.div `
  ${
    ({ isLessThan415px }) => isLessThan415px ? 
    `grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));` 
    : `grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));`
  }
  display: grid;
  grid-gap: ${({ isLessThan415px }) => isLessThan415px ? `30px`: `10px`};
  grid-auto-rows: auto;
  grid-auto-flow: dense;

`
export const PostContainer = styled.div `
	position: relative;
  ${
    ({ aspectRatio, isLessThan415px }) => isLessThan415px ? 
    `grid-column: span 2; grid-row: span 2;`
    : aspectRatio < 1 / 3 ? `grid-column: span 2; grid-row: span 4;` //0.333
    : aspectRatio < 1 / 2 ? `grid-column: span 2; grid-row: span 4;` //0.5
    : aspectRatio < 2 / 3 ? `grid-column: span 2; grid-row: span 4;` //0.6667
    : aspectRatio < 1 / 1 ? `grid-column: span 2; grid-row: span 2;` //1
    : aspectRatio < 3 / 2 ? `grid-column: span 2; grid-row: span 2;` //1.5
    : aspectRatio < 2 / 1 ? `grid-column: span 4; grid-row: span 2;` //2
    : aspectRatio >  2 / 1 ? `grid-column: span 4; grid-row: span 2;` //2+
    : `grid-column: span 2; grid-row: span 2;`
  }
  ${
    ({ isLessThan415px }) => isLessThan415px ?
    null  
    : `box-shadow: 0 0 19px -6px; padding: 10px; height: fit-content;` 
  };
  ${
    ({ numberOfPosts, isLessThan415px }) => numberOfPosts < 3 && !isLessThan415px ? `max-width: 250px` : null
  };
	  .post-title {
  	  display: flex;
		  justify-content: space-between;
		  padding: 2px 2% 5px 2%;
    	position: relative;
    	width: inherit;
      .title-text {
      	overflow: hidden;
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    margin-right: 1ch;
      	:hover {
      		position: absolute;
      		background-color: white;
  		    width: ${({ caption }) => caption.length > 45 ? "96%" : "auto"};
				  left: 0;
				  top: 0;
				  padding: 2px 2% 5px 2%;
      		opacity: 0.9;
      		overflow: unset;
      		white-space: unset;
      	}
      }
		  .edit-post {
		  	cursor: pointer;
		  	padding: 0 0 0 5px;
		  	margin-left: auto;
		  }
	  }
	  .img-container {
	  	display: flex;
		  align-items: center;    
		  justify-content: center;
			  ${
			    ({ isLessThan415px }) => 
			    isLessThan415px ? null
			    : 
			    `:active {
			      left: 0;
			      top: 0;
			      position: fixed;
			      transform: scale(0.9);
			      z-index: 100;
			      width: -webkit-fill-available;
			      height: -webkit-fill-available;
			      img {
				      background-color: white;
				      object-fit: contain;
				      padding: 10px;
				      box-shadow: 0px 7px 36px -12px;
	      	    width: auto;
					    height: 96%;
			      }
			    }`
			  }
		  img {
		  	width: 100%;
			  object-fit: cover;
	      padding: 0px 2px;
		  }
	  }
	  .post-tags-container {
	  	display: flex;
	  	justify-content: space-between;
	  	.container-spacer {
	  		opacity: 0;
	  	}
	  	padding: 2px 0 5px 0;
		  padding: 2px 2% 5px 2%;
	  }
	  .post-tags {
    	overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    margin-right: 1ch;
	    :hover {
    		position: absolute;
    		background-color: white;
		    width: 96%;
			    left: 0;
			    bottom: 0;
    		opacity: 0.9;
    		overflow: unset;
    		white-space: unset;
    		padding: 0 0 5px 0;
			  padding: 2px 2% 5px 2%;
    	}
	  	a {
		  	padding: 0 0.5ch;
		  	display: inline-block;
	  	}
	  }
`;