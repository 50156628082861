import React, { useEffect, useContext, useState } from 'react'

import { ModalStyle } from './ModalStyle.styled'
import { AppMessageContext } from '../../contexts/AppMessageContext'
// import { MediaQueryContext } from '../../contexts/MediaQueryContext'

// rendered by many
// errors handled, successText not necessary
const Modal = ({children, custom, onClickOutside, containerHeading, onClickClose}) => {
	const {
		errorObj,
		setErrorObj
	} = useContext(AppMessageContext)

	const [willDoubleClick, setWillDoubleClick] = useState(false)
  // const {
  //   isLessThan700px
  // } = useContext(MediaQueryContext)

	const handleClick = (e) => {
		if (onClickOutside) {
			// only close if double click
			const classArray = [...e.target.classList]
			if (classArray.includes("modal-background")) {
				if (!willDoubleClick) {
					setWillDoubleClick(true)
				} else {
					setWillDoubleClick(false)
					return onClickOutside()
				}
			}
		} else return false
	}

	useEffect(() => {
		let timer
		if (willDoubleClick) {
			timer = setTimeout(() => {
				if (willDoubleClick) {
					setWillDoubleClick(false)
				}
			}, 1000)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [willDoubleClick, setWillDoubleClick])

	useEffect(() => {
		if (errorObj) {
			setErrorObj(null)
		}
		return () => setErrorObj(null)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
	  document.getElementById("top-of-page").scrollIntoView()
	}, [])

	return (
		<ModalStyle 
			// isLessThan700px={isLessThan700px}
			// className="modal-background" 
			custom={custom} 
			onMouseDown={e => handleClick(e)}
			willDoubleClick={willDoubleClick}
		>
		<div className="modal-background" >
		</div>
			<div id="top-of-modal" />
			<div className="container">
				{
					(custom && custom.absolute) &&
					<svg className="close" onClick={() => onClickClose ? onClickClose() : onClickOutside()} viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
				    <path d="M2 30 L30 2 M30 30 L2 2" />
					</svg>
				}
				{
					containerHeading && 
					<div className="heading">{containerHeading}</div>
				}
				{children}
			</div>
		</ModalStyle>
	)
}

export default Modal
