import firebase from './index'

export const getFollowingInvoices = async ({usersInvoices, userId}) => {
	// lists all invoices where user is listed as follower but not accessor
	// regardless of users projects or following projects
	try {
		let resInvoices = []
		const alreadyFetchedInvoiceIds = (usersInvoices && usersInvoices.length) ? [...usersInvoices.map(inv => inv.id)] : []
		// cant use below unless we can use pagination
		// alreadyFetchedInvoiceIds = [...alreadyFetchedInvoiceIds, ...onlyFollowingInvoices.map(inv => inv.id).filter(id => !alreadyFetchedInvoiceIds.includes(id))]
		// get only invoices following (where user is not an accessor) order by most recent entry date
		let snapshot

		snapshot = await firebase.firestore().collection("invoices")
		.where("followers", "array-contains", userId)
		// .where(accessors, "!array-contains", userId) firebase doesnt suppor a array does not contain ...
			// .where("forProject", "not-in", [projectIds])
			
		// cant use a array-contains with a not-in clause... fk
		// firebase limits not-in querys array to length of 10
		// could do a loop here to keep adding snapshot docs in alreadyFetchedInvoiceIds groups of 10
		// .where("id", "not-in", alreadyFetchedInvoiceIds.slice(0, 9))
		.orderBy("mostRecentEntryDate", "desc")
		// .limit(limit || 5)
		.get()
		// }
		if (snapshot.docs.length) {
			// const docIds = snapshot.docs.map(d => d.id)
			// console.log(docIds, alreadyFetchedInvoiceIds)
			// console.log(docIds.includes())
			snapshot.docs.forEach(doc => {
				// only push invoices that have not already been fetched
				if (!alreadyFetchedInvoiceIds.includes(doc.id)) {
					const docData = doc.data()
					// remove bad properties
					if (docData.isLoading) {
						delete docData.isLoading
					}
					resInvoices.push({...docData, id: doc.id})
				}
			})
		}
		// combine user invoices with following invoices then filter return only following
		resInvoices = (usersInvoices && usersInvoices.length) ? [...usersInvoices, ...resInvoices] : [...resInvoices] // there should be no onlyFollowingInvoices
		resInvoices = resInvoices.filter(inv => {
			if (inv.accessors) {
				// prevent problem when inv = {noResults: true}
				// if looking at following invoices only, must not filter if they are listed within usersProjects
				return !(inv.accessors.includes(userId)) /*&& !projectIds.includes(inv.forProject)*/
			} else return false
		})

		return resInvoices

	} catch (err) {
		console.log("error in getFollowingInvoices")
		throw err
	}
}


export const getFollowingProjects = async ({usersProjects, userId}) => {
	try {
		let alreadyFetchedProjectIds = []
		if (usersProjects && usersProjects.length) {
			alreadyFetchedProjectIds = [...usersProjects.map(proj => proj.id)]
		}

		let resProjects = []

		const snapshot = await firebase.firestore().collection("projects")
		.where("followers", "array-contains", userId)
			// toDO: stop using firebase because of stupid query restrictions or find better way to structure DB
		// cant use a array-contains with a not-in clause... fk
		// firebase limits not-in querys array to length of 10
		// could do a loop here to keep adding snapshot docs in alreadyFetchedInvoiceIds groups of 10
		// .where("id", "not-in", alreadyFetchedProjectIds.slice(0, 9))
		.get()


		if (snapshot.docs.length) {
			snapshot.docs.forEach(doc => {
				// only push projects that are not already in usersProjectss
				if (!alreadyFetchedProjectIds.includes(doc.id)) {
					const docData = doc.data()
					// remove bad properties
					if (docData.isLoading) {
						delete docData.isLoading
					}

					resProjects.push({...docData, id: doc.id})
				}

			})
		}
		// combine already fetched projects with following then filter return only following

		resProjects = (usersProjects && usersProjects.length) ? [...usersProjects, ...resProjects] : [...resProjects] 

		resProjects = resProjects.filter(proj => {
			// prevent problem when proj = {noResults: true}
			if (proj.accessors) {
				return !proj.accessors.includes(userId)
			} else return false
		})

		return resProjects

	} catch (err) {
		console.log("error in getFollowingProjects")
		throw err
	}
}