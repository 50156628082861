import React, { useState, useContext } from "react";

import { UserContext } from "./UserContext";

import { formulateSearchKeywords, removeDuplicates } from "../utils/appUtils";
import firebase from "../firebase/index";

const EditProjectContext = React.createContext();

// rendered by App.js
// errors and success handled
const EditProjectContextProvider = ({ children }) => {
	const {
		successText,
		setSuccessText,
		handleSetSuccessText,
		errorObj,
		setErrorObj,
		currentProjectData,
		usersProjects,
		setUsersProjects,
		setCurrentProjectData,
	} = useContext(UserContext);

	const [isEditing, setIsEditing] = useState(false);
	// const [projectData, setProjectData] = useState({
	// 	contractors: [],
	// 	billTo: {},
	// 	projectName: "",
	// 	followers: [],
	// 	editors: [],
	// 	owner: [],
	// 	id: ""
	// })
	const [projectData, setProjectData] = useState(null);

	const [invoiceData, setInvoiceData] = useState(null);
	const [userIsOwner, setUserIsOwner] = useState(false);
	const [userCanEdit, setUserCanEdit] = useState(false);
	const [userIsBillTo, setUserIsBillTo] = useState(false);
	const [userIsFollower, setUserIsFollower] = useState(false);
	const [followersUserObjs, setFollowersUserObjs] = useState([]);
	// null is used as first value for the purpose of waiting to load
	// use isLoading instead
	const [userCanView, setUserCanView] = useState(null);
	const [changeBillToModalOpen, setChangeBillToModalOpen] = useState(false);
	const [myDocs, setMyDocs] = useState([]);

	const saveProject = ({ newProjectData, setUiState }) => {
		const editors = followersUserObjs.filter(
			(obj) =>
				obj.type.toLocaleLowerCase() === "editor" ||
				obj.type.toLocaleLowerCase() === "owner"
		);
		const editorUsernames = editors.map((obj) => obj.username.trim());
		if (newProjectData.id === currentProjectData.id) {
			setCurrentProjectData((currentProjectData) => ({
				...currentProjectData,
				projectName: newProjectData.projectName,
			}));
		}

		newProjectData = {
			...newProjectData,
			accessors: removeDuplicates([
				...newProjectData.editors,
				newProjectData.owner,
				newProjectData.billTo.uid,
			]),
			// searchKeywords: formulateSearchKeywords([
			// 	newProjectData.shortHandId,
			// 	newProjectData.projectName,
			// newProjectData.billTo.username,
			// newProjectData.billTo.firstname,
			// newProjectData.billTo.lastname,
			// 	editorUsernames.toString().replace(/,/g," ")
			// ])
		};

		newProjectData = {
			...newProjectData,
			searchKeywords: formulateSearchKeywords({
				doc: { ...newProjectData, editorUsernames },
				type: "project",
				additionalWords: [
					...editorUsernames,
					newProjectData.billTo.username,
					newProjectData.billTo.firstname,
					newProjectData.billTo.lastname,
				],
			}),
		};

		firebase
			.firestore()
			.collection("projects")
			.doc(newProjectData.id)
			.update(newProjectData)
			.then(() => {
				if (setUiState !== false) {
					// most times setUiState is undefined, default tot setProjectData
					setProjectData(newProjectData);
					const projectInUsersProjectsIndex = usersProjects
						? usersProjects.findIndex((proj) => proj.id === newProjectData.id)
						: -1;
					const projectInUsersProjects =
						projectInUsersProjectsIndex > -1
							? usersProjects[projectInUsersProjectsIndex]
							: null;
					if (projectInUsersProjects) {
						setUsersProjects((usersProjects) => {
							return usersProjects.map((proj) => {
								if (proj.id === newProjectData.id) {
									return newProjectData;
								} else return proj;
							});
						});
					}
				}

				handleSetSuccessText("All changes saved");
				setIsEditing(false);
			})
			.catch((err) => {
				setIsEditing(false);
				setErrorObj(err);
			});
	};

	return (
		<EditProjectContext.Provider
			value={{
				isEditing,
				setIsEditing,
				projectData,
				setProjectData,
				invoiceData,
				setInvoiceData,
				userIsOwner,
				setUserIsOwner,
				userIsBillTo,
				setUserIsBillTo,
				userCanEdit,
				setUserCanEdit,
				userIsFollower,
				setUserIsFollower,
				followersUserObjs,
				setFollowersUserObjs,
				saveProject,
				myDocs,
				setMyDocs,
				userCanView,
				setUserCanView,
				changeBillToModalOpen,
				setChangeBillToModalOpen,
				errorObj,
				setErrorObj,
				successText,
				setSuccessText,
				handleSetSuccessText,
			}}
		>
			{children}
		</EditProjectContext.Provider>
	);
};

export { EditProjectContextProvider, EditProjectContext };
