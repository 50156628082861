// works on safari and FF but includes the pseudo scroll... next doesnt and still works ios but FF iffy
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { MessagingContext } from "../../contexts/MessagingContext";
// import { MediaQueryContext } from "../../contexts/MediaQueryContext"; 
import { UserContext } from "../../contexts/UserContext"; 

// import WriteMessageInput from "./WriteMessageInput";
import { getFriendlyDate } from "../../utils/dateUtils";
import { handleLogoImageOnError } from "../../utils/appUtils";

import Spinner from "../../components/Spinner/Spinner";
import WriteMessageInput from "./WriteMessageInput";


const MessageContainerStyle = styled.div `
	// .pointer-select {
 //    position: absolute;
 //    top: 0;
 //    right: 0;
 //    z-index: 3;
 //    // border-radius: 50%;
	// 	svg {
	//     background-color: var(--white-text);
	//     margin: 2px 2px 0 0;
	// 		border-radius: 50%;
	//     box-shadow: 0 0 13px -5px;
	// 		height: 40px;
	// 		width: 40px;
	// 	}
	// }
	// .pointer-select.selected {
	// 	svg {
	// 		background-color: var(--nav-item-hover);
	// 	}
	// }
	.no-messages { 
		text-align: center;
		margin-bottom: 100px;
	}

	.messages-scroll-window {
	    // overflow-y: hidden;
			height: 50vh;
	    overflow-y: auto;
	    // scroll-behavior: smooth;
	    transform: ${({ browserIsChrome, browserIsEdge }) => (browserIsChrome || browserIsEdge) ? "rotate3d(0,1,0,180deg)" : "rotateX(0deg) rotateY(180deg) rotateZ(180deg)"};

		> div {
			// scroll-behavior: smooth;
	    width: 100%;
	    transform: ${({ browserIsChrome, browserIsEdge }) => (browserIsChrome || browserIsEdge) ? "rotateX(0deg) rotateY(180deg) rotateZ(0deg)" : "rotateX(0deg) rotateY(180deg) rotateZ(180deg)"};
			.top-of-messages {
				margin: 10px 0;
				padding: 10px 0;
				height: 30px;
				// min-height: 100px;
				.loading {
					// margin-bottom: -120px;
					margin-top: 50px;;
				}
				.loading-spinner {
					opacity: 0;
					// transition: all 0.5s linear;
				}
				.loading-spinner.loading {
					opacity: 1;
					// transition: all 0.5s linear;
				}
			}
			.timestamp-component {
				margin: 70px 0 10px 0;
				text-align: center;
			}
			.message-container {
				// max-width: 80%;
		    align-items: center;
				margin: 25px 0 0 0;
				display: flex;
			}
			.current-user.message-container {
		    justify-content: end;
		    flex-direction: row-reverse;

			}
			.at-bottom, .at-bottom + p {
				position: relative;
				bottom: -100px;
				opacity: 0;
				// transition: all 3.2s linear;
			}
			.in-place, .in-place + p {
				position: relative;
				bottom: 0;
				opacity: 1;
				transition: all 0.2s linear;
			}
		  .message-bubble {
		  	border: 1px solid var(--chicago);
				border-radius: 10px;
				padding: 2px 10px;
		    width: fit-content;
				// min-width: 30%;
		    max-width: 76%;
		    // max-height: 100vw;
		    text-align: left;
		    margin-top: auto;
		    margin-bottom: auto;
        overflow-wrap: break-word;
			  white-space: pre-line;

		    // overflow-y: scroll;
		    img {
    			user-select: none;
				  -moz-user-select: none;
				  -webkit-user-select: none;
			    width: fit-content;
			    max-width: 100%;
		    }
		  }
			.current-user {
				.message-bubble {
					background-color: var(--brand);
					// margin-left: auto;		
					color: var(--titan-white);
				}
			}

			.time-sent {
				text-align: left;
				margin-left: 50px;
				margin-right: 50px;
				margin-top: 0;
				font-size: var(--font-size-xs);
			}
			.current-user.time-sent {
				text-align: right;
			}
			#chat-bottom {
				height: 10px;
			}

			.sender-image {
				user-select: none;
			  -moz-user-select: none;
			  -webkit-user-select: none;
				img {
					padding: 2px;
					border-radius: 50%;
					height: 40px;
					width: 40px;
					margin-right: 2px;
				}
			}
			.current-user {
				.sender-image {
					user-select: none;
				  -moz-user-select: none;
				  -webkit-user-select: none;
					img {
						margin-right: 0;
						margin-left: 2px;
					}
				}
			}

		}
	}

`


const ThreadMessages = ({isLessThan700px, browserIsChrome, browserIsEdge, threadNotFound, newestMessageRead, handleIndicateMessageRead, threadId}) => {
	const isMountedRef = useRef(null)
	// const pseudoMessagesContainerRef = useRef(null)
	const newestMessageRef = useRef(null)
	const messagesContainer = useRef(null)
	const messagesScrollWindow = useRef(null)
	const loadingMoreMessages = useRef(null)


	const {
		// usersThreads,
		currentThreadData,
		handleRetrieveMessages,
		lastMessageRetrieved,
		reachedEndOfMessages,
		setCurrentThreadData,
		handleSendMessage,
	} = useContext(MessagingContext);
  const {
  	userObject,
  } = useContext(UserContext)

  const [shouldLoadMoreMessages, setShouldLoadMoreMessages] = useState(false)

  const [newestMessageId, setNewestMessageId] = useState("")
  const [didInitialScroll, setDidInitialScroll] = useState(false)
  const [messageContainerHeight, setMessageContainerHeight] = useState(0)
  const [scrollContainerScrollTop, setScrollContainerScrollTop] = useState(0)

  const fetchNewWhenScrollIs = 80

	const hasNewMessage = currentThreadData && newestMessageId && currentThreadData.newestMessage && currentThreadData.newestMessage.id !== newestMessageId

 useEffect(() => {
	if (messagesContainer.current && messagesScrollWindow.current) {
		const scrollContainer = messagesScrollWindow.current
		const maxScroll = scrollContainer.scrollHeight - scrollContainer.clientHeight
		const childHeight = scrollContainer.firstChild.clientHeight
		const heightAdded = childHeight - messageContainerHeight

		if (messageContainerHeight !== childHeight) {
    	setMessageContainerHeight(childHeight)
		}

		if (browserIsChrome || browserIsEdge) {
			// const isNearTop = scrollContainer.scrollTop < fetchNewWhenScrollIs // if within 10px of top
			const isNearBottom = scrollContainer.scrollTop > maxScroll - 120 // if within 120 px of bottom
			// we only want to scroll to bottom if near bottom and theres a new message
			// if not near bottom and theres a new message we dont want to change the scroll position
		  if (heightAdded && !hasNewMessage) { // if has new message dont scroll to position this messes things up for wasNearBottom
				scrollContainer.scrollTop = scrollContainer.scrollTop + heightAdded
			}

			// note: as soon as height added we might not be at bottom so we need to use the prev scrollTop and prev maxScroll to see if we were near the bottom

			const wasNearBottom = scrollContainer.scrollTop + heightAdded > (maxScroll - heightAdded) - 120
			// wait til height added but once height added might not be near bottom
			if ((isNearBottom || wasNearBottom) && hasNewMessage) {
				scrollContainer.scrollTop = maxScroll
			}
		} else {
			const isNearTop = scrollContainer.scrollTop > maxScroll - fetchNewWhenScrollIs
			const isNearBottom = scrollContainer.scrollTop < 120

			if (isNearTop && !reachedEndOfMessages) {
				scrollContainer.scrollTop = maxScroll - (fetchNewWhenScrollIs + 2)
			}

			if (maxScroll > 0) {
			  if (isNearTop && scrollContainer.scrollTop !== 0 ) {

					if (!reachedEndOfMessages) {
						// if not already loading messages and has not already loaded messages at this area
						if (!shouldLoadMoreMessages && !loadingMoreMessages.current /*&& heightAdded*/) {
							setShouldLoadMoreMessages(true)	
						}
					}
				}

				
			}

			const wasNearBottom = scrollContainer.scrollTop - heightAdded < 120

			if ((isNearBottom || wasNearBottom) && hasNewMessage) {
				scrollContainer.scrollTop = 0
			}
		}

		
	}
   
 }, [currentThreadData, scrollContainerScrollTop, browserIsChrome, browserIsEdge, hasNewMessage, reachedEndOfMessages, shouldLoadMoreMessages, messageContainerHeight])
 


  const handleScrollMessages = useCallback((e) => {
  	const pseudo = e.target 

  	if ((browserIsChrome || browserIsEdge) && pseudo.scrollTop < fetchNewWhenScrollIs) {

			if (!reachedEndOfMessages && didInitialScroll) {
				// if not already loading messages and has not already loaded messages at this area
				if (!shouldLoadMoreMessages && !loadingMoreMessages.current) {
					setShouldLoadMoreMessages(true)	
				}

	  		pseudo.scrollTop = fetchNewWhenScrollIs + 10
			}
  	}

  	if (scrollContainerScrollTop !== pseudo.scrollTop) {
	  	setScrollContainerScrollTop(pseudo.scrollTop)
  		
  	}

		// only mark message as read if the user is specifically in the thread and the thread is not just usersThreads[0]
		// can know user is here on scroll or on mousedown (handled in Messages.js)
		// re write newestMessageRead
		if (currentThreadData.newestMessage) {
			// if there is no newest message read or the user has not read the newest message
			const userAsParticipant = currentThreadData.participants[userObject.id]
			// if newestMessageRead has already been set, check if it is the newest message
			if (newestMessageRead && newestMessageRead !== currentThreadData.newestMessage.id) {
				if (userAsParticipant && userAsParticipant.lastMessageRead !== currentThreadData.newestMessage.id) {
					if (currentThreadData.newestMessage.id !== newestMessageRead) {
						handleIndicateMessageRead()
					}
				}
			}
		}
  }, [browserIsChrome, browserIsEdge, reachedEndOfMessages, didInitialScroll, shouldLoadMoreMessages, scrollContainerScrollTop, currentThreadData, handleIndicateMessageRead, userObject, newestMessageRead])

  useEffect(() => {
    if (isLessThan700px && threadId) {
    	window.scrollTo(0, document.body.scrollHeight);
    }
  }, [threadId, isLessThan700px])

  useEffect(() => {
  	// reset didInitial scroll to prevent automaticazlly setting shouldLoadMoreMessages true
    return () => {
    	if (browserIsChrome || browserIsEdge) {
	    	setDidInitialScroll(false)
    	}
    }
  }, [threadId, browserIsChrome, browserIsEdge])


	if (browserIsChrome || browserIsEdge) {
    if (!didInitialScroll && currentThreadData && messagesScrollWindow.current && newestMessageRef.current && isMountedRef.current) {
	  	// const scrollHeightsEquivalent = parseFloat(pseudoContainer.firstChild.style.height) === messagesViewWindow.firstChild.clientHeight
	  	const scrollContainer = messagesScrollWindow.current
  		// update the scroll container height
  		// pseudoContainer.firstChild.style.height = messagesViewWindow.firstChild.clientHeight
  		const scrollHeight = scrollContainer.scrollHeight
  		const clientHeight = scrollContainer.clientHeight
  		const maxScroll = scrollHeight - clientHeight
  		if (maxScroll > 0) {
	  		setDidInitialScroll(true)
	    	// scrollContainer.scrollTop = Math.round(maxScroll / 2)
	    	scrollContainer.scrollTop = maxScroll
  		}

  	}
	} else {
		if (!didInitialScroll) {
			setDidInitialScroll(true)
		}
	}

	// add scroll listeners
  useEffect(() => {
  	let scrollContainer
	  if (userObject.id && messagesScrollWindow.current && currentThreadData && !threadNotFound && !loadingMoreMessages.current) {
	  	scrollContainer = messagesScrollWindow.current	
	    // clean up code
	    scrollContainer.removeEventListener('scroll', handleScrollMessages, { passive: true });
	    scrollContainer.addEventListener('scroll', handleScrollMessages, { passive: true });
	  }
    return () => {
    	if (scrollContainer) {
	    	scrollContainer.removeEventListener('scroll', handleScrollMessages, { passive: true });
    	}
    }
    // handleScrollMessages doesn't need to be in dep array
  }, [currentThreadData, messagesContainer, handleScrollMessages, userObject, threadNotFound]);

	useEffect(() => {
	  if (shouldLoadMoreMessages /*&& heightAdded*/) {
			if (isMountedRef.current) {
				// setShouldLoadMoreMessages(true)	
		  	if (!threadNotFound && !loadingMoreMessages.current && currentThreadData) {
		  		loadingMoreMessages.current = true
			  	/*setHeightAdded(false)*/
					setShouldLoadMoreMessages(false)
					handleRetrieveMessages({thread: currentThreadData, lastDoc: lastMessageRetrieved, limit: 10}).then(() => {
			  		loadingMoreMessages.current = false
					})
		  	}
			}
	  }
	  // handleRetrieveMessages doesn't need to be in dep array...?
    // eslint-disable-next-line
	}, [shouldLoadMoreMessages, currentThreadData, threadNotFound])

	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	// set newestMessageId to the newest message id every time currentThreadData changes
	useEffect(() => {
		if (currentThreadData && currentThreadData.newestMessage && !threadNotFound) {
		  setNewestMessageId(currentThreadData.newestMessage.id)
		}
	}, [currentThreadData, threadNotFound])

	let newMessageClassName = hasNewMessage ? "at-bottom" : "in-place"
	// let timeout


	let messageComponents = []

	// build the message components
	if (currentThreadData && currentThreadData.messages && Object.keys(currentThreadData.messages).length) {
		// check who has read the newest message
		let newestMessageReadBy = []
		for (let key in currentThreadData.participants) {
			const user = currentThreadData.participants[key]

			if (user.lastMessageRead === currentThreadData.newestMessage.id) {
				newestMessageReadBy.push(key)
			}
		}

		// make messages into an array
		const messagesArray = []
		for (let key in currentThreadData.messages) {
			messagesArray.push({...currentThreadData.messages[key], id: key})
		}

		for (let i=0; i<messagesArray.length; i++) {
			const message = messagesArray[i]
			let nextMessage = messagesArray[i+1]
  		let senderLogoUrl 
  		let senderUsername
  		let senderUsernameLink
  		let isNewestMessage = currentThreadData.newestMessage.id === message.id

			// find logo in currentThreadData
			if (message.sender === userObject.id && !currentThreadData.subThreadId) {
				senderLogoUrl = userObject.logoUrl
				senderUsername = userObject.username
			}

			// check in currentThreadData
			if (!senderLogoUrl && currentThreadData.participants) {
				senderLogoUrl = currentThreadData.participants[message.sender] ? currentThreadData.participants[message.sender].logoUrl : "" 
			}

			if (!senderUsername && currentThreadData.participants) {
				senderUsername = currentThreadData.participants[message.sender] ? currentThreadData.participants[message.sender].username : "Linvo user"
			}

			// default
			if (!senderLogoUrl) {
				senderLogoUrl = "/assets/blank-profile-picture.png"
			}

			senderUsernameLink = currentThreadData.participants[message.sender] ? currentThreadData.participants[message.sender].usernameLink : ""
			if (!senderUsernameLink) {
				senderUsernameLink = senderUsername
			}

			const friendlyMessageTimestamp = getFriendlyDate(message.timestamp, "noTime")
			let messageTimeSentOrRead = getFriendlyDate(message.timestamp, "time")
			let readReceipt = ""

			if (isNewestMessage) {
			 	const readByExcludingCurretnUser = newestMessageReadBy.filter(id => id !== userObject.id)
			 	if (currentThreadData.isGroup) {
			 		// add the logoUrl of users that have read the message to the readReceipt

			 	} else {
			 			// if the newest message was sent by the current user and someone else has read it
			 		if (readByExcludingCurretnUser.length && currentThreadData.newestMessage.sender === userObject.id) {
				 		// put a read component before the message timestamp and change the timestamp to be the time the message was read
			 			const userInParticipants = currentThreadData.participants[readByExcludingCurretnUser[0]]
			 			const readMessageAt = userInParticipants.lastMessageReadAt ? getFriendlyDate(userInParticipants.lastMessageReadAt, "time") : ""
			 			if (readMessageAt) {
			 				messageTimeSentOrRead = readMessageAt
			 			}
				 		readReceipt = <span>Read </span>
			 		}
			 	}
			}

			let timestampComponent = ""
			const nextMessageTimestamp = nextMessage ? getFriendlyDate(nextMessage.timestamp, "noTime") : ""

			if (friendlyMessageTimestamp !== nextMessageTimestamp) { // note messages are in reverse order
				timestampComponent = <div className="timestamp-component">
					{friendlyMessageTimestamp}
				</div>
			}

			let messageText = ""

			if (message.text.includes("@") || message.text.includes("https://linvo")) {
				let textArray = message.text.split(/\s+/)
				messageText = <span>
					{
						textArray.map((word, i) => {
							if (word.startsWith("@")) {
								return <React.Fragment key={i}><Link className={message.sender === userObject.id ? "white" : ""} to={`/users/${word.slice(1)}`}>{word}</Link>&nbsp;</React.Fragment>
							} else if (word.startsWith("https://linvo.ca") || word.startsWith("https://linvo.app")) {
								let linkText = word 
								if (word.includes("/projects")) {
									linkText = "Linvo Project"	
								}
								if (word.includes("/invoice")) {
									linkText = "Invoice"
								}
								if (word.includes("/marketplace/jobs")) { // in future make a <message> component and render preview of actual job
									linkText = "Marketplace Job"
								}
								if (word.includes("?")) { // do not allow query injections
									const indexOfQuery = word.indexOf("?")
									linkText = word.slice(0, indexOfQuery)
								}
								return <React.Fragment key={i}><Link className={message.sender === userObject.id ? "white" : ""} to={word.replace("https://linvo.ca/", "/").replace("https://linvo.app/", "/")}>{linkText}</Link>&nbsp;</React.Fragment>
							} else {
								return word + " "
							}
						})
					}
				</span>
			} else {
				messageText = <span>{message.text}</span>
			}
			messageComponents.push(
				<React.Fragment key={message.id}>
					{timestampComponent}
					<div ref={isNewestMessage ? newestMessageRef : null} className={`message-container ${message.sender === userObject.id ? "current-user" : ""} ${isNewestMessage ? newMessageClassName : ""}`}>
						<div className="sender-image">
							<Link to={`/users/${senderUsernameLink}`} >
								<img onError={handleLogoImageOnError} src={senderLogoUrl} alt="" />
							</Link>
						</div>
						<div className="message-bubble">
							{
								message.img &&
								<div>
									<img onError={handleLogoImageOnError} src={message.img} alt=""/>
								</div>
							}
							{messageText}
						</div>
					</div>
					<p style={i === 0 ? {paddingBottom: "40px"} : {}} className={`${message.sender === userObject.id ? "current-user" : ""} time-sent`}>
						{readReceipt}
						{
							messageTimeSentOrRead
						}
					</p>
				</React.Fragment>
			)

  	}
  	// reverse the message components so newest on bottom
  	messageComponents = messageComponents.reverse()
	} else {
		// set the ui to no messages view
		messageComponents = <p className="no-messages">Send a message</p>
	}

	// mobile view
  return (
		<MessageContainerStyle browserIsChrome={browserIsChrome} browserIsEdge={browserIsEdge} >
			<div ref={messagesScrollWindow} className="messages-scroll-window" >
				<div ref={messagesContainer}>
					<div className="top-of-messages">
						{
							reachedEndOfMessages ? 
							<React.Fragment>
								<div className="section-divider" />
								<div className="tiny align-center">End of Messages</div>
							</React.Fragment>
							:
							<div className={`loading-spinner ${loadingMoreMessages.current ? "loading" : ""}`}>
								<Spinner position="relative" height="50px" width="50px" />
							</div>
						}
					</div>
					{messageComponents}
				</div>
			</div>
			<div className="toolbar" >
				<WriteMessageInput isLessThan700px={isLessThan700px} threadId={threadId} handleSendMessage={handleSendMessage} currentThreadData={currentThreadData} setCurrentThreadData={setCurrentThreadData}/>
			</div>
		</MessageContainerStyle>
  )
}

export default ThreadMessages
