import React from "react";
import styled from "styled-components";

const MoreInfoIconStyle = styled.div`
	display: none;
	justify-content: ${({ text, img }) =>
		text.length > 55 || img ? "center" : "left"};
	font-size: 12px;
	opacity: 0.7;
	div > div {
		background-color: black;
		color: white;
		border-radius: 5px;
		padding: 5px 10px;
	}
	.img-width {
		width: 600px;
	}
	.img-width-mobile {
		width: 95vw;
	}
	.text-width {
		width: 600px;
		padding: 5px;
	}
	.text-width-mobile {
		width: 90vw;
		padding: 5px;
	}
	${({ absolute }) =>
		absolute &&
		`
		position: absolute;
	`}
	${({ custom }) =>custom && custom};
`;

const InfoIcon = styled.span`
	display: inline-block;
	vertical-align: middle;
	svg {
		width: 16px;
		height: 16px;
		opacity: 1;
	}
	:hover {
		svg {
			opacity: 0.3;
		}
	}
	:hover + div {
		z-index: 5;
		display: flex;
	}
	${({ isCustomIcon }) => isCustomIcon && `vertical-align: unset`};
`;

const MoreInfoIcon = ({ text, className, svgClassname, img, customIcon, absolute, custom }) => {
	// const [isHovering, setIsHovering] = useState(false)

	return (
		<React.Fragment>
			{customIcon ? (
				<InfoIcon isCustomIcon={true}>{customIcon}</InfoIcon>
			) : (
				<InfoIcon>
					<svg
						// onMouseOver={e => setIsHovering(isHovering => false)} onMouseOut={e => setIsHovering(isHovering => true)}
						id="info-icon"
						viewBox="0 0 32 32"
						fill="none"
						stroke="currentcolor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						className={svgClassname}
					>
						<path d="M16 14 L16 23 M16 8 L16 10" />
						<circle cx="16" cy="16" r="14" />
					</svg>
					&nbsp;
				</InfoIcon>
			)}
			<MoreInfoIconStyle absolute={absolute} custom={custom} text={text} img={img}>
				<div>
					{img && <img {...img} alt={img.alt || ""} />}
					<div className={className}>{text}</div>
				</div>
			</MoreInfoIconStyle>
		</React.Fragment>
	);
};

export default MoreInfoIcon;
