import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import imageCompression from 'browser-image-compression'

import { UserContext } from "../../../contexts/UserContext";
import { EditInvoiceContext } from "../../../contexts/EditInvoiceContext";
import { DialogContext } from "../../../contexts/DialogContext";

// import { MediaQueryContext } from '../../../contexts/MediaQueryContext'

import Form from "../../Forms/Form";
import { AppMessage } from "../../MessageUtils";
import MoreInfoIcon from "../../MoreInfoIcon";

import {
	handleChangeUserCapabilities /*, checkFollowOrEditRequest*/,
} from "../../../utils/followUtils";
import { getLocalISODate } from "../../../utils/dateUtils";
// import { sendNotification } from '../../../utils/notificationUtils'
import {
	addEditorsToProject,
	addFollowersToProject,
	handleRemoveInvoice,
} from "../invoiceUtils";
import { sortAlphabetically, getNum, deepObjectCompareDiffers } from "../../../utils/appUtils";

import firebase from "../../../firebase/index";

// rendered by SpecificInvoice.js
// errors and success handled
const InvoiceSummary = ({ setShowInvoiceSummary }) => {

	// toDo: if the paired invoices doNotUpdate settings are changed to allow auto updates of a table entry type eg: paymentEntries, 
	// get the current state of the paired invoices payment entries and add them to this invoice instead waiting til the next time the paired invoice is saved 
	const { dialog } = useContext(DialogContext);
	const {
		currentUser,
		userObject,
		usersProjects,
		setUsersInvoices,
		setSettingsModalOpen,
		removeDocFromRecentlyViewed,
		canAcceptStripePayments
	} = useContext(UserContext);
	const {
		editedInv,
		invoiceTotals,
		setEditedInv,
		userIsCreatorOrContractor,
		userIsOwner,
		userIsBillTo,
		userCanEdit,
		followersUserObjs,
		setFollowersUserObjs,
		handleSave,
		setChangeBillToModalOpen,
		setErrorObj,
		handleSetSuccessText,
		invoiceOwedStr,
		handleSetEditedInv,
		handleSetInv,
	} = useContext(EditInvoiceContext);

	const [hasBeenEdited, setHasBeenEdited] = useState(false);
	const [industryOptions, setIndustryOptions] = useState([]);
	// const hasPairedInvoices = editedInv.pairedInvoices && editedInv.pairedInvoices.length && editedInv.pairedInvoiceOptions && Object.keys(editedInv.pairedInvoiceOptions).length
	const hasPairedInvoices = editedInv.pairedInvoiceOptions && Object.keys(editedInv.pairedInvoiceOptions).length

	let pairedInvoiceInputOptions = []
	if (hasPairedInvoices) {
		for (let key in editedInv.pairedInvoiceOptions) {
			const pairedInv = editedInv.pairedInvoiceOptions[key]
			pairedInvoiceInputOptions.push({
				name: `Invoice #${pairedInv.invNumber} (${pairedInv.invShortHand})`,
				value: key
			})
		}
	}
	const [expandedPairedInvoices, setExpandedPairedInvoices] = useState([])

	const defaultContractorPaymentMethods = (userIsOwner && userObject.paymentMethods) && sortAlphabetically({...userObject.paymentMethods})

	let originalFormData = {
		visibility: editedInv.visibility || "public",
		searchable: editedInv.searchable,
		forProject: editedInv.forProject || "",
		industry: editedInv.industry,
		rate: editedInv.rate,
		closed: editedInv.closed || false,
		closedDate: getLocalISODate(),
		contractor: editedInv.contractor,
		pairedInvoiceOptions: editedInv.pairedInvoiceOptions || [],
		pairedInvoices: editedInv.pairedInvoices || [],
		autoSaveEnabled: editedInv.autoSaveEnabled,
	};

	// the paymentMethods object can be null or undefined upon ownership change so we need to make sure it gets updated (hasBeenEdited will be false if we add in the default contractor payment methods in the default form data while editedInv.paymentMethods is null)
	if (editedInv.paymentMethods && editedInv.paymentMethods.cash) {
		originalFormData.paymentMethods = {
			...defaultContractorPaymentMethods || {}, // only if userIsOwner
			...editedInv.paymentMethods || {}
		}
	}

	const [formData, setFormData] = useState({
		...originalFormData,
		// if no payment methods, add in the contractor default payment methods 
		...editedInv.paymentMethods ? {} : {
			paymentMethods: defaultContractorPaymentMethods || null, // only if userIsOwner
		}
	});

	const [forProjectOptions, setForProjectOptions] = useState([]);

	const handleAddToProject = (newEditedInv) => {
		// add billTo to the project as editor or add contractor to the project send request to project owner
		// const projectDoc = usersProjects.find(proj => proj.id === val)
		addEditorsToProject(
			currentUser.uid,
			// { ...editedInv, [name]: val }, 
			newEditedInv,
			followersUserObjs,
			setErrorObj,
			handleSetSuccessText
		);

		addFollowersToProject(
			currentUser.uid,
			// { ...editedInv, [name]: val },
			newEditedInv,
			followersUserObjs,
			setErrorObj,
			handleSetSuccessText
		);
	};

	const checkhasEdited = (objA, objB) => {
		objB = objB || originalFormData
		if (JSON.stringify(objA) !== JSON.stringify(objB)) {
			return true;
		} else {
			return false;
		}
	};

	const handleSubmit = async (e, fd) => {
		try {
			e.preventDefault();
			if (currentUser && checkhasEdited(fd)) {
				if (originalFormData.forProject !== fd.forProject && fd.forProject) {
					handleAddToProject({...editedInv, ...fd});
				}
				// reset closed date if not indicated as closed
				if (!fd.closed) {
					fd.closedDate = "";
				}
				// handle updates to all paired invoice
				if (hasPairedInvoices) {
					// check if they were idited 
					for (let key in fd.pairedInvoiceOptions) {
						const oldPairedInv = editedInv.pairedInvoiceOptions[key]
						const newPairedInv = fd.pairedInvoiceOptions[key]
						const unpaired = !fd.pairedInvoices.includes(key)
						let userCanUpdatePairedInv = true
						const previouslyUnpaired = oldPairedInv.unpaired || !editedInv.pairedInvoices.includes(key)
						const pairedInvWasDeleted = oldPairedInv.deleted

						if (pairedInvWasDeleted || previouslyUnpaired) {
							userCanUpdatePairedInv = false
						}

						if (userCanUpdatePairedInv && newPairedInv && deepObjectCompareDiffers({a: oldPairedInv, b: newPairedInv})) {
							// make the changes to the paired inv
							// try to get the paired inv first
							const pairedInvDoc = await firebase.firestore().collection("invoices").doc(key).get()
							.then(doc => ({...doc.data(), id: doc.id}))
							.catch(err => {
								if (err.message.includes("Missing or insufficient permissions")) {
									// inv could have been deleted or permission of this user to view revoked 
									return false
								} else throw err
							})

							if (pairedInvDoc) {
								await firebase.firestore().collection("invoices").doc(key).update({
									...unpaired ? {
										leviedInvoice: "",
										leviedInvoiceOptions: null,
									} : {
										leviedInvoiceOptions: {
											condenseEntriesIn: newPairedInv.condenseEntriesIn || [],
											doNotUpdate: newPairedInv.doNotUpdate || [],
										}
									}
								}).catch(err => {
									err.message = "Could not update paired invoice, please try again later. Problem: " + err.message 
									throw err
								})
							}

						}

					}
					
				}

				handleSetEditedInv({mergeIntoHistory: true, changes:fd, caller: "invoiceSummary.js handleSubmit"})
				handleSetInv({changes: fd, caller: "invoiceSummary.js handleSubmit"})

				await handleSave({ newEditedInv: fd });
				setHasBeenEdited(false);
				setShowInvoiceSummary(false);
			} else {
				setShowInvoiceSummary(false);
			}	
		} catch (err) {
			// throw err
			setErrorObj(err)
		}
	};

	const handleChange = (name, value) => {
		let newValue = value;

		let newData = {}
		if (name === "industry" && value === "N/A") {
			newValue = "";
		}

		if (name === "industry" && value !== "N/A") {
			const foundIndustry = industryOptions.find(obj => obj.name === value)
			if (foundIndustry && foundIndustry.rate ) {
				newData = {
					...newData,
					rate: foundIndustry.rate
				}
			}
		}

		if (name === "visibility" && value === "private") {
			newData = {
				...newData,
				searchable: false
			}
		}

		if (checkhasEdited({ ...formData, [name]: newValue })) {
			setHasBeenEdited(true);
		} else {
			setHasBeenEdited(false);
		}


		newData = {
			...newData,
			[name]: newValue
		}

		setFormData((formData) => ({ ...formData, ...newData}));
	};

	const handleChangeDoNotUpdate = (name, val, key) => {
		let newFormData = {}

		if (val) {
			newFormData = {
				...formData,
				pairedInvoiceOptions: {
					...formData.pairedInvoiceOptions,
					[key]: {
						...formData.pairedInvoiceOptions[key],
						doNotUpdate: formData.pairedInvoiceOptions[key].doNotUpdate.filter(listItem => listItem !== name)
					}
				}
			}
		} else {
			newFormData = {
				...formData,
				pairedInvoiceOptions: {
					...formData.pairedInvoiceOptions,
					[key]: {
						...formData.pairedInvoiceOptions[key],
						doNotUpdate: [...formData.pairedInvoiceOptions[key].doNotUpdate, name]
					}
				}
			}
		}
		setFormData(newFormData)
		if (checkhasEdited(newFormData)) {
			setHasBeenEdited(true);
		} else {
			setHasBeenEdited(false);
		}
	}

	const handleChangePairedInvoices = (pairedInv, key) => {
		let newFormData = {}
		if (pairedInv.unpaired) {
			// add the paired invoice back in and re paire item
			newFormData = {
				...formData,
				pairedInvoices: [...formData.pairedInvoices, key],
				pairedInvoiceOptions: {
					...formData.pairedInvoiceOptions,
					[key]: {
						...formData.pairedInvoiceOptions[key],
						unpaired: false
					}
				}
			}
		} else {
			// remove the paired invoicec and mark it unpaired
			newFormData = {
				...formData,
				pairedInvoices: formData.pairedInvoices.filter(id => id !== key),
				pairedInvoiceOptions: {
					...formData.pairedInvoiceOptions,
					[key]: {
						...formData.pairedInvoiceOptions[key],
						unpaired: true
					}
				}
			}
		}
		setFormData(newFormData)
		if (checkhasEdited(newFormData)) {
			setHasBeenEdited(true);
		} else {
			setHasBeenEdited(false);
		}
	}

	const handlePaymentMethodChange = (name, value) => {
		const nameArray = name.split(".")
		if (nameArray.length > 2) {
			console.error("The handlePaymentMethodChange function is not set up to handle 3 layers of nesting")
		}
		const newFormData = {
			...formData,
			paymentMethods: {
				...formData.paymentMethods,
				[nameArray[0]]: {
					...formData.paymentMethods[nameArray[0]],
					[nameArray[1]]: value
				},
			}
		}
		setFormData(newFormData)
		if (checkhasEdited({ ...newFormData})) {
			setHasBeenEdited(true);
		} else {
			setHasBeenEdited(false);
		}
	}

	useEffect(() => {
		if (checkhasEdited(formData)) {
			setHasBeenEdited(true)
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (editedInv.forProject) {
			// if project is in this users project list add project options
			// else get the project data and put it in the options list
			if (
				usersProjects &&
				usersProjects.find((proj) => proj.id === editedInv.forProject)
			) {
				setForProjectOptions([
					{ name: "N/A", value: "" },
					...usersProjects.map((proj) => ({
						name: proj.projectName,
						value: proj.id,
					})),
				]);
			} else {
				firebase
					.firestore()
					.collection("projects")
					.doc(editedInv.forProject)
					.get()
					.then((doc) => {
						// setForProjectName(doc.data().projectName)
						// originalFormData.projectName = doc.data().projectName;
						// setFormData((formData) => ({
						// 	...formData,
						// 	projectName: doc.data().projectName,
						// }));
						setForProjectOptions([
							{ name: "N/A", value: "" },
							{ name: doc.data().projectName, value: doc.id },
							...(usersProjects
								? usersProjects.map((proj) => ({
										name: proj.projectName,
										value: proj.id,
								  }))
								: []),
						]);
					})
					.catch((err) => {
						if (err.message && err.message.includes("Missing or insufficient permissions")) {
							// originalFormData.projectName = "Private Project";
							// setFormData((formData) => ({
							// 	...formData,
							// 	projectName: "Private Project",
							// }));
							setForProjectOptions([
								{ name: "N/A", value: "" },
								{ name: "Private Project", value: editedInv.forProject },
								...(usersProjects
									? usersProjects.map((proj) => ({
											name: proj.projectName,
											value: proj.id,
									  }))
									: []),
							]);
						} else {
							setErrorObj(err);
						}
					});
			}
		} else {
			setForProjectOptions([
				{ name: "N/A", value: "" },
				...(usersProjects
					? usersProjects.map((proj) => ({
							name: proj.projectName,
							value: proj.id,
					  }))
					: []),
			]);
		}

		(async () => {
			try {
				if (currentUser && editedInv.contractor.id === currentUser.uid) {
					setIndustryOptions(userObject.industries);
				} else {
					const contractorObj = await firebase
						.firestore()
						.collection("users")
						.doc(editedInv.contractor.id)
						.get()
						.then((doc) => ({ ...doc.data(), id: doc.id }));
					setIndustryOptions(contractorObj.industries);
				}
			} catch (err) {
				setErrorObj(err);
			}
		})();

		// eslint-disable-next-line
	}, [editedInv, currentUser, userObject]);

	const invoiceStats = (
		<div key="stats">
			<span>Current Amount Owing:&nbsp;</span>
			<span>${invoiceOwedStr}</span>
			<div className="small-line-break" />
			{invoiceTotals.laborEntries.qty > 0 && (
				<React.Fragment>
					<span>Total Hours to Date:&nbsp;</span>
					<span>{getNum(invoiceTotals.laborEntries.qty, 2)}</span>
					<div className="small-line-break" />
				</React.Fragment>
			)}
			<details>
				<summary>Followers</summary>
				{followersUserObjs.map((user) => {
					return (
						<div key={user.id} className="value">
							-{" "}
							<Link className="link-appearance" to={`/users/${user.username}`}>
								{user.username}
							</Link>
							&nbsp;
							{userIsOwner ||
							(currentUser && userCanEdit && currentUser.uid === user.id) ? (
								<select
									disabled={formData.closed}
									name={user.id}
									value={user.type}
									onChange={(e) =>
										handleChangeUserCapabilities(
											e.target.name,
											e.target.value,
											editedInv,
											setEditedInv,
											"invoices",
											followersUserObjs,
											setFollowersUserObjs,
											userIsOwner,
											userObject.uid,
											setErrorObj,
											handleSetSuccessText,
											dialog,
										)
									}
								>
									{currentUser.uid !== user.id && (
										<option value="none">remove</option>
									)}
									<option value="viewer">viewer</option>
									<option value="editor">editor</option>
									<option value="owner" disabled={!userIsOwner}>
										owner
									</option>
								</select>
							) : (
								<span>({user.type})</span>
							)}
						</div>
					);
				})}
			</details>
			<div className="section-divider" />
		</div>
	);
	
	const findTableName = (tableKey, defaultName) => {
		let newName = defaultName
		let headingName = tableKey.replace("Entries", "Heading")
		if (tableKey === "description") {
			const pageOrderItem = editedInv.topSectionPageOrder.find(item => item.for === tableKey)
			newName = (pageOrderItem && pageOrderItem.tableHeadings && pageOrderItem.tableHeadings[0] && pageOrderItem.tableHeadings[0].val) ? pageOrderItem.tableHeadings[0].val : defaultName
		} else {
			const pageOrderItem = editedInv.pageOrder.find(item => item.for === headingName)
			newName = (pageOrderItem && pageOrderItem.val) ? pageOrderItem.val : defaultName
		}

		return newName
	}

	// handle paired invoices
	let pairedInvoiceInputs = []
	if (hasPairedInvoices && userCanEdit) {
		pairedInvoiceInputs = [
			{
				visible: true,
				properties: {
					type: "hidden",
					id: "hiddenInput4",
					onChange: () => null,
				},
				beforeInput: [
					<React.Fragment key="pairedInvoices">
						<div className="section-divider" />
						<div className="section-title">Paired Invoice Settings</div>
					</React.Fragment>
				],
			}
		]

		// handle doNotUpdate items
		for (let key in formData.pairedInvoiceOptions) {
			const oldPairedInv = editedInv.pairedInvoiceOptions[key]
			const pairedInv = formData.pairedInvoiceOptions[key]
			let pairedInvTextClassName = ""

			const previouslyUnpaired = oldPairedInv.unpaired || !editedInv.pairedInvoices.includes(key)
			const pairedInvWasDeleted = oldPairedInv.deleted

			if (pairedInv.unpaired) {
				pairedInvTextClassName = "strikethrough"
				if (expandedPairedInvoices.includes(key)) {
					if (!previouslyUnpaired && !pairedInvWasDeleted) {
						setExpandedPairedInvoices(expandedPairedInvoices => expandedPairedInvoices.filter(id => id !== key))
					}
				}
			}
			if (previouslyUnpaired || pairedInvWasDeleted) {
				pairedInvoiceInputs.push({
					visible: true,
					properties: {
						type: "hidden",
						id: "hiddenInput-" + key,
						onChange: () => null,
					},
					beforeInput: [
						<div key={key} >
							<strong className={`clickable ${pairedInvTextClassName}`} >
								{`Invoice #${pairedInv.invNumber} (${pairedInv.invShortHand})`}
							</strong>{" "}
							<span>{pairedInvWasDeleted ? "deleted" : "unpaired" }</span>
							{" "}
							<MoreInfoIcon
								absolute={true}
								text={pairedInvWasDeleted ? "This invoice cannot be paired because it was deleted" : "This invoice has been unpaired. You can pair it again if you have access to it and you are who the invoice is billed to"} 
							/>
						</div>
					]
				})

			} else {
					pairedInvoiceInputs.push(
						{
							visible: true,
							properties: {
								type: "hidden",
								id: "hiddenInput-" + key,
								onChange: () => null,
							},
							beforeInput: [
								<div key={key}>
									<strong className={`clickable ${pairedInvTextClassName}`} onClick={(e) => {
										if (expandedPairedInvoices.includes(key)) {
											setExpandedPairedInvoices(expandedPairedInvoices => expandedPairedInvoices.filter(id => id !== key))
										} else {
											setExpandedPairedInvoices(expandedPairedInvoices => [...expandedPairedInvoices, key])
										}
									}}>{`${expandedPairedInvoices.includes(key) ? '▼' : '▶'} Invoice #${pairedInv.invNumber} (${pairedInv.invShortHand})`}
									</strong>{" "}
									<span className="link-appearance" 
										onClick={() => handleChangePairedInvoices(pairedInv, key)}
									>{`${pairedInv.unpaired ? "Pair" : "un-pair"} invoice`}
									</span>{" "}
									<MoreInfoIcon
										absolute={true}
										text={`If un-paired, changes made to Invoice #${pairedInv.invNumber} (${pairedInv.invShortHand}) will no longer affect Invoice #${editedInv.invNumber} (${editedInv.invShortHand})`} 
									/>
								</div>
							]
						},
						{
							label: "Auto update Invoice " + findTableName("description", "Description"),
							visible: expandedPairedInvoices.includes(key),
							onChange: (name, val) => handleChangeDoNotUpdate(name, val, key),
							properties: {
								type: "checkbox",
								id: "description",
								value: formData.pairedInvoiceOptions[key].doNotUpdate.includes("description"),
								checked: !formData.pairedInvoiceOptions[key].doNotUpdate.includes("description"),
								// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
							},
						},
						{
							label: "Auto update Invoice Industry",
							visible: expandedPairedInvoices.includes(key),
							onChange: (name, val) => handleChangeDoNotUpdate(name, val, key),
							properties: {
								type: "checkbox",
								id: "industry",
								value: formData.pairedInvoiceOptions[key].doNotUpdate.includes("industry"),
								checked: !formData.pairedInvoiceOptions[key].doNotUpdate.includes("industry"),
								// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
							},
						},
						{
							label: "Auto update Invoice " + findTableName("materialEntries", "Material") + " Entries",
							visible: expandedPairedInvoices.includes(key),
							onChange: (name, val) => handleChangeDoNotUpdate(name, val, key),
							properties: {
								type: "checkbox",
								id: "materialEntries",
								value: formData.pairedInvoiceOptions[key].doNotUpdate.includes("materialEntries"),
								checked: !formData.pairedInvoiceOptions[key].doNotUpdate.includes("materialEntries"),
								// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
							},
						},
						{
							label: "Auto update Invoice " + findTableName("laborEntries", "Labor") + " Entries",
							visible: expandedPairedInvoices.includes(key),
							onChange: (name, val) => handleChangeDoNotUpdate(name, val, key),
							properties: {
								type: "checkbox",
								id: "laborEntries",
								value: formData.pairedInvoiceOptions[key].doNotUpdate.includes("laborEntries"),
								checked: !formData.pairedInvoiceOptions[key].doNotUpdate.includes("laborEntries"),
								// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
							},
						},
						{
							label: "Auto update Invoice custom " + findTableName("summaryEntries", "Summary") + " Entries",
							visible: expandedPairedInvoices.includes(key),
							onChange: (name, val) => handleChangeDoNotUpdate(name, val, key),
							properties: {
								type: "checkbox",
								id: "summaryEntries",
								value: formData.pairedInvoiceOptions[key].doNotUpdate.includes("summaryEntries"),
								checked: !formData.pairedInvoiceOptions[key].doNotUpdate.includes("summaryEntries"),
								// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
							},
						},
						{
							label: "Auto update Invoice custom " + findTableName("paymentEntries", "Payment") + " Entries ",
							visible: expandedPairedInvoices.includes(key),
							onChange: (name, val) => handleChangeDoNotUpdate(name, val, key),
							properties: {
								type: "checkbox",
								id: "paymentEntries",
								value: formData.pairedInvoiceOptions[key].doNotUpdate.includes("paymentEntries"),
								checked: !formData.pairedInvoiceOptions[key].doNotUpdate.includes("paymentEntries"),
								// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
							},
							moreInfoIcon: {
								after: <span className="tiny"> (Not reccomended) </span>,
								absolute: true,
								text:"Only manually added payment entries will be updated. Verified Payment entries cannot be transfered"		
							}
						},
					)

			}

		}

		// handle condenseEntriesIn items
		// ...
		
	}



	const inputs = [
		{
			visible: !userIsOwner && !userIsBillTo && !userCanEdit,
			properties: {
				type: "hidden",
				id: "hiddenInput1",
				onChange: () => null,
			},
			beforeInput: [
				<React.Fragment key="forProject">
					{editedInv.forProject && (
						<span>
							For Project&nbsp;
							{forProjectOptions.length && (
								<Link
									className="link-appearance blue"
									to={`/projects/${formData.forProject}`}
								>
									{forProjectOptions.find(
										(proj) => proj.value === formData.forProject
									).name || "Project Link"}
								</Link>
							)}
						</span>
					)}
				</React.Fragment>,
				<div key="statsLB" className="small-line-break" />,
				invoiceStats,
				<React.Fragment key="visibility">
					<div>
						{`Visibility: ${formData.visibility} `}
						<MoreInfoIcon
							absolute={true}
							text={
								formData.visibility === "private"
									? "Only followers can view this invoice"
									: "Anyone with the link can view this invoice"
							}
						/>
					</div>
					{formData.industry && (
						<React.Fragment>
							<div className="small-line-break" />
							<div>{`Industry: ${formData.industry}`}</div>
						</React.Fragment>
					)}
					<div className="small-line-break" />
				</React.Fragment>,
			],
		},
		{
			custom: true,
			label: "For Project",
			onChange: handleChange,
			visible: userIsOwner || userIsBillTo || userCanEdit,
			properties: {
				type: "dropdown",
				name: "forProject",
				options: forProjectOptions,
				optionVal: "value",
				value: formData.forProject,
				disabled: (!userIsBillTo && !userIsOwner) || formData.closed, // editors must not be able to change the For Project
			},
			insideInputDiv: [
				<React.Fragment key="forProjectLink">
					&nbsp;
					{formData.forProject && forProjectOptions.length && (
						<Link
							className="link-appearance blue"
							to={`/projects/${formData.forProject}`}
						>
							{forProjectOptions.find(
								(proj) => proj.value === formData.forProject
							).name || "Project Link"}
						</Link>
					)}
				</React.Fragment>,
			],
			afterInput: [
				invoiceStats,
				<div key="settings">
					<div className="section-title">General Settings</div>
					<div className="small-line-break" />
				</div>,
			],
		},
		{
			visible: userCanEdit,
			onChange: handleChange,
			label: "Enable auto save ",
			properties: {
				type: "checkbox",
				// type: "checkbox",
				id: "autoSaveEnabled",
				value: !formData.autoSaveEnabled,
				checked: formData.autoSaveEnabled,
			},
			moreInfoIcon: {
				absolute: true,
				text:"Save automatically within 2 seconds of editing. Note: Making edits soon after an invoice is saved can cause a typing problem for some browsers. Uncheck this box if it is causing problems for you."
			},
			afterInput: [
				<React.Fragment key="after-autosave">
					<div className="small-line-break" />
				</React.Fragment>
			]
		},
		{
			custom: true, // label won't show on input type hidden if custom
			// custom is required in input type dropdown but not in input type hidden
			label: "Industry",
			visible: userCanEdit,
			properties: {
				type: "dropdown",
				name: "industry",
				options: [{ name: "N/A", rate: "" }, ...industryOptions],
				optionVal: "name",
				value: formData.industry,
				onChange: handleChange,
				disabled: formData.closed,
			},
			afterInput: [
				<React.Fragment key="add-new-industry">
					{currentUser &&
						editedInv.contractor.id === currentUser.uid &&
						!editedInv.closed && (
							<div>
								<span
									onClick={() => setSettingsModalOpen(true)}
									className={`link-appearance small${
										formData.closed && " hidden"
									}`}
								>
									Add new industry
								</span>
							</div>
						)}
				</React.Fragment>,
			],
		},
		{
			label: "Default rate",
			visible: userCanEdit,
			onChange: (name, val, e) => {
				return handleChange(name, e.target.valueAsNumber, e)
			},
			properties: {
				type: "number",
				name: "rate",
				id: "rate",
				value: formData.rate,
				disabled: formData.closed,
			},
		},
		{
			beforeInput: [
				<div key="beforeVisibilityInput" className="small-line-break" />			
			],
			custom: userIsBillTo || userCanEdit, // label won't show on input type hidden if custom
			// custom is required in input type dropdown but not in input type hidden
			label: "Visibility",
			visible: userIsBillTo || userCanEdit,
			properties: {
				// type: (userIsOwner || userIsBillTo) ? "dropdown" : "hidden",
				type: "dropdown",
				name: "visibility",
				options: [{ name: "private" }, { name: "public" }],
				optionVal: "name",
				value: formData.visibility,
				onChange: handleChange,
				disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
			},
			insideInputDiv: [
				<React.Fragment key="visibilityInfo">
					&nbsp;
					<MoreInfoIcon
						absolute={true}
						text={
							formData.visibility === "private"
								? "Only followers can view this invoice"
								: "Anyone with the link can view this invoice"
						}
					/>
				</React.Fragment>,
			],
		},
		{
			label: userIsOwner || userIsBillTo ? "Show invoice in public search results" : "",
			visible: formData.visibility === "public",
			onChange: handleChange,
			properties: {
				type: userIsOwner || userIsBillTo ? "checkbox" : "hidden",
				// type: "checkbox",
				id: "searchable",
				value: !formData.searchable,
				checked: formData.searchable,
				disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
			},
		},
		{
			custom: userCanEdit, // label won't show on input type hidden if custom
			label: "Invoice Status",
			// custom is required in input type dropdown but not in input type hidden
			properties: {
				// type: (userIsOwner || userIsBillTo) ? "dropdown" : "hidden",
				type: userCanEdit ? "dropdown" : "hidden",
				name: "closed",
				options: [
					{ name: "Running", value: false },
					{ name: "Complete", value: true },
				],
				optionVal: "value",
				value: formData.closed,
				onChange: handleChange,
				disabled: !(userIsCreatorOrContractor || userIsOwner),
			},
			insideInputDiv: [
				<React.Fragment key="statusInfo">
					&nbsp;
					<MoreInfoIcon
						absolute={true}
						text={
							formData.closed
								? "This invoice has been marked as complete"
								: "This invoice is not yet complete"
						}
					/>
				</React.Fragment>,
			],
			beforeInput: [
				<React.Fragment key="InvoiceStatusNoInput">
					<div className="small-line-break" />
					{!userCanEdit && (
						<div>
							Invoice Status: {formData.closed ? "Complete" : "Running"}
						</div>
					)}
				</React.Fragment>,
			],
		},
		{
			label: "Date completed",
			visible: formData.closed && userCanEdit,
			onChange: handleChange,
			properties: {
				type: "date",
				// type: userCanEdit ? "date" : "hidden",
				id: "closedDate",
				value: formData.closedDate,
				disabled: !(userIsCreatorOrContractor || userIsOwner),
				required: true,
			},
			afterInput: [
				<div key="closedDate">
					{!userCanEdit &&
						formData.closed &&
						"Date completed: " + formData.closedDate}
					<div className="small-line-break" />
				</div>,
			],
		},
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "hiddenInput2",
				onChange: () => null,
			},
			beforeInput: [
				<React.Fragment key="delete-invoice">
					{userIsOwner && (
						<div>
							<div className="small-line-break" />
							<span
								className="link-appearance blue"
								onClick={async (e) => {
									try {
										e.preventDefault();
										const whenLoading = () => null;

										const invoiceRemoved = await handleRemoveInvoice(
											editedInv,
											setErrorObj,
											whenLoading,
											dialog,
										);
										if (invoiceRemoved) {

											setUsersInvoices((usersInvoices) => {
												if (usersInvoices) {
													return usersInvoices.filter(
														(inv) => inv.id !== editedInv.id
													)
												} else return usersInvoices
											});
											handleSetEditedInv({mergeIntoHistory: true, changes: {id: "notFound"}, caller: "invoiceSummary.js delete invoice"})
											handleSetInv({changes: {id: "notFound"}, caller: "invoiceSummary.js delete invoice"})
											// must set not found to true before removing so that it is not added back in right away from the useEffect in specificInvoice
											await removeDocFromRecentlyViewed(editedInv);
											setShowInvoiceSummary(false)
											handleSetSuccessText("Invoice Removed");
										}
									} catch (err) {
										setErrorObj(err);
									}
								}}
							>
								Delete Invoice{" "}
							</span>
						</div>
					)}
				</React.Fragment>
			],
		},
		...pairedInvoiceInputs,
		// {
		// 	label: unpaired,
		// 	// visible: userIsOwner || userIsBillTo,
		// 	onChange: handlePaymentMethodChange,
		// 	properties: {
		// 		type: "checkbox",
		// 		id: key + ".accepted",
		// 		value: !formData.paymentMethods[key].accepted,
		// 		checked: formData.paymentMethods[key].accepted,
		// 		disabled: (key === "stripe" && !canAcceptStripePayments),
		// 	},
		// },
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "hiddenInput3",
				onChange: () => null,
			},
			beforeInput: [
				<React.Fragment key="billTo">
					{(userIsOwner ||
						userCanEdit ||
						editedInv.billTo.firstname ||
						editedInv.billTo.lastname ||
						editedInv.billTo.username) && (
						<div>
							<div className="section-divider" />
							<div className="section-title">Billed To</div>
						</div>
					)}
					{editedInv.billTo.uid ? (
						<div>
							<Link
								className="link-appearance"
								to={`/users/${editedInv.billTo.username}`}
							>
								@{editedInv.billTo.username}
							</Link>
						</div>
					) : (
						<div>{`${editedInv.billTo.firstname || ""} ${
							editedInv.billTo.lastname || ""
						} `}</div>
					)}
					{(userIsOwner || userCanEdit) && (
						<div>
							<span
								className="link-appearance small blue"
								onClick={(e) => {
									e.preventDefault();
									setShowInvoiceSummary(false);
									setChangeBillToModalOpen(true);
								}}
							>
								Bill To settings
							</span>
						</div>
					)}
					<div className="small-line-break" />
				</React.Fragment>,
			]
		}
	];

	if (userIsOwner && formData.paymentMethods) {
		// add a new input
		let addedInputs = []
		for (let key in formData.paymentMethods) {
			const prop = formData.paymentMethods[key]
			addedInputs.push(
				{
					label: "Accept " + key,
					// visible: userIsOwner || userIsBillTo,
					onChange: handlePaymentMethodChange,
					properties: {
						type: "checkbox",
						id: key + ".accepted",
						value: !formData.paymentMethods[key].accepted,
						checked: formData.paymentMethods[key].accepted,
						disabled: (key === "stripe" && !canAcceptStripePayments),
					},
					afterInput: [
						<React.Fragment key={"paymentMethods" + key + "payableTo"} >
							{
								(formData.paymentMethods[key].accepted && prop.payableTo !== undefined) &&
								<React.Fragment>
									<label htmlFor={key + ".payableTo"} >
										{"Payable to: "} 
										<MoreInfoIcon
											absolute={true}
											text={"The name the payer should write on the cheque"}
										/>
									<input 
										type="text" 
										id={key + ".payableTo"}
										value={formData.paymentMethods[key].payableTo} 
										onChange={(e) => handlePaymentMethodChange(e.target.id, e.target.value)}
									/>
									</label>
									<div className="small-line-break" />
								</React.Fragment>
							}
						</React.Fragment>,
						<React.Fragment key={"paymentMethods" + key + "email"} >
							{
								(formData.paymentMethods[key].accepted && prop.email !== undefined) &&
								<React.Fragment>
									<label htmlFor={key + ".email"} >
										{"Email: "} 
									<MoreInfoIcon
										absolute={true}
										text={"The email address the payer should send the " + key + " to"}
									/>
									<input 
										type="text" 
										id={key + ".email"}
										value={formData.paymentMethods[key].email} 
										onChange={(e) => handlePaymentMethodChange(e.target.id, e.target.value)}
									/>
									</label>
									<div className="small-line-break" />
								</React.Fragment>
							}
						</React.Fragment>
					]
					// afterInput: [
					// 	<React.Fragment key={"paymentMethods" + key + "discount"} >
					// 	{
					// 		(formData.paymentMethods[key].accepted && prop.discount !== undefined) &&
					// 		<React.Fragment>
					// 			<label htmlFor={key + ".discount"} >
					// 				{key + " discount: % "} 
					// 			<input 
					// 				type="number" 
					//        step="0.01"
					// 				id={key + ".discount"}
					// 				max="100"
					// 				style={{width: "5ch"}}
					// 				value={formData.paymentMethods[key].discount} 
					// 				onChange={(e) => handlePaymentMethodChange(e.target.id, e.target.value)}
					// 			/>
					// 			{" "}
					// 			<MoreInfoIcon
					// 				absolute={true}
					// 				text={"Apply a discount if your customer pays with " + key}
					// 			/>
					// 			</label>
					// 		</React.Fragment>
					// 	}
					// 	<div className="small-line-break" />
					// 	</React.Fragment>
					// ]
				}
			)

			// if (prop.discount !== undefined) {
			// 	addedInputs.push({
			// 		label: key + " discount",
			// 		// visible: userIsOwner || userIsBillTo,
			// 		onChange: handlePaymentMethodChange,
			// 		properties: {
			// 			type: "number",
			// 			id: key + ".discount",
			// 			value: formData.paymentMethods[key].discount,
			// 			// disabled: formData.closed,
			// 		},
			// 	})
			// }
		}
		// add the title to the first added input
		addedInputs[0] = {
			...addedInputs[0], 
			beforeInput: [
				<div key="paymentSettings">
					<div className="section-divider" />
					<div className="section-title">Payment Settings</div>
					<div className="small-line-break" />
				</div>,
			]
		}
		inputs.push(...addedInputs)
	}

	return (
		<Form
			inputs={inputs}
			heading={
				<React.Fragment>
					<AppMessage />
				</React.Fragment>
			}
			submitName={hasBeenEdited ? "Save" : "Ok"}
			onSubmit={(e) => handleSubmit(e, formData)}
		></Form>
	);
};

export default InvoiceSummary;
