import React from 'react'
import { Link } from 'react-router-dom'

import './loginButtonsCss.css'

const FirebaseLoginWithProvider = ({setErrorObj, submitCallback, signInWithGoogle, signInWithFacebook, setShowEmailLoginItems, formData, cleanFormData, newUserData, signup /*, setIsFetching*/}) => {
	const handleSignInWithProvider = (e, provider) => {
		try {
			e.preventDefault()
			// add in empty strings etc on original userData object
			const addedData = cleanFormData({
				...newUserData, 
				...formData,
				industries: formData.industries.length ? formData.industries : (newUserData.industries || []),
				otherLocationsServed: formData.otherLocationsServed.length ? formData.otherLocationsServed : (newUserData.otherLocationsServed || []),
				// paymentMethods: formData.paymentMethods.length ? formData.paymentMethods : newUserData.paymentMethods || []
				// profileAbout
			}); 
			// addNewUserToDb runs as soon as authStateChanged. Cant find a way to pass the formdata to addNewUserToDB without infinite loops (if authStateChanged has newUserData as a dependent it wont stop rendering)
			sessionStorage.setItem("signupFormData", JSON.stringify(addedData));

			if (provider === "facebook.com") {
				signInWithFacebook(submitCallback)
			} else if (provider === "google.com") {
				signInWithGoogle(submitCallback)
			}

		} catch (err) {
			setErrorObj(err)
		}
	}

	return (
		<div>
			<div id="firebaseui_container" lang="en">
				<div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
					<div className="firebaseui-card-content">
						<form onSubmit={(e)=> e.preventDefault()} >
							<ul className="firebaseui-idp-list">
								<li className="firebaseui-list-item">
									<button onClick={(e) => handleSignInWithProvider(e, "google.com")} className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button" data-provider-id="google.com" style={{backgroundColor: "#ffffff"}} data-upgraded=",MaterialButton">
										<span className="firebaseui-idp-icon-wrapper">
											<img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
										</span>
										<span className="firebaseui-idp-text firebaseui-idp-text-long">{signup ? "Sign up" :  "Sign in"} with Google</span>
										<span className="firebaseui-idp-text firebaseui-idp-text-short">Google</span>
									</button>
								</li>
								<li className="firebaseui-list-item">
									<button onClick={(e) => handleSignInWithProvider(e, "facebook.com")} className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-facebook firebaseui-id-idp-button" data-size="medium" data-button-type="sign_in" data-layout="default" data-auto-logout-link="false" data-use-continue-as="true" data-width="" data-provider-id="facebook.com" style={{backgroundColor: "#3b5998"}} data-upgraded=",MaterialButton">
										<span className="firebaseui-idp-icon-wrapper">
											<img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg" />
										</span>
										<span className="firebaseui-idp-text firebaseui-idp-text-long">{signup ? "Sign up" :  "Sign in"} with Facebook</span>
										<span className="firebaseui-idp-text firebaseui-idp-text-short">Facebook</span>
									</button>
								</li>
								<li className="firebaseui-list-item">
									<button onClick={(e) =>  {e.preventDefault(); setShowEmailLoginItems(showEmailLoginItems => !showEmailLoginItems)}} className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button" data-provider-id="password" style={{backgroundColor:"#db4437"}} data-upgraded=",MaterialButton">
										<span className="firebaseui-idp-icon-wrapper">
											<img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg" />
										</span>
										<span className="firebaseui-idp-text firebaseui-idp-text-long">{signup ? "Sign up" :  "Sign in"} with email</span>
										<span className="firebaseui-idp-text firebaseui-idp-text-short">Email</span>
									</button>
								</li>
							</ul>
						</form>
					</div>
					<div className="firebaseui-card-footer firebaseui-provider-sign-in-footer">
						<p className="firebaseui-tos firebaseui-tospp-full-message">By signing in, you are indicating that you accept our <Link to="/terms-and-conditions" className="firebaseui-link firebaseui-tos-link" target="_blank" rel="noopener noreferrer">Terms of Service</Link> and <Link to="/privacy-policy" className="firebaseui-link firebaseui-pp-link" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
						.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FirebaseLoginWithProvider