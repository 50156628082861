import React, { useContext, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useLocation, Link } from 'react-router-dom'

import { MessagingContext } from "../contexts/MessagingContext";
import { MediaQueryContext } from "../contexts/MediaQueryContext"; 
import { UserContext } from "../contexts/UserContext"; 

import ThreadMessages from "../components/Messaging/ThreadMessages"
// import ChromeThreadMessages from "../components/Messaging/ChromeThreadMessages"
import MessageThreads from "../components/Messaging/MessageThreads"
// import WriteMessageInput from "../components/Messaging/WriteMessageInput";
import { handleLogoImageOnError } from "../utils/appUtils";

import firebase from "../firebase";

let browserIsChrome = false
let browserIsEdge = false
const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
const isOpera = typeof window.opr !== "undefined";
const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
const isIOSChrome = winNav.userAgent.match("CriOS");

if (isIOSChrome) {
   // is Google Chrome on IOS
} else if (
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === false
) {
	browserIsChrome = true
   // is Google Chrome
} else if (
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === true
) {
	browserIsEdge = true
}


const MessagesStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	overflow-x: hidden;
	.messages-heading {
		position: relative;
	}
	.compose-new {
		position: absolute;
		top: 0;
		right: 0;
		svg {
			color: var(--chicago);
			height: 20px;
			width: 20px;
		}
	}

	h2 {
		margin: 0;
		font-weight: unset;
	}

	.toolbar {
    z-index: 2;
    background-color: white;
    width: 100%;
    margin-bottom: 20px;
    // position: absolute;
    bottom: 0;
    right: 0;
	}
	.main-container {
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
    overflow: hidden;
		display: ${({ showEmptyScreen }) => showEmptyScreen ? "block" : "flex"};
		min-height: 50vh;
		text-align: center;
		> div {
			width: 100%;
			table {
				width: 100%;
			}
		}
		.thread-heading {
	    text-align: center;
			.img-container {
				display: flex;
				justify-content: start;
				flex-flow: row-reverse;
				width: min-content;
				max-width: 50%;
		    overflow: hidden;
		    margin-left: auto;
		    margin-right: auto;
		    border-radius: 25px; //half of image height
				img {
			    // pointer-events: none;
					height: 50px;
					width: 50px;
			    box-shadow: 2px 2px 15px -5px;
			    border-radius: 50%;
				}
			}
		}
		.back-arrow {
			width: 100%;
			text-align: left;
			position: absolute;
			top: 0;
			a {
		  	color: var(--chicago);
			}
			svg {
				height: 20px;
				width: 20px;
				padding: 10px;
			}
		}
		.messages-view {
			// position: relative;
			// display: inline-block;
	    overflow-y: hidden;
		}

		.threads-view {
			// height: 60vh;
			display: inline-block;
			padding-bottom: 100px;
			${({ isLessThan700px }) => isLessThan700px ? "" : `
				max-width: 350px;
				margin-right: 10px;
			`}
		}
	}

`;


const useQuery = () => new URLSearchParams(useLocation().search);


const Messages = () => {
  const query = useQuery();
  let threadId = query.get("threadId")
  const messageFrom = query.get("from")
  const messageTo = query.get("to")
  const toUsername = query.get("toUsername")

	const {
		usersThreads,
		currentThreadData,
		setCurrentThreadData,
		handleRetrieveMessages,
		lastMessageRetrieved,
		setLastMessageRetrieved,
		reachedEndOfMessages,
		setReachedEndOfMessages,
		sendingMessage,
	} = useContext(MessagingContext);
  const { isLessThan700px } = useContext(MediaQueryContext);
  const {
  	userObject,
  } = useContext(UserContext)

  const [threadNotFound, setThreadNotFound] = useState(null)
  const [newestMessageRead, setNewestMessageRead] = useState(null)


	const handleIndicateMessageRead = useCallback((e) => {
		// firefox doesnt have e.path
		const elementsTargeted = e && (e.path || e.composedPath())
		// do not remove the notification of a new message if the notifications bell is clicked
		if (elementsTargeted && elementsTargeted.find(el => el.id === "svg-bell")) {
			return 
		}
		if (newestMessageRead !== currentThreadData.newestMessage.id) {
			setNewestMessageRead(currentThreadData.newestMessage.id)
			const newThreadData = {
				participants: {
					...currentThreadData.participants,
	  			[userObject.id]: {
	  				...currentThreadData.participants[userObject.id],
	  				lastMessageRead: currentThreadData.newestMessage.id,
	  				lastMessageReadAt: firebase.firestore.Timestamp.now().seconds
	  			}
				}
			}
			firebase.firestore().collection("messageThreads").doc(currentThreadData.id).set({
				...newThreadData
			}, { merge: true }).catch(err => {
				console.error(err)
			})	
		}
	}, [currentThreadData, newestMessageRead, userObject])

	useEffect(() => {
	  if (threadId && currentThreadData && threadId !== currentThreadData.id) {
	  	// reset lastMessageRetrieved
	  	if (lastMessageRetrieved) {
		  	setLastMessageRetrieved(null)
	  	}
	  	if (reachedEndOfMessages) {
		  	setReachedEndOfMessages(false)
	  	}
	  }
	}, [threadId, currentThreadData, lastMessageRetrieved, setLastMessageRetrieved, reachedEndOfMessages, setReachedEndOfMessages])

	// handle when user is viewing a message thread, get the messages
	useEffect(() => {
	  if (userObject && userObject.id && usersThreads) {
	  	// get the thread
	  	// usersThreads will be null until gotten
  		const minimumMessagesToShow = 10
	  	let threadInUsersThreads 
	  	// if there is a threadId in url
	  	if (threadId) {
		  	threadInUsersThreads = usersThreads.find(thread => thread.id === threadId)
	  	} else {
	  		// set the thread to the most recent message
	  		threadInUsersThreads = usersThreads[usersThreads.length - 1]	  		
	  	}

	  	if (threadInUsersThreads) {
	  		// if we've already gotten messages, just set the messages, else get messages
	  		if (threadNotFound) {
	  			setThreadNotFound(false)
  			}

	  		if (threadInUsersThreads.messages && (Object.keys(threadInUsersThreads.messages).length >= minimumMessagesToShow || lastMessageRetrieved)) {
		  		setCurrentThreadData({
		  			...threadInUsersThreads
		  		})

	  		} else {
		  		// if user has not already looked at these messages we store them in the thread.messages so we dont have to get them from DB again
		  		// get the thread messages from DB (handleRetrieveMessages checks if we've reached end of messages)
					if (!sendingMessage) {
			  		handleRetrieveMessages({thread: threadInUsersThreads, lastDoc: lastMessageRetrieved, limit: minimumMessagesToShow})	  				
					}
	  		}
	  	} else {
	  		// must be a new message and the newThread data was not set when user clicked send message or the user refreshed/ landed directly on this page
	  		// set the new thread data 
	  		if (messageTo && messageFrom && threadId) {
	  			if (threadNotFound) {
		  			setThreadNotFound(false)
	  			}
		  		setCurrentThreadData(currentThreadData => {
		  			return {
		  				id: threadId,
		  				newestMessage: {
		  					text: "",
		  					sender: userObject.id
		  				},
		  				accessors: [messageFrom, messageTo],
		  				messages: {},
		  				isGroup: false,
							participants: {
								[messageFrom]: {
									logoUrl: userObject.logoUrl,
									username: userObject.username,
								},
								[messageTo]: {
									logoUrl: (currentThreadData && currentThreadData.participants[messageTo]) ? (currentThreadData.participants[messageTo].logoUrl || "") : "",
									username: toUsername || "Linvo User",
								}
							}
		  			}
		  		})
	  		} else {
	  			setThreadNotFound(true)
	  		}
	  	}
	  }
	  // not including setCurrentThreadData or handleRetrieveMessages in dep array not necessary/ 
	  // eslint-disable-next-line
	}, [threadId, userObject, usersThreads, lastMessageRetrieved, messageFrom, messageTo, toUsername, sendingMessage])


	// listen to changes in document.hidden  (if this tab is in background)
	// any time currentThread data changes add a listener for mouse move to indicate message was read
	useEffect(() => {
		let addedMousedownListener = false

		// only mark message as read if the user is specifically in the thread and the thread is not just usersThreads[0]
		const threadIdsMatch = threadId && currentThreadData && threadId === currentThreadData.id 
		// if threadData is there and threadId matched threadData.id or there is no threadID and on desktop (desktop shows usersThreads[0])
		if (currentThreadData && currentThreadData.newestMessage && !threadNotFound && userObject.id && (threadIdsMatch || (!threadId && !isLessThan700px))) {

			// if there is no newest message read or the user has not read the newest message
			const userAsParticipant = currentThreadData.participants[userObject.id]
			// if newestMessageRead has already been set, check if it is the newest message
			if (newestMessageRead !== null && newestMessageRead !== currentThreadData.newestMessage.id) {
				// mark the message as read once the user clicks something
				// this is to prevent a message being marked as read when a user is in a different browser tab
				// listen for a mouse move or something to indicate the user is on the page and not in another browser tab
				if (userAsParticipant && userAsParticipant.lastMessageRead !== currentThreadData.newestMessage.id) {
					if (currentThreadData.newestMessage.id !== newestMessageRead) {
						addedMousedownListener = true
						// add a listener that will be removed as soon as mouseover happens because of dependancy array
						// document.addEventListener("mouseover", handleIndicateMessageRead)
						document.addEventListener("mousedown", handleIndicateMessageRead)

					}
				}
			} else {
				// should always run once, when currentThreadData.participants[userID]
				// set newestMessageRead read for the first time
				if (userAsParticipant && !newestMessageRead) {
					setNewestMessageRead(userAsParticipant.lastMessageRead)
				}
			}
			// setDocumentInBackground(false)
		}
		return () => {
			if (addedMousedownListener) {
				document.removeEventListener("mousedown", handleIndicateMessageRead)
			}
		}
		// not including handleIndicateMessageRead in dep array
	  // eslint-disable-next-line
	}, [currentThreadData, threadId, threadNotFound, userObject, newestMessageRead])

	let messagesClassName = ""
	let threadsClassName = "" 
	if (isLessThan700px) {
		messagesClassName = threadId ? "swipe-show" : "swipe-hide"
		threadsClassName = threadId ? "swipe-hide" : "swipe-show"
	} 


  // if (isLessThan700px) {
  	let threadParticipantsExcludingUser = []

  	if (currentThreadData && currentThreadData.participants) {
	  	for (let key in currentThreadData.participants) {
	  		const participant = currentThreadData.participants[key]
	  		if (key !== userObject.id) {
	  			threadParticipantsExcludingUser.push(participant)
	  		}
	  	}
  	} else {
  		threadParticipantsExcludingUser = [
  			{
  				logoUrl: "/assets/blank-profile-picture.png",
  				username: "Linvo user"
  			}
  		]
  	}

  	let showEmptyScreen = false
  	if (!userObject.id || (usersThreads && !usersThreads.length && !currentThreadData && !threadId)) {
  		showEmptyScreen = true
  	}


  	return (
			<MessagesStyle browserIsChrome={browserIsChrome} browserIsEdge={browserIsEdge} isLessThan700px={isLessThan700px} showEmptyScreen={showEmptyScreen}>
				<div className="main-container">
					<div className={`threads-view ${threadsClassName}`}>
						<div className="messages-heading">
							<h2>Messages</h2>
							<div className="compose-new" >
								<Link to={`/search${userObject.id ? "?category=users&myCustomers=true&text=all" : ""}`} >
									<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
								    <path d="M27 15 L27 30 2 30 2 5 17 5 M30 6 L26 2 9 19 7 25 13 23 Z M22 6 L26 10 Z M9 19 L13 23 Z" />
									</svg>
								</Link>
							</div>
						</div>
						<div className="section-divider" />
						<div className="section-divider" />

						<MessageThreads />
					</div>
				{/*messages view*/}
					{
						!showEmptyScreen ? 
						<div className={`messages-view right ${messagesClassName}`} >
							<div className="thread-heading">
								<div className="img-container" >
									{
										threadParticipantsExcludingUser.map((p, i) => {
											return <img key={i} 
												style={i !== 0 ? {marginRight: "-20px"} : {}} 
												src={p.logoUrl || "/assets/blank-profile-picture.png"} 
												alt="" 				  			
												onError={handleLogoImageOnError}
											/>
										})
									}
								</div>
								<div className="message-info">
									{
										threadParticipantsExcludingUser.length > 1 ?
										threadParticipantsExcludingUser.map(p => {
											return (
												p.username + " "
											)
										})
										: <div >
											<Link to={`/users/${threadParticipantsExcludingUser[0].usernameLink || threadParticipantsExcludingUser[0].username}`}>{threadParticipantsExcludingUser[0].username}</Link>
										</div>
									}
								</div>
							</div>
							{
								isLessThan700px &&
								<div className="back-arrow">
									<Link to={"/messages"}>
										<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
									    <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
										</svg>
									</Link>
								</div> 
							}
							{
								threadNotFound ? 
								<React.Fragment>
									<div className="section-divider" />
									<div className="section-divider" />
									<p className="align-center" >Message thread not found</p> 
								</React.Fragment>
								:
								<React.Fragment>
									<ThreadMessages isLessThan700px={isLessThan700px} browserIsChrome={browserIsChrome} browserIsEdge={browserIsEdge} threadId={threadId} threadNotFound={threadNotFound} newestMessageRead={newestMessageRead} handleIndicateMessageRead={handleIndicateMessageRead} />
								</React.Fragment>
							}

						</div>
						: null
					}
				</div>
			</MessagesStyle>
		)
};

export default Messages;
