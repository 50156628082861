import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

// import { UserContext } from '../../../contexts/UserContext'
import { MediaQueryContext } from '../../../contexts/MediaQueryContext'

// import DotActions from '../DotActions/DotActions'
import { ProfilePostsStyle, PostContainer } from './ProfilePostsStyle.styled'
import { handleImageOnError } from "../../../utils/appUtils";

// import firebase from '../../../firebase/index'

const ProfilePosts = ({userHasPosts, setPosts, posts, getPosts, setPostData, resultUserData, currentUsersProfile, setEditPostModalOpen}) => {
	const {
		isLessThan415px
	} = useContext(MediaQueryContext)

	// const {
	// 	userObject,
	// 	// offlineMode,
	// 	// handleSetSuccessText,
	// 	// setErrorObj
	// } = useContext(UserContext)

	// const [userHasPosts, setUserHasPosts] = useState('')
	const [lastVisibleDoc, setLastVisibleDoc] = useState(null)
	const [endOfPosts, setEndOfPosts] = useState(false)

	const renderImages = (images) => {
		if (!images.length) {
			return
		}
		if (images.length > 1) {
			let imageComponents = []
			images.forEach(img => {
				imageComponents.push(
					<div className="img-container" >
						<img 
							src={img.url} 
							alt={img.alt} 
							onError={handleImageOnError}
						/>
					</div>
				)
			})
			return (
				<div className="multiple-images-container">
					{imageComponents}
				</div>
			)
		} else {
			return (
				<div className="img-container" >
					<img 
						src={images[0].url} 
						alt={images[0].alt} 
						onError={handleImageOnError}
					/>
				</div>
			)
		}
	}

	useEffect(() => {
		if (resultUserData.id /*&& userObject*/) {
			// setPosts([])
			getPosts(null, setLastVisibleDoc, setEndOfPosts)
		}
		// eslint-disable-next-line
	}, [resultUserData.id])

	return (
		<div>
			{
				currentUsersProfile &&
				<div className="add-post">
					<button className="button-appearance white" onClick={() => {
						setEditPostModalOpen(true)
						setPostData({})
					}}>+ Add</button>
				</div>
			}
			{
				userHasPosts === true ? 
				<React.Fragment>
					<div className="section-divider" />
					<div className="section-divider" />
					<ProfilePostsStyle isLessThan415px={isLessThan415px}>
						{
							posts.map(obj => {
								return (
									<React.Fragment key={obj.id}>
										<PostContainer caption={obj.caption} numberOfPosts={posts.length} isLessThan415px={isLessThan415px} aspectRatio={obj.width / obj.height}>
											<div className="post-title">
												<div className="title-text" onClick={(e) => {
													// let text = obj.caption
													// if (e.target.innerText.length > 49) {
													// 	e.target.innerText = obj.caption.slice(0, 45) + " ..."
													// } else {
													// 	e.target.innerText = obj.caption
													// }
												}}>
													{
														// obj.caption.length > 49 ? obj.caption.slice(0, 45) + " ..." : obj.caption 
														obj.caption
													}
												</div>
												{
														// <svg id="i-ellipsis-vertical" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
													 //    <circle cx="16" cy="7" r="2" />
													 //    <circle cx="16" cy="16" r="2" />
													 //    <circle cx="16" cy="25" r="2" />
														// </svg>
														// <svg id="dots-icon" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
													 //    <circle cx="16" cy="7" r="2" fill="currentcolor" />
													 //    <circle cx="16" cy="16" r="2" fill="currentcolor" />
													 //    <circle cx="16" cy="25" r="2" fill="currentcolor" />
														// </svg>
													currentUsersProfile &&
													<div className="edit-post" onClick={() => {
														setEditPostModalOpen(true)
														setPostData({
															...obj
														})
													}} >
														Edit
													</div>
												}
											</div>
											{
												obj.photos &&
												renderImages(obj.photos)
											}
											<div className="post-tags-container">
												<div className="post-tags">
													{
														obj.tags && obj.tags.map((tag, i) => {
															let userType = "contractors"
															/*if (resultUserData.roles && (resultUserData.roles.subContractor || resultUserData.roles.generalContractor)) {
																userType = "contractors"
															} else {
																userType = "users"
															}*/
															return <Link key={i} to={`/search/?text=${encodeURIComponent(tag.replace("#", ""))}&category=${userType}&posts=true`} >{tag}</Link>
														})
													}
												</div>
												<div className="container-spacer" >#</div>
											</div>
											{
												// obj.comments &&
												// renderComments(obj.comments)
											}
										</PostContainer>
										{
											// isLessThan415px ?
											// <React.Fragment>
											// 	<div className="section-divider" />
											// 	<div className="section-divider" />
											// </React.Fragment>
											// : <div className="small-line-break" />
										}
									</React.Fragment>
								)
							})
						}
					</ProfilePostsStyle>
					{
						(lastVisibleDoc && !endOfPosts) &&
						<React.Fragment>
							<div className="section-divider" />
							<button onClick={() => {
								getPosts(lastVisibleDoc, setLastVisibleDoc, setEndOfPosts)
							}} className="button-appearance white">Load more</button>
						</React.Fragment>
					}
					{
						endOfPosts &&
						<React.Fragment>
							<div className="section-divider" />
							<div>End of posts</div>
						</React.Fragment>
					}
				</React.Fragment>
				: userHasPosts === false ?
				<React.Fragment>
					<div className="small-line-break" />
					<div>No posts yet</div>
				</React.Fragment>
				: <div>...loading</div>
			}
		</div>
	)
}

export default ProfilePosts
