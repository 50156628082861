import styled from 'styled-components'

export const NavbarStyle = styled.div`
	.nav-items-wrapper {
		padding: 5px 0 5px 0;
		width: 100%;
	  display: flex;
		font-size: 14px;
		.active {
	    border-bottom: inset var(--dark-blue);
		}
	}
	.sub-nav {
	  margin: 0 5px 20px 0;
	  white-space: pre-wrap;
    justify-content: space-around;
    ${({isLessThan700px}) => isLessThan700px ? `
	    box-shadow: 0px 4px 15px -10px;
	    ` : "" 
	  }
	}
	${({custom}) => custom}

`