import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FooterStyle } from "./FooterStyle";

import firebase from "../../firebase/index";
import { logInAnonymously } from '../../firebase/auth'


const Footer = ({ userObject, setLoginModalOpen, isLessThan700px, handleSetSuccessText }) => {
  // const [hasAcceptedCookies, setHasAcceptedCookies] = useState(null)
  const [timeoutToShowCookieBanner, setTimeoutToShowCookieBanner] = useState(false)
  const [closedCookieBanner, setClosedCookieBanner] = useState(false)

  const acceptsCookies = window.localStorage.getItem("acceptsCookies") === "true"
  const currentUser = firebase.auth().currentUser

  const showCookieBanner = !(acceptsCookies || currentUser)

  useEffect(() => {
    let timeout = window.setTimeout(() => {
      setTimeoutToShowCookieBanner(true)
    }, 5000)

    return () => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
    }
  }, [])


	return (
		<FooterStyle className="noprint" isLessThan700px={isLessThan700px}>
			<div className="footer-statement noprint">
        <p>
          Cloud based invoicing, project management and networking tools for modern contractors
          {/*Project management and invoicing built for independent construction contractors.*/}
        </p>
      	{
      		userObject.id ?
      		<Link className="button-appearance no-link" to="/dashboard" >Go to Dashboard</Link>
      		: 
          <React.Fragment>
            <div className="button-appearance" onClick={() => {
              setLoginModalOpen(true)}
            }>Sign up</div>
            <div className="section-divider" />
            <div className="tiny white">
              {"Not ready to sign up? "}
              <Link to="/dashboard" className="link-appearance blue" onClick={() => logInAnonymously()}>
                {"Just try it out now"}
              </Link>
              {" with a sample account"}
            </div>
          </React.Fragment>
      	}
        <div className="legal-links">
{/*          <div className="section-divider" />
          <div ><Link className="white" to="/marketplace">Marketplace</Link></div>
          <div ><Link className="white" to="/dashboard">Dashboard</Link></div>
          <div ><Link className="white" to="/messages">Messages</Link></div>
          <div className="section-divider" />
          <div ><Link className="white" to="/about">About</Link></div>
          <div ><Link className="white" to="/pricing">Pricing</Link></div>
          <div ><Link className="white" to="/contact">Contact</Link></div>
          <div ><Link className="white" to="/help">Help</Link></div>
*/}
          <div className="section-divider" />
          <div ><Link className="white" to="/terms-and-conditions">Terms</Link></div>
          <div ><Link className="white" to="/privacy-policy">Privacy Policy</Link></div>
        </div>
      </div>
        <div className={`cookies-notice ${(showCookieBanner && timeoutToShowCookieBanner && !closedCookieBanner) ? "show-height" : "hide-height"}`} >
          <div>
            <div>
              <svg className="close" onClick={() => setClosedCookieBanner(true)} viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M2 30 L30 2 M30 30 L2 2" />
              </svg>
            </div>
            <p>We use cookies to help improve your experience, by clicking "Accept" you
            agree to the placement and use of cookies as described in our <Link to="/cookies-policy" >Cookie Policy</Link>
            </p>
            <button className="button-appearance tiny no-icon-or-underline" onClick={() => {
              window.localStorage.setItem("acceptsCookies", "true")
              setClosedCookieBanner(true)
            }} >Accept</button>
          </div>
        </div>
      <div className="footer-navigation poppins-medium-slate-gray-12px noprint">
        <div>
          <img className="logo" alt="" src="/assets/linvo@2x.svg" />
        </div>
        <ul className="social-links" >
          <li>
            <a href="https://www.instagram.com/_linvo" target="_blank" rel="noopener noreferrer">
              <img alt="instagram profile" src="/assets/instagram-icon.svg"/>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/LinvoTechnologies" target="_blank" rel="noopener noreferrer">
              <img alt="Facebook profile" src="/assets/facebook-icon.svg"/>
            </a>
          </li>
        </ul>
        <ul className="site-links">
          <li><Link to="/about">About</Link></li>
          <li ><Link to="/help">Help</Link></li>
          <li ><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
      <div className="copyright-statement" >
        <p>
          © {new Date().getFullYear()} Linvo Technologies Inc.
          <br />
          <br />
          2-70 Church St. Nanaimo, BC V9R 5H4, Canada
        </p>
      </div>


		</FooterStyle>
	)
};

export default Footer;