import styled from 'styled-components'
//left off here working on styling 
// next work on cleaning it up and then creating a new invoice
export const ChangeLayoutModalStyle = styled.div`

	.show-table-headings-text {
		font-size: 12px;
	}
	.checkBoxes {
		font-size: 12px;
		display: inline-block;
		margin: 0 3px;
	}

`