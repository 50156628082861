import React, { useState, useEffect, useContext } from "react";

import { EditInvoiceContext } from "../contexts/EditInvoiceContext";

import Modal from "./Modal/Modal";
import Form from "./Forms/Form";

import firebase from "../firebase/index";
import {
	getLocalISODate,
	convertToAMPM,
	extractTimeFromDate,
} from "../utils/dateUtils";

// rendered by Invoice.js
// errors handled successText unnecessary
const StartWorkModal = ({
	userObject,
	startWorkModalIsOpen,
	setStartWorkModalIsOpen,
	invoiceId /*, inv*/,
}) => {
	const { handleSave, editedInv, handleSetEditedInv } =
		useContext(EditInvoiceContext);

	const currentDateISO = getLocalISODate();
	// format hh:mm
	const nowTime = extractTimeFromDate();

	const liveEntryRowObj = editedInv.laborEntries.find(
		(row) => row.id === editedInv.liveEntryId
	);
	let laborEntryForToday;

	if (
		liveEntryRowObj &&
		liveEntryRowObj.values.find((td) => td.for === "date").val ===
			currentDateISO
	) {
		laborEntryForToday = liveEntryRowObj;
	} else {
		laborEntryForToday = editedInv.laborEntries.find(
			(obj) => obj.values.find((td) => td.for === "date").val === currentDateISO
		);
	}

	// let todayStartTimeMin
	let minSinceLastStopWork = 0;

	const padTime = (num) => {
		if (num < 10) {
			if (num === 0) {
				return "00";
			} else {
				return "0" + num;
			}
		} else return num;
	};

	let lastShiftEntry

	if (liveEntryRowObj && laborEntryForToday) {
		if (liveEntryRowObj.id === laborEntryForToday.id) {
			lastShiftEntry = liveEntryRowObj.shiftEntries.sort((a, b) => parseInt(b.start) - parseInt(a.start))[0]
			minSinceLastStopWork = Math.round(
				Date.now() / 60000 - lastShiftEntry.stop
			);
		}
	}

	// const startedWorkTodayAtFullDate = new Date(editedInv.startedWorkTodayAt * 60000)
	// const hoursWorkedSoFar = ((Date.now() / 60000 - editedInv.startedWorkTodayAt - editedInv.todayBreakTimeMin) / 60).toFixed(2)

	const isStopWork = startWorkModalIsOpen.action === "stop";
	const isContinuingWork =
		liveEntryRowObj &&
		laborEntryForToday &&
		liveEntryRowObj.id === laborEntryForToday.id &&
		!isStopWork
			? true
			: false;
	// if the invoice only has default entries (newly created) overwrite this first entry
	// should never run unless default entry id === editedInv.liveEntryId
	// if (liveEntryRowObj.isDefaultEntry) {
	// 	isContinuingWork = false
	// }

	const userObjectIndustry = userObject.industries.length
		? userObject.industries.find((ind) => ind.name === editedInv.industry)
		: "";
	const industry = userObjectIndustry ? userObjectIndustry.name : "Labor";
	let rate = userObjectIndustry ? userObjectIndustry.rate : "";
	if (editedInv.rate) {
		rate = editedInv.rate;
	}

	let defaultFormData = {
		industry /*: userObject.industries.length ? userObject.industries[0].name : ""*/,
		rate: parseFloat(rate),
		// startTime: `${resultHours}:${resultMinutes}`,
		startTime: nowTime, // hh:mm
		resumeTime: "",
		hoursPlanned: 8,
		// breakTime: editedInv.todayBreakTimeMin || 0,
		breakTime: "",
		endTimeMinutes: "",
	};

	// if (startWorkModalIsOpen.action === "stop" && liveEntryRowObj) {
	// if the worker is currently working or an entry already exists for today
	if ((isStopWork || isContinuingWork) && liveEntryRowObj) {
		if (
			!liveEntryRowObj ||
			!laborEntryForToday ||
			liveEntryRowObj.id !== laborEntryForToday.id
		) {
			console.error(
				"is stop or resume work and liveEntryRowObj.id !== laborEntryForToday.id"
			);
		}
		// if the liveEntryRowObj was a start work entry, continue work
		// const isContinuingWork = (liveEntryRowObj.id === editedInv.liveEntryId) && startWorkModalIsOpen.action === "start"
		const { startedWorkTodayAt, todayBreakTimeMin } = liveEntryRowObj;
		const startedWorkTodayAtAsDate = new Date(
			parseFloat(startedWorkTodayAt) * 60000
		);
		const minSinceStartedWorkTodayAt = Math.round(
			Date.now() / 60000 - parseFloat(startedWorkTodayAt)
		);

		// let startTimeHour = startedWorkTodayAtAsDate.getHours().toString().length === 1 ? `0${startedWorkTodayAtFullDate.getHours()}` : startedWorkTodayAtFullDate.getHours()
		let startTimeHour = padTime(startedWorkTodayAtAsDate.getHours());

		// const startTimeMinute = startedWorkTodayAtAsDate.getMinutes().toString().length === 1 ?  `0${startedWorkTodayAtFullDate.getMinutes()}` : startedWorkTodayAtFullDate.getMinutes()
		const startTimeMinute = padTime(startedWorkTodayAtAsDate.getMinutes());

		// const totalBreakTimeMin = accumulatedBreakTimeMinutes + parseFloat(liveEntryRowObj.todayBreakTimeMin)

		defaultFormData = {
			...defaultFormData,
			industry: liveEntryRowObj.values.find((td) => td.for === "item").val,
			rate: liveEntryRowObj.values.find((td) => td.for === "cost").val,
			startTime: `${startTimeHour}:${startTimeMinute}`,
		};

		if (isStopWork) {
			defaultFormData = {
				// industry: liveEntryRowObj.values.find(td => td.for === "item").val,
				// rate: liveEntryRowObj.values.find(td => td.for === "cost").val,
				// startTime: `${startTimeHour}:${startTimeMinute}`,
				// hoursPlanned: hoursSinceFirstStartWork.toFixed(2),
				...defaultFormData,
				hoursPlanned: (
					(minSinceStartedWorkTodayAt - parseFloat(todayBreakTimeMin || 0)) /
					60
				).toFixed(2),
				// break time is total break time today
				breakTime: todayBreakTimeMin || "",
				expectedEndTime: "",
			};
		} else {
			// calculate the hours between the last start and stop work for today
			// if the hours worked are greater than the previously indicated hours planned
			// guess that the hours planned are one more hour than already worked

			// const hoursSinceFirstStartWork = ((Date.now() / 60000) - parseFloat(editedInv.startedWorkTodayAt)) / 60
			// const hoursSinceStoppedWork = ((Date.now() / 60000) - parseFloat(editedInv.endWorkTodayAt)) / 60
			// const hoursWorkedToday = hoursSinceFirstStartWork - hoursSinceStoppedWork

			// const prevHoursPlanned = liveEntryRowObj.values.find(td => td.for === "qty").val
			// start time is Date.now() unless user changes it
			defaultFormData = {
				...defaultFormData,
				// hoursPlanned: (parseFloat(prevHoursPlanned) < hoursWorkedToday ? hoursWorkedToday + 1 : parseFloat(prevHoursPlanned)).toFixed(2) ,
				// resumeTime: `${resultHours}:${resultMinutes}`,
				resumeTime: nowTime,
				hoursPlanned: liveEntryRowObj.originalHoursPlanned,
				// break time will be calculated on useEffect
			};
		}
	}

	const [formData, setFormData] = useState({
		...defaultFormData,
	});

	const onClickOutside = () => {
		setStartWorkModalIsOpen((startWorkModalIsOpen) => ({
			...startWorkModalIsOpen,
			open: false,
		}));
	};

	const handleChange = (nameOrId, value) => {
		if (nameOrId === "industry") {
			// if creating invoice for someone else cant use userObject.industries
			const userIndustry = userObject.industries.find(
				(ind) => ind.name === value
			);
			// if (value) {
			setFormData((formData) => ({
				...formData,
				[nameOrId]: value,
				rate: userIndustry ? userIndustry.rate : "",
			}));
			// getDiscount(userIndustry.rate)
			// }
			// } else if (nameOrId === "expectedEndTime") {
			// 	setFormData(formData => ({...formData, [nameOrId]: value, hoursPlanned: calculateHours(formData.startTime, value, formData.breakTime)}))
		} else {
			setFormData((formData) => ({ ...formData, [nameOrId]: value }));
		}
	};

	const getMinutesFromTime = (timeAMPM) => {
		const timeAsArray = timeAMPM.split(":");
		const today = new Date(getLocalISODate(true));
		today.setHours(parseFloat(timeAsArray[0]), parseFloat(timeAsArray[1]), 0); // local time

		return Math.round(today.getTime() / 60000);
	};

	const handleStartOrStopWork = (e, fd) => {
		e.preventDefault();
		const startTimeMinutes = Math.floor(getMinutesFromTime(fd.startTime));
		const resumeTimeMinutes = Math.floor(getMinutesFromTime(fd.resumeTime));
		// let endTimeMinutes = getMinutesFromTime(fd.expectedEndTime);
		// endTimeMinutes could be tomorrow
		let endTimeMinutes = Math.ceil(parseFloat(fd.endTimeMinutes));
		const newTotal = Math.round(parseFloat(fd.rate) * parseFloat(fd.hoursPlanned) * 100) / 100

		const newValues = [
			{ for: "date", val: currentDateISO },
			{ for: "item", val: fd.industry },
			{ for: "qty", val: fd.hoursPlanned },
			{ for: "cost", val: fd.rate },
			{
				for: "total",
				val: newTotal,
				noEdit: false, // total isnt editable but if noEdit = true getNewEntriesOnTdChange wont update the total
			},
		];
		// set easy access values
		let valuesAsProperties = {}
		newValues.forEach(obj => valuesAsProperties[obj.for] = obj.val)

		const getShiftEntries = (ent) => {
			// handle live entries
			let newShiftEntries;
			if (isStopWork) {
				// find the first object in shiftEntries that doesnt have a stop work time yet
				if (!ent.shiftEntries) {
					// this should not run ...
					// newShiftEntries = [{start: startTimeMinutes, stop: endTimeMinutes, break: parseFloat(fd.breakTime || 0)}]
					newShiftEntries = [
						{
							start: startTimeMinutes,
							stop: endTimeMinutes,
							minSinceLastStop: 0,
						},
					];
				} else {
					// update only end time minutes
					newShiftEntries = [...ent.shiftEntries];
					const newShiftEntriesLastShift = ent.shiftEntries.sort((a, b) => parseInt(b.start) - parseInt(a.start))[0]

					newShiftEntriesLastShift.stop = endTimeMinutes;
				}
			} else {
				// if isContinuingWork
				newShiftEntries = [
					{
						start: resumeTimeMinutes,
						minSinceLastStop: minSinceLastStopWork, // maybe change this to a calculation of this shifts resume time - last shift entrys stop time
						stop: endTimeMinutes, // can/will change if user presses stop work, otherwise is default stop time
					},
					...ent.shiftEntries,
				];
			}
			return newShiftEntries;
		};

		let newLiveEntryId = "";
		let firebaseNewLaborEntryRef = "";
		let newLaborEntries = [];

		// and we need to add data to this row
		if (isContinuingWork || isStopWork) {
			if (!liveEntryRowObj) {
				console.error(
					"no liveEntryRowObj and isContinuingWork or isStopWork is true"
				);
			}
			newLiveEntryId = liveEntryRowObj.id;

			// find the entry and add new values
			editedInv.laborEntries.forEach((ent) => {
				if (ent.id === newLiveEntryId) {
					newLaborEntries.push({
						...ent,
						...valuesAsProperties,
						values: newValues,
						shiftEntries: getShiftEntries(ent),
						endWorkTodayAt: endTimeMinutes,
						// ...isStopWork ? {todayBreakTimeMin: fd.breakTime} : {},
						// stopping work could just be for a break so shouldnt change the original hours planned
						// but if resuming work, need to be able to reset the hours planned because this is when the flashing 'is working' indicator will stop
						// in case the worker forgets to press stop work
						hoursPlanned: parseFloat(fd.hoursPlanned),
						// overwrite startedWorkTodayAt if isStopWork
						startedWorkTodayAt: startTimeMinutes,
						todayBreakTimeMin: parseFloat(fd.breakTime || 0),
					});
				} else {
					newLaborEntries.push(ent);
				}
			});
		} else {
			// user has no live entries for today so must be starting work
			// which means we add a new entry
			firebaseNewLaborEntryRef = firebase
				.firestore()
				.collection("invoices")
				.doc(invoiceId)
				.collection("laborEntries")
				.doc();

			newLiveEntryId = firebaseNewLaborEntryRef.id;
			
			newLaborEntries = [
				// filter out any default entries
				...editedInv.laborEntries.filter((ent) => !ent.isDefaultEntry),
				{
					...valuesAsProperties,
					id: newLiveEntryId,
					values: newValues,
					shiftEntries: [
						{
							start: startTimeMinutes,
							minSinceLastStop: minSinceLastStopWork,
							stop: endTimeMinutes,
						},
					],
					startedWorkTodayAt: startTimeMinutes, // doesnt change throughout the day unless changedOriginalStartTime
					endWorkTodayAt: endTimeMinutes, // doesnt change throughout the day but can be changed on every start/stop/resume
					todayBreakTimeMin: parseInt(fd.breakTime || 0), // is changed every time start, stop, resume
					hoursPlanned: parseFloat(fd.hoursPlanned),
					// in case worker presses stop and resume work for every break
					originalHoursPlanned: parseFloat(fd.hoursPlanned),
				},
			];
		}

		// change all items with the labor group in pageOrder
		let newPageOrder = editedInv.pageOrder.map((item) => {
			if (item.group === "labor") {
				return {
					...item,
					visible: true,
				};
			} else return item;
		});

		let newInvoiceItems = {
			startedWorkTodayAt: startTimeMinutes,
			endWorkTodayAt: endTimeMinutes,
			todayBreakTimeMin: parseFloat(fd.breakTime || 0), // in db as number
			liveEntryId: newLiveEntryId,
			rate: parseFloat(fd.rate),
			industry: fd.industry,
			pageOrder: newPageOrder,
		};

		const newEditedInv = {
			// ...editedInv,
			...newInvoiceItems,
			laborEntries: newLaborEntries,
		};
		handleSetEditedInv({
			changes: newEditedInv,
			// mergeIntoHistory: true,
			caller: "StartWorkModal - handleStartOrStopWork",
		});

		handleSave({ newEditedInv });
		onClickOutside();
	};

	useEffect(() => {
		const calculateExpectedEndTime = (startTime, hoursPlanned, breakTime) => {
			const startTimeMin = getMinutesFromTime(startTime);
			const minPlanned = hoursPlanned * 60;
			const newEndTimeMin = startTimeMin + minPlanned + parseInt(breakTime || 0);

			// could result in a date tomorrow
			// const endDateAndTime = getLocalISODate(true, (newEndTimeMin * 60000))

			// const indexOfT = endDateAndTime.search("T")
			// const resultHours = endDateAndTime.slice(indexOfT + 1, indexOfT + 3)
			// const resultMinutes = endDateAndTime.slice(indexOfT + 4, indexOfT + 6)

			// const newEndTime = startTime
			// 	? extractTimeFromDate(newEndTimeMin * 60000)
			// 	: "00:00";

			return (startTime && newEndTimeMin) ? newEndTimeMin : 0;
		};

		const newExpectedEndTime = calculateExpectedEndTime(
			formData.startTime,
			formData.hoursPlanned,
			formData.breakTime
		);
		if (newExpectedEndTime !== formData.expectedEndTime) {
			setFormData((formData) => ({
				...formData,
				endTimeMinutes: newExpectedEndTime,
			}));
		}
		// eslint-disable-next-line
	}, [formData.startTime, formData.hoursPlanned, formData.breakTime]);

	// any time resume time changes need to update break time
	useEffect(() => {
		// get new total break time from previously indicated break time plus time bertween shifts
		if (liveEntryRowObj && isContinuingWork) {
			// const lastShiftEntry =
			// 	liveEntryRowObj.shiftEntries[liveEntryRowObj.shiftEntries.length - 1];
			const resumeTimeMinutes = getMinutesFromTime(formData.resumeTime);
			const timeBetweenShifts =
				Math.round(resumeTimeMinutes - parseInt(lastShiftEntry.stop));
			// minSinceLastStopWork = resumeTimeMinutes
			const newBreakTime =
				timeBetweenShifts + parseInt(liveEntryRowObj.todayBreakTimeMin || 0);
			if (newBreakTime !== formData.breakTime) {
				setFormData((formData) => ({
					...formData,
					breakTime: newBreakTime,
				}));
			}
		}
		// eslint-disable-next-line
	}, [formData.resumeTime, isContinuingWork, liveEntryRowObj, lastShiftEntry]);

	return (
		<Modal custom={{ absolute: true }} onClickOutside={onClickOutside}>
			<div className="heading">
				{isStopWork
					? "Stop Work"
					: isContinuingWork
					? "Resume work"
					: "Start Work"}
			</div>
			<Form
				inputs={[
					{
						label: "",
						properties: {
							type: "hidden",
						},
						afterInput: [
							<React.Fragment key="min-since-last-stop">
								{isContinuingWork && (
									<React.Fragment>
										<div>
											You stoped work{" "}
											<strong>{minSinceLastStopWork} minutes</strong> ago at{" "}
											<strong>
												{convertToAMPM(
													extractTimeFromDate(
														Date.now() - minSinceLastStopWork * 60000
													)
												)}
											</strong>
										</div>
										<div className="small-line-break" />
									</React.Fragment>
								)}
							</React.Fragment>,
						],
					},
					{
						custom: true,
						label: "Work type",
						// visible: startWorkModalIsOpen.action === "start" ? true : false,
						// visible: !isStopWork && !isContinuingWork,
						properties: {
							// dont alalow industry change if isContinuingWork or stop work
							type: isStopWork || isContinuingWork ? "hidden" : "dropdown",
							name: "industry",
							options: userObject.industries.length
								? [
										{ name: "", rate: "", displayName: "N/A" },
										...userObject.industries,
								  ]
								: [{ name: "unspecified", rate: "" }],
							optionVal: "name",
							value: formData.industry,
							onChange: handleChange,
						},
					},
					{
						label: "Rate",
						visible: !isStopWork && !isContinuingWork, // dont alalow rate change if isContinuingWork or stop work
						onChange: handleChange,
						properties: {
							type: "number",
							id: "rate",
							value: formData.rate,
						},
					},
					{
						label: isStopWork ? "Hours Worked" : "Total hours planned today",
						onChange: handleChange,
						properties: {
							type: "number",
							id: "hoursPlanned",
							value: formData.hoursPlanned,
							max: 24,
						},
					},
					{
						label: isStopWork
							? "Worked From"
							: isContinuingWork
							? "Started today at"
							: "Start Time",
						onChange: handleChange,
						properties: {
							type: "time",
							id: "startTime",
							value: formData.startTime,
						},
					},
					{
						label: "Resuming work at",
						onChange: handleChange,
						visible: isContinuingWork,
						properties: {
							type: "time",
							id: "resumeTime",
							value: formData.resumeTime,
						},
						beforeInput: [
							<div className="section-divider" key="before-resume-work" />,
						],
						afterInput: [
							<div style={{ fontSize: "11px" }} key="noonOrMidnight">
								{formData.startTime.includes("12:00")
									? "-- Noon --"
									: formData.startTime.includes("00:00")
									? "-- Midnight --"
									: ""}
							</div>,
						],
					},
					{
						// label: isStopWork ? "Total break minutes today" : isContinuingWork ? "Break time for this shift" : "Estimated total break time minutes",
						label:
							isStopWork || isContinuingWork
								? "Total break minutes today"
								: "Estimated total break minutes",

						onChange: handleChange,
						// visible: isStopWork || isContinuingWork,
						properties: {
							type: "number",
							id: "breakTime",
							value: formData.breakTime || "",
						},
						afterInput: [
							<React.Fragment key="afterBreakTime">
								<div className="small-line-break" />
								<div>{`${
									isStopWork ? "Stopping work at" : "Planned end time:"
								} ${convertToAMPM(
										extractTimeFromDate(formData.endTimeMinutes * 60000) || "00:00"
									)}`}</div>
								<div className="small-line-break" />
								<div className="small-line-break" />
							</React.Fragment>,
						],
					},
				]}
				submitName={
					isStopWork ? "Save" : isContinuingWork ? "Resume" : "Start!"
				}
				onSubmit={(e) => {
					return handleStartOrStopWork(e, formData);
				}}
			></Form>
		</Modal>
	);
};

export default StartWorkModal;
