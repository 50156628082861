import styled from 'styled-components'

export const ControlsNavStyle = styled.div`
	.nav-container {
		width: 100%;
		display: flex;
	  align-items: center;
		background-color: transparent;
		${({isLessThan700px}) => isLessThan700px === false ? `justify-content: left` : `justify-content: space-evenly`};
	}

	.controls-links {
	  padding: 10px 0 5px 0;
	  min-height: 16px;
	  font-size: 14px;
	  text-align: center;
	}
`