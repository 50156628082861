import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import geofire from 'geofire'
import styled from "styled-components";

import Form from "../Forms/Form";
import AutoCompletePlace from "../GoogleLocation/AutoCompletePlace";

import { handleSearch, changeFilterVisibility } from "./utils";
import { industries, capitalizeFirstLetter } from "../../utils/appUtils";
import { UserContext } from "../../contexts/UserContext";

// import { formulateSearchKeywords, getSearchRelevanceScore } from '../../utils/appUtils'
import firebase from "../../firebase/index";

// const geofire = require('geofire-common');

const SearchFormStyle = styled.div``;

const useQuery = () => new URLSearchParams(useLocation().search);

// rendered by searchResults
const SearchForm = ({
	setErrorObj,
	isFetching,
	isSearching,
	setIsSearching,
	searchResultsArray,
	setSearchResultsArray,
	searchData,
	setSearchData,
	canSearchAgain,
	setCanSearchAgain,
}) => {
	const currentUser = firebase.auth().currentUser;
	let query = useQuery();
	const history = useHistory();
	const searchTextInput = useRef(null);

	let searchTextInputClientRect;
	if (searchTextInput.current) {
		searchTextInputClientRect = searchTextInput.current.getBoundingClientRect();
	}

	const { usersInvoices, usersProjects } = useContext(UserContext);
	const formData = searchData;
	const setFormData = setSearchData;
	// const [placeDetails, setPlaceDetails] = useState([])
	const [industriesFilter, setIndustriesFilter] = useState(
		industries.slice(0, 6)
	);
	// const [autocomplete, setAutocomplete] = useState(null)

	const mainSearchCategoryOptions = [
		{ name: "Contractors", id: "contractors" },
		{ name: "Customers", id: "users" },
		{ name: "Documents", id: "documents" },
	];

	const handleIndustryDatalist = (
		newFormData,
		setIndustriesFilter,
		industries
	) => {
		// set the suggestions
		const userInput = newFormData.text
			? newFormData.text.toLowerCase().trim()
			: "";
		if (!newFormData.text) {
			setIndustriesFilter([]);
			return;
		}

		if (formData.mainSearchCategory === "contractors") {
			let newIndustriesFilter = [];
			if (!userInput) {
				newIndustriesFilter = [...industries];
			} else {
				[...industries].reverse().forEach((obj) => {
					const title = obj.title.toLowerCase();
					const name = obj.name.toLowerCase();
					// direct hits
					if (name.startsWith(userInput) || title.startsWith(userInput)) {
						newIndustriesFilter = [obj, ...newIndustriesFilter];

						// indirect hits
					} else if (name.includes(userInput) || title.includes(userInput)) {
						newIndustriesFilter = [...newIndustriesFilter, obj];
					}
				});
			}

			if (
				newIndustriesFilter.length === 1 &&
				(userInput === newIndustriesFilter[0].name.toLowerCase() ||
					userInput === newIndustriesFilter[0].title.toLowerCase())
			) {
				setIndustriesFilter([]);
			} else {
				setIndustriesFilter(newIndustriesFilter.slice(0, 6));
			}
		}
	};

	useEffect(() => {
		if (formData && setIndustriesFilter) {
			handleIndustryDatalist(formData, setIndustriesFilter, industries);
		}
		// shouldnt include handleIndustryDatalist because its a pure function
		// eslint-disable-next-line
	}, [formData, setIndustriesFilter]);


	const handleChange = useCallback((nameOrId, val) => {
		setCanSearchAgain(true);
		let newFormData = {};

		if (nameOrId === "mainSearchCategory") {
			newFormData = changeFilterVisibility(formData, val);
		}

		if (nameOrId === "location" && !val && searchResultsArray && searchResultsArray.length) {
			let newSearchResults = []
			searchResultsArray.forEach(res => {
				newSearchResults.push({
					...res,
					visibleInSearchResults: true
				})
			})

			setSearchResultsArray(newSearchResults)	
		}

		newFormData = {
			...formData,
			...newFormData,
			[nameOrId]: val,
		};

		setFormData((formData) => ({
			...formData,
			...newFormData,
		}));

		// handleIndustryDatalist(newFormData, setIndustriesFilter)
	}, [setCanSearchAgain, formData, setFormData, searchResultsArray, setSearchResultsArray])

	const callSearch = async ({ e, formData, overrideCanSearch }) => {
		// get all contractors based on conditions
		e && e.preventDefault();
		return handleSearch({
			currentUser,
			formData,
			usersInvoices,
			usersProjects,
			setIsSearching,
			isFetching,
			canSearchAgain,
			setCanSearchAgain,
			setSearchResultsArray,
			setErrorObj,
			overrideCanSearch,
		});
	};

	useEffect(() => {
		setCanSearchAgain(true);
		// could cause infinite re-renders
		// eslint-disable-next-line
	}, [history.location]);

	useEffect(() => {
		let text = query.get("text");
		// let filter = query.get("filter")
		let category = query.get("category");
		let generalContractors = query.get("generalContractors");
		let subContractors = query.get("subContractors");
		let myCustomers = query.get("myCustomers")
		let projects = query.get("projects");
		// let bills = query.get("bills")
		let invoices = query.get("invoices");
		let posts = query.get("posts");

		let location = query.get("location");

		// reset if category invalid
		if (
			category &&
			!mainSearchCategoryOptions.find((cat) => cat.id === category)
		) {
			category = "";
		}

		// note: cant do bills or myCustomers until usersInvoices retrieved

		if (
			text ||
			category ||
			generalContractors ||
			subContractors ||
			/*myCustomers ||*/ projects ||
			/*bills ||*/ invoices ||
			location
		) {
			let newFormData = {
				...formData,
				...(text ? { text } : {}),
				...(category ? { mainSearchCategory: category } : {}),
				searchFilters: {
					...formData.searchFilters,
					...(generalContractors
						? {
								generalContractors: {
									...formData.searchFilters.generalContractors,
									checked: generalContractors === "true" ? true : false,
								},
						  }
						: {}),
					...(subContractors
						? {
								subContractors: {
									...formData.searchFilters.subContractors,
									checked: subContractors === "true" ? true : false,
								},
						  }
						: {}),
					...myCustomers ? {myCustomers: {
						...formData.searchFilters.myCustomers, checked: myCustomers === "true" ? true : false
					}} : {},
					...(projects
						? {
								projects: {
									...formData.searchFilters.projects,
									checked: projects === "true" ? true : false,
								},
						  }
						: {}),
					// ...bills ? {bills: {
					// 	...formData.searchFilters[bills], checked: true
					// }} : {},
					// must not include bills until usersInvoices are retrieved
					bills: { ...formData.searchFilters.bills, checked: false },
					...(invoices
						? {
								invoices: {
									...formData.searchFilters.invoices,
									checked: invoices === "true" ? true : false,
								},
						  }
						: {}),
					...(posts
						? {
								posts: {
									...formData.searchFilters.posts,
									checked: posts === "true" ? true : false,
								},
						  }
						: {}),
				},
				...(location ? { location } : {}),
			};

			if (category) {
				// need to change the default catefory
				newFormData = changeFilterVisibility(newFormData, category);
			}

			// handleIndustryDatalist(newFormData, setIndustriesFilter)

			if (text) {
				callSearch({ formData: newFormData, overrideCanSearch: true });
			}

			setFormData(newFormData);
			history.replace(history.location.pathname);
		}

		// eslint-disable-next-line
	}, [/*isSearching,*/ formData, currentUser, history.location]);

	const searchingUsers = formData.mainSearchCategory === "users";

	const searchingContractors = formData.mainSearchCategory === "contractors";
	// const searchingSubContractors = searchingContractors && formData.searchFilters.subContractors.checked
	const searchingDocuments = formData.mainSearchCategory === "documents";

	const inputs = [
		{
			custom: true,
			label: "I'm Looking for",
			properties: {
				type: "dropdown",
				name: "mainSearchCategory",
				options: mainSearchCategoryOptions,
				optionVal: "id",
				value: formData.mainSearchCategory,
				onChange: handleChange,
			},
		},
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "text",
				onChange: () => null,
			},
			beforeInput: [
				<div key="industry" className="search-box">
					<div className="section-divider" />
					<label>
						{searchingUsers || searchingDocuments
							? "Key words: "
							: "Industry: "}
						<div
							style={{
								position: "relative",
								display: "inline-flex",
								flexDirection: "column",
							}}
						>
							<input
								className="pac-target-input"
								required={true}
								type="search"
								placeholder={searchingContractors ? "eg: Plumbing" : ""}
								name="text"
								value={formData.text}
								autoComplete="off"
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								ref={searchTextInput}
								// pattern="[a-zA-Z0-9 ]+"  cant use pattern here because we dont have onSubmit its just a button
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										callSearch({ e, formData });
									}
								}}
							/>
							{industriesFilter.length && searchingContractors ? (
								<div
									className="pac-container pac-logo"
									style={{
										width: searchTextInputClientRect
											? searchTextInputClientRect.width
											: "inherit",
										position: "absolute",
										// left: "120px",
										top: searchTextInputClientRect
											? searchTextInputClientRect.height
											: "33px",
										display: formData.text ? "block" : "none",
									}}
								>
									{industriesFilter.map((obj) => {
										return (
											<div
												key={obj.name}
												className="pac-item"
												onClick={() =>
													handleChange("text", capitalizeFirstLetter(obj.name))
												}
											>
												<span className="pac-item-query">
													{capitalizeFirstLetter(obj.name)}
												</span>
												<span>{capitalizeFirstLetter(obj.title)}</span>
											</div>
										);
									})}
								</div>
							) : null}
						</div>
					</label>
					<div className="section-divider" />
				</div>,
			],
		},
		{
			visible: !searchingDocuments && formData.text ? true : false,
			properties: {
				type: "hidden",
				id: "autocomplete-location",
				onChange: () => null,
			},
			beforeInput: [
				<div key="autocomplete-location" className="search-box">
					<div className="section-divider" />
					<label htmlFor="location">{"Location: "}</label>
					<AutoCompletePlace
						required={true}
						setPlaceDetails={null}
						handleChange={handleChange}
						inputProperties={{
							type: "search",
							name: "location",
							id: "location",
							placeholder: "Start typing your city",
							// defaultValue: formData.location,
							value: formData.location,
							// value: formData.location,
							onKeyPress: (e) => {
								if (e.key === "Enter") {
									callSearch({ e, formData });
								}
								// handleChange(e.target.name, e.target.value)
							},
							// onChange: (e) => handleChange(e.target.name, e.target.value)
						}}
						options={{
							componentRestrictions: { country: ["ca"] },
							fields: ["geometry"],
							types: ["(cities)"],
						}}
					/>
					<div className="location-tags">
						<span className="pac-icon pac-icon-marker"></span>
						<span className="location-name">
							{formData.location ? formData.location : "Anywhere"}
							{formData.location && (
								<svg
									onClick={() => {
										handleChange("location", "");
									}}
									viewBox="0 0 32 32"
									fill="none"
									stroke="currentcolor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="3"
								>
									<path d="M2 30 L30 2 M30 30 L2 2" />
								</svg>
							)}
						</span>
					</div>
				</div>,
			],
		},
		{
			properties: {
				type: "hidden",
				id: "search-button",
				onChange: () => null,
			},
			afterInput: [
				<div key="search-button">
					<div className="section-divider" />
					<div className="section-divider" />

					<button
						className="button-appearance search-button"
						onClick={(e) => callSearch({ e, formData })}
					>
						Search
						<svg
							id="svg-search"
							viewBox="0 0 32 32"
							fill="none"
							stroke="currentcolor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						>
							<circle cx="14" cy="14" r="11" />
							<path d="M23 23 L28 28" />
						</svg>
					</button>
				</div>,
			],
		},
	];

	return (
		<SearchFormStyle>
			<Form heading="" submitName="" inputs={inputs}></Form>
		</SearchFormStyle>
	);
};

export default SearchForm;
