// update 2021 03-10
import React, { useState, useContext, useCallback, useEffect } from "react";
import firebase from "../firebase/index";

import { UserContext } from "./UserContext";

import {
	getDefaultEntries,
	updateInvoice,
	subCollectionsList,
	getInvoiceTotals,
	disabledBillToFields,
	matchesDefaultEntry,
	cloneInvoiceSubCollections,
	handleUpdateReceiptMetadata,
} from "../components/Invoice/invoiceUtils";
import {
	getNum,
	formulateSearchKeywords,
	removeDuplicates,
	deepObjectCompareDiffers,
	handleSortItemOrArray,
} from "../utils/appUtils";
import { getLocalISODate } from "../utils/dateUtils";

const EditInvoiceContext = React.createContext();

// rendered by App.js
// errors and successText handled
const EditInvoiceContextProvider = ({ children }) => {
	const user = firebase.auth().currentUser;
	const {
		usersInvoices,
		setUsersInvoices,
		successText,
		setSuccessText,
		handleSetSuccessText,
		errorText,
		setErrorText,
		errorObj,
		setErrorObj,
		userObject,
		foundAuthState,
	} = useContext(UserContext);

	const [invoiceTotals, setInvoiceTotals] = useState({
		materialEntries: {
			total: 0,
			qty: 0,
		},
		laborEntries: {
			total: 0,
			qty: 0,
		},
		customEntries: {
			total: 0,
			qty: 0,
		},
		paymentEntries: {
			total: 0,
			qty: 0,
		},
	});

	const [inv, setInv] = useState({
		billTo: {
			firstname: "",
			lastname: "",
			address: {
				city: "",
				country: "",
				line1: "",
				line2: "",
				postal_code: "",
				state: "",
			},
			uid: "",
		},
	});

	const invoiceSkeleton = {
		billTo: {
			firstname: "",
			lastname: "",
			address: {
				city: "",
				country: "",
				line1: "",
				line2: "",
				postal_code: "",
				state: "",
			},
			uid: "",
			showAddress: false,		
		},
		contractor: {
			companyEmail: "",
			companyName: "",
			address: {
				city: "",
				country: "",
				line1: "",
				line2: "",
				postal_code: "",
				state: "",
			},
			id: "",
			logoUrl: "",
			phone: "",
			username: "",
		},
		creatorUid: "",
		accessors: [],
		editors: [],
		followers: [],
		dateOfLastPayment: "",
		dateOfNextPayment: "",
		description: "",
		endWorkTodayAt: "",
		forProject: "",
		industry: "",
		invNumber: "",
		liveEntryId: "",
		pageOrder: [], // for the purpose of summary entries pageOrder[0] must alwats be where to find the material entries title as with the labor entries at pos 4
		rate: "",
		showDiscount: "",
		showTotalCost: true,
		showTotalOwing: true,
		startedWorkTodayAt: "",
		todayBreakTimeMin: "",
		laborEntries: [],
		materialEntries: [],
		visibility: "public",
		// taxEntries: [],
		summaryEntries: [
			{
				id: "materialTotals",
				visible: true,
				values: [
					{
						for: "item",
						isFormula: true,
						val: "= editedInv.pageOrder.0.val",
						noEdit: true,
					},
					{
						for: "qty",
						val: "= invoiceTotals.materialEntries.qty",
						noEdit: true,
					},
					// {for: "discount", val: "", noEdit: true},
					{
						for: "total",
						val: "= invoiceTotals.materialEntries.total",
						noEdit: true,
					},
				],
			},
			{
				id: "laborTotals",
				visible: true,
				values: [
					{
						for: "item",
						isFormula: true,
						val: "= editedInv.pageOrder.4.val",
						noEdit: true,
					},
					{ for: "qty", val: "= invoiceTotals.laborEntries.qty", noEdit: true },
					// {for: "discount", val: `${parseFloat(laborDiscount).toFixed(2)}%`, noEdit: true},
					{
						for: "total",
						val: "= invoiceTotals.laborEntries.total",
						noEdit: true,
					},
				],
			},
		],
		paymentEntries: [],
		payments: undefined, // needs to start out undefined because of SpecificInvoice useEffect that waits until editedInv loads to add payment listener
		receipts: [],
		topSectionPageOrder: [],
		autoSaveEnabled: true,
	};

	const [editedInv, setEditedInv] = useState({
		...invoiceSkeleton,
	});

	const [isEditing, setIsEditing] = useState(false);
	const [userIsOwner, setUserIsOwner] = useState(false);
	const [userCanEdit, setUserCanEdit] = useState(false);
	const [userIsFollower, setUserIsFollower] = useState(false);
	const [userCanView, setUserCanView] = useState(null);
	const [followersUserObjs, setFollowersUserObjs] = useState([]);
	const [workerIsWorking, setWorkerIsWorking] = useState(false);
	const [changeBillToModalOpen, setChangeBillToModalOpen] = useState(false);
	const [addSummaryEntryModal, setAddSummaryEntryModal] = useState({
		open: false,
		data: {},
	});
	const [billToBills, setBillToBills] = useState([]);
	// const [billSnapshotListeners, setBillSnapshotListeners] = useState([]);
	const [liveEntryTotal, setLiveEntryTotal] = useState({
		qty: 0,
		total: 0,
	});
	const [activeRow, setActiveRow] = useState({ tableName: "", i: "" });
	const [addPaymentModal, setAddPaymentModal] = useState({ open: false });
	const [verifiedPaymentModalData, setVerifiedPaymentModalData] = useState({
		open: false,
	});
	const [receiptViewModalData, setReceiptViewModalData] = useState({
		open: false,
		tableName: "", 
		rowId: "", 
		tdFor: ""
	});
	const [editedInvVersions, setEditedInvVersions] = useState([
		{
			id: "original",
			current: false,
			changes: { ...editedInv },
		},
	]);

	const [lastSavedVersion, setLastSavedVersion] = useState("original")

	let userIsBillTo = false;
	if (user && editedInv.billTo && editedInv.billTo.uid === user.uid) {
		userIsBillTo = true;
	}

	const undoChange = () => {
		// find the current version
		const currentVersionIndex = editedInvVersions.findIndex((v) => v.current);
		// prevent undo when current version would be version 0 if changed
		if (currentVersionIndex < 1) {
			handleSetSuccessText("Max undo reached", 5000);
			return;
		}
		// change the invoice
		// note: do not use handleSetEditedInv because it resets possibility of redoing
		setEditedInv((editedInv) => {
			const newEditedInv = {
				...editedInv,
				...editedInvVersions[currentVersionIndex - 1].changes,
				version: editedInvVersions[currentVersionIndex - 1].id,
			};
			return newEditedInv;
		});
		// change the active version
		let newEditedInvVersions = [];
		editedInvVersions.forEach((v) => {
			newEditedInvVersions.push({ ...v, current: false });
		});
		newEditedInvVersions[currentVersionIndex - 1].current = true;
		setEditedInvVersions([...newEditedInvVersions]);
	};

	const redoChange = () => {
		// find the current version
		const currentVersionIndex = editedInvVersions.findIndex((v) => v.current);
		// prevent undo when current version would be version 0 if changed
		if (currentVersionIndex + 1 === editedInvVersions.length) {
			handleSetSuccessText("Max redo reached", 5000);
			return;
		}
		// change the invoice
		// note: do not use handleSetEditedInv in redo change because it resets possibility of redoing
		setEditedInv((editedInv) => ({
			...editedInv,
			...editedInvVersions[currentVersionIndex + 1].changes,
			version: editedInvVersions[currentVersionIndex + 1].id,
		}));
		// change the active version
		let newEditedInvVersions = [];
		editedInvVersions.forEach((v) => {
			newEditedInvVersions.push({ ...v, current: false });
		});
		newEditedInvVersions[currentVersionIndex + 1].current = true;
		// set the versions
		setEditedInvVersions([...newEditedInvVersions]);
	};

	const maxChangesStored = 50;

	// includes liveEntry totals
	const invoiceTotal =
		parseFloat(invoiceTotals.materialEntries.total) +
		parseFloat(invoiceTotals.laborEntries.total) +
		parseFloat(invoiceTotals.customEntries.total);
	const invoiceTotalStr = getNum(invoiceTotal, 2);

	const invoiceOwed =
		invoiceTotal - parseFloat(invoiceTotals.paymentEntries.total);
	const invoiceOwedStr = getNum(invoiceOwed, 2);

	const handleSetEditedInv = useCallback(
		({
			changes,
			overWriteAllVersions,
			makeDefault,
			mergeIntoHistory,
			caller,
		}) => {
			if (makeDefault) {
				setEditedInv(invoiceSkeleton);
				return invoiceSkeleton;
			}

			// decide whether to set edited invoice based on versions
			// if newEditedInv.versionId cannot be found in editedInvVersions
			const checkInvoiceHasChanged = () => {
				// use deepObjectCompareDiffers fn to decide whether to setEditedInv
				for (let key in changes) {
					const prevVersion = editedInv[key];
					const thisVersion = changes[key];
					const invoiceHasChanged = deepObjectCompareDiffers({
						a: prevVersion,
						b: thisVersion,
					});
					if (invoiceHasChanged) {
						return true;
					}
				}

				return false;
			};

			const getnewPrevVersions = (editedInvVersions, stateBeforeChanges) => {
				// dont use when undoing or redoing
				// prevent an undo, > change > redo scenario by removing all version objects after the current version
				// and change the current versions v.current === true to false
				// to allow the newly added version to be the only version with current true
				let newVersions = [];
				// add new versions into list until current version reached

				for (let i = 0; i < editedInvVersions.length; i++) {
					const v = editedInvVersions[i];
					if (v.current) {
						newVersions.push({
							...v,
							current: false,
							// add the current editedInv states significant properties to previous version changes
							changes: { ...v.changes, ...stateBeforeChanges },
						});
						// return without caring about newer versions
						return newVersions;
					} else {
						newVersions.push({ ...v });
					}
				}

				if (newVersions.some && newVersions.some((ver) => ver.current)) {
					console.error(
						"error: possibly about to set 2 current versions! a version in version array before newest version contains current=true"
					);
				}

				// if all the versions have been looped through the last one must be the current version
				return newVersions;
			};

			// only setEditedInv if it has changed
			if (checkInvoiceHasChanged()) {
				let newVersionId = (parseInt(editedInv.version) || 100) + 1;

				// let currentValuesAboutToChange = {}
				let actualChanges = {};
				let stateBeforeChanges = {};

				setEditedInv((editedInv) => {
					// only set the actual changes so that less data is being stored in
					// the history of editedInv state
					for (let key in changes) {
						const changesVal = changes[key] === undefined ? "" : changes[key]
						const editedInvVal = editedInv[key] === undefined ? "" : editedInv[key]

						if (
							deepObjectCompareDiffers({ a: editedInvVal, b: changesVal })
						) {
							actualChanges[key] = JSON.parse(JSON.stringify(changes[key] === undefined ? "" : changesVal)); // cant use spread operator because changes[key] could be string, object or array
							stateBeforeChanges[key] = JSON.parse(JSON.stringify(editedInvVal === undefined ? "" : changesVal));
						}
					}

					let newEditedInv = {
						...editedInv,
						...actualChanges,
						...(!mergeIntoHistory &&
							!overWriteAllVersions && { version: newVersionId }),
					};

					newEditedInv = handleSortItemOrArray(newEditedInv);
					return newEditedInv;
				});

				if (overWriteAllVersions) {
					// actual changes when overwriting are any deviation from the original editedInv state
					// which had nothing for almost all values
					setEditedInvVersions((editedInvVersions) => {
						let allPrevChanges = {};
						editedInvVersions.forEach((v) => {
							// relies on the first chnage being editedInvVersions[0]
							allPrevChanges = { ...allPrevChanges, ...v.changes };
						});
						const newEditedInvVersions = [
							{
								id: parseInt(inv.version) || 100,
								current: true,
								changes: { ...allPrevChanges, ...actualChanges },
							},
						];

						return newEditedInvVersions;
					});
				} else {
					if (mergeIntoHistory) {
						setEditedInvVersions((editedInvVersions) => {
							let newEditedInvVersions = [];

							// if (editedInvVersions.length === 1) {
								editedInvVersions.forEach((obj) => {
									let modifiedChanges = {};
									// check if any of the newly updated properties hold a version of this property
									// set the chcanges into a new object to prevent mutation

									for (let key in obj.changes) {
										if (editedInvVersions.length === 1) {
											if (actualChanges.hasOwnProperty(key)) {
												modifiedChanges[key] = actualChanges[key]; // cant spread actualChanges[key because it could be string object or array
											}
										} else if (parseInt(obj.id) !== (parseInt(inv.version) || 100)) {
											if (actualChanges[key] !== undefined && !actualChanges.hasOwnProperty(key)) {
												// need to spread into array if actualChanges[key] === array
												// or spread into obj if actualChanges[key] === obj
												modifiedChanges[key] = changes[key]; // cant spread actualChanges[key because it could be string object or array

											}
										}
										// else dont change anything
									}
									newEditedInvVersions.push({
										...obj,
										changes: { ...obj.changes, ...modifiedChanges },
									});
								});
							// } else {
							// 	newEditedInvVersions = [
							// 		...getnewPrevVersions(editedInvVersions, stateBeforeChanges),
							// 		{
							// 			id: newVersionId, // can use versionId here because this else code is only run if !mergeIntoHistors and !overwrite..
							// 			current: true,
							// 			changes: actualChanges, // eg: the state of the invoice now
							// 			// changes: {}
							// 		},
							// 	];
							// 	newEditedInvVersions = newEditedInvVersions.slice(
							// 		-1 * maxChangesStored
							// 	);
							// }

							return newEditedInvVersions;
						});
					} else {
						// set the curretn version changes and add a new current version
						setEditedInvVersions((editedInvVersions) => {
							let newEditedInvVersions = [
								...getnewPrevVersions(editedInvVersions, stateBeforeChanges),
								{
									id: newVersionId, // can use versionId here because this else code is only run if !mergeIntoHistors and !overwrite..
									current: true,
									changes: actualChanges, // eg: the state of the invoice now
									// changes: JSON.parse(JSON.stringify(actualChanges)), // eg: the state of the invoice now

									// changes: {}
								},
							];
							newEditedInvVersions = newEditedInvVersions.slice(
								-1 * maxChangesStored
							);
							return [...newEditedInvVersions];
						});
					}
				}
			}
		},
		// eslint-disable-next-line
		[editedInv]
	);

	let handleSetInv = ({ changes }) => {
		setInv((inv) => {
			const sortedInv = handleSortItemOrArray({
				...inv,
				...changes,
			});
			return sortedInv;
		});
	};

	const getMostRecentEntryDate = (fullInvoice) => {
		let allEntryDates = [];
		subCollectionsList.forEach((collectionName) => {
			if (collectionName !== "receipts") {
				const collectionVisible = fullInvoice.pageOrder.find(
					(obj) => obj.for === collectionName
				).visible;
				if (collectionVisible) {
					fullInvoice[collectionName].forEach((ent) => {
						const dateTd = ent.values.find((td) => td.for === "date");
						dateTd && allEntryDates.push(dateTd.val);
					});
				}
			}
		});
		if (allEntryDates.length) {
			allEntryDates = allEntryDates.sort((a, b) => {
				const d1 = new Date(a);
				const d2 = new Date(b);
				return d2 - d1;
			});
			return allEntryDates[0];
		} else {
			return getLocalISODate();
		}
	};

	const addRow = ({
		id,
		date,
		item,
		qty,
		cost,
		total,
		spliceStart,
		spliceDelete,
		tempEntryId,
		tableName,
		changedEditedInv,
		additionalRowData,
	}) => {
		const newEditedInv = changedEditedInv ? changedEditedInv : editedInv;
		// for summaryEntries newRowId is not where the entry will sit in DB it is
		// just a way to get a random id
		let newRowId = firebase
			.firestore()
			.collection("invoices")
			.doc(editedInv.id)
			.collection(tableName)
			.doc().id;
		// id is previous row id...
		const i = newEditedInv[tableName].findIndex((row) => row.id === id);
		let newEntries = [...newEditedInv[tableName]]; // keeps state immutable even though nested values arent cloned because map returns new array ?
		// newEntryId is defined as a new entry by having the id contain "newEntry"

		// copy the values from the row above unless a specified new value is passed into the fn
		const newValues = newEntries[i].values.map((obj, j) => {
			// need to use map to keep state immutable
			if (obj.for === "date") {
				let prevEntryDate = new Date(`${obj.val}T00:00:01Z`);
				let newDate = new Date(prevEntryDate);
				newDate.setDate(prevEntryDate.getDate() + 1);
				const resultDate = date
					? date
					: newDate.toJSON().slice(0, newDate.toJSON().search("T"));
				return { ...obj, val: resultDate };
			}
			if (obj.for === "item") {
				if (tableName === "laborEntries") {
					return { ...obj, val: item ? item : obj.val };
					// remove the noEdit on customEntries summaryEntries
				} else if (tableName === "summaryEntries") {
					return { ...obj, val: item || "", noEdit: false };
				} else {
					return { ...obj, val: item || "" };
				}
			}
			if (obj.for === "qty") {
				if (tableName === "laborEntries") {
					return { ...obj, val: qty ? qty : obj.val };
					// remove the noEdit on customEntries summaryEntries
				} else if (tableName === "summaryEntries") {
					return { ...obj, val: qty || "", noEdit: false };
				} else {
					return { ...obj, val: qty || "" };
				}
			}
			if (obj.for === "cost") {
				if (tableName === "laborEntries") {
					return { ...obj, val: cost ? cost : obj.val };
				} else {
					return { ...obj, val: cost || "" };
				}
			}
			// if (obj.for === "discount") {
			// 	return {...obj, val: discount ? discount : obj.val}
			// }
			if (obj.for === "total") {
				if (total) {
					return { ...obj, val: total };
				} else if (tableName === "laborEntries") {
					return {
						...obj,
						noEdit: false, // clear the noEdit property
						val: qty && cost ? parseFloat(qty) * parseFloat(cost) : obj.val,
					};
				} else {
					return {
						...obj,
						noEdit: false, // clear the noEdit property
						val: qty && cost ? parseFloat(qty) * parseFloat(cost) : 0,
					};
				}
			} else {
				console.log(
					"check EditInvoiceContext add row function obj.for",
					obj.for
				);
				return { ...obj };
			}
		});

		// prevent immutable or verified payment rows from copying those properties
		let newEntry;
		if (newEntries[i].immutable || newEntries[i].verifiedPayment) {
			newEntry = {
				id: newRowId,
				values: newValues,
				...(additionalRowData || {}),
			};
		} else {
			newEntry = {
				...newEntries[i],
				id: newRowId,
				values: newValues,
				...(additionalRowData || {}),
			};
		}
		// remove time tracking properties of previous row 

		delete newEntry.shiftEntries
		delete newEntry.startedWorkTodayAt
		delete newEntry.todayBreakTimeMin
		delete newEntry.originalHoursPlanned
		delete newEntry.hoursPlanned
		delete newEntry.endWorkTodayAt

		let splicedNewEntries = [...newEntries];
		splicedNewEntries.splice(
			spliceStart ? spliceStart : i + 1,
			spliceDelete ? spliceDelete : 0,
			newEntry
		);

		handleSetEditedInv({
			changes: {
				...newEditedInv,
				[tableName]: splicedNewEntries,
			},
			caller: "EditInvoiceContext - addRow",
		});
	};

	const deleteRow = ({ id, showWindowConfirm, tableName }) => {
		if (id === editedInv.liveEntryId) {
			setWorkerIsWorking(false);
		}
		// array.filter does keeps state from being mutated
		let newEntries = editedInv[tableName].filter((row) => row.id !== id);
		let newReceipts = [...editedInv.receipts];

		// handle deleting materials rows with receipts
		const receiptsThisRow = editedInv.receipts.filter(
			(rec) => rec.rowId === id
		);
		if (receiptsThisRow.length) {
			// filter out any receipts that are in this row from invoices receipts
			newReceipts = newReceipts.filter(
				(rec) => !receiptsThisRow.find((doc) => doc.id === rec.id)
			);
			// changed to remove receipt when handleSave
			// setBillSnapshotListeners((billSnapshotListeners) => {
			// 	let newBillSnapshotListeners = [];
			// 	if (billSnapshotListeners.length) {
			// 		billSnapshotListeners.forEach((obj) => {
			// 			if (receiptsThisRow.find((rec) => rec.id === obj.id)) {
			// 				// terminate listener and dont add it in to newBillSnapshotListeners
			// 				obj.listener();
			// 			} else {
			// 				newBillSnapshotListeners.push(obj);
			// 			}
			// 		});
			// 	}
			// 	return newBillSnapshotListeners;
			// });
		}
		// set row values back to default if this is the last row
		if (!newEntries.length) {
			handleSetSuccessText(
				"You have no more entries in this table, you can hide the table completely in the invoice's Layout (located below the edit button in the toolbar)",
				10000
			);
			const defaultEntries = getDefaultEntries(
				tableName,
				editedInv.industry,
				editedInv.rate,
				editedInv.id
			);

			// receipts are removed from storage in handleSave
			return handleSetEditedInv({
				changes: {
					[tableName]: defaultEntries,
					receipts: newReceipts,
				},
				caller: "EditInvoiceContext - deleteRow no new entries.length",
			});

			// if user deleted the last row in the table, save in DB with empty entries so that in SpecificInvoice.js
			// the defaultEntries will be retrieved since the DB response for this sobcollection is an empty array
		} else {
			return handleSetEditedInv({
				changes: {
					[tableName]: newEntries,
					receipts: newReceipts,
				},
				caller: "EditInvoiceContext - deleteRow",
			});
		}
	};

	const handleUpdateLeviedInvoice = async (
		invoice,
		allProperEntries,
		deletedRows
	) => {
		// will update the levied invoice entries whether the entry was edited or not unless cloneInvoiceSubCollections determines it is a defaultEntry
		// this is a blind update, the updater might not have view permission
		try {
			const firebaseInvoiceRef = firebase
				.firestore()
				.collection("invoices")
				.doc(invoice.leviedInvoice);

			const isUnpaired = invoice.billTo.uid === "";
			const {
				description,
				editors,
				endWorkTodayAt,
				industry,
				liveEntryId,
				mostRecentEntryDate,
				startedWorkTodayAt,
				summaryEntries,
				todayBreakTimeMin,
				id,
				invNumber,
				invShortHand,
				contractor,
			} = invoice;

				// below doesnt work because the array is an array of objects
				// summaryEntries: firebase.firestore.FieldValue.arrayUnion(...customEntries),
			// let customEntries = invoice.summaryEntries.filter(ent => (ent.entryType !== "materialTotals" && ent.entryType !== "laborTotals"))

			// gather all possible updates
			let forwardedBillUpdates = {
				// if updating below remember to update firestore rules too
				description,
				restrictedEditors: firebase.firestore.FieldValue.arrayUnion(
					...removeDuplicates([...editors, editedInv.contractor.id])
				),
				endWorkTodayAt,
				industry,
				liveEntryId,
				mostRecentEntryDate,
				startedWorkTodayAt,
				todayBreakTimeMin,
				summaryEntries,
				// below doesnt work because the array is an array of objects
				// summaryEntries: firebase.firestore.FieldValue.arrayUnion(...customEntries),
				// NOTE:
					// summary entries can have formulas eg: invoiceTotal * 5%
					// this means a summary entry on the paired invoice could have a much different value than
					// the transfered entry on the paired invoice. If were going to transfer summary entries 
					// we should take the current value of the summary entry and not the formula
				pairedInvoiceOptions: {
					[id]: {
						editors: removeDuplicates([
							...editors,
							editedInv.contractor.id,
							editedInv.owner,
						]),
						contractor,
						unpaired: isUnpaired,
						deleted: false,
						invNumber,
						invShortHand,
						lastUpdated: firebase.firestore.Timestamp.now().seconds,
					},
				},
				version: firebase.firestore.FieldValue.increment(1), // this makes version change so that changes show up right away
				...(isUnpaired
					? {
							// cant remove restricted editors because this is a restricted editor updating the forwarded bill and security rules only allow if in restricted editor array ?
							pairedInvoices: firebase.firestore.FieldValue.arrayRemove(id),
					  }
					: {}),
			};

			// disallow updates of some keys
			let doNotUpdateKeys = [];
			if (invoice.leviedInvoiceOptions) {
				if (
					invoice.leviedInvoiceOptions.doNotUpdate &&
					invoice.leviedInvoiceOptions.doNotUpdate.length
				) {
					// the subcontractor could just change leviedInvoiceOptions so this needs to be prevented in firestore rules too
					doNotUpdateKeys = invoice.leviedInvoiceOptions.doNotUpdate;
				}
			}

			doNotUpdateKeys.forEach((key) => {
				delete forwardedBillUpdates[key];
			});
			// update the subcollections with the contractors rates
			let newSubcollectionEntries = {};
			let batch = null;
			let invoiceWithNoSubcollections = { ...invoice };
			let doNotUpdateCollections = [];
			let laborEntriesWithoutCostOrTotal = [];

			// run through all possible sub collections to either update or delete
			// invoice[col] may be undefines if it was from handleSave and wasnt edited
			subCollectionsList.forEach((col) => {
				// remove subcollection from stripped invoice
				delete invoiceWithNoSubcollections[col];
				// this relies on firestore's doc update function to only update changed values
				// in nested objects and to keep values that are not in the updated object
				const entries = allProperEntries[col]; // get all entries even if they havent changed
				const inDoNotUpdate = doNotUpdateKeys.includes(col);
				if (inDoNotUpdate) {
					doNotUpdateCollections.push(col);
				}

				// only update if it is not listed in doNotUpdate and there are entries
				if (!inDoNotUpdate) {
					if (entries && entries.length) {
						newSubcollectionEntries[col] = [...entries];
						// make laborEntriesWithoutCostOrTotal
						if (col === "laborEntries") {
							// copy everything in entry except cost per hour
							laborEntriesWithoutCostOrTotal = entries.map((ent) => {
								let newValues = [];

								ent.values.forEach((obj) => {
									// update everything except for cost and total val columns
									if (obj.for === "cost" || obj.for === "total") {
										// do not update cost or total val, this is done in specificInvoice handleEmptyValues
										newValues.push({
											...obj,
											// must be null so that in levied invoice we can update cost and total
											val: null, // MUST BE NULL OR UNDEFINED!! because we check if null or undefined in specificInvoice on each td
										});
										// dont add in the total section
									} else {
										newValues.push(obj);
									}
								});

								let newEnt = {
									...ent,
									values: newValues,
								};

								newValues.forEach((td) => {
									newEnt[td.for] = td.val;
								});

								// dont update the ent.cost or ent.total properties
								delete newEnt.cost;
								delete newEnt.total;

								return newEnt;
							});
						}
					}
					// handle deleted rows
					if (deletedRows[col] && Object.keys(deletedRows[col])) {
						if (!batch) {
							batch = firebase.firestore().batch();
						}
						deletedRows[col].forEach((ent) => {
							const entryRef = firebaseInvoiceRef.collection(col).doc(ent.id);
							batch.delete(entryRef);
						});
					}
				}
			});

			await firebaseInvoiceRef
				.set(
					{
						...forwardedBillUpdates,
						// must include  a lastUpdatedByDoc so that we can check in firestore rules if the user can update this doc
						lastUpdatedByDoc: id,
					},
					{ merge: true }
				)
				.catch((err) => {
					throw err;
				});

			await cloneInvoiceSubCollections({
				invoice: {
					...invoiceWithNoSubcollections,
					...forwardedBillUpdates,
					...newSubcollectionEntries, // does not include entries that are empty
				},
				newFirebaseDoc: firebaseInvoiceRef,
				type: "forwardBill",
				newLaborEntries: laborEntriesWithoutCostOrTotal,
				doNotUpdateCollections,
			}).catch((err) => {
				throw err;
			});

			// handle deleted entries
			if (batch) {
				await batch.commit();
			}
		} catch (err) {
			// if the levied invoice doesnt exist
			if (err.message.includes("Missing or insufficient permissions")) {
				// will not work on linvo-dev enviroment
				const docExists = firebase.functions().httpsCallable("docExists");
				docExists({ collection: "invoices", docId: invoice.leviedInvoice })
					.then(({ data }) => {
						if (data.exists) {
							err.metadata = {
								message:
									"caught in handleUpdateLeviedInvoice: " +
									invoice.leviedInvoice +
									" exists but cant update",
							};
							// setErrorObj(err);
							console.error(err) // dont show error to client since this is updating levied invoice and is the leviers problem
						} else {
							// levied invoice could have been removed
							// silently remove the leviedInvoice ID from the invoice
							firebase
								.firestore()
								.collection("invoices")
								.doc(invoice.id)
								.update({
									leviedInvoice: "",
								})
								.catch((err) => {
									console.error(err);
								});
						}
					})
					.catch((err) => {
						// setErrorObj(err);
						console.error(err) // dont show error to client since this is updating levied invoice and is the leviers problem
					});
			} else {
				console.error(err);
				setErrorObj(err);
			}
		}
	};

	const getProperEntry = (entry, collectionName) => {
		let properEntry = { ...entry };

		if (collectionName !== "receipts") {
			properEntry = {
				...properEntry,
				...(properEntry.qty && { qty: parseFloat(properEntry.qty) }),
				...(properEntry.cost && { cost: parseFloat(properEntry.cost) }),
				...(properEntry.total && { total: parseFloat(properEntry.total) }),
				values: properEntry.values.map((td) => {
					if (td.for === "date" || td.for === "item") {
						return td;
					} else {
						return {
							...td,
							val: isNaN(parseFloat(td.val)) ? td.val : parseFloat(td.val),
						};
					}
				}),
			};

			// parse all number vals
			properEntry.values.forEach((td) => {
				let tdVal = td.val;
				if (td.for === "qty" || td.for === "cost" || td.for === "total") {
					tdVal = parseFloat(tdVal);
				}
				if (isNaN(tdVal)) {
					tdVal = td.val;
				}

				properEntry[td.for] = tdVal;
			});
		}

		// dont delete isDefault entry until right before entry update
		return properEntry;
	};

	const handleSave = (prop) => {
		// put this in a try catch async fn...
		// prop is usually object with a newEditedInv property
		// edit the invoice to remove unnecessary fields
		let newEditedInv;
		let newInv = { ...inv };
		if (prop && prop.newEditedInv) {
			newEditedInv = { ...editedInv, ...prop.newEditedInv };
		} else {
			newEditedInv = editedInv;
		}
		// make sure totals are up to date
		const updatedInvoiceTotals = getInvoiceTotals({
			editedInv: newEditedInv,
			invoiceTotals,
		});
		// make sure owner is listed as an editor
		let newEditors = newEditedInv.editors;
		if (
			!newEditors.includes(newEditedInv.owner) &&
			user.uid === newEditedInv.owner
		) {
			newEditors = [...newEditors, newEditedInv.owner];
		}

		// remove payments since it should only be updated by changing the payments subcollection
		delete newEditedInv.payments;
		delete newInv.payments;
		const currentInvVersion = editedInvVersions.find(v => v.current)
		// warn that versions dont match
		if (currentInvVersion && newEditedInv.version !== currentInvVersion.id) {
			console.warn("warning versions don't match", {editedInvVersions, currentInvVersionID: currentInvVersion.id, v: newEditedInv.version})
		}

		// sort for easier comparison
		newEditedInv = handleSortItemOrArray({
			...newEditedInv,
			accessors: removeDuplicates([
				...newEditedInv.editors,
				newEditedInv.owner,
				newEditedInv.billTo.uid,
			]),
			editors: newEditors,
			invoiceTotals: updatedInvoiceTotals,
			mostRecentEntryDate: getMostRecentEntryDate(newEditedInv),
			totalOwed: getNum(
				parseFloat(invoiceTotals.customEntries.total) +
					parseFloat(updatedInvoiceTotals.laborEntries.total) +
					parseFloat(updatedInvoiceTotals.materialEntries.total) -
					parseFloat(updatedInvoiceTotals.paymentEntries.total),
				2
			),
			totalPaid: getNum(
				parseFloat(updatedInvoiceTotals.paymentEntries.total),
				2
			),
			searchKeywords: formulateSearchKeywords({
				doc: newEditedInv,
				type: "invoice",
				userObject,
			}),
		});

		// return the objects that differ
		const invoiceRef = firebase
			.firestore()
			.collection("invoices")
			.doc(newEditedInv.id || editedInv.id);
		let invoiceEdited = deepObjectCompareDiffers({
			a: newInv,
			b: newEditedInv,
		});

		// check if any are default entries and overwrite invoiceEdited
		subCollectionsList.forEach((col) => {
			if (newEditedInv[col].find((ent) => ent.isDefaultEntry)) {
				invoiceEdited = true;
			}
		});

		if (invoiceEdited) {
			let invWithoutEntries = { ...newInv };
			let editedInvWithoutEntries = { ...newEditedInv };

			subCollectionsList.forEach((collectionName) => {
				if (editedInvWithoutEntries[collectionName]) {
					delete editedInvWithoutEntries[collectionName];
				}
				if (invWithoutEntries[collectionName]) {
					delete invWithoutEntries[collectionName];
				}
			});

			// handle billTo saving invoice
			const userIsBillTo =
				editedInvWithoutEntries.billTo &&
				editedInvWithoutEntries.billTo.uid === user.uid;
			const userIsOnlyBillTo = userIsBillTo && !userIsOwner && !userCanEdit;

			const batch = firebase.firestore().batch();
			let deletedRows = {};
			let allProperEntries = {};
			let allChangedProperEntries = {}; // entries should never contain any entries that have property isDefaultEntry
			// update all subcollections
			if (!userIsOnlyBillTo) {
				// billTo cannot update any subcollections
				subCollectionsList.forEach((collectionName) => {
					let entries = newEditedInv[collectionName];
					let properEntries = []; // entries may contain isDefaultEntry
					let changedProperEntries = []; // entries should never contain isDefaultEntry
					let collectionWasEdited =
						JSON.stringify(newInv[collectionName]) !== JSON.stringify(entries);
					// if collection entries has a default entry we need to deepObjectCompare to see if it was edited
					if (entries.find((ent) => ent.isDefaultEntry)) {
						collectionWasEdited = true;
					}
					// make properEntries and changedProperEntries
					entries.forEach((entry) => {
						// const entryRef = invoiceRef.collection(collectionName).doc(entry.id);
						let properEntry = getProperEntry(entry, collectionName);

						const invVersionOfEntry = newInv[collectionName].find(
							(ent) => ent.id === entry.id
						);

						const invEntryString = JSON.stringify(invVersionOfEntry);
						const editedInvEntryString = JSON.stringify(properEntry);

						// if the entry is new, changed or is a default entry, 
						// ... if collection is the receipts collection just add to changedProperEntries
						// ... else if the entry does not match a default entry (could be marked default bit awas actuallly changed)
						// then add it to changed entries
						if (
							invEntryString !== editedInvEntryString ||
							entry.isDefaultEntry
						) {
							if (collectionName === "receipts") {
								// add to changed entries
								changedProperEntries.push(properEntry);
							} else {
								// dont update the subcollection if the entry is matching the result of getDefaultEntry or collection is receipts

								// add/update the td values to a non nested property in the entry
								// so that we can update an entry in forwardBill firestore cant update one property of an object in an array
								// need to be careful not to use the keys that are used in time tracking eg: hoursPlanned,  originalHoursPlanned, shiftEntries ...
								if (
									!matchesDefaultEntry({
										entry: { ...properEntry },
										collection: collectionName,
										invoice: newEditedInv,
									})
								) {
									// this entry is not a default entry anymore since it is changed
									// update the changedProperEntries
									if (properEntry.isDefaultEntry) {
										delete properEntry.isDefaultEntry;
									}

									changedProperEntries.push(properEntry);
								}
							}
						}

						// add to all entries
						// not proper entry's isDefaultEntry could have just changed due to code above
						properEntries.push(properEntry);
					});

					// add all the updatedEntries to changedProperEntries
					allChangedProperEntries[collectionName] = changedProperEntries;

					allProperEntries[collectionName] = properEntries; // can include defaultEntries

					// delete entry if not found in new version of invoice
					newInv[collectionName].forEach((entry) => {
						const entryRef = invoiceRef
							.collection(collectionName)
							.doc(entry.id);
						const entryInNewVersion = newEditedInv[collectionName].find(
							(ent) => ent.id === entry.id
						);
						if (!entryInNewVersion) {
							if (collectionName === "receipts") {
								// remove from storage
								const storageRef =
									entry.storagePath ||
									`${newEditedInv.owner}/receipts/${entry.id}`;
								firebase
									.storage()
									.ref(storageRef)
									.delete()
									.catch((err) => {
										if (err.code === "storage/object-not-found") {
											return;
										} else if (err.code === "storage/unauthorized") {
											// just don't show the message to user
											// this is most likely because the user is trying to delete a receipt that was uploaded by a different user
											return 
										} else {
											setErrorObj(err);
										}
									});

							}
							// add the entry to deletedRows list so that we can delete it from a forwarded bill if there is one
							deletedRows[collectionName] = [
								...(deletedRows[collectionName] || []),
								entry,
							];

							batch.delete(entryRef);
						}
					});

					// update this invoices collection
					if (collectionWasEdited) {
						// dont update the subcollection if the entry is matching the result of getDefaultEntry or collection is receipts
						changedProperEntries.forEach((properEntry) => {
							// check if entry differs
							const entryRef = invoiceRef
								.collection(collectionName)
								.doc(properEntry.id);
							if (collectionName === "receipts") {
								batch.set(entryRef, properEntry, { merge: true });
							} else {

								batch.set(entryRef, properEntry, {
									merge: true,
								});
							}
						});
					}
				});

				// hadnle new or removed editors
				let addedEditors = []
				let removedEditors = []

				newInv.editors.forEach(id => {
					if (!newEditedInv.editors.includes(id)) {
						removedEditors.push(id)
					}
				})

				newEditedInv.editors.forEach(id => {
					if (!newInv.editors.includes(id)) {
						addedEditors.push(id)
					}
				})

				if (addedEditors.length || removedEditors.length) { // note editors can add editors
					const newEditors = removeDuplicates([...newEditedInv.editors, newEditedInv.owner])
					// if the editors array has changed and the current user is currently thr invoice owner (has permission to add editors)
					handleUpdateReceiptMetadata({newEditors, receipts: newEditedInv.receipts/*.map(rec => getProperEntry(rec, "receipts"))*/})
				}

				batch
					.commit()
					.then(() => {
						if (prop && prop.handleSetSuccessText) {
							prop.handleSetSuccessText("All changes saved")	
						} else {
							handleSetSuccessText("All changes saved")
						}
					})
					.catch((err) => {
						setErrorObj(err);
					});
			}

			// cleanNewEditedInv doesnt include some properties like ID etc
			// line below was to blame for row entries bug
			// let cleanNewEditedInv = { ...newEditedInv, ...allChangedProperEntries };
			// allChangedProperEntries are only the eentries that have been changed . unchanged entries wont be added which is problem when setting setInv 
			let cleanNewEditedInv = { ...newEditedInv, ...allProperEntries };


			let cleanInvoiceWithoutEntries = { ...editedInvWithoutEntries };
			// remove non permissible fields
			if (userIsOnlyBillTo) {
				disabledBillToFields.forEach((field) => {
					if (cleanInvoiceWithoutEntries[field]) {
						delete cleanInvoiceWithoutEntries[field];
					}
					if (cleanNewEditedInv[field]) {
						delete cleanNewEditedInv[field];
					}
				});
			}

			if (!userIsBillTo) {
				// must delete, not just set to null because this is added back in when spreading ...newEditedInv
				delete cleanInvoiceWithoutEntries.leviedInvoiceOptions;
				delete cleanNewEditedInv.leviedInvoiceOptions;
			}

			let invTopSectionWasEdited =
				JSON.stringify(invWithoutEntries) !==
				JSON.stringify(editedInvWithoutEntries);
			const newSearchKeywords = formulateSearchKeywords({
				doc: editedInvWithoutEntries,
				type: "invoice",
				userObject,
			});


			if (invTopSectionWasEdited) {
				const billToIdChanged = newEditedInv.billTo.uid !== newInv.billTo.uid;
				if (
					(newEditedInv.billTo.uid === "" || billToIdChanged) &&
					newEditedInv.leviedInvoice
				) {

					// we must de-couple the invoice since the sub-contractor removed the billTo
					cleanInvoiceWithoutEntries.leviedInvoice = "";
					cleanNewEditedInv.leviedInvoice = "";
					// subcontractor not allowed to update leviedInvoiceOptions
				}
				cleanInvoiceWithoutEntries = {
					...cleanInvoiceWithoutEntries,
					searchKeywords: newSearchKeywords,
				};

				let successTextFunction = handleSetSuccessText
				if (prop && prop.handleSetSuccessText) {
					successTextFunction = prop.handleSetSuccessText
				}
				updateInvoice(
					newEditedInv.id, // DO NOT USE editedInvWithNewKeywords.id because it comes from CLEAN INVOICE which strips properties including ID, that the user is not allowed to change
					cleanInvoiceWithoutEntries,
					setErrorObj,
					successTextFunction
				);
			}

			// update usersInvoices for dashboard display, copying invoices relies on usersInvoices
			if (usersInvoices && usersInvoices.length) {
				setUsersInvoices((usersInvoices) => {
					const indexOfInvInUsersInvoices = usersInvoices.findIndex(
						(obj) => obj.id === newEditedInv.id
					);

					if (indexOfInvInUsersInvoices > -1) {
						let newUsersInvoices = [...usersInvoices];
						newUsersInvoices[indexOfInvInUsersInvoices] = {
							...newUsersInvoices[indexOfInvInUsersInvoices],
							...cleanInvoiceWithoutEntries, // usersInvoices do not include subcollections
						};

						return newUsersInvoices;
					} else return usersInvoices;
				});
			}

			// set the saved invoice copy upon successful update of the DB
			// if using a snapshot listener, shouldnt need to do this as inv is set upone snapshot changes
			handleSetInv({ changes: cleanNewEditedInv }); // cleanNewEditedInv includes defaultEntries
			if (newEditedInv.leviedInvoice) {
				// must keep the leviedInvoice id
				handleUpdateLeviedInvoice(
					{
						...newEditedInv,
						...cleanNewEditedInv, // cleanNewEditedInv includes defaultEntries
						leviedInvoice: newEditedInv.leviedInvoice, //nee dthe leviedInvoice id for this function
					},
					allProperEntries,
					deletedRows
				);
			}
			return true;
		} else {
			if (!editedInv.autoSaveEnabled) {
				if (prop && prop.handleSetSuccessText) {
					prop.handleSetSuccessText("No changes made", 4000)
				} else {
					handleSetSuccessText("No changes made", 4000);
				}

				setIsEditing(false); // maybe change this so it only sets isEditing to false if success
			}
			return true;
		}
	};

	// get rid of invoice version "original"
	useEffect(() => {
	  if (inv.version && editedInv.id) {
	  	const invVersion = isNaN(parseInt(inv.version)) ? 1 : parseInt(inv.version)
  		setLastSavedVersion(invVersion)
	  	setEditedInvVersions(editedInvVersions => {
	  		return editedInvVersions.map(obj => {
	  			if (obj.id === "original") {
	  				return {
	  					...obj,
	  					id: parseInt(inv.version),
	  					current: false,
	  					changes: {
	  						...editedInv
	  					}
	  				}
	  			} else return obj
	  		})
	  	})
	  }
	  // editedInv not included because we want to set it to just the first thing that shows up as soon as its loaded this is the original
	  // eslint-disable-next-line
	}, [inv.version, editedInv.id])

		// if the user is editing wait til edits stop then run handle save after a few seconds
	useEffect(() => {
		let timer
		const currentInvVersion = editedInvVersions.find(v => v.current)

	  if (userCanEdit /*&& isEditing*/ && editedInv.autoSaveEnabled && currentInvVersion && currentInvVersion.id !== lastSavedVersion) {
	  	timer = setTimeout(() => {
	  		// handleSetSuccessText("") // this is glitchy either way
	  		handleSave()
	  		setLastSavedVersion(currentInvVersion.id)
	  	}, 3000)
	  }

	  return () => {
	  	clearTimeout(timer)
	  }
	  // handleSave not included since it renders on every render
	  // eslint-disable-next-line
	}, [userCanEdit, editedInv, /*handleSetSuccessText,*/ /*isEditing,*/ editedInvVersions, lastSavedVersion])

	return (
		<EditInvoiceContext.Provider
			value={{
				isEditing,
				setIsEditing,
				editedInv,
				setEditedInv,
				inv,
				setInv,
				handleSetEditedInv,
				handleSetInv,
				undoChange,
				redoChange,
				editedInvVersions,
				setEditedInvVersions,
				invoiceTotal,
				invoiceTotalStr,
				invoiceOwed,
				invoiceOwedStr,
				userIsOwner,
				setUserIsOwner,
				userIsBillTo,
				userCanEdit,
				setUserCanEdit,
				userIsFollower,
				setUserIsFollower,
				userCanView,
				setUserCanView,
				followersUserObjs,
				setFollowersUserObjs,
				changeBillToModalOpen,
				setChangeBillToModalOpen,
				addSummaryEntryModal,
				setAddSummaryEntryModal,
				addRow,
				deleteRow,
				activeRow,
				setActiveRow,
				handleSave,
				workerIsWorking,
				setWorkerIsWorking,
				liveEntryTotal,
				setLiveEntryTotal,
				invoiceTotals,
				setInvoiceTotals,
				billToBills,
				setBillToBills,
				// billSnapshotListeners,
				// setBillSnapshotListeners,
				addPaymentModal,
				setAddPaymentModal,
				verifiedPaymentModalData,
				setVerifiedPaymentModalData,
				successText,
				setSuccessText,
				handleSetSuccessText,
				errorText,
				setErrorText,
				errorObj,
				setErrorObj,
				foundAuthState,
				receiptViewModalData,
				setReceiptViewModalData,
				lastSavedVersion,
				setLastSavedVersion,
			}}
		>
			{children}
		</EditInvoiceContext.Provider>
	);
};

export { EditInvoiceContextProvider, EditInvoiceContext };
