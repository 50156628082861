import styled from 'styled-components'

export const ProfileReviewStyle = styled.div `
	display: grid;
	grid-template-rows: auto;
  grid-template-columns: auto auto 10px 1fr auto;
  grid-gap: 5px;
	.reviews {
		grid-row: 2;
		grid-column: 2 / 5;
	}
	#write-review {
	  display: flex;
	  text-align: right;
	  justify-content: flex-end;
	  grid-row: 1;
	  grid-column: 2 / 5;
		svg {
			width: 16px;
			height: 16px;
		}
	}
	.review-component {
		font-size: 14px;
		.edit-review {
			margin: 0 0 0 20px;
		}
		.reviewer-type {
	    background-color: lightgray;
	    font-size: 12px;
      padding: 0 5px 1px 5px;
	    margin: 0 0 0 5px;
	    cursor: help;
		}
		.review-type-title {
			font-size: 18px;
	    font-weight: 600;
		}
	}

`