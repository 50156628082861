import React, { useState, useContext } from "react";
import firebase from "../../../firebase/index";

import { UserContext } from "../../../contexts/UserContext";
import { NotificationsContext } from "../../../contexts/NotificationsContext";
import { MediaQueryContext } from "../../../contexts/MediaQueryContext";
import { DialogContext } from "../../../contexts/DialogContext";

import { EditInvoiceContext } from "../../../contexts/EditInvoiceContext";

import Modal from "../../Modal/Modal";
import MoreInfoIcon from "../../MoreInfoIcon";
import SmallSpinner from "../../stripeComponents/SmallSpinner";
import { ViewPaymentModalStyle } from "./ViewPaymentModalStyle.styled";
import { AppMessage } from "../../MessageUtils";

import { getNum } from "../../../utils/appUtils";
import { sendNotification } from "../../../utils/notificationUtils";
import { getNewEntriesOnTdChange } from "../invoiceUtils";
import Form from "../../Forms/Form";

// rendered by: SpecificInvoice.js
const ViewPaymentModal = () => {
	const { dialog } = useContext(DialogContext);
	const {
		editedInv,
		handleSetEditedInv,
		deleteRow,
		verifiedPaymentModalData,
		setVerifiedPaymentModalData,
	} = useContext(EditInvoiceContext);
	const { currentUser, userObject, handleSetSuccessText, setErrorObj } =
		useContext(UserContext);
	const { userNotifications, removeNotification } = useContext(NotificationsContext);

	const { isLessThan700px } = useContext(MediaQueryContext);
	const { tableName, rowId } = verifiedPaymentModalData;

	const emptyPayment = {
		type: "",
		paymentName: "",
		amount: "",
		// amountApplied: "",
		amountReceived: "",
		receivedDate: "",
	};

	const payment = editedInv.payments
		? editedInv.payments.find((p) => p.id === rowId) || emptyPayment
		: emptyPayment;

	const userIsReceiver = currentUser && payment.receiver === currentUser.uid;
	const userIsPayer = currentUser && payment.payer === currentUser.uid;

	const [processing, setProcessing] = useState(false);
	const [newPaymentData, setNewPaymentData] = useState({
		...payment,
		// update all the paymen values to not be in cents,
		amount: parseFloat(payment.amount || 0) / 100,
		// amountApplied: parseFloat(payment.amountApplied || 0) / 100,
		amountReceived: parseFloat(payment.amountReceived || 0) / 100,
		receivedDate: payment.receivedDate,
	});

	const handleChange = (name, val) => {
		let otherNewData = {}
		if (name === "status" || val === "succeeded") {
			otherNewData.amountReceived = newPaymentData.amount
		}

		// // note must use newPaymentData or convert payment.amount to $ instead of cents because newPaymentData.amount is converted from cents
		// if (name === "amountReceived" && parseFloat(val) === parseFloat(newPaymentData.amount) ) {
		// 	otherNewData.status = "succeeded"
		// }

		setNewPaymentData((newPaymentData) => ({
			...newPaymentData,
			[name]: val,
			...otherNewData
		}));
	};

	// console.log({newPaymentData})

	// note newPaymentData.amount is converted from cents
	const amountReceivedEqualsAmountPaid = parseFloat(newPaymentData.amount) === parseFloat(newPaymentData.amountReceived)

	const handleUpdatePayment = async (e) => {
		e && e.preventDefault();
		try {
			setProcessing(true);
			let amountPaid = parseFloat(newPaymentData.amount); // do not change back to cents yet. invoice row entry update needs $ value
			let amountReceived = parseFloat(newPaymentData.amountReceived); // do not change back to cents yet. invoice row entry update needs $ value
			let newQtyVal;
			let newEditedInv = {...editedInv}
			let mainInvoiceNeedsUdating = false

			let irrelevantNotifications = [];


			// if the payment hasnt been approved yet but contractors clicks save we should approve
			if (amountReceivedEqualsAmountPaid && payment.status !== "succeeded") {
				newPaymentData.status = "succeeded"
			}
			// if contractor approved the payment here instead of in notifications
			if (
				newPaymentData.status === "succeeded" &&
				payment.status !== "succeeded"
			) {
				// get userNotifications context and find any in there that are for approving this payment
				newQtyVal = 1;
				irrelevantNotifications = userNotifications.filter((n) => {
					if (
						n.type === "addPayment" &&
						n.additional &&
						n.additional.paymentData &&
						n.additional.paymentData.id === payment.id
					) {
						return true;
					} else return false;
				});
				// since the contractor is approving this payment we can say they agree that the amount received is the amount paid
				// unless user updated amount received already
				// below is useless because amountReceived is 0 until contractor approves
				// if (parseFloat(payment.amountReceived) === parseFloat(amountReceived)) {
				// 	amountReceived = amountPaid;
				// }
				// need to update the billTO to the payer as long as payer is not receiver or there has not been a payer yet
				// this is to handle when a receiver adds a payment entry for their payer buut wants to kekep the payer as the billTo
				if ((payment.payer !== payment.receiver) || !newEditedInv.billTo.uid) {
					newEditedInv.billTo = {
						...newEditedInv.billTo,
						uid: payment.payer,
						username: payment.payerUsername,
						// ...payment.payerAddress ? {address: payment.payerAddress} : {}
					}
					mainInvoiceNeedsUdating = true
				}
			}

			let paymentDataUpdate = {
				...newPaymentData,
				amount: amountPaid * 100, // convert back to cents
				amountReceived: amountReceived * 100, // convert back to cents
			};

			if (userIsPayer && !userIsReceiver) {
				// delete most properties because
				// payer can only update one item
				paymentDataUpdate = {
					amount: paymentDataUpdate.amount,// convert back to cents
				};
			}

			if (userIsReceiver && !userIsPayer) {
				// in case the apyer updated the amount since user opened this modal
				delete paymentDataUpdate.amount;
			}

			await firebase
				.firestore()
				.collection("invoices")
				.doc(editedInv.id)
				.collection("payments")
				.doc(payment.id)
				.update({
					...paymentDataUpdate,
				}).catch(err => {
					console.log("Error updating payment data: ", err)
					throw err
				})
			// delete the notification(s) with handleRemoveNotification
			if (irrelevantNotifications.length) {
				await Promise.all(
					irrelevantNotifications.map((n) => {
						const docRef = null // dont need socRef because function defaults to nodifications collection
						const keepInList = false // this is for notifications modal
				  	return removeNotification(n, currentUser.uid, docRef, keepInList)
					})
				).catch(err => {
					console.log("Error removing irrelevantNotifications: ", err, JSON.stringify(err))
					throw err
				})
			}
			if (mainInvoiceNeedsUdating) {
				await firebase
					.firestore()
					.collection("invoices")
					.doc(editedInv.id)
					.set({
						accessors: firebase.firestore.FieldValue.arrayUnion(payment.payer),
						followers: firebase.firestore.FieldValue.arrayUnion(payment.payer),
						billTo: {
							...newEditedInv.billTo
						},
					}, { merge: true }).catch(err => {
					console.log("Error updating main invoice data: ", err)
					throw err
				})
			}
			// update the UI
			let updatedEntries = [];
			// update the row
			newEditedInv = {
				...newEditedInv,
				[tableName]: editedInv[tableName].map((row) => {
					if (row.id === rowId) {
						return { ...row, status: "succeeded" };
					} else return row;
				}),
			};
			// update the date
			updatedEntries = getNewEntriesOnTdChange(
				{ for: "date", val: newPaymentData.receivedDate },
				newEditedInv,
				tableName,
				rowId
			);

			// update the item name
			updatedEntries = getNewEntriesOnTdChange(
				{ for: "item", val: newPaymentData.paymentName },
				{ ...newEditedInv, [tableName]: updatedEntries },
				tableName,
				rowId
			);
			// update the cost and total
			if (userIsPayer) {
				updatedEntries = getNewEntriesOnTdChange(
					{ for: "cost", val: getNum(amountPaid, 2) },
					{ ...newEditedInv, [tableName]: updatedEntries },
					tableName,
					rowId
				);
			}
			if (userIsReceiver) {
				updatedEntries = getNewEntriesOnTdChange(
					{ for: "total", val: getNum(amountReceived, 2) },
					{ ...newEditedInv, [tableName]: updatedEntries },
					tableName,
					rowId
				);
			}
			if (newQtyVal) {
				updatedEntries = getNewEntriesOnTdChange(
					{ for: "qty", val: newQtyVal },
					{ ...newEditedInv, [tableName]: updatedEntries },
					tableName,
					rowId
				);
			}

			const newPayments = editedInv.payments.map((p) => {
				if (p.id === payment.id) {
					return { ...p, ...paymentDataUpdate };
				} else return p;
			});
			handleSetEditedInv({
				mergeIntoHistory: true,
				changes: { payments: newPayments, [tableName]: updatedEntries },
				caller: "ViewPaymentModal - handleUpdatePayment",
			});
			setProcessing(false);
			onClickOutside();
			handleSetSuccessText("Payment updated successfully!", 6000);
		} catch (err) {
			if (err.message.includes("Missing or insufficient permissions")) {
				err.message =
					"You either do not have permission to edit these fields or the payment has been been deleted";
			}
			setProcessing(false);
			setErrorObj(err);
		}
	};

	const handlePayerDeletePayment = async () => {
		try {
			const batch = firebase.firestore().batch();
			setProcessing(true);
			// delete the payment entry
			batch.delete(
				firebase
					.firestore()
					.collection("invoices")
					.doc(editedInv.id)
					.collection("payments")
					.doc(payment.id)
			);
			// get and delete any related notifications
			await firebase
				.firestore()
				.collection("notifications")
				.where("accessors", "array-contains", currentUser.uid)
				.where("additional.paymentData.id", "==", payment.id)
				.get()
				.then((snapshot) => {
					// delete all notifications about that payment
					return snapshot.docs.map((doc) => batch.delete(doc.ref));
				});

			await batch.commit();
			// remove in UI and show confirmation
			deleteRow({ id: rowId, showWindowConfirm: false, tableName });
			handleSetSuccessText("Payment removed successfully", 6000);
			setTimeout(() => {
				setProcessing(false);
				onClickOutside();
			}, 2000);
		} catch (err) {
			setProcessing(false);
			setErrorObj(err);
		}
	};

	const handleDeletePayment = async (e) => {
		e.preventDefault();
		let confirmDelete;
		if (userIsPayer) {
			confirmDelete = await dialog.confirm(
				"Are you sure you want to remove this payment?"
			);
			if (confirmDelete) {
				return handlePayerDeletePayment();
			} else return false;
		} else {
			if (
				payment.type === "stripe" &&
				payment.refunded &&
				parseFloat(payment.amount_refunded) === parseFloat(payment.amount)
			) {
				confirmDelete = await dialog.confirm(
					"Are you sure you want to remove this payment?"
				);
				if (confirmDelete) {
					return handlePayerDeletePayment();
				} else return false;
			} else {
				confirmDelete = await dialog.confirm(
					"Confirm request delete? This will send a notification to the payer asking them to approve this action"
				);
			}

			if (confirmDelete) {
				const notification = {
					additional: {
						paymentData: payment,
					},
					// date: new Date().toISOString(),
					docLink: {
						name: editedInv.invNumber ? `Invoice #${editedInv.invNumber} ${editedInv.invShortHand || ""}` : "",
						pathname: `/${editedInv.contractor.companyName || "invoices"} editedInv.id`,
						externamLink: "",
					},
					forDocumentCollection: "invoices",
					forDocumentId: editedInv.id,
					forUsers: [payment.payer],
					// linkTo: collectionName === "projects" ? `/${collectionName}/${parentResource.id}` : `/${parentResource.contractor.companyName || collectionWithNoS}/${parentResource.id}`,
					// message: "",
					sentBy: currentUser.uid,
					sentByUsername: userObject.username,
					type: "deletePayment",
				};
				return sendNotification(notification)
					.then(() => {
						// onClickOutside()
						handleSetSuccessText(
							"Payment entry will be deleted upon payers approval",
							6000
						);
					})
					.catch((err) => setErrorObj(err));
			}
		}
	};

	const onClickOutside = () => {
		setProcessing(false);
		setVerifiedPaymentModalData((verifiedPaymentModalData) => ({
			...verifiedPaymentModalData,
			open: false,
		}));
	};

	// invoice owner cannot change:
	// amount
	// created
	// payer
	const inputs = [
		{
			visible: userIsReceiver || userIsPayer,
			properties: {
				type: "hidden",
				id: "hiddenInput1",
				onChange: () => null,
			},
			beforeInput: [
				<React.Fragment key="payment-status">
					{payment.receipt_url && (
						<div>
							<a
								style={{ fontSize: "inherit" }}
								href={payment.receipt_url}
								target="blank"
							>
								View Receipt
								<svg
									style={{
										height: "1.5ch",
										margin: "0 0 0 0.5ch",
									}}
									viewBox="0 0 32 32"
									fill="none"
									stroke="currentcolor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
									<path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
								</svg>
							</a>
							<div className="small-line-break" />
						</div>
					)}
					<p>
						Payment Status: <em>{newPaymentData.status}</em>
						{newPaymentData.status === "needs_confirmation" && userIsReceiver && (
							<React.Fragment>
								{" "}
								<button
									disabled={processing}
									className="button-appearance tiny no-icon-or-underline"
									onClick={(e) => {
										e.preventDefault();
										handleChange("status", "succeeded");
									}}
								>
									Approve
								</button>
							</React.Fragment>
						)}
					</p>
					{
						// if user is Contractor, show non editable Amount
						userIsReceiver && !userIsPayer && (
							<p>
								Amount: <em>${getNum(parseFloat(payment.amount) / 100, 2)}</em>
							</p>
						)
					}
					{
						// user is payer / billTo, show non editable amount received and payment type
						userIsPayer && !userIsReceiver && (
							<React.Fragment>
								<p>
									Amount Received:{" "}
									<em>
										${getNum(parseFloat(payment.amountReceived) / 100, 2)}
									</em>
								</p>
								<p>
									Payment Type: <em>{payment.type}</em>
								</p>
							</React.Fragment>
						)
					}
					<div className="small-line-break" />
				</React.Fragment>,
			],
		},
		{
			custom: true,
			label: "Payment Type",
			visible: userIsReceiver,
			properties: {
				onChange: handleChange,
				type: "dropdown",
				optionVal: "name",
				name: "type",
				value: newPaymentData.type,
				options: [
					{ name: "cash" },
					{ name: "etransfer" },
					{ name: "cheque" },
					{ name: "card" },
				],
			},
		},
		{
			label: "Item Name",
			visible: userIsReceiver,
			onChange: handleChange,
			properties: {
				type: "text",
				id: "paymentName",
				value: newPaymentData.paymentName,
			},
		},
		{
			label: "Amount Received",
			visible: userIsReceiver,
			onChange: handleChange,
			properties: {
				type: "number",
				id: "amountReceived",
				value: newPaymentData.amountReceived,
			},
		},
		{
			label: "Payment Received date",
			visible: userIsReceiver,
			onChange: handleChange,
			properties: {
				type: "date",
				id: "receivedDate",
				value: newPaymentData.receivedDate,
			},
		},
		{
			label: "Total Paid",
			visible: userIsPayer,
			onChange: handleChange,
			properties: {
				type: "number",
				id: "amount",
				value: newPaymentData.amount,
				disabled: payment.status === "needs_confirmation" && !userIsReceiver,
			},
		},
		// {
		// 	label: "Received payment",
		// 	visible: userIsReceiver,
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "number",
		// 		id: "amountReceived",
		// 		value: newPaymentData.amountReceived
		// 	}
		// },
		{
			visible: userIsReceiver || userIsPayer,
			properties: {
				type: "hidden",
				id: "hiddenInput1",
				onChange: () => null,
			},
			beforeInput: [
				<div key="delete-payment">
					<div className="small-line-break" />
					<span className="link-appearance" onClick={handleDeletePayment}>
						Delete Payment
					</span>{" "}
					{
						// the owner cannot delete the payment entry unless payer approves
						userIsReceiver &&
							parseFloat(payment.amount_refunded) !==
								parseFloat(payment.amount) && (
								<MoreInfoIcon
									absolute={true}
									text={
										"The payment will only be deleted upon the payers approval"
									}
								/>
							)
					}
				</div>,
			],
		},
	];

	const viewOnlyComponents = [
		<div className="align-left" key="viewOnlyComponents">
			<div className="heading">Payment Details</div>
			<div className="section-divider" />
			{payment.receipt_url && (
				<div>
					<a
						style={{ fontSize: "inherit" }}
						href={payment.receipt_url}
						target="blank"
					>
						View Receipt
						<svg
							style={{
								height: "1.5ch",
								margin: "0 0 0 0.5ch",
							}}
							viewBox="0 0 32 32"
							fill="none"
							stroke="currentcolor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						>
							<path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
						</svg>
					</a>
					<div className="small-line-break" />
				</div>
			)}
			<p>
				Payment Status: <em>{payment.status}</em>
			</p>
			<p>
				Payment Type: <em>{payment.type}</em>
			</p>
			<p>
				{payment.type === "stripe" ? "Total Paid: " : "Amount Paid: "}
				<em>${getNum(parseFloat(payment.amount) / 100, 2)}</em>
			</p>
			<p>
				{payment.type !== "stripe" && (
					<React.Fragment>
						Amount Received:{" "}
						<em>${getNum(parseFloat(payment.amountReceived) / 100, 2)}</em>
					</React.Fragment>
				)}
			</p>
			{payment.type === "stripe" && (
				<React.Fragment>
					{payment.refunded && (
						<React.Fragment>
							Amount Refunded:{" "}
							<em>${getNum(parseFloat(payment.amount_refunded) / 100, 2)}</em>
						</React.Fragment>
					)}
					{(userIsPayer || userIsReceiver) && (
						<div>
							<div className="small-line-break" />
							<span className="link-appearance" onClick={handleDeletePayment}>
								Delete Payment
							</span>{" "}
							{
								// the owner cannot delete the payment entry unless payer approves
								userIsReceiver &&
									parseFloat(payment.amount_refunded) !==
										parseFloat(payment.amount) && (
										<MoreInfoIcon
											absolute={true}
											text={
												"The payment will only be deleted upon the payers approval"
											}
										/>
									)
							}
						</div>
					)}
					{userIsReceiver && !payment.refunded && (
						<div>
							<div className="section-divider" />
							Visit your{" "}
							<a
								style={{ font: "inherit" }}
								target="_blank"
								rel="noopener noreferrer"
								href="https://dashboard.stripe.com"
							>
								Stripe.com Dashboard
							</a>{" "}
							to refund this payment or view more details
						</div>
					)}
				</React.Fragment>
			)}
		</div>,
	];

	return (
		<ViewPaymentModalStyle isLessThan700px={isLessThan700px}>
			<Modal custom={{ absolute: true }} onClickOutside={onClickOutside}>
				{payment.type === "stripe" || (!userIsReceiver && !userIsPayer) ? (
					viewOnlyComponents
				) : (
					<React.Fragment>
					<div className="heading">Edit Payment</div>
						<AppMessage />
						<Form
							heading=""
							submitName={(amountReceivedEqualsAmountPaid && userIsReceiver && newPaymentData.status !== "succeeded") ? "Approve & Save" : "Save"}
							submitDisabled={
								processing ||
								(!userIsReceiver &&
									userIsPayer &&
									payment.status === "needs_confirmation")
							}
							onSubmit={(e) => {
								handleUpdatePayment(e);
							}}
							inputs={inputs}
						></Form>
						<div className="small-line-break" />
						{processing && <SmallSpinner classNames="inverse" />}
					</React.Fragment>
				)}
			</Modal>
		</ViewPaymentModalStyle>
	);
};

export default ViewPaymentModal;








// with "Discounts"

// import React, { useState, useContext } from "react";
// import firebase from "../../../firebase/index";

// import { UserContext } from "../../../contexts/UserContext";
// import { NotificationsContext } from "../../../contexts/NotificationsContext";
// import { MediaQueryContext } from "../../../contexts/MediaQueryContext";

// import { EditInvoiceContext } from "../../../contexts/EditInvoiceContext";

// import Modal from "../../Modal/Modal";
// import MoreInfoIcon from "../../MoreInfoIcon";
// import SmallSpinner from "../../stripeComponents/SmallSpinner";
// import { ViewPaymentModalStyle } from "./ViewPaymentModalStyle.styled";
// import { AppMessage } from "../../MessageUtils";

// import { getNum } from "../../../utils/appUtils";
// import { sendNotification } from "../../../utils/notificationUtils";
// import { getNewEntriesOnTdChange } from "../invoiceUtils";
// import Form from "../../Forms/Form";

// // rendered by: SpecificInvoice.js
// const ViewPaymentModal = () => {
// 	const {
// 		editedInv,
// 		handleSetEditedInv,
// 		// userIsOwner,
// 		// userIsBillTo,
// 		deleteRow,
// 		verifiedPaymentModalData,
// 		setVerifiedPaymentModalData,
// 	} = useContext(EditInvoiceContext);
// 	const { currentUser, userObject, handleSetSuccessText, setErrorObj } =
// 		useContext(UserContext);
// 	const { userNotifications, handleRemoveNotification } =
// 		useContext(NotificationsContext);

// 	const { isLessThan700px } = useContext(MediaQueryContext);
// 	const { tableName, rowId } = verifiedPaymentModalData;

// 	// const row = editedInv.paymentEntries.find(p => p.id === rowId)
// 	// const dateTd = row ? row.values.find(td => td.for === "date") : undefined

// 	const emptyPayment = {
// 		type: "",
// 		paymentName: "",
// 		amount: "",
// 		// amountApplied: "",
// 		amountReceived: "",
// 		receivedDate: "",
// 	};

// 	const payment = editedInv.payments
// 		? editedInv.payments.find((p) => p.id === rowId) || emptyPayment
// 		: emptyPayment;

// 	const userIsReceiver = currentUser && payment.receiver === currentUser.uid;
// 	const userIsPayer = currentUser && payment.payer === currentUser.uid;

// 	const [processing, setProcessing] = useState(false);
// 	const [newPaymentData, setNewPaymentData] = useState({
// 		...payment,
// 		// update all the paymen values to not be in cents,
// 		amount: parseFloat(payment.amount || 0) / 100,
// 		// amountApplied: parseFloat(payment.amountApplied || 0) / 100,
// 		amountReceived: parseFloat(payment.amountReceived || 0) / 100,
// 		discount: parseFloat(payment.discount || 0) / 100,
// 		receivedDate: payment.receivedDate,
// 	});

// 	const handleChange = (name, val) => {
// 		setNewPaymentData((newPaymentData) => ({
// 			...newPaymentData,
// 			[name]: val,
// 		}));
// 	};

// 	const handleUpdatePayment = async (e) => {
// 		e && e.preventDefault();
// 		try {
// 			setProcessing(true);
// 			let amountPaid = newPaymentData.amount;
// 			let amountReceived = newPaymentData.amountReceived;
// 			let discount = newPaymentData.discount;
// 			let newQtyVal;

// 			let irrelevantNotifications = [];
// 			// if contractor approved the payment here instead of in notifications
// 			if (
// 				newPaymentData.status === "succeeded" &&
// 				payment.status !== "succeeded"
// 			) {
// 				// get userNotifications context and find any in there that are for approving this payment
// 				newQtyVal = 1;
// 				irrelevantNotifications = userNotifications.filter((n) => {
// 					if (
// 						n.type === "addPayment" &&
// 						n.additional &&
// 						n.additional.paymentData &&
// 						n.additional.paymentData.id === payment.id
// 					) {
// 						return true;
// 					} else return false;
// 				});
// 				// since the contractor is approving this payment we can say they agree that the amount received is the amount paid
// 				// unless user updated amount received already
// 				if (parseFloat(payment.amountReceived) === parseFloat(amountReceived)) {
// 					amountReceived = amountPaid;
// 				}
// 			}

// 			let paymentDataUpdate = {
// 				...newPaymentData,
// 				amount: parseFloat(amountPaid) * 100,
// 				amountReceived: parseFloat(amountReceived) * 100,
// 				discount: parseFloat(discount) * 100,
// 			};

// 			if (userIsPayer && !userIsReceiver) {
// 				// payer can only update one item
// 				paymentDataUpdate = {
// 					amount: paymentDataUpdate.amount,
// 				};
// 			}

// 			if (userIsReceiver && !userIsPayer) {
// 				// in case the apyer updated the amount since user opened this modal
// 				delete paymentDataUpdate.amount;
// 			}

// 			await firebase
// 				.firestore()
// 				.collection("invoices")
// 				.doc(editedInv.id)
// 				.collection("payments")
// 				.doc(payment.id)
// 				.update({
// 					...paymentDataUpdate,
// 				});
// 			// delete the notification(s) with handleRemoveNotification
// 			if (irrelevantNotifications.length) {
// 				await Promise.all(
// 					irrelevantNotifications.map((n) => {
// 						return handleRemoveNotification(n, currentUser.uid);
// 					})
// 				);
// 			}
// 			// update the UI
// 			let updatedEntries = [];
// 			// update the row
// 			const newEditedInv = {
// 				...editedInv,
// 				[tableName]: editedInv[tableName].map((row) => {
// 					if (row.id === rowId) {
// 						return { ...row, status: "succeeded" };
// 					} else return row;
// 				}),
// 			};
// 			// update the date
// 			updatedEntries = getNewEntriesOnTdChange(
// 				{ for: "date", val: newPaymentData.receivedDate },
// 				newEditedInv,
// 				tableName,
// 				rowId
// 			);

// 			// update the item name
// 			updatedEntries = getNewEntriesOnTdChange(
// 				{ for: "item", val: newPaymentData.paymentName },
// 				{ ...newEditedInv, [tableName]: updatedEntries },
// 				tableName,
// 				rowId
// 			);
// 			// update the cost and total
// 			if (userIsPayer) {
// 				updatedEntries = getNewEntriesOnTdChange(
// 					{ for: "cost", val: getNum(amountPaid, 2) },
// 					{ ...newEditedInv, [tableName]: updatedEntries },
// 					tableName,
// 					rowId
// 				);
// 			}
// 			if (userIsReceiver) {
// 				updatedEntries = getNewEntriesOnTdChange(
// 					{ for: "total", val: getNum(amountReceived, 2) },
// 					{ ...newEditedInv, [tableName]: updatedEntries },
// 					tableName,
// 					rowId
// 				);
// 			}
// 			if (newQtyVal) {
// 				updatedEntries = getNewEntriesOnTdChange(
// 					{ for: "qty", val: newQtyVal },
// 					{ ...newEditedInv, [tableName]: updatedEntries },
// 					tableName,
// 					rowId
// 				);
// 			}

// 			const newPayments = editedInv.payments.map((p) => {
// 				if (p.id === payment.id) {
// 					return { ...p, ...paymentDataUpdate };
// 				} else return p;
// 			});
// 			handleSetEditedInv({
// 				mergeIntoHistory: true,
// 				changes: { payments: newPayments, [tableName]: updatedEntries },
// 				caller: "ViewPaymentModal - handleUpdatePayment",
// 			});
// 			setProcessing(false);
// 			onClickOutside();
// 			handleSetSuccessText("Payment updated successfully!");
// 		} catch (err) {
// 			if (err.message.includes("Missing or insufficient permissions")) {
// 				err.message =
// 					"You either do not have permission to edit these fields or the payment has been been deleted";
// 			}
// 			setProcessing(false);
// 			setErrorObj(err);
// 		}
// 	};

// 	const handlePayerDeletePayment = async () => {
// 		try {
// 			const batch = firebase.firestore().batch();
// 			setProcessing(true);
// 			// delete the payment entry
// 			batch.delete(
// 				firebase
// 					.firestore()
// 					.collection("invoices")
// 					.doc(editedInv.id)
// 					.collection("payments")
// 					.doc(payment.id)
// 			);
// 			// get and delete any related notifications
// 			await firebase
// 				.firestore()
// 				.collection("notifications")
// 				.where("accessors", "array-contains", currentUser.uid)
// 				.where("additional.paymentData.id", "==", payment.id)
// 				.get()
// 				.then((snapshot) => {
// 					// delete all notifications about that payment
// 					return snapshot.docs.map((doc) => batch.delete(doc.ref));
// 				});

// 			await batch.commit();
// 			// remove in UI and show confirmation
// 			deleteRow({ id: rowId, showWindowConfirm: false, tableName });
// 			handleSetSuccessText("Payment removed successfully");
// 			setTimeout(() => {
// 				setProcessing(false);
// 				onClickOutside();
// 			}, 2000);
// 		} catch (err) {
// 			setProcessing(false);
// 			setErrorObj(err);
// 		}
// 	};

// 	const handleDeletePayment = (e) => {
// 		e.preventDefault();
// 		let confirmDelete;
// 		if (userIsPayer) {
// 			confirmDelete = await dialog.confirm(
// 				"Are you sure you want to remove this payment?"
// 			);
// 			if (confirmDelete) {
// 				return handlePayerDeletePayment();
// 			} else return false;
// 		} else {
// 			if (
// 				payment.type === "stripe" &&
// 				payment.refunded &&
// 				parseFloat(payment.amount_refunded) === parseFloat(payment.amount)
// 			) {
// 				confirmDelete = await dialog.confirm(
// 					"Are you sure you want to remove this payment?"
// 				);
// 				if (confirmDelete) {
// 					return handlePayerDeletePayment();
// 				} else return false;
// 			} else {
// 				confirmDelete = await dialog.confirm(
// 					"Confirm request delete? This will send a notification to the payer asking them to approve this action"
// 				);
// 			}

// 			if (confirmDelete) {
// 				const notification = {
// 					additional: {
// 						paymentData: payment,
// 					},
// 					// date: new Date().toISOString(),
// 					docLink: {
// 						name: `Invoice #${editedInv.invNumber} ${editedInv.invShortHand}`,
// 						pathname: `/${editedInv.contractor.companyName || "invoices"}/${
// 							editedInv.id
// 						}`,
// 						externamLink: "",
// 					},
// 					forDocumentCollection: "invoices",
// 					forDocumentId: editedInv.id,
// 					forUsers: [payment.payer],
// 					// linkTo: collectionName === "projects" ? `/${collectionName}/${parentResource.id}` : `/${parentResource.contractor.companyName || collectionWithNoS}/${parentResource.id}`,
// 					// message: "",
// 					sentBy: currentUser.uid,
// 					sentByUsername: userObject.username,
// 					type: "deletePayment",
// 				};
// 				return sendNotification(notification)
// 					.then(() => {
// 						// onClickOutside()
// 						handleSetSuccessText(
// 							"Payment entry will be deleted upon payers approval",
// 							6000
// 						);
// 					})
// 					.catch((err) => setErrorObj(err));
// 			}
// 		}
// 	};

// 	const onClickOutside = () => {
// 		setProcessing(false);
// 		setVerifiedPaymentModalData((verifiedPaymentModalData) => ({
// 			...verifiedPaymentModalData,
// 			open: false,
// 		}));
// 	};

// 	// invoice owner cannot change:
// 	// amount
// 	// created
// 	// payer
// 	const inputs = [
// 		{
// 			custom: true,
// 			label: "Payment Type",
// 			visible: userIsReceiver,
// 			properties: {
// 				onChange: handleChange,
// 				type: "dropdown",
// 				optionVal: "name",
// 				name: "type",
// 				value: newPaymentData.type,
// 				options: [
// 					{ name: "cash" },
// 					{ name: "etransfer" },
// 					{ name: "cheque" },
// 					{ name: "card" },
// 				],
// 			},
// 		},
// 		{
// 			label: "Item Name",
// 			visible: userIsReceiver,
// 			onChange: handleChange,
// 			properties: {
// 				type: "text",
// 				id: "paymentName",
// 				value: newPaymentData.paymentName,
// 			},
// 		},
// 		{
// 			label: "Amount Received",
// 			visible: userIsReceiver,
// 			onChange: handleChange,
// 			properties: {
// 				type: "number",
// 				id: "amountReceived",
// 				value: newPaymentData.amountReceived,
// 			},
// 		},
// 		{
// 			label: "Total You Paid",
// 			visible: userIsPayer,
// 			onChange: handleChange,
// 			properties: {
// 				type: "number",
// 				id: "amount",
// 				value: newPaymentData.amount,
// 				disabled: payment.status === "needs_confirmation" && !userIsReceiver,
// 			},
// 		},
// 		{
// 			label: "Payment Received date",
// 			visible: userIsReceiver,
// 			onChange: handleChange,
// 			properties: {
// 				type: "date",
// 				id: "receivedDate",
// 				value: newPaymentData.receivedDate,
// 			},
// 		},
// 		{
// 			label: "Discount",
// 			visible: userIsReceiver,
// 			onChange: handleChange,
// 			properties: {
// 				type: "number",
// 				id: "discount",
// 				value: newPaymentData.discount,
// 			},
// 		},
// 		{
// 			visible: userIsReceiver || userIsPayer,
// 			properties: {
// 				type: "hidden",
// 				id: "hiddenInput1",
// 				onChange: () => null,
// 			},
// 			beforeInput: [
// 				<div key="delete-payment">
// 					<div className="section-divider" />
// 					<span className="link-appearance" onClick={handleDeletePayment}>
// 						Delete Payment
// 					</span>{" "}
// 					{
// 						// the owner cannot delete the payment entry unless payer approves
// 						userIsReceiver &&
// 							parseFloat(payment.amount_refunded) !==
// 								parseFloat(payment.amount) && (
// 								<MoreInfoIcon
// 									absolute={true}
// 									text={
// 										"The payment will only be deleted upon the payers approval"
// 									}
// 								/>
// 							)
// 					}
// 				</div>,
// 			],
// 		},
// 		{
// 			visible: userIsReceiver || userIsPayer,
// 			properties: {
// 				type: "hidden",
// 				id: "hiddenInput1",
// 				onChange: () => null,
// 			},
// 			afterInput: [
// 				<React.Fragment key="payment-status">
// 					<div className="section-divider" />
// 					<div className="section-title" >Summary</div>
// 					{payment.receipt_url && (
// 						<div>
// 							<a
// 								style={{ fontSize: "inherit" }}
// 								href={payment.receipt_url}
// 								target="blank"
// 							>
// 								View Receipt
// 								<svg
// 									style={{
// 										height: "1.5ch",
// 										margin: "0 0 0 0.5ch",
// 									}}
// 									viewBox="0 0 32 32"
// 									fill="none"
// 									stroke="currentcolor"
// 									strokeLinecap="round"
// 									strokeLinejoin="round"
// 									strokeWidth="2"
// 								>
// 									<path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
// 								</svg>
// 							</a>
// 							<div className="small-line-break" />
// 						</div>
// 					)}
// 					<p>
// 						Payment Status: <em>{newPaymentData.status}</em>
// 						{newPaymentData.status === "needs_confirmation" && userIsReceiver && (
// 							<React.Fragment>
// 								{" "}
// 								<button
// 									disabled={processing}
// 									className="button-appearance tiny no-icon-or-underline"
// 									onClick={(e) => {
// 										e.preventDefault();
// 										handleChange("status", "succeeded");
// 									}}
// 								>
// 									Approve
// 								</button>
// 							</React.Fragment>
// 						)}
// 					</p>
// 					{
// 						// if user is Contractor, show non editable Amount
// 						userIsReceiver && !userIsPayer && (
// 							<p>
// 								Amount: <em>${getNum(parseFloat(payment.amount) / 100, 2)}</em>
// 							</p>
// 						)
// 					}
// 					{
// 						// user is payer / billTo, show non editable amount received and payment type
// 						userIsPayer && !userIsReceiver && (
// 							<React.Fragment>
// 								<p>
// 									Amount Received:{" "}
// 									<em>
// 										${getNum(parseFloat(payment.amountReceived) / 100, 2)}
// 									</em>
// 								</p>
// 								<p>
// 									Payment Type: <em>{payment.type}</em>
// 								</p>
// 							</React.Fragment>
// 						)
// 					}
// 					{
// 						// user is payer / billTo, show non editable amount received and payment type
// 						userIsPayer && !userIsReceiver && (
// 							<p>
// 								Discount:{" "}
// 								<em>
// 									-${getNum(parseFloat(newPaymentData.discount), 2)}
// 								</em>
// 							</p>
// 						)
// 					}
// 					{
// 						<p>
// 							<strong>Total Applied:{" "}</strong>
// 							<em>
// 								${getNum(parseFloat(newPaymentData.amountReceived) + parseFloat(newPaymentData.discount), 2)}
// 							</em>
// 						</p>
// 					}
// 					<div className="small-line-break" />
// 				</React.Fragment>,
// 			],
// 		},
// 	];

// 	const viewOnlyComponents = [
// 		<div className="align-left" key="viewOnlyComponents">
// 			<div className="heading">Payment Details</div>
// 			<div className="section-divider" />
// 			{payment.receipt_url && (
// 				<div>
// 					<a
// 						style={{ fontSize: "inherit" }}
// 						href={payment.receipt_url}
// 						target="blank"
// 					>
// 						View Receipt
// 						<svg
// 							style={{
// 								height: "1.5ch",
// 								margin: "0 0 0 0.5ch",
// 							}}
// 							viewBox="0 0 32 32"
// 							fill="none"
// 							stroke="currentcolor"
// 							strokeLinecap="round"
// 							strokeLinejoin="round"
// 							strokeWidth="2"
// 						>
// 							<path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
// 						</svg>
// 					</a>
// 					<div className="small-line-break" />
// 				</div>
// 			)}
// 			<p>
// 				Payment Status: <em>{payment.status}</em>
// 			</p>
// 			<p>
// 				Payment Type: <em>{payment.type}</em>
// 			</p>
// 			<p>
// 				{payment.type === "stripe" ? "Total Paid: " : "Amount Paid: "}
// 				<em>${getNum(parseFloat(payment.amount) / 100, 2)}</em>
// 			</p>
// 			{payment.type !== "stripe" && (
// 				<React.Fragment>
// 					<p>
// 						Amount Received:{" "}
// 						<em>${getNum(parseFloat(payment.amountReceived) / 100, 2)}</em>
// 					</p>
// 					{payment.discount && (
// 						<React.Fragment>
// 							Discount:{" "}
// 							<em>-${getNum(parseFloat(payment.discount) / 100, 2)}</em>
// 						</React.Fragment>
// 					)}
// 					<React.Fragment>
// 						<div className="small-line-break" />
// 							<p>
// 							<strong>Total Applied:{" "}</strong>
// 							<em>${getNum((parseFloat(payment.amountReceived) + parseFloat(payment.discount)) / 100, 2)}</em>
// 						</p>
// 						<div className="small-line-break" />
// 					</React.Fragment>
// 				</React.Fragment>
// 			)}
// 			{payment.type === "stripe" && (
// 				<React.Fragment>
// 					{payment.refunded && (
// 						<React.Fragment>
// 							Amount Refunded:{" "}
// 							<em>${getNum(parseFloat(payment.amount_refunded) / 100, 2)}</em>
// 						</React.Fragment>
// 					)}
// 					{(userIsPayer || userIsReceiver) && (
// 						<div>
// 							<div className="small-line-break" />
// 							<span className="link-appearance" onClick={handleDeletePayment}>
// 								Delete Payment
// 							</span>{" "}
// 							{
// 								// the owner cannot delete the payment entry unless payer approves
// 								userIsReceiver &&
// 									parseFloat(payment.amount_refunded) !==
// 										parseFloat(payment.amount) && (
// 										<MoreInfoIcon
// 											absolute={true}
// 											text={
// 												"The payment will only be deleted upon the payers approval"
// 											}
// 										/>
// 									)
// 							}
// 						</div>
// 					)}
// 					{userIsReceiver && !payment.refunded && (
// 						<div>
// 							<div className="section-divider" />
// 							Visit your{" "}
// 							<a
// 								style={{ font: "inherit" }}
// 								target="_blank"
// 								rel="noreferrer"
// 								href="https://dashboard.stripe.com"
// 							>
// 								Stripe.com Dashboard
// 							</a>{" "}
// 							to refund this payment or view more details
// 						</div>
// 					)}
// 				</React.Fragment>
// 			)}
// 		</div>,
// 	];

// 	return (
// 		<ViewPaymentModalStyle isLessThan700px={isLessThan700px}>
// 			<Modal custom={{ absolute: true }} onClickOutside={onClickOutside}>
// 				<AppMessage />
// 				{payment.type === "stripe" || (!userIsReceiver && !userIsPayer) ? (
// 					viewOnlyComponents
// 				) : (
// 					<React.Fragment>
// 						<Form
// 							heading={<div className="heading">Edit Payment</div>}
// 							submitName="Save"
// 							submitDisabled={
// 								processing ||
// 								(!userIsReceiver &&
// 									userIsPayer &&
// 									payment.status === "needs_confirmation")
// 							}
// 							onSubmit={(e) => {
// 								handleUpdatePayment(e);
// 							}}
// 							inputs={inputs}
// 						></Form>
// 						<div className="small-line-break" />
// 						{processing && <SmallSpinner classNames="inverse" />}
// 					</React.Fragment>
// 				)}
// 			</Modal>
// 		</ViewPaymentModalStyle>
// 	);
// };

// export default ViewPaymentModal;
