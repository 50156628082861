import React,  { useEffect, useState } from 'react'
// import { getNum } from '../../utils/appUtils'


export const getUserRating = (user, type) => {
	let overallStars = 0
	let ratingText = ""
	let ratingClassName = ""
	let ratings = []

	if (user[type]) {

		ratings = user[type]
		overallStars = ratings ? ratings.stars : 0


		if (ratings.allRatings === 0) {
			ratingText = "No ratings yet"
			ratingClassName = "no-rating"
		}

		if (ratings.allRatings > 3) {
			if (overallStars > 4) {
				ratingText = "Highly rated"
				ratingClassName = "high-rating"	
			}
		}

		if (ratings.allRatings > 10) {
			if (overallStars > 4) {
				ratingText = "Expert rating"
				ratingClassName = "expert-rating"
			} else if (overallStars > 3.5) {
				ratingText = "Highly rated"
				ratingClassName = "high-rating"
			}
		}

		if (!ratingText) {
			ratingText = `${ratings.allRatings} ${ratings.allRatings > 1 ? "reviews" : "review"}` 
		}

	} else {
		overallStars = 0
		ratingText = "No ratings yet"
		ratingClassName = "no-rating"
	}

	return {
		overallStars,
		ratingText,
		ratingClassName,
		ratings,
	}
}

export const getStars = (overall, starClicked, className) => {
	let stars = []
		for (let i=0; i<5; i++) {
			// 2 + 1 <= 2.9
			if (i + 1 <= Math.round(overall - 0.1)) {
				// push filled color
				stars.push(getStar({key: i, id: `star-icon-${i}`, fill: "orange", onClick: starClicked ? () => starClicked(i) : null}))
			} else if (i > 0 && i < overall) {
				// push a partially filled in star
				stars.push(getStar({key: i, id: `star-icon-${i}`, fill: "orange", onClick: starClicked ? () => starClicked(i) : null}, true))
			} else {
				stars.push(getStar({key: i, id: `star-icon-${i}`, fill: "none", onClick: starClicked ? () => starClicked(i) : null}))
			}
		}
	return (
		<span className={`stars ${className || ""}`}>
			{stars}
		</span>
	)
}

export const getStar = (props, isNotFull) => {
	if (isNotFull) {
		return (
			<svg {...props} fill="url(#grad)" viewBox="0 0 32 32" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		    <path d="M16 2 L20 12 30 12 22 19 25 30 16 23 7 30 10 19 2 12 12 12 Z" />
		    <defs>
					<linearGradient id="grad" x1="0" x2="100%" y1="0" y2="0">
		        <stop offset="50%" stopColor="orange"/>
		        <stop offset="50%" stopColor="transparent"/>
			    </linearGradient>
		    </defs>
			</svg>
		)
	} else {
		return (
			<svg {...props} viewBox="0 0 32 32" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
		    <path d="M16 2 L20 12 30 12 22 19 25 30 16 23 7 30 10 19 2 12 12 12 Z" />
			</svg>
		)
	}
}



// for google maps infoWindow (cant render rreact)
export const getStarsAsHtmlString = (overall, starClicked, className) => {
	let stars = []
		for (let i=0; i<5; i++) {
			// 2 + 1 <= 2.9
			if (i + 1 <= Math.round(overall - 0.1)) {
				// push filled color
				stars.push(getStarAsHtmlString(`key="${i}" id="star-icon-${i}" fill="orange"`))
			} else if (i > 0 && i < overall) {
				// push a partially filled in star
				stars.push(getStarAsHtmlString(`key="${i}" id="star-icon-${i}" fill="orange"`, true))
			} else {
				stars.push(getStarAsHtmlString(`key="${i}" id="star-icon-${i}" fill="none"`))
			}
		}
	return (
		`<span class="${`stars ${className || ''}`}">
			${stars.join("")}
		</span>`
	)
}

export const getStarAsHtmlString = (props, isNotFull) => {
	if (isNotFull) {
		return (
			`<svg ${props} fill="url(#grad)" view-box="0 0 32 32" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		    <path d="M16 2 L20 12 30 12 22 19 25 30 16 23 7 30 10 19 2 12 12 12 Z" />
		    <defs>
					<linear-gradient id="grad" x1="0" x2="100%" y1="0" y2="0">
		        <stop offset="50%" stop-color="orange"/>
		        <stop offset="50%" stop-color="transparent"/>
			    </linear-gradient>
		    </defs>
			</svg>`
		)
	} else {
		return (
			`<svg ${props} viewBox="0 0 32 32" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		    <path d="M16 2 L20 12 30 12 22 19 25 30 16 23 7 30 10 19 2 12 12 12 Z" />
			</svg>`
		)
	}
}

const UserRating = ({userRatings}) => {
	const [overall, setOverallRating] = useState(0)
	const [numberOfReviews, setNumberOfReviews] = useState(0)

	useEffect(() => {
		let newOverallRating = 0
		if (userRatings) {
			const ratingsKeys = Object.keys(userRatings)
			if (ratingsKeys.length) {
				setNumberOfReviews(ratingsKeys.length)
				for (let key in userRatings) {
					const review = userRatings[key]
					newOverallRating += review.overall
				}
				newOverallRating = newOverallRating / ratingsKeys.length
				newOverallRating = Math.round(newOverallRating * 10) / 10
			}
		}
		// if (newOverallRating) {
			setOverallRating(newOverallRating)
		// }

	}, [userRatings])

	// if reviews property is array ...
	// useEffect(() => {
	// 	let newOverallRating = 0
	// 	if (userData.ratings && userData.ratings.length) {
	// 		setNumberOfReviews(userData.ratings.length)
	// 		console.log(userData.ratings)
	// 		userData.ratings.forEach(doc => {
	// 			newOverallRating += doc.overall
	// 			newOverallRating = newOverallRating / userData.ratings.length
	// 			newOverallRating = Math.round(newOverallRating * 10) / 10
	// 		})
	// 		// const ratingsKeys = Object.keys(userData.public.ratings)
	// 		// if (ratingsKeys.length) {
	// 		// 	setNumberOfReviews(ratingsKeys.length)
	// 		// 	for (let key in userData.public.ratings) {
	// 		// 		const review = userData.public.ratings[key]
	// 		// 	}
	// 		// }
	// 	}
	// 	// if (newOverallRating) {
	// 		setOverallRating(newOverallRating)
	// 	// }

	// }, [userData])

	return (
		<React.Fragment>
			{
				// numberOfReviews !== 0 ? 
				// <div className="text tiny">{getNum(overall, 1)}&nbsp;</div>
				// : null
			}
			{getStars(overall)}
			{
				numberOfReviews === 0 ? 
				<div className="text tiny">No ratings yet</div>
				:
				<div className="text tiny">{numberOfReviews > 5 ? `(${numberOfReviews})` : `${numberOfReviews} reviews`}</div>
			}

		</React.Fragment>
	)
}

export default UserRating