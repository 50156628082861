import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";

import Form from "../Forms/Form";
import { AppMessage } from "../MessageUtils";
import SymbolFromId from "../svgComponents/SymbolFromId";
import Spinner from "../Spinner/Spinner";

import firebase from "../../firebase/index";


import {
	getDefaultTopSectionPageOrder,
	defaultPageOrder,
	getBillToDataFromContact,
} from "../Invoice/invoiceUtils";
import {
	formulateSearchKeywords,
	makeRandomString,
	removeDuplicates,
	// providerIsFacebookOrGoogle,
	deepObjectCompareDiffers,
} from "../../utils/appUtils";
import { getLocalISODate } from "../../utils/dateUtils";
import '../FirebaseLoginWithProvider/loginButtonsCss.css'

// rendered by dashboard, specificProject
// errors and success handled
// uses Modal syle for most of css
const NewInvoiceForm = ({ billToUserObj, projectData, modalData }) => {
	let history = useHistory();
	const { projectId } = useParams();
	const {
		userObject,
		updateUserData,
		usersProjects,
		setUsersInvoices,
		canAcceptStripePayments,
		// handleSetMessage,
		// handleSetSuccessText,
		setErrorObj,
		isFetching,
		handleAddContacts,
	} = useContext(UserContext);


	const uselessRefComponentBecauseReactSucks = useRef(false);

	const user = firebase.auth().currentUser;


	let loadingFirebaseIdTimer;

	useEffect(() => {
		uselessRefComponentBecauseReactSucks.current = true;

		return () => {
			if (loadingFirebaseIdTimer) {
				clearTimeout(loadingFirebaseIdTimer);
			}
			uselessRefComponentBecauseReactSucks.current = false;
		};
	}, [loadingFirebaseIdTimer]);

	// useEffect(() => {
	// 	if (user) {
	// 		if (!user.emailVerified && !providerIsFacebookOrGoogle(user)) {
	// 			handleSetMessage(
	// 				<React.Fragment>
	// 					<div className="error-text">
	// 						Sorry, you must verify email to create invoice (this helps prevent
	// 						spam)
	// 					</div>
	// 					<div
	// 						onClick={() => {
	// 							user
	// 								.sendEmailVerification()
	// 								.then(() => {
	// 									handleSetSuccessText("Email verification link sent");
	// 								})
	// 								.catch((err) => setErrorObj(err));
	// 						}}
	// 						className="link-appearance blue"
	// 					>
	// 						Re-send verification link{" "}
	// 					</div>
	// 					to {user.email}
	// 				</React.Fragment>
	// 			);
	// 		}
	// 	}
	// 	return () => {
	// 		handleSetMessage("");
	// 	};
	// 	// want to make sure this error message is only set on first render and not set if other messages set
	// 	// eslint-disable-next-line
	// }, [user]);

	useEffect(() => {
		if (modalData && modalData.type !== "invoices") {
			setErrorObj({ message: "Create item is not type 'invoices'" });
		}
	}, [setErrorObj, modalData]);

	const getNewIndustryOptions = (industries) => {
		return [
			...industries.map((ind) => ({
				name: ind.name,
				rate: ind.rate,
				value: ind.name,
			})),
			{ name: "-- None listed --", rate: "", value: "" },
		];
	};
	
	const industryOptions = getNewIndustryOptions(userObject.industries)

	const [forProjectOptions, setForProjectOptions] = useState([]);
	const [isLoadingSymbol, setIsLoadingSymbol] = useState(false);

	const getProjectOptions = () => {
		if (usersProjects) {
			const userProjectOptionsList = (usersProjects && usersProjects.length) ? usersProjects.map((proj) => ({
				name: proj.projectName || "Project " + (proj.shortHandId || ""),
				id: proj.id,
				disabled: disableInputs,
			})) : []
			if (userProjectOptionsList.length) {
				setForProjectOptions(userProjectOptionsList);
			}
		}
	};

	const blankBillToObj = {
		name: "",
		firstname: "",
		lastname: "",
		address: {
			city: "",
			country: "",
			line1: "",
			line2: "",
			postal_code: "",
			state: "",
		},
		username: "",
		uid: "",
		showAddress: false,
		logoUrl: "",
	}

	let billToObj = { ...billToUserObj, name: blankBillToObj.name || "" };


	if (!billToUserObj) {
		billToObj = {
			...blankBillToObj
		};
	}

	const defaultFormDataContractorObj = {
		companyEmail: userObject.companyEmail, // dont use email as it shouldnt be public
		// address: {
		// 	city: "",
		// 	country: "",
		// 	line1: "",
		// 	line2: "",
		// 	postal_code: "",
		// 	state: "",
		// },

		fullRate: userObject.industries.length ? userObject.industries[0].rate : "",
		id: userObject.uid,
		companyName: userObject.companyName || userObject.firstname + " " + userObject.lastname || userObject.username, // unless user adds company info this will be their name

		// make sure to put there name as default companyName when user signs up
		username: userObject.username,
		phone: userObject.phone || "",
		logoUrl: userObject.logoUrl || "",
		lastInvNumber: userObject.lastInvNumber || 100,
		addressString: (userObject.private && userObject.private.address) ? (userObject.private.address.addressString || "") : "",
		...(userObject.private && userObject.private.address) ? {address: userObject.private.address} : {},
	};

	const currentProjectIfUserHasAccess = (userObject.currentProject && usersProjects && usersProjects.length)
		? usersProjects.find((proj) => proj.id === userObject.currentProject)
		: null;
	const [formData, setFormData] = useState({
		billTo: billToObj,
		contractor: { ...defaultFormDataContractorObj },
		dateOfLastPayment: "",
		dateOfNextPayment: "",
		endWorkTodayAt: "",
		forProject: currentProjectIfUserHasAccess
			? currentProjectIfUserHasAccess.id
			: "",
		industry: userObject.industries.length ? userObject.industries[0].name : "",
		invNumber: parseInt(userObject.lastInvNumber || 100) + 1,
		invShortHand: makeRandomString("", 3),
		liveEntryId: "",
		rate: userObject.industries.length ? userObject.industries[0].rate : "",
		startedWorkTodayAt: "",
		todayBreakTimeMin: 0,
		followers: [],
		editors: [],
		owner: "",
		invoiceTotals: {},
		searchable: false,
		searchKeywords: [],
		version: 1,
	});

	// handle contacts selection
	// const [userContacts, setUserContacts] = useState([
	// 	// {id: "saodanpifd34sdfvdo", name: "John Smith", title: "previous customer", firstname: "John", lastname: "Smith"},
	// 	// {id: "saodanpifd34asdf034", name: "Jill Smith", title: "google contact", firstname: "Jill", lastname: "Smith"},
	// 	// {id: "saodanpifd34cxllkr0e4", name: "Sam Smith", title: "google contact", firstname: "Sam", lastname: "Smith"},
	// 	// {id: "saodanpifd3412351vs", name: "Sally Smith", title: "previous customer", firstname: "Sally", lastname: "Smith"},
	// ])

	const [contactsFilter, setContactsFilter] = useState([]);

	const handleContactsDataList = (
		newFormData,
		contactsFilter,
		setContactsFilter,
		userContacts
	) => {

		// set the suggestions
		const userInput = newFormData.billTo.name
			? newFormData.billTo.name.trim()
			: "";
			// if there is no name yet or a name has been selected
		if (!newFormData.billTo.name || newFormData.billTo.uid) {
			setContactsFilter([]);
			return;
		}

		let newContactsFilter = [];

		[...userContacts].reverse().forEach((obj) => {
			const name = obj.name.trim().toLowerCase();
			// const name = obj.name.toLowerCase();
			// direct hits
			if (name.startsWith(userInput.toLowerCase())) {
				newContactsFilter = [obj, ...newContactsFilter];

				// indirect hits
			} else if (name.includes(userInput.toLowerCase())) {
				newContactsFilter = [...newContactsFilter, obj];
			}
		});

		// if (
		// 	newContactsFilter.length === 1 &&
		// 	// (userInput === newContactsFilter[0].name.toLowerCase())
		// 	(userInput.trim() === newContactsFilter[0]) // must exactly equal name
		// ) {
		// 	setContactsFilter([]);
		// } else {
		if (deepObjectCompareDiffers({a: newContactsFilter, b: contactsFilter})) {

			setContactsFilter(newContactsFilter.slice(0, 6));
		}
		// }
	};

	const handleChange = (nameOrId, value) => {
		if (nameOrId === "fullBillTo") {

			// value is an object
			const userInContacts = (userObject.contacts && userObject.contacts.length) ? userObject.contacts.find(cont => cont.id === value.id) : null
			let newFormData = {}
			if (userInContacts) {
				// if the value name matches exactly a name in the users contacts, add the whole userContact object into billTo
				newFormData = {
					...formData, 
					billTo: getBillToDataFromContact({...formData.billTo, ...value, ...userInContacts})
					// billTo: { ...formData.billTo, ...value, ...userInContacts },
				}

			} else {
				const nameArray = value.name.split(/\s+/) 
				const calcFirstname = nameArray[0]
				const calcLastname = nameArray.length > 1 ? nameArray[nameArray.length - 1] : ""
				newFormData = {
					...formData,
					// clean up values and id in case user previously selected a customer
					billTo: { ...blankBillToObj, id: "", name: value.name || "", firstname: calcFirstname, lastname: calcLastname || ""},
				}
			}

			handleContactsDataList(newFormData, contactsFilter, setContactsFilter, userObject.contacts || []);

			return setFormData(newFormData);

		} else {
			setFormData((formData) => ({ ...formData, [nameOrId]: value }));

			if (nameOrId === "industry") {
				const userIndustry = industryOptions.find((ind) => ind.name === value);
				setFormData((formData) => ({
					...formData,
					rate: userIndustry ? userIndustry.rate : "",
				}));
			}

		}
	};

	// const handleAddressChange = (name, val) => {
	// 	let billToAddress = {
	// 		...formData.billTo.address,
	// 		[name]: val,
	// 	};

	// 	setFormData((formData) => ({
	// 		...formData,
	// 		billTo: { ...formData.billTo, address: billToAddress },
	// 	}));
	// };

	const handleSubmit = async (e, fd) => {
		e.preventDefault();

		// if (disableInputs) {
			if (!user) {
				setErrorObj({
					message: "Must be logged in to create an invoice",
					noReport: true,
				});
			// } else {
			// 	setErrorObj({
			// 		message: "Must verify email to create an invoice",
			// 		noReport: true,
			// 	});
			// }

			return false;
		}

		const newInvoiceDoc = firebase
			.firestore()
			.collection("invoices")
			.doc(fd.id);
		if (fd.invNumber <= parseInt(fd.contractor.lastInvNumber || 100)) {
			setErrorObj({
				message: "Invoice numbers should be greater than the last",
				noReport: true,
			});
			return false;
		}

		// below would update the users contacts to the bill to name but dont do this until we can 
		// add and, update, remove contacts in the users settings .. only using google contacts for now
		// let isNewContact = false
		// // handle new contact
		// if (fd.billTo.name) {
		// 	if (fd.billTo.id) {
		// 		// should only be a new contact if user has typed in contact name, not if contact is imported
		// 		const userInContacts = (userObject.contacts && userObject.contacts.length) ? userObject.contacts.find(cont => cont.id === fd.billTo.id) : false
		// 		if (!userInContacts) {
		// 			isNewContact = true
		// 		}
		// 	} else {
		// 		isNewContact = true
		// 	}	
		// }

		// let newContactObj = isNewContact ? {
		// 	name: fd.billTo.name,
		// 	firstname: fd.billTo.firstname || "",
		// 	lastname: fd.billTo.lastname || "",
		// 	email: fd.billTo.email || "",
		// 	id: firebase.firestore().collection("users").doc(userObject.id).collection("contacts").doc().id,
		// } : ""

		// if theres an error here it is caught in the updateUserData fn
		await updateUserData({ 
			lastInvNumber: fd.invNumber/*, ...isNewContact ? {contacts: [...userObject.contacts || [], newContactObj] } : {} */
		}, false, "NewInvoiceForm - handlesubmit");

		let defaultFollowers = [
			userObject.uid,
			fd.contractor.id,
			fd.billTo.uid,
		].filter((truthyObj) => truthyObj);
		let defaultEditors = [userObject.uid, fd.contractor.id];
		defaultFollowers = Array.from(new Set(defaultFollowers));
		defaultEditors = Array.from(new Set(defaultEditors));

		let paymentMethods = {
			cash: {
				accepted: true,
				discount: "",
			},
			etransfer: {
				accepted: false,
				discount: "",
				email: userObject.email,
			},
			cheque: {
				accepted: false,
				discount: "",
				payableTo: (userObject.firstname + " " + userObject.lastname || "").trim(),
			},
			stripe: {
				accepted: canAcceptStripePayments ? true : false
			}
		}
		if (userObject.paymentMethods) {
			paymentMethods = {...userObject.paymentMethods}
		}

		const newInvoiceData = {
			// if user did not click find... invoke find function first
			...fd,
			accessors: removeDuplicates([
				...defaultEditors,
				fd.contractor.id,
				fd.billTo.uid,
			]),
			id: newInvoiceDoc.id,
			followers: defaultFollowers,
			editors: defaultEditors,
			owner: fd.contractor.id,
			creatorUid: userObject.uid,
			description: `${fd.industry || ""} Invoice for ${fd.billTo.firstname || ""} ${fd.billTo.lastname || ""}`,
			mostRecentEntryDate: getLocalISODate(),
			pageOrder: defaultPageOrder,
			searchKeywords: formulateSearchKeywords({
				doc: fd,
				type: "invoice",
				userObject,
			}),
			topSectionPageOrder: getDefaultTopSectionPageOrder({
				contractor: fd.contractor,
				invNumber: fd.invNumber,
				industry: fd.industry,
				rate: fd.rate,
				billTo: fd.billTo,
				description: fd.billTo.firstname
					? `${fd.industry || ""} Invoice for ${fd.billTo.firstname || ""} ${fd.billTo.lastname || ""}`
					: "",
				invShortHand: fd.invShortHand,
			}),
			// creationTime: firebase.firestore.FieldValue.serverTimestamp(),
			creationTime: firebase.firestore.Timestamp.now().seconds,
			visibility: "public",
			closed: false,
			closedDate: "",
			paymentMethods,
			showTotalCost: true,
			showTotalOwing: true,
		};

		// add invoice to UI usersInvoices in userObject
		setUsersInvoices((usersInvoices) => {
			if (usersInvoices) {
				return [newInvoiceData, ...usersInvoices]
			} else return usersInvoices
		});
		// add actual invoice
		await newInvoiceDoc
			.set(newInvoiceData)
			.catch((err) => setErrorObj(err));
		// redirect user to the new invoice page
		history.push(`${fd.contractor.username}/${newInvoiceDoc.id}`, {isNewDoc: true});
	};

	useEffect(() => {
	  if (userObject.private && userObject.private.address) {
	  	setFormData(formData => ({
	  		...formData,
	  		contractor: {
	  			...formData.contractor,
		  		address: userObject.private.address,
		  		addressString: userObject.private.address.addressString || ""
	  		},
	  	}))
	  }
	}, [userObject])

	useEffect(() => {
		if (projectId) {
			setFormData((formData) => ({ ...formData, forProject: projectId }));
		}

		getProjectOptions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (firebase) {
			setFormData((formData) => ({
				...formData,
				id: firebase.firestore().collection("invoices").doc().id,
			}));
		}
		// eslint-disable-next-line
	}, []);

	// const disableInputs =
	// 	(!firebase.auth().currentUser ||
	// 		!firebase.auth().currentUser.emailVerified) &&
	// 	!providerIsFacebookOrGoogle(user);
	const disableInputs = !firebase.auth().currentUser

	const inputs = [
		{
			custom: true,
			label: "For Project",
			properties: {
				type: "dropdown",
				name: "forProject",
				options: [...forProjectOptions, { name: "no project", id: "" }],
				optionVal: "id",
				value: formData.forProject,
				onChange: handleChange,
				disabled: disableInputs,
			},
		},
		{
			custom: true,
			label: "Work type",
			properties: {
				type: "dropdown",
				name: "industry",
				options: industryOptions,
				optionVal: "value",
				value: formData.industry,
				onChange: handleChange,
				disabled: disableInputs,
			},
		},
		{
			label: "Rate",
			onChange: handleChange,
			properties: {
				type: "number",
				id: "rate",
				value: formData.rate,
				disabled: disableInputs,
			},
			afterInput: [
				<div key="afterRate">
					<div className="section-divider" />
					<div className="section-title">Identification</div>
				</div>,
			],
		},
		{
			label: "Invoice #",
			onChange: handleChange,
			properties: {
				type: "number",
				id: "invNumber",
				value: formData.invNumber,
				required: true,
				disabled: disableInputs,
			},
		},
		{
			label: "3 letter quick ID",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "invShortHand",
				value: formData.invShortHand,
				maxLength: 3,
				minLength: 3,
				required: true,
				disabled: disableInputs,
			},
			afterInput: [
				<div key="symbolFromId" className="invoice align-left">
					<div className="section-divider" />
					<div key="SymbolTitle">Invoice Icon:</div>
					<div className="small-line-break" />
					<div className="symbol-container">
						{isLoadingSymbol && (
							<div className="spinner">
								<Spinner position="relative" height="80px" width="80px" />
							</div>
						)}
						<div>
							<SymbolFromId
								id={formData.id || "loading"}
								maxCoordinate={31}
							/>
						</div>
					</div>
				</div>,
				<div key="generateSymbolId">
					<div className="small-line-break" />
					<button
						className="button-appearance tiny no-icon-or-underline"
						disabled={isLoadingSymbol}
						onClick={(e) => {
							e.preventDefault();
							setIsLoadingSymbol(true);
							setFormData((formData) => ({
								...formData,
								id: firebase.firestore().collection("invoices").doc().id,
							}));
							// make a timeout to prevent spam generation of firebase random ids
							const resTime = Math.round(1000 + 1000 * Math.random());
							loadingFirebaseIdTimer = setTimeout(() => {
								if (uselessRefComponentBecauseReactSucks.current) {
									setIsLoadingSymbol(false);
								}
							}, resTime);
						}}
					>
						Generate
					</button>
				</div>,
				<div key="afterSymbolId" className="section-divider" />,
				<div key="billTo">
					<div className="section-divider" />
					<div className="section-title">Bill To:</div>
				</div>,
			],
		},
		{
			visible: true,
			label: "Name",
			onChange: (name, value) => {
				handleChange(name, {...formData.billTo, id: "", name: value})
			},
			properties: {
				type: "text",
				id: "fullBillTo",
				name: "fullBillTo",
				value: formData.billTo.name,
				autoComplete: "off",
				onFocus: (e) => {
					e.target.placeholder = "Start typing name"
				},
				onBlur: (e) => {
					e.target.placeholder = ""
				},
				// placeholder: "Start typing name",
				// required: ind.rate || (ind.specialties && ind.specialties.length),
				// disabled: !(userObject.hasEarlyAccess || userObject.directlyListed),
			},
			afterInput: [
				<div key="find-contacts" 
					style={{
						position: "relative"
					}}
				>
					{
						firebase.auth().currentUser.providerData.some(obj => obj.providerId === "google.com") &&
						<div>
							<div className="section-divider" />
							<button onClick={(e) => {
									e.preventDefault()
									handleAddContacts()
								}} 
								className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button" 
								data-provider-id="google.com" 
								style={{backgroundColor: "#ffffff"}} 
								data-upgraded=",MaterialButton"
							>
								<span className="firebaseui-idp-icon-wrapper">
									<img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
								</span>
								<span className="firebaseui-idp-text firebaseui-idp-text-long">{(userObject.contacts && userObject.contacts.length) ? "Update" : "Import"} Google Contacts</span>
								<span className="firebaseui-idp-text firebaseui-idp-text-short">{(userObject.contacts && userObject.contacts.length) ? "Update" : "Import"} Contacts</span>
							</button>
							{/*<button className="button-appearance tiny no-icon-or-underline import-contacts" onClick={(e) => {
								e.preventDefault()
								handleAddContacts()
							}}>
								<img alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
								<div>{(userObject.contacts && userObject.contacts.length) ? "Update" : "Import"} Contacts</div>
							</button>*/}
							<div className="section-divider" />
						</div>
					}
					<div className="pac-container pac-logo" 
						style={{
					    display: contactsFilter.length ? "block" : "none",
					    width: "100%",
					    top: "0",
						}}
					>
						{
							contactsFilter.length ? contactsFilter.map((obj, i) => {
								return (
									<div 
										key={i} 
										className="pac-item" 
										onClick={() => {
											handleChange("fullBillTo", {...formData.billTo, ...obj})
											// setContactsFilter([])
										}}
									>
										{
											// Cant just use the url from google, need to download the image
											// (obj.photos && obj.photos[0] && obj.photos[0].url) ? 
											// <span style={{padding: "2px", borderRadius: "50%"}}>
											// 	<img height="20px" width="20px" style={{padding: "5px"}} src={obj.photos[0].url} alt="" />
											// </span>
											// : ""
										}
										<span className="pac-item-query" >{obj.name}</span>
										{
											obj.title ?
											<span>{` (${obj.title})`}</span>
											: ""
										}
										{
											obj.username ? <span>@{obj.username.slice(0, 15)}</span> : ""
										}
									</div>
								)
							})
							: null
						}
					</div>
					<div className="section-divider" />
				</div>
			]
		},
		// {
		// 	label: "Bill to First Name",
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "billToFirstname",
		// 		value: formData.billTo.firstname,
		// 		disabled: disableInputs,
		// 		// list: "user-contacts",
		// 	},
		// },
		// {
		// 	label: "Bill to Last Name",
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "billToLastname",
		// 		value: formData.billTo.lastname,
		// 		disabled: disableInputs,
		// 	},
		// 	afterInput: [
		// 		<React.Fragment key="My Page">
		// 			<div className="section-divider" />
		// 		</React.Fragment>,
		// 	],
		// },
		// {
		// 	label: "City",
		// 	onChange: handleAddressChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "city",
		// 		value: formData.billTo.address.city,
		// 		disabled: disableInputs,
		// 	},
		// 	beforeInput: [
		// 		<React.Fragment key="My Page">
		// 			<div className="section-divider" />
		// 			<div className="section-title">Bill to Address</div>
		// 		</React.Fragment>,
		// 	],
		// },
		// {
		// 	label: "Country",
		// 	onChange: handleAddressChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "country",
		// 		value: formData.billTo.address.country,
		// 		disabled: disableInputs,
		// 	},
		// },
		// {
		// 	label: "Line1",
		// 	onChange: handleAddressChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "line1",
		// 		value: formData.billTo.address.line1,
		// 		disabled: disableInputs,
		// 	},
		// },
		// {
		// 	label: "Line2",
		// 	onChange: handleAddressChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "line2",
		// 		value: formData.billTo.address.line2,
		// 		disabled: disableInputs,
		// 	},
		// },
		// {
		// 	label: "Postal Code",
		// 	onChange: handleAddressChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "postal_code",
		// 		value: formData.billTo.address.postal_code,
		// 		disabled: disableInputs,
		// 	},
		// },
		// {
		// 	label: "State",
		// 	onChange: handleAddressChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "state",
		// 		value: formData.billTo.address.state,
		// 		disabled: disableInputs,
		// 	},
		// 	afterInput: [
		// 		<React.Fragment key="My Page">
		// 			<div className="section-divider" />
		// 		</React.Fragment>,
		// 	],
		// },
	];

	return (
		<Form
			inputs={inputs}
			heading={
				<React.Fragment>
					<div className="heading">Create Invoice</div>
					<AppMessage dependants={[formData]} />
				</React.Fragment>
			}
			submitName="Create"
			onSubmit={(e) => handleSubmit(e, formData)}
		>
		{isFetching && <Spinner position="absolute" />}
		</Form>
	);
};

export default NewInvoiceForm;
