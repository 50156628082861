import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from "styled-components";

import { UserContext } from '../contexts/UserContext'
import { DialogContext } from '../contexts/DialogContext'
import { NotificationsContext } from '../contexts/NotificationsContext'


// import SearchResult from '../components/searchComponents/SearchResult'
// import { handleSearch } from '../components/searchComponents/utils'
import MakePost from "../components/Marketplace/MakePost"
import MarketplaceMap from '../components/Marketplace/MarketplaceMap'
import PostView from '../components/Marketplace/PostView'
import MarketplaceList from '../components/Marketplace/MarketplaceList'

import { findAdminLevel2, fetchContractors, fetchJobs, fetchUsersJobs, filterByIndustry, filterContractorsByIndustry, fetchPost, updatePost, deletePost, getAddressDetails } from '../components/Marketplace/utils'
// import MapControls from '../components/Marketplace/MapControls';

import Modal from "../components/Modal/Modal";

import { deepObjectCompareDiffers, industries, capitalizeFirstLetter } from "../utils/appUtils";
import firebase from "../firebase/index";




const MarketplaceStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	text-align: center;
	.category {
	  padding: 0 5px 1px 5px;
	  margin: 0 5px 0 0;
	}
	.category.highlight {
		background-color: var(--nav-item-hover);
	}

  .full-width-button {
  	text-align: center;
		display: block;
		// width: 100%;
  }
  .blue-fill {
  	color: var(--brand);
  }
	.post-time {
		font-size: var(--font-size-xs);
		color: var(--light-gray);
		.material-icons {
		  font-size: var(--font-size-m);
		  display: inline-block;
		  vertical-align: middle;
		}
	}
	.top-info {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0px 10px;
		border-radius: 5px;
	  box-shadow: var(--brand) 0px 3px 10px -5px;
    font-size: var(--font-size-xs);
	  em {
  	  color: var(--chicago);
	  }
	}

	.search-result.add-top-margin {
		padding-top: 40px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
		text-align: left;	
		position: relative;	
	}
	.img-container {
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
	}
	.result-description {
		> div {
			display: flex;
			justify-content: space-between;
		}

	}

	.user-stars.no-rating {
		.stars { 
			svg {
				fill: var(--nav-item-hover);
				color: var(--nav-item-hover);
			}
		}
	}
	.user-stars.high-rating {
		.rating-text {
			background-color: var(--brand-green);			
	    border-radius: 10px;
	    padding: 0 7px;
		}
	}
	.user-stars.expert-rating {
		.rating-text {
			background-color: var(--brand);			
	    color: white;
	    border-radius: 10px;
	    padding: 0 7px;
		}
	}
	.user-stars {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		text-align: right;
		font-size: var(--font-size-xxs);
    padding: 4px 9px 0 9px;
			background-color: white;
		.stars {
			svg {
				height: 18px;
				width: 18px;
			}
		}
		.rating-text {
			margin-top: -4px;
	    align-self: flex-end;
		}
	}
	.user-location {
		margin-top: 5px;
		font-size: var(--font-size-xs);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
		.location-name {
			text-decoration: underline;
			span {
				margin-top: 1px;
			}
		}
		${({isLessThan700px}) => isLessThan700px ? `
			margin-left: 2px;
		` : `
			position: absolute;
			bottom: 0;
			right: 0;
			margin-right: 1px;
		`}


	}






	.post-job-container {
	  text-align: right;
		button {
			white-space: nowrap;
		}
	}

	.top-section {
		p {
			margin: 0;
		}
	}


`;

// rendered by App.js
const Marketplace = ({ lastMapCenter, setLastMapCenter, isLessThan700px}) => {
	const history = useHistory();
	const mapRef = useRef(null);
	const { lookingFor, postId } = useParams();

	const {
		userObject,
		setErrorObj,
		isAdmin,
		handleSetSuccessText,
		handleSetMessage,
		setLoginModalOpen,
		personalNotifications,
	} = useContext(UserContext)

	const {
		alreadyFetchedUsers, 
		handleSetAlreadyFetchedUsers,
	} = useContext(NotificationsContext);

	const { dialog } = useContext(DialogContext);

	const [makePostModalOpen, setMakePostModalOpen] = useState(false)

	const [contractors, setContractors] = useState([])

	const [jobs, setJobs] = useState([])
	const [map, setMap] = useState(null)
	const [hasMapRef, setHasMapRef] = useState(false)
	const [geocoder, setGeocoder] = useState(null)
	const [isLoadingMarkers, setIsLoadingMarkers] = useState(false)

	const [shouldRelaod, setShouldReload] = useState(false)
	const [idleListener, setIdleListener] = useState(null)
	const [isFetchingPost, setIsFetchingPost] = useState(false)
	const [viewingPost, setViewingPost] = useState(null)
	const [editingPost, setEditingPost] = useState(null)
	const [showFilters, setShowFilters] = useState(false)
	const [filters, setFilters] = useState([])

	const [alreadyQueriedJobs, setAlreadyQueriedJobs] = useState([])
	const [alreadyQueriedJobsAdminLevel2, setAlreadyQueriedJobsAdminLevel2] = useState([])

	const [alreadyQueriedContractors, setAlreadyQueriedContractors] = useState([])
	const [mustPanTo, setMustPanTo] = useState(null)
	const [hasFetchedUsersJobs, setHasFetchedUsersJobs] = useState(false)
	// const [viewingPostChanges, setViewingPostChanges] = useState({})

	// handle map center

	const localStorageMapCenter = localStorage.getItem("marketplaceMapCenter")

	let defaultMapCenter = {
		latlng: {lat: 49.2827291, lng: -123.1207375},
		// latlng: {lat: 49.244823, lng: -122.984969},
		city: "Vancouver",
		state: "BC",
		country: "Canada",
		// regionalDistrict: "",
		fullRegion: "Vancouver, BC, Canada",
		adminLevel2: "Metro Vancouver, BC, Canada"
	}

	let parsedMapCenter = {}
	if (lastMapCenter) { // fallback in case localStorage not working
		parsedMapCenter = lastMapCenter
		defaultMapCenter = parsedMapCenter

	} else {
		if (localStorageMapCenter) {
			try {
				parsedMapCenter = JSON.parse(localStorageMapCenter)
				if (parsedMapCenter.latlng && parsedMapCenter.city) {
					defaultMapCenter = parsedMapCenter
				}
			} catch (err) {
				console.log(err)
			}
		}
	}

	const [mapCenter, setMapCenter] = useState({
		...defaultMapCenter
	})

	// must be in useEffect otherwise "bad set state call"
	useEffect(() => {
		if (parsedMapCenter && mapCenter.city) {
			let storedLat = parsedMapCenter?.latlng?.lat
			let storedLng = parsedMapCenter?.latlng?.lng
			let newLat = mapCenter?.latlng?.lat
			let newLng = mapCenter?.latlng?.lng
			if (storedLat && storedLng && newLat && newLng) {
				storedLat = parseFloat(storedLat)
				storedLng = parseFloat(storedLng)
				newLat = parseFloat(newLat)
				newLng = parseFloat(newLng)
				storedLat = Math.round(storedLat * 10) / 10
				storedLng = Math.round(storedLng * 100) / 100
				newLat = Math.round(newLat * 10) / 10
				newLng = Math.round(newLng * 100) / 100

				if (storedLat !== newLat || storedLng !== newLng) {
					setLastMapCenter(mapCenter) // fallback in case localStorage not working
			  	localStorage.setItem("marketplaceMapCenter", JSON.stringify(mapCenter))
				}
			} else { // set for first time
				if (newLat && newLng) {
					setLastMapCenter(mapCenter) // fallback in case localStorage not working
			  	localStorage.setItem("marketplaceMapCenter", JSON.stringify(mapCenter))
				}
			}
		}

	})


	const [formData, setFormData] = useState({
		city: defaultMapCenter.fullRegion,
		text: "",
		cityCenter: defaultMapCenter,
		adminLevel2: defaultMapCenter.adminLevel2,
	})



	let searchingJobs = !lookingFor || lookingFor.toLowerCase() === "jobs";
	let searchingContractors = !lookingFor || lookingFor.toLowerCase() === "contractors";

	// const handleSetViewingPost = ({newViewingPost}) => {
	// 	setViewingPost(viewingPost => {
	// 		...newViewingPost,
	// 	})
	// }

	const handleSetContractors = (filters, newContractors) => {
		setContractors(newContractors)
		// setFilteredContractors(filterContractorsByIndustry(filters, newContractors))
	}

	const handleFetchContractors = async (filters, contractors, handleSetContractors, mapCenter, map, amount = 10) => {
		try {
		  if (!isLoadingMarkers) {
		  	setIsLoadingMarkers(true)
		  }
		  
		  // const lastDoc = jobs[jobs.length - 1]
		  let freshContractors = []

		  let newAlreadyQueriedContractors = []

		  const fetchedContractors = await fetchContractors({userObject, amount, mapCenter})
		  // newAlreadyQueriedContractors.push(mapCenter.adminLevel2 || mapCenter.fullRegion)
		  newAlreadyQueriedContractors.push(mapCenter.adminLevel2)
		  let newAlreadyFetchedUsers = [...alreadyFetchedUsers,  ...fetchedContractors]

		  freshContractors = fetchedContractors

		  if (postId) {
			  if (!freshContractors.find(c => c.id === postId)) {
	    		// try to find contractor
	    		const viewingContractor = await firebase.firestore().collection("users").doc(postId).get().then(doc => {
	    			if (doc.exists) {
	    				return {
			    			...doc.data(), 
			    			id: doc.id
	    				}
	    			} else return null
	    		}).catch(err => {
	    			// couldnt find that user
	    			return null
	    		})

	    		if (viewingContractor) {
	    			// freshContractors.push(viewingContractor)
	    			if (viewingContractor.generalLocation) {
  	    			setMustPanTo(viewingContractor.generalLocation)
	    			}
	    		} else {
	    			handleSetSuccessText("Contractor not found in map")
	    			changePostId(null)
	    		}
			  }
		  	
		  }

			let oldContractors = contractors.filter(cont => {
				const contractorInFreshContractors = freshContractors.find(c => c.id === cont.id)
				if (contractorInFreshContractors) {
					return false
				} else {
					return true
				}
			})


			const newContractors = [...oldContractors, ...freshContractors]

			handleSetContractors(filters, newContractors)
			setAlreadyQueriedContractors(newAlreadyQueriedContractors)
			handleSetAlreadyFetchedUsers({overWrite: true, newUsers: [newAlreadyFetchedUsers], caller: "Marketplace - handleFetchContractors"})
			setIsLoadingMarkers(false)
		} catch (err) {
			setErrorObj(err)
			setIsLoadingMarkers(false)
		}
	}

	const changePostId = (id, type) => {
		let newPath
		if (id) {
			if (postId) {
				newPath = history.location.pathname.replace(postId, id)
			} else {

				if (type) {
					// if (!history.location.pathname.includes()) {
						newPath = `/marketplace/${type}/${id}`
					// }
				}
			}
			newPath = newPath.replace("//", "/") // in case an extra / added
			history.replace(newPath)
		} else {
			// newPath = history.location.pathname.replace("/" + postId, "").replace("//", "/")
			// todo need to just go back when closing modals
			history.replace("/marketplace")
		}

	}

	const handleOpenEditPostModal = (postData) => {
		if (postData) {
			setEditingPost(postData)
		}

		setMakePostModalOpen(true)
	}

	const handleSetJobs = (filters, newJobs) => {
		setJobs(newJobs)
	}

	const handleChangeCity = ({val, place, handleChange, setMapCenter, newMap}) => {
		if (!place) {
	    handleChange("city", {
	    	city: val, 
	    	// place_id: "",
	    	latlng: {lat: null, lng: null}
	    })

	    return
		}

		let city
		let state
		let country
		let fullRegion
		let latlng
		let adminLevel2


		if (place.fullRegion && place.latlng) {
			fullRegion = place.fullRegion
			latlng = place.latlng
			const addressComponents = place.fullRegion.split(",")

			city = (place.city || addressComponents[0] || "").trim()
			state = (place.state || addressComponents[1] || "").trim()
			country = (place.country || addressComponents[2] || "").trim()

		} else {
			if (place && place.geometry && place.geometry.location && place.geometry.location.lat) {
				const response = getAddressDetails(place)
				city = response.city
				state = response.state
				country = response.country
				fullRegion = response.fullRegion
				latlng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
			} else {
		    handleChange("city", {
		    	city: val, 
		    	// place_id: "",
		    	latlng: {lat: null, lng: null}
		    })

		    return
			}
		}

		if (place.adminLevel2) {
			adminLevel2 = place.adminLevel2
		} else {
			if (state && country) {
				const level2Name = findAdminLevel2([{...place}]) 
				if (level2Name) {
					adminLevel2 = level2Name + ", " + state + ", " + country
				} else { // defualt to fullRegion
					adminLevel2 = fullRegion
				}
			}
		}

    handleChange("city", {
    	city: fullRegion, 
    	latlng,
    	adminLevel2
    })

    const newMapCenter = {
			latlng,
  		city,
  		state,
  		country,
  		fullRegion,
  		adminLevel2
    }

    setMapCenter(newMapCenter)
    if (newMap) {
    	newMap.panTo(latlng)
    	
    } else {
	  	map && map.panTo(latlng)
    }

  	return newMapCenter

	}


	const handleUpdatePost = ({newPostData, userObject, isUsersFirstInteraction, handleSetJobs, filters, doNotPan}) => {
		// increment version first
		if (newPostData.version) {
			newPostData.version = parseInt(newPostData.version) + 1
		} else {
			newPostData.version = 2
		}
		if (!newPostData.id) {
			newPostData.id = firebase.firestore().collection("marketPosts").doc().id
		}

		return updatePost({newPostData, userObject, isUsersFirstInteraction, personalNotifications}).then(() => {
			// handleSetSuccessText("updated successfully")
			// find the post in jobs
			// if post is the current post in view update thet too
			const foundInJobs = jobs.find(j => j.id === newPostData.id)
			let newJobs

			if (viewingPost && viewingPost.id === newPostData.id) {
				setViewingPost(viewingPost => ({
					...viewingPost,
					...newPostData
				}))	
			}

			if (foundInJobs) {
				newJobs = jobs.map(j => {
					if (j.id === newPostData.id) {
						return {
							...j,
							...newPostData
						}
					} else return j
				})

			} else {
				// add to jobs
				newJobs = [
					...jobs,
					newPostData
				]
			}

			if (!newPostData.adminLevel2) {
				console.warn("no adminLevel2")
			}

			// set main formdata
			const place = {
				fullRegion: newPostData.city,
				latlng: newPostData.generalLocation,
				adminLevel2: newPostData.adminLevel2 || newPostData.city
			}

			// only update jobs in UI if they wont be updated since a query for this new city has already been made
			// handleChangeCity will trigger a panTO which will run fetchJobs if alreadyQueriedJobs array doent include this new city
			if (alreadyQueriedJobs.includes(place.adminLevel2)) {
				handleSetJobs(filters, newJobs)
			} // else do not set jobs since we are running panTo which will trigger fetchJobs

		// in marketplace list we dont want to pan or do anything to the ui because it will alter the list and look glitchy
			if (doNotPan) {
				handleSetJobs(filters, newJobs)
			} else {
				// set looking for to all if viewing contractors only
				if (lookingFor === "contractors") {
					changePostId(null, null)
				}
				handleChangeCity({val: place.fullRegion, place, handleChange, setMapCenter})				
			}

		}).catch(err => {
			console.log({err})
			setErrorObj(err)
		})
	}

	const handleDeletePost = async ({postData, userObject}) => {
		const confirm = await dialog.confirm(
			"Are you sure you want to delete this post?"
		);
		if (confirm) {
			return deletePost({postData, userObject}).then(() => {

				// if (viewingPost.id === postData.id) {
				// 	setViewingPost(viewingPost => ({
				// 		...viewingPost,
				// 		...postData
				// 	}))	
				// }

				let newJobs = jobs.filter(j => j.id !== postData.id)
					// setJobs(newJobs)
					// setFilteredJobs(filterByIndustry(filters, newJobs))
				handleSetJobs(filters, newJobs)

				closeEditPostModal()
				// setPostModalOpen(false)
				changePostId(null)
				handleSetSuccessText("Post deleted successfully")

			}).catch(err => {
				setErrorObj(err)
			})

		} else return false
	}

	const handleFetchJobs = async (filters, jobs, handleSetJobs, mapCenter, map, alreadyQueriedJobs, alreadyQueriedJobsAdminLevel2, amount = 20) => {
		try {
		  // const lastDoc = jobs[jobs.length - 1]
			let shouldFetchNewJobs = !alreadyQueriedJobs.includes(mapCenter.fullRegion)
			let shouldFetchJobsNearby = false
			let extendedSearchQueryFinished = false
			let existingQueryExtendedSearch = null
			let newLastDoc = null
			// if weve already queried all jobs in this city, search in adminLevel2
			if (!shouldFetchNewJobs) {
				if (!mapCenter.adminLevel2) {
					console.error("No mapCenter.adminLevel2")
				}
				existingQueryExtendedSearch = alreadyQueriedJobsAdminLevel2.find(query => query.adminLevel2 === mapCenter.adminLevel2)
				shouldFetchJobsNearby = !existingQueryExtendedSearch || !existingQueryExtendedSearch.extendedSearchQueryFinished
			}

			if (shouldFetchNewJobs || shouldFetchJobsNearby) {
			  let freshJobs = []
			  let newMapCenter

			  if (!isLoadingMarkers) {
			  	setIsLoadingMarkers(true)
			  }
			  const viewingPostNeedsUpdating = (!viewingPost || !viewingPost.id || !viewingPost.id !== postId)
	    	// first see if the post id can be found in posts
	    	if (postId && jobs.length) {
	  	  	const inJobs = jobs.find(j => j.id === postId)
	  	  	if (inJobs && viewingPostNeedsUpdating) {
				  	setViewingPost(inJobs)
	  	  	}
	    	}

	    	if (postId && !jobs.length && !isLoadingMarkers && viewingPostNeedsUpdating) {
  	  		if (!isFetchingPost) {

  	  			setIsFetchingPost(true)
						const post = await fetchPost({postId})
						if (!post) {
							setViewingPost({id: "not-found"})
						} else {
							freshJobs.push(post)
							setViewingPost(post)
					  	// change the mapCenter so that when we fetch jobs below we fetch all the jobs 
					  	// for the region that or city this post is in
					  	const place = {
					  		fullRegion: post.city,
					  		latlng: post.generalLocation,
					  		adminLevel2: post.adminLevel2 || post.city
					  	}
					  	newMapCenter = handleChangeCity({val: post.fullRegion, place, handleChange, setMapCenter, newMap: map })
						}
		  			setIsFetchingPost(false)
  	  		}

	    	}

	    	if (!newMapCenter) {
	    		newMapCenter = mapCenter
	    	}


			  let jobsInLocation = []
			  if (shouldFetchNewJobs) {
				  const search = await fetchJobs({userObject, amount, mapCenter: newMapCenter, isAdmin})
				  jobsInLocation = search.docs
			  }

		  	if (shouldFetchJobsNearby) {
					const lastDoc = existingQueryExtendedSearch ? existingQueryExtendedSearch.lastDoc : null

				  const search = await fetchJobs({userObject, lastDoc, amount, mapCenter: newMapCenter, extendedSearch: true, isAdmin})
				  const extendedSearch = search.docs
				  newLastDoc = search.lastDoc

				  extendedSearchQueryFinished = search.queryFinished

				  if (jobsInLocation.length) {
				  	// remove all old jobs
				  	jobsInLocation = jobsInLocation.filter(j => !extendedSearch.find(fetched => fetched.id === j.id))
				  }
				  // const removeDuplicatesJobsInLocation = jobsInLocation.filter(j => !freshJobs.find(fetched => fetched.id === j.id))
				  jobsInLocation = [...jobsInLocation, ...extendedSearch]

		  	}

		  	// fetch all jobs that user has posted
	    	if (userObject.id && !hasFetchedUsersJobs /*|| mustUpdateUsersJobs*/) {
	    		const usersJobs = await fetchUsersJobs({userObject})

	    		// fresh jobs here is still only users jobs
					freshJobs = freshJobs.filter(j => !usersJobs.find(fetched => fetched.id === j.id))
					freshJobs = [...usersJobs, ...freshJobs]
	    		setHasFetchedUsersJobs(true)

	    	}

			  const removeDuplicatesJobsInLocation = jobsInLocation.filter(j => !freshJobs.find(fetched => fetched.id === j.id))

			  freshJobs = [...freshJobs, ...removeDuplicatesJobsInLocation]

				let oldJobs = jobs.filter(job => {
					const jobInFreshJobs = freshJobs.find(j => j.id === job.id)
					if (jobInFreshJobs) {
						return false
					} else {
						return true
					}
				})

				const newJobs = [...oldJobs, ...freshJobs]

				handleSetJobs(filters, newJobs)

			  if (shouldFetchNewJobs) {
					setAlreadyQueriedJobs(alreadyQueriedJobs => [...alreadyQueriedJobs, mapCenter.fullRegion])
			  }


			  if (shouldFetchJobsNearby) {
			  	let newAlreadyQueriedJobsAdminLevel2 = alreadyQueriedJobsAdminLevel2.filter(query => query.adminLevel2 !== newMapCenter.adminLevel2)
			  	newAlreadyQueriedJobsAdminLevel2 = [
			  		...newAlreadyQueriedJobsAdminLevel2, 
			  		{
			  			// ...queryInAlreadyQueried add if you dont want to overwrite old query data
				  		adminLevel2: newMapCenter.adminLevel2,
		  				extendedSearchQueryFinished,
		  				lastDoc: newLastDoc
		  			}
	  			]

			  	setAlreadyQueriedJobsAdminLevel2(newAlreadyQueriedJobsAdminLevel2)
			  }

				setIsLoadingMarkers(false)

			} else {
		  	// fetch all jobs that user has posted
	    	if (userObject.id && !hasFetchedUsersJobs /*|| mustUpdateUsersJobs*/) {
	    		const usersJobs = await fetchUsersJobs({userObject})

				  const removeDuplicatesJobsInLocation = jobs.filter(j => !usersJobs.find(fetched => fetched.id === j.id))

					const newJobs = [...usersJobs, ...removeDuplicatesJobsInLocation]

					handleSetJobs(filters, newJobs)
	    		setHasFetchedUsersJobs(true)
	    	}

			}


		} catch (err) {
			setErrorObj(err)
			setIsLoadingMarkers(false)
		}
	}


	const handleLoad = async ({filters, jobs, handleSetJobs, contractors, handleSetContractors, geocoder, map, mapCenter, handleChange, alreadyQueriedJobs, alreadyQueriedContractors, searchingJobs, searchingContractors}) => {
  	const currentCenter = {lat: map.center.lat(), lng: map.center.lng()} 

		let newMapCenter = {
			latlng: currentCenter
		}

    geocoder.geocode({ location: currentCenter }, (results, status) => {
	    	// geocoder docs, error codes
	    	// https://developers.google.com/maps/documentation/javascript/reference/geocoder

	      if (status === "OK") {

		    	const resultNeeded = results[0]

					// caution: may end up being 'null, BC, canada'

	        if (resultNeeded) {
						const {city, state, country, fullRegion} = getAddressDetails(resultNeeded)
						let adminLevel2
						if (state && country) {
							const level2Name = findAdminLevel2(results) 
							if (level2Name) {
								adminLevel2 = level2Name + ", " + state + ", " + country
							} else { // defualt to fullRegion
								adminLevel2 = fullRegion
							}
						}

						// if the getAddress details fails on a any parameters, dont overwrite them
						// if city fails in getAddressDetails this will couse state country and fullRegion to fail
    				newMapCenter = {
    					...mapCenter,
    					...city ? {city} : {}, 
    					...state ? {state} : {}, 
    					...country ? {country} : {}, 
    					...fullRegion ? {fullRegion} : {},
							...resultNeeded.geometry?.location?.lat ? {latlng: {lat: resultNeeded.geometry.location.lat(), lng: resultNeeded.geometry.location.lng()}} : {},
							...adminLevel2 ? {adminLevel2} : {} // middle of ocean may not have state and country
    				}


						let shouldUpdateMap = deepObjectCompareDiffers({a: mapCenter, b: newMapCenter})

						if (searchingContractors) {
							if (!shouldUpdateMap && !alreadyQueriedContractors.includes(newMapCenter.adminLevel2)) {
								shouldUpdateMap = true
							}
	    				if (!alreadyQueriedContractors.includes(newMapCenter.adminLevel2)) {
								handleFetchContractors(filters, contractors, handleSetContractors, newMapCenter, map)
	    				}

	    				// add in userws jobs ... could just do this once whether fetching searchingJobs or not and just pass it as the jobs param...?
	    				if (!searchingJobs) {
						  	// fetch all jobs that user has posted only if not searching jobs already
					    	if (userObject.id && !hasFetchedUsersJobs) {
					    		fetchUsersJobs({userObject}).then(usersJobs => {
					    			// filter out any duplicates out of oldJobs
						  			const oldJobs = jobs.filter(job => {
						  				const jobInUsersJobs = usersJobs.find(j => j.id === job.id)
						  				if (jobInUsersJobs) {
						  					return false
						  				} else {
						  					return true
						  				}
						  			})

						  			const newJobs = [...oldJobs, ...usersJobs]

						  			handleSetJobs(filters, newJobs)

						    		setHasFetchedUsersJobs(true)
						    		shouldUpdateMap = true
					    		}).catch(err => {
					    			console.error(err)
					    		})

					    	}
	    				}
						}

						if (searchingJobs) {
							if (!shouldUpdateMap && !alreadyQueriedJobs.includes(newMapCenter.fullRegion)) {
								shouldUpdateMap = true
							}
	    				// shouldUpdateJobs = !alreadyQueriedJobs.includes(newMapCenter.fullRegion)
	    				// if (shouldUpdateJobs) {
								handleFetchJobs(filters, jobs, handleSetJobs, newMapCenter, map, alreadyQueriedJobs, alreadyQueriedJobsAdminLevel2, 20)
	    				// }
						}

						if (shouldUpdateMap/* || shouldUpdateJobs || shouldUpdateContractors*/) {
							setMapCenter(newMapCenter)
							// setShouldFetchNewJobs(true)
							// change the location input "near" city
							handleChange("city", {
								city: newMapCenter.fullRegion, 
								// place_id: resultNeeded.place_id,
								latlng: newMapCenter.latlng,
								adminLevel2: newMapCenter.adminLevel2 || newMapCenter.fullRegion
							})
						} else {
							// setShouldFetchNewJobs(false)
						}

	        } else {
	          console.log("No results found");
	        }
	      } else {
	        console.log("Geocoder failed due to: " + status);
	      }
	    })

	}

	const handleIndustryDatalist = (text, industries, searchingJobs, searchingContractors) => {
		// set the suggestions
		const userInput = text
			? text.toLowerCase().trim()
			: "";
		if (!text) {
			return [];
		}

		if (searchingContractors || searchingJobs) {
			let newIndustriesFilter = [];
			if (!userInput) {
				newIndustriesFilter = [...industries];
			} else {
				[...industries].reverse().forEach((obj) => {
					const title = obj.title.toLowerCase();
					const name = obj.name.toLowerCase();
					// direct hits
					if (name.startsWith(userInput) || title.startsWith(userInput)) {
						newIndustriesFilter = [obj, ...newIndustriesFilter];

						// indirect hits
					} else if (name.includes(userInput) || title.includes(userInput)) {
						newIndustriesFilter = [...newIndustriesFilter, obj];
					}
				});
			}

			if (
				newIndustriesFilter.length === 1 &&
				(userInput === newIndustriesFilter[0].name.toLowerCase() ||
					userInput === newIndustriesFilter[0].title.toLowerCase())
			) {
				return [];
			} else {
				return newIndustriesFilter.slice(0, 6);
			}
		}
	};

	let industriesFilter = handleIndustryDatalist(formData.text, industries, searchingJobs, searchingContractors);

	const handleChange = (name, val) => {
		let newFormData = {...formData}
		let newVal = val
		if (name === "city") {
			// newFormData.place_id = val.place_id
			newVal = val.city

			newFormData.cityCenter = val.latlng
			newFormData.adminLevel2 = val.adminLevel2
			
		}

		newFormData[name] = newVal

		setFormData(newFormData)

	}


  if (window.google && window.google.maps && !map && mapRef.current) {
		const options = {
			mapId: "f75da1bbb92b19cb",
			center: mapCenter.latlng, 
			zoom: 12,
			disableDefaultUI: true,
			gestureHandling: isLessThan700px ?  "greedy" : "auto",
			clickableIcons: false,
			fullscreenControl: true,
		}
		const newMap = new window.google.maps.Map(mapRef.current, options)

		const newGeoCoder = new window.google.maps.Geocoder()
    setGeocoder(newGeoCoder)
		handleLoad({filters, jobs, handleSetJobs, contractors, handleSetContractors, geocoder: newGeoCoder, map: newMap, mapCenter, handleChange, alreadyQueriedJobs, alreadyQueriedContractors, searchingJobs, searchingContractors})
    setMap(newMap)
  }

  if (shouldRelaod) {
		handleLoad({filters, jobs, handleSetJobs, contractors, handleSetContractors, geocoder, map, mapCenter, handleChange, alreadyQueriedJobs, alreadyQueriedContractors, searchingJobs, searchingContractors})
		setShouldReload(false)
  }

	useEffect(() => {
	  if (map && geocoder && mapCenter) {
	  	setIdleListener(
		  	map.addListener("idle", () => {
					// check if there is an idleListener and only reload if there is one already 
					// this prevents running fetchJobs twice on render
					if (idleListener) {
						// cant use alreadyQueriedJobs here because it is first always an empty array for some reason
						setShouldReload(true)
					}
			  })
		  )
	  	
	  }
	  return () => {
	  	if (idleListener) {
		  	window.google.maps.event.removeListener(idleListener)
	  	}
	  }
	  // disable idleListener since it would trigger infinate
	  // eslint-disable-next-line
	}, [geocoder, map, mapCenter])


	// this will trigger a rerender once mapref.current which will allow the main handleLoad function to run
	useEffect(() => {
	  if (mapRef.current && !hasMapRef) {
	  	setHasMapRef(true)
	  }
	}, [hasMapRef])

	useEffect(() => {
	  if (userObject.id && map) {		
	  	setShouldReload(true)
	  }
	}, [userObject.id, lookingFor, map])

	const closeEditPostModal = () => {
		setMakePostModalOpen(false)
		setEditingPost({})
	}

	const filteredJobs = searchingJobs ?  filterByIndustry(filters, jobs) : []

	const filteredContractors = searchingContractors ?  filterContractorsByIndustry(filters, contractors) : []

	let allFiltered = [...filteredJobs.map(j => ({...j, markerType: "job"})), ...filteredContractors.map(c => ({...c, markerType: "contractor"}))]


	useEffect(() => {
		if (mustPanTo && map) {
			map.panTo(mustPanTo)
			setMustPanTo(null)
		}
	}, [mustPanTo, map])

	// handle timeout error
	useEffect(() => {
		let loadingTimeout = null
	  if (isLoadingMarkers) {
	  	loadingTimeout = window.setTimeout(() => {
	  		setIsLoadingMarkers(false)
	  	}, 7000)
	  }

	  return () => {
	  	if (loadingTimeout) {
	  		window.clearTimeout(loadingTimeout)
	  	}
	  }
	}, [isLoadingMarkers])


	const usersJobs = (userObject.id && jobs.length) ? jobs.filter(j => j.userId === userObject.id) : []

	return (
		<MarketplaceStyle isLessThan700px={isLessThan700px} >
			{
				makePostModalOpen ? 
				<Modal
	   			custom={{ absolute: true, style: {zIndex:101} }}
	   			onClickOutside={closeEditPostModal}
	   		>
	   		<MakePost 
  				handleDeletePost={handleDeletePost}
		   		handleUpdatePost={handleUpdatePost} 
		   		handleSetJobs={handleSetJobs}
		   		filters={filters}
		   		userObject={userObject} 
		   		mainFormData={formData} 
		   		industries={industries} 
		   		handleIndustryDatalist={handleIndustryDatalist} 
		   		searchingJobs={searchingJobs}
		   		searchingContractors={searchingContractors}
		   		capitalizeFirstLetter={capitalizeFirstLetter} 
		   		postType="job" 
		   		postData={editingPost}
		   		onClickOutside={closeEditPostModal}
	   		/>
       </Modal>
       : ""
			}
  		{
	  		(postId && viewingPost && viewingPost.id) ?
				<Modal
					custom={{ absolute: true }}
					onClickOutside={() => {
						changePostId(null)
					}}
				>
	  			<PostView 
						alreadyFetchedUsers={alreadyFetchedUsers}
						handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers}
	  				postId={postId}
	  				handleDeletePost={handleDeletePost}
		  			handleUpdatePost={handleUpdatePost} 
			   		handleSetJobs={handleSetJobs}
			   		filters={filters}
		  			handleOpenEditPostModal={handleOpenEditPostModal} 
		  			changePostId={changePostId}
		  			key={postId} 
		  			viewingPost={viewingPost} 
		  			setViewingPost={setViewingPost} 
	  			/>
  			</Modal>
  			: ""
  		}
			<div className="main-container" >
				{
					userObject.id &&
					<div className="post-job-container">
						<button className="button-appearance" onClick={() => handleOpenEditPostModal()} >Post a job</button>
					</div>
				}
					<div className="section-divider" />
				<div className="top-section">
					<p>
						I'm Looking For 
						<br />
						<span className={`category ${(lookingFor && searchingJobs) ? "highlight" : ""}`}>
							<Link onClick={() => {
								setShouldReload(true)

							}} to="/marketplace/jobs">Jobs</Link>
						</span> | <span className={`category ${(lookingFor && searchingContractors) ? "highlight" : ""}`}>
							<Link onClick={() => {
								setShouldReload(true)

							}} to="/marketplace/contractors">Contractors</Link>
						</span> | <span className={`category ${!lookingFor ? "highlight" : ""}`}>
							<Link onClick={() => {
								setShouldReload(true)
	
							}} to="/marketplace">All</Link>
						</span>
					</p>
				</div>
				<div className="section-divider" />

				<MarketplaceMap
					isLessThan700px={isLessThan700px}
					postId={postId}
					contractors={contractors}
					filteredContractors={filteredContractors}
					searchingContractors={searchingContractors}
					searchingJobs={searchingJobs}
					filteredJobs={filteredJobs}
					allFiltered={allFiltered}
					filters={filters}
					changePostId={changePostId}
					viewingPost={viewingPost} 
					setViewingPost={setViewingPost} 
					isLoadingMarkers={isLoadingMarkers} 
					setIsLoadingMarkers={setIsLoadingMarkers} 
					map={map} 
					jobs={jobs} 
					mapCenter={mapCenter} 
					setErrorObj={setErrorObj} 
					userObject={userObject}

					showFilters={showFilters} 
					setShowFilters={setShowFilters}
					handleChange={handleChange}
					formData={formData}
					industriesFilter={industriesFilter}
					setFilters={setFilters}
					handleChangeCity={handleChangeCity} 
					setMapCenter={setMapCenter}
				>
		  		<div ref={mapRef} id="map" />
				</MarketplaceMap>
				<div className="section-divider" />
				<div className="section-divider" />
				<MarketplaceList 
					usersJobs={usersJobs}
					searchingJobs={searchingJobs}
					jobs={jobs}
					handleDeletePost={handleDeletePost}
					handleUpdatePost={handleUpdatePost}
					handleSetJobs={handleSetJobs}
					handleOpenEditPostModal={handleOpenEditPostModal}
					userObject={userObject}
					setLoginModalOpen={setLoginModalOpen}
					handleSetMessage={handleSetMessage}
					handleSetSuccessText={handleSetSuccessText}
					filters={filters}
					setViewingPost={setViewingPost}
					alreadyFetchedUsers={alreadyFetchedUsers}
					handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers}
				/>
			</div>
		</MarketplaceStyle>
	)
}

export default Marketplace;