import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { UserContext } from '../../contexts/UserContext'

import Modal from '../Modal/Modal'
// import Form from '../Forms/Form'
// import { AppMessage } from '../MessageUtils'
import { ProfileReviewStyle } from './ProfileReviewStyle.styled'
import MoreInfoIcon from '../MoreInfoIcon'
// import LeaveReviewForm from './LeaveReviewForm'

import { getStars } from './UserRating'
import { getNum } from '../../utils/appUtils'
// import firebase from '../../firebase/index'

const ProfileReview = ({resultUserData, userRatings, setUserRatings, reviewModalOpen, setReviewModalOpen}) => {
	// const location = useLocation()
	const {
		currentUser,
		// userObject,
		isFetching
	} = useContext(UserContext)

	const getReviewComponents = () => {
		// if (resultUserData.public && resultUserData.public.ratings) {
		if (userRatings) {
			let ratingsComponents = []
			// const ratings = resultUserData.ratings
			for (var key in userRatings) {
		    // console.log(key, ratings[key])
		    const rating = userRatings[key]
		    let reviewTypes = [rating.customer ? "customer" : "", rating.worker ? "worker" : ""]
		    // filter out if is undefined
		    reviewTypes = reviewTypes.filter(t => t)
		    const currentUsersReview = currentUser && currentUser.uid === key
			    ratingsComponents.push(
			    	<div key={key} className="review-component">
			    		<Modal >
								<div className="medium">
						    	<div>{rating.title}</div>
					    		<div className="small-line-break" />
						    	{
										rating.username ?
											<Link className="link-appearance" to={`/users/${rating.username}`}>{rating.reviewer || `@${rating.username}`}</Link>
										: rating.reviewer || "Anonymous"
									}
									{
										reviewTypes.includes("worker") && 
										<React.Fragment>
											&nbsp;
											<MoreInfoIcon id="what-is-customer" absolute={true} customIcon={
												<span className="reviewer-type">Customer</span> 
											} text={`Customer of ${resultUserData.companyName || resultUserData.firstname || resultUserData.username}`} />
											&nbsp;
										</React.Fragment>
									}
									{
										reviewTypes.includes("customer") && 
										<React.Fragment>
											&nbsp;
											<MoreInfoIcon id="what-is-worker" absolute={true} customIcon={
												<span className="reviewer-type">Worker</span> 
											} text={`Works for ${resultUserData.companyName || resultUserData.firstname || resultUserData.username}`}/>
											&nbsp;
										</React.Fragment>
									}
					    		<div className="section-divider" />
						    	{
						    		(rating.worker || rating.customer) && 
						    		<React.Fragment>
							    		<div className="review-type-title">Overall</div>
						    		</React.Fragment>
						    	}
					    	</div>
								{
				    			currentUsersReview &&
					    		<span className="edit-action">
						    		<div className="edit-review button-appearance tiny" onClick={() => setReviewModalOpen(true)} >
											<span className="material-icons">{"\ue3c9"}</span>&nbsp;Edit
										</div>
									</span>
				    		}
					    	{getStars(rating.overall)} {getNum(rating.overall, 1)} 
				    		<div>{rating.text}</div>
								{
							    reviewTypes.map((type, i) => {
							    	const review = rating[type]
							    	const textComponent =  
								    	<React.Fragment>
								    		<div>{review.text}</div>
							    		</React.Fragment>

							    	const beforeItems =
								    	<React.Fragment>
												<div className="review-type-title">
													{type === "customer" ? "As a customer" : type === "worker" ? "As a worker" : "In general"}
												</div>
												<div className="small-line-break" />
											</React.Fragment>

										if (type === "customer") {
								    	return (
												<React.Fragment key={type} >
													{
														// i === 0 && <div className="small-line-break" />
											    }
										    	<div className="section-divider" />
													{beforeItems}
									    		<div>{getStars(review.quality)} Easy to work for</div>
									    		<div>{getStars(review.punctuality)} Pays on time</div>
									    		{review.text && textComponent}
												</React.Fragment>
								    	)
								    } else if (type === "worker") {
								    	return (
												<React.Fragment key={type} >
													{
														// i === 0 && <div className="small-line-break" />
											    }
										    	<div className="section-divider" />
													{beforeItems}
									    		<div>{getStars(review.price)} Price</div>
									    		<div>{getStars(review.quality)} Quality</div>
									    		<div>{getStars(review.punctuality)} Punctuality</div>
									    		{review.text && textComponent}
												</React.Fragment>
								    	)
								    } else {
											return null
								    }
							    })
							  }
							</Modal>
						</div>
					)

			}
			if (ratingsComponents.length) {
				return ratingsComponents
			} else {
				return <div>No ratings yet</div>
			}
		} else if (isFetching) {
			return <div />
		} else {
			return (
				<div>No ratings yet</div>
			)
		}
	}

	useEffect(() => {
	}, [userRatings/*, resultUserData*/])

	return (
		<React.Fragment>
			<ProfileReviewStyle>
				{
					(currentUser && currentUser.uid !== resultUserData.id) &&
					<div id="write-review">
						<button className="button-appearance" onClick={() => setReviewModalOpen(true)}>
							<svg
								viewBox="0 0 32 32"
								fill="none"
								stroke="currentcolor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="3"
							>
								<path d="M27 15 L27 30 2 30 2 5 17 5 M30 6 L26 2 9 19 7 25 13 23 Z M22 6 L26 10 Z M9 19 L13 23 Z" />
							</svg>
							&nbsp;Leave a review
						</button>
					</div>
				}
				<div className="reviews">
					{getReviewComponents()}
				</div>
			</ProfileReviewStyle>
		</React.Fragment>
	)
}

export default ProfileReview