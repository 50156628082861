	const letterPaperWidth = 8.5
	const letterPaperHeight = 11
	const letterPaperMarginTopBottom = 1
	const letterPaperMarginLeftRight = 1
	const inchesToPx = 96
	const inchesToPt = 72

export const pageConstants = {
	letterPaperWidth,
	letterPaperHeight,
	letterPaperMarginTopBottom,
	letterPaperMarginLeftRight,
	inchesToPx,
	inchesToPt,
	pxToPt: inchesToPx / inchesToPt,
	letterPaperHeightWidthRatio: letterPaperHeight / letterPaperWidth, // 1.29412
	widthToPaddingTopBottomRatio: letterPaperWidth / letterPaperMarginTopBottom, // 8.5"
	widthToPaddingLeftRightRatio: letterPaperWidth / letterPaperMarginLeftRight,
	letterPaperPtToWidthRatio: letterPaperWidth * inchesToPt, // 612
	printablePaperWidthPx: inchesToPx * letterPaperWidth //816px
}