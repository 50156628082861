import React, { useState, useContext } from 'react'

import { EditInvoiceContext } from '../../../contexts/EditInvoiceContext'

import Modal from '../../Modal/Modal'
import { AppMessage } from '../../MessageUtils'
// import MoreInfoIcon from '../../MoreInfoIcon'
import Form from '../../Forms/Form'
import { 
	getFormulaVariableOptions,
	getTdTotalVal,
	formulaOperatorOptions,
	updateTotals,
} from '../invoiceUtils'
import { 
	setInnerProperty,
} from '../../../utils/appUtils'

const AddSummaryEntryModal = () => {
	const {
		editedInv, 
		invoiceTotals,
		addRow,
		addSummaryEntryModal, 
		setAddSummaryEntryModal,
		handleSetEditedInv
	} = useContext(EditInvoiceContext)

	const {editExistingEntry, data} = addSummaryEntryModal

	const formulaVariableOptions = getFormulaVariableOptions(editedInv, invoiceTotals)

	const findSelectedOption = (savedOpt) => {
		if (savedOpt.name === "custom number") {
			return {...savedOpt}
		}
		let selectedOption = formulaVariableOptions.find(opt => opt.name === savedOpt.name)
		if (!selectedOption) {
			// selectedOption must be in operatord
			selectedOption = formulaOperatorOptions.find(opt => opt.value === savedOpt.name)
		}
		if (!selectedOption) {
			console.log("error cant find selectedOption", savedOpt, formulaVariableOptions)
		}
		return selectedOption
	}

	const findFormulaStructure = (entry) => {
		let formulaStructure = {}
		// entry formula structure should only contain a reference to the actual selected option
		if (entry.formulaStructure) {
			for (let key in entry.formulaStructure) {
				const selectedOption = findSelectedOption(entry.formulaStructure[key])
				formulaStructure = {...formulaStructure, [key]: {...selectedOption}}
			}
		}
		return formulaStructure
	}

	const findExistingEntry = () => {
		if (editExistingEntry && data && data.id && data.tableName) {
			let entry = editedInv[data.tableName].find(ent => ent.id === data.id)
			// entry should only use the name properties in formulaStructure
			return entry
		} else return {}
	}

	const existingEntry = findExistingEntry()

	const defaultFormdata = {
		entryType: existingEntry.entryType || "tax",
		// user can change the name of the entry without this form but it does not change the itemName property in the row
		itemName: (existingEntry && existingEntry.values) ? existingEntry.values.find(td => td.for === "item").val : existingEntry.itemName || "GST 5%",
		// existingEntry.total is not used because it can be outdated easily
		// total: (existingEntry && existingEntry.values) ? existingEntry.values.find(td => td.for === "total").val : getNum(getSum([invoiceTotals.materialEntries.total, invoiceTotals.laborEntries.total]) * 0.05, 2),
		// total: getNum(getSum([invoiceTotals.materialEntries.total, invoiceTotals.laborEntries.total]) * 0.05, 2),
		total: 0,
		formulaDisplayString: "",
		formulaStructure: (existingEntry && existingEntry.values) ? {...findFormulaStructure(existingEntry)} : { 
			0: {
				...formulaVariableOptions[0]
			},
			1: {
				...formulaOperatorOptions[2]
			},
			2: {
				...formulaVariableOptions[formulaVariableOptions.length - 1]
			}
		}
	}

	const [formData, setFormData] = useState({
		...defaultFormdata,
		total: updateTotals(defaultFormdata).total,
		formulaDisplayString: updateTotals(defaultFormdata).formulaDisplayString
	})


	const onClickOutside = () => {
		setAddSummaryEntryModal(addSummaryEntryModal => ({...addSummaryEntryModal, open: false}))
	}

	const handleSaveEntry = (e) => {
		e.preventDefault()

		if (formData.entryType === "materialTotals" || formData.entryType === "laborTotals") {
			let newSummaryEntries = editedInv.summaryEntries.map(ent => {
				if (ent.id === formData.entryType) {
					return {
						...ent, 
						visible: true
					}
				} else return ent
			})
			handleSetEditedInv({changes: {
				summaryEntries: newSummaryEntries
			}, caller: "AddSummaryEntryModal - handleSaveEntry"})
		} else {
			// add a new row
			// data has rowId, and tableName
			// item, qty, cost,

			// const formulaDropdownSelections = [
			// 	formulaVariableOptions.find(opt => opt.name === varName0).stringFormula || formData.var0,
			// 	formulaVariableOptions.find(opt => opt.name === varName1).stringFormula || formData.var1,
			// 	formulaVariableOptions.find(opt => opt.name === varName2).stringFormula || formData.var2
			// ]
			let qty = 1
			let spliceStart
			let spliceDelete
			// do not store values and formulas that can change from existing in DB
			// these values and formulas should be loaded in based on the dropdown option selected
			let dbFormulaStructure = {}
			for (let key in formData.formulaStructure) {
			// delete changing values and formulas in fd.formulaStructure
				if (formData.formulaStructure[key].name === "custom number") {
					// add the whole obj
					dbFormulaStructure = {...dbFormulaStructure, [key]: {...formData.formulaStructure[key]}}
				} else {
					// only add name ... exclude value and stringFormula
					dbFormulaStructure = {...dbFormulaStructure, [key]: {name: formData.formulaStructure[key].name}}
					// delete additionalRowData.formulaStructure[key].value
					// delete additionalRowData.formulaStructure[key].stringFormula
				}
			}

			let additionalRowData = {...formData, formulaStructure: dbFormulaStructure}

			// if editing an existing entry
			if (editExistingEntry) {
				spliceStart = editedInv[data.tableName].findIndex(row => row.id === data.id)
				spliceDelete = 1
				// qty has no effect aon the total
				qty = (existingEntry && existingEntry.values) ? existingEntry.values.find(td => td.for === "qty").val : 1
			}

			// always add custom entry rows below labor and material totals
			// note: materialTotals anad laborTotals summary rows always stay there but there
			// only their visibility changes
			if (!editExistingEntry) {
				if (data.id === "materialTotals" || data.id === "laborTotals") {
					spliceStart = 2
				}

				// if (data.id === "laborTotals") {
				// 	spliceStart = 2
				// }
			}


			addRow({...data, item: formData.itemName, qty, total: getTdTotalVal(formData), spliceStart, spliceDelete, additionalRowData}) 

		}

		onClickOutside()
		// return addRow(addSummaryEntryModal.data)
	}

	const handleChange = (nameOrId, value) => {
		// need to parse nameOrId
		let newFd = {...formData}

		// if changing entryType to materialTotals or laborTotals 
		if (nameOrId === "entryType" && (value === "materialTotals" || value === "laborTotals")) {
			// set the formulas in case entry type changed
			// jbut ust make the total material totals or laborTotals
			let invoiceTotalName = ""
			let displayName = ""

			if (value === "materialTotals") {
				const materialHeading = editedInv.pageOrder.find(item => item.for === "materialHeading")

				displayName = materialHeading ? materialHeading.val : "Materials"
				invoiceTotalName = "materialEntries"
			} else {
				const laborHeading = editedInv.pageOrder.find(item => item.for === "laborHeading")

				displayName = laborHeading ? laborHeading.val : "Labor"
				invoiceTotalName = "laborEntries"
			}

			newFd.formulaStructure = {
				0: {
					name: displayName + " total",
					stringFormula: "invoiceTotals[invoiceTotalName].total",
					value: invoiceTotals[invoiceTotalName].total
				},
				1: {
					name: "+",
					stringFormula: "+",
					value: "+"
				},
				2: {
					name: "0", 
					stringFormula: "0",
					value: 0
				}
			}

			// newFd.formulaDisplayString = (invoiceTotals[invoiceTotalName].total).toString()
			newFd = updateTotals({...newFd, [nameOrId]: value})		
		} else {
			if (nameOrId.includes(".")) {
				let objectPath = nameOrId
				let newValue = value

				if (value !== "custom number" && objectPath.includes("formulaStructure") && nameOrId.endsWith(".name")) {
					// update the whole formdata.formulaStructure object to the full dropdown selection

					let selectedOption = formulaVariableOptions.find(opt => opt.name === value)
					if (!selectedOption) {
						selectedOption = formulaOperatorOptions.find(opt => opt.name === value)
					}

						// value must change to the selected option object so cant use .name
					objectPath = objectPath.slice(0, objectPath.lastIndexOf("."))
					newValue = {...selectedOption}
						// newFd = setInnerProperty(newFd, objectPath, newValue)
				} 

				newFd = setInnerProperty(newFd, objectPath, newValue)

				// change the formulaString to nothing to prevent a custom number to actuall be a live number
				// eg: user changes from invoice subtotal ption to custom number then adds entry
				// if formulaString stays as [materials + labor] the custom number will not be custom
				if (value === "custom number") {		
					const formulaStringObjectPath = objectPath.slice(0, objectPath.lastIndexOf(".")) + ".stringFormula"
					newFd = setInnerProperty(newFd, formulaStringObjectPath, "")
				}

				newFd = updateTotals(newFd)

			} else {
				newFd = updateTotals({...newFd, [nameOrId]: value})
			}

		}

		setFormData(formData => ({...formData, ...newFd}))

	}

	const materialHeading = editedInv.pageOrder.find(item => item.for === "materialHeading")
	const laborHeading = editedInv.pageOrder.find(item => item.for === "laborHeading")

	const materialsDisplayName = (materialHeading ? materialHeading.val : "Materials") + " total"
	const laborDisplayName = (laborHeading ? laborHeading.val : "Labor") + " total"

	const materialInSummaryEntries = editedInv.summaryEntries.find(ent => ent.id === "materialTotals")
	const laborInSummaryEntries = editedInv.summaryEntries.find(ent => ent.id === "laborTotals")

	const isCustomOrTax = formData.entryType === "tax" || formData.entryType === "custom"

	let entryTypeOptions = [
		{name: "tax"}, 
		{name: "custom"}, 
	]

	if (materialInSummaryEntries && !materialInSummaryEntries.visible) {
		entryTypeOptions.push({
			displayName: materialsDisplayName, name: "materialTotals"
		})
	}
	if (laborInSummaryEntries && !laborInSummaryEntries.visible) {
		entryTypeOptions.push({
			displayName: laborDisplayName, name: "laborTotals"
		})
	}

	const inputs = [
		{
			custom: true,
			label:"Item Type",
			properties: {
				type: "dropdown",
				name: "entryType",
				// options: industryOptions.length ? [...industryOptions, {name: "-- None listed --", rate: "", value: ""}] : [{name: "-- None listed --", rate: "", value: ""}],
				options: entryTypeOptions,
				optionVal: "name",
				value: formData.entryType,
				onChange: handleChange,
				required: true
				// disabled: disableInputs
			},
		},
		{
			// label: "Tax name",
			label: formData.entryType === "custom" ? "Item name" : "Tax Name",
			visible: isCustomOrTax,
			onChange: handleChange,
			properties: {
				type: "text",
				// type: userCanEdit ? "date" : "hidden",
				id: "itemName",
				value:  formData.itemName,
				// disabled: (!(userIsCreatorOrContractor || userIsOwner)),
				// default: getLocalISODate(),
				required: true
			},
			afterInput: [
				<React.Fragment key="customFormulaSectionTitle">
					{
						formData.entryType === "custom" && 
						<div className="section-title">
							<div className="section-divider" />
							Entry Value Formula
							<div className="small-line-break" />
						</div>
					}
				</React.Fragment>
			]
		},
		{
			label: "% tax",
			visible: formData.entryType === "tax",
			onChange: handleChange,
			// containerClass: "normal-input-style",
			properties: {
				type: "number",
				// type: userCanEdit ? "date" : "hidden",
				id: "formulaStructure.2.value",
				value:  formData.formulaStructure[2].value,
				// disabled: (!(userIsCreatorOrContractor || userIsOwner)),
				// default: getLocalISODate(),
				required: true
			},
			afterInput: [
				<div key="taxPercentLB" className="small-line-break" />
			]
		},
		{
			custom: true,
			label:formData.entryType === "tax" ? "Applied to" : "",
			// visible: formData.entryType === "custom",
			visible: isCustomOrTax,
			properties: {
				// type: formData.entryType === "custom" ? "dropdown" : "hidden",
				type: "dropdown",
				// name: input.name,
				name: "formulaStructure.0.name",
				options: formulaVariableOptions,
				optionVal: "name",
				value:  formData.formulaStructure[0].name,
				required: true,
				onChange: handleChange
			},
			insideInputDiv: [
				<React.Fragment key="customVal">
					&nbsp;
					{
						formData.formulaStructure[0].name === "custom number" ?
						<input 
							type="number" 
							step="0.01"
							value={formData.formulaStructure[0].value} 
							id="formulaStructure.0.value"
							// onChange={(e) => {
							// 	setFormData(formData => ({
							// 		...formData, 
							// 		formulaStructure: {...formData.formulaStructure, 0: {...formData.formulaStructure[0], value: e.target.value}}
							// 	}))
							// }}
							onChange={(e) => handleChange(e.target.id, e.target.value)}
							className="inline-number-input"
						/>
						:
						<span className="whisper">
							{
								formData.formulaStructure[0].value.toString().match(/[/\-*+]/) ? 
								"" : ` ${formData.formulaStructure[0].value}`
							}
						</span>

					}
				</React.Fragment>
			]
		},
		{
			custom: true,
			visible: formData.entryType === "custom",
			properties: {
				// type: formData.entryType === "custom" ? "dropdown" : "hidden",
				type: "dropdown",
				name: "formulaStructure.1.name",
				options: formulaOperatorOptions,
				optionVal: "name",
				value:  formData.formulaStructure[1].name,
				required: true,
				onChange: handleChange
			},
			insideInputDiv: [
				<React.Fragment key="customVal">
					&nbsp;
					{
						formData.formulaStructure[1].name === "custom number" ?
						<input 
							type="number" 
							step="0.01"
							value={formData.formulaStructure[1].value} 
							id="formulaStructure.1.value"
							// onChange={(e) => {
							// 	setFormData(formData => ({
							// 		...formData, 
							// 		formulaStructure: {...formData.formulaStructure, 0: {...formData.formulaStructure[0], value: e.target.value}}
							// 	}))
							// }}
							onChange={(e) => handleChange(e.target.id, e.target.value)}
							className="inline-number-input"
						/>
						:
						<span className="whisper">
							{
								formData.formulaStructure[1].value.toString().match(/[/\-*+]/) ? 
								"" : ` ${formData.formulaStructure[1].value}`
							}
						</span>

					}
				</React.Fragment>
			]
		},
		{
			custom: true,
			visible: formData.entryType === "custom",
			properties: {
				// type: formData.entryType === "custom" ? "dropdown" : "hidden",
				type: "dropdown",
				name: "formulaStructure.2.name",
				options: formulaVariableOptions,
				optionVal: "name",
				value:  formData.formulaStructure[2].name,
				required: true,
				onChange: handleChange
			},
			insideInputDiv: [
				<React.Fragment key="customVal">
					&nbsp;
					{
						formData.formulaStructure[2].name === "custom number" ?
						<input 
							type="number" 
							step="0.01"
							value={formData.formulaStructure[2].value} 
							id="formulaStructure.2.value"
							// onChange={(e) => {
							// 	setFormData(formData => ({
							// 		...formData, 
							// 		formulaStructure: {...formData.formulaStructure, 0: {...formData.formulaStructure[0], value: e.target.value}}
							// 	}))
							// }}
							onChange={(e) => handleChange(e.target.id, e.target.value)}
							className="inline-number-input"
						/>
						:
						<span className="whisper">
							{
								formData.formulaStructure[2].value.toString().match(/[/\-*+]/) ? 
								"" : ` ${formData.formulaStructure[2].value}`
							}
						</span>

					}
				</React.Fragment>
			]
		},
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "hiddenInput2",
				onChange: () => null
			},
			beforeInput: [
				<div key="total" >
					<div className="small-line-break" />
					{
						<div>
							<strong>{formData.entryType === "tax" ? "Total Tax: " : "Total: "}</strong>
							{
							// `${formData.formulaDisplayString} \n = $${formData.total}`
								" $" + formData.total
							}
						</div>
					}
					{
						isCustomOrTax &&
						<div className="whisper">
							<div className="small-line-break" />
							{`Calculation: ${formData.formulaDisplayString} = $${formData.total}`}
						</div>
					}
				</div>
			]
		},
		// {
		// 	visible: !isCustomOrTax,
		// 	properties: {
		// 		type: "hidden",
		// 		id: "hiddenInput3",
		// 		onChange: () => null
		// 	},
		// 	beforeInput: [
		// 		<div key="totalMaterialOrLabor" >
		// 		{
		// 			<div>
		// 				<strong>{formData.entryType === "materialTotals" ?}
		// 			</div>
		// 		}
		// 		</div>
		// 	]
		// }

	]

		return (
			<Modal custom={{absolute: true}} onClickOutside={onClickOutside} >
				<AppMessage />
				<Form 
					heading={<div className="medium" >{editExistingEntry ? "Edit" : "Add a"} Summary Entry</div>}
					submitName={editExistingEntry ? "OK" : "Add"} 
					onSubmit={e => handleSaveEntry(e)}
					inputs={inputs}
				>
				</Form>
			</Modal>
		)
	}

	export default AddSummaryEntryModal