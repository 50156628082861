import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components";

import { UserContext } from '../../contexts/UserContext'
import { MessagingContext } from '../../contexts/MessagingContext'

import MoreInfoIcon from '../MoreInfoIcon'
import { UserPhoto } from './marketplaceComponents'
import { getStars, getUserRating } from '../UserProfile/UserRating'
import { AppMessage } from "..//MessageUtils";

import { fetchAllInteractions, fetchPrivate } from './utils'
import { getFriendlyDate } from '../../utils/dateUtils'
import { findExistingOrNewMessageThread } from '../searchComponents/utils'

import { handleImageOnError } from "../../utils/appUtils";

import ImageCarosel from './ImageCarosel'


import firebase from "../../firebase/index";

const PostViewStyle = styled.div `
	text-align: left;
  color: var(--chicago);
  .material-icons {
	  transition: transform 0.1s ease 0s;
  }
  h2 {
  	margin: 0;
  }
  .edit {
  	> button {
  		position: absolute;
  		right: 0;
  		margin: 0 20px;
  	}
  }
  .app-message-full-width {
  	display: block;
  	width: -webkit-fill-available;
  }
  .icon-and-font {
		color: var(--light-gray);
  	display: flex;
  	justify-content: middle;
  	column-gap: 5px;
	  align-items: center;
  }
  .meta-info {
		font-size: var(--font-size-xs);
		color: var(--light-gray);
  }
	.post-container {
		text-align: left;
    padding-bottom: 20px;
    .wrapper.single-photo {
	    > ul {
	    	li {
					.main-photo {
				  	width: 400px;
					}
	    	}
	    }
    }
  	.wrapper.mobile, .wrapper.single-photo.mobile {
  		> ul {
  			li {
		  		.main-photo {
		  			width: calc(100vw - 100px); // 100px is total padding of modal, wrappers etc
		  		}
  			}
  		}
  	}
    .scroll-images {
	  	background-color: var(--titan-white);
	  	padding: 10px;
    }

    .section-divider {
  	  background: linear-gradient(180deg, var(--light-gray), transparent 1px);
		  background-position-Y: 10px;
    }
    .part-zero {
    }
    
    .part-one {
	    position: relative;
    	.part-one-main {
	    	display: flex;
		    column-gap: 10px;
    	}
    	.part-one-info {
    		display: flex;
    		flex-direction: row;
  		  align-items: flex-start;
    		justify-content: space-between;
    		column-gap: 10px;
    	}
    	.user-stars {
  			background-color: white;
  			position: static;
  			padding: 0;
			  text-wrap: nowrap;
    		.stars {
    			svg {
    				height: 18px;
    				width: 18px;
    			}
    		}
    		.rating-text {
    			margin-top: -4px;
    			padding: 0 1px;
    			position: relative;
  			  cursor: help;
    	    // align-self: flex-end;
    		}
    	}
    	.user-stars.no-rating {
    		.stars { 
    			svg {
    				fill: var(--nav-item-hover);
    				color: var(--nav-item-hover);
    			}
    		}
    	}
    	.user-stars.high-rating {
    		.rating-text {
    			background-color: var(--brand-green);			
    	    border-radius: 10px;
    	    padding: 0 7px;
    		}
    	}
    	.user-stars.expert-rating {
    		.rating-text {
    			background-color: var(--brand);			
    	    color: white;
    	    border-radius: 10px;
    	    padding: 0 7px;
    		}
    	}
	    .user-photo-container {
	    	border-radius: 50%;
			  background: transparent;
			  box-shadow: 0px 0px 10px -2px;
			  height: 60px;
		    .spinner-container {
		    	position: relative;
		    	display: flex;
		    	> div {
		    		position: static;
		    		margin-top: auto;
		    		margin-bottom: auto;
		    	}

		    }
	    	img, > div {
		    	width: 60px;
		    	height: 60px;
		    	// padding: 20px;
		    	border-radius: 50%;
	    	}
	    }
    }
    p {
    	margin: 0;
    }
    .engagement-actions {
    	display: flex;
    	column-gap: 10px;
    	text-align: center;
    	width: 100%;
    	align-items: center;
		  justify-content: space-around;
    	> div {
    		position: relative;
	  	  font-size: var(--font-size-xs);
    		display: flex;
    		justify-content: center;
    		flex-direction: column;
    		text-align: center;
    	}
    }
	}
	
`


// rendered by pages/Marketplace
// will not render unless viewingPost has ID property
const PostView = ({ 
	handleDeletePost,
	handleOpenEditPostModal, 
	handleUpdatePost, 
	changePostId,
	viewingPost, 
	setViewingPost, 
	handleSetJobs, 
	filters,
	alreadyFetchedUsers,
	handleSetAlreadyFetchedUsers
}) => {
	const postContainerRef = useRef(null)

	const {
		userObject,
		setLoginModalOpen,
		handleSetMessage,
		handleSetSuccessText,
	} = useContext(UserContext)

	const {
		usersThreads
	} = useContext(MessagingContext)


	const [poster, setPoster] = useState({})
	const [isFetchingUser, setIsFetchingUser] = useState(false)
	const [shouldFetchSubcollections, setShouldFetchSubcollections] = useState(true)
	const [isFetchingSubcollections, setIsFetchingSubcollections] = useState(false)
	const [fetchedSubcollectionsWithUserObject, setFetchedSubcollectionsWithUserObject] = useState(false)
	const [isUpdatingPost, setIsUpdatingPost] = useState(false)

	const initialUserHasLikedPost = userObject.id && viewingPost.likedBy && viewingPost.likedBy.includes(userObject.id)
	const initialUserHasIntrest = userObject.id && viewingPost.interestedUsers && viewingPost.interestedUsers.includes(userObject.id)
	const userHasPreviouslyViewed = userObject.id && viewingPost.viewedBy && viewingPost.viewedBy.includes(userObject.id)

	const [likedClass, setLikedClass] = useState(initialUserHasLikedPost)
	const [interestedClass, setInterestedClass] = useState(initialUserHasIntrest)


	const userIsPoster = userObject.id && userObject.id === poster.id 
	const canEdit = userIsPoster

	let existingOrNewMessageThread = null
	if (userObject.id && poster && usersThreads) {
		existingOrNewMessageThread = findExistingOrNewMessageThread(userObject, usersThreads, poster)
	}

	const existingMessageThreadLink = `/messages${existingOrNewMessageThread ? `?threadId=${existingOrNewMessageThread.id}&from=${userObject.id}&to=${poster.id}&toUsername=${poster.username}` : ""}`


	useEffect(() => {
	  if (userObject && userObject.id) {
  		let userHasLikedPost = viewingPost.likedBy && viewingPost.likedBy.includes(userObject.id)
			let userHasIntrest = viewingPost.interestedUsers && viewingPost.interestedUsers.includes(userObject.id)
			setLikedClass(userHasLikedPost ? 'blue-fill' : '')
			setInterestedClass(userHasIntrest ? 'blue-fill' : '')
	  }
	}, [userObject, viewingPost.likedBy, viewingPost.interestedUsers])

	const handleFetchUser = async (userId, setPoster, setIsFetchingUser, alreadyFetchedUsers, userObject) => {
		if (!userId) {
			return null
		}

		setIsFetchingUser(true)

		// set user to user object if this is the user
		if (userObject.id && userObject.id === userId) {
			setPoster(userObject)
			setIsFetchingUser(false)
			return userObject
		}

		const userInAlreadyFetchedUsers = alreadyFetchedUsers.find(u => u.id === userId)

		if (userInAlreadyFetchedUsers) {
			setPoster(userInAlreadyFetchedUsers)
			setIsFetchingUser(false)
			return userInAlreadyFetchedUsers
		} else {

			// return firebase.firestore().collection("users").doc(userId).get().then(doc => {
			return firebase.firestore().collection("users").doc(userId).get().then(doc => {
				if (!doc.exists) {
					return null
				}
				const user = {
					...doc.data(),
					id: doc.id
				}
				if (user) {
					setPoster(user)
				}

				setIsFetchingUser(false)
				return user
			}).catch(err => {
				console.log(err)
				return null
			})
		}
	}

	const handleFetchSubcollections = ({userObject, viewingPost, setViewingPost, setIsFetchingSubcollections, setShouldFetchSubcollections}) => {
		let promises = []
		setIsFetchingSubcollections(true)

		promises.push(fetchAllInteractions({userObject, postData: viewingPost}).then(res => ({key: "interactions", data: res})))
		promises.push(fetchPrivate({userObject, postData: viewingPost}).then(res => ({key: "private", data: res})))

		return Promise.all(promises).then(arr => {
			let newViewingPost = {}
			arr.forEach(({key, data}) => {
				newViewingPost[key] = data
			})

			setViewingPost(viewingPost => ({
				...viewingPost, 
				...newViewingPost
			}))

			setIsFetchingSubcollections(false)
			setShouldFetchSubcollections(false)
			setFetchedSubcollectionsWithUserObject(userObject.id)
		}).catch(err => {
			console.log(err)
			setIsFetchingSubcollections(false)
			setShouldFetchSubcollections(false)
			setFetchedSubcollectionsWithUserObject(userObject.id)
		})

	}

	const getDefaultInteractionsObj = (userObject, interactions) =>  {
		if (interactions && interactions[userObject.id]) {
			return {
				...interactions[userObject.id],
				// keep this info up to date
				username: userObject.username,
				photo: userObject.logoUrl || "",
			}

		} else {
			return {
				accessors: [userObject.id], // this doc is only accessible by userId but when user is making a bid we could add the poster id to the accessors list to allow them to view
				visibility: "private",
				// estimate: { // optional
				// 	cost: 1234,
				// 	hours: 10,
				// 	confidence: 80%
				// },
				// interested: true,
				// liked: true,
				id: userObject.id,
				username: userObject.username,
				photo: userObject.logoUrl || "",
				// timestamp: 100000 seconds
			}
		}
	}

	const handleLikeOrInterested = (name, value, e) => {
		if (!userObject.id) {
			// handleSetSuccessText("Please log in to interact with this post")
	  	handleSetMessage(
	  		<div>
		  		<div>
			  		Please <span onClick={() => setLoginModalOpen(true)} className="link-appearance" >Log In</span> to interact with this post
		  		</div>
	  		</div>
  		)
			return 
		}

		let isUsersFirstInteraction = true
		let newPostData = {...viewingPost}
		let newUserInteractionObj = getDefaultInteractionsObj(userObject, newPostData.interactions)

		if (newPostData.interactions && newPostData.interactions[userObject.id]) {
			isUsersFirstInteraction = false
		}

		newUserInteractionObj[name] = value

		const el = e.target

		el.style.transform = "rotate(20deg)"
		setTimeout(() => {
			el.style.transform = "rotate(-20deg)"
		}, 100)

		setTimeout(() => {
			el.style.transform = "rotate(0deg)"
		}, 200)

		// add or remove blue fill right away so it updates sooner
		if (value === true) {
			// el.classList.add("blue-fill")
			const num = parseInt(el.parentElement.lastChild.textContent)
			if (!isNaN(num)) {
				el.parentElement.lastChild.textContent = num + 1
			}
		} else {
			// el.classList.remove("blue-fill")
			const num = parseInt(el.parentElement.lastChild.textContent)
			if (!isNaN(num)) {
				el.parentElement.lastChild.textContent = (num > 0) ? (num - 1) : 0
			}
		}

		if (name === "liked") {
			if (value === true) {
				setLikedClass('blue-fill')
				newPostData.likedBy = [
					...newPostData.likedBy || [],
					userObject.id
				]
			} else {
				setLikedClass('')
				newPostData.likedBy = (newPostData.likedBy || []).filter(id => id !== userObject.id)
			}
		}

		if (name === "interested") {
			if (value === true) {
				setInterestedClass('blue-fill')
				newPostData.interestedUsers = [
					...newPostData.interestedUsers || [],
					userObject.id
				]
			} else {
				setInterestedClass('')
				newPostData.interestedUsers = (newPostData.interestedUsers || []).filter(id => id !== userObject.id)
			}
		}

		newPostData.interactions = {
			...newPostData.interactions || {},
			[userObject.id]: newUserInteractionObj
		}
		setIsUpdatingPost(true)
		handleUpdatePost({newPostData, userObject, isUsersFirstInteraction, handleSetJobs, filters}).then(() => {
			setIsUpdatingPost(false)
		})

		setViewingPost(viewingPost => ({
			...viewingPost,
			...newPostData
		}))
	}

	const handleView = async (userHasPreviouslyViewed, userObject) => {
		let isUsersFirstInteraction = false // this is not considered an interaction 
		// let newUserInteractionObj = getDefaultInteractionsObj(userObject, newPostData.interactions)

		// if (newPostData.interactions && newPostData.interactions[userObject.id]) {
		// 	isUsersFirstInteraction = false
		// }

		// newUserInteractionObj[name] = value
		if (!userHasPreviouslyViewed) {
			let newPostData = {
				...viewingPost,
				viewedBy: [...viewingPost.viewedBy, userObject.id]
			}
			setIsUpdatingPost(true)
			await handleUpdatePost({newPostData, userObject, isUsersFirstInteraction, handleSetJobs, filters})
			setIsUpdatingPost(false)
			setViewingPost(newPostData)
		}

	}

	const {
		overallStars,
		ratingText,
		ratingClassName,
		ratings,
	} = getUserRating(poster, "customerRating")

	
	const timeSincePosted = getFriendlyDate(viewingPost.timestamp, "noTime") + " at " + getFriendlyDate(viewingPost.timestamp, "time")

	let budgetNumber
	if (viewingPost.budget) {
		budgetNumber = parseInt(viewingPost.budget)
	}

	if (viewingPost.budget > 1000) {
		budgetNumber = "999+"
	}

	useEffect(() => {
		if (viewingPost.id !== "not-found" && userObject.id && !userHasPreviouslyViewed && !isUpdatingPost) {
			handleView(userHasPreviouslyViewed, userObject)
		}	  
	})

	useEffect(() => {
	  if (poster.id) {
			// add to alreadyFetchedUsers here instead of in the DB call in case of overwrite because of an old setAlreadyFetchedUsers
			const userInAlreadyFetchedUsers = alreadyFetchedUsers.find(u => u.id === poster.id)
			if (!userInAlreadyFetchedUsers) {
				handleSetAlreadyFetchedUsers({newUsers: [poster], caller: "PostView - poster.id true"})
				// setAlreadyFetchedUsers(alreadyFetchedUsers => [...alreadyFetchedUsers, poster])
			}
		}

		if (!poster.id && !isFetchingUser && viewingPost.userId) {
			if (userObject.id && viewingPost.userId === userObject.id) {
				setPoster(userObject)
			} else {
				handleFetchUser(viewingPost.userId, setPoster, setIsFetchingUser, alreadyFetchedUsers, userObject)
			}
		}

		if (viewingPost.id && viewingPost.id !== "not-found") {
			if (shouldFetchSubcollections && !isFetchingSubcollections) {
				handleFetchSubcollections({userObject, viewingPost, setViewingPost, setIsFetchingSubcollections, setShouldFetchSubcollections})
			}
		}
	}, [poster, isFetchingUser, viewingPost, alreadyFetchedUsers, handleSetAlreadyFetchedUsers, userObject, shouldFetchSubcollections, isFetchingSubcollections, setViewingPost])

	useEffect(() => {
	  if (userObject.id) {
	  	if (fetchedSubcollectionsWithUserObject !== userObject.id) {
	  		// fetch again
	  		setShouldFetchSubcollections(true)
	  	}
	  }
	}, [userObject, fetchedSubcollectionsWithUserObject])

	const userHasLikedPost = likedClass === 'blue-fill'
	const userHasIntrest = interestedClass === 'blue-fill'


	return (
		<PostViewStyle>
			{
				viewingPost.id === "not-found" ?
				<div>Post Not found</div>
				:
				<React.Fragment>
					<div>
		  			<h2>{viewingPost.title}</h2>
  					<MoreInfoIcon 
	  					absolute={true} 
	  					custom={`width: 80%;`}
	  					customIcon={
								<div className="post-time"><span className="material-icons">{"\ue0c8"}</span>Location is Approximate ~ up to 2km</div>
							} 
							text={`We highly value Location Privacy. Job Locations on the map are not exact and are instead random locations within up to 2km. You can ask @${poster.username || viewingPost.username || "linvo user"} for the exact location.`}
						/>
		  			<div className="section-divider" />
					</div>
					<div className="app-message-full-width absolute left"> 
						<AppMessage scrollIntoView={true} />
					</div>
					<div className="section-divider" />
					<div className="section-divider" />	
  				<div ref={postContainerRef} className="post-container">
	  				{
						 viewingPost.photos.length ?
							 <ImageCarosel 
								 parentContainerWidth={postContainerRef.current ? postContainerRef.current.clientWidth : ""} photos={viewingPost.photos.map(p => ({...p, tag: p.tag || viewingPost.industry}))} 
							 >

							 {
									viewingPost.photos.map((obj,i) => {
					  				return (
					  					<li key={i}>
								  			<div className="top-info-tags">
													<span >{obj.tag || viewingPost.industry}</span>
												</div>
						  					<img className="main-photo" onError={handleImageOnError} src={obj.url} alt="" />
					  					</li>
				  					)
									})
								}
							 </ImageCarosel>
		  				: ""
	  				}
						<div className="part-zero">
		  				<div className="engagement-actions">
		  					<div>
			  					<span className={`material-icons ${likedClass}`} onClick={(e) => {
				  					handleLikeOrInterested("liked", !userHasLikedPost, e)
			  					}}>{"\ue8dc"}</span>
			  					<div>Like</div>
			  					<div>{viewingPost.likedBy ? viewingPost.likedBy.length : 0}</div>
		  					</div>
			  				<div>
				  				<span className={`material-icons ${interestedClass}`} onClick={(e) => {
				  					handleLikeOrInterested("interested", !userHasIntrest, e)
				  				}}>{"\ue766"}</span>
				  				<div>Interested</div>
			  					<div>{viewingPost.interestedUsers ? viewingPost.interestedUsers.length : 0}</div>
		  					</div>
		  					<div>
			  					<MoreInfoIcon 
				  					absolute={true} 
				  					custom={`top: -32px; right: 0; width: max-content;`}
				  					customIcon={
						  				<span className="material-icons" onClick={(e) => {
						  					const textEl = e.target?.parentElement?.nextElementSibling?.firstChild?.firstChild
						  					if (textEl) { 
						  						textEl.textContent = "link copied"
						  					}
						  					const input = document.getElementById("viewingPost-" + viewingPost.id + "-input")
						  					input.select()
												document.execCommand("copy");
												handleSetSuccessText("Link copied");

						  				}}>{"\ue80d"}</span>
										} 
										text="copy link" 
									/>
			  					<div>
				  					<input id={"viewingPost-" + viewingPost.id + "-input"} defaultValue={window.location.href} readOnly style={{position: "fixed", bottom: "-100px", opacity: 0}} />
			  					</div>
				  				<div>Share</div>
			  					<div>&nbsp;</div>
		  					</div>
		  				</div>

						</div>
						
						{
							canEdit ? 
							<div>
			  				<div className="small-line-break" />
			  				<div className="small-line-break" />
			  				<div className="small-line-break" />
	  						<span className="button-appearance tiny full-width-button" onClick={() => handleOpenEditPostModal(viewingPost)}>Edit</span>
							</div>
							: ""
						}
	  				<div className="small-line-break" />
						<div className="section-divider" />
  					<div className="part-one">
	  					<div className="part-one-info" >
			  				<div className="icon-and-font">
			  					<span className="material-icons" >{"\ue0c8"}</span>
			  					<span className="meta-info">{viewingPost.city}</span>
			  				</div>
	  						<div key={ratings} className={"user-stars " + ratingClassName} >
	  							{getStars(overallStars)}
	  							{
	  								ratingText ? <span className="rating-text">
					  					{/*<Link to={`/users/${poster.username || viewingPost.username}/reviews`} className="no-link">
		  									{ratingText}
	  									</Link>*/}
	  								<MoreInfoIcon 
					  					absolute={true} 
					  					custom={`right: 0`}
					  					customIcon={
					  						<span>{ratingText}</span>
											} 
											text="Customer rating" 
										/>
	  								</span>
	  								: null	
	  							}
	  						</div>
  						</div>
		  				<div className="small-line-break" />
  						<div className="part-one-main">
		  					<Link to={`/users/${poster.username || viewingPost.username}`} className="no-link">
		  						<UserPhoto userId={poster.id} user={poster} isFetchingUser={isFetchingUser} alreadyFetchedUsers={alreadyFetchedUsers} handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers} />
	  						</Link>
	  						<div>
		  						<p>
			  						<strong>{poster.companyName || (poster.firstName + " " + poster.lastname).trim() || poster.username || viewingPost.username || "linvo user"}</strong>
		  						</p>
		  						<p>
				  					<Link to={`/users/${poster.username || viewingPost.username}`} className="no-link">
				  						@{poster.username || viewingPost.username || "linvo user"}
			  						</Link>
		  						</p>
	  						</div>
  						</div>
	  				</div>
	  				<div className="small-line-break" />
	  				<div className="part-two" >
		  				<p>{viewingPost.body}</p>
	  				</div>
	  				<div className="small-line-break" />
		  				{
								budgetNumber ? 
								<React.Fragment>
				  				<div className="icon-and-font">
				  					<span className="material-icons" >{"\uf05b"}</span>
										<span className="meta-info">
											Budget ${budgetNumber} {viewingPost.budgetIsHourly ? "/h" : "total"}
										</span>
									</div>
								</React.Fragment>
								: ""
							}

	  				<div className="small-line-break" />
	  				<div className="section-divider" />
	  				<div className="small-line-break" />
	  				{
	  					(poster && poster.id && userObject.id && poster.id) !== userObject.id ?
								<div>
									<Link className="button-appearance tiny full-width-button" to={existingMessageThreadLink} >
										{/*<svg
											viewBox="0 0 32 32"
											fill="none"
											stroke="currentcolor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
										>
											<path d="M2 4 L30 4 30 22 16 22 8 29 8 22 2 22 Z" />
										</svg>*/}
						  				{/*<span className="material-icons">{"\ue0ca"}</span>*/}
										Message {poster.username || viewingPost.username}
									</Link>
								</div>
	  					: ""
	  				}
						<div className="small-line-break" />
						<div className="small-line-break" />
						<div className="meta-info">{timeSincePosted}</div>
						<div className="small-line-break" />
						<div className="small-line-break" />
						<div className="small-line-break" />
						{
							userIsPoster ? 
							<div>
								<span className="button-appearance red tiny full-width-button" onClick={() => handleDeletePost({postData: viewingPost, userObject})}>Delect Post</span>
							</div>
							: ""
						}
	  			</div>
				</React.Fragment>
			}
		</PostViewStyle>
	)
}

export default PostView