import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { MediaQueryContext } from '../../contexts/MediaQueryContext'
import { NavbarStyle } from './Navbar.styled';

// not really a point to having this file, wither use it or get rid of it 
// rendered by Heading.js... 
// errors and successText not necessary
const Navbar = ({custom, links, children, noUnderline, className}) => {
	const {pathname} = useLocation()
	const {isLessThan700px} = useContext(MediaQueryContext)

	const navItems = links.map(link => (
		<React.Fragment key={link.to || link.name}>
		{
			link.to ?
			<Link to={link.to} className="white no-link">
				<div onClick={link.onClick} className={`nav-item ${(pathname.includes(link.to) && !noUnderline) && "active"}`}>
					{link.name}
				</div>
			</Link>
			:
			<div>
				<div onClick={link.onClick} className={`nav-item ${(pathname.includes(link.to) && !noUnderline) && "active"}`}>
					{link.name}
				</div>
			</div>
		}
		</React.Fragment>
	))

	return (
		<NavbarStyle isLessThan700px={isLessThan700px} custom={custom} className="noprint">
			<div className={className + " nav-items-wrapper"} >
				{navItems}
				{children}
			</div>
		</NavbarStyle>
	)
}

export default Navbar
