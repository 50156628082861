import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { UserContext } from '../../contexts/UserContext'
import { EditProjectContext } from '../../contexts/EditProjectContext'
import { DialogContext } from "../../contexts/DialogContext"


import Form from '../Forms/Form'
import { AppMessage } from '../MessageUtils'
import MoreInfoIcon from '../MoreInfoIcon'

import { handleChangeUserCapabilities } from '../../utils/followUtils'
import { getNum } from '../../utils/appUtils'

//rendered by specificProject
// errors and success handled
const ProjectSummary = ({setShowProjectSummary}) => {
	const { dialog } = useContext(DialogContext)
	const {
		currentUser,
		userObject, 
		updateUserData,
	} = useContext(UserContext)
	const {
		projectData, 
		setProjectData, 
		invoiceData,
		userIsOwner, 
		userIsBillTo, 
		userCanEdit, 
		followersUserObjs, 
		setFollowersUserObjs, 
		setChangeBillToModalOpen,
		saveProject,
		setErrorObj,
		handleSetSuccessText,
		userCanView
	} = useContext(EditProjectContext)

	const [hasBeenEdited, setHasBeenEdited] = useState(false)
	const [showFollowers, setShowFollowers] = useState(false)

	const originalFormData = {
		projectName: projectData.projectName,
		shortHandId: projectData.shortHandId,
		isSetToCurrentProject: (currentUser && userObject.currentProject && userObject.currentProject === projectData.id) ? true : false,
		visibility: projectData.visibility || "",
		searchable: projectData.searchable
	}

	const [formData, setFormData] = useState({
		...originalFormData
	})
	const [totals, setTotals] = useState({
		hours: "",
		cost: ""
	})

	const checkhasEdited = (fd) => {
		if (JSON.stringify(fd) !== JSON.stringify(originalFormData)) {
			return true
		} else {
			return false
		}
	}

	const handleSubmit = async (e, fd) => {
		e.preventDefault()
		if (checkhasEdited(fd)) {
			// if the usser is not logged in
			if (!currentUser) {
				return setErrorObj({message: "PLease log in to edit project", noReport: true})
			}
			// if the project update has currentProject true
			if (fd.isSetToCurrentProject) {
				// if this project is not already currentProject, set it in userObject
				if (!originalFormData.isSetToCurrentProject) {
					updateUserData({currentProject: projectData.id})
				}
			} else {
				// if the user unselected current project
				if (originalFormData.isSetToCurrentProject) {
					// remove the current poject
					updateUserData({currentProject: ""})
				}
			}

			let newProjectData = {...fd}
			delete newProjectData.isSetToCurrentProject
			await saveProject({newProjectData: {...projectData, ...newProjectData}})
			setHasBeenEdited(false)
		} else {
			setShowProjectSummary(false)		
		}
	}

	const getAndSetTotals = (invoiceData) => {
		let newCost = 0
		let newHours = 0
		invoiceData.forEach(inv => {
			if (inv.invoiceTotals) {
				newHours += inv.invoiceTotals.laborEntries ? getNum(inv.invoiceTotals.laborEntries.qty, 100) : 0
				newCost += inv.invoiceTotals.laborEntries ? getNum(inv.invoiceTotals.laborEntries.total, 100) : 0
				newCost += inv.invoiceTotals.materialEntries ? getNum(inv.invoiceTotals.materialEntries.total, 100) : 0
				newCost += inv.invoiceTotals.customEntries ? getNum(inv.invoiceTotals.customEntries.total, 100) : 0

			}
		})
		setTotals(totals => ({...totals, cost: newCost, hours: newHours}))
	}

	const handleChange = (name, value) => {
		let newValue = value
		let newData = {
			[name]: newValue,
		}

		if (name === "visibility" && value === "private") {
			newData = {
				...newData,
				searchable: false
			}
		}

		setFormData(formData => ({...formData, ...newData}))
	}

	useEffect(() => {
		if (projectData) {
			if (invoiceData && invoiceData.length) {
				getAndSetTotals(invoiceData)
			}
			if (checkhasEdited(formData)) {
				setHasBeenEdited(true)
			} else {
				setHasBeenEdited(false)
			}

		}
		// eslint-disable-next-line
	}, [projectData, invoiceData, userCanView, formData])

	let inputs = [
		{
			label: userObject.id ? "My Current Project" : "",
			onChange: handleChange,
			beforeInput: [
				<div key="stats">
					<span>Total Project Cost:&nbsp;</span>
					<span>${getNum(totals.cost, 2)}</span>
					<div className="small-line-break" />
					{
						totals.hours > 0 &&
						<React.Fragment>
							<span>Total Project Hours:&nbsp;</span>
							<span>{getNum(totals.hours)}</span>
							<div className="small-line-break" />
						</React.Fragment>
					}
					<div>Followers&nbsp;
						<span onClick={e => setShowFollowers(showFollowers => !showFollowers)}>
							{
								showFollowers ? 
								<svg id="minus" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							    <path d="M2 16 L30 16" />
								</svg>
								: <svg id="plus" viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							    <path d="M16 2 L16 30 M2 16 L30 16" />
								</svg>
							}
						</span>
						{
							showFollowers && followersUserObjs.map(user => {
								return ( 
									<div key={user.id} className="value">
										- <Link className="link-appearance" to={`/users/${user.username}`}>{user.username}</Link> 
										&nbsp;
										{
											userIsOwner || (currentUser && userCanEdit && currentUser.uid === user.id) ? 
											<select name={user.id} value={user.type} onChange={e => handleChangeUserCapabilities(e.target.name, e.target.value, projectData, setProjectData, "projects", followersUserObjs, setFollowersUserObjs, userIsOwner, currentUser.uid, setErrorObj, handleSetSuccessText, dialog)}>
												{
													currentUser.uid !== user.id &&
													<option value="none">remove</option>
												}
												<option value="viewer">viewer</option>
												<option value="editor">editor</option>
												<option value="owner" disabled={!userIsOwner} >owner</option>
											</select>
											: <span>({user.type})</span>
										}
									</div>
								)
							})
						}
					</div>
				</div>,
				<React.Fragment key="visibility-info">
					{
						(!userCanEdit && !userIsBillTo) &&
						<div>
							<div className="small-line-break" />
							{`Visibility: ${formData.visibility} `}
							<MoreInfoIcon text={formData.visibility === "private" ? "Only followers can view project data" : "Anyone with the link can view project data"} />
						</div>
					}
					<div className="section-divider" />
				</React.Fragment>,
				<div key="settings" className="section-title">{userObject.id ? "Settings" : ""}</div>
			],
			properties: {
				type: userObject.id ? "checkbox" : "hidden",
				id: "isSetToCurrentProject",
				value:  !formData.isSetToCurrentProject,
				checked: formData.isSetToCurrentProject
			}
		},
		{
			label: "Project Name", 
			onChange: handleChange,
			visible: userCanEdit,
			properties: {
				type: "text",
				id: "projectName",
				value: formData.projectName,
				autoFocus: "autoFocus",
				disabled: !userCanEdit
			}
		},
		{
			label: "3 letter quick ID", 
			onChange: handleChange,
			visible: userCanEdit,
			properties: {
				type: "text",
				id: "shortHandId",
				value: formData.shortHandId,
				maxLength: 3,
				minLength: 3,
				required: true,
				disabled: !userCanEdit
			},
			afterInput: [
				<div key="lb1" className="small-line-break" />
			]
		},
		{
			custom: true,
			label:"Visibility",
			visible: userCanEdit || userIsBillTo,
			properties: {
				type: "dropdown",
				name: "visibility",
				options: [{name: "public"}, {name: "private"}],
				optionVal: "name",
				value: formData.visibility,
				onChange: handleChange,
				disabled: (!userIsBillTo && !userIsOwner)
			},
			insideInputDiv: [
				<React.Fragment key="visibilityInfo" >
					&nbsp;<MoreInfoIcon text={formData.visibility === "private" ? "Only followers can view project data" : "Anyone with the link can view project data" } />
				</React.Fragment>
			]
		},
		{
			label:(userIsOwner || userIsBillTo) ? "Show Project in public search results" : "",
			visible: formData.visibility === "public",
			onChange: handleChange,
			properties: {
				type: (userIsOwner || userIsBillTo) ? "checkbox" : "hidden",
				id: "searchable",
				value: !formData.searchable,
				checked: formData.searchable,
				disabled: (!userIsBillTo && !userIsOwner)
			},
		},
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "hiddenInput2",
				onChange: () => null,
			},
			beforeInput: [
				<div key="billTo">
					{
						(userIsOwner || userCanEdit || userIsBillTo || projectData.billTo.firstname || projectData.billTo.lastname || projectData.billTo.username) &&
						<div>
							<div className="section-divider" />
							<div className="section-title">Billed To</div>
						</div>
					}
					{
						projectData.billTo.uid ?
							<Link className="link-appearance" to={`/users/${projectData.billTo.username}`}>@{projectData.billTo.username}</Link> 
						:
							<div>{`${projectData.billTo.firstname || ""} ${projectData.billTo.lastname || ""} `}</div>
					}
					{
						(userIsOwner || userIsBillTo || userCanEdit) &&
						<div>
							<span className="link-appearance small blue" onClick={(e) => {
								e.preventDefault()
								setShowProjectSummary(false)
								setChangeBillToModalOpen(true)
							}}>Bill To settings</span>
						</div>
					}
					<div className="small-line-break" />
				</div>
			],
		}
	]

	return (
			<Form
				inputs={inputs} 
				heading={
					<React.Fragment >
						<div className="heading" >Details & Summary</div>
						<AppMessage />
					</React.Fragment>
				} 
				submitName={hasBeenEdited ? "Save" : "Ok"}
				onSubmit={e => handleSubmit(e, formData)} 
			>
			</Form>
	)
}

export default ProjectSummary