import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import { MessagingContext } from "../../contexts/MessagingContext";
import { UserContext } from "../../contexts/UserContext"; 

import { getFriendlyDate } from "../../utils/dateUtils";
import { handleLogoImageOnError } from "../../utils/appUtils";
// import firebase from "../firebase";
import styled from 'styled-components'



const ThreadsStyle = styled.ul `
	margin: 0;
	padding: 0;
	text-align: left;
	list-style: none;
	.no-threads-loading { 
		text-align: center;
	}
	p {
		margin: 0;
		padding: 0;
	}
	.thread-container.active {
		// border: inset;
		border-width: 2px;
		// border: none;
		background-color: var(--nav-item-hover);
	}
	.thread-container {
		list-style: none;
    padding: 10px 2px;
    padding: 8px 2px;
    border: outset;
    display: flex;
    border-width: 2px;
    align-items: center;
    font-size: var(--font-size-s);
		> span {
			padding: 0 5px; 
	    align-items: center;
		}
		a {
			display: flex;
			text-decoration: none;
	    align-items: center;
	    position: relative;
	    width: 100%;
	    color: var(--chicago);
		}
		.new-message-indicator {
			// height: 10px;
			// width: 10px;
			// border-radius: 50%;
			// background-color: var(--brand);
			// padding: 5px;
			margin: 2px;
			> svg {
				height: 15px;
				width: 15px;
			}
		}
		.participants {
	    display: flex;
	    justify-content: start;
	    flex-flow: row-reverse;
	    border-radius: 25px;
      margin-right: 10px;
	    span {
		    &:last-child {
		    	margin-left: 0;
		    }
				margin-left: -30px;
				img {
					overflow: hidden;
					height: 50px;
					width: 50px;
			    box-shadow: 2px 2px 15px -5px;
			    border-radius: 50%;
				}

	    }
		}

		.newest-message {
      overflow: hidden;
	    text-overflow: ellipsis;
	    width: 100%;
			p {
		  	white-space: pre-wrap;
				max-height: 45px;
				text-overflow: ellipsis;
				display: block;
				overflow: hidden;
			}
			.message-title {
		    margin-right: 20px;
		    font-size: var(--font-size-m);
		    white-space: nowrap;
		    text-overflow: ellipsis;
		    overflow: hidden;
			}
		}

	  .time-info {
	  	font-size: var(--font-size-xs);
	  	white-space: nowrap;
	    align-self: start;
	    position: absolute;
	    top: -4px;
	    right: 0;
	    padding: 0 8px 0px 8px;
	  }
	  .arrow {
	  	color: var(--chicago);
	  	svg {
	  		height: 20px;
	  		width: 20px;
	  	}
	  }

	}
`

const MessageThreads = () => {
	const {
		usersThreads,
		currentThreadData
	} = useContext(MessagingContext);
  const {
		// setErrorObj,
  	userObject
  } = useContext(UserContext)

  let threadComponents = []

	if (usersThreads && usersThreads.length) {
		threadComponents = usersThreads.map(thread => {

	  	let newestMessagePreview = "Send a message..."
	  	const lastMessageTimeSent = getFriendlyDate(thread.lastUpdated)
  		let hasNewMessages = false
  		const userInThread = thread.participants[userObject.id]

  		if (userInThread) {
  			if (!userInThread.lastMessageRead || userInThread.lastMessageRead !== thread.newestMessage.id) {
	  			hasNewMessages = true
  			}
  		}

	  	if (thread.newestMessage) {
	  		if (thread.newestMessage.text) {
		  		newestMessagePreview = thread.newestMessage.text
	  		}

	  	}

	  	let participantIcons = []
	  	let usernames = []
	  	for (let key in thread.participants) {
	  		const user = thread.participants[key]

	  		if (key !== userObject.id) {
	  			participantIcons.push(
  					// <a key={key} className="img-container" href={`/users/${user.username}`} >
  					<span key={key}>
			  			<img src={user.logoUrl || "/assets/blank-profile-picture.png"} alt="" 
				  			onError={handleLogoImageOnError}
			  			/>
		  			</span>
		  			// </a>
	  			)
		  		usernames.push(user.username)
	  		}
	  	}	

	  	return (
	  		<li key={thread.id} className={`thread-container ${(currentThreadData && currentThreadData.id === thread.id) ? "active" : ""}`}>
	  			<Link to={`/messages?threadId=${thread.id}`} >
	  				<span className="new-message-indicator"> 
	  				{
	  					hasNewMessages &&
		  				<svg viewBox="0 0 32 32" fill="var(--brand)" stroke="var(--brand)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
						    <circle cx="14" cy="14" r="12" />
							</svg>
	  				}
	  				</span>
		  			<span className="participants" >
		  				{participantIcons}
		  			</span>
		  			<span className="newest-message" >
		  				<p className="message-title" ><strong>{usernames.join(", ")}</strong></p>
			  			<p>{newestMessagePreview}</p>
		  			</span>
		  			<span className="time-info" >
		  				{lastMessageTimeSent}
		  			</span>
		  			<span className="arrow" >
		  				<svg viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
						    <path d="M12 30 L24 16 12 2" />
							</svg>
		  			</span>
	  			</Link>
  			</li>
	  	)
	  }).reverse()
	} else {
		threadComponents = <li className="no-threads-loading">
		{
			usersThreads ? 
			<div>
				<p>You have no messages!</p>
				{
					!currentThreadData && 
					<React.Fragment>
						<div className="section-divider" />
						<p className="tiny">Visit a users profile to send them a message</p>
					</React.Fragment>
				}
			</div>
			: userObject.id ? 
			<p>...loading</p>
			: <p><Link to={"?action=promptLogin"}>Log in</Link> to view messages
			</p>
		}
		</li>
	}

  return (
  	<ThreadsStyle>
			{threadComponents}
		</ThreadsStyle>
  )
}

export default MessageThreads