import React, { useRef, useContext } from 'react'
import styled from 'styled-components'
import { MediaQueryContext } from "../../contexts/MediaQueryContext";

const ImageCaroselStyle = styled.div `
	position: relative;
	display: flex;
	overflow: hidden;
	margin: 0 -20px 30px -20px;
	.wrapper::-webkit-scrollbar {
	  height: 0px;
	  display: none;
	}
	.wrapper::-webkit-scrollbar-track {
	  background: transparent;
	}
	.wrapper::-webkit-scrollbar-thumb {
	  background: transparent;
	}
	.wrapper::-webkit-scrollbar-thumb:hover {
	  background: transparent;
	}
	.nav-arrows {
	  color: var(--titan-white);
	  cursor: pointer;
	  height: 100%;
	  position: absolute;
	  top: 0;
	  background: linear-gradient(180deg, transparent, transparent, var(--brand), transparent, transparent);
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  padding: 0px 4px;
	  z-index: 2;
	  font-size: 32px;
	}
	.next {
	  right: 0;
	}
	.prev {
	  left: 0;
	}
	.hide {
	  visibility: hidden;
	}

	.wrapper.mobile {
	  margin: 0 auto;
	  > ul {
	    li {
	      .main-photo {
	        width: calc(100vw - 20px);
	        object-position: center;
	        aspect-ratio: 1 / 1;
	        object-fit: cover;
	      }
	    }
	  }
	}

	.wrapper.single-photo {
	  overflow: clip;
	  margin: 0 auto;
	}

	.wrapper {
	  overflow-x: scroll;
	  .top-info-tags {
	    text-align: left;
	    position: absolute;
	    top: 0;
	    left: 0;
	    > span {
	      background-color: var(--transparent-black);
	      font-size: var(--font-size-xs);
	      color: var(--white-text);
	      padding: 0px 8px;
	      border-radius: 10px;
	      margin: 1px;
	    }
	  }
	  .overlay-top {
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    text-align: center;
	    color: var(--titan-white);
	    background: linear-gradient(180deg, black, transparent);
	    padding: 2px 5px 20px 5px;
	    .recommended-score {
	      position: absolute;
	      top: 0;
	      right: 0;
	      font-size: var(--font-size-xxs);
	      display: inline-block;
	      background-color: var(--brand-green);
	      padding: 0px 5px;
	      border-radius: 10px;
	      margin: 1px;
	    }
	    h2 {
	      font-size: var(--font-size-m);
	      margin: 12px 5px 0 5px;
	    }
	  }
	  .overlay-bottom {
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    text-align: left;
	    font-size: var(--font-size-s);
	    color: var(--titan-white);
	    background: linear-gradient(0deg, black, transparent);
	    padding: 20px 5px 2px 5px;
	    p {
	      margin: 0;
	    }
	  }

	  .overlay-left {
	    position: absolute;
	    bottom: 0px;
	    top: 0px;
	    left: 0px;
	    text-align: left;
	    font-size: var(--font-size-s);
	    color: var(--titan-white);
	    background: linear-gradient(90deg, black, transparent);
	    padding: 2px 20px 2px 10px;
	    display: flex;
	    margin-top: auto;
	    flex-direction: column;
	    justify-content: center;
	  }
	  > ul {
	    width: max-content; // mobile
	    position: relative;

	    display: grid;
	    gap: 10px;
	    grid-auto-flow: column;
	    li {
	      position: relative;
	      .main-photo {
	        width: 300px;
	        object-position: center;
	        aspect-ratio: 1 / 1;
	        object-fit: cover;
	      }
	    }
	  }
	}
`

const ImageCarosel = ({ photos, parentContainerWidth, children }) => {
  const { isLessThan700px } = useContext(MediaQueryContext);

	const scrollWrapper = useRef(null)
	const animationRef = useRef(null)

	const handleWrapperScroll = (e) => {
		const wrapper = e.target
		let liWidth
		let ul

		if (wrapper) {
			ul = wrapper.firstElementChild
			const lastListItem = [...ul.children].reverse()[0]
			const liMargin = 10
			liWidth = lastListItem ? lastListItem.clientWidth + liMargin : 0//should be 125 px
		}

		if (liWidth > 0) {
			const prevArrow = wrapper.previousElementSibling
			const nextArrow = wrapper.nextElementSibling

			//shouldShowNavArrows >> not needed because cant scroll left if less than 3 images
			if (wrapper.scrollLeft >= (liWidth * 0.2)) {
				if (prevArrow.classList.contains("hide")) {
					prevArrow.classList.remove("hide")
				}
			} else {
				if (!prevArrow.classList.contains("hide")) {
					prevArrow.classList.add("hide")
				}
			}

			// if the amount of px scrolled left is greater than the containers full width - half lI item
			// (if almost at the end)
			if ((wrapper.scrollLeft + wrapper.clientWidth) >= (ul.clientWidth - (liWidth * 0.2))) {
				if (!nextArrow.classList.contains("hide")) {
					nextArrow.classList.add("hide")
				}	
			} else {
				if (nextArrow.classList.contains("hide")) {
					nextArrow.classList.remove("hide")
				}
			}

		}
	}


	const handleQuickScroll = (scrollWrapper, scrollRight) =>  {
	  const runAnimation = (resScroll) => {
	  	let multiplier = 0.1
	  	let scrollingBack = false
	  	let scrollStep = Math.floor(resScroll * multiplier)

	  	const wrapper = scrollWrapper.current

	  	if (resScroll < wrapper.scrollLeft) {
	  		scrollingBack = true
	  		scrollStep = Math.floor((resScroll - wrapper.scrollLeft) * multiplier)
	  	}

	  	if (animationRef.current) {
		  	cancelAnimationFrame(animationRef.current)
	  	}

	  	let startTime = performance.now()
	  	const animate = (time) => {
	  		let shouldStep = (wrapper.scrollLeft + scrollStep) < resScroll

	  		if (scrollingBack) {
		  		shouldStep = (wrapper.scrollLeft + scrollStep) > resScroll
	  		}

	  		if (shouldStep) {
		  	  const diff = time - startTime
		  	  // cancel if this animation is taking more than 1000 ms
		  	  if (diff > 1000) {
			  		cancelAnimationFrame(animationRef.current)
			  		console.warn("Animation timeout", {diff})
		  	  } else {
		  			wrapper.scrollLeft += scrollStep;
			  	  animationRef.current = requestAnimationFrame(animate);
		  	  }

	  		} else {
		  	  wrapper.scrollLeft = resScroll;
		  	  animationRef.current = requestAnimationFrame(animate);
		  		cancelAnimationFrame(animationRef.current)
	  		}
	  	};


	  	animationRef.current = requestAnimationFrame(animate);
	  }

		if (scrollWrapper.current) {
			let resScroll
			const scrollx = scrollWrapper.current.scrollLeft

			const ul = scrollWrapper.current.firstElementChild
			const lastListItem = [...ul.children].reverse()[0]
			const liMargin = 10
			const liWidth = lastListItem ? lastListItem.clientWidth + liMargin : 0//should be 125 px
			const maxScroll = ul.clientWidth - scrollWrapper.current.clientWidth

			if (scrollRight) {
				const itemsScrolled = Math.round(scrollx / liWidth)
				if (scrollx < liWidth) {
					resScroll = liWidth
				} else {
					resScroll = (itemsScrolled * liWidth) + liWidth
				}

				// catch if overscroll
				if (resScroll > maxScroll) {
					resScroll = maxScroll
				}
			} else {
				const itemsScrolled = Math.round(scrollx / liWidth)
				resScroll = (itemsScrolled * liWidth) - liWidth
			}

			// scrollWrapper.current.scrollTo(resScroll, 0)
			runAnimation(resScroll)
		}

	}


	const shouldShowNavArrows = isLessThan700px ? (children.length > 1) : (children.length > 2) 

		let wrapperClassNames = `wrapper ${isLessThan700px ? "mobile" : ""}`
		if (children.length === 1) {
			wrapperClassNames += " single-photo"
		}
	  return (
	   	<ImageCaroselStyle >
				<div onClick={() => {
					handleQuickScroll(scrollWrapper, false)
				}} className="nav-arrows prev hide" >
					&#10094;
				</div>
				<div className={wrapperClassNames} ref={scrollWrapper} onScroll={handleWrapperScroll}>
					<ul className="scroll-images" >
						{children}
					</ul>
				</div> 
				<div onClick={() => {
					handleQuickScroll(scrollWrapper, true)
				}} className={`nav-arrows next ${shouldShowNavArrows ? "" : "hide"}`} >
					&#10095;
				</div>
	   	</ImageCaroselStyle> 
	  )
}

export default ImageCarosel