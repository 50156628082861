import React, { useState, useContext, useEffect } from "react";
import firebase from '../firebase';
import { UserContext } from "./UserContext";

const MessagingContext = React.createContext();
	

// existing and new messages are stored in threads
const MessagingContextProvider = ({ children }) => {
	// const [dialogState, setDialogState] = useState({open: false, type: "", message: "", input: ""});
	const user = firebase.auth().currentUser

	const {
		userObject,
		setErrorObj,
		offlineMode
	} = useContext(UserContext);
	const [currentThreadData, setCurrentThreadData] = useState(null)

	const [usersThreads, setUsersThreads] = useState(null) 
	const [lastMessageRetrieved, setLastMessageRetrieved] = useState(null)
	const [reachedEndOfMessages, setReachedEndOfMessages] = useState(false)
	const [sendingMessage, setSendingMessage] = useState(false)
	// Only the DB subscription should ever set this state
	const [newestThreadData, setNewestThreadData] = useState(null)


	const handleSetNewestThreadData = (data, caller) => {
		// this always overwrites the current newest thread data
		// this way the useEffect that sets up the subscription to threads 
		// never needs to care if setNewestThreadData changes, we dont need to include it in th dependancy array
		if (!caller || caller !== "onSnapshot") {
			console.error("must not call handleSetNewestThreadData or setNewestThreadData with anything other than onSnapshot")
		} else {
			setNewestThreadData(data)
		}
	}

	// get a message thread
	const handleRetrieveMessages = async ({thread, lastDoc, limit}) => {
		try {

			// only get messages if we havent already reached end of messages
			if (reachedEndOfMessages) {
				return true
			}
			let query = firebase.firestore()
				.collection("messageThreads")
				.doc(thread.id)
				.collection("messages")
				.orderBy("timestamp", "desc") 
			if (lastDoc) {
				query = query.startAfter(lastDoc)
			}
			if (limit) {
				query = query.limit(limit)
			}
			const newMessages = await query.get()
				.then(messages => {
					setLastMessageRetrieved(messages.docs[messages.docs.length -1])
					if (limit && limit > messages.docs.length) {
						setReachedEndOfMessages(true)
					}
					// const reversedMessages = messages.docs.reverse()
					// return messages.map(message => ({...message.data(), id: message.id}))
					let newMessagesObj = {}
					messages.docs.forEach(message => {
						newMessagesObj[message.id] = {...message.data(), id: message.id}
					})
					return newMessagesObj
				})

			// set the messages into the thread
			addMessagesToThread({newMessages, threadId: thread.id})
			return true
		} catch (err) {
			setErrorObj(err)
		}

	}

	const handleSendMessage = async (text) => {
		// const thread = usersThreads.find(thread => thread.id === threadId)

		// update the thread
		try {
			const cleanedThreadData = {
				...currentThreadData
			}
			setSendingMessage(true)
			const messageThreadRef = firebase.firestore().collection("messageThreads").doc(currentThreadData.id)
			const newMessageId = messageThreadRef.collection("messages").doc().id
			const firebaseTimestamp = firebase.firestore.Timestamp.now().seconds

			cleanedThreadData.participants[userObject.id] = {
				logoUrl: userObject.logoUrl || "",
				username: userObject.username,
				lastMessageRead: newMessageId,
				lastMessageReadAt: firebase.firestore.Timestamp.now().seconds
			}
			cleanedThreadData.newestMessage = {
				text,
				sender: userObject.id,
				id: newMessageId,
				timestamp: firebaseTimestamp,
			}
			delete cleanedThreadData.messages

			// console.log("check to see if offline we can use .then after thread update and it will work to add a message to the messages subcollection ")
			if (offlineMode) {
				messageThreadRef.set({
					...cleanedThreadData,
					lastUpdated: firebaseTimestamp
				})
				// update the message
				messageThreadRef.collection("messages").doc(newMessageId).set({
					text,
					timestamp: firebaseTimestamp,
					sender: userObject.id
				})
			} else {
				// add the thread
				// const batch = firebase.firestore().batch()


				await messageThreadRef.set({
					...cleanedThreadData,
					lastUpdated: firebaseTimestamp
				}, {merge: true})
				
				// update the message
				await messageThreadRef.collection("messages").doc(newMessageId).set({
					text,
					timestamp: firebaseTimestamp,
					sender: userObject.id
				})

				setSendingMessage(false)

			}


			// add text to ui ...?
		} catch (err) {
			setErrorObj(err)
		}
	}

	const addMessagesToThread = ({newMessages, threadId}) => {
		// new messages must be type object
		let newThreadWithMessages = {}

		const threadInUsersThreads = usersThreads.find(thread => thread.id === threadId)
		if (threadInUsersThreads) {
			newThreadWithMessages = {
				...threadInUsersThreads,
				messages: {
					...threadInUsersThreads.messages,
					...newMessages
				}
			}

			handleSetUserThreads({
				newThreads: [newThreadWithMessages],
				setUsersThreads,
				// type: "addToExisting",
				caller: "addMessagesToThread"
			})
		} else {
			console.log("no such thread found in users threads... usersThreads may be old in this fn invocation", {usersThreads, threadId})
		}
	}

	// set the users threads state
	const handleSetUserThreads = ({newThreads, setUsersThreads, type, caller}) => {
		setUsersThreads((usersThreads) => {
			let newUsersThreads = []

				// newThreads only includes the newest message in the message property so we dont want to overwrite the messages property in a previous thread state otherwise we will have to get all the messages again
				// if a thread no longer exists, dont add it back in
				// get any message properties of the threads since newThreads wont contain message property

				newUsersThreads = newThreads.map(t => {
					// se if we have already gotten messages for this thread
					const threadInUsersThreads = usersThreads && usersThreads.find(thread => thread.id === t.id)
					let foundMessagesForThread
					if (threadInUsersThreads) {
						foundMessagesForThread = threadInUsersThreads.messages
					}

					if (foundMessagesForThread) {
						// add the messages to the thread
						return {
							...t,
							messages: {
								...t.messages, // should only ever be the newest message
								...foundMessagesForThread
							}
						}
					} else return t
				})

				// filter out messages from existing threads that are updated
				// let filteredOldThreads = usersThreads && usersThreads.filter(t => !(newUsersThreads.find(thread => thread.id === t.id)))
				// loop through old threads and replace ones that are updated with the ipdated version
				// .map preserves the order
				let updatedOldThreads = usersThreads && usersThreads.map(t => {
					const updatedThread = newUsersThreads.find(thread => thread.id === t.id)
					if (updatedThread) {
						return {
							...updatedThread
						}
					} else return t
				})

				// get only new, not updated threads
				let addedThreads 
				if (updatedOldThreads) {
					addedThreads = newUsersThreads.filter(t => !(updatedOldThreads.find(th => th.id === t.id)))
				} else {
					addedThreads = newUsersThreads
				}

				// stack new threads on the bottom
				newUsersThreads = [
					...updatedOldThreads || [],
					...addedThreads || []
				]

				// sort the threads 
				newUsersThreads = newUsersThreads.sort((a, b) => {
					const d1 = a.lastUpdated;
					const d2 = b.lastUpdated;
					return d1 - d2;
				});

			return newUsersThreads
		})

	}

	// listen for changes to NewestThreadData and decide whether to overwrite existing thread data or not
	useEffect(() => {
		// if user is present
		if (newestThreadData) {
			handleSetUserThreads({newThreads: newestThreadData, setUsersThreads, caller: "useEffect in MessagingContext: newestThreadData"})
		}
		// // if the user logged out erase thread data
		// if (newestThreadData && (!user || !user.uid)) {
		// 	handleSetUserThreads({newThreads: null, setUsersThreads, type: "overwrite"})
		// }
		// dont need setUsersThreads in dep array becuase if newestThread data changes setUsersThreads will be up to date again
	}, [newestThreadData])

	// get user threads and their new messages
	useEffect(() => {
		let unsubscribe 

	  if (user && user.uid) {
	  	unsubscribe = firebase.firestore().collection("messageThreads")
	  	.where("accessors", "array-contains", user.uid)
	  	.orderBy("lastUpdated")
	  	.onSnapshot(snapshot => {
	  		if (snapshot.docs.length) {
		  		const threads = snapshot.docs.map(thread => ({
		  			...thread.data(), 
		  			id: thread.id,
		  			messages: {
		  				[thread.data().newestMessage.id]: thread.data().newestMessage
		  			}
		  		}))
		  		handleSetNewestThreadData(threads, "onSnapshot")
	  		} else {
		  		handleSetNewestThreadData([], "onSnapshot")
	  		}
	  	}, (err) => {
	  		setErrorObj(err)
	  	})
	  }

	  return () => {
	  	if (unsubscribe) {
	  		unsubscribe()
	  	}
	  }
	}, [user, setErrorObj])


	return (
		<MessagingContext.Provider
			value={{
				handleSendMessage,
				usersThreads,
				handleSetUserThreads,
				addMessagesToThread,
				currentThreadData,
				setCurrentThreadData,
				handleRetrieveMessages,
				lastMessageRetrieved,
				setLastMessageRetrieved,
				reachedEndOfMessages,
				setReachedEndOfMessages,
				sendingMessage,
			}}
		>
			{children}
		</MessagingContext.Provider>
	);
};

export { MessagingContextProvider, MessagingContext };
