import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";
import { DialogContext } from "../../contexts/DialogContext";


import Spinner from "../Spinner/Spinner";
import SymbolFromId from "../svgComponents/SymbolFromId";
import DotActions from "../DotActions/DotActions";

import {
	makeCopyOfInvoice,
	handleRemoveInvoice,
} from "../Invoice/invoiceUtils";
import { handleUnfollow, handleAddFollower } from "../../utils/followUtils";
import firebase from "../../firebase/index";

const sortByRecentEntryDate = (list) => {
	// sorts by mostRecentEntryDate with newest date first
	let sortableItems = [];
	let nonSortableItems = [];

	list.forEach((item) => {
		if (item.mostRecentEntryDate) {
			sortableItems.push(item);
		} else {
			nonSortableItems.push(item);
		}
	});

	sortableItems = sortableItems.sort((a, b) => {
		const d1 = new Date(a.mostRecentEntryDate);
		const d2 = new Date(b.mostRecentEntryDate);
		// sort by most recent on top
		return d2 - d1;
	});

	return [...sortableItems, ...nonSortableItems];
};

export const InvoiceLiComponent = ({
	withinProject,
	doc,
	i,
	liClassNames,
	setDocList,
	collection,
	documentsSelectable,
	selections,
	setSelections,
	maxSelections,
	hasPairedInvoice,
	userHasLeviedThisInvoice,
	leviedInvoiceInUsersInvoices,
}) => {
	const { dialog } = useContext(DialogContext);

	const {
		userObject,
		setUserObject,
		setUsersInvoices,
		setErrorObj,
		handleSetSuccessText,
		// offlineMode,
		removeDocFromRecentlyViewed,
	} = useContext(UserContext);

	const userId = firebase.auth().currentUser
		? firebase.auth().currentUser.uid
		: null;

	const userIsOwner = userId ? userId === doc.owner : false;
	const userIsFollower =
		doc.followers && userId ? doc.followers.includes(userId) : false;

	const handleCopyInvoice = async (type, withinProject) => {
		try {
			const whenLoading = (fd) => {
				// add to recentlyViewed
				setDocList((list) => {
					if (list) {
						const indexInDocList = list.findIndex((doc) => doc.id === fd.id);
						if (indexInDocList > -1) {
							let newDocList = [...list];
							newDocList[indexInDocList] = {
								...list[indexInDocList],
								isLoading: true,
							};
							return newDocList;
						} else {
							// find out where to put the newly copied invoice...
							// "i" is useless because represents where thi copied from invoice sits on the UI
							// the ui list is filtered to only include invoices that are not bills or part of a project
							let indexOfCopiedFrom = list.findIndex(doc => doc.id === fd.templateCopyOf)

							if (fd.fullCopyOf) {
								indexOfCopiedFrom = list.findIndex(doc => doc.id === fd.fullCopyOf)
							}
							const newList = [
								...list.slice(0, indexOfCopiedFrom),
								{ ...fd, isLoading: true },
								...list.slice(indexOfCopiedFrom),
							]

							return newList;
						}
						
					} else return list
				});
			};

			let includeEditors;
			let includeFollowers;
			let includeBillTo;
			let includeForProject;

			// only automatically include billTo, editors and followers if within project
			if (withinProject) {
				includeForProject = true;

				if (type === "fullCopy") {
					includeEditors = true;
					includeFollowers = true;
					includeBillTo = true;
				}
			}

			const fd = await makeCopyOfInvoice({
				i,
				type,
				inv: doc,
				whenLoading,
				userObject,
				includeEditors,
				includeFollowers,
				includeBillTo,
				includeForProject,
				setUserObject,
			});

			if (collection === "recent") {
				const linkName = `#${fd.invNumber} ${fd.invShortHand}`;
				const docType = userId && fd.billTo.uid === userId ? "bill" : "invoice";
				const exisitingRecentlyViewedDocs = userObject.recentlyViewedDocs ? userObject.recentlyViewedDocs : []

				const newRecentlyViewedDocs = [
					...exisitingRecentlyViewedDocs.slice(0, i + 1),
					{
						id: fd.id,
						linkName,
						docType,
						relUrl: `/${doc.contractor ? doc.contractor.username : "invoice"}/${
							fd.id
						}`,
					},
					...exisitingRecentlyViewedDocs.slice(i + 1),
				];
				setUserObject((userObject) => ({
					...userObject,
					recentlyViewedDocs: newRecentlyViewedDocs,
				}));

				setUsersInvoices((usersInvoices) => {
					// const newUsersInvoices = [...usersInvoices.slice(0, i+1), {...fd, isLoading: true}, ...usersInvoices.slice(i+1) ]

					return sortByRecentEntryDate([...usersInvoices || [], fd]);
					// dont sort by sortByRecentEntryDate ?
					// return newUsersInvoices
				});
			}

			setDocList((docList) => {
				if (docList) {
					let newDocList = [...docList];
					const indexInDocList = docList.findIndex((doc) => doc.id === fd.id);
					if (indexInDocList > -1) {
						newDocList[indexInDocList] = {
							...docList[indexInDocList],
							isLoading: false,
						};
					} else {
						newDocList = [
							...docList.slice(0, i),
							{ ...fd, isLoading: false },
							...docList.slice(i + 1),
						];
					}

					return newDocList;
					
				} else return docList
			});

			handleSetSuccessText(
				`Made copy of inv #, ${doc.invNumber} ${doc.invShortHand}!`
			);
		} catch (err) {
			// remove any loading docs from the list if error
			setDocList((docList) => {
				return docList ? docList.filter(doc => !doc.isLoading) : docList;
			});
			setErrorObj(err);
		}
	};
	// collection 'recents' structure:
	// {id: invoiceId, linkName: invoiceName, docType, relUrl: window.location.href},
	let linkToUrl = `/${doc.contractor ? doc.contractor.username : "doc"}/${
		doc.id
	}`;
	if (doc.relUrl) {
		linkToUrl = doc.relUrl;
	}

	let linkName = `#${doc.invNumber || ""} ${doc.invShortHand || ""}`;
	if (doc.linkName) {
		linkName = doc.linkName;
	}

	let linkToLeviedInvoice = ""
	let leviedInvoiceName = ""
	if (userHasLeviedThisInvoice && leviedInvoiceInUsersInvoices) {
		// leviedInvoiceName = `#${leviedInvoiceInUsersInvoices.invNumber} ${leviedInvoiceInUsersInvoices.invShortHand}`
		leviedInvoiceName = "Open Levied Invoice"
		linkToLeviedInvoice = `/${leviedInvoiceInUsersInvoices.contractor ? leviedInvoiceInUsersInvoices.contractor.username : "doc"}/${leviedInvoiceInUsersInvoices.id}`;
	}

	let canUseDotActions = false;

	// check if the invoice (doc) object is the full invoice object from DB
	if (doc.invShortHand && doc.pageOrder) {
		canUseDotActions = true;
	}

	return (
		<li key={doc.id} className={liClassNames}>
			{
				userHasLeviedThisInvoice &&
				<div className="info-banner">
					Levied
				</div>
			}
			<Link className="link-appearance symbol-container" to={linkToUrl}>
				{doc.isLoading && (
					<div className="spinner">
						<Spinner position="relative" height="80px" width="80px" />
					</div>
				)}
				<SymbolFromId
					id={doc.id}
					svgProperties={{
						filter: "url(#shadow1)",
						strokeWidth: "2",
					}}
				/>
				<p>{linkName}</p>
			</Link>
			{!doc.isLoading && canUseDotActions && (
				<DotActions options={{ ul: true }}>
					{userIsOwner && (
						<li
							onClick={async () => {
								// add loading spinner before deleting
								try {
									const whenLoading = () => {
										return setDocList((docList) =>
											docList ? docList.map((inv) =>
												inv.id === doc.id ? { ...inv, isLoading: true } : inv
											) : docList
										);
									};

									const removeSuccess = await handleRemoveInvoice(
										doc,
										setErrorObj,
										whenLoading,
										dialog,
									);

									if (removeSuccess) {
										if (withinProject || collection === "recent") {
											// set the docList again because withinProject does not depend on usersInvoices
											setDocList((docList) => {
												return docList ? [
													...docList.filter((inv) => inv.id !== doc.id),
												] : docList
											});
										}

										setUsersInvoices((usersInvoices) => {
											if (usersInvoices && usersInvoices.length) {
												return usersInvoices.filter((inv) => inv.id !== doc.id)
											} else {
												return usersInvoices // might be null
											}
										});

										await removeDocFromRecentlyViewed(doc);
										handleSetSuccessText(
											`Inv #${doc.invNumber} ${doc.invShortHand} removed`
										);
										// docList is usersInvoices if invoices or bills and recentDocs if recents
										// recents is updates every time usersInvoices is updates because of useEffect
										// dont set docList ? ... cant delete an invoice/project in which user is only a follower
										// setDocList(docList => docList.filter(obj => obj.id !== doc.id))
									}
								} catch (err) {
									setErrorObj(err);
								}
							}}
						>
							delete
						</li>
					)}
					<li
						onClick={async () => {
							try {
								if (userIsFollower) {
									// add loading spinner before unfollowing
									setDocList((docList) =>
										docList ? docList.map((inv) =>
											inv.id === doc.id ? { ...inv, isLoading: true } : inv
										) : docList
									);
									const unfollowSuccess = await handleUnfollow({
										userId,
										collection: "invoices",
										userIsOwner,
										docId: doc.id,
										docEditors: doc.editors,
										dialog,
									});
									if (unfollowSuccess) {
										handleSetSuccessText(
											`Unfollowed #${doc.invNumber} ${doc.invShortHand || ""}`
										);
										// need to set docList because of onlyFollowing projects/invoices
										if (!withinProject) {
											setDocList((docList) => {
												return docList ? [
													...docList.filter((i) => i.id !== doc.id),
												] : docList
										});
										} else {
											setDocList((docList) =>
												docList ? docList.map((inv) =>
													inv.id === doc.id ? { ...inv, isLoading: false } : inv
												) : docList
											);
										}
									} else {
										// change loading state whether unfollowSuccess pr not
										setDocList((docList) =>
											docList ? docList.map((inv) =>
												inv.id === doc.id ? { ...inv, isLoading: false } : inv
											) : docList
										);
									}
								} else {
									// add loading spinner before following
									setDocList((docList) =>
										docList ? docList.map((inv) =>
											inv.id === doc.id ? { ...inv, isLoading: true } : inv
										) : docList
									);
									// await handleFollow(userObject.username, userId, "invoices", null, doc, setErrorObj, handleSetSuccessText)
									const followSuccess = await handleAddFollower({
										userId,
										username: userObject.username,
										collection: "invoices",
										parentResource: doc,
									});
									if (followSuccess) {
										handleSetSuccessText(followSuccess);
									}
									if (followSuccess === "Request sent") {
										// set isLoading to false
										setDocList((docList) =>
											docList ? docList.map((inv) =>
												inv.id === doc.id ? { ...inv, isLoading: false } : inv
											) : docList
										);
									} else {
										// add follower if success and set isLoading to false
										setDocList((docList) =>
											docList ? docList.map((inv) => {
												return inv.id === doc.id
													? {
															...inv,
															followers: [
																...inv.followers,
																...(followSuccess ? userId : []),
															],
															isLoading: false,
													  }
													: inv;
											}) : docList
										);
									}
								}
							} catch (err) {
								setErrorObj(err);
							}
						}}
					>
						{userIsFollower ? "unfollow" : "follow"}
					</li>
					<li>
						{userIsOwner && (
							<details className="expand-option">
								<summary>copy</summary>
								<div
									onClick={() => handleCopyInvoice("template", withinProject)}
								>
									Template
								</div>
								<div
									onClick={() => handleCopyInvoice("fullCopy", withinProject)}
								>
									Full
								</div>
							</details>
						)}
					</li>
					<li
						onClick={(e) => {
							if (e.target && e.target.firstElementChild) {
								e.target.firstElementChild.select();
								document.execCommand("copy");
								handleSetSuccessText("Link copied");
							} else {
								console.log(
									"error no firstElementChild in e target",
									e.target,
									e.target.firstElementChild
								);
							}
						}}
					>
						copy link
						<input
							style={{ zIndex: "-1" }}
							type="text"
							className="hide-height absolute"
							defaultValue={`${window.location.href}/${
								doc.contractor ? doc.contractor.username : ""
							}/${doc.id}`}
						/>
					</li>
					{
						userHasLeviedThisInvoice &&
						<li>
							<Link className="link-appearance blue" to={linkToLeviedInvoice} >
								{leviedInvoiceName}
							</Link>
						</li>
					}
				</DotActions>
			)}
		</li>
	);
};

export const ProjectLiComponent = ({
	doc,
	i,
	liClassNames,
	setDocList,
	collection,
	documentsSelectable,
	selections,
	setSelections,
	maxSelections,
}) => {

	const { dialog } = useContext(DialogContext);
	const {
		userObject,
		usersProjects,
		setUsersProjects,
		updateUserData,
		setErrorObj,
		handleSetSuccessText,
		removeDocFromRecentlyViewed,
		// offlineMode,
	} = useContext(UserContext);

	const userId = firebase.auth().currentUser
		? firebase.auth().currentUser.uid
		: null;

	const userIsOwner = userId ? userId === doc.owner : false;
	const userIsFollower =
		doc.followers && userId ? doc.followers.includes(userId) : false;

	const handleRemoveProject = async (id, setUsersProjects, whenLoading, dialog) => {
		const project = (usersProjects && usersProjects.length) ? usersProjects.find((proj) => proj.id === id) : null;
		const firstConfirm = await dialog.confirm(
			"Warning: Anyone following this project will no longer be able to access it if you delete it"
		);
		let secondConfirm = false;
		if (!project) {
			throw new Error("message: 'error no project found'");
		}
		if (firstConfirm) {
			secondConfirm = await dialog.confirm(
				"Confirm Delete? Instead of deleting the project you could make someone else the owner then unfollow the project. \nClick OK to delete anyway"
			);
		} else return false;
		if (secondConfirm) {
			// delete the project
			whenLoading();
			// problem here again with the .then not firing if user offline
			// if no other user has this pproject than delete the project from db

			let removeCurrentProjectSuccess = true;
			// remove right away from users projects so that the project shows loading spinner
			// and so anywhere else this project is shown in dashboarsd is also removed
			// setUsersProjects(usersProjects => {
			// 	return usersProjects.filter(proj => proj.id !== id)
			// })

			if (userId && userObject.currentProject === id) {
				removeCurrentProjectSuccess = await updateUserData({
					currentProject: "",
				});
			}

			// change to not remove the value of any invoice containing the forProject of this project ID
			// user might not have permissions and invoice owner might not understand why forProject changed
			// and where it went, change only if user is owner of inv

			// change forProject property on all invoices that have this user as the owner and this project as forProject
			const removeForProjectSuccess = await firebase
				.firestore()
				.collection("invoices")
				.where("owner", "==", userId)
				.where("forProject", "==", id)
				.get()
				.then((snapshot) => {
					if (snapshot.docs.length) {
						snapshot.docs.forEach((doc) => {
							const docRef = firebase
								.firestore()
								.collection("invoices")
								.doc(doc.id);
							docRef
								.update({
									forProject: "",
								})
								.then(() => true)
								.catch((err) => {
									throw err;
								});
						});
					} else return true;
				})
				.catch((err) => {
					if (err.message.includes("Missing or insufficient permissions")) {
						return true;
					} else {
						// caughtIn property not added into error object
						// setErrorObj({...err, caughtIn: "LiComponentTypes"})
						setErrorObj(err);
						// throw err
					}
				});

			// only remove project if can remove current
			if (removeCurrentProjectSuccess && removeForProjectSuccess) {
				return firebase
					.firestore()
					.collection("projects")
					.doc(id)
					.delete()
					.then(() => {
						return true;
					})
					.catch((err) => {
						setErrorObj(err);
					});
			} else return false;
		} else return false;
	};

	let canUseDotActions = false;

	// check if doc is the full project object from DB
	if (doc.shortHandId && doc.accessors) {
		canUseDotActions = true;
	}

	let docSelected = false;
	if (
		selections &&
		selections.length &&
		selections.find((s) => s.id === doc.id)
	) {
		docSelected = true;
	}

	return (
		<li key={doc.id} className={liClassNames}>
			{documentsSelectable && maxSelections > 0 && (
				<React.Fragment>
					<input
						type="checkbox"
						className="select-document"
						id={doc.id}
						value={doc.id}
						checked={docSelected}
						name="selections"
						onChange={() => {
							setSelections((selections) => {
								if (selections.length) {
									const docInSelections = selections.find(
										(obj) => obj.id === doc.id
									);
									if (docInSelections) {
										return selections.filter((obj) => obj.id !== doc.id);
									} else return [doc, ...selections].slice(0, maxSelections);
								} else return [doc];
							});
						}}
					/>
					<label htmlFor={doc.id}></label>
				</React.Fragment>
			)}
			<Link
				className="link-appearance symbol-container"
				to={doc.relUrl ? doc.relUrl : `projects/${doc.id}`}
			>
				{doc.isLoading && (
					<div className="spinner">
						<Spinner position="relative" height="80px" width="80px" />
					</div>
				)}

				<SymbolFromId
					id={doc.id}
					svgProperties={{
						filter: "url(#shadow1)",
						strokeWidth: "2",
					}}
				/>
				<p>
					{doc.linkName ||
						doc.projectName ||
						"Project " + (doc.shortHandId || "")}
				</p>
			</Link>
			{!doc.isLoading && canUseDotActions && (
				<DotActions options={{ ul: true }}>
					{userIsOwner && (
						<li
							onClick={async () => {
								try {
									const whenLoading = () => {
										return setDocList((docList) => {
											return docList ? docList.map((proj) =>
												proj.id === doc.id ? { ...proj, isLoading: true } : proj
											) : docList
										});
									};
									const removeSuccess = await handleRemoveProject(
										doc.id,
										setUsersProjects,
										whenLoading,
										dialog,
									);

									if (removeSuccess) {
										await removeDocFromRecentlyViewed(doc);
										setUsersProjects((usersProjects) => [
											...usersProjects.filter((proj) => proj.id !== doc.id),
										]);
										handleSetSuccessText(
											`Project '${
												doc.projectName || "Project " + (doc.shortHandId || "")
											}' removed`
										);
										// dont set docList ? ... cant delete an invoice/project in which user is only a follower
										setDocList((docList) =>
											docList ? docList.filter((obj) => obj.id !== doc.id) : docList
										);
									}
								} catch (err) {
									setErrorObj(err);
								}
							}}
						>
							delete
						</li>
					)}
					<li
						onClick={async () => {
							try {
								// const collection = "projects"
								if (userIsFollower) {
									const unfollowSuccess = await handleUnfollow({
										userId,
										collection: "projects",
										userIsOwner,
										docId: doc.id,
										docEditors: doc.editors,
										dialog,
									});
									if (unfollowSuccess) {
										handleSetSuccessText(
											"Unfollowed Project " + doc.projectName ||
												"Project " + (doc.shortHandId || "")
										);
										// need to set docList because of onlyFollowing projects/invoices
										setDocList((docList) => {
											return docList ?
											[
												...docList.filter((i) => i.id !== doc.id),
											] : docList
										});
									}
								} else {
									// await handleFollow(userObject.username, userId, "projects", null, doc, setErrorObj, handleSetSuccessText)
									const followSuccess = await handleAddFollower({
										userId,
										username: userObject.username,
										collection: "projects",
										parentResource: doc,
									});
									if (followSuccess) {
										handleSetSuccessText(followSuccess);
										if (followSuccess !== "Request sent") {
											setDocList((docList) =>
												docList ? docList.map((proj) => {
													return proj.id === doc.id
														? {
																...proj,
																followers: [...proj.followers, userId],
																// isLoading: false
														  }
														: proj;
												}) : docList
											);
										}
									}
								}
							} catch (err) {
								setErrorObj(err);
							}
						}}
					>
						{userIsFollower ? "unfollow" : "follow"}
					</li>
					{/* copy project not implimented yet, need to write the handleCopyProject fn
						<li>
						<details className="expand-option">
							<summary>copy</summary>
							<div onClick={() => handleCopyProject("template")}>Template</div>
							<div onClick={() => handleCopyProject("fullCopy")} >Full</div>
						</details>
					</li>*/}
					<li
						onClick={(e) => {
							e.target.firstElementChild.select();
							document.execCommand("copy");
							handleSetSuccessText("Link copied");
						}}
					>
						copy link
						<input
							style={{ zIndex: "-1" }}
							type="text"
							className="hide-height absolute"
							defaultValue={`${window.location.href}/${
								doc.contractor ? doc.contractor.username : ""
							}/${doc.id}`}
						/>
					</li>
				</DotActions>
			)}
		</li>
	);
};
