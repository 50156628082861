import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import { AppMessage } from "../MessageUtils";
import Form from "../Forms/Form";

import firebase from "../../firebase/index";
// import { canadaProvinces, americanStates } from "../../utils/appUtils";

// rendered by LoginOrSignup
// errors handled, success text handled in handleSubmit < createUserWithEmailAndPassword. checks username exists
// maybe change to check if username exists here upon typing instead
const SignupForm = ({
	setErrorObj,
	formData,
	setFormData,
	handleChange,
	setSignup,
	handleEmailSignup,
	children,
	submitName,
	prefillData,
	setLocallyFetching,
	locallyFetching
}) => {
	// - phone
	const [passwordInvalid, setPasswordInvalid] = useState({
		invalid: false,
		message: "",
	});
	const [passwordAgainInvalid, setPasswordAgainInvalid] = useState({
		invalid: false,
		message: "",
	});
	const [usernameInvalid, setUsernameInvalid] = useState({
		invalid: false,
		message: "",
	});

	const history = useHistory();

	// email and password are always shown
	const [showPasswordAgain, setShowPasswordAgain] = useState(false);
	const [showUsername, setShowUsername] = useState(false);
	// const [detachUsernameFromEmail, setDetachUsernameFromEmail] = useState(false)

	const [neverHidePhone, setNeverHidePhone] = useState(false);
	const [neverHideAddress, setNeverHideAddress] = useState(false);
	const [showNonRequired, setShowNonRequired] = useState(false);
	const [formDataValidationVersion, setFormDataValidationVersion] = useState(0);
	const [validationTimer, setValidationTimer] = useState({
		fdVersion: 0,
		timer: null,
	});

	const handleAddressChange = (name, val) => {
		let address = {
			...formData.address,
			[name]: val,
		};
		setFormData((formData) => ({ ...formData, address }));
	};


	const validateFields = useCallback(
		async (doNotValidateArray, isFinalValidation) => {
			try {
				// let newName = formData[name]
				let { password, passwordAgain, username, acceptTerms, email, name } =
					formData;
				if (!doNotValidateArray) {
					doNotValidateArray = [];
				}

				// get rid of white space
				if (username && !doNotValidateArray.includes("username")) {
					// handle invalid or not allowed usernames
					if (
						username === "current-user" ||
						username === "null" ||
						username === "undefined" ||
						username.includes("@")
					) {
						let message = "username not allowed";
						if (username.includes("@")) {
							message = "username cannot contain '@'";
						}
						setUsernameInvalid((usernameInvalid) => {
							if (
								!usernameInvalid.invalid ||
								usernameInvalid.message !== message
							) {
								return { invalid: true, message };
							} else return usernameInvalid;
						});

						setShowUsername(true);
						return false;
					}

					if (username.includes(" ")) {
						// see if the form validation will render it invalid
						const message = "username cannot contain spaces";
						setUsernameInvalid((usernameInvalid) => {
							if (
								!usernameInvalid.invalid ||
								usernameInvalid.message !== message
							) {
								return { invalid: true, message };
							} else return usernameInvalid;
						});

						setShowUsername(true);
						return false;
					}

					const usernameTaken = firebase.functions().httpsCallable("usernameTaken");

					const usernameIsTaken = await usernameTaken({username}).then(({data}) => {
						if (data.errored || !data) {
							// if there was an error, make the users email as their username
							username = email + ""
							if (data.errorMessage) {
								setErrorObj({message: data.errorMessage})
							}
							return false
						} else {
							return data.taken
						}
					}).catch(err => {
						// if there was an error, make the users email as their username
						username = email + ""
						return false
					})

					if (usernameIsTaken) {
						const message = "username already taken!";
						setUsernameInvalid((usernameInvalid) => {
							if (
								!usernameInvalid.invalid ||
								usernameInvalid.message !== message
							) {
								return { invalid: true, message };
							} else return usernameInvalid;
						});
						setShowUsername(true);
						return false;
					}
				}

				// the below is done right before submit
				// if (name === "firstname" || name === "lastname") {
				// 	newVal = newVal.trim()
				// 	handleChange([name], newVal)
				// }

				if (
					password &&
					password.length < 8 &&
					!doNotValidateArray.includes("password")
				) {
					setPasswordInvalid((passwordInvalid) => {
						if (!passwordInvalid.invalid) {
							return { invalid: true, message: "minumum of 8 characters!" };
						} else return passwordInvalid;
					});
					return false;
				}

				if (
					passwordAgain &&
					passwordAgain !== formData.password &&
					!doNotValidateArray.includes("passwordAgain")
				) {
					setPasswordAgainInvalid((passwordAgainInvalid) => {
						if (!passwordAgainInvalid.invalid) {
							return { invalid: true, message: "passwords do not match!" };
						} else return passwordAgainInvalid;
					});
					return false;
				}

				if (isFinalValidation) {
					if (!email) {
						// should never run because html default required field prevents submit
						setErrorObj({
							message: "Sorry! We need your email!",
							code: "caught in final validation at email input an html required input may have been changed to non required",
							type: "signup missing required fields",
							metdadata: {
								email: formData.email,
								name: formData.name,
							},
							// noReport: true,
						});
						return false;
					}
					// username is auto created if left blank. This is due partially to SignupWithGoogle and SignupWithFacebook
					// in which there is not username property in the providerData of an account that signed up with google or facebook
					// username is always required but it creates less friction upon signup if the user doesnt have to choose one.
					if (!name) {
						// should never run because html default required field prevents submit
						setErrorObj({
							message: "Sorry! We need your name!",
							code: "caught in final validation at name input an html required input may have been changed to non required",
							type: "signup missing required fields",
							metdadata: {
								email: formData.email,
								name: formData.name,
							},
							// noReport: true,
						});
						return false;
					}
					if (!password) {
						// should never run because html default required field prevents submit
						setPasswordInvalid((passwordInvalid) => {
							if (!passwordInvalid.invalid) {
								return { invalid: true, message: "minumum of 8 characters!" };
							} else return passwordInvalid;
						});
						setErrorObj({
							message: "Please choose a password",
							code: "caught in final validation at password input an html required input may have been changed to non required",
							type: "signup missing required fields",
							metdadata: {
								email: formData.email,
								name: formData.name,
							},
							// noReport: true,
						});
						return false;
					}
					if (!passwordAgain) {
						// should never run because html default required field prevents submit
						setPasswordAgainInvalid((passwordAgainInvalid) => {
							if (!passwordAgainInvalid.invalid) {
								return { invalid: true, message: "passwords do not match!" };
							} else return passwordAgainInvalid;
						});
						setErrorObj({
							message: "Please re-type password in 'Password Again' box",
							code: "caught in final validation at passwordAgain input an html required input may have been changed to non required",
							type: "signup missing required fields",
							metdadata: {
								email: formData.email,
								name: formData.name,
							},
							// noReport: true,
						});
						return false;
					}
					if (!username) {
						// should never run because html default required field prevents submit
						const message = "Sorry! We need a username!";
						setUsernameInvalid((usernameInvalid) => {
							if (
								!usernameInvalid.invalid ||
								usernameInvalid.message !== message
							) {
								return { invalid: true, message };
							} else return usernameInvalid;
						});
						setShowUsername(true);
						setErrorObj({
							message,
							code: "caught in final validation at username input an html required input may have been changed to non required",
							type: "signup missing required fields",
							metdadata: {
								email: formData.email,
								name: formData.name,
								username: formData.username,
							},
							// noReport: true,
						});
						return false;
					}
					if (!acceptTerms) {
						setErrorObj({
							message: "Please accept Terms",
							noReport: true,
						});
						return false;
					}
				}

				// else all fields are valid
				return true;
			} catch (err) {
				setErrorObj(err);
				return false;
			}
		},
		[formData, setErrorObj]
	);

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			if (locallyFetching) {
				return
			}
			setLocallyFetching(true)
			// show most required form fields just in case they didnt show
			setShowPasswordAgain(true);
			// setShowUsername(true)
			setShowNonRequired(true);
			// setDetachUsernameFromEmail(true)
			const isValid = await validateFields([], true);
			if (isValid) {
				await handleEmailSignup(formData); // handleEmailSignup needs password
				if (!history.location.pathname || history.location.pathname === "/") {
					history.push('/dashboard');
				}

			}
			
			setLocallyFetching(false)
		} catch (err) {

			setLocallyFetching(false)
			if (err.code === "auth/email-already-in-use") {
				err.noReport = true
				setErrorObj(err);
			} else {
				setErrorObj(err);
			}

		}
	};

	useEffect(() => {
		if (formData.email && !showUsername) {
			const generatedUsername = formData.email.replace(/@.*/, "");

			if (formData.username !== generatedUsername) {
				handleChange("username", generatedUsername);
			}
		}
	}, [showUsername, formData.email, formData.username, handleChange]);

	// useEffect(() => {
	// 	// from the generated username, decide if a new username needs to be chosen
	// 	// based on when we think the user is done entering their email
	// 	if (formData.email && formData.passwordAgain.length > 0) {
	// 		if (!detachUsernameFromEmail) {
	// 			setDetachUsernameFromEmail(true)
	// 		}
	// 	}

	// }, [detachUsernameFromEmail, setDetachUsernameFromEmail, formData.email, formData.passwordAgain])

	useEffect(() => {
		// if validation version doesnt match the timers validation version
		// let didAddTimer = false
		if (formDataValidationVersion !== validationTimer.fdVersion) {
			// clear the timer if there is one
			if (validationTimer.timer) {
				clearTimeout(validationTimer.timer);
			}

			// update the timer
			setValidationTimer({
				fdVersion: 0 + formDataValidationVersion,
				timer: setTimeout(() => {
					if (showUsername) {
						// validate all fields
						validateFields([]);
					} else {
						// dont validate username
						validateFields(["username"]);
					}
				}, 2000),
			});
			// didAddTimer = true
		}
		return () => {
			// if (didAddTimer) {
			clearTimeout(validationTimer.timer);
			// }
		};
	}, [
		showUsername,
		formDataValidationVersion,
		validationTimer,
		setValidationTimer,
		validateFields,
	]);

	let inputs = [
		{
			label: "Name",
			onChange: handleChange,
			// containerClass: (/*formData.passwordAgain || */namesHaveBeenSeen) ? "show-height" : "hide-height",
			properties: {
				type: "text",
				id: "name",
				value: formData.name,
				required: true,
				autoFocus: "autoFocus",
				autoComplete: "name",
			},
		},
		{
			label: "Email",
			onChange: handleChange,
			properties: {
				type: "email",
				id: "email",
				value: formData.email,
				// ...!formData.email && {autoFocus: "autoFocus"},
				required: true,
				autoComplete: "email",
			},
		},
		{
			label: (
				<React.Fragment>
					Password&nbsp;
					{passwordInvalid.message && (
						<span className="error-text">({passwordInvalid.message})</span>
					)}
				</React.Fragment>
			),
			onChange: (name, value) => {
				handleChange(name, value);
				setFormDataValidationVersion(
					(formDataValidationVersion) => formDataValidationVersion + 1
				);
				if (!showPasswordAgain) {
					setShowPasswordAgain(true);
				}
				// any time password field is changed make it not invalid
				if (passwordInvalid.invalid) {
					setPasswordInvalid({ invalid: false, message: "" });
				}
				if (passwordAgainInvalid.invalid) {
					setPasswordAgainInvalid({ invalid: false, message: "" });
				}
			},
			// containerClass: formData.email ? "visible" : "hidden",
			properties: {
				type: "password",
				id: "password",
				value: formData.password,
				required: true,
			},
		},
		{
			label: (
				<React.Fragment>
					Password Again&nbsp;
					{passwordAgainInvalid.message && (
						<span className="error-text">({passwordAgainInvalid.message})</span>
					)}
				</React.Fragment>
			),
			onChange: (name, value) => {
				handleChange(name, value);
				// setFormDataValidationVersion(formDataValidationVersion => formDataValidationVersion + 1)
				// if (!namesHaveBeenSeen) {
				// 	setNamesHaveBeenSeen(true)
				// }
				// do a quick validation with the new value because if we run normal validate it doesnt have new value yet
				if (!showNonRequired) {
					setShowNonRequired(true);
					if (formData.phone && !neverHidePhone) {
						setNeverHidePhone(true);
					}
					if (formData.address && !neverHideAddress) {
						setNeverHideAddress(true);
					}
				}
				// validate all fields except passwordAgain (check if prev field password is too short)
				validateFields(["passwordAgain", "username"]);
				if (value === formData.password) {
					if (passwordAgainInvalid.invalid) {
						setPasswordAgainInvalid({ invalid: false, message: "" });
					}
				} else {
					if (!passwordAgainInvalid.invalid) {
						setPasswordAgainInvalid({
							invalid: true,
							message: "passwords do not match!",
						});
					}
				}
			},
			afterInput: <div className="section-divider" />,
			// password could have been input on the sign in page and therefore necer running the handleChange in password input that sets showPasswordAgain
			containerClass:
				showPasswordAgain || formData.password ? "visible" : "hidden",
			properties: {
				type: "password",
				id: "passwordAgain",
				value: formData.passwordAgain,
				required: true,
				onSelect: (e) => {
					// prevent pressing next or tab before input is shown
					if (!showPasswordAgain) {
						setShowPasswordAgain(true);
					}
					// running validate fields will set showUsername to true if errors
					// could just run validate all fields now but want to wait til user is typing to add the new username field
					validateFields(["passwordAgain", "username"]);
					// setShowPasswordAgain(true)
				},
			},
		},
		{
			label: (
				<React.Fragment>
					Username&nbsp;
					{usernameInvalid.message && (
						<span className="error-text">({usernameInvalid.message})</span>
					)}
				</React.Fragment>
			),
			onChange: (name, value) => {
				const trimmedVal = value.trim();
				handleChange(name, trimmedVal);
				if (formData[name] !== trimmedVal) {
					// validate after some time to prevent too many requests
					setFormDataValidationVersion(
						(formDataValidationVersion) => formDataValidationVersion + 1
					);
					if (usernameInvalid.invalid) {
						setUsernameInvalid({ invalid: false, message: "" });
					}
				}
			},
			// containerClass: "text",
			containerClass: showUsername ? "show-height" : "hide-height hidden",
			properties: {
				type: "text",
				id: "username",
				value: formData.username,
				required: true,
				onSelect: () => {
					// prevent pressing next or tab before input is shown
					if (!showUsername) {
						setShowUsername(true);
					}
					// if (!detachUsernameFromEmail) {
					// 	setDetachUsernameFromEmail(true)
					// }
					validateFields(["username"]);
				},
			},
		},
		// not required
		{
			label: "Phone",
			onChange: handleChange,
			// only show phone input if it was already filled in and username is visiblr
			containerClass:
				(showNonRequired && formData.phone) || neverHidePhone
					? "show-height"
					: "hide-height hidden",
			properties: {
				type: "tel",
				id: "phone",
				value: formData.phone,
				autoComplete: "tel",
				onSelect: () => {
					// since this input is hidden, prevent user from selecting it
					// unless it was auto filled
					if (showNonRequired && formData.phone) {
						// if showNonRequired and formData.phone have ever both been true set an override in case they become false again
						// not really required because setNeverHide... is set when showNonRequired is set
						if (!neverHidePhone) {
							setNeverHidePhone(true);
						}
					} else {
						if (!neverHidePhone) {
							document.getElementById("acceptTerms").focus();
						}
					}
				},
			},
		},
		{
			label: (
				<span>
					&nbsp;I accept the{" "}
					<Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
						Terms of Service
					</Link>{" "}
					and{" "}
					<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
						Privacy Policy
					</Link>
				</span>
			),
			onChange: handleChange,
			// show if last required field is visible
			// containerClass: showUsername ? "visible" : "hidden",
			containerClass: "visible",
			properties: {
				type: "checkbox",
				name: "acceptTerms",
				id: "acceptTerms",
				required: true,
				value: formData.acceptTerms ? false : true,
				checked: formData.acceptTerms ? true : false,
			},
			beforeInput: [<div key="terms-LB" className="small-line-break" />],
		},
	];

	if (prefillData && prefillData.address) {
		// move accept terms checkbox to last inoput ans add address fields
		const filteredInputs = [...inputs];
		filteredInputs.pop();
		inputs = [
			...filteredInputs,
			{
				label: "Country",
				onChange: handleAddressChange,
				properties: {
					type: "text",
					id: "country",
					value: formData.address.country || "",
				},
				beforeInput: [
					<React.Fragment key="My Page">
						<div className="section-title">Address</div>
					</React.Fragment>,
				],
			},
			{
				label: formData.country === "USA" ? "State" : "Province",
				onChange: handleAddressChange,
				properties: {
					type: "text",
					id: "state",
					value: formData.address.state || "",
				},
			},
			{
				label: "City",
				onChange: handleAddressChange,
				properties: {
					type: "text",
					id: "city",
					value: formData.address.city || "",
				},
			},
			{
				label: "Line1",
				onChange: handleAddressChange,
				properties: {
					type: "text",
					id: "line1",
					value: formData.address.line1 || "",
					name: "address-line-1",
				},
			},
			{
				label: "Line2",
				onChange: handleAddressChange,
				properties: {
					type: "text",
					id: "line2",
					name: "address-line-2",
					value: formData.address.line2 || "",
				},
			},
			{
				label: "Postal Code",
				onChange: handleAddressChange,
				properties: {
					type: "text",
					id: "postal_code",
					value: formData.address.postal_code || "",
				},
			},
			inputs[inputs.length - 1],
		];
	}

	return (
		<Form
			heading={
				<React.Fragment>
					<AppMessage dependants={[formData]} scrollIntoView={false} />
				</React.Fragment>
			}
			submitName={submitName ? submitName : "Sign Up"}
			onSubmit={handleSubmit}
			inputs={inputs}
		>
			{children}
		</Form>
	);
};

export default SignupForm;
