import styled from "styled-components";

// notes
// .heading-icons space right -10px brcause padding on icon is 10px

export const HeadingStyle = styled.div`
	.heading-container {
		font-family: var(--font-family-poppins);
		position: fixed;
		top: 0;
		width: 100%;
		margin: 0;
		z-index: 5;
		.fixed-message {
		  text-align: center;
		  padding: 0;
		  width: inherit;
		  z-index: 300;
		  background-color: transparent;
		  width: 100%;
		  color: var(--white-text);

		  .visible {
			  background-color: #131313;;
			  line-height: 1.5em;
		  }
		}
/*		.message-text {
		  font-size: var(--font-size-s);
		}*/
		.bar-container {
		  background-color: var(--white-text);
			padding: 20px 0 0 0;
			display: flex;
			justify-content: space-between;
			box-shadow: 0px 4px 15px -10px;
			.heading-icons {
				list-style: none;
		    margin: 0 10px 0 0;
		    padding: 0;
				> li {
					position: relative;
					display: inline;
					> svg {
						padding: 10px;
					}
				}
			}
			.logo-container {
				margin: 0 0 0 15px;
				a {
					text-decoration: none;
					color: var(--chicago);
				}
			}

			.user-photo {
				border-radius: 50%;
				margin: 8px;
				box-shadow: 1px 2px 10px -2px;
		    vertical-align: top;
			}
			#online-status {
				padding: 0;
				height: 100%;
				position: absolute;
		    right: 0;
		    top: 0;
			}
		}
	}
`;
