import React from 'react'

import Form from '../Forms/Form'
import { AppMessage } from '../MessageUtils'

// rendered by LoginOrSignup.js > Heading.js, AcceptBillModal.js 
// errors handled success text not necessary
const LoginForm = ({formData, handleChange, setSignup, handleLogin, /*Heading,*/ children, submitName, handleSendPasswordReset/*, logInWithEmailLink*/}) => {
	// const [formData, setFormData] = useState({
	// 	email: "",
	// 	password: ""
	// })

	// const handleText = (name, value) => {
	// 	setFormData(formData => ({...formData, [name]: value}))
	// }

	const handleSubmit = (e) => {
		e.preventDefault()
		handleLogin(formData)
		// setFormData({
		// 	email: "",
		// 	password: ""
		// })
	}

	const inputs = [
		{
			label: "Email", 
			onChange: handleChange,
			properties: {
				type: "email",
				id: "email",
				value: formData.email,
				autoFocus: "autoFocus",
				required: true,
				autoComplete: "email"	
			}
		},
		{
			label: "Password", 
			onChange: handleChange,
			properties: {
				type: "password", 
				id: "password",
				value: formData.password,
				required: true			
			},
			afterInput: [
				<div key="loginWithEmail" className="small align-center">
					<div className="section-divider" />
					Forgot Password?&nbsp;
					<div className="link-appearance blue" onClick={(e) => {
						e.preventDefault()
						handleLogin(formData, true)
					}}>
						Log in with email link
					</div>
					<div>or</div>
					<div className="link-appearance blue" onClick={(e) => handleSendPasswordReset(e, formData.email)}>
						Send a password reset link
					</div>
					<div className="small-line-break" />
				</div>
			]
		}
	]

	return (
			<Form 
				heading={
					<React.Fragment>
						<AppMessage dependants={[formData]} scrollIntoView={false} />
					</React.Fragment>
				}
				submitName={submitName ? submitName : "Log In"}
				onSubmit={handleSubmit}
				inputs={inputs}
			>
				{children}
			</Form>
	)
}

export default LoginForm
