import React, { useRef } from 'react'

import { InputDiv, FormStyle } from './Form.styled'
import MoreInfoIcon from '../MoreInfoIcon'


// General form 
// change to always use name and value not ID. for the key, if the input doesnt have an id then 
// you could put key={id || name}

// rendered by many
// errors and success handling  not applicable
const Form = ({heading, inputs, submitName, submitDisabled, onSubmit, nonSubmit, children, errorText}) => {
	const inputsContainerRef = useRef(null)

	const updateToBoolean = (val) => {
		if (val === "false") {
			return false
		} else if (val === "true") {			
			return true
		} else {
			return val
		}
	}

	const textArea = ({label, name, value, placeholder, onChange, className, insideInputDiv, ref, disabled, containerClass, onSelect}) => {
		const resClassName = `${className ? className : ""}${containerClass ? " " + containerClass : ""}`
		return (
			<div {...resClassName ? {className: resClassName} : {}}>
				<div className="small-line-break" />
				<div className="small-line-break" />

				<label>{label}</label>
				<textarea 
					name={name} 
					value={value} 
					onChange={e => onChange(name, e.target.value, e)} 
					placeholder={placeholder}
					ref={ref}
					disabled={disabled ? true : false}
					onSelect={onSelect}
				/>
				{insideInputDiv}
			</div>
		)
	}


	const dropdown = ({label, name, options, optionVal, value, onChange, className, insideInputDiv, ref, disabled, containerClass, onSelect, moreInfoIcon}) => {
		// requires the onChange method to be in properties
		const resClassName = `${className ? className : ""}${containerClass ? " " + containerClass : ""}`
		return (
			<div {...resClassName ? {className: resClassName} : {}}>
				<label>{label && label + ": "}</label>
				<select 
					disabled={disabled ? true : false} 
					name={name} 
					value={value} 
					onChange={e => onChange(name, updateToBoolean(e.target.value), e)} 
					ref={ref}
					onSelect={onSelect}
				>
					{
						options.map((opt, i) => {
							const key = opt[optionVal] || opt.name || opt.val || "option-"+ i
							return <option key={key} name={opt.displayName || opt.name} value={opt.val || opt[optionVal]}>{opt.displayName || opt.name}</option>
						})
					}
				</select>
				{
					moreInfoIcon &&
					<React.Fragment>
						{moreInfoIcon.before}
						<MoreInfoIcon {...moreInfoIcon} />
						{moreInfoIcon.after}
					</React.Fragment>
				}
				{insideInputDiv}
				<div className="small-line-break" />
			</div>
		)
	}

	const renderInputs = inputs.map(({moreInfoIcon, beforeInput, visible, label, onChange, properties, insideInputDiv, afterInput, custom, customElements, containerClass, containerRef}, i) => {
		if (!properties) {
			properties = {}
		}


		// if (properties.type === "date") {
		// 	containerClass += " "
		// }
		
		if (visible === false) {
			return null
		}

		if (custom) {
			// need to change all custom inputs to have only valid firlds in properties so it can be spread into element
			if (properties.type && properties.type === "textarea") {
				return (
					<React.Fragment key={properties.name}>
						{beforeInput}
						{textArea({...properties, label, insideInputDiv, containerClass})}
						{afterInput}
					</React.Fragment>	
				)
			}
			else if (properties.type && properties.type === "dropdown") {
				if (!properties.onChange) {
					properties.onChange = onChange
				}
				return (
					<React.Fragment key={properties.name}>
						{beforeInput}
						{dropdown({...properties, label, insideInputDiv, containerClass, moreInfoIcon})}
						{afterInput}
					</React.Fragment>	
				)
			} else {
				return customElements
			}

		} else {
			let {id, value, ref, type, name} = properties


			// true or false
			const inputTypeTextFamily = (type === "text" || type === "number" || type === "email" || type === "password" || type === "date" || type === "time" || type === "tel")
			if (properties.type === "number" && !properties.step) {
				properties.step = "0.01"
				if (isNaN(value)) {
					properties.value = ""
					value = ""
				}
			}

			// remove all nonHTML properties
			let cleanedProperties = {...properties}
			for (let key in cleanedProperties) {
				if (key === "optionVal" || key === "options") {
					delete cleanedProperties[key]
				}
			}
			// add a name attribute to proerties if there is not yet one for autofill purposes
			if (!cleanedProperties.name) {
				cleanedProperties.name = cleanedProperties.id
			}

			if (type === "number") {
				cleanedProperties.pattern = "[0-9]*"
				cleanedProperties.inputMode = "decimal"
			}

			const resClassName = `${containerClass || ""}${type === "hidden" ? " hidden-input" : ""}`
			return (
				<React.Fragment key={id + "-" + i}>
				{beforeInput}
				<InputDiv {...resClassName ? {className: resClassName} : {}} hasVal={(value || value === 0) ? true : false} inputTypeTextFamily={inputTypeTextFamily}>
					<div {...containerRef ? {ref: containerRef} : {}} className="input-container">
						<input 
							{...cleanedProperties} 
							onChange={(e) => {
								let newTargetValue = updateToBoolean(e.target.value)
								// update all the forms checkboxes and radio inputs 
								if (type === "checkbox" || type === "radio") {
									// if (typeof(newTargetValue) !== "boolean") {
									// 	console.log("CHANGE THIS " + type + " VALUE TO A BOOLEAN", id, name)
									// }
								}

								if (type === "radio") {
									// change onChange to have props obj, have a onChange props property in the input object 
									// and just pass down props ?
									onChange(name, newTargetValue, e)
								} else {
									onChange(id, newTargetValue, e)
								}
							}}
						/>
						{insideInputDiv}
						<label htmlFor={id}>{label}</label> 
						{
							moreInfoIcon &&
							<React.Fragment>
								{moreInfoIcon.before}
								<MoreInfoIcon {...moreInfoIcon} />
								{moreInfoIcon.after}
							</React.Fragment>
						}
					</div>
					{ 
						// only use quick clear text icon on specific types of inputs
						inputTypeTextFamily && properties.type !== "date" &&
						<svg onClick={(e) => {
							if (!properties.disabled) {
								onChange(id, "", e)
								if (ref) {
									ref.current.focus()
								} else {
									document.getElementById(id).focus()
								}
							}
						}} 
							viewBox="0 0 32 32" width="10px" height="10px" 
							fill="none" stroke="currentcolor" strokeLinecap="round" 
							strokeLinejoin="round" strokeWidth="3"
						>
					    <path d="M2 30 L30 2 M30 30 L2 2" />
						</svg>
					}
				</InputDiv> 
				{afterInput}
				</React.Fragment>
			)
		}
	})

	return (
		<FormStyle>
			{heading}
			<form onSubmit={onSubmit}>
				<div className="inputs" ref={inputsContainerRef}>
					{renderInputs}
				</div>
				{children}
				{
					submitName &&
					<div className="submit-actions">
						<button disabled={submitDisabled || false} className="button-appearance">{submitName}</button>
						{nonSubmit && nonSubmit}
					</div>
				}
			</form>
		</FormStyle>
	)
}

export default Form
