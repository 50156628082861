import React, { useContext, useState, useEffect, useRef } from "react";
// import { useHistory } from 'react-router-dom'
import styled from "styled-components";

import { UserContext } from "../../contexts/UserContext";

import Modal from "../Modal/Modal";
import Form from "../Forms/Form";
import { AppMessage } from "../MessageUtils";
import SmallSpinner from "../stripeComponents/SmallSpinner";
import Spinner from "../Spinner/Spinner";
import MoreInfoIcon from "../MoreInfoIcon";

import firebase from "../../firebase/index";

const PaymentSettingsModalStyle = styled.div`
	// .section-title {
	// 	font-size: 18px;
	// 	font-weight: bold;
	// }
	p {
		margin: 7px 0;
	}
	p > em {
		color: gray;
		padding: 0 10px;
		box-shadow: 0px 3px 10px -5px;
	}
	.connect-container {
		.disabled {
			opacity: 0.6;
		}
	}
	.payment-status {
		color: gray;
    font-size: 13px;
    padding: 0 10px;
    font-weight: normal;
    font-style: italic;
	}
	.payment-status.enabled {
		box-shadow: 0px 3px 10px -5px green;		
	}
	.payment-status.disabled {
		box-shadow: 0px 3px 10px -5px red;
	}
`;

// only available to open if there is a current user
const PaymentSettingsModal = ({ pathname, query, history }) => {
	const {
		userObject,
		usersInvoices,
		setUsersInvoices,
		getUsersInvoices,
		setErrorObj,
		handleSetSuccessText,
		isFetching,
		setIsFetching,
		setPaymentSettingsModalOpen,
		hasStripeBusinessAccount,
		canAcceptStripePayments,
		newUserData,
		setNewUserData,
		updateUserData,
	} = useContext(UserContext);

	const accountLinkRef = useRef(null);
	const createStripeAccount = firebase
		.functions()
		.httpsCallable("createStripeAccount");
	// const getStripeAccount = firebase.functions().httpsCallable('getStripeAccount')

	// note: structure in AddInvoicePaymentModal
	// 	contractorPaymentMethods.map(({name, disabled}) => {
	// 	return (
	// 		<div key={name} >
	// 			<input
	// 				type="radio"
	// 				name="paymentMethod"
	// 				id={name + " payment"}
	// 				value={name}
	// 				checked={formData.paymentMethod === name}
	// 				onChange={e => handleChangePayment(e.target.name, e.target.value)}
	// 				disabled={disabled ? true : false}
	// 			/>
	// 			<label htmlFor={name + " payment"}>{name.toLocaleUpperCase()}</label>
	// 		</div>
	// 	)
	// })

	// const [formData, setFormData] = useState({
	// 	paymentMethods: [], // for use in editedInv.contractor.paymentMethods in AddInvoicePaymentModal
	// 	token: "",
	// 	business_type: "",
	// 	country: "",
	// 	default_currency: "",
	// 	product_description: "",
	// 	support_phone: "",
	// });
	const [accountLink, setAccountLink] = useState("");
	const [processing, setProcessing] = useState(false);

	let defaultPaymentMethods = {
		cash: {
			accepted: true,
			discount: "",
		},
		etransfer: {
			accepted: false,
			discount: "",
			email: userObject.email,
			autoUpdate: true,
		},
		cheque: {
			accepted: false,
			discount: "",
			payableTo: (userObject.firstname + " " + userObject.lastname || "").trim(),
			autoUpdate: true,
		},
		stripe: {
			accepted: canAcceptStripePayments ? true : false,
			card_payments: true,
			acss_debit_payments: false
		}
	}
	if (userObject.paymentMethods) {
		defaultPaymentMethods = {
			...userObject.paymentMethods,
			stripe: { // fix for all users payment methods not contain[e properties card_payments and acss_debit_payments
				...defaultPaymentMethods.stripe,
				...userObject.paymentMethods.stripe
			}
		}
	}

	const [paymentMethods, setPaymentMethods] = useState({
		...defaultPaymentMethods
	})

	const handleChange = (name, val) => {
		if (name.includes(".")) {
			let newPaymentMethods = {...paymentMethods}
			const nameArray = name.split(".")
			if (nameArray.length > 2) {
				console.error("The handleChange function is not set up to handle 3 layers of nesting")
			}
			for (let key in paymentMethods) {
				if (nameArray[0] === key) {
					newPaymentMethods[key] = {
						...newPaymentMethods[key],
						[nameArray[1]]: val
					}
				}
			}
			setPaymentMethods(newPaymentMethods)
			
		} else {
			setPaymentMethods((paymentMethods) => ({...paymentMethods, [name]: val}))
		}
	};

	const handleClickOutside = () => setPaymentSettingsModalOpen(false);

	const handleConnectWithStripe = async ({ e, userObject, accountId }) => {
		try {
			e && e.preventDefault();
			setProcessing(true);
			const customerId =
				userObject.private &&
				userObject.private.stripe &&
				userObject.private.stripe.customer
					? userObject.private.stripe.customer.id
					: "";

			const { data } = await createStripeAccount({
				...(accountId && { accountId }),
				email: userObject.email,
				uid: userObject.uid,
				username: userObject.username,
				first_name: userObject.firstname,
				last_name: userObject.lastname,
				address: {
					city: "",
					country: "",
					line1: "",
					line2: "",
					postal_code: "",
					state: "",
				},
				businessName: userObject.companyName || userObject.username,
				country: userObject.country || "CA",
				default_currency: userObject.defaultCurrency || "CAD",
				// mcc: 1520,
				mcc: userObject.mcc,
				product_description: userObject.industryTitle || "Contracting",
				// logoUrl: userObject.logoUrl,
				phone: userObject.phone,
				// branding: {
				//  // icon: "file_1JLE1CDllOrYuqccsJUcuq35",
				//  logo: "file_1JL9w9DllOrYuqccb3bV7Zg8", // this works but should upload the logo to the connected account if possible
				// },
				refresh_url: `${window.location.origin}/setup-payment-reauth`,
				return_url: `${window.location.origin}/setup-payment-returned`,
				// success_url: window.location.href + "?paymentSuccess=true",
				...(customerId && { customer: customerId }),
				// only uses customer email if !customer
			})
			setProcessing(false);
			setAccountLink(data.url);
			return;
		} catch (err) {
			setProcessing(false);
			setErrorObj(err);
			return;
		}
	};

	// redirect user to account link once it is available
	useEffect(() => {
		if (accountLink && accountLinkRef && accountLinkRef.current) {
			accountLinkRef.current.click();
		}
	}, [accountLink, accountLinkRef]);

	// useEffect(() => {
	// 	if (stripeAccountNeedsUpdating) {
	// 		handleSetSuccessText("Complete the rest of your stripe profile to start accepting payments", 10000)
	// 	}
	// }, [stripeAccountNeedsUpdating])

	// if this is a redirect from stripe ... use a stripe hook instead

	useEffect(() => {
		const isReturn = pathname.includes("setup-payment-returned");
		const isRefresh = pathname.includes("setup-payment-reauth");
		let newPathname = history.location.pathname;

		if (userObject.id) {
			const id = query.get("id");
			if (hasStripeBusinessAccount && !canAcceptStripePayments) {
				setIsFetching(true);
				const stripeBusiness = userObject.private.stripe.business;
				if (stripeBusiness && stripeBusiness.id) {
					handleConnectWithStripe({
						e: null,
						userObject,
						accountId: stripeBusiness.id,
					}).then(() => {
						setIsFetching(false);
						newPathname = newPathname.replace("/setup-payment-returned", "");
						newPathname = newPathname.replace("/setup-payment-reauth", "");
						history.replace(newPathname);
					});
				}
			}

			if (isReturn) {
				// This does not mean that all information has been collected or that there are no outstanding requirements on the account. This only means the flow was entered and exited properly.
				setIsFetching(true);
				// check to see if stripe account setup successfully
				if (hasStripeBusinessAccount) {
					handleSetSuccessText("Success! Payments have been connected!");
					setPaymentMethods(paymentMethods => ({
						...paymentMethods,
						stripe: {accepted: true}
					}))
					updateUserData({
						paymentMethods: {
							...paymentMethods, stripe: {accepted: true}
						}
					})
					setIsFetching(false);
					newPathname = newPathname.replace("/setup-payment-returned", "");
					history.replace(newPathname);
				}
				if (id) {
					// call account links again
					handleConnectWithStripe({ e: null, userObject, accountId: id }).then(
						() => {
							setIsFetching(false);
							newPathname = newPathname.replace("/setup-payment-returned", "");
							history.replace(newPathname);
						}
					);
				}
			}

			if (isRefresh) {
				// check to see if there was any error in setting up stripe account
				setIsFetching(true);
				if (id) {
					// call account links again
					handleConnectWithStripe({ e: null, userObject, accountId: id }).then(
						() => {
							setIsFetching(false);
							newPathname = newPathname.replace("/setup-payment-reauth", "");
							history.replace(newPathname);
						}
					);
				} else {
					setIsFetching(false);
					setErrorObj({
						message:
							"Unable to create Stripe account. Contact support if this problem persists",
					});
				}
			}
		}

		// eslint-disable-next-line
	}, [userObject.id, hasStripeBusinessAccount]);

	let stripeBusinessObject = null;
	if (hasStripeBusinessAccount) {
		stripeBusinessObject = userObject.private.stripe.business;
	}

	const handleSaveSettings = async (e) => {
		try {
			e && e.preventDefault()
			const etransferAccepted = paymentMethods.etransfer.accepted
			const chequeAccepted = paymentMethods.cheque.accepted 

			const updatedUserData = {
				...newUserData,
				paymentMethods: {
					...paymentMethods,
					etransfer: {
						...paymentMethods.etransfer,
						// if user had a payment methods autoUpdate checked then removes the payment method there should be 
						// one last autoUpdate for all the invoices to remove the method from the payment methods
						// this way if the user removes a payment method while still autoUpdate, then enables it in a specificInvoice 
						// then goes and updates this modal default settings, the invoice payment method could be disabled again and worse 
						// in the case of etransfers it could result in the wrong email 
						...!etransferAccepted ? {autoUpdate: false} : {}
					},
					cheque: {
						...paymentMethods.cheque,
						...!chequeAccepted ? {autoUpdate: false} : {}
					}
				}
			}
			setIsFetching(true)
			setNewUserData(updatedUserData)
			await updateUserData({...updatedUserData})
			// handle auto updates
			const autoUpdateEtransfer = paymentMethods.etransfer.autoUpdate
			const autoUpdateCheque = paymentMethods.cheque.autoUpdate 

			// update the payment methods on all invoices unless !autoUpdate
			const batch = firebase.firestore().batch()
			let newUsersInvoices = usersInvoices
			if (!usersInvoices) {
				newUsersInvoices = await getUsersInvoices({userId: userObject.id, caller: "PaymentSettingsModal: handleSaveSettings"});
				setUsersInvoices(newUsersInvoices);
			}
			newUsersInvoices.forEach(inv => {
				// only update the invoice if the user is the contractor
				if (inv.contractor.id === userObject.uid) {
					batch.set(firebase.firestore().collection("invoices").doc(inv.id), {
						paymentMethods: {
							cash: paymentMethods.cash,
							stripe: paymentMethods.stripe,
							...autoUpdateEtransfer && {etransfer: paymentMethods.etransfer},
							...autoUpdateCheque && {cheque: paymentMethods.cheque},
						}
					}, { merge: true })
				}
			})
			await batch.commit()
			setPaymentSettingsModalOpen(false)
			setIsFetching(false)
			handleSetSuccessText("Payment settings updated successfully!")
		} catch (err) {
			setIsFetching(false)
			setErrorObj(err)
		}
	}

	let inputs = [
		{
			label: "Cash",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "cash.accepted",
				value: !paymentMethods.cash.accepted,
				checked: paymentMethods.cash.accepted,
			},
			beforeInput: [
				<div className="section-title" key="defaultMethods">
					<div className="section-divider" />
					My Default Payment Methods{" "}
					<MoreInfoIcon
						absolute={true}
						text="You can set payment methods for each specific invoice in the invoices settings"
					/>
				</div>
			]
		},
		{
			label: "E-transfer",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "etransfer.accepted",
				value: !paymentMethods.etransfer.accepted,
				checked: paymentMethods.etransfer.accepted,
			}
		},
		{
			label: "Cheque",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "cheque.accepted",
				value: !paymentMethods.cheque.accepted,
				checked: paymentMethods.cheque.accepted,
			}
		},
		{
			label: "Stripe (Credit, Debit, etc.)",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "stripe.accepted",
				value: !paymentMethods.stripe.accepted,
				checked: paymentMethods.stripe.accepted,
				disabled: !canAcceptStripePayments
			},
			// afterInput: [
			// 	<React.Fragment key="cashPaymentsHerading">
			// 		<div className="section-divider" />
			// 		<div className="section-title">Cash payments</div>
			// 	</React.Fragment>
			// ],
		},
		// {
		// 	visible: paymentMethods.cash.accepted,
		// 	properties: {
		// 		type: "hidden",
		// 		id: "hiddenInput1",
		// 		onChange: () => null,
		// 	},
		// 	beforeInput: [
		// 		<React.Fragment key="cashDiscount">
		// 			<div className="section-divider" />
		// 			<div className="section-title">Cash payments</div>
		// 			<div className="small-line-break" />
		// 			<label htmlFor="cash.discount" >
		// 				{"Cash discount: % "} 
		// 				<input 
		// 					type="number" 
		// 					id="cash.discount"
		// 					max="100"
		// 					step="0.01"
		// 					style={{width: "5ch"}}
		// 					value={paymentMethods.cash.discount} 
		// 					onChange={(e) => handleChange(e.target.id, e.target.value)}
		// 				/>
		// 				{" "}
		// 				<MoreInfoIcon
		// 					absolute={true}
		// 					text={"Apply a discount if your customer pays with Cash"}
		// 				/>
		// 			</label>
		// 		</React.Fragment>,
		// 	],
		// },
		{
			label: "E-transfer email", 
			visible: paymentMethods.etransfer.accepted,
			onChange: handleChange,
			properties: {
				type: "email", 
				id: "etransfer.email",
				value: paymentMethods.etransfer.email,
				required: true,
			},
			beforeInput: [
				<React.Fragment key="etransferPaymentsHeading">
					<div className="section-divider" />
					<div className="section-title">E-transfers</div>
				</React.Fragment>
			]
		},
		{
			label: "Auto update this for all my invoices",
			onChange: handleChange,
			visible: paymentMethods.etransfer.accepted,
			properties: {
				type: "checkbox",
				id: "etransfer.autoUpdate",
				value: !paymentMethods.etransfer.autoUpdate,
				checked: paymentMethods.etransfer.autoUpdate,
			},
			moreInfoIcon: {
				before: " ",
				absolute: true,
				text: "Automatically update the E-transfer email on all your invoices when you change it here",
			},
		},
		// {
		// 	visible: paymentMethods.etransfer.accepted,
		// 	properties: {
		// 		type: "hidden",
		// 		id: "hiddenInput2",
		// 		onChange: () => null,
		// 	},
		// 	beforeInput: [
		// 		<React.Fragment key="etransferDiscount">
		// 			<div className="small-line-break" />
		// 			<label htmlFor="etransfer.discount" >
		// 				{"E-transfer discount: % "} 
		// 				<input 
		// 					type="number" 
		// 					id="etransfer.discount"
		// 					max="100"
		//					step="0.01"
		// 					style={{width: "5ch"}}
		// 					value={paymentMethods.etransfer.discount} 
		// 					onChange={(e) => handleChange(e.target.id, e.target.value)}
		// 				/>
		// 				{" "}
		// 				<MoreInfoIcon
		// 					absolute={true}
		// 					text={"Apply a discount if your customer pays with E-transfer"}
		// 				/>
		// 			</label>
		// 		</React.Fragment>,
		// 	],
		// },
		// {
		{
			label: "Cheques payable to", 
			visible: paymentMethods.cheque.accepted,
			onChange: handleChange,
			properties: {
				type: "text",
				id: "cheque.payableTo",
				value: paymentMethods.cheque.payableTo,
				required: true,
			},
			beforeInput: [
				<React.Fragment key="chequesPaymentsHeading">
					<div className="section-divider" />
					<div className="section-title">Cheques</div>
				</React.Fragment>
			]
		},
		{
			label: "Auto update this for all my invoices",
			onChange: handleChange,
			visible: paymentMethods.cheque.accepted,
			properties: {
				type: "checkbox",
				id: "cheque.autoUpdate",
				value: !paymentMethods.cheque.autoUpdate,
				checked: paymentMethods.cheque.autoUpdate,
			},
			moreInfoIcon: {
				before: " ",
				absolute: true,
				text: "Automatically update the Payable To name on all your invoices when you change it here",
			},
		},
		// {
		// 	visible: paymentMethods.cheque.accepted,
		// 	properties: {
		// 		type: "hidden",
		// 		id: "hiddenInput3",
		// 		onChange: () => null,
		// 	},
		// 	beforeInput: [
		// 		<React.Fragment key="chequeDiscount">
		// 			<div className="small-line-break" />
		// 			<label htmlFor="cheque.discount" >
		// 				{"Cheque discount: % "} 
		// 				<input 
		// 					type="number" 
		// 					id="cheque.discount"
		// 					max="100"
		//				  step="0.01"	
		// 					style={{width: "5ch"}}
		// 					value={paymentMethods.cheque.discount} 
		// 					onChange={(e) => handleChange(e.target.id, e.target.value)}
		// 				/>
		// 				{" "}
		// 				<MoreInfoIcon
		// 					absolute={true}
		// 					text={"Apply a discount if your customer pays with Cheque"}
		// 				/>
		// 			</label>
		// 		</React.Fragment>,
		// 	],
		// },
	]

	if (hasStripeBusinessAccount) {
		inputs = [
			...inputs,
			{
				visible: true,
				properties: {
					type: "hidden",
					id: "hiddenInput4",
					onChange: () => null,
				},
				beforeInput: [
					<React.Fragment key="hasStripeBusinessAccount">
						<div className="section-divider" />
						<div className="section-title">
							{"Stripe payments "}
							<span className={`payment-status ${canAcceptStripePayments ? "enabled" : "disabled"}`}>{canAcceptStripePayments ? "enabled" : "needs updating"}
							</span>
							<span className={`payment-status ${paymentMethods.stripe.accepted ? "enabled" : "disabled"}`}>{paymentMethods.stripe.accepted ? "active" : "inactive"}
							</span>	
						</div>
					</React.Fragment>	
				]
			},
			{
				label: "Credit card payments",
				onChange: handleChange,
				properties: {
					type: "checkbox",
					id: "stripe.card_payments",
					value: !paymentMethods.stripe.card_payments,
					checked: paymentMethods.stripe.card_payments,
				},
				moreInfoIcon: {
					before: " ",
					absolute: true,
					text: "Accept all major credit card brands",
				},
			},		
			// {
			// 	label: "Pre Authorized Debit payments",
			// 	onChange: handleChange,
			// 	properties: {
			// 		type: "checkbox",
			// 		id: "stripe.acss_debit_payments",
			// 		value: !paymentMethods.stripe.acss_debit_payments,
			// 		checked: paymentMethods.stripe.acss_debit_payments,
			// 	},
			// 	moreInfoIcon: {
			// 		before: " ",
			// 		absolute: true,
			// 		text: "Accept Pre Authorized Debit payments (Canada only)",
			// 	},
			// },
			{
				visible: true,
				properties: {
					type: "hidden",
					id: "stripe-info",
					onChange: () => null,
				},

			// if billTO.address we need to be able to change and update this...
			// if user changes their address and is billTo then the next time a contractor tries to change 
			// the billTo portion of an invoice billed to the user the new users address gets input into the
			// fields in billTo modal... this shows that the users address is being exposed ... just solve this
			// when you work on making users information private and public. A user could have a private 
			// address and a business address or something

				afterInput: [
					<React.Fragment key="hasStripeBusinessAccount">
						<div key="afterDebitPayments" className="small-line-break" />
						<div>
							{accountLink && !canAcceptStripePayments ? (
								<p>
									You have missing information.&nbsp;
									<a
										ref={accountLinkRef}
										className="link-appearance"
										target="_blank"
										rel="noopener noreferrer"
										href={accountLink}
									>
										click here
									</a>{" "}
									to complete your profile
								</p>
							) : (
								<p>
									You have full control of your stripe account. Visit your{" "}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://dashboard.stripe.com"
									>
										Stripe.com Dashboard
									</a>{" "}
									to update any information shown below.
								</p>
							)}
						</div>
						<div className="small-line-break" />

						<details>
							<summary>Account Details</summary>
							<div>
								<p>
									Business Name:{" "}
									<em>{stripeBusinessObject.business_profile.name}</em>
								</p>
								<p>
									Business Profile:{" "}
									<em>{stripeBusinessObject.business_profile.url}</em>
								</p>
							</div>
							<div className="small-line-break" />
							<p>
								Account Email: <em>{stripeBusinessObject.email}</em>
							</p>
							<p>
								Default Currency: <em>{stripeBusinessObject.default_currency}</em>
							</p>
							<p>
								Additional account details needed:{" "}
								<em>{stripeBusinessObject.requirements.currently_due.length}</em>
							</p>
							<div className="small-line-break" />
							{stripeBusinessObject.external_accounts &&
							stripeBusinessObject.external_accounts.data &&
							stripeBusinessObject.external_accounts.data[0] &&
							stripeBusinessObject.external_accounts.data[0].bank_name ? (
								<div>
									<p>
										Payouts enabled:{" "}
										<em>
											{stripeBusinessObject.payouts_enabled ? "true" : "false"}
										</em>
									</p>
									<p>
										Pay to:{" "}
										<em>
											{stripeBusinessObject.external_accounts.data[0].bank_name +
												" " +
												stripeBusinessObject.external_accounts.data[0].last4}
										</em>
									</p>
									<p>
										Bank deposit frequency:{" "}
										<em>
											{stripeBusinessObject.settings.payouts.schedule.interval}
										</em>
									</p>
									<p>
										Bank deposit delay days:{" "}
										<em>
											{stripeBusinessObject.settings.payouts.schedule.delay_days}
										</em>
									</p>
								</div>
							) : (
								<div>
									<p>
										<em>No bank accounts connected</em>
									</p>
								</div>
							)}

						</details>
						<div className="section-divider" />
					</React.Fragment>
				]
			}
		]
	} else {
		inputs[inputs.length] = {
			visible: true,
			properties: {
				type: "hidden",
				id: "hiddenInput6",
				onChange: () => null,
			},
			beforeInput: [
				<React.Fragment key="noStripeBusinessAccount">
					<div className="section-divider" />
					<div className="section-title">Stripe payments <span className="payment-status disabled">Needs setup</span></div>
					<div className="small-line-break" />
					<p className="tiny align-center">
						Linvo uses Stripe to get you paid quickly and keep your personal
						and payment information secure. Thousands of companies around the
						world trust Stripe to process payments for their users. Set up a
						Stripe account to get paid with Linvo
					</p>
					<div className="small-line-break" />
					<div className="connect-container align-center">
						<SmallSpinner classNames={processing ? "inverse" : "hide"} />
						<div className="small-line-break" />
						<img
							{...(processing ? { className: "disabled" } : {})}
							onClick={(e) => {
								return processing
									? null
									: handleConnectWithStripe({ e, userObject });
							}}
							height="50px"
							id="connect-with-stripe"
							alt="connect with stripe"
							src="/assets/connect-with-stripe.svg"
						/>
						{accountLink && (
							<p>
								If you are not automatically redirected&nbsp;
								<a
									ref={accountLinkRef}
									className="link-appearance"
									target="_blank"
									rel="noopener noreferrer"
									href={accountLink}
								>
									click here
								</a>
							</p>
						)}
					</div>
					<div className="section-divider" />
				</React.Fragment>
			]
		}
	}

	return (
		<Modal
			custom={{ absolute: true, style: `z-index: 101;` }}
			onClickOutside={handleClickOutside}
		>
			<AppMessage />
			<PaymentSettingsModalStyle>
				{isFetching && <Spinner position="absolute" />}
				<Form 				
					heading={<div className="heading align-center">Payment Settings</div>}
					// submitName="Save"
					onSubmit={handleSaveSettings}
					inputs={inputs}
				>
					<div className="section-divider" />
					<div className="align-center submit-actions">
						<button className="button-appearance">
							Save
						</button>
						<button className="button-appearance gray" onClick={(e) => {
							e.preventDefault()
						 	handleClickOutside()
						}} >
							Cancel
						</button>
					</div>
				</Form>
			</PaymentSettingsModalStyle>
		</Modal>
	);
};

export default PaymentSettingsModal;
