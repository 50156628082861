import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";
import { AppMessage } from "../MessageUtils";
import Form from "../Forms/Form";
import SymbolFromId from "../svgComponents/SymbolFromId";
import Spinner from "../Spinner/Spinner";

import firebase from "../../firebase/index";
import {
	formulateSearchKeywords,
	makeRandomString,
	removeDuplicates,
	// providerIsFacebookOrGoogle,
} from "../../utils/appUtils";
// import Modal from '../Modal/Modal'

// rendered by dashboard
// errors and success handled
const NewProjectForm = ({ onClickOutside }) => {
	let history = useHistory();

	const uselessRefComponentBecauseReactSucks = useRef(false);

	const {
		userObject,
		updateUserData,
		usersInvoices,
		setUsersInvoices,
		getUsersInvoices,
		setUsersProjects,
		// handleSetMessage,
		// handleSetSuccessText,
		setErrorObj,
		// currentUser,
	} = useContext(UserContext);

	let loadingFirebaseIdTimer;

	useEffect(() => {
		uselessRefComponentBecauseReactSucks.current = true;

		return () => {
			if (loadingFirebaseIdTimer) {
				clearTimeout(loadingFirebaseIdTimer);
			}
			uselessRefComponentBecauseReactSucks.current = false;
		};
	}, [loadingFirebaseIdTimer]);

	// useEffect(() => {
	// 	if (user) {
	// 		if (!user.emailVerified && !providerIsFacebookOrGoogle(user)) {
	// 			handleSetMessage(
	// 				<React.Fragment>
	// 					<div className="error-text">
	// 						Sorry, you must verify email to create a project (this helps
	// 						prevent spam)
	// 					</div>
	// 					<div
	// 						onClick={() => {
	// 							user
	// 								.sendEmailVerification()
	// 								.then(() => {
	// 									handleSetSuccessText("Email verification link sent");
	// 								})
	// 								.catch((err) => setErrorObj(err));
	// 						}}
	// 						className="link-appearance blue"
	// 					>
	// 						Re-send verification link{" "}
	// 					</div>
	// 					to {user.email}
	// 				</React.Fragment>
	// 			);
	// 		}
	// 	}
	// 	return () => {
	// 		handleSetMessage("");
	// 	};
	// 	// want to make sure this error message is only set on first render and not set if other messages set
	// 	// eslint-disable-next-line
	// }, [user]);

	const [formData, setFormData] = useState({
		projectName: "",
		isCurrentProject: false, // this doesnt need to exist on the project doc only the user doc
		contractors: [userObject.uid],
		owner: userObject.uid,
		editors: [],
		followers: [],
		creator: userObject.uid,
		shortHandId: makeRandomString("", 3),
		billTo: {
			firstname: "",
			lastname: "",
			address: {
				city: "",
				country: "",
				line1: "",
				line2: "",
				postal_code: "",
				state: "",
			},
			uid: "",
			username: "",
		},
	});

	const [showInvoices, setShowInvoices] = useState(false);
	const [ownerOrBillToInvoices, setOwnerOrBillToInvoices] = useState([]);
	const [idsOfInvoicesToAdd, setIdsOfInvoicesToAdd] = useState([]);
	const [isLoadingSymbol, setIsLoadingSymbol] = useState(false);

	const handleChange = (name, value) => {
		setFormData((formData) => ({ ...formData, [name]: value }));
		if (name === "createdFor") {
			if (value === "forCreator") {
				setFormData((fd) => ({
					...fd,
					contractors: [],
					createdForUid: userObject.uid,
				}));
			}
			if (value === "forCustomer") {
				// set state takes the prev state as first parameter but if there is a problem it might be that it is taking the the actual state
				// value which might be a problem in this funvtion since setformData is called just prior and updates may not have taken affect
				setFormData((fd) => ({ ...fd, contractors: [userObject.uid] }));
			}
		}
	};

	const handleArrayChange = (id, value) => {
		let newArray;
		if (value === true) {
			newArray = [...idsOfInvoicesToAdd, id];
		} else {
			newArray = idsOfInvoicesToAdd.filter((item) => item !== id);
		}
		setIdsOfInvoicesToAdd(newArray);
	};

	useEffect(() => {
		if (userObject.id) {
			const getAndSetUsersInvoices = async (userId) => {
				const resUsersInvoices = await getUsersInvoices({userId, caller: "newProjectForm: useEffect", doNotSetInvoices: true});
				setUsersInvoices(resUsersInvoices);
				setOwnerOrBillToInvoices(
					resUsersInvoices.filter(
						(inv) =>
							!inv.forProject &&
							(
								inv.owner === userObject.id 
								|| inv.billTo.uid === userObject.id // some permissions error here dont include
							)
					)
				);
			};

			if (!usersInvoices) {
				getAndSetUsersInvoices(userObject.id);
			} else {
				setOwnerOrBillToInvoices(
					usersInvoices.filter(
						(inv) =>
							!inv.forProject &&
							(inv.owner === userObject.id ||
								inv.billTo.uid === userObject.id)
					)
				);
			}
		}
		// eslint-disable-next-line
	}, [userObject.id]);

	let userInvoiceInputs = [];
	if (ownerOrBillToInvoices.length && showInvoices) {
		console.log({setOwnerOrBillToInvoices})
		userInvoiceInputs = ownerOrBillToInvoices.map((inv) => ({
			label: `#${inv.invNumber} ${
				inv.invShortHand ? "(" + inv.invShortHand + ")" : ""
			}`,
			onChange: handleArrayChange,
			properties: {
				type: "checkbox",
				id: inv.id,
				name: "invoices",
				value: !idsOfInvoicesToAdd.includes(inv.id),
				checked: idsOfInvoicesToAdd.includes(inv.id),
			},
		}));
	}

	const handleSubmit = (e, fd) => {
		e.preventDefault();
		// if (disableInputs) {
		// 	if (!user) {
		// 		setErrorObj({
		// 			message: "Must be logged in to creat a project",
		// 			noReport: true,
		// 		});
		// 	} else {
		// 		setErrorObj({
		// 			message: "Must verify email to create a project",
		// 			noReport: true,
		// 		});
		// 	}

		// 	return false;
		// }
		const newProjectRef = firebase.firestore().collection("projects").doc(fd.id);

		// same problem here if offline with the .then's not firing
		// this should only update the rest if the others are update
		// newProjectRef.set({id: newProjectRef.id, ...fd})

		const getProjectFollowers = (fd) => {
			let projectFollowers = [];
			let projectEditors = [];
			idsOfInvoicesToAdd.forEach((invId) => {
				const fullInvObj = ownerOrBillToInvoices.find(
					(inv) => inv.id === invId
				);
				// update the invoice
				firebase
					.firestore()
					.collection("invoices")
					.doc(invId)
					.update({ forProject: newProjectRef.id })
					.catch((err) => setErrorObj(err));
				// gather the new  default invoice followers and editors
				projectFollowers = [
					...projectFollowers,
					...fullInvObj.editors,
					fullInvObj.owner,
					fullInvObj.billTo.uid,
				]; // push all editors as followers of the project this does not mean the can see all details
				projectEditors = [...projectEditors, fullInvObj.owner];
			});

			return {
				defaultProjectFollowers: removeDuplicates([
					...projectFollowers,
					userObject.uid,
				]),
				defaultProjectEditors: removeDuplicates([
					...projectEditors,
					userObject.uid,
				]),
			};
		};

		(async () => {
			// console.log(fd)
			const newFd = {
				...fd,
				id: newProjectRef.id,
				searchable: false,
				// creationTime: firebase.firestore.FieldValue.serverTimestamp(),
				creationTime: firebase.firestore.Timestamp.now().seconds,
				searchKeywords: formulateSearchKeywords({
					doc: fd,
					type: "project",
					userObject,
				}),
				visibility: "public",
			};

			delete newFd.isCurrentProject;

			if (idsOfInvoicesToAdd.length) {
				const { defaultProjectFollowers, defaultProjectEditors } =
					await getProjectFollowers(newFd);
				const newProjectData = {
					...newFd,
					accessors: removeDuplicates([
						...defaultProjectEditors,
						newFd.owner,
						newFd.billTo.uid,
					]),
					followers: [...newFd.followers, ...defaultProjectFollowers],
					editors: defaultProjectEditors,
				};
				newProjectRef.set(newProjectData).catch((err) => setErrorObj(err));
				setUsersProjects((usersProjects) => [...usersProjects, newProjectData]);
			} else {
				const newProjectData = {
					...newFd,
					accessors: removeDuplicates([newFd.owner, newFd.billTo.uid]),
					followers: [newFd.owner],
					editors: [newFd.owner],
				};
				newProjectRef.set(newProjectData).catch((err) => setErrorObj(err));
				setUsersProjects((usersProjects) => [...usersProjects, newProjectData]);
			}
		})();

		// redirect to the projects page
		history.push(`/projects/${newProjectRef.id}`, {isNewDoc: true});

		if (fd.isCurrentProject) {
			// const project = {id: newProjectRef.id, projectName: fd.projectName}
			updateUserData({ currentProject: newProjectRef.id });
			onClickOutside();
		} else {
			return onClickOutside();
		}
	};

	useEffect(() => {
		if (firebase) {
			setFormData((formData) => ({
				...formData,
				id: firebase.firestore().collection("projects").doc().id,
			}));
		}
		// eslint-disable-next-line
	}, []);

	// const disableInputs =
	// 	(!firebase.auth().currentUser ||
	// 		!firebase.auth().currentUser.emailVerified) &&
	// 	!providerIsFacebookOrGoogle(user);
	// const disableInputs = firebase.auth().currentUser

	const inputs = [
		{
			label: "Project Name",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "projectName",
				value: formData.projectName,
				autoFocus: "autoFocus",
				required: true,
				// disabled: disableInputs,
			},
		},
		{
			label: "3 letter quick ID",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "shortHandId",
				value: formData.shortHandId,
				maxLength: 3,
				minLength: 3,
				required: true,
				// disabled: disableInputs,
			},
			afterInput: [
				<div key="symbolFromId" className="project align-left">
					<div className="section-divider" />
					<div key="SymbolTitle">Project Icon:</div>
					<div className="small-line-break" />
					<div className="symbol-container">
						{isLoadingSymbol && (
							<div className="spinner">
								<Spinner position="relative" height="80px" width="80px" />
							</div>
						)}
						<div>
							<SymbolFromId
								id={formData.id || "loading"}
								maxCoordinate={31}
							/>
						</div>
					</div>
				</div>,
				<div key="generateSymbolId">
					<div className="small-line-break" />
					<button
						className="button-appearance tiny no-icon-or-underline"
						disabled={isLoadingSymbol}
						onClick={(e) => {
							e.preventDefault();
							// setFormData(formdata => ({...formData, id: ""}))
							setIsLoadingSymbol(true);
							setFormData((formData) => ({
								...formData,
								id: firebase.firestore().collection("projects").doc().id,
							}));
							// make a timeout to prevent spam generation of firebase random ids
							const resTime = Math.round(700 + 1000 * Math.random());
							loadingFirebaseIdTimer = setTimeout(() => {
								if (uselessRefComponentBecauseReactSucks.current) {
									setIsLoadingSymbol(false);
								}
							}, resTime);
						}}
					>
						Generate
					</button>
				</div>,
				// some permissions problem here
				// <div key="afterSymbolId" className="section-divider" />,
				// <div
				// 	key="showAddInvoices"
				// 	onClick={() => setShowInvoices(!showInvoices)}
				// >
				// 	{showInvoices ? "Hide -" : "Add Invoices +"}
				// </div>,
				// <div key="noInvoices">
				// 	{!userInvoiceInputs.length && showInvoices && "-- empty --"}
				// </div>,
			],
		},
		...userInvoiceInputs,
		{
			label: "Set this as my Current Project",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "isCurrentProject",
				value: !formData.isCurrentProject,
				checked: formData.isCurrentProject,
				// disabled: disableInputs,
			},
		},
	];

	return (
		<Form
			inputs={inputs}
			heading={
				<React.Fragment>
					<div className="heading">Create Project</div>
					<AppMessage dependants={[formData]} />
				</React.Fragment>
			}
			submitName="Create"
			onSubmit={(e) => handleSubmit(e, formData)}
		></Form>
	);
};

export default NewProjectForm;
