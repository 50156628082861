import React, { useContext, useState, useEffect } from 'react'
import styled from "styled-components";

import SymbolFromId from "../svgComponents/SymbolFromId";

import firebase from '../../firebase/index';
import { MediaQueryContext } from "../../contexts/MediaQueryContext";


const ForwardBillInfoStyle = styled.div `
	.invoice-name {
		box-shadow:  0px 1px 7px -1px green;
    white-space: nowrap;
    padding: 0 2px;
    border-radius: 5px;
    font-size: ${({ isLessThan700px }) => isLessThan700px ? "var(--font-size-xxs)" : "var(--font-size-xs)"};
	}
	.bill-name {
		box-shadow:  0px 1px 7px -1px blue;
    white-space: nowrap;
    padding: 0 2px;
    border-radius: 5px;
    font-size: ${({ isLessThan700px }) => isLessThan700px ? "var(--font-size-xxs)" : "var(--font-size-xs)"};
	}
	.diagram {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		.invoice-symbol {
				svg {
					${({isLessThan700px}) => isLessThan700px ? `
						height: 30px;
						width: 30px;
					`: 
					`
						height: 40px;
						width: 40px;
					`
					}
				}
			}
			.invoice {
				svg {
					box-shadow: 0px 5px 11px -3px green;
				}
			}
			.bill {
				svg {
					box-shadow: 0px 5px 11px -3px blue;
				}
			}
			> div {
				display: flex;
				flex-direction: column;
				h4 {
					margin: 0;
				}
			}
			.arrow {
				padding: 0;
				margin: 0;
				height: 15px;
			}
			.arrow-right {
		    margin: 10px 0 40px 0;
				padding: 0;
	      // transform: rotateZ(180deg);
				height: 15px;
			}
			.symbols {
				justify-content: flex-end;
			}
			p {
				margin: 5px 0 0 0;
				font-size: ${({isLessThan700px}) => isLessThan700px ? "var(--font-size-xxs)" : "var(--font-size-xs)"};
			}
			.positive-val {
				color: green;
			}
			.positive-val::before {
				content: "+";
			}
			.negative-val {
				color: red;
			}
			.negative-val::before {
				content: "-";
			}
			.img-container {
				border-radius: 50%;
				padding: 7px;
		    box-shadow: 5px 6px 16px -5px;
		    ${({isLessThan700px}) => isLessThan700px ? `
			    height: 35px;
			    width: 35px;

		    ` :`
  		    height: 45px;
			    width: 45px;
		    `
			  }
	      align-self: center;
				img {
					border-radius: 50%;
					padding: 0;
					height: 100%
				}
			}

	}
	.info {
		font-size: ${({isLessThan700px}) => isLessThan700px ? "var(--font-size-xs)" : "(--font-size-s)"};
		text-align: left;
	}

`

const ForwardBillInfo = ({userObject, invoiceTotals, forwardedTotal, subcontractorRate, profit, editedInv, formData, forwardedBill, setForwardedBill, getNum}) => {
	const [billTo, setBillTo] = useState({
		logoUrl: null
	})
  const { isLessThan700px } = useContext(MediaQueryContext);

		// get the billTo's logo
	useEffect(() => {
		if (!billTo.logoUrl) {
			if (editedInv.billTo.uid) {
				// if there is a billTO
			  if (!editedInv.billTo.logoUrl) {
			  	// get the billTo's logoUrl
			  	firebase.firestore().collection("users").doc(editedInv.billTo.uid).get().then((doc) => {
			  		const logoUrl = doc.data().logoUrl
			  		if (logoUrl) {
				  		setBillTo(billTo => ({
				  			...billTo,
				  			logoUrl
				  		}))
			  		}
			  	})
			  } else {
			  	// logoUrl of billTO is already in the editedInv
			  	setBillTo(billTo => ({
			  		...billTo,
			  		logoUrl: editedInv.billTo.logoUrl
			  	}))
			  }
			} else {
				// set the billTO as the current user
				if (userObject && userObject.logoUrl) {
					setBillTo(billTo => ({
						...billTo,
						logoUrl: userObject.logoUrl
					}))
				}
			}
		}
	}, [billTo, editedInv.billTo, userObject])

	const billName = `#${editedInv.invNumber} (${editedInv.invShortHand})`
	const invoiceName = `#${formData.invNumber} (${formData.invShortHand})`

    return (
      <ForwardBillInfoStyle isLessThan700px={isLessThan700px}>
      	<div className="diagram">
					<div className="user-image">
						<p 
							className={invoiceTotals.cost === 0 ? "" : invoiceTotals.cost > 0 ? "positive-val" : "negative-val"}>
					    ${getNum(invoiceTotals.cost || 0, 2)}
				    </p>
						{
							// <div className="arrow">
							// 	<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							//     <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
							// 	</svg>
							// </div>
						}
						<div className="img-container">
							<img alt="" src={editedInv.contractor.logoUrl || "/assets/blank-profile-picture.png"} />
						</div>
						<div className="arrow-right">
							<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
						    <path d="M22 6 L30 16 22 26 M30 16 L2 16" />
							</svg>
						</div>
					</div>

					<div className="symbols">
						{
							// <p>{`$${editedInv.rate}/h`}</p>
							// <p>{"<----"}</p>
						}
						<p>{`$${getNum(subcontractorRate)}/h`}</p>
						<SymbolFromId
							id={editedInv.id}
							className="invoice-symbol bill"
							svgProperties={{
								filter: "url(#shadow1)",
								strokeWidth: "2"
							}}
						/>
						<p className="bill-name">{billName}</p>
					</div>

					<div className="user-image">						
						<p className={profit === 0 ? "" : (profit > 0 ? "positive-val" : "negative-val")}>
							${getNum(profit || 0, 2).replace("-", "")}
						</p>
						{
							// <div className="arrow">
							// 	<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							//     <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
							// 	</svg>
							// </div>
						}
						<div className="img-container">
							<img alt="" src={billTo.logoUrl || "/assets/blank-profile-picture.png"} />
						</div>
						<div className="arrow-right">
							<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
						    <path d="M22 6 L30 16 22 26 M30 16 L2 16" />
							</svg>
						</div>
					</div>

					<div className="symbols">
						{
							// <p>$125%</p>
							// <p>{"<----"}</p>
						}
						<p>{`$${formData.rate}/h`}</p>
						<SymbolFromId
							id={formData.id || "loading"}
							className="invoice-symbol invoice"
							svgProperties={{
								filter: "url(#shadow1)",
								strokeWidth: "2"
							}}
						/>
						<p className="invoice-name">{invoiceName}</p>
					</div>

					<div className="user-image">
						<p className={forwardedTotal === 0 ? "": (forwardedTotal < 0 ? "positive-val" : "negative-val")}>
							${getNum(forwardedTotal || 0, 2).replace("-", "")}
						</p>
						{
							// <div className="arrow">
							// 	<svg viewBox="0 0 32 32" width="16px" height="16px" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							//     <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
							// 	</svg>
							// </div>
						}
						<div className="img-container">
							<img alt="" src={forwardedBill.billTo ? forwardedBill.billTo.logoUrl : "/assets/what-user.jpg"} />
						</div>
						<p>{formData.billTo.firstname}</p>
					</div>
				</div>
				<div className="info" >
					<details>
						<summary>
							{"Pairing "}
							<span className="bill-name">{billName}</span>
							{" allows for automatic updates to "}
							<span className="invoice-name">{invoiceName}</span>
							{" every time "}
							<span className="bill-name" >{billName}</span>
							{" is updated... "}
							<span className="link-appearance" >read more</span>
						</summary>		
							<p>
								A Levied Invoice looks and acts the same as a regular invoice, but its entries can be automatically updated with additional costs or higher rates-per-unit.
							</p>
							<p>
								Example: A contractor who charges $80/h hires a sub-contractor to work for them for $70/h:
							</p>
							<p>
								A Levied Invoice is the invoice between the contractor and their customer.
								<br />
								The sub-contractor works 8 and adds a new labor entry to <span className="bill-name">{billName}</span> with QTY: 8 and COST: $70. (total: $560)
								<br />
								The Levied invoice <span className="invoice-name">{invoiceName}</span> will be automatically updated with a new labor entry with QTY: 8 and COST: $80. (total: $640)
								<br />
								<br />
								The customer has no access to invoice between the contractor and the sub-contractor <span className="bill-name">{billName}</span> unless it is a public invoice and the customer visits the web url where this invoice can be found.
							</p>
					</details>
				</div>
      </ForwardBillInfoStyle>
    )
  }

export default ForwardBillInfo
