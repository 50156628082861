import styled from 'styled-components'


export const SearchPageStyle = styled.div `
	margin-left: 10px;
	margin-right: 10px;

	.top-info {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0px 10px;
		border-radius: 5px;
	  box-shadow: var(--brand) 0px 3px 10px -5px;
    font-size: var(--font-size-xs);
	  em {
  	  color: var(--chicago);
	  }
	}

	.search-result.add-top-margin {
		padding-top: 40px;
	}
	
	.main-container {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
	${({isSearching}) => isSearching && `
		opacity: 0.8;
		.spinner {
			opacity: 1;
		}
	`}
	input::-webkit-calendar-picker-indicator {
	  display: none !important;
	}
	.search-button {
		display: flex;
		svg {
			margin-left: 10px;
			height: 20px;
		}
	}
	.filters {
		text-align: left;
		align-content: left;
		label {
			display: inline-block;
			padding: 1px 7px;
			font-size: var(--font-size-s);
			color: var(--nav-item-hover);
			border-radius: 10px;
			margin: 5px 10px 5px 0;
	    box-shadow: none;
      white-space: nowrap;
		}
		input {
			display: none;
		}
		input:checked + label {
			box-shadow: 0px 0px 4px 1px var(--dark-green);
			color: #ada9a9;
		}

	}
	.card-title {
		font-weight: 700;
		font-size: ${({isLessThan700px}) => isLessThan700px ? "var(--font-size-m)" : "var(--font-size-l)"};
	}
	.contractor-rate {
		box-shadow: 0px 0px 4px 1px var(--chicago);
    font-size: var(--font-size-m);
    font-weight: 800;
    color: #ada9a9;
    border-radius: 10px;
    margin: 0px 0 2px 2px;
    padding: 0 7px;
    display: inline-block;
	}
	.action-buttons {
		font-size: var(--fornt-size-xs);
		white-space: nowrap;
		a {
			text-decoration: none;
			display: flex;
	    align-items: center;
      text-align: center;
	    padding: 4px 15px;
		}
		svg {
			height: 12px;
			width: 12px;
			padding-right: 5px;
		}
	}
	.user-stars.no-rating {
		.stars { 
			svg {
				fill: var(--nav-item-hover);
				color: var(--nav-item-hover);
			}
		}
	}
	.user-stars.high-rating {
		.rating-text {
			background-color: var(--brand-green);			
	    border-radius: 10px;
	    padding: 0 7px;
		}
	}
	.user-stars.expert-rating {
		.rating-text {
			background-color: var(--brand);			
	    color: white;
	    border-radius: 10px;
	    padding: 0 7px;
		}
	}
	.user-stars {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		text-align: right;
		font-size: var(--font-size-xxs);
    padding: 4px 9px 0 9px;
			background-color: white;
		.stars {
			svg {
				height: 18px;
				width: 18px;
			}
		}
		.rating-text {
			margin-top: -4px;
	    align-self: flex-end;
		}
	}
	.user-location {
		margin-top: 5px;
		font-size: var(--font-size-xs);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
		.location-name {
			text-decoration: underline;
			span {
				margin-top: 1px;
			}
		}
		${({isLessThan700px}) => isLessThan700px ? `
			margin-left: 2px;
		` : `
			position: absolute;
			bottom: 0;
			right: 0;
			margin-right: 1px;
		`}

	}
	.clear-location {
    padding: 5px 2px 5px 7px;
    vertical-align: middle;
    width: 12px;
    height: 12px;
	}
	.location-name {
		font-size: var(--font-size-s);
		color: #ada9a9;
	}
	.location-tags {
		margin-top: 20px;
		margin-bottom: 10px;
		.location-name {
			padding: 1px 7px;
			box-shadow: 0px 0px 4px 1px var(--dark-green);			
			border-radius: 10px;
		}
		svg {
	    padding: 5px 2px 5px 7px;
	    vertical-align: middle;
	    width: 12px;
	    height: 12px;
		}
	}
	.search-result {
	  padding: 10px;
    margin: 2px 2px 60px 2px;
	  border-radius: 5px;
	  display: flex;
	  position: relative;
	  left: 0;
	  justify-content: space-between;
	  text-align: left;
	  box-shadow: 0 0 10px -3px;
	  background-color: var(--white-text);
	  img {
		  height: 75px;
	    margin: 0 auto;
			box-shadow: 0px 0px 4px 1px #ada9a9;
	  	border-radius: 5px;
	  }
	  a {
	  	margin-right: 12px;
	  }
	  p {
	  	margin: 0;
	    width: 100px;
	    overflow: hidden;
	    text-overflow: ellipsis;
      white-space: nowrap;
	  }
	  .user-icon-container {
	  	position: relative;
      height: 71px;
	    display: flex;
	    flex-direction: column;
	    text-align: center;
			font-size: var(--font-size-xs);
	    height: min-content;
			box-shadow: 0px 0px 4px 1px #ada9a9;
			border-radius: 5px;
			padding: 10px 2px 5px 2px;
			background-color: white;
			img {
			  height: 55px;
				box-shadow: none;
				border-radius: 0;
			}
			p {
				width: 70px;
			}
	  }
	  .search-result-body {
	  	flex-grow: 1;
	  	text-overflow: ellipsis;
	    white-space: nowrap;
	    overflow: hidden;
      margin: 0 10px 0 5px;
			font-size: ${({ isLessThan700px }) => isLessThan700px ? "var(--font-size-s)" : "var(--font-size-m)"};
			position: relative;
			.hashtags {
				overflow: hidden;    
				max-height: 65px;
		    white-space: pre-wrap;
		    :hover {
			    overflow: visible;    
    	    max-height: unset;
			    background-color: white;
		    }
				a {
			    text-overflow: ellipsis;
					display: inline-block;
				}
			}
      ul {
  	    list-style: none;
		    margin: 0;
		    padding: 0;
      }
	  }
	  .post-result {
	  	max-width: 50%;
	  }
	  p:hover, .search-result-body:hover {
	  	white-space: normal;
	  }
	}
	.project {
		svg {
		  box-shadow: 0px 5px 11px -3px brown;
		}
	}
	.invoice {
		svg {
		  box-shadow: 0px 5px 11px -3px var(--brand-green);	
		}
	}

	.bill {
    svg {
		  box-shadow: 0px 5px 11px -3px var(--brand-blue);
		}
	}
	
	.symbol-container {
		text-align: center;
		padding: 5px;
    width: 100px;
  	${({isLessThan700px}) => isLessThan700px ? `
	    width: 80px;
	    font-size: var(--font-size-xs);
	    > a {
	    	overflow: hidden;
	    }
  		svg {
		    width: 60px;
		    height: 60px;
  		}
	    `:`
		    overflow: hidden;
	    `
		}
		p {
	    width: 100%;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    margin: 5px 0 0 0;
		}
		> a {
			display: block;
	    width: 85px;
		}

	}

`