import React, { useContext, useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { UserContext } from '../contexts/UserContext'

import NewProjectForm from '../components/CreateItem/NewProjectForm'
import NewInvoiceForm from '../components/CreateItem/NewInvoiceForm'

import Modal from '../components/Modal/Modal'

import { LayoutIconColumnsSvg, LayoutIconRowSvg } from '../components/svgComponents/generalIcons'

import DocumentList from '../components/DocumentList/DocumentList'

import firebase from '../firebase/index'
import { getFollowingProjects, getFollowingInvoices } from '../firebase/utils' 
import { MediaQueryContext } from '../contexts/MediaQueryContext'
import { AddCircleButton } from '../components/svgComponents/generalIcons'


const useQuery = () => new URLSearchParams(useLocation().search)

// rendered by App.js
// errors and successText handled
const Dashboard = () => {
	const {
		// currentUser,
		userObject,
		// setUserObject,
		usersProjects,
		setUsersProjects,
		getUsersProjects,
		usersInvoices,
		setUsersInvoices,
		getUsersInvoices,
		setErrorObj,
		// handleSetSuccessText
	} = useContext(UserContext)
	const {
		isLessThan700px
	} = useContext(MediaQueryContext)

	const query = useQuery()
	const history = useHistory()

	const userId = firebase.auth().currentUser ?  firebase.auth().currentUser.uid : null


	// in order to satisfy dependency list of a useEffect and call an async function only once from a useEffect 
	// and to only set the fetched data in state if the component is mounted, we need to use a useRef that makes
	// a mutable variable to track whether the component is mounted... unless we dont care about esLint warnings
	// if nothing else (like a setIsFetching state) is fired while the fetch is happening we dont need to use the 
	// useRef. we can call any other side effects in the fetching function to avoid re render, making the isFetching true
	// and thereby not calling the set state function that happens after fetch is completed
	const uselessRefComponentBecauseReactSucks = useRef(false)

	const [modalData, setModalData] = useState({
		open: false,
		type: "",
		industry: "",
		contractorId: ""
	})

	const [onlyFollowingProjects, setOnlyFollowingProjects] = useState(null)
	const [onlyFollowingInvoices, setOnlyFollowingInvoices] = useState(null)
	const [recentDocs, setRecentDocs] = useState([])
	const [filters, setFilters] = useState({
		listOnlyFollowingProjects: false,
		listOnlyFollowingInvoices: false
	})

	const [isFetchingUsersInvoices, setIsFetchingUsersInvoices] = useState(false)
	const [isFetchingUsersProjects, setIsFetchingUsersProjects] = useState(false)
	const [isFetchingFollowingInvoices, setIsFetchingFollowingInvoices] = useState(false)
	const [isFetchingFollowingProjects, setIsFetchingFollowingProjects] = useState(false)

	const [isFetchingRecents, setIsFetchingRecents] = useState(false)

	const handleFilterChange = (e) => {
		const {name, value} = e.target

		const resVal = value === "false" ? false : true

		setFilters(filters => ({
			...filters,
			[name]: resVal
		}))
	}

	const onClickOutside = () => {
		// setErrorObj(null)
		setModalData(modalData => ({
			...modalData,
			open: false
		}))
	}

	// // // fetchees and sets docs, for use with docs that are undefined or null to start
	const handleFetcAndSetDocs = async ({setIsFetchingDocs, setDocs, getDocs, setErrorObj}) => {
		try {
			// console.log("getDocs running")
			setIsFetchingDocs(true)
			const resDocs = await getDocs()
			if (uselessRefComponentBecauseReactSucks.current) {
				setDocs(docs => {
					// must compare length to prevent infinate loop in case user has no invoices
					if (!docs || (resDocs && (docs.length !== resDocs.length))) {
						return resDocs
					} else return docs
				})
				setIsFetchingDocs(false)
			// } else {
			// 	console.log("componentIsMounted = ", uselessRefComponentBecauseReactSucks.current, " after resDocs =", resDocs)
			}
		} catch (err) {
			setErrorObj(err)
		}
	}

	const getFilters = (propertyName, handleFilterChange, filters) => {
		return (
			<div className="radio-button-nav">
				<input id={`my-work-${propertyName}`} type="radio" value="false" name={propertyName} onChange={handleFilterChange} checked={!filters[propertyName]} />
				<label htmlFor={`my-work-${propertyName}`} >My Work
				</label>
				<input id={`only-following-${propertyName}`} type="radio" value="true" name={propertyName} onChange={handleFilterChange} checked={filters[propertyName]} />
				<label htmlFor={`only-following-${propertyName}`} >Following
				</label>
			</div>
		)
	}

	useEffect(() => {
    uselessRefComponentBecauseReactSucks.current = true

    return () => { 
    	uselessRefComponentBecauseReactSucks.current = false 
    }
	}, [])

	// // if user does not have early access or not directly listed. set filters to following
	// useEffect(() => {
	//   if (userObject && userObject.id && (userObject.hasEarlyAccess === false || userObject.directlyListed === false)) {
	// 		setFilters(filters => ({
	// 			...filters,
	// 			listOnlyFollowingInvoices: true,
	// 			listOnlyFollowingProjects: true,
	// 		}))
	//   }
	//   // only do this if there is already userObject otherwise someone logged in will be shown onlyFollowing
	//   // invoices and projects upon page refresh
	//   // eslint-disable-next-line
	// }, [userObject.id, userObject.hasEarlyAccess, userObject.directlyListed])

	// usersInvoices
	useEffect(() => {
		// get and set usersInvoices
		if (!usersInvoices && !isFetchingUsersInvoices && userId && !filters.listOnlyFollowingInvoices) {
			const getDocs = () => getUsersInvoices({userId, caller: "Dashboard: useEffect", doNotSetInvoices: true})
			handleFetcAndSetDocs({setIsFetchingDocs: setIsFetchingUsersInvoices, setDocs: setUsersInvoices, getDocs, setErrorObj})
		}
	}, [
		userId, 
		setErrorObj,
		filters.listOnlyFollowingInvoices, 

		isFetchingUsersInvoices,
		setIsFetchingUsersInvoices,

		usersInvoices,
		setUsersInvoices,
		getUsersInvoices
	])

	// only following invoices
	useEffect(() => {
		// get and set usersInvoices
		if (!onlyFollowingInvoices && !isFetchingFollowingInvoices && userId && filters.listOnlyFollowingInvoices) {
			const getDocs = () => getFollowingInvoices({usersInvoices, userId})
			handleFetcAndSetDocs({setIsFetchingDocs: setIsFetchingFollowingInvoices, setDocs: setOnlyFollowingInvoices, getDocs, setErrorObj})
		}
	}, [
		userId,
		setErrorObj,
		filters.listOnlyFollowingInvoices,

		isFetchingFollowingInvoices,
		setIsFetchingFollowingInvoices,

		usersInvoices,

		onlyFollowingInvoices,
		setOnlyFollowingInvoices
	])

	// usersProjects
	useEffect(() => {
		// get and set usersProjects
		if (!usersProjects && !isFetchingUsersProjects && userId && !filters.listOnlyFollowingProjects) {
			const getDocs = () => getUsersProjects({userId, caller: "Dashboard: useEffect", doNotSetProjects: true})
			handleFetcAndSetDocs({setIsFetchingDocs: setIsFetchingUsersProjects, setDocs: setUsersProjects, getDocs, setErrorObj})
		}
	}, [
		userId,
		setErrorObj,
		filters.listOnlyFollowingProjects,

		isFetchingUsersProjects,
		setIsFetchingUsersProjects,

		usersProjects, 
		setUsersProjects,
		getUsersProjects
	])

	// only following projects
	useEffect(() => {
		// get and set usersInvoices
		if (!onlyFollowingProjects && !isFetchingFollowingProjects && userId && filters.listOnlyFollowingProjects) {
			const getDocs = () => getFollowingProjects({usersProjects, userId})			
			handleFetcAndSetDocs({setIsFetchingDocs: setIsFetchingFollowingProjects, setDocs: setOnlyFollowingProjects, getDocs, setErrorObj})
		}
	}, [
		userId,
		setErrorObj,
		filters.listOnlyFollowingProjects,

		isFetchingFollowingProjects,
		setIsFetchingFollowingProjects,

		usersProjects,
		
		onlyFollowingProjects,
		setOnlyFollowingProjects
	])

	// set recent docs
	useEffect(() => {
		if (userObject.recentlyViewedDocs && userObject.recentlyViewedDocs.length) {
				setIsFetchingRecents(true)
				// let newRecentDocs = [...userObject.recentlyViewedDocs.invoices, ...userObject.recentlyViewedDocs.projects]
			// setIsFetchingRecents(false)

			if ((usersProjects && usersProjects.length) || (usersInvoices && usersInvoices.length)) {
				// set any recent docs to the full object found in in usersInvoices or usersProjects
				let newRecentDocs = []
				userObject.recentlyViewedDocs.forEach(doc => {
					const docInUsersProjects = usersProjects ? usersProjects.find(proj => proj.id === doc.id) : null
					const docInUsersInvoices = usersInvoices ? usersInvoices.find(inv => inv.id === doc.id) : null

					if (docInUsersProjects) {
						newRecentDocs.push(docInUsersProjects)
					} else if (docInUsersInvoices) {
						newRecentDocs.push(docInUsersInvoices)
					} else {
						newRecentDocs.push(doc)
					}

				})

				setRecentDocs(recentDocs => {
					if (!recentDocs || JSON.stringify(recentDocs) !== JSON.stringify(newRecentDocs)) {
						return [...newRecentDocs]
					} else return recentDocs
				})
			} else {
				// set the light weight version of the recent docs
				setRecentDocs(recentDocs => {
					if (!recentDocs || JSON.stringify(recentDocs) !== JSON.stringify(userObject.recentlyViewedDocs)) {
						return [...userObject.recentlyViewedDocs]
					} else return recentDocs
				})
			}

			setIsFetchingRecents(false)
		}		
	}, [userObject.recentlyViewedDocs, setRecentDocs, usersProjects, usersInvoices])

	// get url search params
	useEffect(() => {
		let action = query.get("action")
		let open = query.get("open") === "true" ? true : false
		let type = query.get("type") || ""
		let industry = query.get("industry") || ""
		let contractorId = query.get("contractorId") || ""

		if (userId && action === "addDocument") {
			setModalData(modalData => ({
				...modalData,
				open,
				type,
				industry,
				contractorId
			}))

			history.replace(history.location.pathname)
		}
	}, [userId, history, query])

	return (
		<div>
			{
				modalData.open && 
				<Modal custom={{absolute: true}} onClickOutside={onClickOutside}>
					{
						modalData.type === "projects" &&
						<NewProjectForm modalData={modalData} onClickOutside={onClickOutside} />
					}
					{
						modalData.type === "invoices" &&
						<NewInvoiceForm modalData={modalData} onClickOutside={onClickOutside} />
					}
					{
						// modalData.type === "contract" && 
						// <NewContractForm modalData={modalData} onClickOutside={onClickOutside} subCollectionsList={subCollectionsList}/>
					}
				</Modal>
			}
				<div className="recentDocs-container" >
						<h2 >
							Recent
						</h2>
						<DocumentList 
							// isLoadingDocs={isLoadingDocs}
							userId={userId}
							collection="recent" 
							docList={recentDocs} 
							setDocList={setRecentDocs} 
							setModalData={setModalData}
							isFetchingDocs={isFetchingRecents}
							setIsFetchingDocs={setIsFetchingRecents}
						/>
				</div>
			<Modal>
				{
					!filters.listOnlyFollowingProjects &&
					<div className="create-new">
						<span
							onClick={() => {
								// if (userObject && userObject.hasEarlyAccess) {
									setModalData(modalData => ({...modalData, type: "projects", open: true}))
								// } else {
				        	// handleSetSuccessText(<div style={{color: "white"}}>Sorry! this is limited to Early Access Members only<Link  className="link-appearance white" style={{padding: "0 5px"}} to="/request-early-access">Request Access</Link></div>, 10000)
								// }
							}}
						>
							<AddCircleButton 
									height="20px" width="20px"
									style={{backgroundColor: "var(--brand-green)"}}
								/>
								<span> New {
								//	(!userObject || !userObject.hasEarlyAccess) ? <span className="tiny">🔒</span> : ""
								}</span>
							</span>
					</div>
				}
				{
					isLessThan700px && <div className="small-line-break" />
				}
				<h2 className="heading-l">
					Projects
				</h2>
				{getFilters("listOnlyFollowingProjects", handleFilterChange, filters)}
				<input className="checkbox-item-layout" id="projects-item-layout" type="checkbox" defaultChecked={isLessThan700px} />
				<label className="item-layout" htmlFor="projects-item-layout" >
					<LayoutIconColumnsSvg className="columns-layout" />
					<LayoutIconRowSvg className="horizontal-scroll-layout" />
				</label>
				<DocumentList 
					// isLoadingDocs={isLoadingDocs}
					userId={userId}
					collection={filters.listOnlyFollowingProjects ? "onlyFollowingProjects" : "projects"} 
					docList={filters.listOnlyFollowingProjects ? onlyFollowingProjects : usersProjects} 
					setDocList={filters.listOnlyFollowingProjects ? setOnlyFollowingProjects : setUsersProjects} 
					isFetchingDocs={filters.listOnlyFollowingProjects ? isFetchingFollowingProjects : isFetchingUsersProjects}
					setIsFetchingDocs={filters.listOnlyFollowingProjects ? setIsFetchingFollowingProjects : setIsFetchingUsersProjects}

				/>
				<div className="section-divider" />
			</Modal>

			<Modal>
				{
					!filters.listOnlyFollowingInvoices &&
					<div className="create-new">
						<span
							onClick={() => {
								// if (userObject && userObject.hasEarlyAccess) {
									setModalData(modalData => ({...modalData, type: "invoices", open: true}))
								// } else {
				        	// handleSetSuccessText(<div style={{color: "white"}}>Sorry! this is limited to Early Access Members only<Link  className="link-appearance white" style={{padding: "0 5px"}} to="/request-early-access">Request Access</Link></div>, 10000)
								// }
							}}
						>
							<AddCircleButton 
									height="20px" width="20px"
									style={{backgroundColor: "var(--brand-green)"}}
								/>
								<span> New {
								//	(!userObject || !userObject.hasEarlyAccess) ? <span className="tiny">🔒</span> : ""
								}</span>
							</span>
					</div>
				}
				{
					isLessThan700px && <div className="small-line-break" />
				}
				<h2 className="heading-l">
					Invoices
				</h2>
				{getFilters("listOnlyFollowingInvoices", handleFilterChange, filters)}
				<input className="checkbox-item-layout" id="invoices-item-layout" type="checkbox" defaultChecked={isLessThan700px} />
				<label className="item-layout" htmlFor="invoices-item-layout" >
					<LayoutIconColumnsSvg className="columns-layout" />
					<LayoutIconRowSvg className="horizontal-scroll-layout" />
				</label>
				<DocumentList 
					// isLoadingDocs={isLoadingDocs}
					userId={userId}
					collection={filters.listOnlyFollowingInvoices ? "onlyFollowingInvoices" :"invoices"}
					docList={filters.listOnlyFollowingInvoices ? onlyFollowingInvoices : usersInvoices} 
					setDocList={filters.listOnlyFollowingInvoices ? setOnlyFollowingInvoices : setUsersInvoices} 
					setModalData={setModalData}
					isFetchingDocs={filters.listOnlyFollowingInvoices ? isFetchingFollowingInvoices : isFetchingUsersInvoices}
					setIsFetchingDocs={filters.listOnlyFollowingInvoices ? setIsFetchingFollowingInvoices : setIsFetchingUsersInvoices}

				/>
				<div className="section-divider" />
			</Modal>

			<Modal>
				<h2 className="heading-l">My Bills</h2>
				<input className="checkbox-item-layout" id="bills-item-layout" type="checkbox" defaultChecked={isLessThan700px} />
				<label className="item-layout" htmlFor="bills-item-layout" >
					<LayoutIconColumnsSvg className="columns-layout" />
					<LayoutIconRowSvg className="horizontal-scroll-layout" />
				</label>
				<DocumentList 
					// isLoadingDocs={isLoadingDocs}
					userId={userId}
					collection="bills" 
					docList={usersInvoices} 
					setDocList={setUsersInvoices} 
					setModalData={setModalData}
					isFetchingDocs={isFetchingUsersInvoices}
					setIsFetchingDocs={setIsFetchingUsersInvoices}
				/>
				<div className="section-divider" />
			</Modal>

		</div>
	)
}

export default Dashboard
