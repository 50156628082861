import React, { useContext, useEffect, useState, useRef } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";

import { UserContext } from "./contexts/UserContext";
import { EditInvoiceContextProvider } from "./contexts/EditInvoiceContext";
import { EditProjectContextProvider } from "./contexts/EditProjectContext";
import { MediaQueryContext } from "./contexts/MediaQueryContext";
import { DialogContext } from "./contexts/DialogContext";

import { AppMessage } from "./components/MessageUtils";
import Heading from "./components/Heading/Heading";
// import Features from "./pages/Features";
import Home from "./pages/Home";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard";
import Spinner from "./components/Spinner/Spinner";
import NotificationsModal from "./components/NotificationsModal";
import ChangePasswordModal from "./components/ChangePasswordModal";

import Menu from "./components/Menu/Menu";
import Contact from "./pages/Contact";
import Help from "./pages/Help";
import SpecificInvoice from "./pages/SpecificInvoice";
import SpecificProject from "./pages/SpecificProject";
import UserProfile from "./components/UserProfile/UserProfile";
import UserSettingsModal from "./components/UserSettings/UserSettingsModal";
import PaymentSettingsModal from "./components/UserSettings/PaymentSettingsModal";
import Footer from "./components/Footer/Footer";
import Messages from "./pages/Messages";
import EmailPreferences from './components/EmailPreferences';
import Pricing from './pages/Pricing';
import Marketplace from './pages/Marketplace';
import Promotions from './pages/Promotions';
// import RequestAccess from './pages/RequestAccess';


import LoginOrSignup from "./components/Login/LoginOrSignup";
import SearchResults from "./components/searchComponents/SearchResults";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import CookiesPolicy from "./CookiesPolicy";
import ContestTerms from "./ContestTerms";


import firebase from "./firebase/index";
import {
  loginStatus,
  handleDeleteAccount,
  signInWithGoogle,
  signInWithFacebook,
} from "./firebase/auth";

const useQuery = () => new URLSearchParams(useLocation().search);

// rendered by Index.js
const App = ({stripePublicKey}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  let query = useQuery();

  const {
    currentUser,
    userObject,
    handleAuthStateChanged,
    setErrorObj,
    isFetching,
    setIsFetching,
    loginModalOpen,
    setLoginModalOpen,
    settingsModalOpen,
    paymentSettingsModalOpen,
    setPaymentSettingsModalOpen,
    offlineMode,
    handleSetSuccessText,
    changePasswordModalData,
    promptPasswordIfOnlyEmailLink,
    doNotTerminateFetching,
    userIsAnonymous
  } = useContext(UserContext);
  const { isLessThan700px } = useContext(MediaQueryContext);
  const { dialog } = useContext(DialogContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResultsArray, setSearchResultsArray] = useState(null);
  const [searchData, setSearchData] = useState({
    text: "",
    location: "",
    city: "",
    postalCode: "",
    searchFilters: {
      generalContractors: {exclusive: false, name: "General Contractors", checked: true, visible: true},
      subContractors: {exclusive: false, name: "Sub Contractors", checked: true, visible: true},
      myCustomers: {exclusive: true, name: "Only My Customers", checked: false, visible: false},
      projects: {exclusive: false, name: "Projects", checked: true, visible: false},
      bills: {exclusive: false, name: "My Bills", checked: true, visible: false},
      invoices: {exclusive: false, name: "Invoices", checked: true, visible: false},
      posts: {exclusive: false, name: "Posts", checked: false, visible: true},
    },
    mainSearchCategory: "contractors",
    // subCategory: "all",
    searchRadius: 50,
  });

  const [lastMapCenter, setLastMapCenter] = useState(null);

  const topOfPageRef = useRef(null);

  const handlePromptMergeAccount = async (err) => {
    // to do... does this even work?
    const runPasswordPrompt = async (message) => {
      try {
        let promptPassword = await dialog.prompt(message);
        if (promptPassword) {
          const loginResult = await firebase
            .auth()
            .signInWithEmailAndPassword(err.email, promptPassword);
          if (loginResult && loginResult.user) {
            await loginResult.user.linkWithCredential(err.credential);
            // delete old user from db if one exists... ?
            handleSetSuccessText("Success! accounts linked");
            return true;
          }
        } else {
          await firebase.auth().sendPasswordResetEmail(err.email);
          handleSetSuccessText(
            `Success, password reset link sent to ${err.email}`,
            6000
          );
          return true;
        }
      } catch (err) {
        throw err;
      }
    };

    try {
      const signInMethods = await firebase
        .auth()
        .fetchSignInMethodsForEmail(err.email);
      if (signInMethods[0] === "password") {
        let message = `The email address (${err.email}) in your ${err.credential.signInMethod} account is already in use. Please link these accounts by entering your password. If you forgot your password click cancel`;
        await runPasswordPrompt(message);
        return true;
      } else if (signInMethods[0] === "google.com") {
        const confirmContinueWithGoogle = await dialog.confirm(
          `${err.email} already has an account through the Google Authentication Provider. Click 'OK' to Continue with Google`
        );
        if (confirmContinueWithGoogle) {
          signInWithGoogle();
        }
        // set credential in localStorage ?
      } else if (signInMethods[0] === "facebook.com") {
        const confirmContinueWithFacebook = await dialog.confirm(
          `${err.email} already has an account through the Facebook Authentication Provider. Click 'OK' to Continue with Facebook`
        );
        if (confirmContinueWithFacebook) {
          signInWithFacebook();
        }
      } else {
        console.log("error no such provider");
      }
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        return runPasswordPrompt(
          "Wrong password, please try again or click cancel to send password reset email"
        ).catch((err) => {
          if (err.code === "auth/wrong-password") {
            return runPasswordPrompt(
              "Wrong password, please try again or click cancel to send password reset email"
            ).catch((err) => {
              if (
                err.code === "auth/wrong-password" ||
                err.code === "auth/too-many-requests"
              ) {
                err.message =
                  "Too many password attempts. To try again, please log out and log back in";
                err.noReport = true;
                throw err;
              } else {
                throw err;
              }
            });
          } else {
            throw err;
          }
        });
      } else {
        throw err;
      }
    }
  };

  // // handle image errors
  // useEffect(() => {
  //   let listener = window.addEventListener("error", (e) => {
  //     console.log(e)
  //   })

  //   return () => {
  //     if (listener) {
  //       window.removeEventListener("error", listener)
  //     }
  //   }
  // })

  // prevent mouse wheel number changes on number inputs
  useEffect(() => {
    const preventNumberChange = (e) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    }
    document.addEventListener("wheel", preventNumberChange)
    return () => {
      document.removeEventListener("wheel", preventNumberChange)
    }
  }, [])

  // catch fetching errors
  useEffect(() => {
    let reloadTimeout;
    let timer;

    const terminateFetching = () => {
      setIsFetching(false);
      let tries = window.history.state
        ? window.history.state.terminateFetchingTries || 0
        : 0;
      if (isNaN(tries)) {
        tries = 0;
      }
      tries += 1;
      window.history.replaceState({ terminateFetchingTries: tries }, "");

      if (tries > 1) {
        handleSetSuccessText(
          "Sorry! This page isn't loading properly, one moment please"
        );
        const errorExists = firebase
          .firestore()
          .collection("errorLogs")
          .doc(
            `Fetching Timeout on ${encodeURIComponent(window.location.href)}`
          );

        const user = firebase.auth().currentUser

        if (
          window.location.href.includes("192.168") ||
          window.location.href.includes("localhost")
        ) {
          console.log({
            error: "localhost Fetching Timeout in App.js",
            tries,
            page: encodeURIComponent(window.location.href),
            occurance: firebase.firestore.FieldValue.increment(1),
            lastInstance: firebase.firestore.FieldValue.serverTimestamp(),
            ...user && user.uid ? {affectedUsers: firebase.firestore.FieldValue.arrayUnion(user.uid)} : {} 
          })
          reloadTimeout = setTimeout(() => {
            window.history.replaceState({ terminateFetchingTries: 0 }, "");
            window.location.reload(true);
          }, 4000);
        } else {
          errorExists
            .set(
              {
                error: "Fetching Timeout in App.js",
                tries,
                page: encodeURIComponent(window.location.href),
                occurance: firebase.firestore.FieldValue.increment(1),
                lastInstance: firebase.firestore.FieldValue.serverTimestamp(),
                ...user && user.uid ? {affectedUsers: firebase.firestore.FieldValue.arrayUnion(user.uid)} : {} 
              },
              { merge: true }
            )
            .then(() => {
              reloadTimeout = setTimeout(() => {
                window.history.replaceState({ terminateFetchingTries: 0 }, "");
                window.location.reload(true);
              }, 4000);
            })
            .catch((err) => {
              clearTimeout(reloadTimeout);
              window.history.replaceState({ terminateFetchingTries: 0 }, "");
              setErrorObj(err);
            });

        }
      }
    };
    if (isFetching) {
      if (!doNotTerminateFetching) {
        clearTimeout(timer);
        timer = setTimeout(terminateFetching, 7000);
      }
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(reloadTimeout);
    };
    // eslint-disable-next-line
  }, [isFetching]);


  useEffect(() => {
    if (userObject.id && loginModalOpen && !userIsAnonymous) {
      setLoginModalOpen(false)
      handleSetSuccessText("You are logged in!")
    }
    // eslint-disable-next-line 
  }, [userObject.id, loginModalOpen])

  // handle all urlParams
  useEffect(() => {
    // let params = query.get("action")
    // if there is a query ... query.keys() returns an iterator
    if (query.toString()) {
      let keysToDelete = []

      query.forEach((val, key) => {
        if (key === "action") {
          if (val === "promptLogin") {
            // delete doesnt seem to do anything
            // query.delete(key)
            setLoginModalOpen(true);
          }

          if (val === "openNotifications") {
            setNotificationsModalOpen(true)            
          }
          keysToDelete.push({key, val})
        }
      });

      // remove all keys
      if (keysToDelete.length) {
        let newQuery = new URLSearchParams(query.toString())
        keysToDelete.forEach(({key, val}) => {
          newQuery.delete(key)
        })

        if (newQuery) {
          history.replace(`${history.location.pathname}?${newQuery.toString()}`);
        } else {
          history.replace(`${history.location.pathname}`);
        }
      }
    }
    // eslint-disable-next-line
  }, [history.location]);

  // check user auth state
  useEffect(() => {
    if (pathname !== "account-deleted") {
      const isPendingLoginRedirect =
        sessionStorage.getItem("pendingRedirect") === "true";
      if (isPendingLoginRedirect) {
        setIsFetching(true);
        sessionStorage.setItem("pendingRedirect", "false");
      } else {
        setIsFetching(false);
      }

      firebase
        .auth()
        .getRedirectResult()
        .then(async (res) => {
          if (res.user) {
            sessionStorage.setItem("newLogin", "true");
            const evaluatePendingOperations = async (str) => {
              let pendingOpsArray = JSON.parse(str);
              if (pendingOpsArray.includes("deleteAccount")) {
                // deleteAccount initiated by deleteAccount action in userSettingsModal.js
                // pendingOpsArray.pop("deleteAccount");
                pendingOpsArray = pendingOpsArray.filter(
                  (item) => item !== "deleteAccount"
                );
                if (!pendingOpsArray.length) {
                  localStorage.setItem("linvoPendingOps", "");
                } else {
                  localStorage.setItem(
                    "linvoPendingOps",
                    JSON.stringify(pendingOpsArray)
                  );
                }
                const confirmDelete = await dialog.confirm(`Confirm delete ${res.user.email + "'s" || "your"} account`)
                if (confirmDelete) {
                  // setUserObject({})
                  setIsFetching(false);
                  handleDeleteAccount(null, false, true, true, dialog);
                } else {
                  setIsFetching(false);
                }
              }

              if (pendingOperations.includes("changeEmailAddress")) {
                let newEmail = localStorage.getItem("newEmailAddress");
                pendingOpsArray.pop("changeEmailAddress");

                if (!pendingOpsArray.length) {
                  localStorage.setItem("linvoPendingOps", "");
                } else {
                  localStorage.setItem(
                    "linvoPendingOps",
                    JSON.stringify(pendingOpsArray)
                  );
                }
                setIsFetching(false);
                let promptNewEmailAddress = await dialog.prompt(
                  "Enter your new email address",
                  newEmail
                );
                localStorage.setItem("newEmailAddress", "");

                if (promptNewEmailAddress) {
                  res.user
                    .updateEmail(promptNewEmailAddress)
                    .then(() => {
                      // setting userObject here wrecks onAuthStateChanged
                      return res.user
                        .sendEmailVerification()
                        .then(() => window.location.reload());
                    })
                    .catch((err) => setErrorObj(err));
                }
              }
              return;
            };

            const pendingOperations = localStorage.getItem("linvoPendingOps");

            if (pendingOperations) {
              setIsFetching(true);
              return evaluatePendingOperations(pendingOperations);
            } else {
              setIsFetching(false);
            }

            handleSetSuccessText("You are now logged in", 4000)
          }
        })
        .catch((err) => {
          // The email of the user's account used.
          // The firebase.auth.AuthCredential type that was used.
          if (err.code === "auth/user-cancelled") {
            return;
          } else if (
            err.code === "auth/account-exists-with-different-credential"
          ) {
            return handlePromptMergeAccount(err);
          } else {
            setErrorObj(err);
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  // handle user logged in status
  useEffect(() => {
    // only set fetching if not already fetching
    const shouldSetFetching = !isFetching
    if (shouldSetFetching) {
      setIsFetching(true)
    }
    loginStatus(handleAuthStateChanged, dialog)
    .then((result) => {
      if (shouldSetFetching) {
        setIsFetching(false);
      }
      if (result && result.isLoginWithEmailLink && result.email) {

        // prompt user to change password
        promptPasswordIfOnlyEmailLink(result.email)
      }
    })
    .catch((err) => {
      setErrorObj(err);
      if (shouldSetFetching) {
        setIsFetching(false);
      }
    });
    // eslint-disable-next-line
  }, [])

  // scroll to top of page on new window location
  useEffect(() => {
    if (topOfPageRef.current) {
      topOfPageRef.current.scrollIntoView(false);
    }
    let redirectTimeout;

    if (pathname === "/account-deleted") {
      redirectTimeout = setTimeout(() => {
        window.location.assign(window.location.origin);
      }, 3000);
    }

    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout);
      }
    };
  }, [pathname]);

  useEffect(() => {
    if (sessionStorage.getItem("newLogin") === "true") {
      if (pathname === "/") {
        history.replace("/dashboard", {});
        sessionStorage.setItem("newLogin", "false");
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  // set PaymentSettingsModal open if url includes setup-payment-reauth
  useEffect(() => {
    if (pathname.includes("setup-payment-reauth") || pathname.includes("setup-payment-returned")) {
      setPaymentSettingsModalOpen(true)
    }
  }, [pathname, setPaymentSettingsModalOpen])

  // const aModalIsOpem = settingsModalOpen || paymentSettingsModalOpen || loginModalOpen /*|| notificationsModalOpen*/

  return (
    <div className={`App ${isLessThan700px ? "mobile" : "wide-screen"}`}>
      <div ref={topOfPageRef} id="top-of-page" className="noprint" />
      {settingsModalOpen && <UserSettingsModal />}
      {paymentSettingsModalOpen && <PaymentSettingsModal pathname={pathname} query={query} history={history} />}
      {menuOpen && <Menu setMenuOpen={setMenuOpen} />}
      {loginModalOpen && (
        <LoginOrSignup
          setLoginModalOpen={setLoginModalOpen}
          submitCallback={() => setLoginModalOpen(false)}
        />
      )}
      {notificationsModalOpen && (
        <NotificationsModal
          setNotificationsModalOpen={setNotificationsModalOpen}
          setLoginModalOpen={setLoginModalOpen}
        />
      )}
      {
        changePasswordModalData.open && (
          <ChangePasswordModal />
        )
      }
      <Heading
        setMenuOpen={setMenuOpen}
        setLoginModalOpen={setLoginModalOpen}
        setNotificationsModalOpen={setNotificationsModalOpen}
        isLessThan700px={isLessThan700px}
      >
        <div className="fixed-message">
          <AppMessage className="noprint" dependants={[userObject, pathname]} />
        </div>
      </Heading>
      <div /*style={{overflowY: aModalIsOpem ? "hidden" : "scroll"}}*/>
        <div className="small-line-break noprint" />
        {isFetching && <Spinner position="fixed" />}
        <Switch>
          <Route exact path="/">
            <Home
              setErrorObj={setErrorObj}
              userObject={userObject}
              offlineMode={offlineMode}
              setLoginModalOpen={setLoginModalOpen}
              handleSetSuccessText={handleSetSuccessText}
            />
          </Route>
          <Route exact path="/account-deleted">
            <div className="medium">
              Account deleted, redirecting to home page ...
            </div>
          </Route>
          <Route exact path="/dashboard">
            <Dashboard userObject={userObject} />
          </Route>
          <Route exact path="/unsubscribe">
            <EmailPreferences query={query} history={history} />
          </Route>
          <Route exact path="/search">
            <SearchResults
              // setErrorObj={setErrorObj}
              // isFetching={isFetching}
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              setSearchResultsArray={setSearchResultsArray}
              searchResultsArray={searchResultsArray}
              searchData={searchData}
              setSearchData={setSearchData}
              isLessThan700px={isLessThan700px}
            />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/help">
            <Help setLoginModalOpen={setLoginModalOpen} />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/cookies-policy">
            <CookiesPolicy />
          </Route>          
          <Route exact path="/contest-terms">
            <ContestTerms />
          </Route>
          <Route exact path="/messages">
            <Messages />
          </Route>

          <Route 
            exact 
            path={["/marketplace", "/marketplace/:lookingFor", "/marketplace/:lookingFor/:postId"]} 
          >
            <Marketplace 
              isLessThan700px={isLessThan700px}
              lastMapCenter={lastMapCenter}
              setLastMapCenter={setLastMapCenter}
            />
          </Route>
          <Route exact path="/projects/:projectId">
            <EditProjectContextProvider>
              <SpecificProject />
            </EditProjectContextProvider>
          </Route>
          <Route
            exact
            path={["/users/:userUsername", "/users/:userUsername/:section"]}
          >
            <UserProfile setLoginModalOpen={setLoginModalOpen} />
          </Route>
          <Route exact path="*/:company/:invoiceId">
            <EditInvoiceContextProvider>
              <SpecificInvoice stripePublicKey={stripePublicKey} />
            </EditInvoiceContextProvider>
          </Route>
          <Route exact path="/pricing">
            <Pricing userObject={userObject} setLoginModalOpen={setLoginModalOpen} />
          </Route>
          <Route exact path="/free-drill-kit">
            <Promotions userObject={userObject} setLoginModalOpen={setLoginModalOpen} />
          </Route>
        </Switch>
        {
          !(pathname === "/messages" && isLessThan700px) &&
          <Footer userObject={userObject} setLoginModalOpen={setLoginModalOpen} isLessThan700px={isLessThan700px} handleSetSuccessText={handleSetSuccessText} />
        }
      </div>
    </div>
  );
};

export default App;
