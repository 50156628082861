import React, { useState, useEffect, useContext } from 'react'

import { EditInvoiceContext } from '../../contexts/EditInvoiceContext'
import { DialogContext } from "../../contexts/DialogContext";


import { getLocalISODate } from '../../utils/dateUtils'
import { getNum } from '../../utils/appUtils'
import { handleTdChange } from '../Invoice/invoiceUtils'

// rendered by Table < RenderingUtils < Invoice
// errors handled, success text not necessary
const LiveEntryRow = ({row, i, activeRow, setActiveRow, setReceiptViewModalData, tableName}) => {
	const { dialog } = useContext(DialogContext);
	const {
		isEditing, 
		editedInv, 
		// setEditedInv,
		handleSetEditedInv,
		setWorkerIsWorking, 
		liveEntryTotal, 
		setLiveEntryTotal
	} = useContext(EditInvoiceContext)
	// const [qtyVal, setQtyVal] = useState(0)
	const [pulse, setPulse] = useState(false)

	// if row is updated update then necessisary items in this live row
	// useEffect(() => {
	// 	const thisEntryInEditedInv = editedInv[tableName].find(tr => tr.id === row.id)
	// 	if (thisEntryInEditedInv) {
	// 	} else {
	// 		console.log("cant find row", row, tableName)
	// 	}
	// }, [editedInv.laborEntries])

	useEffect(() => {
		const getHoursPassed = (liveEntry) => {
			// use Milliseconds because it updates more frequently on UI
			// LIVE ENTRY VALUES ARE IN MINUTES
			const now = Date.now()
			const endTimeMS = parseInt(liveEntry.endWorkTodayAt) * 60000
			const startTimeMS = parseInt(liveEntry.startedWorkTodayAt) * 60000

			const breakTimeMS = parseInt(liveEntry.todayBreakTimeMin) * 60000
			const MSPassed = now - startTimeMS
			const MSPassedInclBreak = MSPassed - breakTimeMS

			// round to 3 decimals to update UI more frequently
			const hoursPassed = Math.round((MSPassedInclBreak / 3600000) * 1000) / 1000
			// const hoursPassed = MSPassedInclBreak / 3600000


			const MSPlanned = (endTimeMS - startTimeMS) - breakTimeMS
			const hoursPlanned = Math.round(MSPlanned / 3600000 * 10) / 10
			// const hoursPlanned = ((endTimeMS - breakTime) - startTimeMS).toFixed(1)

			if (now <= endTimeMS) { // if time is before worker leaves jobsite
				setPulse(pulse => !pulse)
				if (now < (endTimeMS - breakTimeMS)) { // if now is less than quit work time minus break time
					return hoursPassed
				} else { // if total hours have been worked for today, the remaining time is break time
					// set message text to "accounting for break time"
					return hoursPlanned
				}
			} else {
				clearInterval(seconds)
				setWorkerIsWorking(false)
				// setLiveEntryClassName("")
				return hoursPlanned
			}
		}

		const increment = () => {
			// console.log(row)

			const newVal = getHoursPassed(row)
			// const costTd = editedInv[tableName][i].values.find(obj => obj.for === "cost")
			const costTd = row.values.find(obj => obj.for === "cost")

			// setQtyVal(newVal)
			setLiveEntryTotal({
				...liveEntryTotal, 
				total: Math.round(newVal * parseFloat(costTd.val) * 100) / 100,
				qty: newVal
			})
			// td.change(newVal, editedInv, i, j)
		}

		const seconds = setInterval(() => increment(), 1000)

		return () => {
			clearInterval(seconds) 
		}
		// eslint-disable-next-line
	}, [editedInv[tableName], editedInv.startedWorkTodayAt, editedInv.endWorkTodayAt, editedInv.todayBreakTimeMin])
	// activeRow set to i which is the index of this row in the tableBody. If this table body is split there can be 2 active rows in the same table
	const receiptsThisRow = editedInv.receipts.filter(rec => rec.rowId === row.id)

	return (
		<tr className={(isEditing && activeRow.i === i && activeRow.tableName === tableName) ? "active-row" : ""} key={row.id} id={`${row.id}-live`} onClick={e => setActiveRow({tableName, i})} >
			{
				row.values.map((td, j) => {
					let inputType = td.for === "date" ? "text" : (td.for === "qty" || td.for === "cost" || td.for === "total") ? "number" : "text"
					let resultTdVal = td.val
					let className="editable-entry"
					let tdClassName = "itemized-entry"
					let minTdWidth = (resultTdVal && resultTdVal.length > 8) ? resultTdVal.length - 1 : 9
					if (minTdWidth > 25) {
						minTdWidth = 24
						resultTdVal = resultTdVal.slice(0, 22) + "..."
					}
					let onClick = null
					const isMaterialsItemEntry = tableName === "materialEntries" && (td.for === "item")
					let isLinkType
					if (td.type === "link") {
						isLinkType = true
					} else {
						if (td.type === undefined && isMaterialsItemEntry) {
							td.type = "link"
							isLinkType = true
						}
					}

					let noInput = /*isLinkType ||*/ td.for === "total" || td.for === "billTo" || td.noEdit

					if (td.for === "date") {
						minTdWidth = 12
						className = className + " date-entry"
								// if any receipts in this row have the autoUpdateDate property
						if (receiptsThisRow.find(rec => rec.autoUpdateDate)) {
							onClick = (e) => isEditing ? dialog.alert("Cannot edit value because this row has a live bill! To change the value you must first open Cell Settings and disable the 'Auto update date' checkbox") : null
							noInput = true
						}
					}

					if (td.for === "item" && minTdWidth === 9) {
						minTdWidth = 12
					}


					if ((td.for === "cost" || td.for === "qty") /*&& td.disabled*/) {
						if (receiptsThisRow.find(rec => rec.autoUpdateCosts)) {
							onClick = (e) => isEditing ? dialog.alert("Cannot edit value because this row has a live bill! To change the value you must first open Cell Settings and disable the 'Auto update cost' checkbox") : null
							noInput = true
						}
					}

					if (td.for === "today") {
						resultTdVal = getLocalISODate()
					}

					// const itemTd = row.values.find(td => td.for === "item")
					// handle open modal when adding a receipt 
					if (isLinkType && !isEditing) {
						className = className + " link-appearance blue"
						onClick = (e) => setReceiptViewModalData(receiptViewModalData => ({
							...receiptViewModalData, 
							open: true, 
							tableName, 
							rowId: row.id, 
							// tdFor: itemTd.for,
							tdFor: td.for,
							// handleTdChange
						}))
					}

					if (inputType === "number") {
						if (td.for === "total") {
							resultTdVal = getNum(liveEntryTotal.total, 2)
						} else if (td.for === "qty") {
							resultTdVal = getNum(liveEntryTotal.qty, 3)
						} else {
							resultTdVal = getNum(resultTdVal)
						}
					}

					if (td && td.visible !== false) {
						if (typeof(td) !== "string") {
							const path = tableName ? `editedInv[${tableName}]/${row.id || ""}/${td.for}` : td.for
							const inputProperties = {
								type: inputType,
								// style: {width: td.for === "date" && "fit-content"},
								value: resultTdVal,
								onChange: e => td.change ? td.change(e.target.value, td) : handleSetEditedInv({
									// currentEditedInv: editedInv,
									changes: handleTdChange({...td, val: e.target.value}, editedInv, tableName, row.id, setActiveRow),
									caller: "LiveEntryRow - td && td.visible !== false"
								}),
								className,
								disabled: td.disabled === true
							}

							if (isEditing && !noInput) {
								return (
									<td 
										key={path} 
										id={path} 
										style={{minWidth: `${minTdWidth}ch`}}
										className={`${tdClassName} ${pulse ? "live-entry" : "live-entry-fade"}`}
										onClick={onClick}>
										{
											td.for === "date" ?
												<React.Fragment>
												{
													(activeRow.i === i && activeRow.tableName === tableName) ?
															/*<input type="date" className="date-picker noprint" value={resultTdVal} onChange={(e) => setEditedInv(handleTdChange({...td, val: e.target.value}, editedInv, tableName, row.id, setActiveRow))} />*/
														<input disabled type="date" className="date-picker noprint" value={resultTdVal} onChange={
															(e) => handleSetEditedInv({
																// currentEditedInv: editedInv,
																changes: handleTdChange(
																	{...td, val: e.target.value}, 
																	editedInv, 
																	tableName, 
																	row.id,
																	setActiveRow
																),
																caller: "LiveEntryRow - isEditing && !noInput"
															})
														} />

														: <span className={className} >{resultTdVal}</span>
													}
												</React.Fragment>
												:
												<React.Fragment>
													<input 
														{...inputProperties}
														maxLength="25"
													/>
													{
														(td.for === "item" && activeRow.i === i && activeRow.tableName === tableName) &&
														<svg 
															className="noprint settings-icon" 
															// id="settings-icon" 
															onClick = {(e) => setReceiptViewModalData(receiptViewModalData => ({
																...receiptViewModalData, 
																open: true, 
																tableName, 
																rowId: row.id, 
																// tdFor: itemTd.for,
																tdFor: td.for,
																// handleTdChange
															}))}
															viewBox="0 0 32 32" 
															fill="white" 
															stroke="currentcolor" 
															strokeLinecap="round" 
															strokeLinejoin="round" 
															strokeWidth="2"
														>
													    <path d="M13 2 L13 6 11 7 8 4 4 8 7 11 6 13 2 13 2 19 6 19 7 21 4 24 8 28 11 25 13 26 13 30 19 30 19 26 21 25 24 28 28 24 25 21 26 19 30 19 30 13 26 13 25 11 28 8 24 4 21 7 19 6 19 2 Z" />
													    <circle cx="16" cy="16" r="4" />
														</svg>
													}
												</React.Fragment>

										}

									</td>
								)
							} else {
								return (
									<td style={{minWidth: `${minTdWidth}ch`}} className={`${tdClassName} ${pulse ? "live-entry" : "live-entry-fade"}`} key={path} id={path} onClick={onClick} >
										<span className={className} >{resultTdVal}</span>
										{
											(isEditing && isLinkType && activeRow.i === i && activeRow.tableName === tableName) &&
											<svg 
												className="noprint" 
												id="settings-icon" 
												onClick = {(e) => setReceiptViewModalData(receiptViewModalData => ({
													...receiptViewModalData, 
													open: true, 
													tableName, 
													rowId: row.id, 
													// tdFor: itemTd.for,
													tdFor: td.for,
													// handleTdChange
												}))}
												viewBox="0 0 32 32" 
												fill="none" 
												stroke="currentcolor" 
												strokeLinecap="round" 
												strokeLinejoin="round" 
												strokeWidth="2"
											>
										    <path d="M13 2 L13 6 11 7 8 4 4 8 7 11 6 13 2 13 2 19 6 19 7 21 4 24 8 28 11 25 13 26 13 30 19 30 19 26 21 25 24 28 28 24 25 21 26 19 30 19 30 13 26 13 25 11 28 8 24 4 21 7 19 6 19 2 Z" />
										    <circle cx="16" cy="16" r="4" />
											</svg>
										}
									</td>

								)
							}
						} else {
							return (
								<td className={tdClassName} key={td} id={td} onClick={onClick} >{td}</td>								
							)
						}
					} else return null
				})
			}
			{
				// userIsCreatorOrContractor && <div style={{border: "none"}}>+</div>
			}
		</tr>
	)
}

export default LiveEntryRow
