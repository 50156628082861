import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";

import { handleImageOnError } from "../utils/appUtils";

import { UserContext } from "../contexts/UserContext";

import Form from '../components/Forms/Form'
import { InputDiv } from '../components/Forms/Form.styled'
import LoginOrSignup from "../components/Login/LoginOrSignup"


import { AddCircleButton } from '../components/svgComponents/generalIcons'
import MoreInfoIcon from '../components/MoreInfoIcon'

import CountDownTimer from '../components/CountDownTimer'

import { 
	formulateSearchKeywords,
	industries, 
	capitalizeFirstLetter,
	// formatLocationString,
	getNum,
	getContractorKeywords
} from "../utils/appUtils";

import { findAdminLevel2, getRandomCoordinatesNearby, getAddressDetails } from '../components/Marketplace/utils'






const PromotionsStyle = styled.div `
	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
		#combo-kit {
			// height: 200px;
			max-width: 300px;
		}

		.user-info-form {
			// max-width: 400px;
			padding: 20px;
			margin: 0px auto;
		}
			input {
				font-size: inherit;
			}
			.ind-name {
				width: 110px;
			}
			.ind-rate {
				width: 50px;
			}
			.spacer-for-label {
				margin-top: 5px;
				height: 15px;
			}
			#label-removeAutoCompleteUFA::after {
				content: "Your business address";
				font-size: 12px;
				margin: 10px 0 10px 0;
			}
			.absolute-info-icon {
				position: absolute;
				top: 0;
			}
			.specialties-wrapper {
				.specialty {
					padding: 1px 7px;
					font-size: var(--font-size-s);
					color: #ada9a9;
					border-radius: 10px;
					margin: 3px 10px 3px 0;
			    box-shadow: 0px 1px 5px 0px var(--chicago);
		      display: inline-block;
					svg {
				    padding: 5px 2px 5px 7px;
				    vertical-align: middle;
				    width: 12px;
				    height: 12px;
					}
				}
				input[value], input[value] + span {
					opacity: 1;
				}
				input[value=""], input[value=""] + span {
					opacity: 0;
				}
				input:focus, input:focus + span {
					opacity: 1;
				}
				.add-item-container {
					display: flex;
					span {
				    margin-top: auto;
				    margin-bottom: auto;
					}
				}
				input {
					width: 100%;
					opacity: 0;
					font-size: var(--font-size-m);
					border-radius: 10px;
					padding: 0 5px;
					margin: 5px 0;
				}
				input + span {
					opacity: 0;
					padding: 2px 5px;
				}
			}
			.section-input {
				margin-right: 20px;
				padding: 7px 10px 0px 10px;
			}
			.remove-industry {
				position: relative;
				div {
					position: absolute;
					top: 10px;
					right: -5px;
					svg {
						width: 20px;
					}
				}
			}
	}

`

const Promotions = () => {
	const autoCompleteAddressRef = useRef(null)
	const {
		userObject,
		newUserData,
		setNewUserData,
		setErrorObj,
		handleSetSuccessText,
		isFetching,
		setIsFetching,
		updateUserData,
		userIsAnonymous,
		foundAuthState
	} = useContext(UserContext);

	const defaultFormData = {
		roles: {
			generalContractor: true,
			subContractor: true
		},
		isEnteredToWinFreeDrillKit: false,
		isCustomerOnly: false,
		// private {
		// 	address: {
		// 		addressString: "",
		// 		lat: "",
		// 		lng: "",
		// 		locationPrivacy: ""
		// 	}
		// }
	}





	// create account via email
	// tested if logged in 

	// const [newUserData, setNewUserData] = useState(defaultFormData)
	const [isEntering, setIsEntering] = useState(true)

	const [industriesFilters, setIndustriesFilters] = useState([])

	const [haveSetInitialOverrides, setHaveSetInitialOverrides] = useState(false)
	const [hasAlreadyEntered, setHasAlreadyEntered] = useState(false)


	const isSubcontractor = newUserData.roles.generalContractor
	const isGeneralContractor = newUserData.roles.subContractor

	const loginOrSignup = "signup"

	const submitCallback = () => {
		// handleSetSuccessText("Success!")
		setIsEntering(false)
	}


	const handleChange = (name, val) => {
		let newNewUserData = {...newUserData}

		if (name.includes(".")) {			
			const parentProperty = name.split(".")[0]
			const nestedProperty = name.split(".")[1]

			newNewUserData[parentProperty] = {
				...newNewUserData[parentProperty],
				[nestedProperty]: val
			}

		} else {
			newNewUserData[name] = val
		}

		if (name === "isCustomerOnly") {
			newNewUserData.roles = {
				generalContractor: false,
				subContractor: false,
			}
		}

		if (name === "roles.generalContractor" || name === "roles.subContractor") {
			if (val === true) {
				newNewUserData.isCustomerOnly = false
			}
		}

		setNewUserData(newNewUserData)

	};

	const handleIndustryEdit = (name, value, i) => {
		const newIndustries = newUserData.industries.map((ind, j) => {
			if (j === i) {
				return {
					...ind,
					[name]: value
				}
			} else return ind
		})
		setNewUserData((newUserData) => ({
			...newUserData,
			industries: newIndustries,
		}));
	};


	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			const cleanedIndustries = newUserData.industries.map(ind => {
				if (ind.name) {
					return {
						...ind,
						name: ind.name.trim(),
						rate: ind.rate ? parseFloat(getNum(ind.rate)) : ind.rate,
					}
				} else {
					return {
						...ind, 
						rate: ind.rate ? parseFloat(getNum(ind.rate)) : ind.rate, 
					}
				}
			})

			// update search keywords and other meta
			let updatedUserData = {
				...newUserData,
				industries: cleanedIndustries,
				isEnteredToWinFreeDrillKit: true,
			};

			const isGeneralContractor = updatedUserData.roles.generalContractor
			const isSubcontractor = updatedUserData.roles.subContractor
			// add in search keywords
			updatedUserData = {
				...updatedUserData,
				searchKeywords: formulateSearchKeywords({
					doc: {
						...userObject,
						...newUserData,
						contractorKeywords: getContractorKeywords(updatedUserData, isSubcontractor, isGeneralContractor),
					},
					type: "user",
				}),
				contractorKeywords: getContractorKeywords(updatedUserData, isSubcontractor, isGeneralContractor)
			}

			setNewUserData(updatedUserData)

			if (userObject.id) {
				setIsFetching(true)
				await updateUserData(updatedUserData, false, "Promotions - handleSubmit");
				setIsFetching(false)
				handleSetSuccessText("Success! you are now in the draw to win a free drill combo kit!")
				setHasAlreadyEntered(true)
				window.scrollTo(0,0)
			} else { // open login / signup
				setIsEntering(true)
			}
		} catch (err) {
			setIsFetching(false)
			setErrorObj(err)
		}

	}

	const handleUserIsLoggedIn = (newUserData) => {
		// let newFd = {}
		// if there is a user, get all appropriate fields from the user object and add them into newUserData
		// for (let key in newUserData) {
		// 	if (key === "private") {
		// 		const val = newUserData[key]
				
		// 	}
		// 	const val = newUserData[key]

		// 	if (!val) {
		// 		const valFoundInNewUserData = newUserData[key]
		// 		if (valFoundInNewUserData) {
		// 			newFd[key] = valFoundInNewUserData
		// 		}
		// 	}

		// 	if (Object.keys(newFd).length) {
		// 		setNewUserData(newUserData => ({
		// 			...newUserData,
		// 			...newFd
		// 		}))
		// 	}

		// }

		if (newUserData.isEnteredToWinFreeDrillKit) {
			setHasAlreadyEntered(true)
		} else {
			const roles = {
				...newUserData.roles
			}
			if (!roles.generalContractor && !roles.subContractor) {
				roles.subContractor = true
			}
			setNewUserData(newUserData => ({
				...newUserData,
				roles,
				isEnteredToWinFreeDrillKit: true,
				isCustomerOnly: false,
			}))
		}
	}

	const handleRemoveIndustry = (i) => {
		let newIndustries = []
		// if ((userObject.hasEarlyAccess || userObject.directlyListed)) {
		if (userObject.id && newUserData.industries) {
			newUserData.industries.forEach((ind, j) => {
				if (j !== i) {
					newIndustries.push(ind)
				}
			})

			setNewUserData((newUserData) => ({
				...newUserData,
				industries: newIndustries,
			}));
		}
	};

	const getRelevantIndustries = (userInput, industries) => {
		let newIndustriesFilter = []
		const cleanUserInput = userInput ? userInput.toLowerCase() : ""
		if (cleanUserInput) {
			const reversedIndustries = industries.reverse()
			reversedIndustries.forEach(obj => {
				const title = obj.title.toLowerCase()
				const name = obj.name.toLowerCase()
				// direct hits
				if (name.startsWith(cleanUserInput) || title.startsWith(cleanUserInput)) {
					newIndustriesFilter = [
						obj,
						...newIndustriesFilter
					]

				// indirect hits
				} else if (name.includes(cleanUserInput) || title.includes(cleanUserInput)) {
					newIndustriesFilter = [
						...newIndustriesFilter,
						obj
					]
				}
			})
		} else return []

		if (newIndustriesFilter[0] && newIndustriesFilter[0].name === cleanUserInput) {
			return []
		} else {
			return newIndustriesFilter.slice(0,6)
		}
	}

	// need to pass setNewUserData, otherwise it is outdated
	const handleChangeGeneralLocation = ({name, val, place, setNewUserData}) => {
		if (!place || !val) {
			let newNewUserData = {
				...newUserData,
				generalLocation: {lat: null, lng: null},
				private: {
					...newUserData.private,
					address: {
						...newUserData.private.address,
						lat: null,
						lng: null,
						addressString: val,
					}
				}
			}
			// return handleChange(name, val)
			return setNewUserData(newNewUserData)
		}

		// const place_id = place.place_id
		// const fullAddress = place.formatted_address
		const latlng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
		let radius = 1000 
		if (newUserData.private.address.locationPrivacy) {
			radius = parseFloat(newUserData.private.address.locationPrivacy) * 1000
		}

		const randomCoords = getRandomCoordinatesNearby({...latlng, radius})

		const { fullRegion, state, country } = getAddressDetails(place)
		let adminLevel2

		if (state && country) {
			const level2Name = findAdminLevel2([{...place}]) 
			if (level2Name) {
				adminLevel2 = level2Name + ", " + state + ", " + country
			} else { // defualt to fullRegion
				adminLevel2 = fullRegion
			}
		}

		setNewUserData(newUserData => ({
				...newUserData,
				private: {
					...newUserData.private,
					address: {
						...newUserData.private.address,
						lat: latlng.lat,
						lng: latlng.lng,
						addressString: val,
					}
				},
				generalLocation: randomCoords,
				fullCityString: fullRegion,
				adminLevel2,
			}))
	}

	const handleLocationPrivacyChange = (value) => {
		let newNewUserData = {...newUserData}
		if (newUserData.private.address.lat && newUserData.private.address.lng) {
			const radius = parseFloat(value) * 1000
			newNewUserData.generalLocation = getRandomCoordinatesNearby({lat: newUserData.private.address.lat, lng: newUserData.private.address.lng, radius})
		}
		newNewUserData.private.address = {
			...newUserData.private.address,
			locationPrivacy: parseFloat(value)
		}

		setNewUserData(newNewUserData)
	}

	useEffect(() => {
  	if (foundAuthState) {
  		setIsFetching(false)
  	} else {
  		if (!isFetching) {
  			setIsFetching(true)
  		}
  	}
	  if (foundAuthState && newUserData.id) {
	  	handleUserIsLoggedIn(newUserData)	
	  }
	  // disable because not necissary
	  // eslint-disable-next-line
	}, [userObject.id, newUserData.id, foundAuthState])

	useEffect(() => {
		if (foundAuthState && !haveSetInitialOverrides) {
			setHaveSetInitialOverrides(true)
		  setNewUserData(newUserData => ({
		  	...newUserData,
		  	...defaultFormData
		  }))
		}
		// disable because infinite
	  // eslint-disable-next-line
	}, [newUserData, foundAuthState, defaultFormData])


	let inputs = [
		// {
		// 	label: "",
		// 	properties: {
		// 		type: "hidden",
		// 	},
		// 	afterInput: [
		// 		<div key="get on marketplace">
		// 			<div className="section-title">
		// 				{
		// 					userObject.id ?
		// 					"Verify your info to enter" :
		// 					"Get on the Linvo Marketplace Map to enter the draw"
		// 				}
		// 			</div>
		// 		</div>
		// 	]
		// },
		// {
		// 	label: "I don't have an account with Linvo yet",
		// 	visible: !userObject.id,
		// 	onChange: () => handleChangeHasAccount(true),
		// 	properties: {
		// 		type: "radio",
		// 		id: "noAccount",
		// 		name: "existingAccount",
		// 		value: true,
		// 		checked: hasAccount,
		// 	},
		// },
		// {
		// 	label: "I have an account with Linvo",
		// 	visible: !userObject.id,
		// 	onChange: () => handleChangeHasAccount(false),
		// 	properties: {
		// 		type: "radio",
		// 		id: "hasAccount",
		// 		name: "existingAccount",
		// 		value: false,
		// 		checked: !hasAccount,
		// 	},
		// },
		{
			label: "",
			properties: {
				type: "hidden",
			},
			afterInput: [
				<div key="private-address">
					<div className="small-line-break" />
					<div className="small-line-break" />

					<div >
						<span id="label-removeAutoCompleteUFA" /*className="small-line-break spacer-for-label"*/ />
						{
							parseFloat(newUserData.private.address.locationPrivacy) > 0 ?
							<span>
								<MoreInfoIcon 
									customIcon=<span className="info-tag green white">Private </span>
									absolute={true}
									text={`If you dont have a business address you can keep your home address private by selecting a privacy level below. On the map, users will only be shown a random general location within ${newUserData.private.address.locationPrivacy} km of this address`}
								/>
							</span>
							: 
							<span>
								<MoreInfoIcon 
									customIcon=<span className="info-tag">Public </span>
									absolute={true}
									text={`This address can be shown on the map as your business location`}
								/>
							</span>
						}
					</div>
					{/*put a blank input to satisfy autofill off for chrome*/}
					<input type="address" autoComplete="address" style={{opacity: "0", height: "0", position: "absolute"}} />

					<div >
						<InputDiv key={newUserData} inputTypeTextFamily={true} hasVal={true}>
								<div className="input-container" >
								<Autocomplete
									key={newUserData}
								  apiKey={process.env.GOOGLE_PLACES_API_KEY}
								  onPlaceSelected={(place, target) => handleChangeGeneralLocation({name: "removeAutoCompleteUFAHidden", val: target.value, place, setNewUserData})}
								  onChange={(e) => handleChangeGeneralLocation({name: "removeAutoCompleteUFAHidden", val: e.target.value, place: null, setNewUserData})}
									type="search"
									name="removeAutoCompleteUFAHidden"
									id="removeAutoCompleteUFAHidden"
									ref={autoCompleteAddressRef}
									placeholder="Start typing"
									value={newUserData.private.address.addressString}
									required={true}
									options={{
										componentRestrictions: { country: ["ca"] },
										fields: ["geometry.location", "address_components"],
										types: ["address"],
									}}
								/>
							</div>
						</InputDiv>
					</div>
					<div className="section-divider" />
				</div>
			],
		},
		{
			label: "",
			onChange: (name, val) => handleLocationPrivacyChange(val),
			beforeInput: [
				<div key="privacy-level-label">{"Privacy level "}
					<MoreInfoIcon 
						customIcon=<span className={`info-tag ${parseFloat(newUserData.private.address.locationPrivacy) > 0.1 ? "green white" : ""}`} >{newUserData.private.address.locationPrivacy > 0 ? newUserData.private.address.locationPrivacy + " km range" : "none"}</span>
						absolute={true}
						text={`Random location coordinates within this range will be shown as your address`}
					/>
				</div>
			],
			properties: {
				type: "range",
				id: "private.address.locationPrivacy",
				name: "private.address.locationPrivacy",
				value: newUserData.private.address.locationPrivacy,
				min: 0,
				max: 2,
				step: 0.05,
			}
		},
		{
			visible: true,
			label: "General Contractor",			
			onChange: handleChange,
			// required: !isSubcontractor
			properties: {
				type: "checkbox",
				id: "roles.generalContractor",
				value: !newUserData.roles.generalContractor,
				checked: newUserData.roles.generalContractor,
			},
			beforeInput: [
				<div key="section-title-account-type">
					<div className="section-title">
						I am a
					</div>
					<div className="small-line-break" />
				</div>
			],
			moreInfoIcon: {
				before: " ",
				absolute: true,
				text: "Your company hires sub-contractors"
			}
		},
		{
			visible: true,
			label: "Sub-contractor",
			onChange: handleChange,
			properties: {
				type: "checkbox",
				id: "roles.subContractor",
				value: !newUserData.roles.subContractor,
				checked: newUserData.roles.subContractor,
				required: !newUserData.roles.generalContractor,
			},
		},
		// {
		// 	visible: isSubcontractor || isGeneralContractor,
		// 	properties: {
		// 		type: "hidden",
		// 		id: "locations-address-title",
		// 		onChange: () => null,
		// 	},
		// 	beforeInput: [
		// 		<div key="locations-address-title" className="section-title">
		// 			<div className="section-divider" />
		// 			Locations Served
		// 		</div>
		// 	],
		// },

		// {
		// 		visible: isSubcontractor || isGeneralContractor,
		// 		label: "",
		// 		properties: {
		// 			type: "hidden",
		// 			required: true,
		// 		},
		// 		afterInput: [
		// 			<React.Fragment key="city">
		// 				<InputDiv inputTypeTextFamily={true} hasVal={true}>
		// 					<div className="input-container" >
		// 						<Autocomplete
		// 						  apiKey={process.env.GOOGLE_PLACES_API_KEY}
		// 						  onPlaceSelected={(place, target) => handleChangeCity({ val: target.value, place, setNewUserData })}
		// 						  onChange={(e) => handleChangeCity({ val: e.target.value, place: null, setNewUserData })}
		// 							type="search"
		// 							id="removeAutoCompleteFCS"
		// 							ref={autoCompleteCityRef}
		// 							placeholder="Start typing your city"
		// 							value={newUserData.fullCityString}
		// 							required={true}
		// 							options={{
		// 								componentRestrictions: { country: ["ca"] },
		// 								fields: ["geometry.location", "address_components"/*, "place_id"*/],
		// 								types: ["(cities)"],
		// 							}}
		// 						/>
		// 					<span>
		// 						<label htmlFor="removeAutoCompleteFCS" >{"Main:"}
		// 						</label>
		// 						<MoreInfoIcon svgClassname="absolute-info-icon" absolute={true} text="The main city you work in" />
		// 					</span>
		// 				</div>
		// 			</InputDiv>
		// 		</React.Fragment>
		// 	],
		// },
		{
			visible: isSubcontractor || isGeneralContractor,
			properties: {
				type: "hidden",
				id: "Services",
				onChange: () => null,
			},
			beforeInput: [
				<div key="My Services">
					<div className="section-divider" />
					<div className="section-title">
						{"My Services "}
						<AddCircleButton 
							onClick={(e) => {
								e.preventDefault();
								// if (userObject.id) {
									setNewUserData((newUserData) => ({
										...newUserData,
										industries: [
											{ name: "", rate: "" },
											...newUserData.industries,
										],
									}));
								// }
							}}
						/><span className="tiny lightgray"> Add</span>
					</div>
					{!newUserData.industries.length && (
						<React.Fragment>
							<div className="small-line-break" />
						</React.Fragment>
					)}
				</div>
			]
		},
	]


	useEffect(() => {
	  if (newUserData.industries && newUserData.industries.length) {
	  	let newIndustriesFilters = []
	  	newUserData.industries.forEach(ind => {
	  		if (ind.name) {
		  		const relevantIndustries = getRelevantIndustries(ind.name, industries)
		  		newIndustriesFilters.push(relevantIndustries)
	  		}
	  	})

	  	setIndustriesFilters(newIndustriesFilters)
	  } else {
	  	setNewUserData((newUserData) => ({
				...newUserData,
				industries: [
					{ name: "", rate: "" },
				]
			}))

	  	setIndustriesFilters([])
	  }
	  // disable because would cause infinite loop but should change to only set new user data if it has changed
	  // eslint-disable-next-line
	}, [newUserData.industries])

	// handle industries inputs
	if (isSubcontractor || isGeneralContractor) {
		for (let i = 0; i < newUserData.industries.length; i++) {
			const ind = newUserData.industries[i];
			const handleAddSpecialty = (e) => {
				e && e.preventDefault()
				let newSpecialties = [] 
				let newSpecialty = ""
				const previousElementSibling = e.target.previousElementSibling

				if (previousElementSibling) {
					newSpecialty = previousElementSibling.value ? previousElementSibling.value.trim() : ""
				}
				if (!newSpecialty || (ind.specialties && ind.specialties.includes(newSpecialty))) {
					return
				} else {
					if (ind.specialties && ind.specialties.length) {
						newSpecialties = [
							...ind.specialties,
						]
					}

					newSpecialties.push(newSpecialty)

					handleIndustryEdit("specialties", newSpecialties, i)
					previousElementSibling.value = ""
				}
			}

			let newInputs = [
				{
					beforeInput: [
						<div key={`remove-industry-${i}`} className="remove-industry">
						{
							<div onClick={() => handleRemoveIndustry(i)}>
								<svg
									viewBox="0 0 32 32"
									fill="none"
									stroke="currentcolor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								>
									<path d="M28 6 L6 6 8 30 24 30 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
								</svg>
							</div>
						}
						</div>,
					],
					label: "Industry",
					onChange: (name, val) => handleIndustryEdit("name", val, i),
					containerClass: "section-input top",
					properties: {
						type: "text",
						id: `${i}-name`,
						value: ind.name,
						autoComplete: "off",
						required: ind.rate || (ind.specialties && ind.specialties.length),
					},
					afterInput: [
						<div key={i} 
							style={{
								position: "relative"
							}}
						>
							<div className="pac-container pac-logo" 
								style={{
							    display: (industriesFilters[i] && industriesFilters[i].length) ? "block" : "none",
							    width: "100%",
							    top: "0",
								}}
							>
								{
									(industriesFilters[i] && industriesFilters[i]) ?  industriesFilters[i].map((obj) => {
										return (
											<div key={obj.name} className="pac-item" onClick={() => handleIndustryEdit("name", capitalizeFirstLetter(obj.name), i)} >
												<span className="material-icons pac-icon pac-icon-marker industries" >{obj.icon}</span>
												<span className="pac-item-query" >{capitalizeFirstLetter(obj.name)}</span>
												<span>{capitalizeFirstLetter(obj.title)}</span>
											</div>
										)
									})
									: ""
								}
							</div>
						</div>
					]
				},
				{
					label: "Rate",
					onChange: (name, val) => handleIndustryEdit("rate", val, i),
					containerClass: "section-input middle",
					properties: {
						type: "number",
						id: `${i}-rate`,
						value: ind.rate,
					},
					afterInput: [
						<div key="specialties" className="section-input bottom specialties-wrapper">
							<div className="small-line-break" />
							<div>Specialties</div>
							{
								ind.specialties ? ind.specialties.map(sp => {
									return (
										<span key={sp} className="specialty">{sp}
											<svg
												onClick={() => {
													handleIndustryEdit("specialties", ind.specialties.filter(s => s !== sp), i)
												}}
												viewBox="0 0 32 32" 
												fill="none" stroke="currentcolor" strokeLinecap="round" 
												strokeLinejoin="round" strokeWidth="3"
											>
										    <path d="M2 30 L30 2 M30 30 L2 2" />
									    </svg>
										</span>
									)
								})
								: ""
							}
							{
								(ind.specialties && ind.specialties.length) ?
								<div className="small-line-break" />
								: ""
							}
							<label htmlFor={`${i}-specialties`}>
								<AddCircleButton 
									height="10px" width="10px"
								/>
								{" "}
							</label>
							<div className="add-item-container">
								<input 
									type="text" 
									id={`${i}-specialties`}
									placeholder="eg: hardwood flooring"
									autoComplete="off"
									onChange={e => e.target.setAttribute("value", e.target.value)}
									// cant use onKeypress because it submits the form and preventDefault looses e.target
									onKeyPress={(e) => {
											if (e.key === "Enter") {
												handleAddSpecialty(e)
											}
										}
									}
								/>
								<span 
									className="link-appearance tiny"
									onClick={handleAddSpecialty} 
								>Add</span>
							</div>
							<div className="small-line-break" />
						</div>
					]
				},
			];

			inputs.push(...newInputs);
		}
	}



  return (
  	<PromotionsStyle>
      <div className="main-container">
      	<h2>
      		{hasAlreadyEntered ? 
      		"You're entered to " : ""
      		}
	      	Win a FREE Milwaukee drill combo kit!
      	</h2>
	      <img id="combo-kit" src="/assets/combo-kit.jpg" alt="drill combo kit" onError={handleImageOnError} />
	      <div className="section-divider" />
	      <div className="section-divider" />
	      <h3>Contest ends in</h3>
	      <CountDownTimer expiry="April 31, 2024" />
	      <div className="section-divider" />
	      <p>Winner will be announced on the <strong className="blue"><a className="no-link" href="https://www.instagram.com/_linvo" target="_blank" rel="noopener noreferrer">@_linvo</a></strong> instagram on March 31, 2024</p>
	      {/*<p className="tiny">*Terms and conditions apply. <Link to="/contest-terms">See contest rules</Link></p>*/}
	      <div className="section-divider" />
	      <div className="section-divider" />
	      {
	      	hasAlreadyEntered ? 
	      	<div>
		      	<h3>Thank you for entering to win!</h3>
	      	</div>
	      	: 
		      <div className="user-info-form">
			      <h3 className="section-title">
			      	{/*Sign up to win*/}
			      </h3>
			      {
			      	((!userObject.id || userIsAnonymous) && isEntering) ? 
			      	<LoginOrSignup 
				      	// loginOrSignup={loginOrSignup}
				      	// loginHeading={loginHeading}
				      	// signupHeading={signupHeading}
			      		defaultLoginOrSignup={loginOrSignup}
				      	defaultSignupHeadingText="Sign up to win"
				      	defaultLoginHeadingText="Sign in to win"
				      	submitName="Continue"
				      	submitCallback={submitCallback}
				      	prefillData={newUserData}
				      	// exitModalCallback={exitModalCallback}
				      	noModal={true}
			      	/>
			      	:
			      	<React.Fragment>
				      	<Form 
					      	inputs={inputs} 
					      	heading={<h2>{(userObject.industries && userObject.industries.length) ? "Verify Your Business Info" : "Your Business Info"}</h2>}
					      	onSubmit={(e) => handleSubmit(e)}
					      	submitName="Enter to win"
				      	/>
				      	<p className="tiny">* By submitting this form you are agreeing to the <Link to="/contest-terms">Contest rules</Link></p>
			      	</React.Fragment>
			      }
	      	</div>
	      }
      </div>
    </PromotionsStyle>
  )
}

export default Promotions