import styled from 'styled-components'


export const DocumentListStyle = styled.div `
	position: relative;
  text-align: left;
	.wrapper::-webkit-scrollbar {
		height: 8px;
	}
	.wrapper::-webkit-scrollbar-track {
    background: transparent;
	}
	.wrapper::-webkit-scrollbar-thumb {
	  background: gray;
	}
	.wrapper::-webkit-scrollbar-thumb:hover {
	  background: #555;
	}
	.empty-list {
		margin: 0;
		padding: 0;
		height: 105px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		li {
			list-style-type: none;
			display: inline-block;
			margin: 0 20px;
			width: 100px;
			height: 100px;
			background-color: #ebebeb;
			border-radius: 50%;
		}
	}
	.nav-arrows {
		cursor: pointer;
    height: 75%;
    position: absolute;
    top: 0;
    /* bottom: 0; */
    background-color: #808080d9;
    display: flex;
    margin: 6px 0;
    flex-direction: column;
    justify-content: center;
    padding: 0px 3px;
    box-shadow: 0 0 4px 1px #808080;
    z-index: 2;
	}
	.next {
    right: 0;
	}
	.prev {
    left: 0;
	}
	.hide {
		visibility: hidden;
	}
	.wrapper.overflow-clip {
	  overflow: clip;
	}
	.wrapper {
		overflow-x: scroll;
		/*margin-bottom: 30px;*/
		padding-bottom: 15px;
    min-height: 130px;
	  scroll-behavior: smooth;

		> ul {
	    width: max-content;
	    padding: 0 50px 0 0;
	    margin: 0;
	    display: flex;
	    position: relative;
	    a {
	    	text-decoration: none;
		    color: var(--chicago);
	    }
	    .link-appearance {
	    	text-decoration: underline;
	    }
	    .blue {
		    color: var(--brand-blue);	
	    }
		  li.invoice svg {
			  box-shadow: 0px 5px 11px -3px  green;
			}

			li.bill svg {
			  box-shadow: 0px 5px 11px -3px blue;
			}

			li.project svg {
			  box-shadow: 0px 5px 11px -3px brown;
			}

			> li.recent-copy > a {
	      outline: gray;
		    outline-offset: 3px;
		    outline-width: 2px;
		    outline-style: dashed;
			}

			li.loading-within > a {
			  > .spinner > div {
			  	position: absolute;
			  }
			}
			
			li.loading-within {
				.spinner-container {
					height: 100%;
					.spinner {
						  display: flex;
						  align-items: center;
						  // height: 100%;
						}
					}
				}

			> li {
			  display: flex;
			  padding: 3px 4px;
			  margin: 3px 6px;
			  position: relative;
	      align-items: center;
	      width: 110px;
	      // overflow: hidden;

	      .info-banner {
	      	color: var(--white-text);
	      	font-size: var(--font-size-xxs);
    	    padding: 0 16px 0 17px;
			    margin: 10px 0px 0px -15px;
	      	position: absolute;
	      	top: 0;
	      	left: 0;
	      	transform: rotate(-45deg);
	      	background: var(--brand-green);
    	    box-shadow: 0px 3px 8px -3px black;
	      }

	      input.select-document {
	      	position: absolute;
	      	top: 0;
	      	z-index: 2;
	      	+ label {
	      		width: 100%;
	      		height: 100%;
				    position: absolute;
	      	}
		      + label + a {
		      	border: none;
		      	pointer-events: none;
		      }
	      }

	      input.select-document:checked {
	      	+ label + a {
    		    box-shadow: 0 0 4px 3px green;
	      	}
	      }

				> .symbol-container {
		      display: inline-block;
					font-size: 12px;
					text-align: center;
	        padding: 5px;
	        width: 100%;
					p {
				    overflow: hidden;
				    text-overflow: ellipsis;
				    white-space: nowrap;
				    width: 100%;
				    margin: 5px 2px 0 2px;
				    background-color: transparent;

					}
					.spinner ~ * {
					  opacity: 0.2;
					}
				}
			}
		}

	}

`