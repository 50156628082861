import React, { useContext, useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'

import { UserContext } from '../../contexts/UserContext'
import { MessagingContext } from '../../contexts/MessagingContext'

import SearchForm from './SearchForm'
import Spinner from '../Spinner/Spinner'

import { SearchPageStyle } from './SearchPageStyle.styled'
import { handleSearch } from './utils'
import SearchResult from './SearchResult'

import firebase from "../../firebase/index";

// rendered by App.js
const SearchResults = ({ isSearching, setIsSearching, setSearchResultsArray, searchResultsArray, searchData, setSearchData, isLessThan700px}) => {

	const {
		userObject,
		setErrorObj,
		isFetching,
		isAdmin,
		usersInvoices,
		usersProjects,
		getUsersInvoices,
		getUsersProjects,
	} = useContext(UserContext)

	const {
		usersThreads
	} = useContext(MessagingContext)

	const [canSearchAgain, setCanSearchAgain] = useState(true)

	useEffect(() => {
	  if (!usersInvoices && userObject.id) {
	  	getUsersInvoices({userId: userObject.id, caller: "SearchResults.js: useEffect"})
	  }
		// eslint-disable-next-line
	}, [usersInvoices, userObject.id])

	useEffect(() => {
	  if (!usersProjects && userObject.id) {
	  	getUsersProjects({userId: userObject.id, caller: "SearchResults.js: useEffect"})
	  }
		// eslint-disable-next-line
	}, [usersProjects, userObject.id])

	const checkLocationsMatch = (a, b) => {
		if (a === b) {
			return true
		}
		if (!a || !b) {
			return false
		}
		if (typeof(a) !== "string" || typeof(b) !== "string") {
			return false
		}
		let cleanedLocationA = a.toLowerCase().replace(/,+\s+/g, "-")
		let cleanedLocationB = b.toLowerCase().replace(/,+\s+/g, "-")
		// replace all non A-Z  except"-"
		cleanedLocationA = cleanedLocationA.replace(/[^a-zA-Z-]/g, "")
		cleanedLocationB = cleanedLocationB.replace(/[^a-zA-Z-]/g, "")
		return cleanedLocationA === cleanedLocationB
	}

	const changeLocation = (location) => {
		setCanSearchAgain(true)
		setSearchData(searchData => ({
			...searchData,
			location
		}))

		let filteredResults = []
		// if we dont need to search again and can just filter
		if (searchResultsArray && searchResultsArray.length && location && !searchData.location) {
			searchResultsArray.forEach(res => {
				let newRes = {
					...res,
					visibleInSearchResults: false
				}
				if (res.fullCityString) {	
					const locationsMatch = checkLocationsMatch(res.fullCityString, location)
					if (locationsMatch) {
						newRes=  {
							...res, 
							visibleInSearchResults: true
						}
					}

				}

				filteredResults.push(newRes)

			})

			setSearchResultsArray(filteredResults)
		} else {
			let overrideCanSearch = true
			let canSearchAgain = true
			if (searchData.location === location) {
				overrideCanSearch = false
				canSearchAgain = false
			}
			handleSearch({
				currentUser: firebase.auth().currentUser,
				formData: {...searchData, location},
				usersInvoices: [],
				usersProjects: [],
				setIsSearching,
				isFetching,
				canSearchAgain,
				setCanSearchAgain,
				setSearchResultsArray,
				setErrorObj,
				overrideCanSearch,
			})
			// const newUrlParams = new URLSearchParams(`location=${location}&category=${searchData.category}&text=${searchData.text}`)
			// window.location.search = newUrlParams
		}


	}

	const handleFiltersChange = (name, checked, exclusive) => {
		setCanSearchAgain(true)
		let newFormData = {
			...searchData,
			searchFilters: {
				...searchData.searchFilters,
				[name]: {
					...searchData.searchFilters[name],
					checked
				}
			}
		}

		setSearchData(newFormData)
		let appliedFilters = []

		for (let key in newFormData.searchFilters) {
			const filterObj = newFormData.searchFilters[key]
			if (filterObj.visible && filterObj.checked) {
				appliedFilters.push(key)
			}
		}

		let newResults = []

		if (searchResultsArray && searchResultsArray.length) {
			searchResultsArray.forEach(res => {
				const filtersInAppliedFilters = res.filterNames.some(name => appliedFilters.includes(name))
				let passesLocationFilter = false
				const locationsMatch = checkLocationsMatch(res.fullCityString, newFormData.location)
				if (locationsMatch || !newFormData.location) {
					passesLocationFilter = true
				}
				if (filtersInAppliedFilters && passesLocationFilter) {
					newResults.push({
						...res, 
						visibleInSearchResults: true
					})
				} else {
					if (exclusive && passesLocationFilter) {
						newResults.push({
							...res,
							visibleInSearchResults: !checked,
						})
					} else {
						newResults.push({
							...res,
							visibleInSearchResults: false
						})
					}
				}
			})

			setSearchResultsArray(newResults)
			
		}

	}


	// COMPONENTS
	let resFilters = []

	for (let key in searchData.searchFilters) {
		const obj = searchData.searchFilters[key]
		if (obj.visible) {
			resFilters.push(
				<React.Fragment key={key}>
					<input 
						id={key} 
						type="checkbox" 
						checked={obj.checked}
						value={!obj.checked} 
						onChange={(e) => handleFiltersChange(e.target.id, e.target.value === "true" ? true : false, obj.exclusive)} 
					/>
					<label htmlFor={key} >
						{obj.name + " "}
					</label>
				</React.Fragment>
			)
		}
	}

	let resultsHeading = 
	<h2 className="heading">Results</h2>

	if (searchData.mainSearchCategory === "documents") {
		resultsHeading = 
		<h2 className="heading">Documents</h2>
	}

	if (searchData.mainSearchCategory === "contractors" || searchData.mainSearchCategory === "users") {
		let headingText = searchData.mainSearchCategory === "contractors" ? "Contractors" : "Customers"

		resultsHeading = 
			<div>
				<h2 className="heading">{headingText}</h2>
				<div className="location">
					<span className="location-name" >
						<span className="pac-icon pac-icon-marker"></span>
						{
							searchData.location ? 
							searchData.location : "Anywhere"
						}
						{
							searchData.location && (
								<svg
									onClick={() => {
										changeLocation("")
									}}
									className="clear-location"
									viewBox="0 0 32 32"
									fill="none"
									stroke="currentcolor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="3"
								>
									<path d="M2 30 L30 2 M30 30 L2 2" />
								</svg>
							)}
					</span>
				</div>
				<div className="small-line-break" />
			</div>	
	}

	return (
		<SearchPageStyle isSearching={isSearching} isLessThan700px={isLessThan700px} >
			<div className="main-container" >
				<h1>Search</h1>
				<SearchForm 
	        setErrorObj={setErrorObj} 
	        isFetching={isFetching}
	        isSearching={isSearching} 
	        setIsSearching={setIsSearching} 
	        setSearchResultsArray={setSearchResultsArray} 
	        searchData={searchData} 
	        setSearchData={setSearchData} 
	        searchResultsArray={searchResultsArray}
	        canSearchAgain={canSearchAgain}
	        setCanSearchAgain={setCanSearchAgain}
	      />
				<div className="section-divider" />
	      <div>
	      	{
	      		searchResultsArray &&
						resultsHeading
	      	}

					{
						(resFilters && resFilters.length) ?
						<React.Fragment>
							<h4 className="align-left" >{"Filters"}</h4>
							<div className="filters">
								{resFilters}
							</div>
						</React.Fragment>
						: null
					}
					<div className="section-divider" />
					<div className="section-divider" />
					{
						//(searchData.mainSearchCategory === "contractors" && (!userObject || !(userObject.hasEarlyAccess || userObject.directlyListed))) ? 
						//<React.Fragment>
						//	<div><em>Only <Link to="/request-early-access">Early Access</Link> or Directly Listed Contractors can be listed here</em></div>
						//	<div className="section-divider" />
						//	<div className="section-divider" />
						//</React.Fragment>
						//: ""
					}

					<div style={{position: "relative"}} >
						{
							isSearching && <Spinner position="absolute" />
						}
					</div>
	        {
	          searchResultsArray && searchResultsArray.length ? searchResultsArray.map(doc => <SearchResult isAdmin={isAdmin} key={doc.id} doc={doc} userObject={userObject} searchResultsArray={searchResultsArray} searchData={searchData} usersThreads={usersThreads} changeLocation={changeLocation} />)
	          : (!isSearching && searchResultsArray) && "no results"
	        }
	      </div>
      </div>
	  </SearchPageStyle>
	)
}

export default SearchResults
