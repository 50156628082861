import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const WriteMessageInputStyle = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	padding: 10px 5px 15px 5px;

	.input-container {
		margin: 0px 10px 0px 0px;
		width: 100%;
    border: 1px solid var(--chicago);
    border-radius: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
    	margin: 2px 10px;
    }
		textarea {
			-webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	    padding: 5px 5px 5px 10px;
	    margin-left: 5px;
	    border: none;
	    vertical-align: middle;
			text-align: left;
			background-color: transparent;
			// width: -webkit-fill-available;
	    border-radius: 20px;
	    width: inherit;
			resize: vertical;
		}
	}
`;

const WriteMessageInput = ({ isLessThan700px, handleSendMessage, currentThreadData, setCurrentThreadData, threadId }) => {
	const [text, setText] = useState("")
	const [rows, setRows] = useState(1)
	const textInputRef = useRef(null)

	useEffect(() => {
		// change the textarea size based on amount of line breaks 
		// not cant detect natural line breaks easoly
	  if (text) {
	    // look for any "\n" occurences
	    let matches = text.match(/[\r\n]/g)
	    let breaks = matches ? matches.length + 1 : 1;
	    if (!matches) {
	    	if (text.length > 30) {
	    		breaks += Math.round(text.length / 30)
	    	}
	    }
	    if (breaks > 4) {
		    setRows(4)
	    } else { 
	    	setRows(breaks)
	    }
	  } else {
	  	setRows(1)
	  }
	}, [text])

	const handleChange = (e) => {
		const val = e.target.value;
		setText(val)
	};

	const handleSelectInput = () => {
		textInputRef.current.focus()
	}

	// if user has selected a thread auto focus
	useEffect(() => {
	  if (threadId && textInputRef && textInputRef.current && !isLessThan700px) {
	  	handleSelectInput()
	  }
	}, [threadId, textInputRef, isLessThan700px])

	return (
		<WriteMessageInputStyle>
			{/*<div className="attach-files">Files...</div>*/}
			<div className="input-container">
				<textarea 
					// type="text"
					ref={textInputRef}
					placeholder="Send Message"
					onChange={handleChange}
					value={text}
					rows={rows}
				/>
				<button disabled={!text} className="button-appearance" onClick={() => {
					setText("")
					handleSendMessage(text)
				}}>Send</button>
			</div>
		</WriteMessageInputStyle>
	);
};

export default WriteMessageInput;
