import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import Autocomplete from "react-google-autocomplete";

import { industries, capitalizeFirstLetter } from "../../utils/appUtils";



const MapControlsStyle = styled.div `
  	position: absolute;
  	// padding: 2px 10px;
  	padding: 0;
  	margin: 0;
  	z-index: 2;
  	top: 0;
  	left: 0;
  	cursor: pointer;
	  .loading-markers {
	  	position: absolute;
	  	z-index: 2;
	  	top: 0;
	  	left: 0;
	  	color: white;
	  	background-color: var(--text-2);
	  	font-size: var(--font-size-s);
	  	padding: 2px 10px;
	  	margin: 0;
	  	width: max-content;
	  	margin-bottom: 5px;
	  }
  	.filters {
	  	vertical-align: middle;
	  	margin-top: 5px;
		  display: flex;
		  align-items: center;
	    flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 5px;
      margin-left: 5px;

	  	.select-gray {
	  		background-color: var(--off-white);
	  	}	
	  	.add-or-subtract {
  		  padding: 6px;
			  border-radius: 50%;
	  	}
	  	.industry-tag {
		  	font-size: var(--font-size-s);
		  	padding: 2px 7px;
		  	.material-icons {
			  	font-size: var(--font-size-m);
			  	// height: 100%;
				  // display: inline-flex;
				  // flex-direction: column;
				  // align-items: center;
				  margin-top: auto;
		  	}
	  	}

  		> span, .location-input, .industry-input {
		  	background-color: white;
		  	padding: 2px 10px;
		  	border-radius: 15px;
		  	font-size: var(--font-size-m);
		  	// font-size: 18px;
		  	vertical-align: middle;
		  	// margin-left: 10px;
		  	> svg {
		  		height: var(--font-size-xs);
			  	vertical-align: middle;
			  	padding: 0;
			  	margin-left: 5px;
		  	}
		  	span {
			  	vertical-align: inherit;
		  	}
		  	.material-icons {
	  		  // font-size: var(--font-size-l);
	  		  vertical-align: middle;
	  		  // margin: 0;
		  	}
  		}

  		.location-input, .industry-input {
	  		position: relative;
		  	padding: 0;
		  	input {
	  		  max-width: 17ch;
		  		outline: none;
		  		border: none;
		  		margin: 0;
		  		// background: transparent;
		  		background-color: white;
		  		border-radius: 15px;
		  		// padding: 2px 25px 2px 25px;
	  			padding: 2px 25px 2px 10px;
		  	}
		  	.material-icons {
	  		  // margin: 0 5px;
	  		  height: 100%;
	  		  top: 0;
	  		  position: absolute;
		  		padding: 0px 3px;
				  display: flex;
				  align-items: center;
				  background-color: inherit;
				  border-radius: 50%;
		  	}
		  	.right-action {
		  		right: 0;
		  	}
		  	.location-icon {
	  		  left: 0;
		  	}
	  	}

	  	.industry-input-container {
	  		padding: 0;
	  	}

	  	.location-input {
	  		input {
		  		padding-left: 32px;
	  		}
	  	}

  	}
`

const MapControls = ({
	contractors,
	searchingContractors,
	searchingJobs,
	showFilters, 
	setShowFilters,
	isLoadingMarkers,
	handleChange,
	map,
	jobs,
	formData,
	industriesFilter,
	filters, 
	setFilters,
	handleChangeCity, 
	setMapCenter,

	infoWindow,
	setSelectedMarker,
	selectedMarker
}) => {

	const searchTextInput = useRef(null);
	const autoCompleteLocationRef = useRef(null)

	let searchTextInputClientRect;
	if (searchTextInput.current) {
		searchTextInputClientRect = searchTextInput.current.getBoundingClientRect();
	}

	const [isAddingFilter, setIsAddingFilter] = useState(false)
  let resFilters = []

	const addOrRemoveIndustry = (action, name) => {
		if (!name) {
			return
		}

		const indName = name.toLowerCase()
		let industryInIndustries = industries.find(ind => ind.name === indName)
		let newfilters

		if (action === "add") {
			if (!industryInIndustries) {

				industryInIndustries = {
					name: indName,
					title: "",
					icon: "\uea3c",
				}
			}

			handleChange("text", "")
			// if not already listed 
			if (!filters.find(ind => ind.name === industryInIndustries.name)) {
				newfilters = [industryInIndustries, ...filters]
			}
		} else {
			newfilters = filters.filter(ind => ind.name !== indName)
		}
		if (newfilters) {
			setFilters(newfilters)

			// const newFilteredJobs = filterByIndustry(newfilters, jobs)
			// setFilteredJobs(newFilteredJobs)

			// const newFilteredContractors = filterContractorsByIndustry(newfilters, contractors)
			// setFilteredContractors(newFilteredContractors)

		} else {
			console.log("NO FILTERS.... SHOULD THIS RUN?")
		}

		if (!showFilters) {
			setShowFilters(true)
		}
	}


  filters.forEach(ind => {
  	resFilters.push(
  		<span className="industry-tag" key={ind.name}>
				<span className="material-icons" >{ind.icon}</span>
				<span>{" "}
	  		{capitalizeFirstLetter(ind.name)} 
	  		</span>
				<span 
					className="material-icons right-action" 
					onClick={(e) => {
						addOrRemoveIndustry("remove", ind.name)
					}} 
				>{"\ue14c"}</span>
			</span>
  	)
  }) 


  return (
		<MapControlsStyle>
		{
			isLoadingMarkers ? 
	  		<p className="loading-markers">{searchingJobs ? "loading jobs..." : "finding contractors..." }</p>
	  		: ""
		}
  		<div className="filters">
  			<span className={showFilters ? "select-gray" : ""} 
    			onClick={() => {
    				setShowFilters(showFilters => !showFilters)
						if (!filters.length) {
							setIsAddingFilter(true)
						}
    			}}
  			>
					<span className="material-icons" >{"\ue429"}</span>
					{" "}
	    		<span>{(showFilters && filters.length) ? "Hide" : "Filters"}</span>
    		</span>
				<span onClick={() => {
					setIsAddingFilter(!isAddingFilter)	
				}} 
					className="material-icons add-or-subtract" >{ isAddingFilter ? "\ue15b" : "\ue145"}
				</span>
				{
					isAddingFilter ? 
					<span className="industry-input-container" >
						<label>
							<div
								style={{
									position: "relative",
									display: "inline-flex",
									flexDirection: "column",
								}}
							>
								<div className="industry-input">
									<input
										className="pac-target-input"
										// required={true}
										type="text"
										placeholder="Industry"
										name="text"
										value={formData.text}
										autoComplete="off"
										onChange={(e) => handleChange(e.target.name, e.target.value)}
										ref={searchTextInput}
									/>
									<span 
										className="material-icons right-action" 
										onClick={(e) => {
											addOrRemoveIndustry("add", formData.text)
										}} 
									>{"\ue147"}</span>
								</div>
								{industriesFilter.length ? (
									<div
										className="pac-container pac-logo"
										style={{
											width: searchTextInputClientRect
												? searchTextInputClientRect.width
												: "inherit",
											position: "absolute",
											// left: "120px",
											top: searchTextInputClientRect
												? searchTextInputClientRect.height
												: "33px",
											display: formData.text ? "block" : "none",
										}}
									>
										{industriesFilter.map((obj) => {
											return (
												<div
													key={obj.name}
													className="pac-item"
													onClick={() => {
														handleChange("text", capitalizeFirstLetter(obj.name))
														addOrRemoveIndustry("add", obj.name)
													}}
												>
													<span style={{marginTop: "auto"}} className="material-icons pac-icon pac-icon-marker industries" >{obj.icon}</span>
													<span className="pac-item-query">
														{capitalizeFirstLetter(obj.name)}
													</span>
													<span>{capitalizeFirstLetter(obj.title)}</span>
												</div>
											);
										})}
									</div>
								) : null}
							</div>
						</label>
					</span>
					: ""
				}

    		{
    			showFilters ? 
    			<React.Fragment>
    				{resFilters}
    			</React.Fragment>
    			: ""
    		}
    	</div>

  		<div className="filters">
  			<span className="location-input">
					<span className="material-icons location-icon" >{"\ue0c8"}</span>

	  			<Autocomplete
						key={map ? map.mapId : "map"} // must include key in order for a new map
					  apiKey={process.env.GOOGLE_PLACES_API_KEY}
					  onPlaceSelected={(place, target) => handleChangeCity({val: target.value, place, handleChange, setMapCenter})}
					  onChange={(e) => handleChangeCity({val: e.target.value, place: null, handleChange, setMapCenter})}
						type="text"
						name="city"
						id="city"
						ref={autoCompleteLocationRef}
						placeholder="City/Region"
						value={formData.city}
						options={{
							componentRestrictions: { country: ["ca"] },
							fields: ["geometry.location", "address_components"/*, "place_id"*/],
							types: ["(cities)"],
						}}
						onFocus={() => {
			    		if (infoWindow.current) {
				    		infoWindow.current.close()
			    		}

			    		if (selectedMarker) {
				    		setSelectedMarker(null)
			    		}
						}}
					/>
					<span 
						className="material-icons right-action" 
						onClick={(e) => {
					    handleChange("city", {
					    	city: "", 
					    })
						  // autoCompleteLocationRef is not on page for the AutoComplete input
						  e.target.parentElement.querySelector("input").focus()
					  }} 
					>{"\ue14c"}</span>
  			</span>
  		</div>
		</MapControlsStyle> 
  )
}

export default MapControls