import firebase from './index'
import { formulateSearchKeywords, getNewProfileFilePath, getContractorKeywords } from '../utils/appUtils'
// import { subCollectionsList } from '../components/Invoice/invoiceUtils'
// import { getDefaultNotificationSubscriptions } from '../utils/notificationUtils'

export const extractProviderData = async (user) => {
	let firstname = ""
	let lastname = "" 
	let logoPath = "" 
	let logoUrl = ""
	let phone = ""

	const googleProvider = user.providerData ? user.providerData.find(obj => obj.providerId === "google.com") : null 
	const facebookProvider = user.providerData ? user.providerData.find(obj => obj.providerId === "facebook.com") : null
	const passwordProvider = user.providerData ? user.providerData.find(obj => obj.providerId === "password")  : null

	if (googleProvider) {
	  let nameArray = googleProvider.displayName ? googleProvider.displayName.split(" ") : []
	  firstname = firstname ? firstname : nameArray[0]
	  lastname = lastname ? lastname : (nameArray.length > 1 ? nameArray[nameArray.length - 1] : "")

	  // handle logoUrl 
	  if (!logoUrl && googleProvider.photoURL) {
	  	// upload the image and get the new path. we will have to get the doenloadURL later since cant do an async pipi in nodejs and file.metadata.mediaUrl is not getDownloadUrl
			const path = await getNewProfileFilePath(googleProvider.photoURL)
			logoPath = path || ""
	  }
	  // dont add the logoUrl from providerData because it can have errors when downloading
	  // logoUrl = logoUrl ? logoUrl : googleProvider.photoURL || ""
	  logoUrl = logoUrl || ""
		phone = phone ? phone : googleProvider.phoneNumber || ""
	}
	if (facebookProvider) {
		let nameArray = facebookProvider.displayName ? facebookProvider.displayName.split(" ") : []
		firstname = firstname ? firstname : nameArray[0]
	  lastname = lastname ? lastname : (nameArray.length > 1 ? nameArray[nameArray.length - 1] : "")

	  // handle logoUrl 
	  if (!logoUrl && facebookProvider.photoURL) {
	  	// upload the image and get the new path. we will have to get the doenloadURL later since cant do an async pipi in nodejs and file.metadata.mediaUrl is not getDownloadUrl
			const path = await getNewProfileFilePath(facebookProvider.photoURL)
			logoPath = path || ""
	  }
	  // dont add the logoUrl from providerData because it can have errors when downloading
	  // logoUrl = logoUrl ? logoUrl : facebookProvider.photoURL || ""
	  logoUrl = logoUrl || ""
		phone = phone ? phone : facebookProvider.phoneNumber || ""
	}
	if (passwordProvider) {
	  let nameArray = passwordProvider.displayName ? passwordProvider.displayName.split(" ") : []
	  firstname = firstname ? firstname : nameArray[0]
	  lastname = lastname ? lastname : (nameArray.length > 1 ? nameArray[nameArray.length - 1] : "")

	  // handle logoUrl 
	  if (!logoUrl && passwordProvider.photoURL) {
	  	// upload the image and get the new path. we will have to get the doenloadURL later since cant do an async pipi in nodejs and file.metadata.mediaUrl is not getDownloadUrl
			const path = await getNewProfileFilePath(passwordProvider.photoURL)
			logoPath = path || ""
	  }
	  // dont add the logoUrl from providerData because it can have errors when downloading
	  // logoUrl = logoUrl ? logoUrl : passwordProvider.photoURL || ""
	  logoUrl = logoUrl || ""
		phone = phone ? phone : passwordProvider.phoneNumber || ""
	}

	return {firstname, lastname, logoPath, logoUrl, phone}
}

// Handle sign ups or when user data cannot be found. This is the only place that adds the user data 
// to firestore upon sign up whether sign up with google, facebook or email
export const addNewUserToDB = async (maybeUser, defaultUserData, dialog) => {
	let user = maybeUser
	// handle if user is logged in anonymously and then signs up
	if (!user.uid) {
		user = user.multiFactor && user.multiFactor.user
	}
	if (!user || !user.uid) {
		console.log({maybeUser})
		throw new Error("Cannot find current user. Please contact support!")
	}

	let newUserData = {...defaultUserData}

	let signupFormData = sessionStorage.getItem('signupFormData')

	if (signupFormData) {
		signupFormData = JSON.parse(signupFormData)
		newUserData = {
			...newUserData,
			...signupFormData,
			// email: user.email
		}
	}

	// de structure after signupFormData set in newUserData
	let {
		email,
		firstname,
		lastname,
		logoUrl,
		phone,
		username
	} = newUserData

	if (!firstname) {
		if (newUserData.name && newUserData.name.split) {
			let nameArray = newUserData.name.split(" ")
			firstname = nameArray[0]
			if (nameArray.length > 1) {
				lastname = nameArray[nameArray.length -1]
			} else {
				lastname = ""
			}
		} else {
			firstname = ""
			lastname = ""
		}
	}

	let logoPath = ""
	
	// username = user.displayName
	email = email || user.email
	if (!email) {
		// await user.updateEmail(user.providerData[0].email)
		if (user.providerData && user.providerData[0]) {
			if (!user.providerData[0].email) {
		    email = await dialog.prompt('Please enter your email address')
			} else {
				email = user.providerData[0].email
			}
			user.updateEmail(user.providerData[0].email)
		} else {
	    email = await dialog.prompt('Please enter your email address')
		}
	}

	if (!username) {
		// could be a sign up with google or facebook
		// when sign up with email or change username we dont allow usernames that contain "@"
		// so adding an @ to the end of username when facebook or google prevents duplicate usernames
		const generatedUsername = email.replace(/@.*/, "") + "@"
		username = generatedUsername
	}

	const providerData = await extractProviderData(user)

	firstname = firstname || providerData.firstname || ""
	lastname = lastname || providerData.lastname || ""
	logoPath = logoPath || providerData.logoPath || ""
	logoUrl = logoUrl || providerData.logoUrl || ""
	phone = phone || providerData.phone || ""

	// prevent cardUserInfo from being an empty object or undefined
	let newCardUserInfo = {
		rows: (newUserData.cardUserInfo && newUserData.cardUserInfo.rows) ? newUserData.cardUserInfo.rows : 2,
		val: (newUserData.cardUserInfo && newUserData.cardUserInfo.val && newUserData.cardUserInfo.val.length) ? newUserData.cardUserInfo.val : [`@${username}`, email],
	}

	let newCompanyName = newUserData.companyName
	// replace company name if sample
	if (!newCompanyName) {
		newCompanyName = username.replace("@", "")
	}

  newUserData = {
  	...newUserData,
  	cardUserInfo: newCardUserInfo,
  	companyName: newCompanyName,
  	companyEmail: newUserData.companyEmail || email,
  	firstname: firstname, 
  	lastname: lastname,
  	name: newUserData.name || (firstname + " " + lastname).trim(),
  	username,
  	email,
  	phone, 
  	logoUrl,
  	logoPath: logoPath,
  	searchable: newUserData.searchable || true,
		id: user.uid,
		accountCreated: user.metadata ? (user.metadata.creationTime || new Date().toGMTString()) :  new Date().toGMTString(),
		isAnonymous: user.isAnonymous,
  }

  let privateData = {}

  if (newUserData.private) {
  	privateData = {...newUserData.private}
  	// console.log("newUserData.private true", {privateData} )
  	delete newUserData.private

	  if (privateData.address) {
	  	// remove all falsy items. Cloud function sets defaults
	  	for (let key in privateData.address) {
	  		if (privateData.address[key] === "" || privateData.address[key] === undefined) {
	  			delete privateData.address[key]
	  		}
	  	}
	  }

  }

  const isGeneralContractor = newUserData.roles && newUserData.roles.generalContractor
  const isSubcontractor = newUserData.roles && newUserData.roles.subContractor

  if (isGeneralContractor || isSubcontractor) {
  	newUserData.isCustomerOnly = false
		newUserData.contractorKeywords = getContractorKeywords(newUserData, isSubcontractor, isGeneralContractor)
  }

  newUserData = {
  	...newUserData,
  	searchKeywords: [
	  	...formulateSearchKeywords({
				doc: newUserData,
				type: "user"
	  	}),
  		username,
  	],
  }

  // delete unused
  // todo: doesnt seem to be working
  delete newUserData.uid
  if (!newUserData.hasEarlyAccess) {
  	delete newUserData.hasEarlyAccess
  }

  if (newUserData.address && !Object.keys(newUserData.address).length) {
  	delete newUserData.address
  }

  
  // console.log("creating user with id: " + newUserData.id)
  // must await otherwise insuf permissions error because doc not exist?
	await firebase.firestore().collection("users").doc(newUserData.id).set(newUserData, { merge: true }).then(() => {
		sessionStorage.removeItem('signupFormData')
	})

	// set privateData, if error, we dont need to do anything we just lost the users address
	for (let key in privateData) {
		firebase.firestore().collection("users").doc(newUserData.id).collection("private").doc(key).set(privateData[key], { merge: true }).catch(err => {
			console.log("error setting private", JSON.stringify(err))
		})
	}

	// loginWithFacebook or google sets the display name to the given names
	if (!user.displayName) {
		await user.updateProfile({displayName: newUserData.name || newUserData.username}).catch(err => {
			console.error(err)
		})
	}

	// add defaults and private back in
  return {
  	...defaultUserData,
  	...newUserData,
  	private: {
  		...defaultUserData.private,
  		...privateData, // wont have the cloud function onAccountCreated updates but they are only overwrite defaults if empty value
  	}
  }
}

export const addAuthStateChangedListener = (handleAuthStateChanged) => {
	const newListener = firebase.auth().onAuthStateChanged(user => {
		handleAuthStateChanged(user)
	})
	return newListener
}

// App.js fires login status and sets user object into context
export const loginStatus = async (handleAuthStateChanged, dialog) => {
	// userObject is always null at refresh so no point in using if  !userObject
	try {
		// add auth state changed listener
		addAuthStateChangedListener(handleAuthStateChanged)
		const currentUser = firebase.auth().currentUser
		if (!currentUser) {
			const isSignInWithEmailLink = await firebase.auth().isSignInWithEmailLink(window.location.href)
			if (isSignInWithEmailLink) {
				// return and once signed in loginStatus should fire again
				return runSignInWithEmailLink(dialog)
			}
		}
	} catch (err) {
		throw err
	}
}

// sign up with email
export const signUpWithEmail = async (cleanedFormData, password, dialog) => {
	const user = firebase.auth().currentUser
	sessionStorage.setItem('providerId', 'password')

	// signUpWithEmail creates the user which invokes authStateChanged before the users data can be added to firestore
	try {
		// onAuthStateChanged doesnt fire from this linkWithCredential so we must update the new info to Db
		if (user && user.isAnonymous) {
			// upgrade the account
			const credential = firebase.auth.EmailAuthProvider.credential(cleanedFormData.email, password);
			const userCred = await user.linkWithCredential(credential)

			let resDisplayName = cleanedFormData.name 
			if (!resDisplayName) {
				resDisplayName = (cleanedFormData.firstname + " " + cleanedFormData.lastname).trim()
			}
			// update the user  display name so that email verification is correct
			await user.updateProfile({displayName: resDisplayName || cleanedFormData.username}).catch(err => {
				console.error(err)
			})

			// add the new info to Db
			// update the user fully as if they didnt have an anon account but add in current data as defaultData
			await addNewUserToDB({...user, displayName: resDisplayName || cleanedFormData.username}, cleanedFormData, dialog)

			await user.sendEmailVerification();

			return userCred
		} else {
			const result = await firebase.auth().createUserWithEmailAndPassword(cleanedFormData.email, password)

			await result.user.sendEmailVerification();

			return result
		}
	} catch (err) {
		throw err
	}
}

//log in
export const logUserIn = ({email, password}/*, submitCallback, setErrorObj*/) => {
	return firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
		sessionStorage.setItem('providerId', 'password')
		sessionStorage.setItem('newLogin', 'true')
		return {
			res: firebase.auth().currentUser.getIdToken(true),
			uid: firebase.auth().currentUser.uid
		}
	})
}

export const logInAnonymously = () => {
	return firebase.auth().signInAnonymously().then((result) => {
		sessionStorage.setItem('providerId', 'anonymous')
		sessionStorage.setItem('newLogin', 'true')
		return {
			res: firebase.auth().currentUser.getIdToken(true),
			uid: firebase.auth().currentUser.uid
		}
	})
}

export const logInWithEmailLink = (email) => {
	const actionCodeSettings = {
	  // URL you want to redirect back to. The domain (www.example.com) for this
	  // URL must be whitelisted in the Firebase Console.
	  url: window.location.href,
	  // This must be true.
	  handleCodeInApp: true,
	  // iOS: {
	  //   bundleId: 'com.example.ios'
	  // },
	  // android: {
	  //   packageName: 'com.example.android',
	  //   installApp: true,
	  //   minimumVersion: '12'
	  // },
	  // dynamicLinkDomain: 'example.page.link'
	}
	if (!email) {
		// eslint-disable-next-line
		throw {message: "Email cannot be blank", noReport: true}
		// setErrorObj({message: "Email cannot be blank"})
	}
	return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
		localStorage.setItem('emailForSignIn', email)
		return true
	})
}

export const runSignInWithEmailLink = async (dialog) => {
	let email;
	let uid;
	let recoverAccountSig;
  	// check if email was passed in url
	if (window.location.search) {
		let query = new URLSearchParams(window.location.search);
		let continueUrl = query.get('continueUrl')
		if (continueUrl) {
			continueUrl = new URL(continueUrl)
			query = new URLSearchParams(continueUrl.search);
		}

		query.forEach((val, name) => {
	    if (name === "emailForSignIn") {
	    	email = val;
	    }
	    if (name === "uid") {
	    	uid = val;
	    }
	    if (name === "recoverAccountSig") {
	    	recoverAccountSig = val
	    }
		})
	};

  if (!email) {
		email = window.localStorage.getItem('emailForSignIn');
  }

	if (!email) {
    email = await dialog.prompt('Please provide your email for confirmation');
  }

  if (recoverAccountSig && uid) {
		const recoverAccount = firebase.functions().httpsCallable("recoverAccount")
	  await recoverAccount({email, uid, recoverAccountSig}).then(({ data }) => {
			if (data.success) {
				dialog.alert("Your account has been reactivated. Please make a new password")
			} else {
				dialog.alert("Sorry, we can't recover your account data at the moment! Please contact support")
			}
			return 
		})
		.catch((err) => {
			dialog.alert("Sorry, we can't recover your account data at the moment due to a server error! Please contact support. Error: " + err.message)
			return
		});
  }

  await firebase.auth().signInWithEmailLink(email, window.location.href).then(result => {
    // Clear email from storage.
    window.localStorage.removeItem('emailForSignIn');
    window.history.replaceState({}, "", "/dashboard");
    // You can access the new user via result.user
    // Additional user info profile not available via:
    // result.additionalUserInfo.profile == null
  }).catch(err => {
  	// dont do anything with error
  	console.error(err)
    window.history.replaceState({}, "", window.location.pathname + "?action=promptLogin");
    return 
  })

	return {
		isLoginWithEmailLink: true,
		email,
	}

}

//log out
export const logUserOut = () => {
	localStorage.setItem('linvoPendingOps', '')
	sessionStorage.removeItem('providerId')
	window.location.reload()
	return firebase.auth().signOut()
}

export const getUserContactsGoogle = async (token, user) => {
	// This gives you a Google Access Token.
	// update the user in firestore
  const response = await fetch('https://people.googleapis.com/v1/people/me/connections?personFields=names,photos,emailAddresses,addresses,phoneNumbers', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).catch(err => {
  	console.log(err)
  	throw err
  })
  const data = await response.json();
  return data.connections || []
}

export const addPermissions = async (scopes, providerName) => {
	let provider
	firebase.auth().useDeviceLanguage()
	if (providerName === "google.com") {
		provider = new firebase.auth.GoogleAuthProvider()
	}
	if (providerName === "facebook.com") {
		provider = new firebase.auth.FacebookAuthProvider()	
	}

	scopes.forEach(scope => {
		provider.addScope(scope)
	})

	provider.setCustomParameters({
	  // 'signInSuccessUri': `${window.location.origin}/dashboard`,
	  'tosUrl': `${window.location.origin}/terms-and-conditions`,/* 'https://www.termsfeed.com/live/c8d92435-ddf0-4b42-9b06-c9ba36798da0',*/
	  'privacyPolicyUrl': `${window.location.origin}/privacy-policy`, /*'https://www.termsfeed.com/live/cbe69072-d5af-4e63-9171-8eb07b46f490'*/
	})

	return firebase.auth().signInWithPopup(provider) // should return result
}

// sign in with google 
export const signInWithGoogle = async (submitCallback) => {
	const user = firebase.auth().currentUser
	firebase.auth().useDeviceLanguage()
	var provider = new firebase.auth.GoogleAuthProvider()
	provider.addScope('email')
	provider.addScope('profile')
	provider.setCustomParameters({
	  // 'signInSuccessUri': `${window.location.origin}/dashboard`,
	  'tosUrl': `${window.location.origin}/terms-and-conditions`,/* 'https://www.termsfeed.com/live/c8d92435-ddf0-4b42-9b06-c9ba36798da0',*/
	  'privacyPolicyUrl': `${window.location.origin}/privacy-policy`, /*'https://www.termsfeed.com/live/cbe69072-d5af-4e63-9171-8eb07b46f490'*/
	})
	if (submitCallback) {
		if (typeof(submitCallback) === "string") {
		// add the submit callback to pending operations if there is no current pending operations
			let pendingOps = localStorage.getItem('linvoPendingOps') 
			if (pendingOps) {
				const opsArray = JSON.parse(pendingOps)
				if (!opsArray.includes(submitCallback)) {
					const newPendingOpsArr = [...JSON.parse(pendingOps), submitCallback]
					localStorage.setItem('linvoPendingOps', JSON.stringify(newPendingOpsArr))
				}
			} else {
				localStorage.setItem('linvoPendingOps', JSON.stringify([submitCallback]))
			}
		}
	}
	sessionStorage.setItem('providerId', 'google.com')
	// pendingRedirect is for evaluating pending operations after login see App.js useEffect
	sessionStorage.setItem('pendingRedirect', 'true')

	if (user && user.isAnonymous) {
		// sign in
		return user.linkWithRedirect(provider)
	}

	// return firebase.auth().signInWithPopup(provider)
	if (window.location.pathname.includes("/signInWithPopup")) {
		return firebase.auth().signInWithPopup(provider).catch(err => {
			console.log(JSON.stringify(err))
		})
	} else {
		return firebase.auth().signInWithRedirect(provider).catch(err => {
			console.log(JSON.stringify(err))
		})
	}
}

export const signInWithFacebook = async (submitCallback) => {
	const user = firebase.auth().currentUser
	firebase.auth().useDeviceLanguage()
	var provider = new firebase.auth.FacebookAuthProvider();

	provider.addScope('public_profile')
	provider.addScope('email')
	
	if (submitCallback) {
		if (typeof(submitCallback) === "string") {
		// add the submit callback to pending operations if there is no current pending operations
			let pendingOps = localStorage.getItem('linvoPendingOps') 
			if (pendingOps) {
				const opsArray = JSON.parse(pendingOps)
				if (!opsArray.includes(submitCallback)) {
					const newPendingOpsArr = [...JSON.parse(pendingOps), submitCallback]
					localStorage.setItem('linvoPendingOps', JSON.stringify(newPendingOpsArr))
				}
			} else {
				localStorage.setItem('linvoPendingOps', JSON.stringify([submitCallback]))
			}
		}
	}
	sessionStorage.setItem('providerId', 'facebook.com')
	// pendingRedirect is for evaluating pending operations after login see App.js useEffect
	sessionStorage.setItem('pendingRedirect', 'true')

	if (user && user.isAnonymous) {
		// upgrade the account
		return user.linkWithRedirect(provider)
	}

	if (window.location.pathname.includes("/signInWithPopup")) {
		return firebase.auth().signInWithPopup(provider).catch(err => {
			console.log(JSON.stringify(err))
		})
	} else {
		return firebase.auth().signInWithRedirect(provider).catch(err => {
			console.log(JSON.stringify(err))
		})
	}
}

export const handleDeleteAccount = async (e, offlineMode, bypassPrompts, accountOnly, dialog) => {
	// remove user from accessors of invoices they are listed in, and delete as much data as possible
	// user wornt be cleaned up in the pubsub function since main firebase doc will be deleted
	e && e.preventDefault()
	let confirmDelete
	if (bypassPrompts) {
		confirmDelete = true
	} else {
		await dialog.alert("Deleting your account may not remove all documents of which you are owner. Users that have made verified payments to your invoices might still have access to them unless you delete these documents before you delete your account")
		confirmDelete = await dialog.confirm("Are you sure you want to delete your account?")
	}
	const user = firebase.auth().currentUser

	if (confirmDelete) {
		if (offlineMode) {
			return dialog.alert("Cannot delete account while offline. Check your internet connection")
		}
		try {
			// todo: set loginModalOpen instead and let user choose login, then delete
			// if user has only providerData password, prompt password
			if (user.providerData && (user.providerData.find(obj => obj.providerId === "password") && user.providerData.length === 1)) {
				let promptPassword = await dialog.prompt("Please enter your password", "", {type: "password"})
				if (!promptPassword) {
					return false
				}
				const cred = firebase.auth.EmailAuthProvider.credential(user.email, promptPassword)
				await user.reauthenticateWithCredential(cred)
			}

			if (!accountOnly) {
				const batch = firebase.firestore().batch()
				const batchUpdateValues = {
				 	followers: firebase.firestore.FieldValue.arrayRemove(user.uid),
					accessors: firebase.firestore.FieldValue.arrayRemove(user.uid),
					editors: firebase.firestore.FieldValue.arrayRemove(user.uid)
				}

				// const getSubColEntryRefs = async (mainCol, doc) => {
				// 	try {
				// 		let newEntrtyRefs = []
				// 		for (let j=0; j< subCollectionsList.length; j++) {
				// 			let col = subCollectionsList[j]
				// 			const entries = firebase.firestore().collection(mainCol).doc(doc.id).collection(col)
				// 			await entries.get().then(snapshot => {
				// 				// if snapshot.exists... 
				// 				if (!snapshot.empty) {
				// 					return snapshot.docs.forEach(ent => {

				// 						if (col === "receipts") {
				// 							// delete the file from storage 
				// 							const doc = ent.data()
				// 							if (doc.fileType !== "bill" && doc.fileType !== "url") {
				// 								firebase.storage().ref(doc.storagePath || `${user.uid}/receipts/${ent.id}`).delete()
				// 								.catch(err => {
				// 									throw err
				// 								})
				// 							}
				// 							// delete instead with cloud function ? see post https://medium.com/google-developer-experts/automatically-delete-your-firebase-storage-files-from-firestore-with-cloud-functions-for-firebase-36542c39ba0d
				// 						}
				// 						newEntrtyRefs.push(ent.ref)
				// 					})
				// 				}
				// 			}).catch(err => {
				// 				console.log(err)
				// 			})
				// 		}
				// 		return Promise.all(newEntrtyRefs)

				// 	} catch (err) {
				// 		throw err
				// 	}
				// }

				const invoicesSnapshot = await firebase.firestore().collection("invoices").where("accessors", "array-contains", user.uid).get()
				for (let i=0; i< invoicesSnapshot.docs.length; i++) {
					let doc = invoicesSnapshot.docs[i]
					if (doc.data().owner === user.uid) {
						// dont do anything, this data will be deleted in the purgeDeletedAccountData cloud function

						// // if no one has accepted this doc as their bill or billtoself
						// if (!doc.data().billTo.uid || doc.data().billTo.uid === user.uid) {
						// 	// delete doc subCollections
						// 	const entryRefsToDelete = await getSubColEntryRefs("invoices", doc)
						// 	// delete actual doc
						// 	batch.delete(doc.ref) 
						// 	entryRefsToDelete.forEach(ref => batch.delete(ref))

						// } else {
						// 	batch.update(doc.ref, {
						// 		...batchUpdateValues, 
						// 		owner: doc.data().billTo.uid,
						// 		closed: true
						// 	})
						// }
					} else {
						const docData = doc.data()
						// the user is already not an editor and if we try to remove them when theyre not there this may result in permissions error
						if (!docData.editors || !docData.editors.includes(user.uid)) {
							delete batchUpdateValues.editors
						}
						if (!docData.followers || !docData.followers.includes(user.uid)) {
							delete batchUpdateValues.followers
						}

						batch.update(doc.ref, batchUpdateValues)
					}
				}

				const projectsSnapshot = await firebase.firestore().collection("projects").where("accessors", "array-contains", user.uid).get()
				for (let i=0; i<projectsSnapshot.docs.length; i++) {
					const doc = projectsSnapshot.docs[i]
					if (doc.data().owner === user.uid) {
						// dont do anything, this data will be deleted in the purgeDeletedAccountData cloud function

						// // if no one has accepted this doc as their bill or billtoself
						// if (!doc.data().billTo.uid || doc.data().billTo.uid === user.uid) {
						// 	const projectsEditorsWithoutOwner = doc.data().editors.filter(id => id !== user.uid)
						// 	let confirmDelete = true
						// 	if (projectsEditorsWithoutOwner.length) {
						// 		confirmDelete = await dialog.confirm(`${doc.data().projectName}'s editors will loose access because it this project will not exist. Delete anyway?`)
						// 	}
						// 	if (confirmDelete) {
						// 		batch.delete(doc.ref)
						// 	} else {
						// 		batch.update(doc.ref, {
						// 			...batchUpdateValues,
						// 			owner: doc.data().billTo.uid,
						// 			closed: true
						// 		})
						// 	}
						// } else {
						// 	batch.update(doc.ref, batchUpdateValues)

						// }
					} else {
						batch.update(doc.ref, batchUpdateValues)		
					}
				}
			
				// update user status to deleted. This will bypass the warning email
				await firebase.firestore().collection("users").doc(user.uid).update({
					//recoverAccountSig, // include if we want to send a reminder account data deleted email
					status: {
						deleted: true, // set to false if we want to send reminder account data deleted email
						errors: [],
					}
				})

				await batch.commit()
			}

			await firebase.auth().currentUser.delete()

			localStorage.removeItem('linvoPendingOps')
			sessionStorage.removeItem('providerId')
			sessionStorage.removeItem('signupFormData')

			window.location.pathname = '/account-deleted'
		} catch (err) {

			if (err.code === "auth/requires-recent-login") {
		    if (user.providerData.find(obj => obj.providerId === "google.com")) {
					return dialog.alert("Deleting account requires re-authentication, log in with google to proceed").then(() => signInWithGoogle("deleteAccount"))
				} else if (user.providerData.find(obj => obj.providerId === "facebook.com")) {
					return dialog.alert("Deleting account requires re-authentication, log in with facebook to proceed").then(() => signInWithFacebook("deleteAccount")) 	
				} else {
					err.message =  "No such login provider, please contact support"
					err.code = "Provider-not-found delete-account AND auth/requires-recent-login"
				}
			}
			throw err
		}
	} else return
}