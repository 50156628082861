import React, { useRef, useState, useEffect } from 'react'
import styled from "styled-components";
// import { AppMessageContextProvider } from './contexts/AppMessageContext'

import { getTimeRemaining } from '../utils/dateUtils'


const CountDownTimerStyle = styled.div `
	color: var(--chicago);
	> div {
		display: inline-flex;
		flex-direction: column;
		font-size: var(--font-size-xl);
    box-shadow: 0px 0px 18px -8px;
    padding: 0 20px;
		> p {
			margin: 0;
			font-size: var(--font-size-s);
		}
	}
`

const CountDownTimer = (props) => {
	const [timeRemaining, setTimeRemaining] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	})

	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true
		// const nowTime = Date.now()
		const expiry = new Date(props.expiry).valueOf()
	  const times = getTimeRemaining(expiry)
	  setTimeRemaining({...times})

  	const seconds = setInterval(() => {
			const expiry = new Date(props.expiry).valueOf()
		  const times = getTimeRemaining(expiry)
		  if (isMountedRef.current) {
			  setTimeRemaining({...times})
		  }
		}, 1000);

	  return () => {
	  	isMountedRef.current = false
			clearInterval(seconds) 
	  }
	}, [props.expiry])


  return (
    <CountDownTimerStyle {...props}>
			<div><span>{timeRemaining.days}</span><p>Days</p></div>
			<div><span>{timeRemaining.hours}</span><p>Hours</p></div>
			<div><span>{timeRemaining.minutes}</span><p>Minutes</p></div>
			<div><span>{timeRemaining.seconds}</span><p>Seconds</p></div>
    </CountDownTimerStyle>
  )
}

export default CountDownTimer