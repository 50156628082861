import React, {
	useRef,
	useState,
	useLayoutEffect,
	useEffect,
	useContext,
} from "react";

import { EditInvoiceContext } from "../../contexts/EditInvoiceContext";

import InvoiceControls from "../Invoice/InvoiceControls";
import StartWorkModal from "../StartWorkModal";
// import AcceptBillModal from "./AcceptBillModal";
import { RenderPages } from "./renderingUtils";
import ChangeLayoutModal from "./ChangeLayoutModal/ChangeLayoutModal";
import ReceiptViewModal from './/ReceiptViewModal/ReceiptViewModal'

import { InvoiceStyle } from "./InvoiceStyle.styled";

import { pageConstants } from "./pageConstants";
import { getInvoiceTotals } from "./invoiceUtils";
import {
	getUserObjs,
	getFollowersUserDataWithType,
} from "../../utils/followUtils";
import { getLocalISODate } from '../../utils/dateUtils'



// rendered by: SpecificInvoice
// errors handled success text not necessary
const Invoice = ({
	currentUser,
	userObject,
	invoiceId,
}) => {
	const pagesContainerRef = useRef(null);
	const {
		editedInv,
		handleSetEditedInv,
		isEditing,
		userIsBillTo,
		userCanEdit,
		setFollowersUserObjs,
		liveEntryTotal,
		workerIsWorking,
		invoiceTotals,
		setInvoiceTotals,
		setAddPaymentModal,
		setErrorObj,
		receiptViewModalData,
		setReceiptViewModalData,
	} = useContext(EditInvoiceContext);

	const {
		letterPaperHeightWidthRatio,
		widthToPaddingTopBottomRatio,
		widthToPaddingLeftRightRatio,
		printablePaperWidthPx, // 816
		pxToPt,
	} = pageConstants;

	const paperVW = 0.93;
	const invoicePageMargin = "10px auto";
	const initialScreenPageWidthPx = window.outerWidth >= 700 ? 700 * paperVW : window.outerWidth * paperVW;

	const [startWorkModalIsOpen, setStartWorkModalIsOpen] = useState({
		open: false,
		action: "start",
	});
	const [layoutModalOpen, setLayoutModalOpen] = useState(false);
	const [paperDetails, setPaperDetails] = useState({
		margin: invoicePageMargin,
		screenPageWidthPx: printablePaperWidthPx,
		screenPageHeightPx: printablePaperWidthPx * letterPaperHeightWidthRatio,
		paddingTopBottom: printablePaperWidthPx / widthToPaddingTopBottomRatio, // one inch
		paddingLeftRight: printablePaperWidthPx / widthToPaddingLeftRightRatio, // one inch
		printablePaperWidthToScreenWidthRatio: 1,
		rowHeight: 35 / pxToPt,
	});

	const invoiceTopSectionRef = useRef(null);

	window.onresize = (e) => {
		if (
			e.target.outerWidth >= 700 &&
			paperDetails.screenPageWidthPx / paperVW >= 700
		) {
			return false;
		}
		setNewPageWidth(e.target.outerWidth * paperVW);
	};

	const setNewPageWidth = (newWidth) => {
		setPaperDetails((paperDetails) => ({
			...paperDetails,
			printablePaperWidthToScreenWidthRatio: printablePaperWidthPx / newWidth,
		}));
	};

	useLayoutEffect(() => {
		if (invoiceTopSectionRef.current) {
			const printablePaperWidthToScreenWidthRatio =
				printablePaperWidthPx / initialScreenPageWidthPx;

			const topSectionHeight =
				invoiceTopSectionRef.current.getBoundingClientRect().height; /** 1.05*/ // add space to combat styling manipulations by safari
			const invContentHeightPx =
				paperDetails.screenPageHeightPx - paperDetails.paddingTopBottom * 2;
			// const headings for materials and labor height plus padding for subtraction
			// and carry over the heading for labor if the height of the materials entries expands into the next page
			// these are all calculated before the scale ratio changes the heigts
			// these are good > console.log(invContentHeightPx / printablePaperWidthToScreenWidthRatio, topSectionHeight / printablePaperWidthToScreenWidthRatio)
			const entriesPermittedFirstPage = Math.floor(
				(invContentHeightPx - topSectionHeight) / paperDetails.rowHeight
			);
			const entriesPermittedOnOnePage = Math.floor(
				invContentHeightPx / paperDetails.rowHeight
			);

			setPaperDetails((paperDetails) => ({
				...paperDetails,
				entriesPermittedOnOnePage,
				entriesPermittedFirstPage,
				printablePaperWidthToScreenWidthRatio,
				topSectionHeight,
			}));
		} else {
			setErrorObj({
				message: "error on layout, no invoice top section component reference",
			});
		}
		// eslint-disable-next-line
	}, [invoiceTopSectionRef]);

	const handlePrint = () => {
		setPaperDetails((paperDetails) => ({
			...paperDetails,
			margin: 0,
			printablePaperWidthToScreenWidthRatio: 1,
			printAction: true,
		}));
	};
	
	// get updated user objects of followers, editors etc
	useEffect(() => {
		(async () => {
			// needs to be in specificInvoice or Invoice because AcceptBill button and cha nge bill To needs follower user objects
			// maybe put in a try catch block instead
			// get the raw user objects from db
			let resEditorData = []
			let resFollowerData = []
			try {
				resEditorData = await getUserObjs(editedInv.editors)
				resFollowerData = await getUserObjs(
					[...editedInv.followers || []],
					resEditorData
				)

			} catch (err) {
				setErrorObj(err)
			}
			// combine editors and followers in case editor is not follower
			const followersAndEditors = [...resFollowerData, ...resEditorData];
			// make follower data have type and only have username and id in the follower obj
			const extractedFollowerData = getFollowersUserDataWithType(
				followersAndEditors,
				resEditorData,
				{ owner: editedInv.owner }
			);
			setFollowersUserObjs(extractedFollowerData);
		})();
	}, [userIsBillTo, editedInv, currentUser, setErrorObj, setFollowersUserObjs]);

	useEffect(() => {
		const newInvoiceTotals = getInvoiceTotals({
			editedInv,
			workerIsWorking,
			liveEntryTotal,
			invoiceTotals,
		});
		setInvoiceTotals((invoiceTotals) => {
			if (
				JSON.stringify(invoiceTotals) ===
				JSON.stringify({ ...invoiceTotals, ...newInvoiceTotals })
			) {
				return invoiceTotals;
			} else {
				return { ...invoiceTotals, ...newInvoiceTotals };
			}
		});
	}, [
		setInvoiceTotals,
		invoiceTotals,
		editedInv,
		workerIsWorking,
		liveEntryTotal,
		setErrorObj,
		setFollowersUserObjs,
	]);

	// show a cell highlight any time the contents change ...
	// not using for now because it adds a lot of complexity although it seems to work
	// to try, uncomment background color and transition items in invoiceStyle.styled
	// and add a data property to all table cells you want to highlight on update that dont already have a value attribute (eg: spans in td in table.js) so an attriibute change is fired
	// const setHighlightChangeTimers = useCallback((element) => {
	// 	let timer
	// 	if (!element.classList.contains("just-changed-color")) {
	//     element.classList.add("just-changed-color")

	//     timer = setTimeout(() => {
	//     	// console.log("timed out")
	//     	if (element.classList.contains("just-changed-color")) {
	// 		    element.classList.remove("just-changed-color")
	// 	      // mutation.target.parentElement.parentElement.style.backgroundColor = "unset"

	//     	}
	//     }, 500)
	// 	}

	// 	return () => {
	// 		console.log("useEffect on unmount setHighlightChangeTimers")
	// 		if (timer) {
	// 			// console.log(timer)
	// 			clearTimeout(timer)
	// 		}
	// 		if (element.classList.contains("just-changed-color")) {
	// 	    element.classList.remove("just-changed-color")
	// 		}
	// 	}
	// }, [])

	// useEffect(() => {
	// 	// highlight changes when undo / redo button clicked
	// 	let observer
	// 	let didAddObserver = false
	// 	// let timers = []

	// 	if (editedInv.version && pagesContainerRef.current) {
	// 		const invoiceNode = pagesContainerRef.current.lastElementChild
	// 		// console.log("adding observer")

	// 		observer = new MutationObserver(mutations => {
	// 			// console.log("a mutation ...", mutations)
	// 	    mutations.forEach(mutation => {
	//         // if (mutation.target.parentElement.tagName === "TD") {
	// 	        // console.log(mutation) // <- It always detects changes
	// 	        // if (mutation.target.) {}
	// 	        // console.log(editedInv.version, editedInvVersions)
	//         if (!mutation.target || !mutation.target.classList) {
	//         	console.log("'ERROR no mutation.target", mutation)
	//         } else {
	//         	// console.log(mutation)
	// 	        const includesItemizedEntry = mutation.target.parentElement.classList.contains("itemized-entry")
	// 	        const includesTh = mutation.target.parentElement.tagName === "TH"
	// 	        // const isTextArea = mutation.target.tagName === "TEXTAREA"
	// 	        // const isSectionHeading = mutation.target.classList.contains("section-heading")
	// 	        const isSectionHeadingInput = (mutation.target.parentElement && mutation.target.parentElement.classList.contains("section-heading"))

	// 	        // console.log("mutated", mutation)
	// 						// console.log(includesTh, mutation)
	// 	        if ((includesItemizedEntry || includesTh || /*isSectionHeading ||*/ isSectionHeadingInput) && !mutation.target.parentElement.classList.contains("just-changed-color")) {
	// 		        // mutation.target.parentElement.parentElement.classList.add("just-changed-color")
	// 		        const currentVersionIndex = editedInvVersions.findIndex(v => v.current)
	// 		        // // console.log(mutation, currentVersionIndex, editedInvVersions.length)
	// 		        // if (currentVersionIndex !== editedInvVersions.length - 1) {
	// 		        // 	if (/*isSectionHeading || */isSectionHeadingInput) {
	// 		        // 		// console.log(mutation.target.parentElement)
	// 		        // 	}
	// 			        setHighlightChangeTimers(mutation.target.parentElement)
	// 		        // }
	// 		        // timers.push(setTimeout(() => {
	// 		        // 	console.log("timed out")
	// 			       //  mutation.target.parentElement.parentElement.classList.remove("just-changed-color")
	// 			       //  // mutation.target.parentElement.parentElement.style.backgroundColor = "unset"
	// 		        // }, 500))

	// 	        }

	//         }
	// 	    })
	// 		})

	// 		var config = {attributes: true, /*characterData: true,*/ subtree: true}
	// 		observer.observe(invoiceNode, config)
	// 		didAddObserver = true
	// 	}

	// 	return () => {
	// 		if (didAddObserver) {
	// 			observer.disconnect()
	// 			// console.log(timers)
	// 			// if (timers.length) {
	// 			// 	timers.forEach(timer => {
	// 			// 		clearTimeout(timer)
	// 			// 	})
	// 			// }
	// 		}
	// 	}
	// }, [editedInv.version, pagesContainerRef, editedInvVersions])

	// const aModalIsOpen = startWorkModalIsOpen.open || layoutModalOpen

	return (
		<React.Fragment>
			{startWorkModalIsOpen.open && (
				<StartWorkModal
					userObject={userObject}
					startWorkModalIsOpen={startWorkModalIsOpen}
					setStartWorkModalIsOpen={setStartWorkModalIsOpen}
					invoiceId={invoiceId}
				/>
			)}
			{layoutModalOpen && (
				<ChangeLayoutModal setLayoutModalOpen={setLayoutModalOpen} />
			)}
			{
				receiptViewModalData.open && 
				<ReceiptViewModal setReceiptViewModalData={setReceiptViewModalData} receiptViewModalData={receiptViewModalData} />
			}
			<InvoiceStyle
				// onDoubleClick={handleDblClickEdit}
				// className="printable"
				screenPageWidthPx={paperDetails.screenPageWidthPx}
				scaleRatio={paperDetails.printablePaperWidthToScreenWidthRatio}
				rowHeight={paperDetails.rowHeight}
			>
				<div className="pages-container printable" ref={pagesContainerRef}>
					<div>
						<InvoiceControls
							className="noprint"
							userObject={userObject}
							invoiceId={invoiceId}
							handlePrint={handlePrint}
							paperDetails={paperDetails}
							userCanEdit={userCanEdit}
							setLayoutModalOpen={setLayoutModalOpen}
						/>
						{
							editedInv.hideStatus ?
							""
							:
							<div className="invoice-status noprint">
								<span>
									<svg id="invoice-status-indicator" width="1.7ch" height="1.7ch" viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
								    <circle cx="16" cy="16" r="14" fill={editedInv.closed ? "gray" : "green"} />
									</svg>
								</span>
								{
									editedInv.closed ?
									" Status: Completed as of  " + editedInv.closedDate || ""
									: " Status: Running as of " + getLocalISODate()
								}
							</div>
						}
					</div>
					<RenderPages
						editedInv={editedInv}
						handleSetEditedInv={handleSetEditedInv}
						isEditing={isEditing}
						liveEntryTotal={liveEntryTotal}
						paperDetails={paperDetails}
						invoiceTopSectionRef={invoiceTopSectionRef}
						setLayoutModalOpen={setLayoutModalOpen}
						// setUploadFileModalOpen={setUploadFileModalOpen}
					/>
				</div>
				<div className="section-divider" />
				<div className="noprint">
					{(currentUser &&
						editedInv.contractor.id === currentUser.uid &&
						!editedInv.closed) ? (
							<React.Fragment>
								{workerIsWorking ? (
									<div className="noprint button-appearance red">
										<div
											onClick={(e) => {
												setStartWorkModalIsOpen({ open: true, action: "stop" });
											}}
										>
											STOP WORK
										</div>
									</div>
								) : (
									<div className="noprint button-appearance green">
										<div
											onClick={(e) => {
												setStartWorkModalIsOpen({
													open: true,
													action: "start",
												});
											}}
										>
											START WORK
										</div>
									</div>
								)}
							</React.Fragment>
						) : ""
					}

					{
						// a user is logged in and no one has accepted the invoice yet
						// userObject && !editedInv.billTo.uid ?
						// 	<div className="start-button noprint" onClick={e => {
						// 		setAcceptBillModal({open: true})
						// 	}} ><div>Accept Bill</div>
						// 	</div>

						// :  // a user is logged in and this user has accepted the invoice
						// userObject && userObject.uid === editedInv.billTo.uid ?
						// 	<div className="start-button noprint" onClick={e => {
						// 			setAcceptBillModal({open: true})
						// 		}} ><div>Pay Bill</div>
						// 	</div>
						// : // no one has accepted this invoice and user is not logged in
						// !editedInv.billTo.uid &&
						// 	<div className="start-button noprint" onClick={e => {
						// 		setAcceptBillModal({open: true})
						// 	}} ><div>Accept Bill</div>
						// 	</div>
						!editedInv.closed && (
							<React.Fragment>
								<div className="section-divider" />
								<div>
									<button
										onClick={(e) => {
										setAddPaymentModal({
											open: true
										});
									}}
									className="noprint button-appearance">
										Add Payment
									</button>
								</div>
							</React.Fragment>
						)
					}
				</div>
			</InvoiceStyle>
		</React.Fragment>
		// </div>
	);
	// } else return <div>Loading...</div>
};

export default Invoice;
