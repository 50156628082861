import React, { useState, useContext, useEffect, useRef } from "react";
import firebase from "../../../firebase/index";

import { UserContext } from "../../../contexts/UserContext";
import { MediaQueryContext } from "../../../contexts/MediaQueryContext";
import { DialogContext } from "../../../contexts/DialogContext";

// import { AppMessageContext } from '../../../contexts/AppMessageContext'
import { EditInvoiceContext } from "../../../contexts/EditInvoiceContext";

import Modal from "../../Modal/Modal";
import MoreInfoIcon from "../../MoreInfoIcon";
import { ReceiptViewModalStyle } from "./ReceiptViewModalStyle.styled";
import { AppMessage } from "../../MessageUtils";
import { getNewEntriesOnTdChange, getBillCosts } from "../invoiceUtils";
// import { getLocalISODate } from "../../../utils/dateUtils";
import { getNum, handleImageOnError } from "../../../utils/appUtils";

// rendered by: Table in renderingUtils
// errors and success text handled

// maintainance / clean up:
// when updating entries and saving (updateEntriesAndSave) have the allFIles param
// only the new files rather than including all receipts and allFIles ?
// allFiles is all the files for that row of the invoice

// work on cleaning this file up
// add payment receipts ... ? if owner pays bill there should be a receipt
// add option to download or save receipts?
// find out how to enable cors for PDF, when pdf added from iphone it wont appear on PC using chrome

// change this file to a form
const ReceiptViewModal = ({
	receiptViewModalData,
	setReceiptViewModalData,
}) => {
	const { dialog } = useContext(DialogContext);
	const {
		editedInv,
		handleSetEditedInv,
		isEditing,
		setIsEditing,
		userCanEdit,
		handleSave,
		billToBills,
		// setBillToBills,
		// billSnapshotListeners,
		// setBillSnapshotListeners,
	} = useContext(EditInvoiceContext);
	const {
		currentUser,
		handleSetSuccessText,
		setErrorObj,
		offlineMode,
		allUserBills,
		// setAllUserBills,
	} = useContext(UserContext);

	const { isLessThan700px } = useContext(MediaQueryContext);

	const { tableName, rowId, tdFor } = receiptViewModalData;

	const [uploadProgress, setUploadProgress] = useState({
		percent: 0,
		opacity: 0,
	});
	const [allFiles, setAllFiles] = useState([]);
	// const [addFileType, setAddFileType] = useState("file");
	// const [idOfNewBill, setIdOfNewBill] = useState("");
	// const [spreadsheetProps, setSpreadsheetProps] = useState({
	// 	id: "",
	// 	params: [
	// 		{ key: "gid", val: "" },
	// 		{ key: "gridlines", val: "false" },
	// 		{ key: "portrait", val: "true" },
	// 		{ key: "headers", val: "false" },
	// 	],
	// 	type: "pdf",
	// });
	// const possibleParams = [
	// 	"gid",
	// 	"gridlines",
	// 	"portrait",
	// 	"headers",
	// 	"range",
	// 	"custom",
	// ];
	const [newTd, setNewTd] = useState({
		for: "",
		val: "",
		type: "",
	});

	const fileInput = useRef(null);

	const indexOfRow = editedInv[tableName].findIndex((row) => row.id === rowId);
	const indexOfTd = editedInv[tableName][indexOfRow].values.findIndex(
		(td) => td.for === tdFor
	);

	const firebaseInvoiceRef = firebase
		.firestore()
		.collection("invoices")
		.doc(editedInv.id);

	const handleInputChange = (name, val) => {
		setNewTd((newTd) => ({ ...newTd, [name]: val }));
	};


	const handleNameChange = (val, id, name) => {
		const newAllFiles = [...allFiles]
		const indexOfFile = newAllFiles.findIndex(file => file.id === id)

		newAllFiles[indexOfFile] = {...newAllFiles[indexOfFile], [name]: val}

		// costValueIs and paidValueIs can only be updated if autoUpdateCosts is true
		if (name === "costValueIs" || name === "paidValueIs" || name === "autoUpdateCosts") {

			const billObj = [...allUserBills, ...billToBills].find(bill => bill.id === newAllFiles[indexOfFile].billProps.id)
			let file = newAllFiles[indexOfFile] 
			newAllFiles[indexOfFile] = {
				...file,
				...getBillCosts(file, billObj)
			}
		}
		setAllFiles(newAllFiles)
	}

	const handleNewFileGetCost = (allFiles) => {
		let defaultCost = "";
		if (!allFiles.length) {
			const thisRowInEditedInv = editedInv[tableName][indexOfRow];
			const costTd = thisRowInEditedInv.values.find((td) => td.for === "cost");
			if (costTd && costTd.val) {
				defaultCost = getNum(costTd.val);
			}
		}
		return defaultCost;
	};

	const fileSelectedHandler = (e) => {
		if (e.target.files[0]) {
			const fileList = e.target.files;
			let i;
			for (i = 0; i < fileList.length; i++) {
				let file = fileList[i];
				let fileReader = new FileReader();
				fileReader.onload = (e) => {
					let newFile = {
						src: fileReader.result,
						url: "",
						id: firebaseInvoiceRef.collection("receipts").doc().id,
						name: file.name,
						uploadedDate: new Date().toISOString(),
						cost: handleNewFileGetCost(allFiles),
						paid: 0,
						fileType: file.type,
						width: "",
						height: "",
						rowId,
					};
					if (file.type.includes("pdf")) {
						newFile = {
							...newFile,
							file,
						};
						setAllFiles((allFiles) => [...allFiles, newFile]);
					} else {
						const image = new Image();
						image.src = fileReader.result;
						image.onload = () => {
							newFile = {
								...newFile,
								file,
								width: image.width,
								height: image.height,
							};
							setAllFiles((allFiles) => [...allFiles, newFile]);
						};
					}
				};

				fileReader.readAsDataURL(file);
			}
		}
	};

	// const billSelectedHandler = (e, billId) => {
	// 	e.preventDefault();
	// 	const bill = [...allUserBills, ...billToBills].find(
	// 		(obj) => obj.id === billId
	// 	);
	// 	// add limited accessors property in bill
	// 	if (!bill) {
	// 		return;
	// 	}
	// 	const { laborEntries, materialEntries, paymentEntries, customEntries } =
	// 		bill.invoiceTotals;
	// 	let billCost =
	// 		laborEntries.total +
	// 		materialEntries.total +
	// 		(customEntries ? customEntries.total : 0);
	// 	let billPaid = 0;
	// 	let paidValueIs = "";
	// 	if (bill.billTo.uid === editedInv.billTo.uid) {
	// 		billPaid = paymentEntries.total;
	// 		paidValueIs = "totalPaid";
	// 	}
	// 	// add to all files
	// 	if (bill) {
	// 		setAllFiles((allFiles) => [
	// 			...allFiles,
	// 			{
	// 				width: "",
	// 				height: "",
	// 				fileType: "bill",
	// 				name: `Bill #${bill.invNumber} ${bill.invShortHand}`,
	// 				billProps: {
	// 					id: bill.id,
	// 					companyName:
	// 						bill.contractor.companyName || bill.contractor.username || "",
	// 				},
	// 				uploadedDate: new Date().toISOString(),
	// 				id: firebaseInvoiceRef.collection("receipts").doc().id,
	// 				rowId,
	// 				costValueIs: "totalCost",
	// 				paidValueIs,
	// 				cost: getNum(billCost),
	// 				paid: getNum(billPaid),
	// 				autoUpdateDate: true,
	// 				autoUpdateCosts: true,
	// 				mostRecentEntryDate: bill.mostRecentEntryDate || getLocalISODate(),
	// 			},
	// 		]);
	// 	}
	// };

	// const urlSelectedHandler = (e) => {
	// 	e.preventDefault();
	// 	setAllFiles((allFiles) => [
	// 		...allFiles,
	// 		{
	// 			width: "",
	// 			height: "",
	// 			fileType: "url",
	// 			name: spreadsheetProps.id,
	// 			spreadsheetProps,
	// 			uploadedDate: new Date().toISOString(),
	// 			id: firebaseInvoiceRef.collection("receipts").doc().id,
	// 			rowId,
	// 			cost: handleNewFileGetCost(allFiles),
	// 			paid: "",
	// 		},
	// 	]);
	// };

	const getGoogleSheetsUrl = ({ id, type, params }) => {
		let newUrl;
		let paramsString = "";
		if (params && params.length) {
			params.forEach(
				(param) =>
					(paramsString += param.val ? "&" + param.key + "=" + param.val : "")
			);
		}
		if (type === "pdf") {
			newUrl = `https://drive.google.com/viewer/viewer?embedded=true&url=${encodeURIComponent(
				`https://docs.google.com/spreadsheets/d/${id}/export?exportFormat=pdf&format=pdf${
					paramsString && paramsString
				}`
			)}`;
		} else if (type === "html") {
			if (
				params.find((p) => p.key === "gid") &&
				params.find((p) => p.key === "gid").val
			) {
				newUrl = `https://docs.google.com/spreadsheets/u/1/d/${id}//htmlembed?format=html${
					paramsString && paramsString
				}&single=true&widget=false&chrome=false`;
			} else {
				newUrl = `https://docs.google.com/spreadsheets/u/1/d/${id}//htmlembed?format=html${
					paramsString && paramsString
				}&chrome=false`;
			}
		}
		return newUrl;
	};

	// const handleSpreadsheetIdChnage = (name, val) => {
	// 	if (name === "id") {
	// 		val = val.replace("/", "");
	// 	}
	// 	setSpreadsheetProps((spreadsheetProps) => ({
	// 		...spreadsheetProps,
	// 		[name]: val,
	// 	}));
	// };

let hasNewFiles = false
	allFiles.forEach(f => {
		if (editedInv.receipts.some(rec => rec.id === f.id)) {
			hasNewFiles = false
		} else {
			hasNewFiles = true
		}
	})

	const updateEntriesAndSave = async () => {
		// setUploadProgress((uploadProgress) => ({
		// 	...uploadProgress,
		// 	display: "block",
		// 	opacity: 1,
		// }));

		let newAllInvoiceReceipts = editedInv.receipts.map((rec) => {
			return { ...rec };
		});

		let newFiles = [];

		// note ALLFILES IS NOT === TO editedInv.receipts
		// allFiles are files this row
		let newReceiptSum = 0;
		let newReceiptSumNotIncludingNewFiles = 0;
		// push the receipts for this table row into the main receipts array
		// either add the file to the newFiles array or update it
		allFiles.forEach((file) => {
			newReceiptSum += getNum(file.cost) - getNum(file.paid); // problem with bills?

			// find file in editedInv.receipts
			let indexOfFile = newAllInvoiceReceipts.findIndex(
				(rec) => rec.id === file.id
			);

			if (indexOfFile === -1) {
				// add to receipt to newFiles if file is not found
				newFiles.push({
					...file,
					cost: getNum(file.cost, 2),
					paid: getNum(file.paid, 2),
				});
			} else {
				newReceiptSumNotIncludingNewFiles +=
					getNum(file.cost) - getNum(file.paid); // problem with bills?
				// update if file has changed
				newAllInvoiceReceipts[indexOfFile] = {
					...newAllInvoiceReceipts[indexOfFile],
					...file,
					cost: getNum(file.cost, 2),
					paid: getNum(file.paid, 2),
				};
			}
		});

		if (newFiles.length) {
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				display: "block",
				opacity: 1,
			}));
		}
		
		// update the row that the receipt is held in (td item and qty)
		let updatedEntries = getNewEntriesOnTdChange(
			newTd,
			editedInv,
			tableName,
			rowId
		);

		const qtyTd = editedInv[tableName][indexOfRow].values.find(
			(td) => td.for === "qty"
		);
		if (qtyTd && !qtyTd.val) {
			updatedEntries = getNewEntriesOnTdChange(
				{ for: "qty", val: 1 },
				{ ...editedInv, [tableName]: updatedEntries },
				tableName,
				rowId
			);
		}

		if (offlineMode) {
			// dont include new file costs in entry update
			// update the td cost and total
			updatedEntries = getNewEntriesOnTdChange(
				{ for: "cost", val: getNum(newReceiptSumNotIncludingNewFiles, 2) },
				{ ...editedInv, [tableName]: updatedEntries },
				tableName,
				rowId
			);
			const newEditedInv = {
				...editedInv,
				// update the receipt changes and the name change bit not the cost, qty td's
				[tableName]: updatedEntries,
				receipts: newAllInvoiceReceipts,
			};

			handleSave({
				newEditedInv,
			});
			// does not update new files if there is any
			handleSetEditedInv({
				mergeIntoHistory: true,
				changes: newEditedInv,
				caller: "ReceiptViewModal - updateEntriesAndSave - offlineMode",
			});
			if (newFiles.length) {
				setErrorObj({
					message:
						"Unable to upload files in offline mode. Check your Internet connection",
					noReport: true,
				});
			}
		} else {
			try {
				updatedEntries = getNewEntriesOnTdChange(
					{ for: "cost", val: getNum(newReceiptSum, 2) },
					{ ...editedInv, [tableName]: updatedEntries },
					tableName,
					rowId
				);
				const newEditedInv = {
					...editedInv,
					[tableName]: updatedEntries,
					receipts: newAllInvoiceReceipts,
				};

				// update existing receipt values and new row cost qty total
				// add new files to storage, get downloadUrl, update in firestore, update editedInv
				if (newFiles.length) {
					const filesForStorage = newFiles.filter(
						(obj) => obj.fileType !== "url" && obj.fileType !== "bill"
					);

					// upload filesForStorage to storage
					const uploadTaskPromiseSnapshots = await fileUploader(
						filesForStorage,
						setUploadProgress
					);
					let newReceiptObjects = [];

					for (let i = 0; i < newFiles.length; i++) {
						const file = newFiles[i];
						let dbReceiptObj = {
							...file,
							cost: getNum(file.cost, 2),
							paid: getNum(file.paid, 2),
							storagePath: `${currentUser.uid}/receipts/${file.id}`,
						};
						delete dbReceiptObj.src;
						delete dbReceiptObj.file;

						const fileSentToStorageSnapshot = uploadTaskPromiseSnapshots.find(
							(snapshot) => snapshot.metadata.name === file.id
						);

						// add url if file type is not BILL or URL
						if (fileSentToStorageSnapshot) {
							const url = await fileSentToStorageSnapshot.ref.getDownloadURL();
							dbReceiptObj = {
								...dbReceiptObj,
								url,
								uploadedDate: fileSentToStorageSnapshot.metadata.timeCreated,
							};
						}
						newReceiptObjects.push(dbReceiptObj);
					}

					await handleSave({
						newEditedInv: {
							...newEditedInv,
							receipts: [...newEditedInv.receipts, ...newReceiptObjects],
						},
					});

					handleSetEditedInv({
						mergeIntoHistory: true,
						changes: {
							...newEditedInv,
							receipts: [...newEditedInv.receipts, ...newReceiptObjects],
						},
						caller:
							"ReceiptViewModal - updateEntriesAndSave - newFiles.length === true",
					});

					handleSetSuccessText("Files uploaded successfully!");
				} else {
					await handleSave({
						// newEditedInv does not include new files
						// but does add up new qty cost totals from new files
						newEditedInv,
					});
					// update any changes to existing receipts
					// updated entries should take into account any receipt cost changes

					// setEditedInv(newEditedInv)
					handleSetEditedInv({
						mergeIntoHistory: true,
						changes: newEditedInv,
						caller:
							"ReceiptViewModal - updateEntriesAndSave - !newFiles.length",
					});
					handleSetSuccessText("Files updated successfully!");
				}
			} catch (err) {
				setUploadProgress((uploadProgress) => ({
					...uploadProgress,
					percent: 0,
					opacity: 0,
				}));
				setErrorObj(err);
			}
		}

		setUploadProgress((uploadProgress) => ({
			...uploadProgress,
			percent: 0,
			opacity: 0,
		}));
	};

	const fileUploader = async (newFiles, setUploadProgress) => {
		if (newFiles.length) {
			let storageRef;
			storageRef = firebase.storage().ref(`${currentUser.uid}/receipts`);

			// keep photos in cache for one month and allow cdn to cache and if max age passed, async revalidate within one day
			let fileEditorsCustomMetadada = {}
			editedInv.editors.forEach(id => {
				fileEditorsCustomMetadada[id] = "editor"
			})

			const metadata = {
				cacheControl: "public, max-age=2592000, stale-while-revalidate=86400",
				customMetadata: {
					forDoc: editedInv.id,
					...fileEditorsCustomMetadada
				}
			};

			let uploadTaskPromises = [];
			let i;
			for (i = 0; i < newFiles.length; i++) {
				let doc = newFiles[i];
				let uploadTask = storageRef.child(doc.id).put(doc.file, metadata);
				uploadTask.on(
					"state_changed", // on('evt_listener', progress(), error(), complete()
					(snapshot) => {
						// progress function
						let progress = Math.round(
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100
						);
						setUploadProgress((uploadProgress) => ({
							...uploadProgress,
							percent: progress,
						}));
					},
					(err) => {
						// error function
						setUploadProgress((uploadProgress) => ({
							...uploadProgress,
							percent: 0,
							opacity: 0,
						}));
						throw err;
					},
					() => {}
				);
				uploadTaskPromises.push(uploadTask);
			}
			const finishedUploadResults = await Promise.all(uploadTaskPromises);
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				percent: 0,
				opacity: 0,
			}));

			return finishedUploadResults;
		} else {
			setUploadProgress((uploadProgress) => ({
				...uploadProgress,
				percent: 100,
			}));
			return [];
		}
	};

	const fileRemovedHandler = async (id, ownerUid) => {
		// if file exists in db window confirm delete
		const fileObj = allFiles.find((obj) => obj.id === id);
		let confirmRemove;
		const receiptInEditedInv = editedInv.receipts.find((rec) => rec.id === id);

		if (!receiptInEditedInv) {
			confirmRemove = true;
		} else {
			confirmRemove = await dialog.confirm("Confirm remove this file?");
		}

		if (confirmRemove) {
			const newAllFiles = allFiles.filter((file) => file.id !== id);
			const newReceipts = editedInv.receipts.filter((rec) => rec.id !== id);
			setAllFiles(newAllFiles);
			handleSetEditedInv({
				mergeIntoHistory: true,
				changes: { receipts: newReceipts },
				caller: "ReceiptViewModal - fileRemovedHandler",
			});
			// fileInput.current.value = ""
		}

		// remove from firebase if it exists
		if (confirmRemove && receiptInEditedInv) {
			let storageRef;
			if (fileObj.fileType !== "url" && fileObj.fileType !== "bill") {
				storageRef = firebase.storage().ref(`${ownerUid}/receipts/${id}`);
			}
			firebaseInvoiceRef
				.collection("receipts")
				.doc(id)
				.delete()
				.catch((err) => setErrorObj(err));

			// let newBillSnapshotListeners = [];
			// billSnapshotListeners.forEach((obj) => {
			// 	if (obj.id === id) {
			// 		obj.listener();
			// 	} else {
			// 		newBillSnapshotListeners.push(obj);
			// 	}
			// });

			// setBillSnapshotListeners(newBillSnapshotListeners);

			// only update the one file to be deleted rather than a complete handleSave
			// fileInput.current.value = ""

			// update storage
			if (storageRef) {
				storageRef
					.delete()
					.then(() => handleSetSuccessText("file removed successfully"))
					.catch((err) => {
						if (err.code === "storage/object-not-found") {
							console.error(err)
							return;
						} else if (err.code === "storage/unauthorized") {
							console.error(err)		
							return;
						} else {
							setErrorObj(err);
						}
					});
			}
		}
	};

	const onClickOutside = () => {
		fileInput.current.value = "";
		setReceiptViewModalData((receiptViewModalData) => ({
			...receiptViewModalData,
			open: false,
		}));
	};

	const getFileType = async (path) => {
		const res = await firebase.storage().ref(path);
		const metadata = await res.getMetadata();
		return metadata.contentType;
	};

	const updateFileType = async (file) => {
		const newFileType = await getFileType(file.storagePath);
		setAllFiles((allFiles) =>
			allFiles.map((obj) => {
				if (file.id === obj.id) {
					return {
						...obj,
						fileType: newFileType,
					};
				} else {
					return obj;
				}
			})
		);
	};

	useEffect(() => {
		setNewTd({
			for: tdFor, //should almost always be "item"
			val: editedInv[tableName][indexOfRow].values[indexOfTd].val,
			type: editedInv[tableName][indexOfRow].values[indexOfTd].type || "",
		});
		// below was only for adding live bills
		// if (userCanEdit) {
		// 	(async () => {
		// 		// change this to be a subscription onSnapshot in userContext
		// 		try {
		// 			const userBillsSnapshot = await firebase
		// 				.firestore()
		// 				.collection("invoices")
		// 				.where("accessors", "array-contains", currentUser.uid)
		// 				.where("billTo.uid", "==", currentUser.uid)
		// 				.get();
		// 			const userBills = userBillsSnapshot.docs.map((doc) => ({
		// 				...doc.data(),
		// 				id: doc.id,
		// 			}));
		// 			setAllUserBills(userBills);
		// 			if (editedInv.billTo.uid) {
		// 				const billToBIllsSnapshot = await firebase
		// 					.firestore()
		// 					.collection("invoices")
		// 					.where("accessors", "array-contains", currentUser.uid)
		// 					.where("billTo.uid", "==", editedInv.billTo.uid)
		// 					.get();
		// 				let newBillToBills = billToBIllsSnapshot.docs.map((doc) => ({
		// 					...doc.data(),
		// 					id: doc.id,
		// 				}));
		// 				newBillToBills = newBillToBills.filter(
		// 					(bill) => !userBills.find((userBill) => userBill.id === bill.id)
		// 				);
		// 				setBillToBills(newBillToBills);
		// 			}
		// 		} catch (err) {
		// 			setErrorObj(err);
		// 		}
		// 	})();
		// }
		let receiptsThisRow = editedInv.receipts.filter(
			(rec) => rec.rowId === rowId
		);

		// filter out all receipts if they are already in allFiles
		const filteredAllReceiptsThisRow = receiptsThisRow.filter(
			(rec) => !allFiles.find((file) => file.id === rec.id)
		);
		let newAllFiles = [...allFiles, ...filteredAllReceiptsThisRow];

		newAllFiles = newAllFiles.sort((a, b) => {
			const d1 = new Date(a.uploadedDate);
			const d2 = new Date(b.uploadedDate);
			return d1 - d2;
		});

		// make sure the edited file prevails if the live bill listener had caused a re render
		if (newAllFiles.length) {
			newAllFiles.forEach((file, i) => {
				// if autoUpdateCosts has changed
				const fileInReceipts = receiptsThisRow.find(
					(rec) => rec.id === file.id
				);
				if (fileInReceipts) {
					if (!isEditing) {
						// get the live update
						newAllFiles[i] = fileInReceipts;
					} else {
						// only update costs if autoUpdate checked
						if (file.autoUpdateCosts) {
							newAllFiles[i].cost = fileInReceipts.cost;
							newAllFiles[i].paid = fileInReceipts.paid;
						}
					}
				}
			});
		}

		setAllFiles(newAllFiles);
		// eslint-disable-next-line
	}, [editedInv.receipts]);

	useEffect(() => {
		allFiles.forEach((file, i) => {
			if (
				!file.fileType &&
				file.url &&
				!editedInv.receipts.find((rec) => rec.id === file.id)
			) {
				// updates files individually, sets allFiles state multiple times
				updateFileType(file);
			}
		});
		// eslint-disable-next-line
	}, [allFiles]);

	return (
		<ReceiptViewModalStyle isLessThan700px={isLessThan700px}>
			<Modal custom={{ absolute: true }} onClickOutside={onClickOutside}>
				<input
					id="file-input"
					type="file"
					ref={fileInput}
					onChange={fileSelectedHandler}
					style={{ display: "none" }}
					accept="image/*"
					multiple
				/>
				{
					isEditing ? (
							<div className="heading">Cell Settings</div>
					) : (
						<div className="heading">{newTd.val}</div>
					)
				}
				<AppMessage />
				<div className="section-divider" />
				{userCanEdit && (
					<button
						className={`button-appearance small edit-button ${isEditing ? "gray" : ""}`}
						onClick={(e) => {
							setIsEditing(!isEditing);
						}}
					>
						{isEditing ? "Stop Edit" : "Edit"}
					</button>
				)}
				<progress
					style={{ opacity: uploadProgress.opacity }}
					value={uploadProgress.percent}
					max="100"
				/>
				{
					isEditing && 
					<React.Fragment>
						<div className="section-divider" />
							<div>
								<label htmlFor="tdItemVal">
									Cell name:&nbsp;
									<input
										id="tdItemVal"
										type="text"
										value={newTd.val}
										onChange={(e) => handleInputChange("val", e.target.value)}
									/>
								</label>
							</div>
						<div className="small-line-break" />
						<label htmlFor="tdType">
							Cell click action:&nbsp;
							<select
								id="tdType"
								value={newTd.type}
								onChange={(e) => handleInputChange("type", e.target.value)}
							>
								<option value="">None</option>
								<option value="link">Link (Open files below)</option>
							</select>
							{
								// add options to format pdf eg: gridlines ...
							}
						</label>
						{newTd.type === "" && (
							<div className="tiny">
								* Only editors will be able to click to view any files below
							</div>
						)}
					</React.Fragment>
				}
				<div className="section-divider" />
				{allFiles.length ? (
					allFiles.map((file, i) => {
						return (
							<React.Fragment key={file.id}>
								<div className="thumbnail-container">
									{isEditing ? (
										<div className="thumbnail-heading">
											<input
												className="receipt-title"
												id={file.id}
												type="text"
												value={file.name}
												onChange={(e) =>
													handleNameChange(e.target.value, file.id, "name")
												}
											/>
											<div className="file-remove">
												<svg
													onClick={() =>
														fileRemovedHandler(file.id, editedInv.owner)
													}
													viewBox="0 0 32 32"
													fill="none"
													stroke="currentcolor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
												>
													<path d="M28 6 L6 6 8 30 24 29 26 6 4 6 M16 12 L16 24 M21 12 L20 24 M11 12 L12 24 M12 6 L13 2 19 2 20 6" />
												</svg>
											</div>
										</div>
									) : (
										<span className="receipt-title">{file.name}</span>
									)}
									<div>
										{file.fileType && file.fileType.includes("pdf") && (
											<React.Fragment>
												<iframe
													loading="eager"
													title="Google webViewer"
													className="file"
													src={
														file.url
															? `https://drive.google.com/viewerng/viewer?embedded=true&url=${
																	encodeURIComponent(file.url) || file.url
															  }`
															: file.src || ""
													}
												>
													<div className="cant-display-file">
														<svg
															id="blank-page"
															viewBox="0 0 32 32"
															width="16px"
															height="16px"
															fill="none"
															stroke="currentcolor"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
														>
															<path d="M6 2 L6 30 26 30 26 10 18 2 Z M18 2 L18 10 26 10" />
														</svg>
														{file.name || "PDF file"}
													</div>
												</iframe>
												<div className="small-line-break" />
											</React.Fragment>
										)}
										{file.fileType === "url" && (
											<div
												className={`embed-type-${
													file.spreadsheetProps && file.spreadsheetProps.type
												}`}
											>
												<iframe
													loading="eager"
													title="Google webViewer"
													src={getGoogleSheetsUrl(file.spreadsheetProps)}
												>
													<div>Cannot load URL</div>
												</iframe>
												<div className="small-line-break" />
												<div className="small-line-break" />
											</div>
										)}
										{file.fileType === "bill" && (
											<div className="embed-type-html">
												<iframe
													loading="eager"
													title={`Bill ${file.billProps.id}`}
													className="file"
													src={`${window.location.origin}/${file.billProps.companyName}/${file.billProps.id}`}
												>
													<div>Cannot load Bill</div>
												</iframe>
												<div className="small-line-break" />
											</div>
										)}
										{(!file.fileType ||
											(!file.fileType.includes("pdf") &&
												file.fileType !== "bill" &&
												file.fileType !== "url")) && (
											<img
												className="file"
												src={file.url || file.src}
												alt={file.name || ""}
												onError={handleImageOnError}
											/>
										)}
									</div>
									{file.fileType === "url" && (
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={getGoogleSheetsUrl(file.spreadsheetProps)}
										>
											View Full Size
										</a>
									)}
									{file.fileType === "bill" && (
										<React.Fragment>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={`${window.location.origin}/${file.billProps.companyName}/${file.billProps.id}`}
											>
												View Bill
											</a>
											{isEditing && (
												<div>
													<label htmlFor="autoUpdateDate">
														<input
															id="autoUpdateDate"
															type="checkbox"
															value={file.autoUpdateDate ? "no" : "yes"}
															checked={file.autoUpdateDate}
															onChange={(e) =>
																handleNameChange(
																	e.target.value === "no" ? false : true,
																	file.id,
																	"autoUpdateDate"
																)
															}
														/>
														&nbsp;Auto update date&nbsp;
													</label>
													<MoreInfoIcon
														className={
															isLessThan700px
																? "text-width-mobile"
																: "text-width"
														}
														text="Automatically update the date on this entry of your invoice to the date of the most recent entry in this bill"
													/>
													<div className="small-line-break" />
													<label htmlFor="autoUpdateCosts">
														<input
															id="autoUpdateCosts"
															type="checkbox"
															value={file.autoUpdateCosts ? "no" : "yes"}
															checked={file.autoUpdateCosts}
															onChange={(e) =>
																handleNameChange(
																	e.target.value === "no" ? false : true,
																	file.id,
																	"autoUpdateCosts"
																)
															}
														/>
														&nbsp;Auto update Costs&nbsp;
													</label>
												</div>
											)}
										</React.Fragment>
									)}
									{file.fileType !== "bill" && file.fileType !== "url" && (
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={file.url}
										>
											View Full Size
										</a>
									)}
									<div className="small-line-break" />
									{isEditing ? (
										<React.Fragment>
											{file.autoUpdateCosts ? (
												<React.Fragment>
													<label htmlFor="cost-value">
														Cost: $:&nbsp;
														<select
															id="cost-value"
															value={file.costValueIs}
															onChange={(e) =>
																handleNameChange(
																	e.target.value,
																	file.id,
																	"costValueIs"
																)
															}
														>
															<option value="totalCost">Total Cost</option>
															<option value="totalPaid">Payments Total</option>
															<option value="totalOwed">Total Owing</option>
															<option value="totalLabor">Labor Total</option>
															<option value="totalMaterials">
																Materials Total
															</option>
														</select>
													</label>
													<div className="small-line-break" />
													<label htmlFor="paid-value">
														Deducted: $:&nbsp;
														<select
															id="paid-value"
															value={file.paidValueIs}
															onChange={(e) =>
																handleNameChange(
																	e.target.value,
																	file.id,
																	"paidValueIs"
																)
															}
														>
															<option value="">Nothing</option>
															<option value="totalCost">Total Cost</option>
															<option value="totalPaid">Payments Total</option>
															<option value="totalOwed">Total Owing</option>
															<option value="totalLabor">Labor Total</option>
															<option value="totalMaterials">
																Materials Total
															</option>
														</select>
													</label>
													<div className="small-line-break" />
													Result Cost: ${" "}
													<span>
														{getNum(
															getNum(file.cost, 100) - getNum(file.paid, 100)
														)}
													</span>
												</React.Fragment>
											) : (
												<React.Fragment>
													<label htmlFor={`${file.id}-cost`}>
														Cost: $&nbsp;
														<input
															className="inline-input"
															id={`${file.id}-cost`}
															value={file.cost}
															onChange={(e) =>
																handleNameChange(
																	e.target.value,
																	file.id,
																	"cost"
																)
															}
															type="number"
															step="0.01"
														/>
													</label>
													<div className="small-line-break" />
													<label htmlFor={`${file.id}-paid`}>
														Deducted: $&nbsp;
														<input
															className="inline-input"
															id={`${file.id}-paid`}
															value={file.paid}
															onChange={(e) =>
																handleNameChange(
																	e.target.value,
																	file.id,
																	"paid"
																)
															}
															type="number"
															step="0.01"
														/>
													</label>
												</React.Fragment>
											)}
										</React.Fragment>
									) : (
										<React.Fragment>
											Cost: $
											<span className="inline-input" id={`${file.id}-cost`}>
												{getNum(file.cost, 2)}
											</span>
											<div className="small-line-break" />
											Deducted: $
											<span className="inline-input" id={`${file.id}-paid`}>
												{getNum(file.paid, 2)}
											</span>
										</React.Fragment>
									)}
								</div>
								<div className="section-divider" />
							</React.Fragment>
						);
					})
				) : (
					<div>{!isEditing && "-- empty --"}</div>
				)}
				<div className="small-line-break" />
				{isEditing && (
					<div>
						<button
							className="button-appearance small"
							onClick={(e) => {
								e.preventDefault();
								fileInput.current.click();
							}}
						>
							{
								allFiles.length ? 
								"Add more"
								: "Upload file(s)"
							}
						</button>

					</div>
				)}
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="align-center" >
					{
						isEditing &&
						<React.Fragment>
							<button disabled={uploadProgress.opacity !== 0} className="button-appearance" onClick={() => {
								updateEntriesAndSave();
								document.getElementById("top-of-modal").scrollIntoView();
							}}>
								{hasNewFiles ? "Upload & Save" : "Save"}
							</button>{" "}
						</React.Fragment>
					}
					<button className={`button-appearance${isEditing ? " gray" : ""}`} onClick={onClickOutside} >
						{isEditing ? "Close" : "OK"}
					</button>
				</div>
			</Modal>
		</ReceiptViewModalStyle>
	);
};

export default ReceiptViewModal;
