import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { EditInvoiceContext } from '../../contexts/EditInvoiceContext'
import { DialogContext } from "../../contexts/DialogContext";


import TableSidebar from './TableSidebar'
import LiveEntryRow from '../LiveEntryRow/LiveEntryRow'
import { TableStyle } from '../Invoice/InvoiceStyle.styled'

import { handleTdChange, parseEntriesRef } from '../Invoice/invoiceUtils'
import { getLocalISODate } from '../../utils/dateUtils'
import { getNum } from '../../utils/appUtils'
import MoreInfoIcon from "../MoreInfoIcon";


// rendered by table > RenderingUtils
// errors handled successText unnecessary 
const TableBody = ({body, tableName, activeRow, setActiveRow/*, setReceiptViewModalData*/}) => {
	const { dialog } = useContext(DialogContext);
	const {
		isEditing,
		editedInv, 
		// setEditedInv, 
		handleSetEditedInv,
		setAddSummaryEntryModal,
		invoiceTotals,
		workerIsWorking, 
		setChangeBillToModalOpen,
		userCanEdit,
		setVerifiedPaymentModalData,
		setReceiptViewModalData,
	} = useContext(EditInvoiceContext)

	useEffect(() => {
	}, [workerIsWorking])

	return (
		<tbody>
			{
				body.map((row, i) => {
					//row is the doc
					if (row && row.visible !== false) {

						const getClassNames = () => {
							let resClassNames = ""
							if (isEditing && activeRow.i === i && activeRow.tableName === tableName) {
								resClassNames += "active-row "
							}
							if (row.verifiedPayment) {
								resClassNames += "verified-payment "
							}
							return resClassNames
						}
						const receiptsThisRow = editedInv.receipts.filter(rec => rec.rowId === row.id)
						// console.log(workerIsWorking, editedInv.liveEntryId, row.id)
						if (workerIsWorking && editedInv.liveEntryId === row.id) { 
							return (
								<LiveEntryRow 
									key={row.id} 
									row={row}
									i={i} 
									// handleTdChange={handleTdChange}
									activeRow={activeRow}
									setActiveRow={setActiveRow}
									setReceiptViewModalData={setReceiptViewModalData}
								  tableName={tableName}
								/>
							)
						} else return (
							<tr 
								className={getClassNames()}
								// className={(isEditing && activeRow.i === i && activeRow.tableName === tableName) ? "active-row" : ""} 
								key={row.id} 
								id={row.id} 
								onClick={e => setActiveRow({tableName, i})}
							>
								{
									row.values.map((td, j) => {
										let inputType = td.for === "date" ? "text" : (td.for === "qty" || td.for === "cost" || td.for === "total") ? "number" : "text"
										let resultTdVal = td.val
										let className="editable-entry"
										let tdClassName = "itemized-entry"

										// check for formulas
										// only total column can handle formulas
										// if any value in qty, cost or total column starts with "=" it is a formula
										if (typeof(resultTdVal) === "string") {
											if (td.isFormula) {
												resultTdVal = parseEntriesRef(editedInv, invoiceTotals, resultTdVal)
											} else if ((td.for === "total" || td.for === "cost" || td.for === "qty") && resultTdVal.startsWith("=")) {
												resultTdVal = parseEntriesRef(editedInv, invoiceTotals, resultTdVal)
											}
										}
										// if (typeof(resultTdVal) === "string" && (td.isFormula || ((td.for === "total" || td.for === "cost" || td.for === "qty") && resultTdVal.startsWith("=") /*&& (resultTdVal.includes("editedInv") || resultTdVal.includes("invoiceTotals"))*/) {
											// resultTdVal = parseEntriesRef(editedInv, invoiceTotals, resultTdVal)
										// }
										// if (tableName === "summaryEntries") {
										// 	console.log(tableName, td)
										// 	if (typeof) {}
										// }
										let minTdWidth = (resultTdVal && resultTdVal.length > 8) ? resultTdVal.length - 1 : 9
										if (minTdWidth > 25 && tableName !== "description" && tableName !== "billTo") {
											minTdWidth = 24
											resultTdVal = resultTdVal.slice(0, 22) + "..."
										}
										if (!resultTdVal && tableName === "billTo" && isEditing) {
											resultTdVal = <span style={{display: "table-cell"}} />
										}
										let onClick = null
										const isMaterialsItemEntry = tableName === "materialEntries" && (td.for === "item")
										let isLinkType
										let isUrl = !row.verifiedPayment && row.url && td.for === "item"
										if (td.type === "link" || (row.verifiedPayment && td.for === "item")) {
											isLinkType = true
										} else {
											if (td.type === undefined && isMaterialsItemEntry) {
												td.type = "link"
												isLinkType = true
											}
										}
										const isBillTo = (tableName === "billTo" && td.for === "billTo")
										let noInput = /*isLinkType ||*/ td.for === "total" || td.for === "billTo" || td.noEdit
										// let inputEditible = td.noEdit === true ? false : true
										// handle verified payment entries
										if (row.immutable) {
											noInput = true
										}

										if (td.for === "date") {
											minTdWidth = 13
											className = className + " date-entry"
											// if any receipts in this row have the autoUpdateDate property
											if (receiptsThisRow.find(rec => rec.autoUpdateDate)) {
												onClick = (e) => isEditing ? dialog.alert("Cannot edit value because this row has a live bill! To change the value you must first open Cell Settings and disable the 'Auto update date' checkbox") : null
												noInput = true
											}
										}

										if (td.for === "item" && minTdWidth === 9) {
											minTdWidth = 12
										}

										if (td.for === "cost" || td.for === "qty") {
											if (receiptsThisRow.find(rec => rec.autoUpdateCosts)) {
												onClick = (e) => isEditing ? dialog.alert("Cannot edit value because this row has a live bill! To change the value you must first open Cell Settings and disable the 'Auto update cost' checkbox") : null
												noInput = true
											}
										}

										if (td.for === "today") {
											if (editedInv.closed) {
												resultTdVal = editedInv.closedDate
											} else {
												// resultTdVal = editedInv.invoiceDate || getLocalISODate()
												resultTdVal = td.val || getLocalISODate()
												// new update to allow edits 
												td.noEdit = false
											}
										}

										// const itemTd = row.values.find(td => td.for === "item")
										// handle open modal when adding a receipt 

										if (isLinkType && !isEditing) {
											className = className + " link-appearance blue"		
											// if the row is a verified payment open verified payment modal instead
											if (row.verifiedPayment) {
												onClick = (e) => {
													setVerifiedPaymentModalData(verifiedPaymentModalData => ({
														...verifiedPaymentModalData,
														open: true,
														tableName,
														td,
														rowId: row.id,
														tdFor: td.for
													}))
												}
											} else {
												onClick = (e) => {
													setReceiptViewModalData(receiptViewModalData => ({
														...receiptViewModalData, 
														open: true, 
														tableName, 
														rowId: row.id, 
														// tdFor: itemTd.for,
														tdFor: td.for,
														// handleTdChange
													}))
												}
											}
										}
										// handle open form when changing bill to
										if (isBillTo) {
											if (userCanEdit) {
												onClick = () => {
													setChangeBillToModalOpen(true)
												}
											}
											// make resultTdVal = a link component
											resultTdVal = editedInv.billTo.username ? 
												<React.Fragment>
													{resultTdVal}
													<div>
														<Link to={`/users/${editedInv.billTo.username}`}>@{editedInv.billTo.username}</Link>
													</div>
												</React.Fragment>
												: resultTdVal
										}
										if (inputType === "number") {
											if (td.for === "total") {
												resultTdVal = getNum(resultTdVal, 2)
											} else {
												resultTdVal = getNum(resultTdVal)
											}
										}
										if (td.for === "invNumber") {
											resultTdVal = `#${resultTdVal}`
										}
										if (td && td.visible !== false) {
											if (typeof(td) !== "string") {
												// not using path for anything other than id maybe change to random number
												const path = tableName ? `editedInv[${tableName}]/${row.id || ""}/${td.for}` : td.for
												const inputProperties = {
													type: inputType,
													// style: {width: td.for === "date" && "fit-content"},
													value: resultTdVal,
													// onChange: e => td.change ? td.change(e.target.value, td) : setEditedInv(handleTdChange({...td, val: e.target.value}, editedInv, tableName, row.id, setActiveRow)),
													onChange: e => td.change ? td.change(e.target.value, td) : handleSetEditedInv({
														// currentEditedInv: editedInv,
														changes: handleTdChange({...td, val: e.target.value}, editedInv, tableName, row.id, setActiveRow),
														caller: "table.js - td.visible === true - inputProperties.onChange"
													}),

													className,
													disabled: td.disabled === true
												}
												if (isEditing && !noInput) {
													return (
														// <td key={td.path ? td.path : td.for} id={td.path ? td.path : td.for}>
														<td 
															style={(tableName !== "description" && tableName !== "billTo") ? {minWidth: `${minTdWidth}ch`} : {}} 
															className={tdClassName} key={path} id={path} onClick={onClick} >
															{
																td.for === "description" /*|| td.for === "billTo"*/ ? 
																	<textarea
																		rows={(resultTdVal.length > 87 || resultTdVal.includes("\n")) ? "auto" : 1}
																		{...inputProperties}
																	/>
																: 
																<React.Fragment>
																	{
																		(td.for === "date" /*&& activeRow.i === i*/) ?
																		<React.Fragment>
																			{
																				(activeRow.i === i && activeRow.tableName === tableName) ?
																					/*<input type="date" className="date-picker noprint" value={resultTdVal} onChange={(e) => setEditedInv(handleTdChange({...td, val: e.target.value}, editedInv, tableName, row.id, setActiveRow))} />*/
																				<input type="date" className="date-picker noprint" value={resultTdVal} onChange={
																					(e) => handleSetEditedInv({
																						// currentEditedInv: editedInv,
																						changes: handleTdChange(
																							{...td, val: e.target.value}, 
																							editedInv, 
																							tableName, 
																							row.id,
																							setActiveRow
																						),
																						caller: "table.js - td.visible === true - td.for === date"
																					})
																				} />

																				: <span className={className} >{resultTdVal}</span>
																			}
																		</React.Fragment>
																		:
																		<React.Fragment>
																			<input 
																				{...inputProperties}
																				maxLength="25"
																			/>
																			{
																				(td.for === "item" && activeRow.i === i && activeRow.tableName === tableName) &&
																				<svg 
																					className="noprint settings-icon" 
																					// id="settings-icon" 
																					onClick = {(e) => {
																						if (tableName === "summaryEntries") {
																							setAddSummaryEntryModal({open: true, editExistingEntry: true, data: {id: row.id, tableName, showWindowConfirm: false}})
																						} else if (row.verifiedPayment) {
																							setVerifiedPaymentModalData(verifiedPaymentModalData => ({
																								...verifiedPaymentModalData,
																								open: true,
																								tableName,
																								td,
																								rowId: row.id,
																								tdFor: td.for
																							}))
																						} else {
																							setReceiptViewModalData(receiptViewModalData => ({
																								...receiptViewModalData, 
																								open: true, 
																								tableName, 
																								rowId: row.id, 
																								// tdFor: itemTd.for,
																								tdFor: td.for,
																								// handleTdChange
																							}))
																						}
																					}}
																					viewBox="0 0 32 32" 
																					fill="white" 
																					stroke="currentcolor" 
																					strokeLinecap="round" 
																					strokeLinejoin="round" 
																					strokeWidth="2"
																				>
																			    <path d="M13 2 L13 6 11 7 8 4 4 8 7 11 6 13 2 13 2 19 6 19 7 21 4 24 8 28 11 25 13 26 13 30 19 30 19 26 21 25 24 28 28 24 25 21 26 19 30 19 30 13 26 13 25 11 28 8 24 4 21 7 19 6 19 2 Z" />
																			    <circle cx="16" cy="16" r="4" />
																				</svg>
																			}
																		</React.Fragment>
																	}
																</React.Fragment>
															}
														</td>
													)
												} else {
													// handle open link svg  space
													if (isUrl) {
														minTdWidth += 2
													}
													return (
														<td 
															style={(tableName !== "description" && tableName !== "billTo") ? {minWidth: `${minTdWidth}ch`} : {whiteSpace: "pre-wrap"}} 
															className={tdClassName} key={path} id={path} onClick={onClick} >
															{
																isUrl ? 
																	<a className={className} href={row.url} target="blank" >
																		<React.Fragment>
																			{resultTdVal}
																			<svg id="open-link" viewBox="0 0 32 32" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
																		    <path d="M14 9 L3 9 3 29 23 29 23 18 M18 4 L28 4 28 14 M28 4 L14 18" />
																			</svg>
																		</React.Fragment>
																	</a>
																: <span className={className} >{resultTdVal}</span>
																	// description ^^
															}
															{
																(row.status === "needs_confirmation" && td.for === "item" && !isEditing) &&
																<MoreInfoIcon
																	svgClassname="noprint absolute"
																	absolute={true}
																	text={"Awaiting the contractors confirmation of payment received"}
																/>
															}
															{
																(isEditing && isLinkType && activeRow.i === i && activeRow.tableName === tableName) &&
																<svg 
																	className="noprint settings-icon" 
																	// id="settings-icon" 
																	onClick = {(e) => {
																		if (row.verifiedPayment) {
																			setVerifiedPaymentModalData(verifiedPaymentModalData => ({
																				...verifiedPaymentModalData,
																				open: true,
																				tableName,
																				td,
																				rowId: row.id,
																				tdFor: td.for
																			}))
																		} else {
																			setReceiptViewModalData(receiptViewModalData => ({
																				...receiptViewModalData, 
																				open: true, 
																				tableName, 
																				rowId: row.id, 
																				// tdFor: itemTd.for,
																				tdFor: td.for,
																				// handleTdChange
																			}))
																		}
																	}}
																	viewBox="0 0 32 32" 
																	fill="none" 
																	stroke="currentcolor" 
																	strokeLinecap="round" 
																	strokeLinejoin="round" 
																	strokeWidth="2"
																>
															    <path d="M13 2 L13 6 11 7 8 4 4 8 7 11 6 13 2 13 2 19 6 19 7 21 4 24 8 28 11 25 13 26 13 30 19 30 19 26 21 25 24 28 28 24 25 21 26 19 30 19 30 13 26 13 25 11 28 8 24 4 21 7 19 6 19 2 Z" />
															    <circle cx="16" cy="16" r="4" />
																</svg>
															}
															
														</td>

													)
												}
											} else {
												return (
													<td className={tdClassName} key={td} id={td} onClick={onClick} >{td}</td>
												)
											}
										} else return null
									})
								}
								{
									// userIsCreatorOrContractor && <div style={{border: "none"}}>+</div>
								}
							</tr>
						)
					} else return null
				})
			} 
		</tbody>
	)
}

// rendered by: RenderingUtils, ...
// errors handled successText unnecessary
const Table = React.forwardRef(({paperDetails, headings, body, minWidth, maxWidth, className, tableName, entriesPageOrderName}, ref) => {
	const {
		isEditing,
		activeRow,
		setActiveRow,
		editedInv, 
		// setEditedInv,
		handleSetEditedInv,
		setErrorObj
	} = useContext(EditInvoiceContext)
	// const [chooseDateModalOpen, setChooseDateModalOpen] = useState({open: false, td: {}, i: "", j: "", row: {}, tableName: ""})

	const indexOfTable = editedInv[entriesPageOrderName].findIndex(table => table.for === tableName)

	const handleThChange = (val, newEditedInv, tableName, thFor) => {
		if (indexOfTable === -1) {
			setErrorObj({message: "tableName: " + tableName + " not found "})
			return false
		}
		const indexOfTh = newEditedInv[entriesPageOrderName][indexOfTable].tableHeadings.findIndex(thObj => thObj.for === thFor)

		// create a whole new page order to prevent mutating state
		let newPageOrder = []
		newEditedInv[entriesPageOrderName].forEach(item => {
			if (item.tableHeadings && typeof(item.tableHeadings[0]) !== "string") {
				let newTableHeadings = []
				item.tableHeadings.forEach(th => newTableHeadings = [...newTableHeadings, {...th}])
				newPageOrder = [...newPageOrder, {...item, tableHeadings: newTableHeadings}]
			} else {
				newPageOrder = [...newPageOrder, item]
			}
		})

		newPageOrder[indexOfTable].tableHeadings[indexOfTh].val = val
		// setEditedInv(editedInv => ({...newEditedInv, [entriesPageOrderName]: newPageOrder}))
		handleSetEditedInv({changes: {...newEditedInv, [entriesPageOrderName]: newPageOrder}})

	}

	let tableHeadings = headings.filter(th => th.visible !== false)

	tableHeadings = tableHeadings.map(th => {
		if (typeof(th) !== "string") {
			// before copy pasting the previous working version this file had new conditions for 
			// the tableheadings classNames? 
			return (
				<th key={th.for}>
				{
					isEditing ?
					<input 
						className="editable-entry" 
						value={th.val} 
						onChange={e => handleThChange(e.target.value, editedInv, tableName, th.for)}
					/>: <span>{th.val}</span>
				}
				</th>
			) 
		} else {
			return <th key={th}><span>{th}</span></th> 
		} 
	})

		return (
			<div className={`table-container ${className && className}`}>
				{
					// chooseDateModalOpen.open &&
					// <Modal custom={{absolute: true}} onClickOutside={onClickOutside} >
					// 	<input 
					// 		type="date" 
					// 		value={editedInv[tableName][chooseDateModalOpen.i].values[chooseDateModalOpen.j].val} 
					// 		onChange={e => chooseDateModalOpen.td.change({td: {...chooseDateModalOpen.td, val: e.target.value}, newEditedInv: editedInv, newI: chooseDateModalOpen.i, newJ: chooseDateModalOpen.j, row: chooseDateModalOpen.row})}
					// 	/>
					// 	<button onClick={onClickOutside}>Okay</button>
					// </Modal>
				}
				<TableStyle 
					// className={className}
					ref={ref}
					screenPageWidthPx={paperDetails.screenPageWidthPx} 
					columns={tableHeadings.length} 
					minWidth={minWidth} 
					maxWidth={maxWidth}
					scaleRatio={paperDetails.printablePaperWidthToScreenWidthRatio}
					rowHeight={paperDetails.rowHeight}
				>
					<thead>
						<tr>
							{tableHeadings}
						</tr>
					</thead>
					<TableBody 
						body={body} 
						tableName={tableName} activeRow={activeRow} 
						setActiveRow={setActiveRow}
						// setReceiptViewModalData={setReceiptViewModalData}
					/>
				</TableStyle>
				{
					isEditing && (tableName === "materialEntries" || tableName === "laborEntries" || tableName === "paymentEntries" || tableName === "summaryEntries") &&
					<TableSidebar paperDetails={paperDetails} body={body} tableName={tableName} activeRow={activeRow} />
				}
			</div>
		)
	})

export default Table
