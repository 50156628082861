import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import styled from "styled-components";


import { getTopPickRating } from "./utils";
// import PostView from './PostView'
import MoreInfoIcon from "../MoreInfoIcon";

import { UserPhoto } from './marketplaceComponents';
import ImageCarosel from './ImageCarosel';

import { handleImageOnError } from "../../utils/appUtils";


const MarketplaceListStyle = styled.div `
	.section-container {
		h3 {
			color: var(--chicago);
			.material-icons {
				color: var(--light-gray);
			  vertical-align: text-top;
			}
		}
		.section-items {
			> div {
				margin: 0 0 30px 0 !important;
				overflow: auto !important;
			}
		  .photo-and-text {
		  	display: flex;
		  	column-gap: 5px;
		  }
	    .user-photo-container {
	    	border-radius: 50%;
			  background: transparent;
			  box-shadow: 0px 0px 10px -2px;
			  height: 40px;
		    display: inline-block;
		    .spinner-container {
		    	position: relative;
		    	display: flex;
		    	> div {
		    		position: static;
		    		margin-top: auto;
		    		margin-bottom: auto;
		    	}

		    }
	    	img, > div {
		    	width: 40px;
		    	height: 40px;
		    	border-radius: 50%;
	    	}
	    }

			.post-img-container {
				.see-more-container {
					margin-top: 10px;
					display: flex;
					justify-content: space-between;
				}
				.main-photo {
					// width: 300px;
				  max-width: 390px;
				}
				.engagement-actions {
					font-size: var(--font-size-xs);
					.material-icons {
						font-size: var(--font-size-l);
					}
					padding-top: 15px;
					display: flex;
					position: absolute;
					right: 0;
					top: 0;
					justify-content: space-between;
					column-gap: 10px;
				}
		}
	}
`

const Section = ({
	name,
	displayIcon,
	displayName,
	children,
	photos,
}) => {

	const postContainerRef = useRef(null)
	const parentContainerWidth = postContainerRef.current ? postContainerRef.current.clientWidth : 700

	return (
		<div key={name} id={`${name}-section`} className="section-container">
			<h3>
				{
					displayIcon ? 
						<span className="material-icons location-icon" >{displayIcon}</span>
					: ""
				}
				{" "}
				{displayName}
			</h3>
			<div className="section-items" ref={postContainerRef} >
				<ImageCarosel parentContainerWidth={parentContainerWidth - 40} photos={photos}> 
					{children}
				</ImageCarosel>
			</div>
		</div>
	)


}

const MarketplaceList = ({
	usersJobs,
	searchingJobs,
	jobs,
	handleDeletePost,
	handleUpdatePost,
	handleSetJobs,
	handleOpenEditPostModal,
	userObject,
	setLoginModalOpen,
	handleSetMessage,
	handleSetSuccessText,
	filters,
	setViewingPost,
	alreadyFetchedUsers,
	handleSetAlreadyFetchedUsers,
}) => {
	let sections = [{name: "myJobs", displayName: "My Jobs", displayIcon: "\ue838"}, {name: "topPicks", displayName: userObject.id ? "Recommended" : "Top Picks"/*, displayIcon: "\ue8e5"*/, displayIcon: "\ue85c"}] 

	// add in city sections
	jobs.forEach(job => {
		const cityName = job.city.toLowerCase()
		const cityDisplayName = job.city
		const displayIcon = "\ue0c8"

		const cityInnSections = sections.find(s => s.name === cityName)
		if (!cityInnSections) {
			sections.push({name: cityName, displayName: cityDisplayName, displayIcon})
		}
	})

	let sortedSectionizedJobsWithTopPicks = [...jobs]
	// if not searching jobs, only include users jobs
	if (!searchingJobs) {
		sections = [{name: "myJobs", displayName: "My Jobs"}]
		sortedSectionizedJobsWithTopPicks = [...usersJobs]
	}

	// add in recomendation score
	sortedSectionizedJobsWithTopPicks = sortedSectionizedJobsWithTopPicks.map(j => {
		let newJob = {...j}
		let newJobSections = newJob.sections || []
		let recommendedSum = 0
		// 60 in getTopPickRating
		// 15 max for interacted to viewed ratio
		const maxRecommendationNum = 60 + 15 + 5 + 7

		if (userObject.id) {
			if (userObject.id === newJob.userId) {
				newJobSections.push("myJobs")
			}

			// const userHasLikedPost = newJob.likedBy.includes(userObject.id)
			// const userHasIntrest = newJob.likedBy.includes(userObject.id)

			// cant increase score if liked or interested since this can cause a glitchy ui with jobs changing spots in list
			// if (userHasLikedPost) {
			// 	// newJobSections.push("myJobs")
			// 	recommendedSum += 5
			// }

			// if (userHasIntrest) {
			// 	// newJobSections.push("myJobs")
			// 	recommendedSum += 7
			// }

			// newJob.recommendedScore = getTopPickRating(userObject, newJob)
			recommendedSum += getTopPickRating(userObject, newJob)

		}


		// handle recommended score if user logged in
		// check recommended score before interactions because 
		// the score right here is more important than below since it is based on industry match and location
		if (userObject.id && recommendedSum > 34 && newJob.userId !== userObject.id) {
			newJobSections.push("topPicks")
		}

		// recommendedSum += newJob.interestedUsers.length * 2 
		// recommendedSum += newJob.likedBy.length 
		const likedOrInterested = [...newJob.interestedUsers, newJob.likedBy]
		// const viewedOnly = newJob.viewedBy.filter(userId => !likedOrInterested.includes(userId))
		if (!newJob.viewedBy) {
			console.error("no job.viewedBy property")
		}

		const interacted = newJob.viewedBy ? newJob.viewedBy.filter(userId => likedOrInterested.includes(userId)) : []

			// default ratio is 0.5 which is high
		let viewsToInteractionsRatio = 0.5
		// if only a few people have viewed, dont add the ratio
		// this will increase or decrease score if ><50%
		if (newJob.viewedBy && newJob.viewedBy.length > 3) {
			viewsToInteractionsRatio = interacted.length / newJob.viewedBy.length
		}

		// high views without liking or interested are bad
		recommendedSum += (30 * viewsToInteractionsRatio) - 15

		if (!userObject.id) { // still show top picks if user not logged in
			if (recommendedSum >= 15) {
				newJobSections.push("topPicks")
			}
		}

		// add in scores based on customer rating
		// todo need to get the customer user object
		newJob.sections = newJobSections
		newJob.recommendedScore = recommendedSum / maxRecommendationNum

		// the interactions subcollection is not fetched upstream, would have to fetch it here
		// if (newJob.interactions) {
			
		// }



		return newJob
	})

	// add top picks based on recommended score 
	sortedSectionizedJobsWithTopPicks = sortedSectionizedJobsWithTopPicks.sort((a, b) => b.recommendedScore - a.recommendedScore)


	const getDefaultInteractionsObj = (userObject, interactions) =>  {
		if (interactions && interactions[userObject.id]) {
			return {
				...interactions[userObject.id],
				// keep this info up to date
				username: userObject.username,
				photo: userObject.logoUrl || "",
			}

		} else {
			return {
				accessors: [userObject.id], // this doc is only accessible by userId but when user is making a bid we could add the poster id to the accessors list to allow them to view
				visibility: "private",
				id: userObject.id,
				username: userObject.username,
				photo: userObject.logoUrl || "",
			}
		}
	}

	const handleLikeOrInterested = (name, value, jobId, e) => {

		if (!userObject.id) {
			// handleSetSuccessText("Please log in to interact with this post")
	  	handleSetMessage(
	  		<div>
		  		<div>
			  		Please <span onClick={() => setLoginModalOpen(true)} className="link-appearance" >Log In</span> to interact with this post
		  		</div>
	  		</div>
  		)
			return 
		}

		const job = jobs.find(j => j.id === jobId)

		let isUsersFirstInteraction = true // this is just for a timestamp in the interactions subcollection
		let newPostData = {...job}
		let newUserInteractionObj = getDefaultInteractionsObj(userObject, newPostData.interactions)

		// if (newPostData.interactions && newPostData.interactions[userObject.id]) {
		// 	isUsersFirstInteraction = false
		// }

		if (job.inviteOnly || job.interestedUsers.includes(userObject.id) || job.likedBy.includes(userObject.id)) {
			// toDo:
			// invite only we have to get the whole interactions sub-collection to see if it is their first interaction
			// or we can check an invited array?
			isUsersFirstInteraction = false
		}

		newUserInteractionObj[name] = value

		const el = e.target

		el.style.transform = "rotate(20deg)"
		setTimeout(() => {
			el.style.transform = "rotate(-20deg)"
		}, 100)

		setTimeout(() => {
			el.style.transform = "rotate(0deg)"
		}, 200)

		// add or remove blue fill right away so it updates sooner
		if (value === true) {
			el.classList.add("blue-fill")
			const num = parseInt(el.parentElement.nextElementSibling.textContent)
			if (!isNaN(num)) {
				el.parentElement.nextElementSibling.textContent = num + 1
			}
		} else {
			el.classList.remove("blue-fill")
			const num = parseInt(el.parentElement.nextElementSibling.textContent)
			if (!isNaN(num)) {
				el.parentElement.nextElementSibling.textContent = (num > 0) ? (num - 1) : 0
			}
		}

		if (name === "liked") {
			if (value === true) {
				newPostData.likedBy = [
					...newPostData.likedBy || [],
					userObject.id
				]
			} else {
				newPostData.likedBy = (newPostData.likedBy || []).filter(id => id !== userObject.id)
			}
		}

		if (name === "interested") {
			if (value === true) {
				newPostData.interestedUsers = [
					...newPostData.interestedUsers || [],
					userObject.id
				]
			} else {
				newPostData.interestedUsers = (newPostData.interestedUsers || []).filter(id => id !== userObject.id)
			}
		}

		newPostData.interactions = {
			...newPostData.interactions || {},
			[userObject.id]: newUserInteractionObj
		}

		handleUpdatePost({newPostData, userObject, isUsersFirstInteraction, handleSetJobs, filters, doNotPan: true})
	}

	let sectionComponents = sections.map(({name, displayName, displayIcon}) => {

		let sectionItems = []

		sortedSectionizedJobsWithTopPicks.forEach(job => {
			const hasSection = job.sections && job.sections.includes(name)
			const isCitySection = job.city.toLowerCase() === name.toLowerCase()
			const isInMyJobs = job.sections.includes("myJobs")

			if ((hasSection || isCitySection) && (!isInMyJobs || name === "myJobs")) {
				let userHasLikedPost = userObject.id && job.likedBy && job.likedBy.includes(userObject.id)

				let userHasIntrest = userObject.id && job.interestedUsers && job.interestedUsers.includes(userObject.id)

				let budgetNumber
				if (job.budget) {
					budgetNumber = parseInt(job.budget)
				}

				// if (jobInJobs.budget >= 1000) {
				// 	budgetNumber = "900+"
				// }
				sectionItems.push(
					<li className="post-img-container" key={job.id} >
						{
							job.tag ? 
			  			<div className="top-info-tags">
								<span className="info-tag" >{job.tag}</span>
							</div>
							: ""
						}
						<div className="overlay-top" >
							<h2 >{job.title}</h2>
							{
								(job.recommendedScore && name === "topPicks") ? 
									<span className="recommended-score" >
										<strong>{Math.round(job.recommendedScore * 100)}% </strong> match
									</span>
								: ""
							}
						</div>
						<img className="main-photo" alt="" src={(job.photos && job.photos[0]) ? job.photos[0].url : "/assets/fallback-image.jpg"} onError={handleImageOnError} />

						<div className="overlay-bottom" >
							<div className="photo-and-text">
		  					<Link to={`/users/${job.username}`} className="no-link">
		  						<UserPhoto alreadyFetchedUsers={alreadyFetchedUsers} handleSetAlreadyFetchedUsers={handleSetAlreadyFetchedUsers} userId={job.userId} user={null} isFetchingUser={false} />
	  						</Link>
	  						<div>
	  							<p><strong>{job.username}</strong></p>
	  							<div className="engagement-actions">
				  					<div>
					  					<span className={`material-icons ${userHasLikedPost ? 'blue-fill' : ''}`} onClick={(e) => {
						  					return handleLikeOrInterested("liked", !userHasLikedPost, job.id, e)
					  					}}>{"\ue8dc"}</span>
				  					</div>
					  					<div>{job.likedBy ? job.likedBy.length : 0}</div>
					  				<div>
						  				<span className={`material-icons ${userHasIntrest ? 'blue-fill' : ''}`} onClick={(e) => {
						  					return handleLikeOrInterested("interested", !userHasIntrest, job.id, e)
						  				}}>{"\ue766"}</span>
				  					</div>
				  					<div>{job.interestedUsers ? job.interestedUsers.length : 0}</div>
				  					<MoreInfoIcon 
					  					absolute={true} 
					  					custom={`top: -16px; right: 6px;`}
					  					customIcon={
							  				<span className="material-icons" onClick={(e) => {
							  					const textEl = e.target?.parentElement?.nextElementSibling?.firstChild?.firstChild
							  					if (textEl) { 
							  						textEl.textContent = "link copied"
							  					}
							  					const input = document.getElementById(job.id + "-input")
							  					input.select()
													document.execCommand("copy");
													handleSetSuccessText("Link copied");
							  				}}>{"\ue80d"}</span>
											} 
											text="copy link" 
										/>
				  					<div>
					  					<input id={job.id + "-input"} defaultValue={`${window.location.origin}/marketplace/jobs/${job.id}`} readOnly style={{position: "fixed", bottom: 0, opacity: 0}} />
				  					</div>
				  				</div>
									<p>{job.body}</p>
								</div>
							</div>
							<div className="see-more-container">
								<Link onClick={() => {
									const jobInJobs = jobs.find(j => j.id === job.id)
						  		setViewingPost(jobInJobs || job)
						  		// setPostModalOpen(true)
						  		// changePostId(job.id, "jobs")
								}} to={`/marketplace/jobs/${job.id}`} className="link-appearance white">{userObject.id === job.userId ? "View / edit" : "See more"}</Link>
								{ 
									budgetNumber ?
									<div>
										<strong>Budget</strong> {`$${budgetNumber} ${job.budgetIsHourly ? "/h" : "total"}`}
									</div>
									: ""
								}
							</div>
						</div>
					</li>
				)
			}

		})

		const photosThisSection = sortedSectionizedJobsWithTopPicks.map(j => {
			if (j.photos && j.photos[0]) {
				return {
					...j.photos[0],
					url: j.url || "/assets/fallback-image.jpg"
				}
			} else return {
				url: "/assets/fallback-image.jpg"
			}
		})

		if (sectionItems && sectionItems.length) {
			return (
				<Section 
					key={displayName}
					name={name}
					displayIcon={displayIcon}
					displayName={displayName}
					photos={photosThisSection}
				>
					{sectionItems}
				</Section>
			)
		} else return ""

	})


	// add in section components for My Jobs or interacted jobs



  return (
    <MarketplaceListStyle>
    	{sectionComponents}
    </MarketplaceListStyle>
  )
}

export default MarketplaceList