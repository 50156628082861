import styled from "styled-components";

import { pageConstants } from "./pageConstants";

const { pxToPt } = pageConstants;

export const InvoiceStyle = styled.div`
	/*td.just-changed-color, th.just-changed-color, .section-heading.just-changed-color > input {
		background-color: #00ff0059;
    transition: background-color 0.9s cubic-bezier(0, 1.15, 1, 1);
	}*/
	.invoice-status {
		text-align: left;
		position: absolute;
		margin-left: ${({ scaleRatio }) => (12 * pxToPt) / scaleRatio}px;
		margin-top: ${({ scaleRatio }) => (12 * pxToPt) / scaleRatio}px;
		color: var(--chicago);
		font-family: var(--font-family-poppins);
		font-size: ${({ scaleRatio }) => (12 * pxToPt) / scaleRatio}px;
		font-style: normal;
		font-weight: 300;
	}

	.pages-container {
		width: fit-content;
		font-size: ${({ scaleRatio }) => (11 * pxToPt) / scaleRatio}px;
		text-size-adjust: none !important;
		font-family: sans-serif;
		position: relative;
		-webkit-text-size-adjust: 100%;
		margin-top: 25px;
		.invoice-page {
			box-shadow: 0px 4px 31px -14px;
		}
	}
	.print-share-container {
		display: flex;
		flex-direction: row;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
		opacity: 0.4;
		color: var(--chicago);
	}
	.print-share-container:hover {
		opacity: 1;
	}
	.print-share-container > div {
		width: ${({ scaleRatio }) => (55 * pxToPt) / scaleRatio}px;
		display: flex;
		flex-direction: column;
		align-items: center;
		/*box-shadow: 0px 3px 10px -2px;*/
		border-left: outset 2px;
		border-bottom: outset 2px;
	}
	.print-share-container > div 
		> div {
			/*border: outset 2px #adadad;*/
			/*padding: 4px 6px 4px 6px;*/

			padding: 8% 1%;
			cursor: pointer;
			font-size: ${({ scaleRatio }) => (12 * pxToPt) / scaleRatio}px;
			display: flex;
			flex-direction: column;
			align-items: center;
			align-self: stretch;
			/*width: -webkit-fill-available;*/
		}
		> span {
			height: ${({ scaleRatio }) => (26 * pxToPt) / scaleRatio}px;
		}
		.material-icons {
			font-size: ${({ scaleRatio }) => (25 * pxToPt) / scaleRatio}px;
		}
	}

	.controls-container {
		width: 1%;
		height: 100%;
		position: absolute;
		.invoice-toolbar.pin-at-bottom {
			position: absolute;
			bottom: 0;
		}
		.invoice-toolbar {
			display: flex;
			flex-direction: column;
			position: fixed;
			z-index: 2;
			color: white;
			opacity: 0.6;
		}
		.invoice-toolbar:hover {
			opacity: 1;
		}
		.toolbar-hide {
			height: 0;
			overflow: hidden;
		}
		.toolbar-hide > div {
			height: 0;
		}
		.invoice-toolbar > div {
			background-color: gray;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.invoice-toolbar > div > div {
			border: outset 2px #adadad;
			padding: 2px 6px 4px 6px;
			cursor: pointer;
			font-size: ${({ scaleRatio }) => (12 * pxToPt) / scaleRatio}px;
			display: flex;
			flex-direction: column;
			align-items: center;
			align-self: stretch;
			width: -webkit-fill-available;
		}
		.invoice-toolbar > div svg {
			width: ${({ scaleRatio }) => (25 * pxToPt) / scaleRatio}px;
		}
	}
	#invoice-top-section table tbody tr td {
		height: min-content;
		min-height: ${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio}px;
	}
	.invoice-heading-text {
		text-align: right;
	}
	#invoice-heading {
		color: black;
		display: grid;
		// grid-template-columns: 1fr 1fr auto 1fr 1fr;
	  grid-template-columns: 1fr 10fr auto 1fr 1fr;
		.logo {
			font-size: ${({ scaleRatio }) => (30 * pxToPt) / scaleRatio}px;
			text-align: right;
			grid-row: span 2;
			grid-column: span 2;
			img {
				vertical-align: bottom;
				height: ${({ scaleRatio }) => (50 * pxToPt) / scaleRatio}px;
			}
			position: relative;
		}
		.bill-to {
			grid-column: span 4;
			margin: 0 0 ${({ scaleRatio }) => (20 * pxToPt) / scaleRatio}px 0;
			max-width: 70%;
		}
		#edit-logo {
			color: black;
			position: absolute;
			right: -25px;
		}

		#edit-company-info {
			position: relative;
			height: 100%;
			svg {
				color: black;
				position: absolute;
				right: 80px;
				top: -160%;
				padding: 10px;
			}
		}
		#title {
			display: inline-block;
			font-size: ${({ scaleRatio }) => (30 * pxToPt) / scaleRatio}px;
			line-height: ${({ scaleRatio }) => (36 * pxToPt) / scaleRatio}px;
			grid-column: span 3;
			input {
				line-height: inherit;
				border: none;
				font-size: inherit;
				padding: 0;
				margin: 0;
				width: 90%;
			}
		}
		#invoice-info {
			display: flex;
			justify-content: space-between;
			font-size: inherit;
			line-height: ${({ scaleRatio }) => (14 * pxToPt) / scaleRatio}px;
		}
		#companyInfo {
			font-size: inherit;
			line-height: ${({ scaleRatio }) => (14 * pxToPt) / scaleRatio}px;
			grid-column: span 3;
		}

		#invoice-info table {
			font-size: inherit;
			line-height: ${({ scaleRatio }) => (14 * pxToPt) / scaleRatio}px;
		}
		#date-invoice {
			grid-column: span 1;
			margin: 0 0 ${({ scaleRatio }) => (20 * pxToPt) / scaleRatio}px 0;
		}
		#date-invoice table tbody tr td {
			width: ${({ scaleRatio }) => `${(70 * pxToPt) / scaleRatio}px`};
			height: ${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio}px;
		}
		#date-invoice table th input {
			width: ${({ scaleRatio }) => `${(70 * pxToPt) / scaleRatio}px`};
			height: 80%;
		}
	}
	.section-heading {
		height: ${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio}px;
		font-size: ${({ scaleRatio }) => (15 * pxToPt) / scaleRatio}px;
		padding: 0;
		margin: 0;
	}
	.section-heading input {
		border: none;
		font-size: inherit;
		padding: 0;
		margin: 0;
		background-color: inherit;
	}
	.page-spacer {
		height: ${({ scaleRatio, rowHeight }) =>
			rowHeight / scaleRatio}px !important;
		grid-column: span 5;
	}
	.stop div {
		background-color: red;
	}
`;

export const TableStyle = styled.table`
	table-layout: fixed;
	color: black;
	display: grid;
	grid-template-columns: ${({ columns, scaleRatio }) =>
		`repeat(${columns}, auto)`};
	font-size: inherit;
	border: ${({ scaleRatio }) => (1 * pxToPt) / scaleRatio}px solid #a9a9a9;
	min-width: ${({ minWidth }) => minWidth};
	max-width: ${({ maxWidth }) => maxWidth};
	height: 100%;
	th {
		top: 0;
		background-color: #d3d3d3;
		-webkit-print-color-adjust: exact;
		padding: ${({ scaleRatio }) =>
			`${(2 * pxToPt) / scaleRatio}px ${(3 * pxToPt) / scaleRatio}px 0 ${
				(3 * pxToPt) / scaleRatio
			}px`};
		font-size: inherit;
		height: ${({ scaleRatio, rowHeight }) =>
			rowHeight / scaleRatio - (2 * pxToPt) / scaleRatio}px;
	}
	tr.active-row {
		/*below is for if using a listener on any doc changes*/
		/*td {
	    transition: background-color 0s linear;
	    background-color: unset;
		}*/
		:focus-within {
			td {
				background-color: #add8e661;
			}
		}
		:focus {
			td {
				background-color: #add8e661;
			}
		}
		:hover {
			td {
				background-color: #add8e661;
			}
		}
	}
	thead,
	tbody,
	tr {
		display: contents;
		font-size: inherit;
	}
	input {
		background-color: transparent;
		border-spacing: 0;
	}
	.date-entry {
		width: fit-content;
	}

	.itemized-entry {
		position: relative;
		padding: ${({ scaleRatio }) => `0 ${(3 * pxToPt) / scaleRatio}px`};
		font-size: inherit;
		font-style: inherit;
		min-width: ${({ scaleRatio }) => `${(60 * pxToPt) / scaleRatio}px`};
		height: ${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio}px;
		/*below is for if using listener on any doc changes*/
		/*background-color: unset;
    transition: background-color 0.5s cubic-bezier(0, 1.15, 1, 1);*/
		svg.settings-icon {
			padding: 1% 7% 7% 7%;
			height: 90%;
			position: absolute;
			right: -8%;
		}
		textarea {
			background-color: inherit;
		}
	}
	td.itemized-entry > span {
		vertical-align: -webkit-baseline-middle;
	}
	.date-picker {
		width: 100%;
		border: none;
		padding: ${({ scaleRatio }) => `${(1.2 * pxToPt) / scaleRatio}px 0 0 0`};
		font: inherit;
	}
	input.date-picker::-webkit-datetime-edit-day-field,
	input.date-picker::-webkit-datetime-edit-month-field,
	input.date-picker::-webkit-datetime-edit-year-field {
		outline: none;
		padding: 0;
	}
	input.date-picker::-webkit-datetime-edit {
		overflow: visible;
	}
	input.date-picker::-webkit-calendar-picker-indicator {
		margin: 0;
		padding-left: ${({ scaleRatio }) => `${(40 * pxToPt) / scaleRatio}px`};
		outline: none;
	}

	.editable-entry {
		cursor: cell;
		padding: 0;
		margin: 0;
		border: none;
		font-size: inherit;
		font-style: inherit;
		font: inherit;
		width: 98%;
		height: 93%;
		line-height: ${({ scaleRatio, rowHeight }) =>
			(rowHeight - 3) / scaleRatio}px;
		:focus {
			overflow: overlay;
			cursor: auto;
		}
	}

	.verified-payment {
		font-style: italic;
		color: gray;

		td a {
			text-transform: uppercase;
			cursor: pointer;
			vertical-align: middle;
			vertical-align: -webkit-baseline-middle;
		}
		td svg {
			height: 2ch;
			padding: 0 0 0 1ch;
			vertical-align: text-bottom;
		}
	}

	th {
		line-height: ${({ scaleRatio, rowHeight }) =>
			(rowHeight - 3) / scaleRatio}px;
	}

	${({ custom }) => custom && custom};
`;

export const TableSidebarStyle = styled.table`
	table-layout: fixed;
	color: black;
	display: grid;
	grid-template-columns: ${({ columns, scaleRatio }) =>
		`repeat(${columns}, auto)`};
	font-size: inherit;
	border: none;
	width: 0;
	align-items: end;

	thead,
	tbody,
	tr {
		display: contents;
		font-size: inherit;
	}
	th,
	td {
		font-size: inherit;
		background-color: white;
		min-width: 0;

		height: ${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio - 2}px;
		position: relative;
	}
	.td-container {
		padding: 0 5px 0 2px;
		display: inline-flex;
		align-items: flex-end;
		position: absolute;
		height: 100%;
		svg:first-child {
			padding-left: 0.4vw;
		}
		svg {
			height: 100%;
	    padding: ${({ isLessThan700px }) => isLessThan700px ? "0.3vw 0.9vw" : "0.3vw 0.5vw"};
		}
	}
`;

export const InvoicePageStyle = styled.div`
	color: black;
	text-align: left;
	background-color: white;
	margin: ${({ margin }) => margin};
	padding: ${({ paddingTopBottom, paddingLeftRight }) =>
		`${paddingTopBottom}px ${paddingLeftRight}px`};
	width: ${({ invContentWidthPx }) => invContentWidthPx}px;
	height: ${({ invContentHeightPx, paddingTopBottom, paddingLeftRight }) =>
		invContentHeightPx}px;
	font-size: inherit;
	.section-total {
		font-weight: bold;
		display: flex;
		align-content: flex-end;
		padding-top: ${({ scaleRatio }) => `${(5 * pxToPt) / scaleRatio}px`};
		font-size: ${({ scaleRatio }) => (13 * pxToPt) / scaleRatio}px;
		height: ${({ scaleRatio, rowHeight }) =>
			rowHeight / scaleRatio - (5 * pxToPt) / scaleRatio}px;
	}
	.section-total > span::before {
		content: "$ ";
	}
	.section-total span {
		width: 100%;
		text-align: right;
	}
	.section-total div {
		width: 100%;
	}
	.table-container {
		position: relative;
		display: flex;
		align-items: space-evenly;
	}
	.edit {
		font-size: ${({ scaleRatio }) => (13 * pxToPt) / scaleRatio}px;
		top: 0;
		right: ${({ scaleRatio }) => (-50 * pxToPt) / scaleRatio}px;
		position: absolute;
	}
	.toggle-table-visibility {
		font-size: ${({ scaleRatio }) => (11 * pxToPt) / scaleRatio}px;
		position: absolute;
		left: ${({ paddingLeftRight }) => paddingLeftRight / 2}px;
		height: ${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio}px;
		width: auto;
		svg {
			height: ${({ scaleRatio, rowHeight }) =>
				rowHeight / scaleRatio - (5 * pxToPt) / scaleRatio}px;
			width: auto;
		}
	}
	.bump-up-margin {
		margin: -${({ scaleRatio, rowHeight }) => rowHeight / scaleRatio}px 0 0 0;
	}
`;
