import React, { useContext, useState, useEffect, useRef } from 'react'

import { UserContext } from "../../contexts/UserContext";

import { ForwardBillModalStyle } from './ForwardBillModalStyle.styled'
import { AppMessage } from '../MessageUtils'
import Modal from '../Modal/Modal'
import Form from '../Forms/Form'
import SymbolFromId from "../svgComponents/SymbolFromId";
import Spinner from "../Spinner/Spinner";
import ForwardBillInfo from './ForwardBillInfo';

import firebase from "../../firebase/index";
import {
	makeRandomString,
	removeDuplicates,
	getNum,
	// providerIsFacebookOrGoogle,
} from "../../utils/appUtils";
import {
	makeCopyOfInvoice,
	matchesDefaultEntry,
	cloneInvoiceSubCollections,
} from '../Invoice/invoiceUtils'


const ForwardBillModal = ({forwardBillModal, setForwardBillModal, editedInv, invoiceTotals, forwardedBill, setForwardedBill, invoiceTotalStr}) => {
	const {
		userObject,
		setUserObject,
		usersInvoices,
		setUsersInvoices,
		handleSetMessage,
		handleSetSuccessText,
		setErrorObj,
		setLoginModalOpen,
	} = useContext(UserContext);


	const user = firebase.auth().currentUser;
	const isMounted = useRef(false);
	const invoice = editedInv || forwardBillModal.invoice

	const [isLoadingSymbol, setIsLoadingSymbol] = useState(false);

	let loadingFirebaseIdTimer;

	// const disableInputs = (
	// 	!user ||
	// 	!user.emailVerified
	// ) && !providerIsFacebookOrGoogle(user);
	const disableInputs = !user


	// if there is no paired invoice yet
	let defaultFormdata = {
		billTo: {
			name: "",
			firstname: "Customer",
			lastname: "",
			address: {
				city: "",
				country: "",
				line1: "",
				line2: "",
				postal_code: "",
				state: "",
			},
			username: "",
			uid: "",
		},
		// contractor: { ...defaultFormDataContractorObj },
		// dateOfLastPayment: "",
		// dateOfNextPayment: "",
		// endWorkTodayAt: "",
		// industry: userObject.industries.length ? userObject.industries[0].name : "",
		invNumber: parseInt(userObject.lastInvNumber || 100) + 1,
		invShortHand: makeRandomString("", 3),
		rate: userObject.industries.length ? userObject.industries[0].rate : "",
		// leviedInvoiceOptions
		doNotUpdate: [
			"description", 
			"industry",
			"paymentEntries",
			// "summaryEntries"
			// laborEntries.date
		],
		condenseEntriesIn: ["laborEntries"],
	}

	// const defaultPairedInvoiceOptions = {
	// 	condenseEntriesIn: defaultFormdata.condenseEntriesIn,
	// 	contractor: invoice.contractor,
	// 	deleted: false,
	// 	doNotUpdate: defaultFormdata.doNotUpdate,
	// 	editors: removeDuplicates([
	// 		...invoice.editors,
	// 		invoice.owner,
	// 		invoice.contractor.id
	// 	]),
	// 	invNumber: invoice.invNumber,
	// 	invShortHand: invoice.invShortHand,
	// 	lastUpdated: firebase.firestore.Timestamp.now().seconds,
	// 	unpaired: false,
	// }

	const [formData, setFormData] = useState({
		...defaultFormdata,
		// pairedInvoiceOptions: defaultPairedInvoiceOptions,
	})

	const [isPairingToExisting, setIsPairingToExisting] = useState(false)

	const [levyToInvoiceDoc, setLevyToInvoiceDoc] = useState(null)
	const [levyToExistingInvoiceOptions, setLevyToExistingInvoiceOptions] = useState([
		{name: "Select one", val: ""}
	])

	const findTableName = (tableKey, defaultName) => {
		let newName = defaultName
		let headingName = tableKey.replace("Entries", "Heading")
		if (tableKey === "description") {
			const pageOrderItem = invoice.topSectionPageOrder.find(item => item.for === tableKey)
			newName = (pageOrderItem && pageOrderItem.tableHeadings && pageOrderItem.tableHeadings[0] && pageOrderItem.tableHeadings[0].val) ? pageOrderItem.tableHeadings[0].val : defaultName
		} else {
			const pageOrderItem = invoice.pageOrder.find(item => item.for === headingName)
			newName = (pageOrderItem && pageOrderItem.val) ? pageOrderItem.val : defaultName
		}

		return newName
	}

	const sortByRecentEntryDate = (list) => {
		// sorts by mostRecentEntryDate with newest date first
		let sortableItems = [];
		let nonSortableItems = [];

		list.forEach((item) => {
			if (item.mostRecentEntryDate) {
				sortableItems.push(item);
			} else {
				nonSortableItems.push(item);
			}
		});

		sortableItems = sortableItems.sort((a, b) => {
			const d1 = new Date(a.mostRecentEntryDate);
			const d2 = new Date(b.mostRecentEntryDate);
			// sort by most recent on top
			return d2 - d1;
		});

		return [...sortableItems, ...nonSortableItems];
	};

	const handleChangeDoNotUpdate = (name, val) => {
		let newFormData = {}

		if (val) {
			newFormData = {
				...formData,
				doNotUpdate: formData.doNotUpdate.filter(listItem => listItem !== name)
			}
		} else {
			newFormData = {
				...formData,
				doNotUpdate: [...formData.doNotUpdate, name]
			}
		}
		setFormData(newFormData)
	}

	const onClickOutside = () => {
		setForwardBillModal(forwardBillModal => ({...forwardBillModal, open: false}))
	}

	const handleChange = (nameOrId, value) => {
		let newFormData = {...formData}
		if (nameOrId === "billToFirstname") {
			newFormData.billTo = {
				...newFormData.billTo,
				firstname: value
			}
		} else if (nameOrId === "billToLastname") {
			newFormData.billTo = {
				...newFormData.billTo,
				lastname: value
			}
		} else {
			newFormData[nameOrId] = value
		}

		setFormData(newFormData)
	}

	const handleChangeLevyTo = (pairToExisting) => {
		if (pairToExisting) {
			setIsPairingToExisting(true)
			handleLevyToInvoiceSelected("", (levyToInvoiceDoc && levyToInvoiceDoc.id) ? levyToInvoiceDoc.id : "")
		} else {
			setIsPairingToExisting(false)
			// reset the form data
			setFormData(defaultFormdata)
		}
	}

	const handleLevyToInvoiceSelected = (name, val) => {
		const leviedInvoiceChosen = (usersInvoices && usersInvoices.length) ? usersInvoices.find(inv => inv.id === val) : null 
		if (!leviedInvoiceChosen) {
			if (usersInvoices && usersInvoices.length) {
				setErrorObj({message: "Sorry an unknown error occurred with that invoice. Please try selecting another or select New Invoice"})
			}
		} else {
			setLevyToInvoiceDoc(leviedInvoiceChosen)
			let newFormData = {
				...defaultFormdata,
				id: leviedInvoiceChosen.id,
				billTo: leviedInvoiceChosen.billTo,
				rate: leviedInvoiceChosen.rate,
				invShortHand: leviedInvoiceChosen.invShortHand,
				invNumber: leviedInvoiceChosen.invNumber,
			}

			// if the levied invoice chosen already has this invoice as a paired invoice
			if (leviedInvoiceChosen.pairedInvoiceOptions && Object.keys(leviedInvoiceChosen.pairedInvoiceOptions).length) {
				// if the current invoice is already paired to selected one copy options
				let currentPairedOptions = leviedInvoiceChosen.pairedInvoiceOptions[invoice.id]
				if (!currentPairedOptions) {
					// pick any of the paired invoice options to use as template
					const firstKey = Object.keys(leviedInvoiceChosen.pairedInvoiceOptions)[0]
					currentPairedOptions = leviedInvoiceChosen.pairedInvoiceOptions[firstKey]
					// remove payment entries
					if (!currentPairedOptions.doNotUpdate.includes("paymentEntries")) {
						currentPairedOptions.doNotUpdate = [...currentPairedOptions.doNotUpdate, "paymentEntries"]
					}
				}

				// set the settings to the current paired invoice options 
				newFormData = {
					...newFormData,
					doNotUpdate: currentPairedOptions.doNotUpdate,
					condenseEntriesIn: currentPairedOptions.condenseEntriesIn,
				}
			} 

			// formData.pairedInvoices = removeDuplicates([...newFormData.pairedInvoices || [], invoice.id])

			setFormData(newFormData)
		}
		// change the form data
	}

	const getNewBillTo = (editedInv, userObject) => {
		let newBillTo;

		if (editedInv.billTo && editedInv.billTo.uid) {
			// overwrite billTo info with userObject info if they have that info
			newBillTo = {
				...editedInv.billTo,
				...(userObject.firstname && { firstname: userObject.firstname }),
				...(userObject.lastname && { lastname: userObject.lastname }),
				// ...(userObject.private.address && { address: userObject.address }),
				// ...(userObject.private.address.addressString && {
				// 	userFullAddress: userObject.private.address.addressString,
				// }),
				username: userObject.username,
				uid: userObject.id,
			};
		} else {
			newBillTo = {
				firstname: userObject.firstname || (editedInv.billTo && editedInv.firstname) || "",
				lastname: userObject.lastname || (editedInv.billTo && editedInv.lastname) || "",
				// address: userObject.private.address || (editedInv.billTo && editedInv.address) || {},
				// userFullAddress: userObject.private.address.addressString || (editedInv.billTo && editedInv.userFullAddress) || "",
				username: userObject.username || "",
				uid: userObject.id || "",
			};
		}

		return newBillTo
	}

	const handleSubmit = async (e) => {
		// can only have one Levied invoice but many paired invoices
		// user can only levy invoice if no levied inv yet and they must be a billTo
		try {
			e.preventDefault()

			if (disableInputs) {
				if (!user) {
					setLoginModalOpen(true)
				} else {
					setErrorObj({
						message: "Must verify email to levy an invoice",
						noReport: true,
					});
				}

				return false;
			}

			let updateInvoiceItems = {
				leviedInvoice: formData.id,
				// pairedInvoiceOptions[id] and leviedInvoiceOptions have some of same data
				// if we are going to include leviedInvoiceOptions in the subcontractors invoice we need to prevent them from updating it
				// which may be a problem when subcontractor uses handleSave ... could use rules though
				// this is still much easier than trying to update if levied bill allows it or update blind then subtract entries upon levied bill render
				leviedInvoiceOptions: {
					// contractor dont need this only need it in the levied invoice
					doNotUpdate: formData.doNotUpdate,
					condenseEntriesIn: formData.condenseEntriesIn,
				},
				accessors: firebase.firestore.FieldValue.arrayUnion(userObject.id)
			}
			if (!editedInv.billTo.uid || editedInv.billTo.uid !== userObject.id) {
				updateInvoiceItems = {
					...updateInvoiceItems,
					billTo: getNewBillTo(invoice, userObject),
				}
				// run the accept bill function 
				// add the current user to the bill as billTo and add the forwardedInvoice feilds
				// so they can edit
				// only add them as billTO if there are no accept bill/ add payment notifications
			}

			// update the subcontractors invoice
			await firebase.firestore().collection("invoices").doc(invoice.id).update({
				...updateInvoiceItems
			}).catch(err => {
				err.messagetwo = "in updateInvoiceItems, forwardBillModal"
				throw err
			})

			// make the invoice labor entries with the new rate
			let newLaborEntries = []
			if (invoice.laborEntries) {
				newLaborEntries = editedInv.laborEntries.map(ent => {
					let shouldAddEntry = true
					let newEntry = {...ent}
					if (ent.isDefaultEntry) {
						// check if actually default ... if it matches default entry
						shouldAddEntry = !(matchesDefaultEntry({entry: ent, collection: "laborEntries", invoice}))
					}

						// check if should include this entry
						if (shouldAddEntry) {
							// get entry values  with the new rate
							const qty = ent.values.find(obj => obj.for === "qty").val
							const newValues = ent.values.map(obj => {
								if (obj.for === "cost") {
									return {
										...obj,
										val: parseFloat(formData.rate),
									}
								} else if (obj.for === "total") {
									return {
										...obj, 
										val: parseFloat(getNum(parseFloat(qty) * parseFloat(formData.rate), 2)),
									}
								// } else if (obj.for === "qty") {
								// 	return {
								// 		...obj,
								// 		autoUpdated: true
								// 	}
								} else return obj
							})

							// add in new values
							newEntry = {
								...newEntry,
								values: newValues
							}

							// set the new values into entry.property
							newValues.forEach(td => {
								newEntry[td.for] = td.val
							})

							return newEntry
						// dont add the entry
						} else return false

				})
			}

			// filter out null entries
			newLaborEntries = newLaborEntries.filter(isTruthy => isTruthy)

			let inv = {
				...invoice, // keep as much of the subcontractors invoice untouched so we can compare it 
				...updateInvoiceItems,
				// billTo: formData.billTo,
				// rate: formData.rate,
				// laborEntries: newLaborEntries,
				// editors that can not view and can only edit certain fields
				restrictedEditors: removeDuplicates([
					...invoice.editors,
					invoice.owner,
					invoice.contractor.id
				])
			}

			if (isPairingToExisting) {
				// update the levied invoicemain section
				const leviedInvRef = firebase.firestore().collection("invoices").doc(levyToInvoiceDoc.id)
				await leviedInvRef.set({
					pairedInvoices: firebase.firestore.FieldValue.arrayUnion(invoice.id),
					// isForwardedBill: true,
					pairedInvoiceOptions: { // subcontractor shouldnt be able to edit this
						[invoice.id]: {
							condenseEntriesIn: formData.condenseEntriesIn,
							contractor: inv.contractor,
							deleted: false,
							doNotUpdate: formData.doNotUpdate,
							editors: inv.restrictedEditors,
							invNumber: inv.invNumber,
							invShortHand: inv.invShortHand,
							lastUpdated: firebase.firestore.Timestamp.now().seconds,
							unpaired: false,
						}
					},
					// inv.restrictedEditors is created in forwardBillModal handleSave
					restrictedEditors: firebase.firestore.FieldValue.arrayUnion(...inv.restrictedEditors)
				}, { merge: true })
				// update subcollections
				const newFirebaseDoc = leviedInvRef // not a "new" firebase doc in this case
				await cloneInvoiceSubCollections({invoice, newFirebaseDoc, type: "forwardBill", newLaborEntries})

			} else {
				// copy the bill but change the owner, contractor, etc to the current user
				const fd = await makeCopyOfInvoice({
					// i,
					type: "forwardBill",
					inv,
					pairedInvFd: formData,
					whenLoading: () => null,
					userObject,
					newLaborEntries,
					setUserObject,
				});

				setUsersInvoices(usersInvoices => {
					return [
						...usersInvoices || [],
						fd
					]
				})

			}

			onClickOutside()
			// handleSetSuccessText("Invoice has been successfully paired and levied!", 6000)
			handleSetSuccessText(
				<div>
					<div>{`This invoice has been successfully paired and levied as Invoice #${formData.invNumber} (${formData.invShortHand})`}</div>
				</div>,
				8000
			);
		} catch(err) {
			setErrorObj(err)
		}
	}

	useEffect(() => {
		isMounted.current = true;

		return () => {
			if (loadingFirebaseIdTimer) {
				clearTimeout(loadingFirebaseIdTimer);
			}
			isMounted.current = false;
		};
	}, [loadingFirebaseIdTimer]);


	useEffect(() => {
		if (user) {
			// if (!user.emailVerified && !providerIsFacebookOrGoogle(user)) {
			// 	handleSetMessage(
			// 		<React.Fragment>
			// 			<div className="error-text">
			// 				Sorry, you must verify email to levy/pair this invoice (this helps prevent
			// 				spam)
			// 			</div>
			// 			<div
			// 				onClick={() => {
			// 					user
			// 						.sendEmailVerification()
			// 						.then(() => {
			// 							handleSetSuccessText("Email verification link sent");
			// 						})
			// 						.catch((err) => setErrorObj(err));
			// 				}}
			// 				className="link-appearance blue"
			// 			>
			// 				Re-send verification link{" "}
			// 			</div>
			// 			to {user.email}
			// 		</React.Fragment>
			// 	);
			// }
		} else {
			handleSetMessage(
					<React.Fragment>
						<div className="error-text">
							{"Please "}
							<span onClick={() => setLoginModalOpen(true)} className="link-appearance blue">
							Log In
							</span>
							{" To Pair / Levy this invoice"}
						</div>
					</React.Fragment>
				);
		}

		return () => {
			handleSetMessage("");
		};
		// want to make sure this error message is only set on first render and not set if other messages set
		// eslint-disable-next-line
	}, [user]);

	// set the firebase ID
	useEffect(() => {
		if (firebase && !isPairingToExisting) {
			setFormData((formData) => ({
				...formData,
				id: firebase.firestore().collection("invoices").doc().id,
			}));
		}
		// eslint-disable-next-line
	}, [isPairingToExisting]);

	// useEffect(() => {
	// 	if (!loginModalOpen && !user && !message) {
	// 			setErrorObj({
	// 				message: "Must be logged in to levy an invoice",
	// 				noReport: true,
	// 			});
	// 	}
	// }, [loginModalOpen])

	// const industryOptions = getNewIndustryOptions(userObject.industries)
	// const disableInputs =
	// 	(!firebase.auth().currentUser ||
	// 		!firebase.auth().currentUser.emailVerified) &&
	// 	!providerIsFacebookOrGoogle(user);

	// let user know that they cant create invoices if they arent logged in and or their email isnt verified
	// useEffect(() => {
	//   if (firebase.auth().currentUser) {
	  	
	//   }
	// }, [])

	const newInvoiceTotals = (
		() => {
		let newHours = 0
		let newCost = 0
		let laborCost = 0

		newHours += invoiceTotals.laborEntries ? getNum(invoiceTotals.laborEntries.qty, 100) : 0
		laborCost += invoiceTotals.laborEntries ? getNum(invoiceTotals.laborEntries.total, 100) : 0
		newCost += invoiceTotals.laborEntries ? getNum(invoiceTotals.laborEntries.total, 100) : 0
		newCost += invoiceTotals.materialEntries ? getNum(invoiceTotals.materialEntries.total, 100) : 0
		newCost += invoiceTotals.customEntries ? getNum(invoiceTotals.customEntries.total, 100) : 0
		return {
			hours: newHours,
			cost: newCost,
			laborCost
		}
	})()

	const forwardedTotal = newInvoiceTotals.hours * formData.rate + (newInvoiceTotals.cost - getNum(newInvoiceTotals.laborCost, 100)) 		

	const subcontractorRate = newInvoiceTotals.laborCost / newInvoiceTotals.hours 
	const profit = forwardedTotal - newInvoiceTotals.cost

	useEffect(() => {
		if (user && userObject.id) {
			const invoicesWhereUserIsContractor = (usersInvoices && usersInvoices.length) ? usersInvoices.filter(inv => inv.contractor.id === userObject.id) : []
			// filter out any invoices already paired
			const invoicesNotAlreadyPairedToThisOne = invoicesWhereUserIsContractor.filter(inv => {
				let isInPairedInvoicesList = false
				let isPairedInInvoiceOptionsList = false

				// check if properly paired
				if (inv.pairedInvoices && inv.pairedInvoices.length) {
					isInPairedInvoicesList = inv.pairedInvoices.includes(invoice.id)
				}
				if (inv.pairedInvoiceOptions && inv.pairedInvoiceOptions[invoice.id]) {
					// check if it has been marked as unpaired... it should not be in pairedInvoices but this is failsafe in case there was an error in removing it from pairedInvoices
					// if inv is marked as deleted the button to open this modal should never have opened
					if (inv.pairedInvoiceOptions[invoice.id].unpaired) {
						isPairedInInvoiceOptionsList = false
					} else {
						isPairedInInvoiceOptionsList = true
					}
				}
				// only filter out if it is fully and properly paired or the inv is the current invoice being viewed
				if ((isInPairedInvoicesList && isPairedInInvoiceOptionsList) || inv.id === invoice.id) {
					return false
				} else return true
			})

		  if (invoicesNotAlreadyPairedToThisOne && invoicesNotAlreadyPairedToThisOne.length) {
		  	// sort by recent entrys
		  	let sortedList = sortByRecentEntryDate(invoicesNotAlreadyPairedToThisOne)

		  	// dort by most likely to choose
		  	const invoicesWithPairedInvoices = sortedList.filter(inv => inv.pairedInvoices && inv.pairedInvoices.length)
		  	const invoicesWithoutPairedInvoices = sortedList.filter(inv => !(inv.pairedInvoices && inv.pairedInvoices.length))

		  	sortedList = [
		  		...invoicesWithPairedInvoices,
		  		...invoicesWithoutPairedInvoices,
		  	]

				const usersInvoiceOptionsList = sortedList.map((inv) => ({
					name: `Invoice #${inv.invNumber} ${inv.invShortHand}`,
					val: inv.id,
				}));
				if (usersInvoiceOptionsList.length) {
					if (!levyToInvoiceDoc) {
						setLevyToInvoiceDoc(sortedList[0])
						setLevyToExistingInvoiceOptions(usersInvoiceOptionsList)
					}
				}
			}
			
		} else {
			// if user is not logged in 
			setFormData(formData => ({
				...formData,
				rate: getNum(subcontractorRate * 1.1, 2)
			}))
		}

	}, [usersInvoices, userObject, invoice.id, levyToInvoiceDoc, subcontractorRate, user])

	const getDoNotUpdateOptions = () => {
		return [
			{
				label: "Auto update Invoice " + findTableName("description", "Description"),
				onChange: (name, val) => handleChangeDoNotUpdate(name, val),
				properties: {
					type: "checkbox",
					id: "description",
					value: formData.doNotUpdate.includes("description"),
					checked: !formData.doNotUpdate.includes("description"),
					// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
				},
			},
			{
				label: "Auto update Invoice Industry",
				onChange: (name, val) => handleChangeDoNotUpdate(name, val),
				properties: {
					type: "checkbox",
					id: "industry",
					value: formData.doNotUpdate.includes("industry"),
					checked: !formData.doNotUpdate.includes("industry"),
					// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
				},
			},
			{
				label: "Auto update Invoice " + findTableName("materialEntries", "Material") + " Entries",
				onChange: (name, val) => handleChangeDoNotUpdate(name, val),
				properties: {
					type: "checkbox",
					id: "materialEntries",
					value: formData.doNotUpdate.includes("materialEntries"),
					checked: !formData.doNotUpdate.includes("materialEntries"),
					// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
				},
			},
			{
				label: "Auto update Invoice " + findTableName("laborEntries", "Labor") + " Entries",
				onChange: (name, val) => handleChangeDoNotUpdate(name, val),
				properties: {
					type: "checkbox",
					id: "laborEntries",
					value: formData.doNotUpdate.includes("laborEntries"),
					checked: !formData.doNotUpdate.includes("laborEntries"),
					// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
				},
			},
			{
				label: "Auto update Invoice custom " + findTableName("summaryEntries", "Summary") + " Entries",
				onChange: (name, val) => handleChangeDoNotUpdate(name, val),
				properties: {
					type: "checkbox",
					id: "summaryEntries",
					value: formData.doNotUpdate.includes("summaryEntries"),
					checked: !formData.doNotUpdate.includes("summaryEntries"),
					// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
				},
			},
			{
				label: "Auto update Invoice custom " + findTableName("paymentEntries", "Payment") + " Entries ",
				onChange: (name, val) => handleChangeDoNotUpdate(name, val),
				properties: {
					type: "checkbox",
					id: "paymentEntries",
					value: formData.doNotUpdate.includes("paymentEntries"),
					checked: !formData.doNotUpdate.includes("paymentEntries"),
					// disabled: (!userIsBillTo && !userIsOwner) || formData.closed,
				},
				moreInfoIcon: {
					after: <span className="tiny"> (Not reccomended) </span>,
					absolute: true,
					text:"Only manually added payment entries will be updated. Verified Payment entries cannot be transfered"		
				}
			},
		]
	}


	let inputs = [
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "LevyTo",
				onChange: () => null,
			},
			beforeInput: [
				<div key="billTo">
					<div className="section-divider" />
					<div className="section-title">Levy To</div>
				</div>
			]
		},
		{
			label: "New Invoice",
			onChange: () => handleChangeLevyTo(false),
			properties: {
				type: "radio",
				id: "pairToNew",
				name: "pairToExisting",
				value: false,
				checked: !isPairingToExisting,
			},
		},
		{
			label: "Existing Invoice",
			onChange: () => handleChangeLevyTo(true),
			properties: {
				type: "radio",
				id: "pairToExisting",
				name: "pairToExisting",
				value: true,
				checked: isPairingToExisting,
			},
		},
		{
			custom: true,
			label: "Select invoice",
			visible: isPairingToExisting,
			properties: {
				type: "dropdown",
				name: "id",
				options: levyToExistingInvoiceOptions,
				optionVal: "val",
				value: levyToInvoiceDoc ? levyToInvoiceDoc.id : "",
				onChange: handleLevyToInvoiceSelected,
				// onChange: (name, val) => setLevyToInvoiceDoc(levyToExistingInvoiceOptions.find(inv => inv.id === val)),
			},
		},

		// pairedInvoice options
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "pairedInvoiceOptions",
				onChange: () => null,
			},
			beforeInput: [
				<div key="billTo">
					<div className="section-divider" />
					<div className="section-title">Options</div>
				</div>
			]
		},
		...isPairingToExisting ? getDoNotUpdateOptions() : [],
		{
			label: "First Name",
			onChange: handleChange,
			visible: !isPairingToExisting,
			properties: {
				type: "text",
				id: "billToFirstname",
				value: formData.billTo.firstname,
				// disabled: disableInputs,
			},
		},
		{
			label: "Last Name",
			onChange: handleChange,
			visible: !isPairingToExisting,
			properties: {
				type: "text",
				id: "billToLastname",
				value: formData.billTo.lastname,
				// disabled: disableInputs,
			},
		},
		{
			label: "Your hourly rate",
			onChange: handleChange,
			visible: !isPairingToExisting,
			properties: {
				type: "number",
				id: "rate",
				value: formData.rate,
				// disabled: disableInputs,
			},
			beforeInput: [
				<div key="afterRate">
					<div className="section-divider" />
					<div className="section-title">Charge to {formData.billTo.firstname}</div>
				</div>
			],
		},
		{
			label: "Invoice #",
			onChange: handleChange,
			visible: !isPairingToExisting,
			properties: {
				type: "number",
				id: "invNumber",
				value: formData.invNumber,
				required: true,
				// disabled: disableInputs,
			},
			beforeInput: [
				<div key="invoice-identification">
					<div className="section-divider" />
					<div className="section-title">Identification</div>
				</div>
			]
		},
		{
			label: "3 letter quick ID",
			onChange: handleChange,
			visible: !isPairingToExisting,
			properties: {
				type: "text",
				id: "invShortHand",
				value: formData.invShortHand,
				maxLength: 3,
				minLength: 3,
				required: true,
				// disabled: disableInputs,
			},
			afterInput: [
				<div key="symbolFromId" className="invoice align-left">
					<div className="section-divider" />
					<div key="SymbolTitle">Invoice Icon:</div>
					<div className="small-line-break" />
					<div className="symbol-container">
						{isLoadingSymbol && (
							<div className="spinner">
								<Spinner position="relative" height="80px" width="80px" />
							</div>
						)}
						<div>
							<SymbolFromId
								id={formData.id || "loading"}
								maxCoordinate={31}
							/>
						</div>
					</div>
				</div>,
				<div key="generateSymbolId">
					<div className="small-line-break" />
					<button
						className="button-appearance tiny no-icon-or-underline"
						disabled={isLoadingSymbol}
						onClick={(e) => {
							e.preventDefault();
							setIsLoadingSymbol(true);
							// dont use handle change here
							setFormData((formData) => ({
								...formData,
								id: firebase.firestore().collection("invoices").doc().id,
							}));
							// make a timeout to prevent spam generation of firebase random ids
							const resTime = Math.round(1000 + 1000 * Math.random());
							loadingFirebaseIdTimer = setTimeout(() => {
								if (isMounted.current) {
									setIsLoadingSymbol(false);
								}
							}, resTime);
						}}
					>
						Generate
					</button>
				</div>,
				<div key="afterSymbolId" className="section-divider" />,
			],
		},
	]

	// if (disableInputs) {
	// 	inputs = inputs.map(input => {
	// 		return {
	// 			...input,
	// 			properties: {
	// 				...input.properties,
	// 				onClick: () => {
	// 					setLoginModalOpen(true)
	// 				}
	// 			}
	// 		}
	// 	})
	// }

  return (
    <Modal custom={{ absolute: true }} onClickOutside={onClickOutside} >
	    <div className="heading" >
		    Pair/Levy Invoice
	    </div>
			<AppMessage dependants={[formData]} scrollIntoView={true} />
	    <ForwardBillModalStyle>
			<ForwardBillInfo userObject={userObject} invoiceTotals={newInvoiceTotals} forwardedTotal={forwardedTotal} subcontractorRate={subcontractorRate} profit={profit} editedInv={editedInv} formData={formData} forwardedBill={forwardedBill} getNum={getNum} />
			{
				// <details className="invoice-info " key="existingInvoiceInfo">
				// 	<summary className="section-title">{`Invoice #${invoice.invNumber} (${invoice.invShortHand})`}
				// 	</summary>
				// 		<p>
				// 			<a href={`/users/${invoice.contractor.username}`} target="_blank" rel="noopener noreferrer">
				// 				<img src={invoice.contractor.logoUrl || "/assets/blank-profile-picture.png"} />
				// 				<em>{subcontractor}</em>
				// 			</a>
				// 		</p>
				// 		<p>
				// 			{`${subcontractor}'s rate: `}
				// 			<em>{`$${invoice.rate} /h`}</em>
				// 		</p>
				// 		<p>
				// 			{`${subcontractor}'s hours to date: `}
				// 			<em>{newInvoiceTotals.hours}</em>
				// 		</p>
				// 		<p>
				// 			{"Labor cost: "}
				// 			<em>{`$ ${getNum(invoice.newInvoiceTotals.laborEntries.total, 100) || 0}`}</em>
				// 		</p>
				// 		<p>
				// 			{"Materials cost: "}
				// 			<em>{`$ ${getNum(invoice.newInvoiceTotals.materialEntries.total, 100) || 0}`}</em>
				// 		</p>
				// 		<p>
				// 			{`Other costs`}
				// 			<em>{`$ ${getNum(invoice.newInvoiceTotals.customEntries.total, 100) || 0}`}</em>
				// 		</p>
				// 		<p>
				// 			{"Total: "} 
				// 			<em>{`$ ${newInvoiceTotals.cost}`}</em>
				// 		</p>
				// </details>
			}
				<Form 
					heading=""
					submitName={isPairingToExisting ? "Pair Invoice" : "Levy Invoice"}
					onSubmit={handleSubmit}
					inputs={inputs}
				>
				</Form>
			</ForwardBillModalStyle>
    </Modal>
  )
}

export default ForwardBillModal