import React from "react";
import styled from "styled-components";

const AboutStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	img:hover + figcaption {
    opacity: 0.7;
	}
	figcaption {
		opacity: 0;
		background-color: white;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		// color: black;
	}
	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
	}
	.img-container {
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
	}
	ul {
		text-align: left;
	}
	.fk-the-system {
		position: relative;
		img {
			max-height: 400px;
			max-width: 100%;
		}
	}
	.team {
		margin: 0;
		text-align: left;
		list-style: none;
		font-size: var(--font-size-m);
		p {
			margin: 0;
			// font-size: var(--font-size-s);
		}
		.img-container {
			// max-width: unset;
			// max-height: unset;
			display: inline-flex;
			img {
				height: 100%;
				width: 100%;
			}
		}
		li {
			display: flex;
			margin-bottom: 100px;
		}
	}
	.big-quote {
    position: relative;
    height: 15px;
    width: 25px;
    display: inline-block;
    margin-left: -4px;
		span {
	    font-size: var(--font-size-xxl);
	    position: absolute;
	    top: -12px;
	    left: 0;
		}
	}
	.founder-story {
		text-align: left;
		background-color: var(--off-white);
		border-radius: 20px;
		color: var(--chicago);
		padding: 25px 40px;
		font-size: var(--font-size-s);
		font-style: italic;
		box-shadow: 0 0 25px -10px;
		.story-header {
			display: flex;
			margin-bottom: 20px;
			img {
				height: 80px;
				border-radius: 50%;
				margin-right: 10px;
			}
			div {
				display: flex;
				flex-direction: column;
		    margin-top: auto;
		    font-size: var(--font-size-m);
			}
		}
		p {
			margin: 0;
		}
	}
`;

const About = () => {
	return (
		<AboutStyle>
			<div className="main-container">
				<div className="large">Linvo</div>
				<div>V 3.9.3</div>
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />

				<p>
					Linvo Technologies is a Vancouver Island based tech company, founded to help Homeowners and Building Managers connect with those hard to find, highly efficient small contractors and to help these contractors grow into modern industry leaders.
				</p>
				<div className="section-divider" />
				<div className="section-divider" />

				<h2>Our Team</h2>
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />
				<ul className="team">
					<li>
						<div className="img-container"><img alt="" src="/assets/linvo-founder.png" /></div>
						<div>
							<p><strong>Titus</strong></p>
							<p>Founder</p>
							<p>Electrician, Web Developer</p>
						</div>
					</li>
					<li>
						<div className="img-container"><img alt="" src="/assets/Maksym.png" /></div>
						<div>
							<p><strong>Maksym</strong></p>
							<p>Web developer</p>
						</div>
					</li>
					<li>
						<div className="img-container"><img alt="" src="/assets/Yulia.jpg" /></div>
						<div>
							<p><strong>Yulia</strong></p>
							<p>Design expert</p>
						</div>
					</li>
					<li>
						<div className="img-container"><img alt="" src="/assets/pecode.svg" /></div>
						<div>
							<p><strong>Pecode Software</strong></p>
							<p>Android, IOS and web development</p>
						</div>
					</li>
				</ul>
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />
				<h2>Linvo Origin</h2>

{/*
				<h2>Our Why</h2>
				<div className="fk-the-system">
					<img src="/assets/the-system.jpg" alt="The ugly, inefficient construction industry system" />
					<figcaption>The system</figcaption>
				</div>
				<h4>In short;</h4>
				<p>
					In BC, Canada: The average contractor hourly rate is $66/h. The average construction worker's hourly rate is less than half of this yet the average contractor profit margin is only 5.5%. 
					Except for large projects, most service work and residential projects can be done by an independent contractor or a small team of independent contractors under a general contractor. 
					<br/>
					<br/>
					With the lack of responsibility the current industry's system creates, we often 
					see horrible work being done at the expense of the customer. Many times this work is actually done by a decent worker simply under pressure from a boss who's struggling to keep their business above water.
					A re-modeled construction industry with independent contractors instead of employees can greatly combat this problem and provide huge benefits that would:
			  </p>
					<ul>
						<li>Give workers more freedom to choose the work they want to do</li> 
						<li>Unlock incredible workers from their cage of employment, allowing them to reach their full potential</li>
					  <li>Give customers and contractors a greater amount of incredible, specialized workers to choose from</li>				
					  <li>Increase pay to workers and decrease cost to customers at the same time by cutting out the middleman for small jobs and increasing efficiency in bigger jobs</li>
					</ul>*/}
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="founder-story">
					<div className="story-header">
						<img alt="" src="/assets/linvo-founder.png" />
						<div>
						<p>Titus</p>
						<p>Linvo Founder</p>
						<p>In the construction industry for over 10 years</p>
						</div>
					</div>
					<div className="section-divider" />
					<p>How it all started...</p>
					<div className="small-line-break" />
					<p>
						<span className="big-quote">
							<span>"</span>
						</span>
						{/*As I stuck my head up into the attic for the first look, I was soon reminded about how much I hated my job. It was a hot summer day in 2018 in a small town just north of where I lived. Today, Saturday, was the only day I had to get the kitchen all wired up in time for drywall. I brushed away the cobwebs, placed my hands on either side of the hatch and hoisted myself up. As I army crawled my way around a corner to the area above the kitchen, my inaudible cursing became increasingly more audible in direct proportion to the mess I saw. From free-air splicing to in-accessible junction boxes, I realized there was a lot of work ahead of me. The home had clearly gone through multiple renovations and additions. But it wasn’t the actual work that was causing my grief. It was that I would be the one who “touched it last”. I took a deep breath of insulation dust and turned on to my side to give my neck a rest and condemn myself for choosing such a career. Before touching anything, I'd need to tell the homeowner.*/}
						I initially wanted to build a contracting business, but while doing the paperwork for small jobs, I got fed up with all the different platforms for invoicing and decided to make my own. Traditional invoicing just didn't work well for things like renovations or ongoing work. Like most small contractors, I couldn’t wait 6 months for the renovation to finish before I got paid. But customers didn’t want multiple separate invoices for each payment or job change. They just wanted the total amount owed, right now, so that they could make decisions on project direction and budget.
						<br />
						<br />
						One less-than-ideal solution was to share the URL of a google spreadsheet Invoice where I could put receipts and make daily updates - like an ‘Ongoing Tab’ or “Live Invoice". But spreadsheets just weren’t mobile friendly and were quickly abandoned. 
						<br />
						<br />
						I thought If I could just make a website that could simply display invoices in a familiar paper like format, then just share the URL, even 62 year old Brenda could understand it.
						<br />
						<br />
						Live Invoicing (or Linvo for short) was born. And Brenda loved it. But while learning to code, I found the endless capabilities of technology absolutely fascinating. The comfortably busy stream of ideas in my mind was turning into a rushing river. Linvo could be a marketplace of contractors. Full of highly skilled tradesmen and ambitious customers. Contractors could build a team of sub-contractors and customers could find the perfect contractor for their project..
						<br />
						<br />
						Today, I'm proud to witness Linvo's growing impact as a trusted platform for contractors and customers, allowing highly skilled independent contractors to grow into well respected businesses. I'm grateful for the opportunity to drive industry transformation and exhilarated by Linvo's boundless growth and future success.
						<span className="big-quote">
							<span>"</span>
						</span>
					</p>
					{/*<div className="section-divider" />
					<div className="section-divider" />

					<p>How often do things like this happen? What makes a worker or homeowner okay with creating or adding to such poor quality work? Building permits, licenses and inspections were made to help solve these problems but they're clearly not working very well. Simply creating more laws and rules instead of looking into fixing the fundamental problem only worsens the situation over time. In a competitive market, strict regulations create demand for those willing to break/evade them and can instill a mentality in young workers to look for solutions that satisfy the regulations instead of solutions that satisfy the customer and the fundamental safety concern.</p>
					<div className="section-divider" />
					<div className="section-divider" />

					<p><span className="big-quote"><span>"</span></span>After weiging some options and a little convincing, we came up with a cost effective solution to cut an access panel in the pantry near the kitchen from where I could climb up and reach the mess and get the job done right.</p>
					<p>This and many other experiences have shown me the extent of the many responsibility related problems in construction. Employees are essentially incentivised to do bad work, especially when their company only got the job because they were the lowest bidder.<span className="big-quote"><span>"</span></span></p>
*/}
				</div>
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />

{/*
				<p>Picture this: You’re an employee at ABC Contracting Inc. which was awarded the contract for a commercial building. The project manager tells you to go ahead with a task that you know will result in future problems but still “meets code”. You see that doing it right will save a lot of money for the customer in the future but not doing as you're told could leave you jobless. Hopefully you wouldn’t, but let's say you're under some financial pressure to keep your job so, with much grumbling, you give in to 
				“Whatever you say boss”. 
				<br/>
				<br/>
				A few years later, as you predicted, things go wrong and you are blamed for causing the problem. You pass that blame on to the project manager, he passes it on to the company owners and they pass it on to the customer or their competition for pressuring them to bid low on the contract. The customer complains to the lawmakers, and eventually new band-aid-solution regulations are passed. The new regulations put a greater financial burden on ABC Contracting and - since they aren’t just running their business for fun - they must either increase their rates or find ways to slip under the regulations... giving rise to even more regulations.</p>
				<div className="section-divider" />

				<p>So where did this problem start? With you the worker, wishing to keep your job? With the Project manager wishing to keep his? With ABC Contracting trying to keep their business afloat in an industry where over 20% of businesses aren't even profitable? Well, unless someone in the chain was actually trying to harm someone else, ‘everyone is part of the problem’. ‘Everyone’ because everyone played their expected role in a system which incentivises passing on blame all the way to lawmakers. These lawmakers often give little thought into - or simply don't know  the extent of how the new regulations will affect the difficulty and complexity of a job. Neither do they think about how much more the customers will have to pay if their contractor follows the regulations.</p>

				<div className="section-divider" />
				<div className="section-divider" />

				<p>We think it’s time to try a new approach. Instead of incentivising solutions on how to evade regulation, what if we had a system that creates incentive for workers to do good, honest work? A system where workers are not scared of losing their jobs from doing things right. A system where customers know what they're paying for and can choose workers based on their specific needs. A system where a worker's success is not determined by how much their boss likes them but rather how well their customers and partners value them.</p>



				<div className="section-divider" />
				<div className="section-divider" />
				<div className="section-divider" />
*/}{/*
				<h4>Sub-contractors instead of employees</h4>
				<div className="section-divider" />

				<p>So far, companies like Uber and AirBNB have come up with business models that put more responsibility on the individual. When you ride in an Uber or stay in an AirBNB you never see a depressed employee waiting for their day to end. You see a happy host, grateful to you for your business. In similar ways, Linvo will give construction workers the opportunity to make their own life and choose the type of work they want to do. Linvo builds on the system that companies like Uber use, but with much more opportunity for workers. Instead of limiting workers to independent contractor status, any contractor on Linvo can work together with partners as their sub-contractor or find and hire their own sub-contractors to rapidly and effectively grow their business.</p>


				<p>A marketplace of customers, contractors and sub-contractors could result in a lot of complexity but this is where Linvo comes in. We are building the tools to help make this possible. One of the tools already in place is Invoice Levying. With Linvo’s Invoice Levying feature, a contractor can generate an invoice based on their sub-contractors invoice. This invoice is automatically updated in sync with the sub-contractors invoice, meaning the contractor wouldn't need to make updates. This allows a contractor more time to relax, find jobs or hand pick incredible workers to help them build their business. In this model of business, an experienced worker has virtually unlimited potential for growth and is much more affordable for customers than a traditional business which is burdened with employee contracts, HR, accounting and unmotivated or irresponsible workers.</p>
*/}
				<br />
				<br />
				<br />
				<h3>
					There are many new ideas and features we are planning for Linvo.
					Please understand that we are a small team, working with quite a large, feature rich application.
					Despite our limited resources we are still growing and always have time for our customers.
					Please let us know if you experience any bugs so we can fix them asap! 
					<br />
				</h3>

				<p className="medium"><strong>Enjoy!</strong></p>
			</div>
		</AboutStyle>
	);
};

export default About;
