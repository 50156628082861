import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { UserContext } from "../contexts/UserContext";

import Modal from "../components/Modal/Modal";
import Form from "../components/Forms/Form";

import { formulateSearchKeywords } from "../utils/appUtils";
import firebase from "../firebase/index";

const HelpStyle = styled.div`
	margin-left: 10px;
	margin-right: 10px;
	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
		text-align: left;
		.search-items-container {
			svg {
				width: 22px;
				padding: 5px;
		    vertical-align: bottom;
			}
		}
		.faq-wrapper {
		  cursor: pointer;
		  box-shadow: 0 0 10px -3px;
		  padding: 10px;
		  margin-top: 20px;
		}
		h4 {
			font-family: Gilroy-Bold;
		  margin: 0 0 10px 0;
		}
		h2 {
			font-family: Gilroy-Bold;
		  margin: 0 0 40px 0;	
		}
		h1 {
			font-family: Gilroy-Bold;
		  margin: 0 0 40px 0;		
		  font-size: var(--font-size-xl);
		}
	}
`;


const Help = ({ setLoginModalOpen }) => {
	const {
		currentUser,
		userObject,
		setErrorObj,
		handleSetSuccessText,
		offlineMode,
		setIsFetching,
		isFetching
	} = useContext(UserContext);

	const [faq, setFaq] = useState([]);
	const [endOfResults, setEndOfResults] = useState(false);
	const [activeQuestion, setActiveQuestion] = useState("");
	const [questionSubmitted, setQuestionSubmitted] = useState(false);
	const [formData, setFormData] = useState({
		questionTitle: "",
		fullQuestion: "",
		searchText: "",
	});
	const [aboutSearchMessage, setAboutSearchMessage] = useState("");

	const getFaq = (startAfterObj, limit) => {
		if (!offlineMode) {
			setIsFetching(true);
		}
		return firebase
			.firestore()
			.collection("faq")
			.where("answered", "==", true)
			.orderBy("relevanceScore")
			.startAfter(startAfterObj)
			.limit(limit)
			.get()
			.then((snapshot) => {
				setIsFetching(false);
				if (!snapshot.docs.length) {
					setEndOfResults(true);
					return [];
				} else {
					if (snapshot.docs.length < limit && startAfterObj) {
						setEndOfResults(true);
					}
					return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				}
			});
	};

	const handleSearch = async (value) => {
		if (!offlineMode) {
			setIsFetching(true);
		}
		setEndOfResults(false);
		// const searchArray = formulateSearchKeywords(value.toString().split(" "))
		const searchArray = formulateSearchKeywords({
			doc: { searchText: value, searchTextArray: value.toString().split(" ") },
			type: "search",
		});

		let resFaq;
		if (searchArray.length) {
			setAboutSearchMessage("");
			resFaq = await firebase
				.firestore()
				.collection("faq")
				.where("answered", "==", true)
				.where("searchKeywords", "array-contains-any", searchArray)
				.limit(30)
				.orderBy("relevanceScore")
				.get()
				.then((snapshot) => {
					if (!snapshot.docs.length) {
						return ["No results"];
					} else {
						return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
					}
				});
		} else {
			if (value && !searchArray.length) {
				// the formulateSearchKeywords fn didn't find any meaningful words
				setAboutSearchMessage(
					"Please use more specific words to get meaningful results"
				);
			} else {
				setAboutSearchMessage("");
			}
			resFaq = await getFaq(0, 10);
		}

		if (resFaq && resFaq.length) {
			setFaq(resFaq);
		}
		setIsFetching(false);
	};

	const handleLoadMore = async () => {
		try {
			if (!offlineMode) {
				setIsFetching(true);
			}
			const resFaq = await getFaq(faq.length, 10);
			if (resFaq && resFaq.length) {
				setFaq((faq) => [...faq, ...resFaq]);
			}
			setIsFetching(false);
		} catch (err) {
			setErrorObj(err);
		}
	};

	const handleSubmitFaq = async (e, fd) => {
		e.preventDefault();
		if (!currentUser || !currentUser.uid) {
			return setErrorObj({
				message: "Sorry, you must be logged in to submit a question",
				noReport: true,
			});
		}
		// if (!currentUser.) {
		// 	return setErrorObj({message: "Please verify your email address to submit a question"})
		// }
		// if (offlineMode) {
		// 	return setErrorObj({message: "You are in offline mode, check your internet connection", noReport: true})
		// }
		try {
			if (!offlineMode) {
				setIsFetching(true);
			}
			await firebase
				.firestore()
				.collection("faq")
				.doc()
				.set({
					...fd,
					answered: false,
					relevanceScore: 1,
					answer: [],
					timestamp: firebase.firestore.Timestamp.now().seconds,
					askedBy: {
						username: userObject.username,
						id: currentUser.uid,
						firstname: userObject.firstname || "",
						email: currentUser.email,
					},
					// searchKeywords: formulateSearchKeywords([
					// 	fd.questionTitle,
					// 	fd.fullQuestion,
					// 	fd.searchText
					// ], true),
					searchKeywords: formulateSearchKeywords({
						doc: fd,
						type: "faq",
					}),
				});

			setIsFetching(false);
			setQuestionSubmitted(true);
			setFormData({
				questionTitle: "",
				fullQuestion: "",
				searchText: "",
			});
			handleSetSuccessText("Thank you! Your question has been submitted");
		} catch (err) {
			setErrorObj(err);
		}
	};

	const handleChange = (name, value) => {
		setFormData((formData) => ({ ...formData, [name]: value }));
	};

	const questionInputs = [
		{
			label: "Question title",
			onChange: handleChange,
			properties: {
				type: "text",
				id: "questionTitle",
				value: formData.questionTitle,
				ref: useRef(null),
				required: true,
				maxLength: 50,
				disabled: !currentUser,
			},
		},
		{
			label: "Full question",
			custom: true,
			properties: {
				type: "textarea",
				onChange: handleChange,
				name: "fullQuestion",
				value: formData.fullQuestion,
				ref: useRef(null),
				maxLength: 200,
				disabled: !currentUser,
			},
		},
	];

	useEffect(() => {
		(async () => {
			try {
				if (!faq.length || typeof faq[0] === "string") {
					const resFaq = await getFaq(null, 30);
					if (resFaq && resFaq.length) {
						setFaq(resFaq);
					}
				}
			} catch (err) {
				setErrorObj(err);
			}
		})();
		// eslint-disable-next-line
	}, []);

	return (
		<HelpStyle>
			<div className="main-container">
				<h1 className="large">Help</h1>
				<div className="section-divider" />
				<h2 >FAQ</h2>
				<div className="small-line-break" />
				<div className="search-items-container">
					<div className="search-box">
						<input
							type="search"
							name="searchText"
							placeholder="Search FAQ"
							value={formData.searchText}
							onKeyPress={(e) =>
								e.key === "Enter" ? handleSearch(formData.searchText) : null
							}
							onChange={(e) => handleChange(e.target.name, e.target.value)}
						/>
							<svg
								onClick={() => handleSearch(formData.searchText)}
								id="svg-search"
								viewBox="0 0 32 32"
								fill="none"
								stroke="currentcolor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
							>
								<circle cx="14" cy="14" r="11" />
								<path d="M23 23 L28 28" />
							</svg>
					</div>
				</div>
				<div className="small-line-break" />
				<div className="about-search-message">
					{aboutSearchMessage && (
						<React.Fragment>
							<p className="small">{aboutSearchMessage}</p>
							<div className="small-line-break" />
						</React.Fragment>
					)}
				</div>
				{faq.map((q) => {
					if (typeof q === "string") {
						return <div key={q}>{q}</div>;
					} else {
						return (
							<div key={q.id} className="faq-wrapper">
								<h4 className="medium">{q.questionTitle}</h4>
								<div className="small">
									Q: {q.fullQuestion.slice(0, 30)}
									<span>
										{activeQuestion === q.id ? q.fullQuestion.slice(30) : "..."}
									</span>
								</div>
								<div className="small">
									A:&nbsp;
									{q.answer.map((obj, j) => {
										let newVal = obj.val;
										if (activeQuestion !== q.id) {
											newVal = newVal.slice(0, 30) + "...";
											if (j === 0) {
												if (obj.type === "link") {
													return (
														<React.Fragment key={j}>
															<Link
																className="link-appearance"
																to={(location) => ({
																	...location,
																	pathname: obj.link,
																})}
															>
																{newVal}
															</Link>
															&nbsp;
														</React.Fragment>
													);
												} else if (obj.type === "url") {
													return (
														<React.Fragment key={j}>
															<a className="link-appearance" href={obj.url} target="_blank" rel="noopener noreferrer">{newVal}</a>
															&nbsp;
														</React.Fragment>
													);
												} else if (obj.type === "string") {
													return (
														<React.Fragment key={j}>
															{newVal + " "}
														</React.Fragment>
													);
												} else return null;
											} else return null;
										} else {
											if (obj.type === "link") {
												return (
													<React.Fragment key={j}>
														<Link className="link-appearance" to={obj.link}>
															{newVal}
														</Link>
														&nbsp;
													</React.Fragment>
												);
											} else if (obj.type === "url") {
												return (
													<React.Fragment key={j}>
														<a className="link-appearance" href={obj.url} target="_blank" rel="noopener noreferrer">{newVal}</a>
														&nbsp;
													</React.Fragment>
												)
											} else if (obj.type === "string") {
												return (
													<React.Fragment key={j}>{newVal + " "}</React.Fragment>
												);
											} else {
												return null;
											}
										}
									})}
									<div
										className="link-appearance"
										onClick={() =>
											setActiveQuestion((activeQuestion) =>
												activeQuestion === q.id ? "" : q.id
											)
										}
									>
										{activeQuestion === q.id
											? "hide full answer"
											: "show full answer"}
									</div>
								</div>

								<div className="section-divider" />
							</div>
						);
					}
				})}
				<div className="small-line-break" />
				{(endOfResults || faq[0] === "No results") ? (
					<div>End of FAQ</div>
				) : (
					<button
						disabled={isFetching || !faq.length || endOfResults}
						className="button-appearance small"
						onClick={handleLoadMore}
					>
						load more
					</button>
				)}
				<div className="section-divider" />
				<div className="section-divider" />
				<div>Can't find an answer?</div>
				<div>
					Fill out the form below to ask a question or{" "}
					<Link className="link-appearance" to="/contact">
						contact us
					</Link>
				</div>
				<Modal custom={{ style: "align-items: unset; justify-content: unset;" }}>
					{!currentUser && (
						<div>
							<span
								className="link-appearance blue"
								onClick={() => setLoginModalOpen(true)}
							>
								Log in or Sign up&nbsp;
							</span>
							to submit a question
						</div>
					)}
					{questionSubmitted ? (
						<div>
							<div className="medium">Thank you!</div>
							Your question has been submitted
							<div
								onClick={() => setQuestionSubmitted(false)}
								className="link-appearance blue"
							>
								Ask another question
							</div>
						</div>
					) : (
						<Form
							inputs={questionInputs}
							heading={<div className="medium">Ask Question</div>}
							submitName="Submit"
							onSubmit={(e) => handleSubmitFaq(e, formData)}
						></Form>
					)}
				</Modal>
			</div>
		</HelpStyle>
	);
};

export default Help;
