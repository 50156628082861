import React, { useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';

// import { DialogContext } from "../../contexts/DialogContext";
import { UserContext } from '../../contexts/UserContext'


import Modal from "../Modal/Modal";
import Form from "../Forms/Form";
import { AppMessage } from "../MessageUtils";

import { handleChangeBillTo, getBillToDataFromContact } from "./invoiceUtils";
import { deepObjectCompareDiffers, handleLogoImageOnError } from "../../utils/appUtils";

import firebase from '../../firebase/index'
import '../FirebaseLoginWithProvider/loginButtonsCss.css'

// rendered by specificInvoice
// errors and success text handled
const ChangeBillToModal = ({
	userObject,
	parentResourceContextItems,
	parentResource,
	collectionName,
}) => {
	// const { dialog } = useContext(DialogContext);
	const {
		handleSave, // save project if changing bill to of project
		followersUserObjs,
		setChangeBillToModalOpen,
		setErrorObj,
		handleSetSuccessText,
		handleSetEditedInv,
	} = parentResourceContextItems;

	const {
		handleAddContacts,
	} = useContext(UserContext);

	const blankBillToObj = {
		name: "",
		firstname: "",
		lastname: "",
		address: {
			city: "",
			country: "",
			line1: "",
			line2: "",
			postal_code: "",
			state: "",
		},
		username: "",
		uid: "",
		showAddress: false,
		logoUrl: "",
	}

	// const [billToUserObj, setBillToUserObj] = useState({})
	const [newBillToData, setNewBillToData] = useState({
		...blankBillToObj,
		...parentResource.billTo,
		...parentResource.billTo.address ? {} : {address: {}}
	});

	const [contactsFilter, setContactsFilter] = useState([]);


	useEffect(() => {
	  if (!newBillToData.name && (newBillToData.firstname || newBillToData.lastname)) {
	  	setNewBillToData(newBillToData => ({
	  		...newBillToData,
	  		name: `${newBillToData.firstname} ${newBillToData.lastname}`.trim()
	  	}))
	  	
	  }
	}, [newBillToData])

	const handleChange = (nameOrId, value) => {
		if (nameOrId === "fullBillTo") {

			// value is an object
			const userInContacts = (userObject.contacts && userObject.contacts.length) ? userObject.contacts.find(cont => cont.id === value.id) : null
			let newBillTo = {}
			if (userInContacts) {
				// if the value name matches exactly a name in the users contacts, add the whole userContact object into billTo
				newBillTo = getBillToDataFromContact({...blankBillToObj, ...value, ...userInContacts})

			} else {
				const nameArray = value.name.split(/\s+/) 
				const calcFirstname = nameArray[0]
				const calcLastname = nameArray.length > 1 ? nameArray[nameArray.length - 1] : ""
				newBillTo = { ...blankBillToObj, ...newBillToData, id: "", name: value.name || "", firstname: calcFirstname, lastname: calcLastname || ""}
			}

			handleContactsDataList(newBillTo, contactsFilter, setContactsFilter, userObject.contacts);

			return setNewBillToData({ ...newBillTo });

		} else {
			let newBillTo = { ...newBillToData };
			newBillTo[nameOrId] = value;
			if (nameOrId === "uid") {
				if (!value) {
					newBillTo.username = "";
					newBillTo.logoUrl = "";
				} else {
					newBillTo.username = parentResource.billTo.username;
					newBillTo.logoUrl = parentResource.billTo.logoUrl;
				}
			}
			// setParentResource(parentResource => ({...parentResource, billTo: newBillTo}))
			setNewBillToData({ ...newBillTo });
		}
	};

	const handleAddressChange = (name, val) => {
		let address = {
			...newBillToData.address,
			[name]: val,
		};
		setNewBillToData((newBillToData) => ({ ...newBillToData, address }));
	};

	const updateBillTo = async (e, setChangeBillToModalOpen) => {
		try {
			e.preventDefault();
			// let confirmUpdate = true;
			// if (parentResource.billTo.uid && !newBillToData.uid) {
			// 	confirmUpdate = await dialog.confirm(
			// 		`${
			// 			parentResource.billTo.username
			// 		} has accepted this ${collectionName.slice(
			// 			0,
			// 			-1
			// 		)} as their bill. Are you sure you want to remove them?`
			// 	);
			// }
			// if (!confirmUpdate) {
			// 	return;
			// }
			// erase address if !sbhow address
			if (!showAddressFields) {
				newBillToData.showAddress = false
			} else {
				newBillToData.showAddress = true
			}
			if (collectionName === "invoices") {
				await handleChangeBillTo(
					e,
					{ ...parentResource, billTo: newBillToData },
					handleSetEditedInv,
					followersUserObjs,
					userObject.id,
					setErrorObj,
					handleSetSuccessText,
					parentResource.billTo
				);
				setChangeBillToModalOpen(false);
				return;
			} else if (collectionName === "projects") {
				await handleSave({
					newProjectData: { ...parentResource, billTo: newBillToData },
				});
				setChangeBillToModalOpen(false);
				return;
			} else {
				setErrorObj({
					message: "only capable of changing billTo of projects and invoices",
				});
				setChangeBillToModalOpen(false);
				return;
			}
		} catch (err) {
			throw err;
		}
	};

	let defaultShowAddressFields = false
	if (parentResource.billTo.showAddress !== undefined) {
		defaultShowAddressFields = parentResource.billTo.showAddress	
	} else {
		if (parentResource.billTo.address) {
			for (let key in parentResource.billTo.address) {
				// if any part of the address is filled in, show all of the address
				if (parentResource.billTo.address[key]) {
					defaultShowAddressFields = true
				}
			}
		}
	}

	const [showAddressFields, setShowAddressFields] = useState(defaultShowAddressFields) 

	const handleContactsDataList = (
		newBillTo,
		contactsFilter,
		setContactsFilter,
		userContacts = [],
	) => {

		// set the suggestions
		const userInput = newBillTo.name
			? newBillTo.name.trim()
			: "";
			// if there is no name yet or a name has been selected
		if (!newBillTo.name || newBillTo.id) {
			setContactsFilter([]);
			return;
		}

		let newContactsFilter = [];

		[...userContacts].reverse().forEach((obj) => {
			const name = obj.name.trim().toLowerCase();
			// const name = obj.name.toLowerCase();
			// direct hits
			if (name.startsWith(userInput.toLowerCase())) {
				newContactsFilter = [obj, ...newContactsFilter];

				// indirect hits
			} else if (name.includes(userInput.toLowerCase())) {
				newContactsFilter = [...newContactsFilter, obj];
			}
		});

		if (deepObjectCompareDiffers({a: newContactsFilter, b: contactsFilter})) {

			setContactsFilter(newContactsFilter.slice(0, 6));
		}
	};

	const clearBillToData = () => {
		setNewBillToData(blankBillToObj)
	}

	const newBillToDataExcludingNameAndId = {
		...newBillToData,
		name: "",
		firstname: "",
		lastname: "",
		id: ""
	}

	const changeBillToInputs = [
		{
			visible: true,
			properties: {
				type: "hidden",
				id: "billToUserInfo",
				onChange: () => null,
			},
			afterInput: [
				<React.Fragment key="billToUserInfo">
					<div className="section-divider" />
						<div className="billTo-user-card">
							<img 
								onError={handleLogoImageOnError} 
								src={newBillToData.logoUrl || "/assets/blank-profile-picture.png"} 
								alt="" 
							/>
							{		
								(newBillToData.uid && newBillToData.username) ? 
									<React.Fragment>
										<Link to={`/users/${newBillToData.username}`}>@{newBillToData.username}</Link>
										<div>{newBillToData.companyName || ""}</div>
									</React.Fragment>
								: <div className="small-line-break" />
							}
						</div>
					{
						(!newBillToData.uid || !newBillToData.username) && (deepObjectCompareDiffers({a: newBillToDataExcludingNameAndId, b: {...blankBillToObj, id: ""} })) ? 
						<React.Fragment>
							<div onClick={() => {
								clearBillToData()
							}} 
								className="link-appearance blue">Remove {(newBillToData.googleContactData && newBillToData.googleContactData.nameData && newBillToData.googleContactData.nameData.displayName) || newBillToData.email || "contact data"}</div>
						</React.Fragment>
						: <div className="section-divider" />
					}
				</React.Fragment>
			]
		},
		{
			label: `Remove ${parentResource.billTo.username} as Bill To`,
			onChange: handleChange,
			// allow remove bill to 
			visible: parentResource.billTo.uid && 
			(parentResource.owner === userObject.id ||
					(parentResource.contractor && parentResource.contractor.id === userObject.id)
			)
					? true
					: false,
			properties: {
				type: "checkbox",
				id: "uid",
				value: newBillToData.uid ? "" : parentResource.billTo.uid,
				checked: newBillToData.uid ? false : true,
				disabled: parentResource.closed,
			},
		},
		{
			visible: true,
			label: "Name",
			onChange: (name, value) => {
				// only change the id so that this is considered a new contact because there names dont match
				handleChange(name, {...newBillToData, id: "", name: value})
			},
			properties: {
				type: "text",
				id: "fullBillTo",
				name: "fullBillTo",
				value: newBillToData.name,
				autoComplete: "off",
			},
			beforeInput: [
				<div key="afterRemoveBillTo" className="section-divider" />		
			],
			afterInput: [
				<div key="find-contacts" 
					style={{
						position: "relative"
					}}
				>
					{
						(firebase.auth().currentUser && firebase.auth().currentUser.providerData.some(obj => obj.providerId === "google.com")) &&
						<div>
							<div className="section-divider" />
							<button onClick={(e) => {
									e.preventDefault()
									handleAddContacts()
								}} 
								className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button" 
								data-provider-id="google.com" 
								style={{backgroundColor: "#ffffff"}} 
								data-upgraded=",MaterialButton"
							>
								<span className="firebaseui-idp-icon-wrapper">
									<img className="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
								</span>
								<span className="firebaseui-idp-text firebaseui-idp-text-long">{(userObject.contacts && userObject.contacts.length) ? "Update" : "Import"} Google Contacts</span>
								<span className="firebaseui-idp-text firebaseui-idp-text-short">{(userObject.contacts && userObject.contacts.length) ? "Update" : "Import"} Contacts</span>
							</button>
							{/*<button className="button-appearance tiny no-icon-or-underline import-contacts" onClick={(e) => {
								e.preventDefault()
								handleAddContacts()
							}}>
								<img alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" />
								<div>{(userObject.contacts.length && userObject.contacts.some(c => c.fromGoogle)) ? "Update" : "Import"} Contacts</div>
							</button>*/}
							<div className="section-divider" />
						</div>
					}
					<div className="pac-container pac-logo" 
						style={{
					    display: contactsFilter.length ? "block" : "none",
					    width: "100%",
					    top: "0",
						}}
					>
						{
							contactsFilter.length ? contactsFilter.map((obj, i) => {
								return (
									<div 
										key={i} 
										className="pac-item" 
										onClick={() => {
											// overwrite billTo											
											handleChange("fullBillTo", {...blankBillToObj, ...obj})
											// setContactsFilter([])
										}}
									>
										{
											// Cant just use the url from google, need to download the image
											// (obj.photos && obj.photos[0] && obj.photos[0].url) ? 
											// <span style={{padding: "2px", borderRadius: "50%"}}>
											// 	<img height="20px" width="20px" style={{padding: "5px"}} src={obj.photos[0].url} alt="" />
											// </span>
											// : ""
										}
										<span className="pac-item-query" >{obj.name}</span>
										{
											obj.title ?
											<span>{` (${obj.title})`}</span>
											: ""
										}
										{
											obj.username ? <span>@{obj.username.slice(0, 15)}</span> : ""
										}
									</div>
								)
							})
							: null
						}
					</div>
					<div className="section-divider" />
				</div>
			]
		},

		// {
		// 	label: "First Name",
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "firstname",
		// 		value: newBillToData.firstname,
		// 		autoFocus: "autoFocus",
		// 		disabled: parentResource.closed,
		// 	},
		// },
		// {
		// 	label: "Last Name",
		// 	onChange: handleChange,
		// 	properties: {
		// 		type: "text",
		// 		id: "lastname",
		// 		value: newBillToData.lastname,
		// 		disabled: parentResource.closed,
		// 	},
		// },
		{
			label: "Add an address",
			onChange: (e) => {
				setShowAddressFields(showAddressFields => !showAddressFields)
			},
			// allow remove bill to 
			visible: true,
			properties: {
				type: "checkbox",
				id: "showAddressFields",
				value: !showAddressFields,
				checked: showAddressFields,
				disabled: parentResource.closed,
			},
		},
		{
			label: "City",
			onChange: handleAddressChange,
			visible: showAddressFields,
			properties: {
				type: "text",
				id: "city",
				value: newBillToData.address.city || "",
			},
			beforeInput: [
				<React.Fragment key="My Page">
					<div className="section-divider" />
					<div className="section-title">Address</div>
				</React.Fragment>,
			],
		},
		{
			label: "Country",
			onChange: handleAddressChange,
			visible: showAddressFields,
			properties: {
				type: "text",
				id: "country",
				value: newBillToData.address.country || "",
			},
		},
		{
			label: "Line1",
			onChange: handleAddressChange,
			visible: showAddressFields,
			properties: {
				type: "text",
				id: "line1",
				value: newBillToData.address.line1 || "",
			},
		},
		{
			label: "Line2",
			onChange: handleAddressChange,
			visible: showAddressFields,
			properties: {
				type: "text",
				id: "line2",
				value: newBillToData.address.line2 || "",
			},
		},
		{
			label: "Postal Code",
			onChange: handleAddressChange,
			visible: showAddressFields,
			properties: {
				type: "text",
				id: "postal_code",
				value: newBillToData.address.postal_code || "",
			},
		},
		{
			label: "State",
			onChange: handleAddressChange,
			visible: showAddressFields,
			properties: {
				type: "text",
				id: "state",
				value: newBillToData.address.state || "",
			},
		},
	];


	return (
		<Modal
			custom={{ absolute: true }}
			onClickOutside={() => setChangeBillToModalOpen(false)}
		>
			<AppMessage />
			<Form
				heading={<div className="heading">Change Bill To</div>}
				submitName="Change"
				onSubmit={(e) => {
					updateBillTo(e, setChangeBillToModalOpen);
				}}
				inputs={changeBillToInputs}
			></Form>
		</Modal>
	);
};

export default ChangeBillToModal;
