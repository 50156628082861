import firebase from "../firebase/index";
import { checkForNotification, sendNotification } from "./notificationUtils";
import { removeDuplicates } from "./appUtils";
import { handleUpdateReceiptMetadata } from "../components/Invoice/invoiceUtils";


// errors and successText handled
export const handleChangeUserCapabilities = async (
	userId,
	value,
	parentResource,
	setParentResource,
	collection,
	followersUserObjs,
	setFollowersUserObjs,
	userIsOwner,
	userObjectUid,
	setErrorObj,
	handleSetSuccessText,
	dialog,
) => {
	let newFollowersList = [...parentResource.followers];
	let newFollowersUserObjs = [];
	let newEditorsList = [...parentResource.editors];
	let newProjectFollowers = [];
	let newProjectEditors = [];
	let newOwner = parentResource.owner;
	let newContractorObj;
	let confirmChangeCapabilities = true;
	const collectionWithNoS = collection.slice(0, -1);
	const userIsSelf = userId === userObjectUid;
	const targetUser = followersUserObjs.find(
		(follower) => follower.id === userId
	);

	followersUserObjs.forEach((user) => {
		if (user.id === userId) {
			newFollowersUserObjs.push({ ...user, type: value });
			// remove old owner
		} else if (value === "owner" && user.type === "owner") {
			newFollowersUserObjs.push({ ...user, type: "editor" });
		} else {
			newFollowersUserObjs.push({ ...user });
		}
	});

	// prevent ownership change on invoices ( contractor is always owner )
	if (userIsOwner && userIsSelf) {
		confirmChangeCapabilities = false;
		return dialog.alert("Please select a new owner first").then(() => false);
	}

	// prevent a changing the payers visibility
	if (userId === parentResource.billTo.uid && confirmChangeCapabilities) {
		if (value === "none") {
			return dialog
				.alert(
					`The ${collectionWithNoS} is billed to this user, to change their viewing capabilities please remove them as Bill To first`
				)
				.then(() => false);
		} else {
			confirmChangeCapabilities = await dialog.confirm(
				`The ${collectionWithNoS} is billed to this user, are you sure you want to change their capabilities to '${value}'?`
			);
		}
	}

	if (value === "owner" && userIsOwner && confirmChangeCapabilities) {
		confirmChangeCapabilities = await dialog.confirm(
			`Confirm change ownership of this ${collectionWithNoS}? This will reduce your capabilities to 'editor'`
		);
		newOwner = userId;

		// update contractor obj if parent resource is invoice
		if (parentResource.contractor && targetUser) {
			newContractorObj = {
				companyEmail: targetUser.companyEmail, // dont use email as it shouldnt be public
				address: targetUser.address || "",
				fullRate: targetUser.industries.length
					? targetUser.industries[0].rate
					: "",
				id: targetUser.id,
				// name: userObject.companyName ? userObject.companyName : userObject.lastname, // unless user adds company info this will be their name
				companyName: targetUser.companyName || targetUser.username, // unless user adds company info this will be their name

				// make sure to put there name as default companyName when user signs up
				username: targetUser.username,
				// companyUrl: userObject.companyUrl ? userObject.companyUrl : `${userObject.lastname}-invoices`,
				phone: targetUser.phone || "",
				logoUrl: targetUser.logoUrl || "",
				lastInvNumber: targetUser.lastInvNumber || 100,
			};
		}
		// user must have edit capabilities if owner
		if (!parentResource.editors.includes(userId)) {
			newEditorsList.push(userId);
		}
		// add the old owner as editor
		if (!parentResource.editors.includes(userObjectUid)) {
			newEditorsList.push(userObjectUid);
		}
		if (parentResource.forProject) {
			newProjectFollowers.push(userId);
			newProjectEditors.push(userId);
		}
		// leave the userId in the followers list
	}

	if (value === "editor") {
		newEditorsList.push(userId);
		if (parentResource.forProject) {
			newProjectFollowers.push(userId);
		}
		// leave the userId in the followers list
	}

	if (value === "viewer") {
		// filter out user from editors list if in it
		if (newEditorsList.includes(userId)) {
			newEditorsList = newEditorsList.filter((str) => str !== userId);
		}
	}

	// confirm remove a followers access to view
	if (value === "none" && confirmChangeCapabilities) {
		if (parentResource.visibility === "private") {
			confirmChangeCapabilities = await dialog.confirm(
				`Confirm remove ${
					userIsSelf ? "yourself ? You" : "this follower? They"
				} will no longer be able to view this ${collectionWithNoS}`
			);
		} else {
			confirmChangeCapabilities = await dialog.confirm(
				`Confirm remove ${
					userIsSelf
						? `yourself from this ${collectionWithNoS}?`
						: `this follower? This ${collectionWithNoS} is 'Public' so they will still be able to view it if the have the link. \nChange the visibility to 'Private' if you don't want anyone except followers to be able to view it.`
				}`
			);
		}
		if (newEditorsList.includes(userId)) {
			newEditorsList = newEditorsList.filter((str) => str !== userId);
		}
		newFollowersList = newFollowersList.filter((str) => str !== userId);
		newFollowersUserObjs = newFollowersUserObjs.filter(
			(user) => user.id !== userId
		);
		// change the newFollowersUserObjs list if doesnt automatically update this user to an editor
	}

	if (collection === "projects" && confirmChangeCapabilities) {
		const contractorObjInProject = parentResource.editors.find(
			(editorId) => editorId === userId
		);
		if (contractorObjInProject) {
			if (value === "none" || value === "viewer") {
				confirmChangeCapabilities = await dialog.confirm(
					userIsSelf ?
					"Warning: You will no longer be able to add invoices or make edits to this project. Do you want to continue?"
					: "Warning: This user will no longer be able to add invoices to this project. Do you want to continue?"
				);
			}
		}
	}

	// make the changes
	if (confirmChangeCapabilities) {
		newEditorsList = removeDuplicates(newEditorsList);
		newFollowersList = removeDuplicates(newFollowersList);
		let parentResourceItemsToUpdate = {
			accessors: removeDuplicates([
				...newEditorsList,
				newOwner,
				parentResource.billTo.uid,
			]),
			followers: newFollowersList,
			editors: newEditorsList,
			owner: newOwner,
		};
		if (newContractorObj) {
			parentResourceItemsToUpdate.contractor = { ...newContractorObj };
		}

			// reset payment methods
		if (collection === "invoices") {
			// get the new owners payment methods
			await firebase.firestore().collection("users").doc(newOwner).get().then(user => {
				const userData = {...user.data()}
				parentResource.paymentMethods = userData.paymentMethods || null
				parentResourceItemsToUpdate.paymentMethods = userData.paymentMethods || null
			})
		}

		setParentResource((parentResource) => ({
			...parentResource,
			...parentResourceItemsToUpdate,
		}));
		setFollowersUserObjs(newFollowersUserObjs);
		return (
			firebase
				.firestore()
				.collection(collection)
				.doc(parentResource.id)
				.update({
					...parentResourceItemsToUpdate,
				})
				.then(() => {
					// add billTo and owner to project as followers
					// ??change to use the add editors to project function in invoiceUtils
					if (parentResource.forProject) {
						// only runs if ownership changed
						// let new owner have edit access to the forProject
						if (newProjectEditors.length) {
							return firebase
								.firestore()
								.collection("projects")
								.doc(parentResource.forProject)
								.update({
									// basically add all invoice accessors as followers but dont use accessors property in case an accessor
									// doesnt want to be a follower
									followers: firebase.firestore.FieldValue.arrayUnion(
										...newProjectFollowers
									),
									editors: firebase.firestore.FieldValue.arrayUnion(
										...newProjectEditors
									),
									accessors: firebase.firestore.FieldValue.arrayUnion(
										...newProjectEditors
									),
								});
						} else if (newProjectFollowers.length) {
							return firebase
								.firestore()
								.collection("projects")
								.doc(parentResource.forProject)
								.update({
									// basically add all invoice accessors as followers but dont use accessors property in case an accessor
									// doesnt want to be a follower
									followers: firebase.firestore.FieldValue.arrayUnion(
										...newProjectFollowers
									),
								});
						}
					}

					if (collection === "invoices") {
						// const newEditors = removeDuplicates([...parentResource.editors, parentResource.owner])
						handleUpdateReceiptMetadata({newEditors: newEditorsList, receipts: parentResource.receipts})
					}
				})
				.then(() => handleSetSuccessText("All changes saved!"))
				// .catch(err => setErrorObj(err))
				.catch((err) => {
					throw err;
				})
		);
	} else {
		return false;
	}
};

export const getUserObjs = (listOfUserIds, usersAlreadyFound, setErrorObj) => {
	// returns array of users and their data
	if (listOfUserIds && listOfUserIds.length) {
		const userDataRequests = listOfUserIds.map((id) => {
			let alreadyFoundUserObj = null;
			if (usersAlreadyFound && usersAlreadyFound.length) {
				alreadyFoundUserObj = usersAlreadyFound.find((user) => user.id === id);
			}
			if (alreadyFoundUserObj) {
				return alreadyFoundUserObj;
			} else {
				return firebase
					.firestore()
					.collection("users")
					.doc(id)
					.get()
					.then((doc) => ({ ...doc.data(), id: doc.id }));
				// .catch((err) => setErrorObj(err));
			}
		});

		const newUserDataRequests = Promise.all(userDataRequests);
		return newUserDataRequests;
	} else return [];
};

export const getFollowersUserDataWithType = (
	resFollowerData,
	resEditorData,
	parentResource
) => {
	resFollowerData = [...resFollowerData, ...resEditorData].filter(
		(isTruthy) => isTruthy
	);
	resFollowerData = removeDuplicates(resFollowerData);
	return resFollowerData.map((fullUserObj) => {
		let type = "viewer";
		// add type bill to ?
		if (parentResource.owner === fullUserObj.id) {
			type = "owner";
		} else if (resEditorData.find((user) => user.id === fullUserObj.id)) {
			type = "editor";
		}
		// return (id && username) && ({id, username, type})
		return { ...fullUserObj, type };
	});
};

export const checkFollowOrEditRequest = async (
	parentResource,
	userId,
	setFollowText
) => {
	if (!userId) {
		if (parentResource.visibility === "private") {
			setFollowText && setFollowText("Request access");
			return "Request access";
		} else {
			setFollowText && setFollowText("Follow");
			return "Follow";
		}
	}

	const followRequestNotification = checkForNotification({
		type: "followRequest",
		userKind: "sentBy",
		userId,
		forDocumentId: parentResource.id,
	}).catch((err) => {
		throw err;
	});
	const editRequestNotification = checkForNotification({
		type: "editRequest",
		userKind: "sentBy",
		userId,
		forDocumentId: parentResource.id,
	}).catch((err) => {
		throw err;
	});

	if (parentResource.followers && parentResource.followers.includes(userId)) {
		setFollowText && setFollowText("Unfollow");
		return "Unfollow";
	} else if (
		parentResource.editors && (
			parentResource.editors.includes(userId) ||
			parentResource.billTo.uid === userId ||
			parentResource.owner === userId
		)
	) {
		setFollowText && setFollowText("Follow");
		return "Follow";
	} else if (
		(await followRequestNotification) ||
		(await editRequestNotification)
	) {
		// if (followRequestNotification || editRequestNotification) {
		setFollowText && setFollowText("Request sent");
		return "Request sent";
	} else {
		if (parentResource.visibility === "private") {
			setFollowText && setFollowText("Request access");
			return "Request access";
		} else {
			setFollowText && setFollowText("Follow");
			return "Follow";
		}
	}
};

export const handleAddFollower = async ({
	userId,
	username,
	collection,
	parentResource,
}) => {
	try {
		// send notifications to those able to grant access
		// use cloud function to send notification if notification not already sent need to prevent spam in function
		// or have a notifications folder in db and scan that folder every time the invoice/ project is loaded?

		// temporary solution ..
		const collectionWithNoS = collection.slice(0, -1);
		if (!userId) {
			const e = new Error("Log in to follow");
			e.noReport = true
			throw e
		}
		if (
			parentResource.visibility !== "private" ||
			(
				parentResource.editors &&
				parentResource.editors.includes(userId)
			) ||
			(
				parentResource.billTo &&
				parentResource.billTo.uid === userId
			) ||
			parentResource.owner === userId
		) {
			await firebase
				.firestore()
				.collection(collection)
				.doc(parentResource.id)
				.update({
					followers: firebase.firestore.FieldValue.arrayUnion(userId),
				});

			return "Following";
		} else {
			// send a notification
			let forUsersArray = []
			if (parentResource.editors && parentResource.owner && parentResource.billTo) {
				forUsersArray = [
					...parentResource.editors,
					parentResource.owner,
					parentResource.billTo.uid,
				];
			}

			const newNotificationObj = {
				docLink: {
					// if the docs visibility is private the follow requestor wont be able to know the names or short hand IDs of the docs so add nothing and we add this later in rendering the notification
					name:
						collection === "projects"
							? parentResource.projectName
								? `${parentResource.projectName} ${
										parentResource.shortHandId || ""
								  }`
								: ""
							: parentResource.invNumber
							? `Invoice #${parentResource.invNumber} ${
									parentResource.invShortHand || ""
							  }`
							: "",
					pathname:
						collection === "projects"
							? `/${collection}/${parentResource.id}`
							: `/${
									parentResource.contractor.companyName || collectionWithNoS
							  }/${parentResource.id}`,
					externamLink: "",
				},
				forDocumentCollection: collection,
				forDocumentId: parentResource.id,
				forUsers: forUsersArray,
				sentBy: userId,
				sentByUsername: username || userId,
				type: "followRequest",
			};

			await sendNotification(newNotificationObj);
			return "Request sent";
		}
	} catch (err) {
		throw err;
	}
};

// not in use?
export const rejectBill = async (
	username,
	userId,
	collection,
	parentResource /*, setParentResource, setErrorObj, handleSetSuccessText*/,
	dialog
) => {
	const confirmReject = await dialog.confirm(
		"Are you sure you want to reject this bill?"
	);

	if (confirmReject) {
		// need to make billTo section of invoice editable by billTo

		// if user has only sent a request to accept bill
		if (parentResource.billTo.uid !== userId) {
			// user not yet bill to
			// dont use checkForAcceptBillNotification here since userKind ("sentBy") is specific
			const notification = await checkForNotification({
				type: "acceptBill",
				userKind: "sentBy",
				userId,
				forDocumentId: parentResource.id,
			}).catch((err) => {
				throw err;
			});
			if (notification) {
				// get rid of any notifications of accepting bill
				// change to change the properyty "read" to true or something instead of deleting
				await firebase
					.firestore()
					.collection("notifications")
					.doc(notification.id)
					.delete();
			}
		} else {
			await firebase
				.firestore()
				.collection(collection)
				.doc(parentResource.id)
				.update({
					billTo: {
						...parentResource.billTo,
						uid: "",
						username: "",
					},
					accessors: removeDuplicates([
						...parentResource.editors,
						parentResource.owner,
					]),
				});
		}

		return true;
	} else {
		return false;
	}
};

export const handleUnfollow = async ({
	userId,
	collection,
	userIsOwner,
	docId,
	docEditors,
	dialog,
}) => {
	try {
		if (!userId) {
			const err = new Error("No user ID found");
			throw err;
		}

		if (userIsOwner) {
			dialog.alert(
				"You can't unfollow if you are the owner. You must transfer ownership to unfollow"
			);
		}

		if (docEditors.includes(userId)) {
			let confirmRevokeEditAccess = await dialog.confirm(
				"You will no longer be able to edit this document. Are you sure you want to continue?"
			);
			if (confirmRevokeEditAccess) {
				await firebase
					.firestore()
					.collection(collection)
					.doc(docId)
					.update({
						followers: firebase.firestore.FieldValue.arrayRemove(userId),
						editors: firebase.firestore.FieldValue.arrayRemove(userId),
						accessors: firebase.firestore.FieldValue.arrayRemove(userId),
					});
			} else return false;
		} else {
			await firebase
				.firestore()
				.collection(collection)
				.doc(docId)
				.update({
					followers: firebase.firestore.FieldValue.arrayRemove(userId),
				});
		}

		return true;
	} catch (err) {
		throw err;
	}
};

export const handleAcceptFollower = (collection, docId, newFollowerId) => {
	return firebase
		.firestore()
		.collection(collection)
		.doc(docId)
		.update({
			followers: firebase.firestore.FieldValue.arrayUnion(newFollowerId),
		});
};

export const handleAcceptEditor = async (collection, docId, newEditorId) => {
	if (collection === "invoices") {
		// we need to get the invoice, find the editors and find any receipts 
		// because receipt files need their custom metadata updated to allow the new editor to edit 
		const invoiceEditors = await firebase.firestore().collection(collection).doc(docId).get().then(doc => {
			if (doc.exists) {
				return doc.data().editors				
			} else return null
		})

		if (invoiceEditors) {
			const receipts = await firebase.firestore().collection(collection).doc(docId).collection("receipts").get().then(snapshot => {
				if (snapshot.docs.length) {
					return snapshot.docs.map(rec => ({...rec.data(), id: rec.id}))
				} else return []
			})

			if (receipts && receipts.length) {
				const newEditors = removeDuplicates([...invoiceEditors, newEditorId])
				await handleUpdateReceiptMetadata({newEditors, receipts})
			}
		}
	}

	return firebase
		.firestore()
		.collection(collection)
		.doc(docId)
		.update({
			editors: firebase.firestore.FieldValue.arrayUnion(newEditorId),
			accessors: firebase.firestore.FieldValue.arrayUnion(newEditorId),
		});
};
